import React,{history,useEffect, useState, useMemo } from "react";
import Quick_links from "../../utility/Quick_links";
import Hiring from "../Jobs/Hiring";
import services from "../../services/services";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useNavigate, useParams } from "react-router-dom";
const ProfileBanner = () => {  // const { companyId } = useParams();
    //const companyId = 24;
    const companyId=sessionStorage.getItem("logCompanyId");

    const navigate = useNavigate();

    const [banner, setBanner] = useState([]);
    const [bannerBase64, setBannerBase64] = useState([]);
    const [imageName, setImageName] = useState([]);
    const [imageExtension, setImageExtension] = useState([]);
    const staticData = require('../../constants/'+process.env.React_App_Static_Folder+'/static-data.json');

  function getImage(){
        var files = document.getElementById('banner').files;

        if (files.length > 0) {

            var sizeInBytes = files[0].size;
            var sizeInMB = (sizeInBytes / (1024*1024)).toFixed(2);
            console.log(sizeInBytes+' - '+sizeInMB);

            if(sizeInMB > "3"){
                $("#bannerSizeError").show();
                return false;
            }
            else {
                $("#bannerSizeError").hide();

                var fname = files[0].name;
                // var fextn = fname.substr(0, fname.lastIndexOf('.'));
                var fextn = fname.split(".").pop();
                var fileActName = fname.split(".")[0];
                setImageName(fileActName);
                setImageExtension(fextn);
    
              getBase64(files[0]);
            }
            
        }
      }

    function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          console.log(reader.result);

          var base64result = reader.result.split(',')[1];
          //setBannerBase64(reader.result)
          setBannerBase64(base64result);
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }

    useEffect(() => {
        var jsonData = {
            "profileBanner": {
              
            }
          };
          var jsondataformatted = JSON.stringify(jsonData);
          sessionStorage.setItem('pagejsonObject', jsondataformatted);

        $(".load-wrap").hide();    
        $("#successMessage").hide();
        $(".successMessageCls").hide();
        $("#bannerSizeError").hide();
        if(companyId == null || companyId == "null"){
            //navigate('/login');
            navigate('/Home');
          }
       getCompanyBanner();
       $("#imgError").hide();

    }, []);

    const getCompanyBanner = () => {
       services.getCompanyProfile(companyId).then((res) => {
        if(res.data.bannerUrl==null || res.data.bannerUrl == ""){
            //setBanner("images/Photo/Video box.png");
            setBanner(staticData.default_banner);
           }else{

            setBanner(res.data.bannerUrl);
           }


       });
    }

    const {
       register,
       formState: { errors },
       handleSubmit 
    } = useForm({
       criteriaMode: "all", mode: "onBlur"
    });

    const submitValue = () => {

    //    const requestObject =
    //    {
    //       "companyId": "24",
    //       "siteId": 2,
    //       // "logoUrl": "https:/hometownopportunity.com"
    //       "bannerUrl": bannerBase64
    //    }



    if(imageExtension != "png" && imageExtension != "jpeg" && imageExtension != "jpg" && imageExtension != "gif"  ){
        //document.getElementById('logo').reset();
        $("#imgError").show();
        return false;
     }else{
        $("#imgError").hide();
     }
       const requestObject ={
        "imageName": imageName,
        "companyId": companyId,
        // "siteId": 2,
        "imageExtension": imageExtension,
        "imageByteString": bannerBase64
      }

       console.log(requestObject);
       var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
          method: 'POST',
          headers: {
             'Content-Type': 'application/json',
              'Authorization': "Bearer "+accessTokenIn
              //'Authorization': accessTokenIn
          },
          body: JSON.stringify(requestObject)
       };
       $(".load-wrap").show();
       services.editCompanyBanner(requestOptions).then((res) => {
        //   navigate('/thankyou');
          $("#addUserForm").trigger("reset");
          $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
            $("#successMessage").slideUp(1000);
            getCompanyBanner();
          });  
          $(".load-wrap").hide();
          //getCompanyBanner();
       });

    }




  return(
  <>
      <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="/companyJobs">Company Dashboard</a> </li>
                                <li class="breadcrumb-item headtitle "><a href="#">Company Banner</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">
                                {/* <!-- left side --> */}
                                <Quick_links/>

                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">
                                <form id="addUserForm"   onSubmit={handleSubmit(submitValue)}>
                                    <div class="row resume-wrap">
                                        <div class="col-lg-12 mb-4">
                                            <label for="firstName" class="form-label">Current Banner </label>
                                            <div class="mb-4">
                                                <img src={banner} class="w-100 mb-2 m-auto pro-banner-prev" alt=""/>
                                                {/* <img src="images/Photo/Video box.png" class="w-100 mb-2 m-auto" alt=""/> */}
                                            </div>

                                            <div class="d-flex align-items-center choose">
                                                <div>
                                                    <label for="firstName" class="form-label">Change Banner </label>
                                                    <div class="d-flex align-items-center">
                                                        <input class="form-control form-control-lg" id="banner" type="file" onChange={()=>getImage()}/>
                                                         </div>


                                                    <div id="imgError"  class="error">Invalid file format</div>
                                                    <div id="bannerSizeError" class="error">Image size is greater than 3 MB. Please upload Image below 3MB.</div>
                                                    
                                            <div id="successMessage" class="alert successMessageCls">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div class="d-flex align-items-center">
                                                        <img src="/images/success-alert.png" alt="" />
                                                        <strong class="mx-2">Success!</strong> company Banner Updated Successfully.
                                                    </div>
                                                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                                </div>
                                            </div>

                                                    <div className="d-flex flex-column mt-2">
                                                       <small>- Suggested Image dimensions <b>1300X365</b> pixels</small>
                                                       <small>- Maximum upload file size: <b>3MB</b></small>
                                                    </div>
                                                </div>
                                                <div><button type="submit" class="reg-button">Save</button></div>
                                            </div>

                                        </div>
                                    </div>
                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
            {/* <Hiring/> */}

            <section class="load-wrap">
            <div class="loadingIcon">
                <img src="images/loading.gif"></img>
            </div>
            </section>
    </main>
  </>
)};

ProfileBanner.propTypes = {};

ProfileBanner.defaultProps = {};

export default ProfileBanner;
