import React,{history,useEffect, useState, useMemo } from "react";
import Hiring from "./Jobs/Hiring";
import Recent_Jobs from "./Jobs/Recent_Jobs";
import services from "../services/services";
import $, { parseJSON } from 'jquery';

const DownloadApp = () => {
    const [downloadAppContent, setDownloadAppContent] = useState([]);
    const getPageInfo = (reqopt) => {

        $.ajax({
            url: 'https://snv3.herokuapp.com/api/pages/1',
            type: 'get',
            dataType: 'json',
            contentType: 'application/json',
            success: function (data) {
                //$('#target').html(data.msg);
                console.log(JSON.stringify(data));
                var temp = data.data;
                console.log(temp);
                var desc = temp.attributes.Description;
                desc = desc.replace(/\\/g, '');
                setDownloadAppContent(desc);
                //setDownloadAppContent(data.attributes.Description);
            }
          
        });

        /*
        services.getPageInfo_downloadourapp().then((res) => {
            
            console.log(JSON.stringify(res));
       
        });
        */
     }

    useEffect(() => {   
        const jsonData = {
            "downloadApp": {
              
            }
          };
          var jsondataformatted = JSON.stringify(jsonData);
          sessionStorage.setItem('pagejsonObject', jsondataformatted);

        getPageInfo("downloadourapp");
  
    }, []);
  return(
  <>
<main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="#">Download our App</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="info-typography py-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="py-5 px-5 white-bg" style={{paddingBottom:"20px !important"}}>
                           
                        <div class="row cd-desktop">
                                {downloadAppContent}
                            </div>
                           <div class="row cd-desktop">
                                <div class="col-md-8 text-left">
                                    <h2>Stay connected with your local opportunities…</h2>
                                    <p>Download the <b>Hometown Opportunity app</b> and stay constanly connected with companies and job oppportunities in your area.
                                        <br/>
                                        <br/>Hometown Opportunity is available on the Apple App Store or on Google Play.
                                    </p>
                                    <div class="d-flex justify-content-start app-ic-home">
                                        <a href="#"> <img src="images/apple-app-store.png" alt=""/></a>
                                        <a href="#"> <img src="images/google-play.png" alt="" class="ms-2"/></a>
                                    </div>
                                </div>
                                <div class="col-md-4 phone-home">
                                    <img src="images/HTO-app-face.png" alt="" style={{maxHeight: "360px"}}/>
                                </div>
                            </div> 
                            <section class="stay-connect-mobile">
                                <div class="title">
                                    <h2 class="text-center px-5">Stay connected with your local opportunities…</h2>
                                    <img src="images/HTO-app-face.png" class="w-100 p-5" alt=""/>
                                    <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. </p>
                                    <p>Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                                </div>
                                <div class="app-download text-center">
                                    <a href="#"> <img src="images/apple-app-store.png" class="py-2" alt=""/></a>
                                    <a href="#"><img src="images/google-play.png" class="py-2" alt=""/></a>

                                </div>
                            </section>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <Recent_Jobs/>
                        {/* <div class="white-bg mb-4">
                            <img src="images/ad-1.png" class="w-100" alt=""/>
                        </div>
                        <div class="white-bg mb-4">
                            <img src="images/ad-2.png" class="w-100" alt=""/>
                        </div> */}
                       {/* <div class="p-4 white-bg mb-4">
                            <h2 class="title-stay-connect">Stay connected. Get the app!</h2>
                            <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                            <img src="images/HTO-app-face.png" class="w-100" alt=""/>

                            <div class="d-flex justify-content-center  mt-3">
                                <img src="images/apple-app-store.png" alt="" style={{width: "130px"}}/>
                                <img src="images/google-play.png" alt="" class="ms-2" style={{maxWidth: "130px"}}/>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- Top companies hiring in your area --> */}
        <Hiring/>
    </main>
  </>
)};

DownloadApp.propTypes = {};

DownloadApp.defaultProps = {};

export default DownloadApp;
