import React, { history, useEffect, useState, useMemo, ChangeEvent } from "react";
import Quick_links from "../../utility/Quick_links";
import Hiring from "../Jobs/Hiring";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import services from "../../services/services";
import { useNavigate, useParams } from "react-router-dom";
import ReactPlayer from 'react-player';


const VideoUpload = () => {
   const [itemName, setFieldName] = useState('');

   //const companyId = 24;
   const companyId = sessionStorage.getItem("logCompanyId");

   const navigate = useNavigate();

   const [videoUrl, setVideo] = useState([]);
   const [videoUrlArr, setVideoUrlArr] = useState([]);

   const [state, setState] = useState({
      rows: [{}]
   });
   const [defaultVideo, setDefaultVideo] = useState('');
   /*
   const state = {
                     rows: [{}]
                  };
                  */

   useEffect(() => {
      $('#defaultvidMessage').hide();
      $('#emptyvurlMessage').hide();
      const jsonData = {
         "videoUpload": {
           
         }
       };
       var jsondataformatted = JSON.stringify(jsonData);
       sessionStorage.setItem('pagejsonObject', jsondataformatted);

      $("#successMessage").hide();
      if (companyId == null || companyId == "null") {
         //navigate('/login');
         navigate('/Home');
      }
      $("#carouselExampleIndicators_empty").hide();
      $('#carouselExampleIndicators_empty').attr('style','display:none !important');
      getCompanyVideosByCompanyId();
      //getCompanyVideo();

   }, []);



   function handleChange(idx, e) {
      const { name, value } = e.target;
      console.log("------58" + idx);
      const rows = [...state.rows];
      rows[idx] = {
         [name]: value
      };
      setState({
         rows
      });
      console.log(state.rows.length);
      if(state.rows.length == 1) {
         setDefaultVideo('0-'+value);
      }
   }

   function handleAddRow() {
      var emptyfound = 0;
      var rowlength = state.rows.length;
      for(var i=0; i<rowlength; i++){
         if(state.rows[i].name === ''){
            emptyfound = 1;
         }
      }
      if(emptyfound == 1){
         $('#emptyvurlMessage').show();
      } else {
         $('#emptyvurlMessage').hide();
         const item = {
            name: "",
            mobile: ""
         };
         setState({
            rows: [...state.rows, item]
         });
      }
   }


   function handleAddRowDyn(url) {
      const item = {
         name: url,
         mobile: ""
      };
      setState({
         rows: [...state.rows, item]
      });
   }

   function handleRemoveRow() {
      setState({
         rows: state.rows.slice(0, -1)
      });
   }
   function handleRemoveSpecificRow(idx, vurl) {
      if(defaultVideo === idx+'-'+vurl){
         setDefaultVideo("");
       }
      const rows = [...state.rows];
      rows.splice(idx, 1);
      setState({ rows });
   }

    
   function handleDefaultVideo(idx, e) {
      setDefaultVideo(idx);
   }


   //################################

   const getCompanyVideosByCompanyId = () => {
      handleRemoveRow();
      services.getCompanyVideosByCompanyId(companyId).then((res) => {
         console.log(JSON.stringify(res));
         var vobj = res.data;

         var tempRows = [];
         for (var v = 0; v < vobj.length; v++) {

            //var temp = vobj[v].url;
            //var vidId = getId(temp);
            //console.log(temp + "---" + vidId);
            //var embed_videourl = "https://www.youtube.com/embed/" + vidId;

            var embed_videourl = vobj[v].url;

            var item_iter = {
               name: embed_videourl,
               vid: vobj[v].companyVideoId,
               featured: vobj[v].featuredVideo
            };

            if(vobj[v].featuredVideo == 'Y'){
               setDefaultVideo(v+'-'+embed_videourl);
             }
            //tempObj.rows.push(item_iter);
            tempRows.push(item_iter);
            //console.log("#########" + v + "#######" + temp);
            console.log(JSON.stringify(tempRows));

         }

         if(vobj.length == 0){
            $("#carouselExampleIndicators").hide();
            $("#carouselExampleIndicators_empty").show();
            $('#carouselExampleIndicators_empty').attr('style','display:block !important');

         }else{
            $("#carouselExampleIndicators").show();
            $("#carouselExampleIndicators_empty").hide();
            $('#carouselExampleIndicators_empty').attr('style','display:none !important');
         }
         setVideoUrlArr(tempRows);

         if(vobj.length ==0){
            //handleAddRow();
            const item = {
              name: "",
              mobile: ""
           };
            setState({
              rows: [item]
           })
          }else{
            var tempObj = {
               rows: tempRows
            };
      
            setState(tempObj);
          }
      });
   }

   const getCompanyVideo = () => {
      services.getCompanyProfile(companyId).then((res) => {

         if (res.data.videoUrl == '') {
            setVideo("https://www.youtube.com/embed/Gg1rEy7hClo&t=7s");
         } else {

            //var video_url = res.data.videoUrl;
            //var vidId1 = getId(video_url);
            //console.log(video_url + "---" + vidId1);
            //var embed_videourl1 = "https://www.youtube.com/embed/" + vidId1;
            var embed_videourl1 = res.data.videoUrl;

            setVideo(embed_videourl1);
            //setVideo(video_url);
         }

      });
   }

   const {
      register,
      formState: { errors },
      handleSubmit
   } = useForm({
      criteriaMode: "all", mode: "onBlur"
   });

   function getId(url) {
      return url;
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = url.match(regExp);

      if (match && match[2].length == 11) {
         return match[2];
      } else {
         return 'error';
      }
   }

   function updateCompanyVideo() {
      $('#defaultvidMessage').hide();
      $('#emptyvurlMessage').hide();
      $("#successMessage").hide();
      var videourlfields = $('input[name^="name"]');
      if(videourlfields.length == 0){
         var urlTempArr = [];
         const requestObject =
         {
            "companyId": companyId,
            "siteId": process.env.React_App_SITE_ID,
            "videoUrl": urlTempArr
   
         }
   
         console.log(requestObject);
         var accessTokenIn = sessionStorage.getItem("accToken");
         const requestOptions = {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               // 'Authorization': "Bearer "+accessTokenIn
               //'Authorization': accessTokenIn
            },
            body: JSON.stringify(requestObject)
         };
   
         services.updateCompanyVideo(requestOptions).then((res) => {
            if(res){
               $("#successMessage").show();
               getCompanyVideosByCompanyId();
            }  
         });
      }
      else {
      var radioButtons = document.getElementsByName('default_video');
  var checkedval = '';
      // Loop through radio buttons
      for (var i = 0; i < radioButtons.length; i++) {
         // Check if the radio button is checked
         if (radioButtons[i].checked) {
            checkedval = radioButtons[i].value;
         }
      };

      if(checkedval != ''){
         $('#defaultvidMessage').hide();
      var chkValid = true;
      $('input[name^="name"]').each(function () {
         //code
         var iter_name = $(this).val()
         if (iter_name == "") {
            $(this).addClass("videoInputErrorClass");
            chkValid = false;
         } else {
            $(this).removeClass("videoInputErrorClass");
         }
      });

      if (!chkValid) {
         return false;
      }

    

      var urlTempArr = [];
      console.log(JSON.stringify(state));
      for (var k = 0; k < state.rows.length; k++) {
         urlTempArr.push(state.rows[k].name);
      }
      const requestObject =
      {
         "companyId": companyId,
         "siteId": process.env.React_App_SITE_ID,
         //"videoUrl": $("#videoUrl").val()
         //"videoUrl": ["v31111","v43333333"]
         "videoUrl": urlTempArr

      }

      console.log(requestObject);
      var accessTokenIn = sessionStorage.getItem("accToken");
      const requestOptions = {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            // 'Authorization': "Bearer "+accessTokenIn
            //'Authorization': accessTokenIn
         },
         body: JSON.stringify(requestObject)
      };

      services.updateCompanyVideo(requestOptions).then((res) => {
         // navigate('/thankyou');
         //$("#addUserForm").trigger("reset");
         //getCompanyVideo();
           //update default video
if(checkedval != ''){
   var mydefault = checkedval.split('-');
   const requestObject_defaultvideo =
   {
      "companyId": companyId,
      "url": mydefault[1],
   }
 
     console.log(requestObject_defaultvideo);
     var accessTokenIn = sessionStorage.getItem("accToken");
     const requestOptions_defaultvideo = {
        method: 'POST',
        headers: {
           'Content-Type': 'application/json',
            'Authorization': "Bearer "+accessTokenIn
           //'Authorization': accessTokenIn
        },
        body: JSON.stringify(requestObject_defaultvideo)
     };
 
     services.updateCompanyFeaturedVideo(requestOptions_defaultvideo).then((res) => {
        
       // $("#successMessage").show();
       // getCompanyVideosByCompanyId(companyId);
       $("#successMessage").show();
         getCompanyVideosByCompanyId();
     });
   }
         
      });
   }
   else{
      $('#defaultvidMessage').show();
      return false;
   }
}
   }

   const submitValue = () => {


      return false;

      //var vid_url = $("#videoUrl").val();
      //var vidId = getId(vid_url);
      //console.log(vid_url + "---" + vidId);
      //var embed_videourl = "https://www.youtube.com/embed/" + vidId;
      var embed_videourl = $("#videoUrl").val();


      const requestObject =
      {
         "companyId": companyId,
         "siteId": process.env.React_App_SITE_ID,
         //"videoUrl": $("#videoUrl").val()
         "videoUrl": embed_videourl

      }

      console.log(requestObject);
      var accessTokenIn = sessionStorage.getItem("accToken");
      const requestOptions = {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + accessTokenIn
            //'Authorization': accessTokenIn
         },
         body: JSON.stringify(requestObject)
      };

      services.editCompanyVideo(requestOptions).then((res) => {
         // navigate('/thankyou');
         $("#addUserForm").trigger("reset");
         getCompanyVideo();
      });

   }

   const handleOptionChange = (event) => {
      console.log('value : '+event.target.value);
      setDefaultVideo(event.target.value);
      $('#defaultvidMessage').hide();
    }; 


   return (
      <>
         <main class="empr-directory-main">
            <div class="main-bgpatten"></div>
            {/* <!-- info typography --> */}
            <section class="headtitle-wrap">
               <div class="container">
                  <div class="row">
                     <div class="col-lg-12">
                        <nav style={{ "--bs-breadcrumb-divider": '>' }} aria-label="breadcrumb">
                           <ol class="breadcrumb">
                              <li class="breadcrumb-item headtitle "><a href="/companyJobs">Company Dashboard</a> <span style={{ color: "#6c757d" }}>&#62;</span></li>
                              <li class="breadcrumb-item headtitle "><a href="#">Edit Video</a></li>
                           </ol>
                        </nav>
                     </div>
                  </div>
               </div>
            </section>
            {/* <!-- info typography --> */}
            <section class="py-5">
               <div class="container">
                  <div class="row">
                     <div class="col-12 ">
                        <div class="white-bg p-5">
                           <div class="row">
                              {/* <!-- left side --> */}
                              <Quick_links />


                              {/* <!-- right side --> */}

                              <div class="col-lg-8 mb-4">
                                 <form id="addUserForm" onSubmit={handleSubmit(submitValue)}>
                                    <div class="row resume-wrap">

                                       <div class="col-lg-12 mb-4">
                                          <label for="Upload" class="form-label">Current Video </label>
                                          <div className="d-none">
                                             {/* <iframe width="100%" height="420" src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe> */}
                                             <ReactPlayer url={videoUrl} />
                                          </div>
                                          <div id="carouselExampleIndicators_empty" className="text-center d-flex align-items-center" style={{ backgroundColor:"#f5f5f5", minHeight:"420px"}}>
                                            <div className="d-flex flex-column text-center m-auto" style={{width:"220px"}}>
                                            <img src="/images/no-video.png" className="m-auto mb-2 " style={{maxWidth:"50px"}} alt=""/>
                                            No videos available

                                            </div>
                                          </div>
                                          <div id="carouselExampleIndicators" class="carousel slide up-vid" data-bs-ride="carousel">
                                             <div class="carousel-indicators">
                                             {
                                                   videoUrlArr.map((data, index) => (
                                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} class={index == 0 ? "active" : " "} aria-current="true" aria-label={"Slide "+index}></button>
                                                ))}
                                                {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                                             </div>
                                             <div class="carousel-inner">


                                                {
                                                   videoUrlArr.map((data, index) => (

                                                      <div class={index == 0 ? "carousel-item active" : "carousel-item "}>
                                                         {/* <iframe width="100%" height="420" src={data.name} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe> */}
                                                         <ReactPlayer url={data.name} controls="true" width="100%" height="420px" config = {
	{
		facebook: {
			attributes: {
				'data-width': 'auto',
				'data-height': 'auto'
			}
		}
	}
} />
                                                      </div>


                                                   ))}
                                                {/* <div class="carousel-item active">
                                          <iframe width="100%" height="420" src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                                          </div>
                                          <div class="carousel-item">
                                             <iframe width="100%" height="420" src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                                          </div>
                                          <div class="carousel-item">
                                             <iframe width="100%" height="420" src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                                          </div> */}
                                             </div>
                                             <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Previous</span>
                                             </button>
                                             <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Next</span>
                                             </button>
                                          </div>



                                       </div>

                                       <div class="col-lg-12 mb-4">
                                          <table
                                             className="table table-bordered table-hover vd-ad-rm mb-4"
                                             id="tab_logic"
                                          >
                                             <thead>
                                                <tr>
                                                   <th className="text-center"> # </th>
                                                   <th className="text-center" style={{maxWidth: "50px"}}>Default Video</th>
                                                   <th className="text-center"> Video URL </th>
                                                   <th />
                                                </tr>
                                             </thead>
                                             <tbody>
                                                {state.rows.map((item, idx) => (
                                                   <tr id="addr0" key={idx}>
                                                      <td className="text-center">{idx + 1}</td>
                                                      <td className="text-center"><input class="form-check-input" type="radio" value={idx+'-'+state.rows[idx].name} name="default_video" id={idx+'_default_video'} checked={(defaultVideo === '' || defaultVideo === idx+'-'+state.rows[idx].name)} onChange={handleOptionChange}/>  </td>
                                                      <td>
                                                         <input
                                                            type="text"
                                                            name="name"
                                                            id={"name_" + idx}
                                                            value={state.rows[idx].name}
                                                            onChange={(e) => handleChange(idx, e)}
                                                            className="form-control " style={{ lineHeight: "30px" }}
                                                         />
                                                      </td>

                                                      <td style={{ maxWidth: "40px" }}>
                                                         <button
                                                            className="btn btn-outline-danger btn-sm"
                                                            onClick={() => handleRemoveSpecificRow(idx, state.rows[idx].name)}
                                                         >
                                                            <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
</svg></span>
                                                         </button>
                                                      </td>
                                                   </tr>
                                                ))}
                                             </tbody>
                                          </table>
                                          <button onClick={() => handleAddRow()} className="btn reg-button mr-2">
                                             + Add New
                                          </button>

                                          <button onClick={() => updateCompanyVideo()} class="btn reg-button"  >Save</button>
                                          {/* <button

                                    onClick={() => handleRemoveRow()}
                                    className="btn btn-danger float-right"
                                 >
                                    Delete Last Record
                                 </button> */}
                                       </div>

                                       <div id='defaultvidMessage' class="alert alert-danger mt-3">Please choose a default video.</div>

                                       <div id="emptyvurlMessage" className="alert alert-danger" role="alert">Please enter video url</div>

                                       <div id="successMessage" class="alert">
                                          <div class="d-flex align-items-center justify-content-between">
                                             <div class="d-flex align-items-center">
                                                <img src="/images/success-alert.png" alt="" />
                                                <strong class="mx-2">Success!</strong> Videos updated successfully.
                                             </div>
                                             <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                          </div>
                                       </div>
                                    </div>
                                 </form>
                              </div>

                           </div>
                        </div>

                     </div>

                  </div>
               </div>
            </section>
            <section class="white-bg py-5">
               <div class="container">
                  {/* <Hiring/> */}
                  {/* <div class="row mb-4">
                  <div class="col-12">
                     <div class="d-flex justify-content-between border-bottom  mb-4 pb-4 flex-lg-row flex-column">
                        <h4 class="headtitle">Top companies hiring in your area</h4>
                        <a href="#" class="text-uppercase">See All</a>
                     </div>
                  </div>

               </div>
               <div class="row">
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>


               </div> */}
               </div>
            </section>
         </main>
      </>

   )
};

VideoUpload.propTypes = {};

VideoUpload.defaultProps = {};

export default VideoUpload;
