import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams, useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import ReactDOM from "react-dom";
//import { useParams } from "react-router";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
//import { useSearchParams } from "react-router-dom";
import TopCompaniesHiring from "../Jobs/top_comp_hiring";
import services from "../../services/services";

import Hiring from "../Jobs/Hiring";
import $, { parseJSON } from 'jquery';
import MetaTags from 'react-meta-tags';

/*

import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import ReactDOM from "react-dom";
import Quick_links from "../../utility/Quick_links";
import Hiring from "../Jobs/Hiring";
import TopCompaniesHiring from "../Jobs/top_comp_hiring";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import services from "../../services/services";
*/


const CompanySummary = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const jobId = queryParams.get('jobId');
    const pagenum = queryParams.get('page');
    const explorepagenum = queryParams.get('ejpage');
    const [jobDetails, setJobDetails] = useState([]);
    const [companyDetails, setCompanyDetails] = useState([]);
    const [perksListInfo, setPerksListInfo] = useState([]);
    const [educations, setEducation] = useState([]);
    const [educationAr, setEducationAr] = useState([]);

    const companyIdChkLogin = sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin = sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin = sessionStorage.getItem("logProfileId");
    const userIdChkLogin = sessionStorage.getItem("logUserId");
    const chKRoleAr = sessionStorage.getItem("roleAr");

    const [summary, setSummary] = useState('');
    const [url, setUrl] = useState('');
    const [jobShareURL, setJobShareURL] = useState('');
    const [title, setTitle] = useState('');
    const staticData = require('../../constants/'+process.env.React_App_Static_Folder+'/static-data.json');

    var eduArray2 = [];
    useEffect(() => {
        // const jsonData = {
        //     "companySummary": {
              
        //     }
        //   };

        // var jsondataformatted = JSON.stringify(jsonData);
        // sessionStorage.setItem('pagejsonObject', jsondataformatted);

        //$(window).scrollTop();
        window.scrollTo(0, 0);
        getEducation();
        getJobDetailsById();

        var siteurlval = document.URL;
        var spliturl = siteurlval.split(/[#]/);
        var urllength = spliturl.length;
        console.log('******siteurlval: '+spliturl[0]);
        setUrl(spliturl[0]);

    }, []);

    const getEducation = () => {
        var eduArray = [];
        services.getEducation().then((res) => {
            console.log("getEducation data=" + JSON.stringify(res));
            setEducation(res.data.content);
            var dc = res.data.content;
            for (var i = 0; i < dc.length; i++) {

                var tempId = dc[i].educationId;
                var tempName = dc[i].name;
                var tobj = {
                    "educationId": tempId,
                    "name": tempName
                }
                //eduArray.push(tobj);
                educationAr[tempId] = tempName;
            }

            //setEducationAr(educationAr);
            console.log("##########56#####")
            console.log(JSON.stringify(educationAr));

        });
    }

    function getEduInfo(ed) {
        if (ed == "") {
            return "";
        }
        //var eduArray = [];
        //var ret = "ed--"+ed;
        console.log("##########64#####")
        console.log(JSON.stringify(educationAr));



        var ret = educationAr[ed];
        // alert(ret);
        return ret;
    }
    function getJobDetailsById() {
        //alert(jobId);
        services.getJobDetailsById(jobId).then((res) => {
            if (res.data) {
                console.log(res.data);
                setJobDetails(res.data.jobPostVo);
                var jobdata = res.data.jobPostVo;
                setTitle(jobdata.title);
                setSummary(jobdata.summary);
                setCompanyDetails(res.data.companyVo);
                var url_temp = process.env.React_App_FRONT_URL+"jobsummary?jobId="+jobdata.jobId+"&title="+jobdata.title;
                var encodeUrl = encodeURIComponent(url_temp);
                //$('head').append('<meta property="og:title" content="'+jobdata.title+'" />');
                //$('head').append('<meta property="og:url" content="'+url_temp+'" />');
                   /*
                var meta_url = document.getElementById('og_url');
                    meta_url.setAttribute("content", url_temp);
                var meta_title = document.getElementById('og_title');
                meta_title.setAttribute("content", jobdata.title);
                var meta_description = document.getElementById('og_description');
                var meta_main_description = document.getElementById('main_description');
                var js_string = jobdata.summary.substring(0,250);
                meta_description.setAttribute("content", js_string);
                meta_main_description.setAttribute("content", js_string);
                var main_title  = document.getElementById('main_title');
                main_title.setAttribute("content", jobdata.title);

                var tw_title  = document.getElementById('tw_title');
                tw_title.setAttribute("content", jobdata.title);
                var tw_url  = document.getElementById('tw_url');
                tw_url.setAttribute("content", url_temp);
                var tw_description  = document.getElementById('tw_description');
                tw_description.setAttribute("content", js_string);
                */

                setJobShareURL(encodeUrl);

                if (res.data.companyVo.perksList) {
                    setPerksListInfo(res.data.companyVo.perksList);
                }

            }

        });
    }

    return (
        <>
           <MetaTags>
           <meta property="og:locale" content="en_US" />
            <meta name="title" id="main_title" content={title}/>
            <meta name="description" id="main_description" content={summary} />


            <meta property="og:type" content="website" />
            <meta property="og:url" id="og_url" content={jobShareURL} />
            <meta property="og:title" id="og_title" content={title} />
            <meta property="og:description" id="og_description" content={summary} />
            {/* <meta property="og:image" content="https://snv3-hto-fs.s3.us-east-2.amazonaws.com/assets/meta/hto-fb.png" /> */}


            <meta property="twitter:card" content="summary" />
            <meta name="twitter:site" content="@hometownopportunity" />
            <meta property="twitter:url" id="tw_url" content={jobShareURL} />
            <meta property="twitter:title" id="tw_title" content={title} />
            <meta property="twitter:description" id="tw_description" content={summary} />
            {/* <meta property="twitter:image" content="https://snv3-hto-fs.s3.us-east-2.amazonaws.com/assets/meta/hto-fb.png"></meta> */}
           </MetaTags>
            <main class="empr-directory-main">
                <div class="main-bgpatten"></div>

                <section class="headtitle-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">

                                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                                    <ol class="breadcrumb">

                                        {(() => {

                                            if (jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null") {
                                                return (<><li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li> </>);
                                            }

                                            if (studentIdChkLogin != null && studentIdChkLogin != "null") {
                                                return (<><li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li> </>);
                                            }

                                            if (chKRoleAr == "schoolsuper") {
                                                return (<><li class="breadcrumb-item headtitle"> <a href="/schoolAdminDashboard">School Admin</a> </li> </>);
                                            }

                                        })()}


                                        <li class="breadcrumb-item headtitle "><a href="#">View Job -
                                            {/* {jobDetails.title.substring(0, 50)} */}
                                            {jobDetails.title ? jobDetails.title.substring(0, 50) : ""}

                                            {(() => {
                                                if (typeof jobDetails.title !== 'undefined')
                                                    if (jobDetails.title.length > 50) {
                                                        return (<>...</>);
                                                    }
                                            })()}



                                        </a></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="info-typography py-5">
                    <div class="container">


                        <div className="row pb-3">
                            <div className="d-flex">
                                <span className="mr-1 rotate-90">
                                    <img src="images/mob-arrow.png" alt="" />
                                </span>

                                {(() => {
                                    if (pagenum != null && pagenum != "null" && pagenum != "") {
                                        return (<><Link to={"/availableJobs?page=" + pagenum} style={{ color: "#fff" }}>Back to Jobs List</Link> </>);
                                    }else if (explorepagenum != null && explorepagenum != "null" && explorepagenum != ""){
                                        return (<><Link to={"/careerExploreJobs?pageid=" + explorepagenum} style={{ color: "#fff" }}>Back to Jobs List</Link> </>);
                                    }else{
                                        return (<><Link to={"/availableJobs"} style={{ color: "#fff" }}>Back to Jobs List</Link> </>);
                                    }

                                    })()}

                            </div>
                        </div>



                        <div class="row">
                            <div class="col-lg-8">
                                <div class="py-5 px-5 white-bg position-relative cd-desktop" style={{ "padding-bottom": "20px !important;" }}>
                                    <div class="row jobsummary-content">
                                        <div class="company-summery">
                                            <div class="mb-4 d-flex cs-tab">
                                            <img src={companyDetails.logoUrl ? companyDetails.logoUrl : staticData.default_logo} alt="" class="mr-2" />
                                                
                                                <div>
                                                <p class="mb-0"><a href={`/companyDetails?companyId=` + companyDetails.companyId + `&page=` + pagenum}>{companyDetails.name}</a> </p>

                                                    <p class="mb-0">{companyDetails.address1 != '' ? companyDetails.address1 : ''}</p>

                                                    <p class="mb-0">

                                                        {companyDetails.city != '' ? companyDetails.city : ''}

                                                        {companyDetails.city != '' && companyDetails.state != '' ? ', ' : ''}

                                                        {companyDetails.state != '' ? companyDetails.state : ''}

                                                        {companyDetails.state != '' && companyDetails.zip != '' ? ', ' : ''}

                                                        {companyDetails.zip != '' ? companyDetails.zip : ''}

                                                    </p>
                                                </div>


                                            </div>

                                            <div class="right-info">
                                                <h4>{jobDetails.title}</h4>
                                            </div>

                                            <div class="stock-wrap ">
                                                {(jobDetails.jobApplyUrl == '' || jobDetails.jobApplyUrl == null) ? <a href={"/applyJobProfile?jobId=" + jobDetails.jobId}>Apply Now</a> : <a href={jobDetails.jobApplyUrl} target="_blank">Apply Now</a>}
                                               {/* <a href={"/applyJobProfile?jobId=" + jobDetails.jobId}>Apply Now</a> */}
                                            </div>

                                            <div class="date">
                                                <b class="d-flex text-uppercase">
                                                    {/* <span>August 19</span>,
                         <span class="ms-1"> 2021</span> */}
                                                    <span>{moment(jobDetails.createdDate).format("MM/DD/YYYY")}</span>
                                                </b>
                                            </div>
                                            <div class="d-flex align-items-center mt-4">
                                           <span className="text-uppercase">Share: </span>

<div class="mx-2 cs-social">
   {/* <a id="fbsharebtn" href={`https://www.facebook.com/dialog/share?app_id=1284249534969949&display=popup&amp;href=${url}&amp;redirect_uri=${url}`} data-mobile-iframe="true">
        <img src="images/FACEBOOK 2.png" alt="" />
    </a> */}

    {/* <a id="fbsharebtn" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${url}&amp;src=sdkpreparse`} data-mobile-iframe="true">
        <img src="images/FACEBOOK 2.png" style={{maxWidth:"32px"}} alt="" />
    </a> &nbsp; */}

    <a id="fbsharebtn" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${jobShareURL}&amp;src=sdkpreparse`} data-mobile-iframe="true">
        <img src="images/FACEBOOK 2.png" style={{maxWidth:"32px"}} alt="" />
    </a> &nbsp;

     {/* <a class="popup" target="_blank" href={`https://www.linkedin.com/shareArticle?mini=true&url=${process.env.React_App_FRONT_URL}jobsummary?jobId=${jobDetails.jobId}&title=${title}`}>
        <img src="images/LINKEDIN 2.png" style={{maxWidth:"32px"}} alt="" />
    </a> &nbsp;  */}

<a class="popup" target="_blank" href={`https://www.linkedin.com/shareArticle?mini=true&url=${jobShareURL}`}>
        <img src="images/LINKEDIN 2.png" style={{maxWidth:"32px"}} alt="" />
    </a> &nbsp;




    {/* <a class="popup" target="_blank" href={`https://twitter.com/share?text=${title}&url=${jobShareURL}`}>
        <img src="images/TWITTER 2.png" style={{maxWidth:"32px"}} alt="" />
    </a> &nbsp; */}

    <a class="popup" target="_blank" href={`https://twitter.com/intent/tweet?text=${title}&url=${jobShareURL}`}>
        <img src="images/TWITTER 2.png" style={{maxWidth:"32px"}} alt="" />
    </a> &nbsp;
    <a target="_blank" href={`mailto:?subject=Job:%20${title}&body=${jobShareURL}`}>
        <img src="images/EMAIL 2.png" style={{maxWidth:"32px"}} alt="" />
    </a>
</div>
                                           </div>

                                        </div>
                                        <hr class="my-4" />

                                        <div class="mb-4">
                                            <h4>JOB TYPE</h4>
                                            <p>{jobDetails.strJobType} </p>
                                        </div>

                                        <div class="mb-4">
                                            <h4>JOB SUMMARY</h4>
                                            {/* <p>{jobDetails.summary}</p> */}
                                            <p dangerouslySetInnerHTML={{ __html: jobDetails.summary }} />
                                        </div>

                                        <div class="mb-4">
                                            <h4>CAREER LEVEL </h4>
                                            <p>{jobDetails.career} </p>
                                        </div>



                                        <div class="mb-4">
                                            <h4>EDUCATION LEVEL</h4>
                                            <p>{getEduInfo(jobDetails.educationId)}
                                            </p>
                                        </div>
                                        {/*
                                <div class="mb-4">
                                    <h4>CAREER LEVEL </h4>
                                    <p>Manager with Reports </p>
                                </div> */}

                                        <div class="mb-4">
                                            <h4>EXPERIENCE/CERTIFICATION REQUIRED:</h4>
                                            {/* <p>{jobDetails.experiance} </p> */}
                                            <p dangerouslySetInnerHTML={{ __html: jobDetails.experiance }} />
                                        </div>



                                        <div class="mb-4">
                                            <h4>SALARY/BENEFITS</h4>
                                            {/* <p>{jobDetails.salary}</p> */}
                                            <p dangerouslySetInnerHTML={{ __html: jobDetails.salary }} />
                                        </div>

                                        <div class="mb-4">
                                            <h4>ADDITIONAL INSTRUCTIONS TO APPLY:</h4>
                                            <p>
                                                {/* <a href={jobDetails.apply} alt="">{jobDetails.apply}</a> */}
                                                {jobDetails.apply}
                                            </p>
                                            <div  style={{marginTop:"25px"}}>
                                               {/* <a href={"/applyJobProfile?jobId=" + jobDetails.jobId} className="reg-button">Apply Now</a> */}

                                                {(jobDetails.jobApplyUrl == '' || jobDetails.jobApplyUrl == null) ? <a href={"/applyJobProfile?jobId=" + jobDetails.jobId} class="reg-button">Apply Now</a> : <a href={jobDetails.jobApplyUrl} target="_blank" class="reg-button">Apply Now</a>}
                                            </div>
                                        </div>







                                    </div>

                                </div>

                                <div class="cd-mobile py-5 px-2 white-bg position-relative ">
                                    <div class="card border-0 border-bottom p-3 ">
                                        <div class="text-center">
                                        <img width="100%" src={companyDetails.logoUrl ? companyDetails.logoUrl : staticData.default_logo} alt="" /> 

                                        </div>
                                        <div class="text-left js-img">
                                            <hr class="my-4" />
                                            <div class="date">
                                                <b class="d-flex text-uppercase">
                                                    <span>{moment(jobDetails.createdDate).format("MM/DD/YYYY")}</span>




                                                </b>
                                            </div>

                                            <div class="mb-3">
                                                    <p class="mb-0"><a href={`/companyDetails?companyId=` + companyDetails.companyId + `&page=` + pagenum}>{companyDetails.name}</a> </p>
                                                    <p class="mb-0">{companyDetails.address1 != '' ? companyDetails.address1 : ''}</p>

                                                    <p class="mb-0">
                                                         {companyDetails.city != '' ? companyDetails.city : ''}
                                                        {companyDetails.city != '' && companyDetails.state != '' ? ', ' : ''}
                                                         {companyDetails.state != '' ? companyDetails.state : ''}
                                                          {companyDetails.state != '' && companyDetails.zip != '' ? ', ' : ''}
                                                          {companyDetails.zip != '' ? companyDetails.zip : ''}
                                                     </p>
                                                </div>
                                            <h4>{jobDetails.title}</h4>



                                            {/* <div class="d-flex mb-3">
                                                <span><a href={`/companyDetails?companyId=` + companyDetails.companyId + `&page=` + pagenum} class="company-name">{companyDetails.name} </a></span>
                                                <span class="mx-3">|</span>


                                                <span> {companyDetails.address1 != '' ? companyDetails.address1 : ''}
                                                    {companyDetails.address1 != '' && companyDetails.state != '' ? ', ' : ''}
                                                    {companyDetails.city != '' ? companyDetails.city : ''}
                                                    {companyDetails.city != '' && companyDetails.city != '' ? ', ' : ''}
                                                    {companyDetails.state != '' ? companyDetails.state : ''}</span>
                                            </div> */}

<div class="d-flex align-items-center my-4">
    <span className="text-uppercase">Share</span>
                                       <div className="mx-3">
                                       <a id="fbsharebtn" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${jobShareURL}&amp;src=sdkpreparse`} data-mobile-iframe="true">
                                            <img src="images/FACEBOOK 2.png" style={{maxWidth:"32px"}} alt="" />
                                        </a> &nbsp;

                                         {/* <a class="popup" target="_blank" href={`https://www.linkedin.com/shareArticle?mini=true&url=${process.env.React_App_FRONT_URL}jobsummary?jobId=${jobDetails.jobId}&title=${title}`}>
                                            <img src="images/LINKEDIN 2.png" style={{maxWidth:"32px"}} alt="" />
                                        </a> &nbsp; */}

                                         <a class="popup" target="_blank" href={`https://www.linkedin.com/shareArticle?mini=true&url=${jobShareURL}`}>
                                            <img src="images/LINKEDIN 2.png" style={{maxWidth:"32px"}} alt="" />
                                        </a> &nbsp;


                                        {/* <a class="popup" target="_blank" href={`https://twitter.com/share?text=${title}&url=${jobShareURL}`}>
                                            <img src="images/TWITTER 2.png" style={{maxWidth:"32px"}} alt="" />
                                        </a> &nbsp; */}

                                        <a class="popup" target="_blank" href={`https://twitter.com/intent/tweet?text=${title}&url=${jobShareURL}`}>
                                            <img src="images/TWITTER 2.png" style={{maxWidth:"32px"}} alt="" />
                                        </a> &nbsp;

                                        <a target="_blank" href={`mailto:?subject=Job:%20${title}&body=${jobShareURL}`}>
                                            <img src="images/EMAIL 2.png" style={{maxWidth:"32px"}} alt="" />
                                        </a>
                                       </div>
                                        </div>

                                            <div class="text-left mb-5">
                                                <h4 class="text-uppercase"><small>JOB TYPE:</small></h4>
                                                <p>{jobDetails.strJobType}</p>
                                            </div>

                                            <div class="text-left mb-5">
                                                <h4 class="text-uppercase"><small>JOB SUMMARY</small></h4>
                                                {/* <p>{jobDetails.summary}</p> */}
                                                <p dangerouslySetInnerHTML={{ __html: jobDetails.summary }} />
                                            </div>

                                            <div class="text-left mb-5">
                                                <h4 class="text-uppercase"><small>CAREER LEVEL: </small></h4>
                                                <p>{jobDetails.career}</p>

                                            </div>

                                            <div class="text-left mb-5">
                                                <h4 class="text-uppercase"><small>EXPERIENCE/CERTIFICATION REQUIRED:</small></h4>
                                                {/* <p>{jobDetails.experiance}</p> */}
                                                <p dangerouslySetInnerHTML={{ __html: jobDetails.experiance }} />
                                            </div>



                                            {/* <div class="text-left mb-5">
                                        <h4 class="text-uppercase"><small>EDUCATION and/or EXPERIENCE:</small></h4>
                                        <p>{jobDetails.jobEducation}</p>
                                    </div> */}

                                            <div class="text-left mb-5">
                                                <h4 class="text-uppercase"><small>SALARY/BENEFITS: </small></h4>
                                                {/* <p>{jobDetails.salary}</p> */}
                                                <p dangerouslySetInnerHTML={{ __html: jobDetails.salary }} />
                                            </div>





                                            <div class="text-left mb-5">
                                                <h4 class="text-uppercase"><small>ADDITIONAL INSTRUCTIONS TO APPLY: </small></h4>
                                                <p><a href={jobDetails.apply} alt="">{jobDetails.apply}</a></p>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="p-4 white-bg mb-4">
                                    <div class="bg-heading">
                                        <h3 class="mb-0">job perks</h3>
                                    </div>
                                    <ul class="mt-4 job-perks">

                                        {
                                            perksListInfo.map((datap, index) => (
                                                <li> <span> <img src={datap.perksImageUrl} alt="" /></span> {datap.perksTitle}</li>
                                            ))}

                                        {/* <li> <span> <img src="images/lunch-plate.png" alt="" /></span> <a href="#">1 Hour lunch Breaks</a></li>
                                <li> <span> <img src="images/cofee.png" alt="" /></span> <a href="#">Break Room cofee  Bar</a></li>
                                <li> <span> <img src="images/dollars.png" alt="" /></span> <a href="#">Refferal Bonuses</a></li>
                                <li> <span> <img src="images/health.png" alt="" /></span> <a href="#">Health Insurance</a></li>
                                <li> <span> <img src="images/dental.png" alt="" /></span> <a href="#">Dental Insurance</a></li>
                                <li> <span> <img src="images/vision.png" alt="" /></span> <a href="#">Vision Insurance</a></li>
                                <li> <span> <img src="images/life-insurance.png" alt="" /></span> <a href="#">Life Insurance</a></li>
                                <li> <span> <img src="images/discounts.png" alt="" /></span> <a href="#">Company Discounts</a></li>
                                <li> <span> <img src="images/tutions.png" alt="" /></span> <a href="#">Tution Reimbursment</a></li>
                                <li> <span> <img src="images/stocks.png" alt="" /></span> <a href="#">Stock Options</a></li>
                                <li> <span> <img src="images/hoiliday.png" alt="" /></span> <a href="#">2-week Vacation</a></li>
                                <li> <span> <img src="images/sick.png" alt="" /></span> <a href="#">Unlimited sick leave</a></li>
                                <li> <span> <img src="images/parantal.png" alt="" /></span> <a href="#">Parental Leave</a></li> */}
                                    </ul>
                                    <hr class="mt-4 mb-4" />
                                    <div class="text-center mb-3">
                                        {/*<a href={"/applyJobProfile?jobId=" + jobDetails.jobId} class="reg-button">Apply Now</a> */}

                                        {(jobDetails.jobApplyUrl == '' || jobDetails.jobApplyUrl == null) ? <a href={"/applyJobProfile?jobId=" + jobDetails.jobId} class="reg-button">Apply Now</a> : <a href={jobDetails.jobApplyUrl} target="_blank" class="reg-button">Apply Now</a>}
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </section>
                <section class="white-bg py-5 p-4 cd-desktop">
                    <div class="container">
                        <div class="row mb-4 mt-4">
                            {/* <TopCompaniesHiring/> */}
                            <Hiring />
                        </div>
                    </div>
                </section>

                {/* <section class="cd-mobile white-bg py-4">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="border-bottom mb-4 py-3 headtitle ">Midmark Corporation Available Positions</h2>
                        <div class="card p-4 mb-4 ">
                            <div class="text-center">
                                <img src="images/midmark-logo.JPG" class="m-auto" alt="" />

                            </div>
                            <div class="text-left">
                                <hr class="my-5" />
                                <div class="date">
                                    <b class="d-flex text-uppercase">
                                <span>August 19</span>,
                                <span class="ms-1"> 2021</span>
                            </b>
                                </div>
                                <h4>PRODUCT AND SERVICES MANAGER</h4>
                                <div class="d-flex mb-3">
                                    <span><a href="#" class="company-name">Midmark Corporation </a></span>
                                    <span class="mx-3">|</span>
                                    <span> Versailles, Ohio</span>

                                </div>
                                <p>The Senior Product and Services Manager researches and develops the market for services to support the organization. Responsible for executing the divisional strategy through the delivery of products, services, and solutions
                                    to the market. Performs the following duties…</p>
                            </div>

                            <hr class="my-4" />
                            <div class="jobperks-mobile">
                                <h2 class="text-center text-uppercase">Job Perks</h2>
                                <div class="job-seek-mobile mb-3">
                                    <div> <img src="images/health.png" alt="" /></div>
                                    <div> <img src="images/lunch-plate.png" alt="" /></div>
                                    <div> <img src="images/dental.png" alt="" /></div>
                                    <div> <img src="images/cofee.png" alt="" /></div>
                                    <div> <img src="images/cofee.png" alt="" /></div>
                                    <div><a href="#" class="js-more">more </a></div>
                                </div>
                                <div class="text-center">
                                    <a href="#" class="reg-button">View JOB</a>
                                </div>
                            </div>
                        </div>

                        <div class="card p-4 mb-4 ">
                            <div class="text-center">
                                <img src="images/midmark-logo.JPG" class="m-auto" alt="" />

                            </div>
                            <div class="text-left">
                                <hr class="my-5" />
                                <div class="date">
                                    <b class="d-flex text-uppercase">
                                <span>August 19</span>,
                                <span class="ms-1"> 2021</span>
                            </b>
                                </div>
                                <h4>PRODUCT AND SERVICES MANAGER</h4>
                                <div class="d-flex mb-3">
                                    <span><a href="#" class="company-name">Midmark Corporation </a></span>
                                    <span class="mx-3">|</span>
                                    <span> Versailles, Ohio</span>

                                </div>
                                <p>The Senior Product and Services Manager researches and develops the market for services to support the organization. Responsible for executing the divisional strategy through the delivery of products, services, and solutions
                                    to the market. Performs the following duties…</p>
                            </div>

                            <hr class="my-4" />
                            <div class="jobperks-mobile">
                                <h2 class="text-center text-uppercase">Job Perks</h2>
                                <div class="job-seek-mobile mb-3">
                                    <div> <img src="images/health.png" alt="" /></div>
                                    <div> <img src="images/lunch-plate.png" alt="" /></div>
                                    <div> <img src="images/dental.png" alt="" /></div>
                                    <div> <img src="images/cofee.png" alt="" /></div>
                                    <div> <img src="images/cofee.png" alt="" /></div>
                                    <div><a href="#" class="js-more" data-bs-toggle="modal" data-bs-target="#exampleModal">more</a></div>
                                </div>
                                <div class="text-center">
                                    <a href="#" class="reg-button">View JOB</a>
                                </div>
                            </div>
                        </div>

                        <div class="card p-4 mb-4 ">
                            <div class="text-center">
                                <img src="images/midmark-logo.JPG" class="m-auto" alt="" />

                            </div>
                            <div class="text-left">
                                <hr class="my-5" />
                                <div class="date">
                                    <b class="d-flex text-uppercase">
                                <span>August 19</span>,
                                <span class="ms-1"> 2021</span>
                            </b>
                                </div>
                                <h4>PRODUCT AND SERVICES MANAGER</h4>
                                <div class="d-flex mb-3">
                                    <span><a href="#" class="company-name">Midmark Corporation </a></span>
                                    <span class="mx-3">|</span>
                                    <span> Versailles, Ohio</span>

                                </div>
                                <p>The Senior Product and Services Manager researches and develops the market for services to support the organization. Responsible for executing the divisional strategy through the delivery of products, services, and solutions
                                    to the market. Performs the following duties…</p>
                            </div>

                            <hr class="my-4" />
                            <div class="jobperks-mobile">
                                <h2 class="text-center text-uppercase">Job Perks</h2>
                                <div class="job-seek-mobile mb-3">
                                    <div> <img src="images/health.png" alt="" /></div>
                                    <div> <img src="images/lunch-plate.png" alt="" /></div>
                                    <div> <img src="images/dental.png" alt="" /></div>
                                    <div> <img src="images/cofee.png" alt="" /></div>
                                    <div> <img src="images/cofee.png" alt="" /></div>
                                    <div><a href="#" class="js-more" data-bs-toggle="modal" data-bs-target="#exampleModal">more</a></div>
                                </div>
                                <div class="text-center">
                                    <a href="#" class="reg-button">View JOB</a>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
            <div class="pagination-wrap text-center mt-3">
                <nav aria-label="...">
                    <ul class="pagination m-auto d-inline-flex">
                        <li class="page-item disabled">
                            <span class="page-link"><img src="images/arrow-left.JPG" alt="" /></span>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                        <li class="page-item active" aria-current="page">
                            <span class="page-link">2</span>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item">
                            <a class="page-link" href="#"><img src="images/arrow-right.JPG" alt="" /></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </section> */}

                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">JOB PERKS</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body py-5">
                                <div class="job-seek-modal">
                                    <div class="d-flex mb-2 align-items-center">
                                        <div> <img src="images/health.png" alt="" /></div>
                                        <p>Health Insurance</p>
                                    </div>

                                    <div class="d-flex mb-2 align-items-center">
                                        <div> <img src="images/lunch-plate.png" alt="" /></div>
                                        <p>1-Hour Lunch Breaks</p>
                                    </div>

                                    <div class="d-flex mb-2 align-items-center">
                                        <div> <img src="images/dental.png" alt="" /></div>
                                        <p>Dental Insurance</p>
                                    </div>

                                    <div class="d-flex mb-2 align-items-center">
                                        <div> <img src="images/cofee.png" alt="" /></div>
                                        <p>Break Room Coffee Bar</p>
                                    </div>

                                    <div class="d-flex mb-2 align-items-center">
                                        <div> <img src="images/sick.png" alt="" /></div>
                                        <p>Unlimited Sick Leave</p>
                                    </div>

                                    <div class="d-flex mb-2 align-items-center">
                                        <div> <img src="images/lunch-plate.png" alt="" /></div>
                                        <p>1-Hour Lunch Breaks</p>
                                    </div>

                                    <div class="d-flex mb-2 align-items-center">
                                        <div> <img src="images/life-insurance.png" alt="" /></div>
                                        <p>Life Insurance</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </main>
        </>
    )
};

CompanySummary.propTypes = {};

CompanySummary.defaultProps = {};

export default CompanySummary;