import React,{history,useEffect, useState, useMemo } from "react";

const AvailableJobs = () => {

  return(
  <>
     <main  class="empr-directory-main">
         <div class="main-bgpatten"></div>
         <section class="headtitle-wrap">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                     <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item headtitle "><a href="#">Available Jobs</a></li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- info typography --> */}
         <section class="company-detail-wrap py-5 ">
            <div class="container">
               <div class="row">
                  <div class="col-12 ">
                     <div class="white-bg px-5 pt-4 pb-4">
                        <div class="row mb-3">
                           <div class="col-lg-12 m-auto">
                              <h1 class="text-center ">
                                 There are   <span class=" red-text">so many opportunities</span> <br/>
                                 for local careers!
                              </h1>
                              <div class="col-4 m-auto">
                                 <hr/>
                              </div>
                           </div>
                           <div class="col-lg-8 m-auto text-center">
                              <p>
                                 To find a great new job with one of the many amazing local employers in your area, simply select your industry, location and experience level below, as well as whether you’d like part-time, full-time, seasonal work or a full time career. Your matches will automatically appear in the <b>“top job opportunities”</b> box below.
                              </p>
                           </div>
                        </div>
                        <div class="col-lg-12 mb-4">
                           <label for="firstName" class="form-label"> Choose Your Industry </label>
                           <select class="form-select" aria-label="Default select example">
                              <option selected="">Industry All</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                           </select>
                        </div>
                        <div class="row">
                           <div class="col-lg-6 mb-4">
                              <label for="firstName" class="form-label"> Choose Your Location </label>
                              <select class="form-select" aria-label="Default select example">
                                 <option selected="">Industry All</option>
                                 <option value="1">One</option>
                                 <option value="2">Two</option>
                                 <option value="3">Three</option>
                              </select>
                           </div>
                           <div class="col-lg-6 mb-4">
                              <label for="firstName" class="form-label"> SELECT YOUR EXPERIENCE LEVEL</label>
                              <select class="form-select" aria-label="Default select example">
                                 <option selected="">Industry All</option>
                                 <option value="1">One</option>
                                 <option value="2">Two</option>
                                 <option value="3">Three</option>
                              </select>
                           </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 mb-2">
                                <div class="d-flex">
                                    <input class="form-check-input mr-2" type="checkbox" value="" id="defaultCheck1"/>
                                    <label for="firstName" class="form-label"> INTERNSHIP / COOP</label>
                                </div>
                            </div>
                            <div class="col-lg-3 mb-2">
                                <div class="d-flex">
                                    <input class="form-check-input mr-2" type="checkbox" value="" id="defaultCheck1"/>
                                    <label for="firstName" class="form-label"> SEASONAL</label>
                                </div>
                            </div>
                            <div class="col-lg-3 mb-2">
                                <div class="d-flex">
                                    <input class="form-check-input mr-2" type="checkbox" value="" id="defaultCheck1"/>
                                    <label for="firstName" class="form-label">PART TIME</label>
                                </div>
                            </div>
                            <div class="col-lg-3 mb-2">
                                <div class="d-flex">
                                    <input class="form-check-input mr-2" type="checkbox" value="" id="defaultCheck1"/>
                                    <label for="firstName" class="form-label">FULL TIME</label>
                                </div>
                            </div>
                            <div class="col-12 mb-3 ">
                               <hr/>
                            </div>

                            <div class="container-fluid">
                                <form class=" search-bar">
                                   <label for="firstName" class="form-label">SEARCH BY KEYWORD</label>
                                   <input class="form-control me-2" type="search" aria-label="Search" placeholder="Search Employer by Name"/>
                                   <button class="border-0 p-0" type="submit"><img src="images/search-icon.JPG" alt=""/></button>
                                </form>
                             </div>

                             <div class="text-center mb-4 mt-5">
                                <a href="#" class="reg-button">Show Me</a>
                            </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section class="pb-5" >
            <div class="container">
               <div >
                  <div class="col-12">
                     <div class="white-bg px-5 pt-4 pb-4">
                        <div class="row mb-4 mt-4">
                           <div class="col-lg-12">
                              <div class="d-flex justify-content-between border-bottom  mb-4 pb-4 flex-lg-row flex-column">
                                 <h4 class="headtitle">Top companies hiring in your area</h4>
                                <span>Total of <b>35</b> Jobs</span>
                              </div>
                           </div>
                           <div class="oppertunity-card2">
                              <div class="logo-org">
                                  <img src="images/midmark-logo.JPG" alt=""/>
                              </div>
                              <div class="right-info">
                                  <div class="d-flex justify-content-between flex-lg-row flex-column">
                                      <a href="#"><h4>PRODUCT AND SERVICES MANAGER</h4></a>
                                      <div class="date">
                                          <b class="d-flex text-uppercase">
                                              <span>August 19</span>,
                                              <span class="ms-1"> 2021</span>
                                          </b>
                                      </div>
                                  </div>
                                  <div class="d-flex mb-3">
                                      <span><a href="#" class="company-name">Midmark Corporation </a></span>
                                      <span class="mx-3">|</span>
                                      <span> Versailles, Ohio</span>
                                  </div>
                                  <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                                
                              </div>
                              <div class="job-seeker">
                                  <b class="text-uppercase mb-2">job-seeker</b>
                                  <div class="job-seek-icon mb-3">
                                     <div> <img src="images/health.png" alt=""/></div>
                                     <div> <img src="images/lunch-plate.png" alt=""/></div>
                                     <div> <img src="images/dental.png" alt=""/></div>
                                     <div> <img src="images/cofee.png" alt=""/></div>
                                     <div> <img src="images/cofee.png" alt=""/></div>
                                  </div>
                                  <div class="text-center">
                                      <a href="#" class="reg-button">View JOB</a>
                                  </div>
                              </div>
                          </div>
                          <div class="oppertunity-card2">
                           <div class="logo-org">
                               <img src="images/midmark-logo.JPG" alt=""/>
                           </div>
                           <div class="right-info">
                               <div class="d-flex justify-content-between flex-lg-row flex-column">
                                 <a href="#"><h4>PRODUCT AND SERVICES MANAGER</h4></a>
                                   <div class="date">
                                       <b class="d-flex text-uppercase">
                                           <span>August 19</span>,
                                           <span class="ms-1"> 2021</span>
                                       </b>
                                   </div>
                               </div>
                               <div class="d-flex mb-3">
                                   <span><a href="#" class="company-name">Midmark Corporation </a></span>
                                   <span class="mx-3">|</span>
                                   <span> Versailles, Ohio</span>
                               </div>
                               <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                             
                           </div>
                           <div class="job-seeker">
                               <b class="text-uppercase mb-2">job-seeker</b>
                               <div class="job-seek-icon mb-3">
                                  <div> <img src="images/health.png" alt=""/></div>
                                  <div> <img src="images/lunch-plate.png" alt=""/></div>
                                  <div> <img src="images/dental.png" alt=""/></div>
                                  <div> <img src="images/cofee.png" alt=""/></div>
                                  <div> <img src="images/cofee.png" alt=""/></div>
                               </div>
                               <div class="text-center">
                                   <a href="#" class="reg-button">View JOB</a>
                               </div>
                           </div>
                       </div>
                       <div class="oppertunity-card2">
                        <div class="logo-org">
                            <img src="images/midmark-logo.JPG" alt=""/>
                        </div>
                        <div class="right-info">
                            <div class="d-flex justify-content-between flex-lg-row flex-column">
                              <a href="#"><h4>PRODUCT AND SERVICES MANAGER</h4></a>
                                <div class="date">
                                    <b class="d-flex text-uppercase">
                                        <span>August 19</span>,
                                        <span class="ms-1"> 2021</span>
                                    </b>
                                </div>
                            </div>
                            <div class="d-flex mb-3">
                                <span><a href="#" class="company-name">Midmark Corporation </a></span>
                                <span class="mx-3">|</span>
                                <span> Versailles, Ohio</span>
                            </div>
                            <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                          
                        </div>
                        <div class="job-seeker">
                            <b class="text-uppercase mb-2">job-seeker</b>
                            <div class="job-seek-icon mb-3">
                               <div> <img src="images/health.png" alt=""/></div>
                               <div> <img src="images/lunch-plate.png" alt=""/></div>
                               <div> <img src="images/dental.png" alt=""/></div>
                               <div> <img src="images/cofee.png" alt=""/></div>
                               <div> <img src="images/cofee.png" alt=""/></div>
                            </div>
                            <div class="text-center">
                                <a href="#" class="reg-button">View JOB</a>
                            </div>
                        </div>
                    </div>
                    <div class="oppertunity-card2">
                                    <div class="logo-org">
                                        <img src="images/midmark-logo.JPG" alt=""/>
                                    </div>
                                    <div class="right-info">
                                        <div class="d-flex justify-content-between flex-lg-row flex-column">
                                            <a href="#"><h4>PRODUCT AND SERVICES MANAGER</h4></a>
                                            <div class="date">
                                                <b class="d-flex text-uppercase">
                                                    <span>August 19</span>,
                                                    <span class="ms-1"> 2021</span>
                                                </b>
                                            </div>
                                        </div>
                                        <div class="d-flex mb-3">
                                            <span><a href="#" class="company-name">Midmark Corporation </a></span>
                                            <span class="mx-3">|</span>
                                            <span> Versailles, Ohio</span>
                                        </div>
                                        <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                                      
                                    </div>
                                    <div class="job-seeker">
                                        <b class="text-uppercase mb-2">job-seeker</b>
                                        <div class="job-seek-icon mb-3">
                                           <div> <img src="images/health.png" alt=""/></div>
                                           <div> <img src="images/lunch-plate.png" alt=""/></div>
                                           <div> <img src="images/dental.png" alt=""/></div>
                                           <div> <img src="images/cofee.png" alt=""/></div>
                                           <div> <img src="images/cofee.png" alt=""/></div>
                                        </div>
                                        <div class="text-center">
                                            <a href="#" class="reg-button">View JOB</a>
                                        </div>
                                    </div>
                                </div>
                           <div class=" row mb-4">
                              <img src="images/mcdonald-ad.JPG" class="w-100" style={{padding: "0px"}} alt=""/>
                           </div>
                           <div class="oppertunity-card2">
                              <div class="logo-org">
                                  <img src="images/midmark-logo.JPG" alt=""/>
                              </div>
                              <div class="right-info">
                                  <div class="d-flex justify-content-between flex-lg-row flex-column">
                                      <a href="#"><h4>PRODUCT AND SERVICES MANAGER</h4></a>
                                      <div class="date">
                                          <b class="d-flex text-uppercase">
                                              <span>August 19</span>,
                                              <span class="ms-1"> 2021</span>
                                          </b>
                                      </div>
                                  </div>
                                  <div class="d-flex mb-3">
                                      <span><a href="#" class="company-name">Midmark Corporation </a></span>
                                      <span class="mx-3">|</span>
                                      <span> Versailles, Ohio</span>
                                  </div>
                                  <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                                
                              </div>
                              <div class="job-seeker">
                                  <b class="text-uppercase mb-2">job-seeker</b>
                                  <div class="job-seek-icon mb-3">
                                     <div> <img src="images/health.png" alt=""/></div>
                                     <div> <img src="images/lunch-plate.png" alt=""/></div>
                                     <div> <img src="images/dental.png" alt=""/></div>
                                     <div> <img src="images/cofee.png" alt=""/></div>
                                     <div> <img src="images/cofee.png" alt=""/></div>
                                  </div>
                                  <div class="text-center">
                                      <a href="#" class="reg-button">View JOB</a>
                                  </div>
                              </div>
                          </div>
                          <div class="oppertunity-card2">
                           <div class="logo-org">
                               <img src="images/midmark-logo.JPG" alt=""/>
                           </div>
                           <div class="right-info">
                               <div class="d-flex justify-content-between flex-lg-row flex-column">
                                   <a href="#"><h4>PRODUCT AND SERVICES MANAGER</h4></a>
                                   <div class="date">
                                       <b class="d-flex text-uppercase">
                                           <span>August 19</span>,
                                           <span class="ms-1"> 2021</span>
                                       </b>
                                   </div>
                               </div>
                               <div class="d-flex mb-3">
                                   <span><a href="#" class="company-name">Midmark Corporation </a></span>
                                   <span class="mx-3">|</span>
                                   <span> Versailles, Ohio</span>
                               </div>
                               <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                             
                           </div>
                           <div class="job-seeker">
                               <b class="text-uppercase mb-2">job-seeker</b>
                               <div class="job-seek-icon mb-3">
                                  <div> <img src="images/health.png" alt=""/></div>
                                  <div> <img src="images/lunch-plate.png" alt=""/></div>
                                  <div> <img src="images/dental.png" alt=""/></div>
                                  <div> <img src="images/cofee.png" alt=""/></div>
                                  <div> <img src="images/cofee.png" alt=""/></div>
                               </div>
                               <div class="text-center">
                                   <a href="#" class="reg-button">View JOB</a>
                               </div>
                           </div>
                       </div>
                       <div class="oppertunity-card2">
                        <div class="logo-org">
                            <img src="images/midmark-logo.JPG" alt=""/>
                        </div>
                        <div class="right-info">
                            <div class="d-flex justify-content-between flex-lg-row flex-column">
                                <a href="#"><h4>PRODUCT AND SERVICES MANAGER</h4></a>
                                <div class="date">
                                    <b class="d-flex text-uppercase">
                                        <span>August 19</span>,
                                        <span class="ms-1"> 2021</span>
                                    </b>
                                </div>
                            </div>
                            <div class="d-flex mb-3">
                                <span><a href="#" class="company-name">Midmark Corporation </a></span>
                                <span class="mx-3">|</span>
                                <span> Versailles, Ohio</span>
                            </div>
                            <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                          
                        </div>
                        <div class="job-seeker">
                            <b class="text-uppercase mb-2">job-seeker</b>
                            <div class="job-seek-icon mb-3">
                               <div> <img src="images/health.png" alt=""/></div>
                               <div> <img src="images/lunch-plate.png" alt=""/></div>
                               <div> <img src="images/dental.png" alt=""/></div>
                               <div> <img src="images/cofee.png" alt=""/></div>
                               <div> <img src="images/cofee.png" alt=""/></div>
                            </div>
                            <div class="text-center">
                                <a href="#" class="reg-button">View JOB</a>
                            </div>
                        </div>
                    </div>
                    <div class="oppertunity-card2">
                     <div class="logo-org">
                         <img src="images/midmark-logo.JPG" alt=""/>
                     </div>
                     <div class="right-info">
                         <div class="d-flex justify-content-between flex-lg-row flex-column">
                             <a href="#"><h4>PRODUCT AND SERVICES MANAGER</h4></a>
                             <div class="date">
                                 <b class="d-flex text-uppercase">
                                     <span>August 19</span>,
                                     <span class="ms-1"> 2021</span>
                                 </b>
                             </div>
                         </div>
                         <div class="d-flex mb-3">
                             <span><a href="#" class="company-name">Midmark Corporation </a></span>
                             <span class="mx-3">|</span>
                             <span> Versailles, Ohio</span>
                         </div>
                         <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                       
                     </div>
                     <div class="job-seeker">
                         <b class="text-uppercase mb-2">job-seeker</b>
                         <div class="job-seek-icon mb-3">
                            <div> <img src="images/health.png" alt=""/></div>
                            <div> <img src="images/lunch-plate.png" alt=""/></div>
                            <div> <img src="images/dental.png" alt=""/></div>
                            <div> <img src="images/cofee.png" alt=""/></div>
                            <div> <img src="images/cofee.png" alt=""/></div>
                         </div>
                         <div class="text-center">
                             <a href="#" class="reg-button">View JOB</a>
                         </div>
                     </div>
                 </div>
                           {/* <!-- pagination --> */}
                           <div class="pagination-wrap text-center mt-3">
                              <nav aria-label="...">
                                 <ul class="pagination m-auto d-inline-flex">
                                    <li class="page-item disabled">
                                       <span class="page-link"><img src="images/arrow-left.JPG" alt=""/></span>
                                    </li>
                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item active" aria-current="page">
                                       <span class="page-link">2</span>
                                    </li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item"><a class="page-link" href="#">...</a></li>
                                    <li class="page-item">
                                       <a class="page-link" href="#"><img src="images/arrow-right.JPG" alt=""/></a>
                                    </li>
                                 </ul>
                              </nav>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </main>
  </>
)};

AvailableJobs.propTypes = {};

AvailableJobs.defaultProps = {};

export default AvailableJobs;
