import React, { Component, PropTypes } from 'react';

class Stay_Connected extends React.Component 
{

      constructor(props) 
      {
            super(props);
           
      }
      componentDidMount()
      {
            
           
      }
      render() 
      {
        return (<>
       <section class="stay-connected my-4">
            <div class="container stay-connected my-5">
                <div class="row">
                    <div class="col-lg-7 m-auto">
                        <div class="row">
                            <div class="col-md-8 text-left">
                                <h2>Stay connected with your local opportunities…</h2>
                                <p>Download the <b>Hometown Opportunity app</b> and stay constanly connected with companies and job oppportunities in your area.
                                   
                                    Hometown Opportunity is available on the Apple App Store or on Google Play.
                                </p>
                                <div class="d-flex justify-content-start app-ic-home">
                                    <img src="images/apple-app-store.png" alt="" />
                                    <img src="images/google-play.png" alt="" class="ms-2" />
                                </div>
                            </div>
                            <div class="col-md-4 phone-home">
                                <img src="images/HTO-app-face.png" alt="" style={{"max-height": "360px"}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="stay-connect-mobile">
            <div class="title">
                <h2 class="text-center px-5">Stay connected with your local opportunities…</h2>
                <img src="images/HTO-app-face.png" class="w-100 p-5" alt="" />
                <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. </p>
                <p>Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
            </div>
            <div class="app-download text-center">
                <a href="#"> <img src="images/apple-app-store.png" class="py-2" alt="" /></a>
                <a href="#"><img src="images/google-play.png" class="py-2" alt="" /></a>

            </div>
        </section>
        </>)
      }
}
export default Stay_Connected;