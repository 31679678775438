import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams, useLocation, useHistory } from "react-router-dom";
import Hiring from "../Jobs/Hiring";
import Services from "../../services/services";
import $, { parseJSON } from 'jquery';
import moment from "moment";
import Quick_links from "../../utility/Quick_links";
import Pagination from "../../utility/Pagination/Pagination";
import DateObject from "react-date-object";
const ExpiredJobs = () => {
    const navigate = useNavigate();
    const [availableJobsList, setAvailableJobsList] = useState([]);
    const [expiredJobsList, setExpiredJobsList] = useState([]);
    const [path, setPath] = useState([]);

    const [searchText, setsearchText] = useState();
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 15; // Per page show the data pagination
    const Pagesize = 10;

    const [status, setStatus] = useState();
    const [title, setTitle] = useState();
    const [sortBy, setSortBy] = useState();
    const [sortDir, setSortDir] = useState();

    //var companyId = "24";
    var companyId = sessionStorage.getItem("logCompanyId");
    const siteId = process.env.React_App_SITE_ID;


    function getExpireyDateex(startdate) {
        if (startdate == "") {
            return "";
        }
        var daten = new DateObject(startdate);
        var daten1 = daten.add(45, "d").format("MM/DD/YYYY");
        return daten1;
    }
    const handlePageChange = (offset, triggerevent) => {
        // alert(offset);
        getExpired(companyId, 'expired', '', ITEMS_PER_PAGE, offset, 'postedDate', 'desc');
    };



    useEffect(() => {
        // const jsonData = {
        //     "expiredJobs": {
              
        //     }
        //   };

        // var jsondataformatted = JSON.stringify(jsonData);
        // sessionStorage.setItem('pagejsonObject', jsondataformatted);

        getExpired(companyId, 'expired', '', ITEMS_PER_PAGE, '1', 'postedDate', 'desc');


        const pathName = window.location.pathname;
        setPath(pathName);

    }, []);


    function getExpired(companyId, status, title, ITEMS_PER_PAGE, pageNumber, sortBy, sortDir) {
        // function getExpired(companyId,status,title,ITEMS_PER_PAGE,pageNumber,sortBy,sortDir){
        // var companyId = "24";
        // setStatus("expired");
        var title = ($("#jobTitleExpired").val()).trim();
        // var pageNumber = 1;
        // var sortBy = '';
        // var sortDir = '';

        Services.getCompanyJobExpiredCurrent(companyId, status, title, ITEMS_PER_PAGE, pageNumber, sortBy, sortDir, siteId).then((res) => {
            // totalElements

            setTotalItems(res.data.totalElements);

            settotalPages(Math.ceil(res.data.totalElements / ITEMS_PER_PAGE));
            // settotalPages(Math.ceil(res.data.totalElements / ITEMS_PER_PAGE));
            // setCurrentPage(res.data.number);
            setCurrentPage(pageNumber);

            // alert(res.data.totalElements);
            // var exampleItemss = [...Array(100).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
            // setExampleItems(exampleItemss);
            // setPageOfItems()
            setExpiredJobsList(res.data.content);
            //    var dVal = eval(res.data.content);
            //    console.log(dVal);
            //    console.log(typeof(dVal));
            //    console.log(dVal.length);
            //    console.log("142-->"+JSON.stringify(dVal));
            //    //console.log(res.data.length);
            //    if(typeof(dVal) == "object" &&dVal.data.length>0){

            //     console.log(JSON.stringify(res));
            //     setExpiredJobsList(dVal.data);

            //    }else{
            //     setExpiredJobsList([]);

            //    }  

            //navigate('/thankyou');
        });

    }



    return (
        <>


            <div class="job-item-wrap position-relative">
                {/* <!-- search --> */}
                {/* <form class=" search-bar mb-4 w-100"> */}
                <input class="form-control mb-3" type="search" id="jobTitleExpired" aria-label="Search" placeholder="Search by Job Title" onKeyPress={() => getExpired(companyId, 'expired', '', ITEMS_PER_PAGE, 1, '', '')} />
                <button class="border-0 p-0 dir-search" type="button"><img src="images/search-icon.JPG" alt="" onClick={() => getExpired(companyId, 'expired', '', ITEMS_PER_PAGE, 1, '', '')} /></button>
                {/* </form> */}

                {/* <!-- table --> */}
                <div class="current-job-tbl">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Posted</th>
                                <th scope="col">Title</th>
                                <th scope="col">Expire</th>
                                <th scope="col">Status</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                expiredJobsList.map((data, index) => (

                                    <tr>

                                        <td style={{ width: "120px" }}>{moment(data.createdDate).utcOffset('-0400').format("MM/DD/YYYY")}</td>

                                        <td>{data.jobTitle}</td>

                                        <td style={{ width: "125px" }}>{getExpireyDateex(data.createdDate)}</td>

                                        <td>Expired</td>

                                        <td><a href={`/companyCopyJob?jobId=${data.jobId}`} class="apply-button text-uppercase text-center">Repost</a></td>

                                    </tr>




                                ))}



                        </tbody>
                    </table>
                    <Pagination

                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}

                    />
                </div>
            </div>

        </>
    )
};

ExpiredJobs.propTypes = {};

ExpiredJobs.defaultProps = {};

export default ExpiredJobs;
