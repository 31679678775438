import React, { useEffect, useState } from 'react';

import { ErrorMessage } from "@hookform/error-message";
import { set, useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import StudentRegistration2 from "./student-registration2";
import StudentRegistration3 from "./student-registration3";
import Services from '../../services/services';
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup'
import Header from '../../layouts/header/header';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SunEditor, { buttonList } from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';

const StudentRegistrationSchoolInfo = () => {

  const [categories, setCategory] = useState([]);
  const [currentYearList, setCurrentYearList] = useState([]);
  const [schoolIds, setSchoolInData] = useState([]);
  const [educations, setEducation] = useState([]);
  const [collegeInfo, setCollegeInfo] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);


  const navigate = useNavigate();

  const studentRegId = sessionStorage.getItem("studentRegId");

  const getEducation = () => {
    Services.getEducation().then((res) => {
      console.log("getEducation data=" + JSON.stringify(res));
      var tempedu = res.data.content;
      //console.log(tempjobtypes);
      tempedu = tempedu.filter(function (elem) {
        return elem.name != 'PhD';
      });
      setEducation(tempedu);
    });
  }

  useEffect(() => {
    const jsonData = {
      "studentRegistrationSchoolInfo": {
        
      }
    };
    var jsondataformatted = JSON.stringify(jsonData);
    sessionStorage.setItem('pagejsonObject', jsondataformatted);

    if(studentRegId != null){
      getStudentProfile(studentRegId);
    }
    else{
      navigate('/StudentRegistrationPersonalInfo');
    }   

    $(".se-dialog-tabs").hide();
    $("#fileErrorMobile").hide();
    $(".load-wrap").hide();
    $("#alertFailMessage").hide();
    $("#successMessage").hide();
    $("#alertFailMessage").hide();
    $("#studentCareersValidMsg").hide();
    $("#coursesRelatedValidMsg").hide();
    $("#studentSkillsValidMsg").hide();
    $("#studentCoursesValidMsg").hide();
    $("#studentCommentsValidMsg").hide();
    $('#seekinginterestValidMsg').hide();
    $("#categoriesinterestedValidMsg").hide();
    $('#school_districtValidMsg').hide();
    $('#university_nameValidMsg').hide();
    $('#pursuing_degreeValidMsg').hide();
    $('#class_typeValidMsg').hide();
    $('#school_districtValidMsg_m').hide();
    $('#university_nameValidMsg_m').hide();
    $('#pursuingValidMsg_m').hide();
    $('#classTypeValidMsg_m').hide();
    $('#categoryvalidmsg_m').hide();
    $('#categoryvalidmsg').hide();
    
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
    
    getIndustryData();
    getCurrentYear();
    getSchoolnData();
    getEducation();
    getCollegeInfo();
    $('#college_div').hide();
    $('#college_divm').hide();
    $("#fileError").hide();
    $("#schoolIdOtherDiv").hide();
    $("#schoolIdOtherDiv_m").hide();
    $('#collegeIdOtherDiv').hide();
    $("#collegeIdOtherDiv_m").hide();
    $('#schoolIdOthermsg').hide();
    $('#collegeIdOthermsg').hide();
    $('#schoolIdOther_mmsg').hide();
    $('#collegeIdOther_mmsg').hide();
    $('#planedu').hide();
    $('#extensionvalidmsg').hide();
    $('#planedu_m').hide();
    $('#extensionvalidmsg_m').hide();
    setTimeout(() => {
      $('#pursuing_degree').val('3');
      $('#pursuing_m').val('3');
      $('input[id="inlineRadio2"]').prop('checked', true);
      $('input[id="inlineRadio2_m"]').prop('checked', true);
    }, 1000)

    $('#continueEduDiv').hide();
    $('#continueEduDiv_m').hide();
    $("#inlineRadio1, #inlineRadio2").click(function () {
      var selectedEdu = $('input[name="continueEducation"]:checked').val();
      // alert(selectedEdu);
      if (selectedEdu == "Y") {
        $("#continueEduDiv").show();
      } else if (selectedEdu == "N") {
        $('#collegeId').val('');
        $('#pursuingProgram').val('');
        $("#continueEduDiv").hide();
      }
    });
    $("#inlineRadio1_m, #inlineRadio2_m").click(function () {
      var selectedEdu = $('input[name="continueEducation_m"]:checked').val();
      // alert(selectedEdu);
      if (selectedEdu == "Y") {
        $("#continueEduDiv_m").show();
      } else if (selectedEdu == "N") {
        $('#collegeId_m').val('');
        $('#pursuingProgram_m').val('');
        $("#continueEduDiv_m").hide();
      }
    });
  }, []);

  const getStudentProfile = (studentId) => {
    Services.getpubStudentProfile(studentId).then((res) => {
      $("#class_type").val(res.data.classYear);
      $("#classType_m").val(res.data.classYear);

      var companyCatIn = res.data.categoriesOfExpertise;
      $("#education").val(res.data.educationExperiance);
      

      setTimeout(() => {
        $('#pursuing_degree').val(res.data.educationId);
        $('#pursuing_m').val(res.data.educationId);
        $('#collegeIdOther').val(res.data.nameOfCollegeUniversity);
        $('#schoolIdOther').val(res.data.schoolDistrictOther);
        $('#collegeIdOther_m').val(res.data.nameOfCollegeUniversity);
        $('#schoolIdOther_m').val(res.data.schoolDistrictOther);

        $("#class_type").val(res.data.classYear);
        $("#classType_m").val(res.data.classYear);
        

        setCategoriesSelected(res.data.categoriesOfExpertise);
  

        if (res.data.educationId == 3) {
          var selectedyearval = res.data.classYear;
          var currentyearval = new Date().getFullYear();

         // if ((selectedyearval == currentyearval) || (selectedyearval == currentyearval + 1)) {
            $('#planedu').show();
            $('#planedu_m').show();
            $("#continueEducation").val(res.data.highSchoolDiplomaExtContinueEduStatus);
            $("#continueEducation_m").val(res.data.highSchoolDiplomaExtContinueEduStatus);

            if (res.data.highSchoolDiplomaExtContinueEduStatus == "Y") {
              $('#inlineRadio1').prop("checked", true);
              $("#continueEduDiv").show();

              $('#inlineRadio1_m').prop("checked", true);
              $("#continueEduDiv_m").show();
            } else if (res.data.highSchoolDiplomaExtContinueEduStatus == "N") {
              $('#inlineRadio2').prop("checked", true);
              $("#continueEduDiv").hide();

              $('#inlineRadio2_m').prop("checked", true);
              $("#continueEduDiv_m").hide();
            }
            $("#pursuingProgram").val(res.data.highSchoolDiplomaExtPursuingProgram);
            $("#collegeId").val(res.data.highSchoolDiplomaExtNameOfInstitutionCollegeId);

            $("#pursuingProgram_m").val(res.data.highSchoolDiplomaExtPursuingProgram);
            $("#collegeId_m").val(res.data.highSchoolDiplomaExtNameOfInstitutionCollegeId);
         /* }
          else {
            $('#planedu').hide();
            $('#planedu_m').hide();
          } */
          $('#school_div').show();
          $('#college_div').hide();
          $("#school_district").val(res.data.schoolId);

          $('#schooldiv_m').show();
          $('#college_divm').hide();
          $("#district_m").val(res.data.schoolId);
          
          if (res.data.schoolId == '33') {
            $('#schoolIdOtherDiv').show();
            $('#schoolIdOtherDiv_m').show();
          }
          $('#university_name').val();
          $('#university_m').val();
        }
        else {
          $('#school_div').hide();
          $('#college_div').show();

          $('#schooldiv_m').hide();
          $('#college_divm').show();

          $("#school_district").val();
          $('#university_name').val(res.data.nameOfCollegeUniversityId);

          $("#district_m").val();
          $('#university_m').val(res.data.nameOfCollegeUniversityId);

          if (res.data.nameOfCollegeUniversityId == '22') {
            $('#collegeIdOtherDiv').show();
            $('#collegeIdOtherDiv_m').show();
          }
        } 

        for (var i = 0; i < companyCatIn.length; i++) {
          $('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
          $('input[name="category_m"][value="' + companyCatIn[i] + '"]').prop("checked", true);
        }
        var isCheck = true;

        var checkedValues = $("input[name='category']").val();
        if (!checkedValues) {
          isCheck = false;
          $("#categoryError").html("Category field is required");
        } else {
          isCheck = true;
          $("#categoryError").html("");
        }

        return isCheck;
      }, 1000);



    });
  }

  const getIndustryData = () => {

    Services.getIndustryData().then((res) => {

      console.log("Industry data=" + JSON.stringify(res.data));

      setCategory(res.data.content);

    });

  }
  const getCurrentYear = () => {
    var currentYear = new Date().getFullYear()
    var years = [];
    years.push(currentYear);
    for (var i = 1; i < 7; i++) {
      years.push(currentYear + i);
    }

    //const now = new Date().getUTCFullYear();
    //const years = Array(now - (now + 5)).fill('').map((v, idx) => now - idx);
    console.log("#41#41-->" + JSON.stringify(years));
    setCurrentYearList(years);
  }

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    criteriaMode: "all",
    mode: "onBlur"
    //resolver: yupResolver(formSchema)
  });
  
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });

  $('#class_type').change(function () {
    var classyr = $('#class_type').val();
    var currentyearval = new Date().getFullYear();

    //alert(currentyearval);
    /*if (($('#pursuing_degree').val() == 3) && ((classyr == currentyearval) || (classyr == (currentyearval + 1)))) {
      $('#planedu').show();
    }
    else {
      $('#planedu').hide();
    } */

    if ($('#pursuing_degree').val() == 3) {
      $('#planedu').show();
    }
    else {
      $('#planedu').hide();
    } 
  })

  $('#classType_m').change(function () {
    var classyr = $('#classType_m').val();
    var currentyearval = new Date().getFullYear();

    //alert(currentyearval);
    /*if (($('#pursuing_m').val() == 3) && ((classyr == currentyearval) || (classyr == (currentyearval + 1)))) {
      $('#planedu_m').show();
    }
    else {
      $('#planedu_m').hide();
    } */

    if ($('#pursuing_m').val() == 3) {
      $('#planedu_m').show();
    }
    else {
      $('#planedu_m').hide();
    } 
  })

  $('#pursuing_degree').change(function () {
    //alert($('#pursuing_degree').val());
    $('#school_districtValidMsg').hide();
    $('#university_nameValidMsg').hide();
    $('#schoolIdOther').val('');
    $('#collegeIdOther').val('');
    $('#school_district').val('');
    $('#schoolIdOtherDiv').hide();
    $('#university_name').val('');
    $('#collegeIdOtherDiv').hide();
    if ($('#pursuing_degree').val() == 3) {
      $('#school_div').show();
      $('#college_div').hide();
      $('#parent_authorization').show();
      $('#school_district').prop('disabled', false);
      $('#university_name').prop('disabled', true);
      $('#class_type').change();
    }
    else {
      $('#parent_authorization').hide();
      $('#planedu').hide();
      $('#school_div').hide();
      $('#college_div').show();
      $('#school_district').prop('disabled', true);
      $('#university_name').prop('disabled', false);
    }
  })

  $('#pursuing_m').change(function () {
    //alert($('#pursuing_degree').val());
    $('#school_districtValidMsg_m').hide();
    $('#university_nameValidMsg_m').hide();
    $('#schoolIdOther_m').val('');
    $('#collegeIdOther_m').val('');
    $('#district_m').val('');
    $('#schoolIdOtherDiv_m').hide();
    $('#university_m').val('');
    $('#collegeIdOtherDiv_m').hide();
    if ($('#pursuing_m').val() == 3) {
      $('#parent_authorizationm').show();
      $('#schooldiv_m').show();
      $('#college_divm').hide();
      $('#district_m').prop('disabled', false);
      $('#university_m').prop('disabled', true);
      $('#classType_m').change();
    }
    else {
      $('#parent_authorizationm').hide();
      $('#planedu_m').hide();
      $('#schooldiv_m').hide();
      $('#college_divm').show();
      $('#district_m').prop('disabled', true);
      $('#university_m').prop('disabled', false);
    }
  })

  const getCollegeInfo = () => {
    Services.getEnrolledCollege().then((res) => {
      //console.log("getEducation data=" + JSON.stringify(res.data));
      setCollegeInfo(res.data);
    });
  }

  const go_back = () => {
    navigate('/StudentRegistrationPersonalInfo');
  }
  const curr_date = Date.now();

  const submitValue = (data) => {
    var catofExpertiseArray = [];
   /* $.each($("input[name='category']:checked"), function () {
      catofExpertiseArray.push($(this).val());
    }); */

    var validateEditorTxt = 1;
/*
    if(catofExpertiseArray.length == 0){
      $('#categoryvalidmsg').show();
      $(window).scrollTop($('#categoryvalidmsg').position().top);
      validateEditorTxt = 0;
    }
    else{
      $('#categoryvalidmsg').hide();
    } */

    if ($('#pursuing_degree').val() == '') {
      $("#pursuing_degreeValidMsg").show();
      $(window).scrollTop($('#pursuing_degreeValidMsg').position().top);
      validateEditorTxt = 0;

    }
    else {
      $("#pursuing_degreeValidMsg").hide();
      if ($('#pursuing_degree').val() == 3) {
        if ($('#school_district').val() == '') {
          $("#school_districtValidMsg").show();
          $(window).scrollTop($('#school_districtValidMsg').position().top);
          validateEditorTxt = 0;
        } else {
          $("#school_districtValidMsg").hide();
        }

        if ($('#school_district').val() == 33 && $('#schoolIdOther').val() == '') {
          $("#schoolIdOthermsg").show();
          $(window).scrollTop($('#schoolIdOthermsg').position().top);
          validateEditorTxt = 0;
        } else {
          $("#schoolIdOthermsg").hide();
        }

        var classyr = $('#class_type').val();
        //alert(classyr);
        var currentyearval = new Date().getFullYear();
        var selectedEduction = $('input[name="continueEducation"]:checked').val();
        /*if (((classyr == currentyearval) || (classyr == (currentyearval + 1))) && selectedEduction == 'Y' && ($('#collegeId').val() == '')) { */
        if (selectedEduction == 'Y' && ($('#collegeId').val() == '')) {
          $("#extensionvalidmsg").show();
          $(window).scrollTop($('#extensionvalidmsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#extensionvalidmsg").hide();
        }
      }
      else {
        console.log('here');
        if ($('#university_name').val() == '') {
          $("#university_nameValidMsg").show();
          $(window).scrollTop($('#university_nameValidMsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#university_nameValidMsg").hide();
        }

        if ($('#university_name').val() == 22 && $('#collegeIdOther').val() == '') {
          $("#collegeIdOthermsg").show();
          $(window).scrollTop($('#collegeIdOthermsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#collegeIdOthermsg").hide();
        }
      }
    }

    if ($('#class_type').val() == '') {
      $("#class_typeValidMsg").show();
      $(window).scrollTop($('#class_typeValidMsg').position().top);
      validateEditorTxt = 0;

    }
    else {
      $("#class_typeValidMsg").hide();
    }

    if (validateEditorTxt == 0) {
      return false;
    }

    

    var selectedEduction = $('input[name="continueEducation"]:checked').val();
    var extclgid = $("#collegeId").val();
    //phone_val = "1234567899";
    const requestObject = {
      "studentId": studentRegId,
      //step1 fields
      "firstName": "",
      "lastName": "",
      "email": "",
      "password": "",
      "phone": "",
      //step2 fields
      "educationId": $('#pursuing_degree').val(),
      "classYear": $("#class_type").val(),
      "schoolId": $("#school_district").val(),
      "nameOfCollegeUniversity": $('#collegeIdOther').val(),
      "nameOfCollegeUniversityId": $('#university_name').val(),
      "schoolDistrictOther": $('#schoolIdOther').val(),
      "highSchoolDiplomaExtContinueEduStatus": selectedEduction,
      "highSchoolDiplomaExtNameOfInstitutionCollegeId": extclgid,
      "highSchoolDiplomaExtPursuingProgram": $('#pursuingProgram').val(),
      
      "studentInterestedIn": [],
      "schoolName": null,
      //step3 fields
      "categoriesOfExpertise": [],
      "seekingEmploymentTypes": [],
      "seeking": null,
      "reasonForSelectCareers": "", 
      "experiance": "",
      //step4 fields
      "comments": "",
      "fileName": null,
      "fileExtension": null,
      "pdfByString": null,
      "resumeUrl": null,
      "resumeFilePath": null,

      "dateUpdated": curr_date,
      "dateCreated": curr_date,
      "siteId": process.env.React_App_SITE_ID,
      "helpPrepare": "yes",
      "status": "published",
      "interested": null,
      "events": null,
      "authorization": "",
      "userId": null,
      "countyId": null,
      "isStudent": true,
      "updatedOn": null,
      "securityRoleName": null,
      "course": "",
      "studentTalentConnectionForumIn": [],
      "totalElement": null,
      "searchText": null,
      "logo": null,
      "profileS3Pic": null,
      "partiallyRegistered":"Y",
      "loggedInUser":"No"
  }
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "Bearer "+accessTokenIn
        //'Authorization': accessTokenIn
      },
      body: JSON.stringify(requestObject)
    };
    $(".load-wrap").show();
    Services.updateStudentProfile(requestOptions).then((res) => {
      $(".load-wrap").hide();
      console.log(JSON.stringify(res));
      if (res.success == "false" || res.success == false) {
        $("#alertFailMessage").html(res.message);
        $("#alertFailMessage").fadeTo(2000, 1000).slideUp(1000, function () {

          $("#alertFailMessage").slideUp(1000);
        });

      } else {
        $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#successMessage").slideUp(1000);
        });
        navigate('/studentRegistrationFieldsofInterest');
      }
    });

  }

  const submitValueStep2 = (data) => {
    /*var catofExpertiseArray = [];
    $.each($("input[name='category_m']:checked"), function () {
      catofExpertiseArray.push($(this).val());
    }); */

    var validateEditorTxt = 1;
    if ($('#classType_m').val() == '') {
      $("#classTypeValidMsg_m").show();
      $(window).scrollTop($('#classTypeValidMsg_m').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#classTypeValidMsg_m").hide();
    }
/*
    if(catofExpertiseArray.length == 0){
      $('#categoryvalidmsg_m').show();
      $(window).scrollTop($('#categoryvalidmsg_m').position().top);
      validateEditorTxt = 0;
    }
    else{
      $('#categoryvalidmsg_m').hide();
    } */

    
    if ($('#pursuing_m').val() == '') {
      $("#pursuingValidMsg_m").show();
      $(window).scrollTop($('#pursuingValidMsg_m').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#pursuingValidMsg_m").hide();
      if ($('#pursuing_m').val() == 3) {
        if ($('#district_m').val() == '') {
          $("#school_districtValidMsg_m").show();
          $(window).scrollTop($('#school_districtValidMsg_m').position().top);
          validateEditorTxt = 0;
        } else {
          $("#school_districtValidMsg_m").hide();
        }

        if ($('#district_m').val() == 33 && $('#schoolIdOther_m').val() == '') {
          $("#schoolIdOther_mmsg").show();
          $(window).scrollTop($('#schoolIdOther_mmsg').position().top);
          validateEditorTxt = 0;
        } else {
          $("#schoolIdOther_mmsg").hide();
        }

        var classyr = $('#classType_m').val();
        var currentyearval = new Date().getFullYear();
        var selectedEduction = $('input[name="continueEducation_m"]:checked').val();
        /*if (((classyr == currentyearval) || (classyr == (currentyearval + 1))) && selectedEduction == 'Y' && ($('#collegeId_m').val() == '')) { */
        if (selectedEduction == 'Y' && ($('#collegeId_m').val() == '')) {
          $("#extensionvalidmsg_m").show();
          $(window).scrollTop($('#extensionvalidmsg_m').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#extensionvalidmsg_m").hide();
        }
      }
      else {
        console.log('here');
        if ($('#university_m').val() == '') {
          $("#university_nameValidMsg_m").show();
          $(window).scrollTop($('#university_nameValidMsg_m').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#university_nameValidMsg_m").hide();
        }

        if ($('#university_m').val() == 22 && $('#collegeIdOther_m').val() == '') {
          $("#collegeIdOther_mmsg").show();
          $(window).scrollTop($('#collegeIdOther_mmsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#collegeIdOther_mmsg").hide();
        }
      }
    }

    if (validateEditorTxt == 0) {
      return false;
    }
    var selectedEduction = $('input[name="continueEducation_m"]:checked').val();
    var extclgid = $("#university_name").val();
    //phone_val = "1234567899";
    const requestObject = {
      "studentId": studentRegId,
      //step1 fields
      "firstName": "",
      "lastName": "",
      "email": "",
      "password": "",
      "phone": "",
      //step2 fields
      "educationId": $('#pursuing_m').val(),
      "classYear": $("#classType_m").val(),
      "schoolId": $("#district_m").val(),
      "nameOfCollegeUniversity": $('#collegeIdOther_m').val(),
      "nameOfCollegeUniversityId": $('#university_m').val(),
      "schoolDistrictOther": $('#schoolIdOther_m').val(),
      "highSchoolDiplomaExtContinueEduStatus": selectedEduction,
      "highSchoolDiplomaExtNameOfInstitutionCollegeId": extclgid,
      "highSchoolDiplomaExtPursuingProgram": $('#pursuingProgram_m').val(),
      "studentInterestedIn": [],
      "schoolName": null,
      
      //step3 fields
      "categoriesOfExpertise": [],
      "seekingEmploymentTypes": [],
      "seeking": null,
      "reasonForSelectCareers": "", 
      "experiance": "",
      //step4 fields
      "comments": "",
      "fileName": null,
      "fileExtension": null,
      "pdfByString": null,
      "resumeUrl": null,
      "resumeFilePath": null,

      "dateUpdated": curr_date,
      "dateCreated": curr_date,
      "siteId": process.env.React_App_SITE_ID,
      "helpPrepare": "yes",
      "status": "published",
      "interested": null,
      "events": null,
      "authorization": "",
      "userId": null,
      "countyId": null,
      "isStudent": true,
      "updatedOn": null,
      "securityRoleName": null,
      "course": "",
      "studentTalentConnectionForumIn": [],
      "totalElement": null,
      "searchText": null,
      "logo": null,
      "profileS3Pic": null,
      "partiallyRegistered":"Y",
      "loggedInUser":"No"
  }
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "Bearer "+accessTokenIn
        //'Authorization': accessTokenIn
      },
      body: JSON.stringify(requestObject)
    };
    $(".load-wrap").show();
    Services.updateStudentProfile(requestOptions).then((res) => {
      $(".load-wrap").hide();
      console.log(JSON.stringify(res));
      if (res.success == "false" || res.success == false) {
        $("#alertFailMessage").html(res.message);
        $("#alertFailMessage").fadeTo(2000, 1000).slideUp(1000, function () {

          $("#alertFailMessage").slideUp(1000);
        });

      } else {
        $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#successMessage").slideUp(1000);
        });
        navigate('/studentRegistrationFieldsofInterest');
      }
    });

  }

  
  const getSchoolnData = () => {
    //alert("14");
    const requestObject = {
      "siteId": process.env.React_App_SITE_ID,
      "pageNumber": 1,
      "pageSize": 150
    }

    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
       //  "origin":"http://career-sites-uat-hto.s3-website.us-east-2.amazonaws.com"
        //'Authorization': "Bearer "+accessTokenIn
        //'Authorization': accessTokenIn
      },
      body: JSON.stringify(requestObject)
    };

    Services.getSchoolList(requestOptions).then((res) => {
      console.log("############36");
      console.log(JSON.stringify(res))
      if (res) {
        console.log(JSON.stringify(res.data));
        var contAr = res.content;
        //setProfileList(res.content);
        if (res) {
          setSchoolInData(res);
        }
      }
    });


  }
  function handleChangeSchool(event) {
    //alert("teste")
    //alert(event.target.value);
    var sch = event.target.value;
    $('#schoolIdOther').val('');
    $("#schoolIdOthermsg").hide();
    //console.log(event.target.value);
    //var sch = $("#schoolId'").val();
    if (sch == "33") {
      $("#schoolIdOtherDiv").show();
    } else {
      $("#schoolIdOtherDiv").hide();
    }
  }

  function handleChangeSchool_m(event) {
    //alert("teste")
    //alert(event.target.value);
    var sch_m = event.target.value;
    $('#schoolIdOther_m').val('');
    //console.log(event.target.value);
    //var sch = $("#schoolId'").val();
    if (sch_m == "33") {
      $("#schoolIdOtherDiv_m").show();
    } else {
      $("#schoolIdOtherDiv_m").hide();
    }
  }

  function handleChangeCollege(event) {
    //alert("teste")
    //alert(event.target.value);
    var clg = event.target.value;
    $('#collegeIdOther').val('');
    //console.log(event.target.value);
    //var sch = $("#schoolId'").val();
    if (clg == "22") {
      $("#collegeIdOtherDiv").show();
    } else {
      $("#collegeIdOtherDiv").hide();
    }
  }

  function handleChangeCollege_m(event) {
    //alert("teste")
    //alert(event.target.value);
    var clg_m = event.target.value;
    $('#collegeIdOther_m').val('');
    //console.log(event.target.value);
    //var sch = $("#schoolId'").val();
    if (clg_m == "22") {
      $("#collegeIdOtherDiv_m").show();
    } else {
      $("#collegeIdOtherDiv_m").hide();
    }
  }

  return (
    <>
      <main class="empr-directory-main cd-desktop">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item headtitle "><a href="#">Student Registration</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">
          <div class="container">
            <div class="row">
              <div class="col-12 ">
                <form onSubmit={handleSubmit(submitValue)}>
                  <div class="white-bg px-5 pt-4 pb-4">
                  <div class="row mb-0">
                      <div class="col-lg-12 m-auto">
                          <div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                              <h5 className='mb-0'>SCHOOL INFORMATION</h5>
                              <div class="student-reg-step">STEP 2/4</div>
                            </div>
                      </div>
                    </div>

                    <div class="row mt-4 resume-wrap">

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">I am Currently Pursuing <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" id="pursuing_degree">
                          <option selected="" value=''>Select</option>
                          {
                            educations.map((data, index) => (
                              <option value={data.educationId}>{data.name}</option>
                            ))}
                        </select>
                        {errors.pursuing_degree && errors.pursuing_degree.type === "required" && <div class="error">This Field is required</div>}
                        <div id="pursuing_degreeValidMsg" class="error">This Field is required.</div>
                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">GRADUATING YEAR <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" id="class_type">
                          <option selected="" value=''>Select</option>
                          {
                            currentYearList.map((data, index) => (
                              <option value={data}>{data}</option>
                            ))}
                        </select>
                        {errors.class_type && errors.class_type.type === "required" && <div class="error">Class type is required</div>}
                        <div id="class_typeValidMsg" class="error">This Field is required.</div>
                      </div>


                      <div class="col-lg-6 mb-5" id="school_div">
                        <label for="firstName" class="form-label">school district<span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" id="school_district" onChange={handleChangeSchool}>
                          <option selected="" value=''>Select</option>
                          {
                            schoolIds.map((data, index) => (
                              <option value={data.schoolId}>{data.name}</option>
                            ))
                          }
                          {/*<option value="0" >Other</option> */}
                        </select>
                        <div id="school_districtValidMsg" class="error">School District is required.</div>
                      </div>

                      <div class="col-md-6 " id="schoolIdOtherDiv">
                        <div class="d-flex ">
                          <label for="schoolIdOther" class="form-label mr-2">Name of the Institution <span class="red-text">*</span></label>
                        </div>
                        <input type="text" class="form-control " id="schoolIdOther" aria-describedby="emailHelp" placeholder="Other" />
                        <div id="schoolIdOthermsg" class="error">Name of the Institution is required.</div>
                      </div>


                      <div class="col-lg-6 mb-5" id="college_div">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">College / University <span class="red-text">*</span></label></div>
                        <select class="form-select" id="university_name" onChange={handleChangeCollege}>
                          <option value="">Select College</option>
                          {
                            collegeInfo.map((data, index) => (
                              <option value={data.enrolledCollegeId}>{data.name}</option>
                            ))}
                        </select>
                        <div id="university_nameValidMsg" class="error">College / Universities is required.</div>
                      </div>



                      <div class="col-md-6 " id="collegeIdOtherDiv">
                        <div class="d-flex ">
                          <label for="collegeIdOther" class="form-label mr-2">Name of the Institution <span class="red-text">*</span></label>
                        </div>
                        <input type="text" class="form-control " id="collegeIdOther" aria-describedby="emailHelp" placeholder="Other" />
                        <div id="collegeIdOthermsg" class="error">Name of the Institution is required.</div>
                      </div>


                      <div class="col-lg-12 mb-4" id="planedu">

                        <div class="d-flex">
                          <label for="education" class="form-label mr-2">Are you planning to go to college after high school? <span class="red-text">*</span></label>
                        </div>

                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="continueEducation" id="inlineRadio1" value="Y" style={{ borderRadius: "30px" }} />
                          <label class="form-check-label" for="inlineRadio1">YES</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="continueEducation" id="inlineRadio2" value="N" />
                          <label class="form-check-label" for="inlineRadio2">NO</label>
                        </div>

                        <div className="row" id="continueEduDiv">
                          <div className="col-md-6 mt-4">
                            <div class="d-flex"><label for="lookingFor" class="form-label mr-2">Name of The Institution <span class="red-text">*</span></label></div>
                            <select class="form-select" id="collegeId">
                              <option value="">Select College</option>
                              <option value="0">I Don't know yet</option>
                              {
                                collegeInfo.map((data, index) => (
                                  <option value={data.enrolledCollegeId}>{data.name}</option>
                                ))}
                            </select>
                            <div id="extensionvalidmsg" class="error">Name of the Institution is required.</div>
                          </div>

                          <div className="col-md-6 mt-4">
                            <div class="d-flex"><label for="pursuingProgram" class="form-label mr-2">Pursuing Program</label></div>
                            <input type="text" class="form-control " id="pursuingProgram" aria-describedby="emailHelp" placeholder="Program Name" />
                          </div>
                        </div>

                      </div>
{/*
                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">FIELDS I'M INTERESTED IN<span class="red-text">*</span></label></div>
                        <small>(Select up to three)</small>

                        <div >
                          <div class="category-check-itm">
                            <ul class="category-check">
                              {
                                categories.map((data, index) => (


                                  <li><input class="form-check-input" type="checkbox" name="category" value={data.catId} id="defaultCheck1" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                ))}

                            </ul>
                          </div>
                          {errors.category && errors.category.type === "required" && <div class="error">Please check atleast one category</div>}
                          <div id="categoryvalidmsg" class="error">Please check atleast one category</div>
                        </div>

                      </div> */}

                      <div class="col-lg-12 mb-4">
                        <div class="d-flex justify-content-end align-items-end">          
                          <div>
                            <button type="submit" class="reg-button" >Next</button>
                          </div>
                        </div>

                      </div>

                      <div id="successMessage" class="alert">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <img src="/images/success-alert.png" alt="" />
                            <strong class="mx-2">Success!</strong> Student school information submitted Successfully.
                          </div>
                          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        </div>
                      </div>

                      <div id='alertFailMessage' class="error"></div>



                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
          </div>
        </section>
      </main>

      <main class="empr-directory-main cd-mobile">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item headtitle "><a href="#">Student Registration</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
   
                {/* <!-- info typography Step 1--> */}
                <section class="company-detail-wrap py-5" id="step1">
          <div class="container">
            <div class="row">
              <div class="col-12 ">
                <form onSubmit={handleSubmit2(submitValueStep2)}>
                  <div class="white-bg px-5 pt-4 pb-4">
                  <div class="row mb-0">
                      <div class="col-lg-12 m-auto">
                          <div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                              <h5 className='mb-0'>SCHOOL INFORMATION</h5>
                              <div class="student-reg-step">STEP 2/4</div>
                            </div>
                      </div>
                    </div>
          
                    <div class="row mt-4 resume-wrap">

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">I am Currently Pursuing <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" id="pursuing_m" >
                          <option selected="" value=''>Select</option>
                          {
                            educations.map((data, index) => (
                              <option value={data.educationId}>{data.name}</option>
                            ))}
                        </select>
                        <div id="pursuingValidMsg_m" class="error">This Field is required.</div>
                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">GRADUATING YEAR <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" id="classType_m">
                          <option selected="" value=''>Select</option>
                          {
                            currentYearList.map((data, index) => (
                              <option value={data}>{data}</option>
                            ))}
                        </select>
                        {errors2.classType_m && errors2.classType_m.type === "required" && <div class="error">Class type is required</div>}
                        <div id="classTypeValidMsg_m" class="error">This Field is required.</div>
                      </div>

                      <div class="col-lg-6 mb-5" id="schooldiv_m">
                        <label for="firstName" class="form-label">school district <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" id="district_m" onChange={handleChangeSchool_m}>
                          <option selected="" value=''>Select</option>
                          {
                            schoolIds.map((data, index) => (
                              <option value={data.schoolId}>{data.name}</option>
                            ))
                          }
                         {/* <option value="0" >Other</option> */}
                        </select>
                        <div id="school_districtValidMsg_m" class="error">School District is required.</div>
                      </div>

                      <div class="col-md-6 " id="schoolIdOtherDiv_m">
                        <div class="d-flex ">
                          <label for="schoolIdOther" class="form-label mr-2">Name of the Institution <span class="red-text">*</span></label>
                        </div>
                        <input type="text" class="form-control " id="schoolIdOther_m" aria-describedby="emailHelp" placeholder="Other" />
                        <div id="schoolIdOther_mmsg" class="error">Name of the Institution is required.</div>
                      </div>

                      <div class="col-lg-6 mb-5" id="college_divm">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">College / University <span class="red-text">*</span></label></div>
                        <select class="form-select" id="university_m" onChange={handleChangeCollege_m}>
                          <option value="">Select College</option>
                          {
                            collegeInfo.map((data, index) => (
                              <option value={data.enrolledCollegeId}>{data.name}</option>
                            ))}
                        </select>
                        <div id="university_nameValidMsg_m" class="error">College / Universities is required.</div>
                      </div>

                      <div class="col-md-6 " id="collegeIdOtherDiv_m">
                        <div class="d-flex ">
                          <label for="collegeIdOther" class="form-label mr-2">Name of the Institution <span class="red-text">*</span></label>
                        </div>
                        <input type="text" class="form-control " id="collegeIdOther_m" aria-describedby="emailHelp" placeholder="Other" />
                        <div id="collegeIdOther_mmsg" class="error">Name of the Institution is required.</div>
                      </div>

                      <div class="col-lg-12 mb-4" id="planedu_m">

                        <div class="d-flex">
                          <label for="education" class="form-label mr-2">Are you planning to go to college after high school? <span class="red-text">*</span></label>
                        </div>

                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="continueEducation_m" id="inlineRadio1_m" value="Y" style={{ borderRadius: "30px" }} />
                          <label class="form-check-label" for="inlineRadio1_m">YES</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="continueEducation_m" id="inlineRadio2_m" value="N" />
                          <label class="form-check-label" for="inlineRadio2_m">NO</label>
                        </div>

                        <div className="row" id="continueEduDiv_m">
                          <div className="col-md-6 mt-4">
                            <div class="d-flex"><label for="lookingFor" class="form-label mr-2">Name of The Institution <span class="red-text">*</span></label></div>
                            <select class="form-select" id="collegeId_m">
                              <option value="">Select College</option>
                              <option value="0">I Don't know yet</option>
                              {
                                collegeInfo.map((data, index) => (
                                  <option value={data.enrolledCollegeId}>{data.name}</option>
                                ))}
                            </select>
                            <div id="extensionvalidmsg_m" class="error">Name of the Institution is required.</div>
                          </div>

                          <div className="col-md-6 mt-4">
                            <div class="d-flex"><label for="pursuingProgram_m" class="form-label mr-2">Pursuing Program</label></div>
                            <input type="text" class="form-control " id="pursuingProgram_m" aria-describedby="emailHelp" placeholder="Program Name" />
                          </div>
                        </div>

                      </div>
{/*
                      <div class="col-lg-12 mb-5">
                        <div><label for="firstName" class="form-label mr-2" >FIELDS I'M INTERESTED IN<span class="red-text">*</span></label> </div>
                        <small>(Select up to three)</small>
                        <div class="category-check-itm">
                        <ul>
                              {
                                categories.map((data, index) => (
                                  <li><input class="form-check-input category_mcls" type="checkbox" name="category_m" value={data.catId} />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                                ))}
                            </ul>
                        </div>
                        {errors2.category_m && errors2.category_m.type === "required" && <div class="error">Category is required</div>}
                        <div id="categoryvalidmsg_m" class="error">Category is required.</div>
                      </div> */}

                      <div class="mt-4 mb-4">
                        {/* <a href="#" class="reg-button">Next &#62;&#62;</a> */}
                        <button type="submit" class="reg-button"  >Next &#62;&#62;</button>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        
        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
          </div>
        </section>


      </main>

    
    </>
  )
};

StudentRegistrationSchoolInfo.propTypes = {};

StudentRegistrationSchoolInfo.defaultProps = {};

export default StudentRegistrationSchoolInfo;
