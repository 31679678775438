import React,{history,useEffect, useState, useMemo } from "react";
import Quick_links from "../../utility/Quick_links";
import Hiring from "../Jobs/Hiring";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import services from "../../services/services";
import { useNavigate, useParams } from "react-router-dom";

const PublicProfileView = () => {
    const navigate = useNavigate();
    const [profileDetails, setProfileDetails] = useState([]);
    const [googleMapAddress, setGoogleMapAddress] = useState('');
    //const companyId=24;
    const companyId=sessionStorage.getItem("logCompanyId");
    const staticData = require('../../constants/'+process.env.React_App_Static_Folder+'/static-data.json');

    useEffect(() => {
        const jsonData = {
            "publicProfileView": {
              
            }
          };
          var jsondataformatted = JSON.stringify(jsonData);
          sessionStorage.setItem('pagejsonObject', jsondataformatted);

        if(companyId == null || companyId == "null"){
            //navigate('/login');
            navigate('/Home');
          }
        getCompanyProfile();

    }, []);

    const getCompanyProfile = () => {
       services.getCompanyProfile(companyId).then((res) => {

        setProfileDetails(res.data);

        var address = res.data.address1;
        var city = res.data.city;
         var state = res.data.state;
        var zip = res.data.zip;
        var urlcode = address+'+'+city+'+'+state+'+'+zip;
       var urlcode2 = "https://www.google.com/maps/embed/v1/search?key=AIzaSyAIdz2pY_NwIEnW6T4kxtHIMpMizrVdePg&q="+urlcode;

        setGoogleMapAddress(urlcode2);
        // alert(profileDetails.name);
       });
    }


  return(
  <>
      <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="/companyJobs">Company Dashboard</a>  </li>
                                <li class="breadcrumb-item headtitle "><a href="#"> Employer Page</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">
                                {/* <!-- left side --> */}
                                <Quick_links/>

                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">

                                <div class="banner-profile">
                                        {/* <img src="images/company-banner.jpg" alt=""/> */}
                                        <img src={profileDetails.bannerUrl?profileDetails.bannerUrl:staticData.default_logo } alt=""/>
                                    </div>

                                    <div class="grid-item p-3 mb-4">
                                        <div class="grid-head-company">
                                            <div class="grid-img-com mr-2">
                                                {/* <img src="images/career-logo.JPG" class="w-100" alt=""/> */}
                                                <img src={profileDetails.logoUrl?profileDetails.logoUrl:staticData.default_logo} class="w-100" alt=""/>
                                            </div>
                                            <div class="grid-title-wrap">
                                                {/* <h4 class="margin-bottom-0">iTone Aide</h4>
                                                <address>
                                                    Ohio Hi Point Career Center <br/>
                                                    2280 St Rt 540 <br/>
                                                    Bellefontaine, OH, 43311 <br/>
                                                    8765479779
                                                </address> */}
                                                <h4 class="margin-bottom-0">{profileDetails.name}</h4>
                                                <address>
                                                {profileDetails.address1} <br/>
                                                {/* {profileDetails.address2} <br/> */}
                                                    {profileDetails.city}, {profileDetails.state}, {profileDetails.zip} <br/>
                                                    {profileDetails.phone}
                                                </address>

                                            </div>
                                        </div>

                                    </div>

                                    {/* <div class="mb-4">
                                        <p>3-Way Machine and Tool Co. is a full service machine shop located in Maria Stein Ohio.</p>

                                        <ul class="mb-4">
                                            <b style={{marginLeft: "-18px",minHeight:"30px",display: "block"}}>We specialize in:</b>
                                            <li> Low/Mid Volume production runs</li>
                                            <li> Tool/Die/Fixture design and assembly</li>
                                            <li>Prototyping and "1 off " parts</li>
                                            <li>CNC Milling & Turning</li>
                                            <li>Surface Grinding</li>
                                            <li>Welding/Fabrication</li>
                                            <li> Part duplication and repair</li>
                                            <li> Stamping</li>
                                        </ul>
                                    </div> */}

                                <div class="mb-4">


                                {/* {profileDetails.description}    */}

                                <div className="cp-image" dangerouslySetInnerHTML={{ __html: profileDetails.description }} />
                                </div>



                                    <div class="row">
                                        <div class="col-lg-12 mb-4">
                                            <label for="firstName" class="form-label">Route Map</label>
                                            <div class="mb-4">
                                                <div id="map" >

                                                <iframe src={googleMapAddress} allowfullscreen    frameborder='0' class="w-100">
                                            </iframe>

                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
        {/* <Hiring/> */}
    </main>
  </>
)};

PublicProfileView.propTypes = {};

PublicProfileView.defaultProps = {};

export default PublicProfileView;
