import React, { history, useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Recent_Jobs from "./Jobs/Recent_Jobs";
import Services from "../services/services";
import Hiring from "./Jobs/Hiring";

import Pagination3 from "../utility/Pagination/Pagination";
import moment from "moment";
const Events = () => {

    const [eventList, setEventList] = useState([]);
    const siteId = process.env.React_App_SITE_ID;

    const ITEMS_PER_PAGE = 6; // Per page show the data pagination
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const companyIdChkLogin=sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin=sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");
    const chKRoleAr=sessionStorage.getItem("roleAr");

    const getEvents = () => {
        Services.getEventsbySiteId(siteId).then((res) => {
          console.log("Industry data=" + JSON.stringify(res.data));
          if(res.data){
            setEventList(res.data);
          }
        });
      }
      const handlePageChange3 = (offset,triggerevent) => {
        filterEvents(ITEMS_PER_PAGE,offset)
      };
      const filterEvents = (ITEMS_PER_PAGE,pageNumber) => {
                var siteIdIn = process.env.React_App_SITE_ID;//"2";
                Services.getEventsbySiteId(siteIdIn,ITEMS_PER_PAGE,pageNumber).then((res) =>
                {
                //console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@events widg 113");
                    if(res.data.content){
                    console.log(JSON.stringify(res.data));
                        $(".paginationContent").show();
                        setTotalItems(res.data.totalElements);
                        settotalPages(Math.ceil(res.data.totalElements / ITEMS_PER_PAGE));
                        setCurrentPage(pageNumber);

                        var contAr = res.data.content;
                        //setProfileList(res.content);
                        if(res.data.content){
                        setEventList(res.data.content);
                        }
                }
                });
        }

    useEffect(() => {
        const jsonData = {
            "events": {
              
            }
          };
          var jsondataformatted = JSON.stringify(jsonData);
          sessionStorage.setItem('pagejsonObject', jsondataformatted);

       // getEvents();
       filterEvents(ITEMS_PER_PAGE,'1');
    }, []);

    return(<>
        <main  class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
           <div class="container">
              <div class="row">
                 <div class="col-lg-12">
                 <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                       <ol class="breadcrumb">


                       {(() => {



                        if( jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null"){
                            return (<><li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li>
                            <li class="breadcrumb-item headtitle "><a href="#">Events Calendar</a></li></>);
                        }

                        if( studentIdChkLogin != null && studentIdChkLogin != "null"){
                            return (<> <li class="breadcrumb-item headtitle "><a href="/studentDashboard">Student Dashboard</a> </li>
                            <li class="breadcrumb-item headtitle "><a href="#">Events Calendar</a></li></>);
                        }

                        if( chKRoleAr == "schooladmin" || chKRoleAr == "schoolsuper"){
                            return (<><li class="breadcrumb-item headtitle"> <a href="/schoolAdminDashboard">School Admin</a> </li>
                            <li class="breadcrumb-item headtitle "><a href="#">Events Calendar</a></li></>);
                            }

                        })()}

                          {/* <li class="breadcrumb-item headtitle "><a href="#">Events Calendar</a></li> */}
                       </ol>
                    </nav>
                 </div>
              </div>
           </div>
        </section>

        <section class="company-detail-wrap py-5 ">
           <div class="container">
           <div class="row">
                    <div class="col-lg-8">
                        <div class="py-5 px-5 white-bg" style={{paddingBottom:"20px !important"}}>
                            <div>
                                <h4 class="pb-3 border-bottom text-center">Upcoming Events</h4>


                                {
                                eventList.map((data, index) => (

                                <div class="d-flex pb-4 mt-4 border-bottom">
                                    <div class="logo-org justify-content-start">
                                                    {(data.companyLogoUrl == null || data.companyLogoUrl == "") ? <img src="https://snv3-dev-fs.s3.us-east-2.amazonaws.com/HometownMigration/uploads/default-logo.png" alt="" className="m-auto" /> : <img src={data.companyLogoUrl} alt="" className="m-auto" />}
                                                    {/* <img src={companyProfile.logoUrl} alt="" /> */}
                                                </div>
                                                <div class="right-info px-3">
                                    <a href={'/eventDetails?eventId='+data.eventId}><h4 class="mb-0">{data.eventTitle}</h4></a>
                                    <span className="mb-2 d-block"><strong>Sponsoring Company: <a href={'/companyDetails?companyId='+data.companyId}>{data.companyName}</a></strong></span>
                                    <span className="mb-2 d-block"><strong>Venue: {data.eventAddress}
                                                {data.eventAddress!=''? ', ' : ''}
                                                {data.eventCity}
                                                {data.eventCity!=''? ', ' : ''}
                                                {data.eventState}
                                                {data.eventState!=''? ', ' : ''}
                                                {data.eventZip}</strong></span>
                                                <span className="mb-4 d-block"><strong> {moment(data.eventDate).format("MM/DD/YYYY")}</strong></span>
                                    {/* <p class="mt-3">{data.eventDescription}</p> */}

                                    {/* <p class="mt-3">{`${data.eventDescription.substring(0, 200)}...`}<a href={`/eventDetails?eventId=`+data.eventId}></a></p> */}
                                    {/* <p dangerouslySetInnerHTML={{ __html: data.eventDescription.substring(0, 200)  }} />     */}

                                    <span class="mb-4 mr-2"><a href={'/eventDetails?eventId='+data.eventId} class="reg-button">View Details</a></span>
                                    </div>
                                   {/* <span class="mb-2"><a href={data.eventExternalLink} class="reg-button" target="_blank">Learn more</a></span> */}
                                </div>
                                ))}

                            <div class="pagination-wrap text-center mt-3 paginationContent">
                                        <Pagination3
                                    total={totalItems}
                                    itemsPerPage={ITEMS_PER_PAGE}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange3}/>

                            </div>


                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                    <Recent_Jobs/>
                        {/* <div class="white-bg mb-4">
                            <img src="/images/ad-1.png" class="w-100" alt=""/>
                        </div>
                        <div class="white-bg mb-4">
                            <img src="/images/ad-2.png" class="w-100" alt=""/>
                        </div> */}
                       {/* <div class="p-4 white-bg mb-4">
                            <h2 class="title-stay-connect">Stay connected. Get the app!</h2>
                            <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                            <img src="/images/HTO-app-face.png" class="w-100" alt=""/>

                            <div class="d-flex justify-content-center  mt-3">
                                <img src="/images/apple-app-store.png" alt="" style={{width: "130px"}}/>
                                <img src="/images/google-play.png" alt="" class="ms-2" style={{maxWidth: "130px"}}/>
                            </div>
                        </div> */}


                    </div>
                </div>
           </div>
        </section>
        <Hiring/>
     </main>
  </>);

}

Events.propTypes = {};

Events.defaultProps = {};

export default Events;