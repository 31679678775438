//import React,{history,useEffect, useState, useMemo } from "react";
//import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams, useLocation, useHistory } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { set, useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Services from '../../services/services';
import UsNumberValidate from '../../utility/usNumberValidate';
import services from "../../services/services";
//import { useNavigate,useParams } from "react-router-dom";
import SidebarProfile from "../../utility/sidebarProfile";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SunEditor, { buttonList } from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
const ApplyJobProfile = () => {



  const [jobTypes, setJobTypes] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [jobType, setJobType] = useState([]);

  const [jobSeekerResumePath, setJobSeekerPath] = useState([]);
  const [itemName, setFieldName] = useState('');
  const [resumePath, setResumepath] = useState('');

  const [jobDetails, setJobDetails] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const jobId = queryParams.get('jobId');
  // const{jobSeekerId}=
  const [jobSeekerDetails, setJobSeekerDetails] = useState([]);
  //const jobSeekerId = 51;
  const jobSeekerId = sessionStorage.getItem("logProfileId");
  const studentId = sessionStorage.getItem("logStudentId");

  //const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");

  const [fileName, setFileName] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const [fileBase64, setFileBase64] = useState('');

  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState('');

  const [pdfDownload, setPdfDownload] = useState('');

  const [personalProfileInfo, setPersonalProfile] = useState('');
  const [educationDetails, setEducationInfo] = useState([]);
  const [trainingDetails, setTrainingInfo] = useState([]);
  const [lookingforDetails, setLookingforInfo] = useState([]);



  const navigate = useNavigate();

  const companyIdChkLogin = sessionStorage.getItem("logCompanyId");
  const studentIdChkLogin = sessionStorage.getItem("logStudentId");
  const jobSeekerIdChkLogin = sessionStorage.getItem("logProfileId");
  const userIdChkLogin = sessionStorage.getItem("logUserId");


  function handleImageUploadBefore(files, info, uploadHandler) {
    var reader1 = new FileReader();
    reader1.readAsDataURL(files[0]);
    reader1.onload = function () {
      var rdt1 = reader1.result;
      var base64result_banner = rdt1.split(',')[1];
      var bname = files[0].name;
      var bextn = bname.split(".").pop();
      var bannerActName = bname.split(".")[0];
      var fileext_banner = bextn;

      const requestObject = {
        //"imageName": files[0].name,
        "imageName": bannerActName,
        "imageExtension": fileext_banner,
        "imageByteString": base64result_banner
      };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "Bearer "+accessTokenIn 
          //'Authorization': accessTokenIn 
        },
        body: JSON.stringify(requestObject)
      };

      Services.fileUploadS3URL(requestOptions).then((res) => {
        const response = {
          // The response must have a "result" array.
          "result": [
            {
              "url": res.url,
              "temp": files[0],
              "name": bannerActName,
              "size": files[0].size
            },
          ]
        }
        uploadHandler(response);

      });
    };

  }


  function handleChangePersonalProfileDesc(content) {
    setPersonalProfile(content);
  }


  function handleChangeEducationInfoDesc(content) {
    setEducationInfo(content);
  }


  function handleChangeTrainingInfoDesc(content) {
    setTrainingInfo(content);
  }

  function handleChangeLookingforInfoDesc(content) {
    setLookingforInfo(content);
  }


  function getImage() {
    var files = document.getElementById('resume').files;
    if (files.length > 0) {

      var fname = files[0].name;
      // var fextn = fname.substr(0, fname.lastIndexOf('.'));
      var fextn = fname.split(".").pop();
      // alert(fextn);
      var fileActName = fname.split(".")[0];
      // alert(fileActName);
      setFileName(fileActName);
      setFileExtension(fextn);
      getBase64(files[0]);

      // if(fextn != "pdf"){
      if (fextn != "doc" && fextn != "docx" && fextn != "pdf") {
        $('#filevalidError').hide();
        $("#fileError").show();
        return false;
      } else {
        $("#fileError").hide();
        $('#resumevalid').hide();
        return true;
      }

    }
  }

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // console.log(reader.result);
      var base64result = reader.result.split(',')[1];
      setFileBase64(base64result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const submitValue = () => {
    validateCategory();
    if (jobSeekerId != null && jobSeekerId != "null") {
      $('#filevalidError').hide();
      if (fileExtension != "doc" && fileExtension != "docx" && fileExtension != "pdf" && fileExtension != "txt") {

        //document.getElementById('logo').reset();
        $("#fileError").show();
        return false;
      } else {
        $("#fileError").hide();
      }
    }
    else if (studentId != null && studentId != "null") {
      if (fileExtension != '') {
        $('#filevalidError').hide();
        if (fileExtension != "doc" && fileExtension != "docx" && fileExtension != "pdf") {
          //document.getElementById('logo').reset();
          //alert('1');
          $("#fileError").show();
          return false;
        } else {
          $("#fileError").hide();
        }
      }
      else {
        $("#filevalidError").show();
        return false;
      }

    }
    else {

    }
    var validateEditorTxt = 1;
    if ($("#firstName").val() == "") {
      $("#firstNameValidMsg").show();
      // $("#jobSummaryValidMsg").focus();
      $(window).scrollTop($('#firstNameValidMsg').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#firstNameValidMsg").hide();
    }

    if ($("#lastName").val() == "") {
      $("#lastNameValidMsg").show();
      // $("#jobSummaryValidMsg").focus();
      $(window).scrollTop($('#lastNameValidMsg').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#lastNameValidMsg").hide();
    }

    var email_regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    var emailid = $('#email').val();
    if (emailid == "") {
      $("#emailValidMsg").show();
      // $("#jobSummaryValidMsg").focus();
      $(window).scrollTop($('#emailValidMsg').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#emailValidMsg").hide();
    }

    var emailvalid = email_regex.test(emailid);
    if (emailid != '') {
      if (emailvalid == true) {
        $("#emailValidregex").hide();
      }
      else {
        $("#emailValidregex").show();
        $(window).scrollTop($('#emailValidregex').position().top);
        validateEditorTxt = 0;
      }
    }
    else {
      $("#emailValidregex").hide();
    }

    if ($("#phone").val() == "") {
      $("#phoneValidMsg").show();
      // $("#jobSummaryValidMsg").focus();
      $(window).scrollTop($('#phoneValidMsg').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#phoneValidMsg").hide();
    }
    
    if (personalProfileInfo == "" || personalProfileInfo == '<p><br></p>' || personalProfileInfo == null || personalProfileInfo == 'null') {
      $("#personalProfileValidMsg").show();
      // $("#jobSummaryValidMsg").focus();
      $(window).scrollTop($('#personalProfileValidMsg').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#personalProfileValidMsg").hide();
    }



    if (educationDetails == "" || educationDetails == '<p><br></p>' || educationDetails == null || educationDetails == 'null') {
      $("#educationInfoValidMsg").show();
      // $("#jobSummaryValidMsg").focus();
      $(window).scrollTop($('#educationInfoValidMsg').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#educationInfoValidMsg").hide();
    }

    if (trainingDetails == "" || trainingDetails == '<p><br></p>' || trainingDetails == null || trainingDetails == 'null') {
      $("#trainingInfoValidMsg").show();
      // $("#jobSummaryValidMsg").focus();
      $(window).scrollTop($('#trainingInfoValidMsg').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#trainingInfoValidMsg").hide();
    }

    if (lookingforDetails == "" || lookingforDetails == '<p><br></p>' || lookingforDetails == null || lookingforDetails == 'null') {
      $("#lookingforInfoValidMsg").show();
      // $("#jobSummaryValidMsg").focus();
      $(window).scrollTop($('#lookingforInfoValidMsg').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#lookingforInfoValidMsg").hide();
    }

    /*if(fileExtension == ""){
      $("#filevalidError").show();
      $(window).scrollTop($('#filevalidError').position().top);
      validateEditorTxt = 0;
    }
    else{
      $("#filevalidError").hide();
    } */


    if (validateEditorTxt == 0) {
      return false;
    }
    //return false;

    const requestObject = {
      "profileId": jobSeekerId,
      "studentId": studentIdChkLogin,
      "jobId": jobId,
      "companyId": companyId,
      "siteId": process.env.React_App_SITE_ID,
      "firstName": $("#firstName").val(),
      "lastName": $("#lastName").val(),
      "email": $("#email").val(),
      "phone": $("#phone").val(),
      "personalProfile": personalProfileInfo,
      "profileSummary": personalProfileInfo,
      "educationExperiance": educationDetails,
      "certificationsTrainingInfo": trainingDetails,
      "jobType": $("#jobType").val(),
      "enrolledCollege": 3,
      "lookingFor": lookingforDetails,
      "resumePath": resumePath,

      //"pdfByString":fileBase64,
      //"fileName":fileName,
      //"fileExtension":fileExtension,

      "status": "statusindi",

    }
    var files = document.getElementById('resume').files;
    if (files.length > 0) {
      if (fileExtension != "") {
        $('#filevalidError').hide();
        if (fileExtension != "doc" && fileExtension != "docx" && fileExtension != "pdf" && fileExtension != "txt") {
          //document.getElementById('logo').reset();
          $("#fileError").show();

          return false;
        } else {
          $("#fileError").hide();

          requestObject.fileName = fileName;
          requestObject.fileExtension = fileExtension;
          requestObject.pdfByString = fileBase64;
        }
      }
      else {
        $('#filevalidError').show();
        return false;
      }
    }

    console.log(requestObject);
    //return false;


    if (jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null") {
      //
      //

      var accessTokenIn = sessionStorage.getItem("accToken");
      console.log("Resume update request object===" + JSON.stringify(requestObject));
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + accessTokenIn
          //'Authorization': accessTokenIn
        },
        body: JSON.stringify(requestObject)
      };

      $(".load-wrap").show();
      services.applyJobAsJobSeeker(requestOptions).then((res) => {
        $(".load-wrap").hide();
        $("#addUserForm").trigger("reset");
        // navigate('/thankyou');
        /*
        $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#successMessage").slideUp(1000);
          //navigate('/thankyou?r=1');
        });
        */
        //setTimeout(function() {navigate('/profileDashboard');}, 2000);
        setTimeout(function () { navigate('/thankyou?r=4'); }, 2000);
        //navigate('/thankyou?r=2');

      });


      //
    } else if (studentIdChkLogin != null && studentIdChkLogin != "null") {
      //
      //alert("158")
      //applyJobAsStudent
      if (fileExtension == "") {
        $("#filevalidError").show();
        return false;
      }
      var accessTokenIn = sessionStorage.getItem("accToken");
      console.log("Resume update request object===" + JSON.stringify(requestObject));
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + accessTokenIn
          //'Authorization': accessTokenIn
        },
        body: JSON.stringify(requestObject)
      };

      $(".load-wrap").show();
      services.applyJobAsStudent(requestOptions).then((res) => {
        $(".load-wrap").hide();
        $("#addUserForm").trigger("reset");
        // navigate('/thankyou');
        /*
        $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#successMessage").slideUp(1000);
          //navigate('/thankyou?r=1');
        });
        */
        //setTimeout(function() {navigate('/studentDashboard');}, 2000);
        setTimeout(function () { navigate('/thankyou?r=5'); }, 2000);

      });

      //
    } else {
      //alert("162")
      //
      if (fileExtension == "") {
        $("#filevalidError").show();
        return false;
      }

      var accessTokenIn = sessionStorage.getItem("accToken");
      console.log("Resume update request object===" + JSON.stringify(requestObject));
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "Bearer "+accessTokenIn
          //'Authorization': accessTokenIn
        },
        body: JSON.stringify(requestObject)
      };

      $(".load-wrap").show();
      services.applyJob(requestOptions).then((res) => {
        $(".load-wrap").hide();
        $("#addUserForm").trigger("reset");

        console.log(JSON.stringify(res));
        // navigate('/thankyou');
        /*
        $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#successMessage").slideUp(1000);
          //navigate('/thankyou?r=1');
        });
        */
        //setTimeout(function() {navigate('/thankyou');}, 2000);
        setTimeout(function () { navigate('/thankyou?r=6'); }, 2000);

      });

      //
    }








  }

  const getProfile = (jobSeekerId) => {
    services.getProfile(jobSeekerId).then((res) => {
      setJobSeekerDetails(res);
      $("#firstName").val(res.data.firstName);
      $("#lastName").val(res.data.lastName);
      $("#email").val(res.data.email);
      $("#phone").val(res.data.phone);
      $("#personalProfile").val(res.data.personalProfile);
      $("#education").val(res.data.educationExperiance);
      $("#certificationTraining").val(res.data.certificationTraining);
      $("#lookingFor").val(res.data.lookingFor);
      $("#jobType").val(res.data.jobType);
      $("#resume").val(res.data.pdfByString);
      setPdfDownload(res.data.resumePath);
      setJobSeekerPath(res.data.resumePath);
      setResumepath(res.data.resumePath);
      setPersonalProfile(res.data.personalProfile);
      setTrainingInfo(res.data.certificationTraining);
      setEducationInfo(res.data.course);
      setLookingforInfo(res.data.lookingFor);
      var ext = res.data.fileExtension;
      setFileExtension(ext.replace('.', ''));


      setJobType(res.data.jobType);
      console.log("jobseeker@@@@@@@@" + JSON.stringify(res.data));

      setCategoriesSelected(res.data.categoriesOfExpertise);
      var companyCatIn = res.data.categoriesOfExpertise;
      // alert(companyCatIn);

      //$('#jobType').prop('selectedIndex', res.data.jobType);
      $('#jobType').val(res.data.jobType);
      //alert("res.data.jobType"+res.data.jobType);





      setTimeout(() => {
        for (var i = 0; i < companyCatIn.length; i++) {
          //$("input[value='" + val + "']").prop('checked', true);
          console.log("###" + companyCatIn[i]);
          $('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
          //$('input[name="category"][value="' + companyCatIn[i] + '"]').trigger("click");
        }
        validateCheck();

      }, 2000);


    });
  }

  const [categories, setCategory] = useState([]);




  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });

  function getJobDetailsById() {
    //alert(jobId);
    services.getJobDetailsById(jobId).then((res) => {
      setJobDetails(res.data.jobPostVo);
      setCompanyDetails(res.data.companyVo);
      setCompanyId(res.data.companyVo.companyId);
    });
  }
  var resurl = '';
  var studentinfo = '';
  const getStudentProfile = (studentId) => {
    Services.getStudentProfile(studentId).then((res) => {
      // setJobSeekerDetails(res);
      studentinfo = res.data;
      $("#firstName").val(res.data.firstName);
      $("#lastName").val(res.data.lastName);
      $("#email").val(res.data.email);
      $("#phone").val(res.data.phone);
      $("#personalProfile").val(res.data.personalProfile);
      $("#education").val(res.data.educationExperiance);
      $("#lookingFor").val(res.data.lookingFor);
      //$("#jobType").val(res.data.jobType);
      $("#jobType").val("2");
      $("#resume").val(res.data.pdfByString);

      $("#personalProfile").val(res.data.reasonForSelectCareers);
      $("#lookingFor").val(res.data.comments);


      $("#careers").val(res.data.reasonForSelectCareers);
      $("#courses_related").val(res.data.course);
      $("#certificationTraining").val(res.data.course);

      var ext = res.data.fileExtension;
      if (ext != null) {
        setFileExtension(ext.replace('.', ''));
      }
      resurl = studentinfo.resumeUrl;
      setPdfDownload(res.data.resumeUrl);
      setJobSeekerPath(res.data.resumeUrl);
      setResumepath(res.data.resumeUrl);

      $("#class_type").val(res.data.classYear);
      $("#school_district").val(res.data.schoolId);

      $("#skills").val(res.data.experiance);
      $("#education").val(res.data.experiance);

      $('#courses').val(res.data.course);
      $('#comments').val(res.data.comments);
      $('#password').val(res.data.password);
      $('#authorization').val(res.data.authorization);
      $('#authorization').prop('selectedIndex', res.data.authorization);
      //setPdfDownload(res.data.resumePath);
      //setJobType(res.data.jobType);
      console.log("jobseeker@@@@@@@@" + JSON.stringify(res.data));

      setCategoriesSelected(res.data.categoriesOfExpertise);
      var companyCatIn = res.data.categoriesOfExpertise;

      var seekingIn = res.data.seekingEmploymentTypes;




      setTimeout(() => {

        setPersonalProfile(res.data.reasonForSelectCareers);
        setTrainingInfo(res.data.experiance);
        setEducationInfo(res.data.course);
        //setLookingforInfo(res.data.lookingFor);
        for (var i = 0; i < seekingIn.length; i++) {
          //$("input[value='" + val + "']").prop('checked', true);
          console.log("###" + seekingIn[i]);
          //$('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
          $('input[name="seeking"][value="' + seekingIn[i] + '"]').trigger("click");
        }

        for (var i = 0; i < companyCatIn.length; i++) {
          //$("input[value='" + val + "']").prop('checked', true);
          console.log("company catein ###" + companyCatIn[i]);
          //$('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
          $('input[name="exp"][value="' + companyCatIn[i] + '"]').trigger("click");
        }
        //validateCheck();

        var isCheck = true;

        var checkedValues = $("input[name='category']").val();
        // var checkedValues = [];
        //     $.each($("input[name='category']:checked"), function () {
        //       checkedValues.push($(this).val());
        //     });
        // alert(checkedValues);
        if (!checkedValues) {
          isCheck = false;
          $("#categoryError").html("Category field is required");
        } else {
          // alert(checkedValues);
          isCheck = true;
          $("#categoryError").html("");
        }

        return isCheck;

        setTimeout(() => {
          console.log("141");
          $('input[name="category"]').trigger("change");
          $('input[name="state"]').trigger("change");
        }, 3000);

      }, 1000);



    });
  }
  useEffect(() => {
    const jsonData = {
      "applyJobProfile": {
        
      }
    };

  var jsondataformatted = JSON.stringify(jsonData);
  sessionStorage.setItem('pagejsonObject', jsondataformatted);

    $(".load-wrap").hide();
    $(".se-dialog-tabs").hide();
    $("#successMessage").hide();
    $('#phone').usPhoneFormat();
    getJobDetailsById();
    getIndustryData();
    $("#educationInfoValidMsg").hide();
    $("#personalProfileValidMsg").hide();
    $("#trainingInfoValidMsg").hide();
    $("#lookingforInfoValidMsg").hide();
    $('#firstNameValidMsg').hide();
    $("#lastNameValidMsg").hide();
    $("#emailValidregex").hide();
    $("#emailValidMsg").hide();
    $('#phoneValidMsg').hide();



    if (jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null") {
      getProfile(jobSeekerIdChkLogin);
    } else if (studentIdChkLogin != null && studentIdChkLogin != "null") {
      getStudentProfile(studentIdChkLogin);
    } else {
      //$("#addUserForm").find("input[type=text], textarea").val("");
      //$("#firstName").attr("defaultValue", "");

      $("#firstName").val("");
      $("#lastName").val("");
      $("#email").val("");
      $("#phone").val("");
      $("#personalProfile").val("");
      $("#education").val("");
      $("#certificationTraining").val("");
      $("#lookingFor").val("");

      // $("#lastName").focus();
      // $("#firstName").focus();

    }

    $("#fileError").hide();
    $('#filevalidError').hide();
    Services.getJobType().then((res) => {
      console.log("setJobType data=" + JSON.stringify(res.data));
      var tempjobtypes = res.data.content;
      tempjobtypes = tempjobtypes.filter(function (elem) {
        return elem.name !== 'Continuing Education';
      });
      setJobTypes(tempjobtypes);
    });
    // alert(jobId)

  }, []);

  const getIndustryData = () => {

    Services.getIndustryData().then((res) => {

      console.log("Industry data=" + JSON.stringify(res.data));

      setCategory(res.data.content);

    });

  }


  function validateCategory() {
    // alert("@@category");
    var isCheck = true;

    var checkedValues = $("input[name='category']:checked");
    // var checkedValues = [];
    //     $.each($("input[name='category']:checked"), function () {
    //       checkedValues.push($(this).val());
    //     });
    //  alert(checkedValues);
    // alert(checkedValues.length);
    var lenghtCheck = checkedValues.length;
    if (lenghtCheck == 0) {
      isCheck = false;
      $("#categoryError").html("Category field is required");
    } else {
      // alert(checkedValues);
      isCheck = true;
      $("#categoryError").html("");
    }

    return isCheck;
  }

  function goLogin() {
    //navigate("/Home?loginRedirect=1");
    window.location.href = "/Home?loginRedirect=1";
  }

  const validateCheck = () => {

    const checked = $('input[name="category"]:checked').length;

    if (checked >= 3) {
      $('input[name="category"]').not(':checked').prop('disabled', true);
    } else {
      $('input[name="category"]').prop('disabled', false);
    }
  }


  return (
    <>
      <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">

                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                  <ol class="breadcrumb">

                    {(() => {

                      if (jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null") {
                        return (<><li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li> </>);
                      }

                      if (studentIdChkLogin != null && studentIdChkLogin != "null") {
                        return (<><li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li> </>);
                      }

                    })()}

                    <li class="breadcrumb-item headtitle ">Apply Now</li>

                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
          <div class="container">
            <div class="row">
              <div class="col-12 ">
                <div class="white-bg p-5">
                  <div class="row">
                    {/* <!-- left side --> */}


                    {/* <SidebarProfile/> */}

                    <div class="col-lg-4 mb-4">
                      <div class="card p-3 ">
                        <div class="bg-heading">
                          <h3 class="mb-0"> {companyDetails.name} </h3>
                        </div>
                        <div class="mb-5 mt-5 text-center">
                          <a href={"/companyDetails?companyId=" + companyDetails.companyId} ><img class="w-100" src={companyDetails.logoUrl} alt="" /></a>
                        </div>


                      </div>
                    </div>




                    {/* <div class="col-lg-4 mb-4">
                                    <div class="card p-3 ">
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Profile Management </h3>
                                        </div>



                                         <div class="qlinks">
                                            <ul>
                                                <li><a href="/updateProfile">Edit Your Profile </a></li>
                                                <li><a href="/profileInfo">View Attached Resume</a></li>
                                            </ul>
                                            <div class="p-5 white-bg">
                                                <div class="text-center">
                                                    <img src="images/Group 75.png" alt=""/>
                                                </div>
                                                <div class="text-cente">
                                                    <h3 class="pb-3 border-bottom text-center">Upcoming Events</h3>
                                                    <div class="pb-4 mt-4 border-bottom">
                                                        <h4>CAREER FAIR</h4>
                                                        <span>September 21, 2021</span>
                                                        <p>Wright State University will be hosiint it’s 8th Annual Career Fair on October 21st.</p>
                                                    </div>

                                                    <div class="pb-4 mt-4 border-bottom">
                                                        <h4>CAREER FAIR</h4>
                                                        <span>September 21, 2021</span>
                                                        <p>Wright State University will be hosiint it’s 8th Annual Career Fair on October 21st.</p>
                                                    </div>

                                                    <div class=" mt-4 ">
                                                        <h4>CAREER FAIR</h4>
                                                        <span>September 21, 2021</span>
                                                        <p>Wright State University will be hosiint it’s 8th Annual Career Fair on October 21st.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> *
                                    </div>

                                </div> */}

                    {/* <!-- right side --> */}



                    <div class="col-lg-8 mb-4">


                      {(userIdChkLogin == '' || userIdChkLogin == null) ? <div class=" right-info">Do you already have a jobseeker account with us? Click here to  <a className="text-underline" href="/Home?loginRedirect=1">sign in</a></div> : ''}
                      <div className="mb-2"> <hr /></div>
                      <div class="right-info">
                        <br></br>
                      </div>


                      <div class="right-info">
                        <h4>Job Title: {jobDetails.title}</h4>
                        {/* <div class="d-flex">
                                        <span><a href="#">Company Name: {companyDetails.name}</a> </span>
                                    </div> */}
                      </div>
                      <div class="right-info">
                        <br></br>
                      </div>

                      <form id="addUserForm" onSubmit={handleSubmit(submitValue)}>
                        <div class="row resume-wrap mb-4">
                          <div class="col-lg-6 mb-4">
                            <label for="firstName" class="form-label">First Name<span class="red-text">*</span></label>
                            <input type="text" class="form-control" defaultValue="firstName" id="firstName" placeholder="First Name" />
                            <div id="firstNameValidMsg" class="error">First name is required.</div>
                            {errors.firstName && errors.firstName.type === "required" && <div class="error">First name is required</div>}
                          </div>

                          <div class="col-lg-6 mb-4">
                            <label for="lastName" class="form-label">Last Name<span class="red-text">*</span></label>
                            <input type="text" class="form-control" defaultValue="lastName" id="lastName" placeholder="Last Name" />
                            <div id="lastNameValidMsg" class="error">Last name is required.</div>
                            {errors.lastName && errors.lastName.type === "required" && <div class="error">Last name is required</div>}
                          </div>

                          {/* <div class="col-lg-6 mb-4">
                                            <label for="email" class="form-label">Email<span class="red-text">*</span></label>
                                            <input type="text" class="form-control" defaultValue="email" id="email" placeholder="Email"  {...register('email', { required: true, pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ })}/>
                                            {errors.email && errors.email.type === "required" && <div class="error">Email is required</div>}
                                            {errors.email && errors.email.type === "pattern" && <div class="error">Invalid email address.</div> }
                                        </div> */}

                          <div class="col-lg-6 mb-4">
                            <div class="d-flex"><label for="email" class="form-label mr-2">Email <span class="red-text">*</span></label> <small>Valid email address for notifications</small></div>
                            <input type="text" class="form-control" defaultValue="email@email.com" id="email" onChange={e => setFieldName(e.target.value)} />
                            <ErrorMessage
                              errors={errors}
                              name="Email"
                              class="invalid-feedback"
                              render={({ messages }) => {
                                console.log("messages", messages);
                                return messages
                                  ? Object.entries(messages).map(([type, message]) => (
                                    <p key={type} class="error">{message}</p>
                                  ))
                                  : null;
                              }}
                            />

                            <div id="emailValidMsg" class="error">Email Address is required.</div>
                            <div id="emailValidregex" class="error">Invalid email address.</div>
                          </div>

                          <div class="col-lg-6 mb-4">
                            <label for="phone" class="form-label">Phone <span class="red-text">*</span></label><small>XXX-XXX-XXXX Format</small>
                            <input type="text" class="form-control" id="phone" defaultValue="phone" placeholder="Phone" />
                            {errors.phone && errors.phone.type === "required" && <div class="error">Phone number is required</div>}
                            <div id="phoneValidMsg" class="error">Phone Number is required.</div>
                          </div>

                          <div class="col-lg-12 mb-5">
                            <div><label for="personalProfile" class="form-label">Personal Profile<span class="red-text">*</span></label></div>


                            {/* <CKEditor
                            editor={ ClassicEditor }
                            config={{
                              removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
                            }}
                            id="personalProfileid"
                            data={personalProfileInfo}
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                                //editor.setData(profileDescription);
                                //editor.setData(profileDescription);

                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setPersonalProfile(data);
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        /> */}

                            <SunEditor
                              setOptions={{
                                height: "400",
                                //buttonList: [['font', 'align'], ['image']]

                                buttonList: [
                                  ['undo', 'redo'],
                                  ['font', 'fontSize', 'formatBlock'],
                                  ['paragraphStyle', 'blockquote'],
                                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                  ['fontColor', 'hiliteColor', 'textStyle'],
                                  ['removeFormat'],
                                  '/', // Line break
                                  ['outdent', 'indent'],
                                  ['align', 'horizontalRule', 'list', 'lineHeight'],
                                  ['table', 'link', 'image' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.                    
                                  ['fullScreen', 'showBlocks', 'codeView'],
                                  ['preview'],

                                  /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                                ]
                                //buttonList: buttonList.complex
                                // plugins: [font] set plugins, all plugins are set by default
                                // Other option
                              }}
                              onChange={handleChangePersonalProfileDesc}
                              setContents={personalProfileInfo}
                              //onImageUpload={handleImageUpload}
                              onImageUploadBefore={handleImageUploadBefore}
                            />

                            <div id="personalProfileValidMsg" class="error">Personal Profile is required.</div>

                            {/*} <textarea class="form-control textarea" id="personalProfile" defaultValue="personalProfile" placeholder="Personal Profile" {...register('personalProfile', { required: true,maxLength:500 })}></textarea>
                                            {errors.personalProfile && errors.personalProfile.type === "required" && <div class="error">Personal profile is required</div>}
                                            {errors.personalProfile && errors.personalProfile.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>} */}
                          </div>

                          <div class="col-lg-12 mb-5">
                            <div class="d-flex"><label for="education" class="form-label">Education/Experience<span class="red-text">*</span></label></div>


                            {/* <CKEditor
                            editor={ ClassicEditor }
                            config={{
                              removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
                            }}
                            id="educationid"
                            data={educationDetails}
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                                //editor.setData(profileDescription);
                                //editor.setData(profileDescription);

                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setEducationInfo(data);
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        /> */}


                            <SunEditor
                              setOptions={{
                                height: "400",
                                //buttonList: [['font', 'align'], ['image']]

                                buttonList: [
                                  ['undo', 'redo'],
                                  ['font', 'fontSize', 'formatBlock'],
                                  ['paragraphStyle', 'blockquote'],
                                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                  ['fontColor', 'hiliteColor', 'textStyle'],
                                  ['removeFormat'],
                                  '/', // Line break
                                  ['outdent', 'indent'],
                                  ['align', 'horizontalRule', 'list', 'lineHeight'],
                                  ['table', 'link', 'image' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.                    
                                  ['fullScreen', 'showBlocks', 'codeView'],
                                  ['preview'],

                                  /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                                ]
                                //buttonList: buttonList.complex
                                // plugins: [font] set plugins, all plugins are set by default
                                // Other option
                              }}
                              onChange={handleChangeEducationInfoDesc}
                              setContents={educationDetails}
                              //onImageUpload={handleImageUpload}
                              onImageUploadBefore={handleImageUploadBefore}
                            />


                            <div id="educationInfoValidMsg" class="error">Education/Experience is required.</div>

                            {/* <textarea class="form-control textarea" id="education" defaultValue="education" placeholder="EDUCATION/EXPERIENCE" {...register('education', { required: true,maxLength:500 })}></textarea>
                                            {errors.education && errors.education.type === "required" && <div class="error">Education/Experience is required</div>}
                                            {errors.education && errors.education.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>} */}
                          </div>

                          <div class="col-lg-12 mb-5">
                            <div class="d-flex"><label for="certificationTraining" class="form-label">Certification/Training<span class="red-text">*</span></label></div>

                            {/* <CKEditor
                            editor={ ClassicEditor }
                            config={{
                              removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
                            }}
                            id="trainingid"
                            data={trainingDetails}
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                                //editor.setData(profileDescription);
                                //editor.setData(profileDescription);

                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setTrainingInfo(data);
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        /> */}



                            <SunEditor
                              setOptions={{
                                height: "400",
                                //buttonList: [['font', 'align'], ['image']]

                                buttonList: [
                                  ['undo', 'redo'],
                                  ['font', 'fontSize', 'formatBlock'],
                                  ['paragraphStyle', 'blockquote'],
                                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                  ['fontColor', 'hiliteColor', 'textStyle'],
                                  ['removeFormat'],
                                  '/', // Line break
                                  ['outdent', 'indent'],
                                  ['align', 'horizontalRule', 'list', 'lineHeight'],
                                  ['table', 'link', 'image' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.                    
                                  ['fullScreen', 'showBlocks', 'codeView'],
                                  ['preview'],

                                  /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                                ]
                                //buttonList: buttonList.complex
                                // plugins: [font] set plugins, all plugins are set by default
                                // Other option
                              }}
                              onChange={handleChangeTrainingInfoDesc}
                              setContents={trainingDetails}
                              //onImageUpload={handleImageUpload}
                              onImageUploadBefore={handleImageUploadBefore}
                            />


                            <div id="trainingInfoValidMsg" class="error">Certification/Training is required.</div>

                            {/* <textarea class="form-control textarea" id="certificationTraining" defaultValue="certificationTraining" placeholder="Certification/training" {...register('certificationTraining', { required: true,maxLength:500 })}></textarea>
                                            {errors.education && errors.education.type === "required" && <div class="error">Certification/training is required</div>}
                                            {errors.education && errors.education.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>} */}
                          </div>


                          <div class="col-lg-6 mb-5">

                            <div class="d-flex justify-content-between align-items-end">
                              <div>
                                <label for="jobType" class="form-label">Job Type</label>

                                <select class="form-select form-control" aria-label="Default select example" style={{ maxWidth: "420px" }} id="jobType">
                                  {/* <option  value=''>Select</option> */}
                                  {
                                    jobTypes.map((data, index) => (
                                      <option value={data.jobTypeId} >{data.name}</option>
                                    ))}
                                  {/* <option value="freeeducation" selected={jobType=="freeeducation"?"selected":''}>freeeducation</option> */}
                                  {/* <option value="1">One</option> */}

                                </select>
                              </div>
                              {/* {errors.category && errors.category.type === "required" && <div class="error">Profile ype is required</div>} */}
                            </div>

                          </div>

                          <div class="col-lg-12 mb-5">
                            <div class="d-flex"><label for="lookingFor" class="form-label mr-2">What You're Looking For<span class="red-text">*</span></label> </div>
                            {/* <CKEditor
                            editor={ ClassicEditor }
                            config={{
                              removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
                            }}
                            id="lookigforid"
                            data={lookingforDetails}
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                                //editor.setData(profileDescription);
                                //editor.setData(profileDescription);

                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setLookingforInfo(data);
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        /> */}


                            <SunEditor
                              setOptions={{
                                height: "400",
                                //buttonList: [['font', 'align'], ['image']]

                                buttonList: [
                                  ['undo', 'redo'],
                                  ['font', 'fontSize', 'formatBlock'],
                                  ['paragraphStyle', 'blockquote'],
                                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                  ['fontColor', 'hiliteColor', 'textStyle'],
                                  ['removeFormat'],
                                  '/', // Line break
                                  ['outdent', 'indent'],
                                  ['align', 'horizontalRule', 'list', 'lineHeight'],
                                  ['table', 'link', 'image' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.                    
                                  ['fullScreen', 'showBlocks', 'codeView'],
                                  ['preview'],

                                  /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                                ]
                                //buttonList: buttonList.complex
                                // plugins: [font] set plugins, all plugins are set by default
                                // Other option
                              }}
                              onChange={handleChangeLookingforInfoDesc}
                              setContents={lookingforDetails}
                              //onImageUpload={handleImageUpload}
                              onImageUploadBefore={handleImageUploadBefore}
                            />
                            <div id="lookingforInfoValidMsg" class="error">What You're Looking for is required.</div>

                            {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="lookingFor" defaultValue="lookingFor" spellcheck="false" {...register('lookingFor', { required: true,maxLength:500 })}></textarea>
                                            {errors.lookingFor && errors.lookingFor.type === "required" && <div class="error">This field is required</div>}
                                            {errors.lookingFor && errors.lookingFor.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>}
                                            */}
                          </div>

                          <div class="col-lg-12 mb-4">

                            <div class="d-flex justify-content-between align-items-end choose">
                              <div>
                                <label for="resume" class="form-label">Upload a Resume</label>
                                <div class="d-flex align-items-center">
                                  <input class="form-control form-control-lg mr-2" id="resume" type="file" onChange={() => getImage()} />

                                  {/* <a href={window.location.path+'/'+pdfDownload} download="apple"><button type="button">Download</button></a> */}
                                </div>
                                <small> 1MB Max, File Type: PDF, DOC, DOCX</small>
                                <div id="filevalidError" class="error">Resume required.</div><br />
                                <div id="fileError" class="error">Invalid file format</div><br />

                                {(() => {

                                  if (jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null") {
                                    if (jobSeekerResumePath != '') {



                                      return (<><div id="resumevalid" class="alert alert-warning" role="alert">Caution: You are applying with existing resume.</div>
                                        <a href={jobSeekerResumePath} download="resume" target="_blank"><button type="button" class="reg-button">Download Current Resume</button></a> </>);
                                    }

                                  }

                                })()}

                                {(() => {

                                  if (studentIdChkLogin != null && studentIdChkLogin != "null") {
                                    if (jobSeekerResumePath) {



                                      return (<><div id="resumevalid" class="alert alert-warning" role="alert">Caution: You are applying with existing resume.</div>
                                        <a href={jobSeekerResumePath} download="resume" target="_blank"><button class="reg-button" type="button">Download Current Resume</button></a> </>);
                                    }
                                  }

                                })()}

                                {/* <a href={jobSeekerResumePath} download="resume"><button type="button">Download Current Resume</button></a>  */}
                                {/* {...register('resume', { required: true })} */}
                                {errors.resume && errors.resume.type === "required" && <div class="error">Upload a PDF Resume is required</div>}
                                <span id="FileSizeError" class="error"></span>
                              </div>
                              {/* <div><a href="#" class="reg-button">Update</a></div> */}
                              <div><button type="submit" class="reg-button">Apply Now</button></div>
                            </div><br /><br />
                            <div id="successMessage" class="alert">
                              <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                  <img src="/images/success-alert.png" alt="" />
                                  <strong class="mx-2">Success!</strong> Applied Successfully.
                                </div>
                                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                              </div>
                            </div>
                          </div>


                        </div>
                      </form>

                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </section>

        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
          </div>
        </section>

      </main>
    </>
  )
};

ApplyJobProfile.propTypes = {};

ApplyJobProfile.defaultProps = {};

export default ApplyJobProfile;
