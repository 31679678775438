import React, { history, useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Recent_Jobs from "./Jobs/Recent_Jobs";
import services from "../services/services";
const PrivacyPolicy = () => {
    const [currentPageContent, setCurrentPageContent] = useState([]);
    const getPageInfo = (reqopt) => {        
        services.getCMSPageContent('3').then((res) => {            
            console.log(JSON.stringify(res));
            if(res.data){
                setCurrentPageContent(res.data.content.rendered);
            }       
        });       
     }
    useEffect(() => {
        const jsonData = {
            "privacyPolicy": {
              
            }
          };
          var jsondataformatted = JSON.stringify(jsonData);
          sessionStorage.setItem('pagejsonObject', jsondataformatted);

        getPageInfo();

    }, []);

    return (<>
        <main class="empr-directory-main">
            <div class="main-bgpatten"></div>
            <section class="headtitle-wrap">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <nav style={{ "--bs-breadcrumb-divider": '>' }} aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item headtitle "><a href="#">Privacy Policy</a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section class="company-detail-wrap py-5 ">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="white-bg px-5 pt-5 pb-4">
                                <div class="row mb-3">
                                    <div class="col-lg-12 mb-4">
                                    <div dangerouslySetInnerHTML={{ __html: currentPageContent }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </>);

}

PrivacyPolicy.propTypes = {};

PrivacyPolicy.defaultProps = {};

export default PrivacyPolicy;