import React,{history,useEffect, useState, useMemo } from "react";
import moment from "moment";
import Services from "../services/services";

const SidebarProfile = () => {

    const[path,setPathName]=useState([]);
    const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");
    const [eventList, setEventList] = useState([]);
    const siteId = 2;
    const getEvents2 = () => {
        Services.getEventsbySiteId(siteId).then((res) => {
          console.log("Industry data=" + JSON.stringify(res.data));
          if(res){
            //setEventList(res.data);
            var evArr = res.data;
            var evList = [];
            for(var i=0;i<3;i++){
                evList[i]=res.data[i];
            }
            setEventList(evList);
          }
        });
    }

    const getEvents = () => {
        Services.getEventsbySiteId(siteId).then((res) => {
          console.log("Industry data=" + JSON.stringify(res.data));
          if(res.data){
            //setEventList(res.data);
            var evArr = res.data;
            var evList = [];
            if(res.data.length >0){
                for(var i=0;i<3;i++){
                    evList[i]=res.data[i];
                }
            }

            //alert(JSON.stringify(evList));
            setEventList(evList);
          }
        });
    }
    useEffect(() => {

        const pathName=window.location.pathname;
        setPathName(pathName);
        //getEvents();
      }, []);


  return(
  <>
    <div class="col-lg-4 mb-4">




                               {(() => {

                                if( jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null"){
                                    return (<>

                                    <div class="card p-3 ">
                                    <div class="bg-heading">
                                       <h3 class="mb-0">Quick Links </h3>
                                    </div>
                                    <div class="qlinks">
                                    <ul>
                                    <li><a href="/profileDashboard" class={"/updateProfle"!=path?'':"active"}>Dashboard</a></li>
                                        <li><a href="/updateProfile" class={"/updateProfle"!=path?'':"active"}>Update Career Profile </a></li>
                                        <li><a href="/viewResume">Your Resume</a></li>
                                        <li><a href="/editAdminstrationLogin" class={"/editAdminstrationLogin"!=path?'':"active"}>Change Password</a></li>
                                        <li><a href="/careerExploreJobs" class={"/careerExploreJobs"!=path?'':"active"}>Available Jobs </a></li>
                                        <li><a href="/videoLibrary" class={"/careerExploreJobs"!=path?'':"active"}>Company Video Library</a></li>
                                        {/* <li><a href="#" class={"/careerPath"!=path?'':"active"}>  Sample Career Paths </a></li>
                                        <li><a href="#" class={"/articales"!=path?'':"active"}>   Relevant Articles </a></li>
                                        <li><a href="/helplink" class={"/helpfullLinks"!=path?'':"active"}>  Helpful Links </a></li> */}
                                        <li><a href="/events" class={"/events"!=path?'':"active"}>  Events Calendar</a></li>
                                    </ul>
                                    {/* <div class="p-5 white-bg">
                                        <div class="text-center">
                                            <img src="images/Group 75.png" alt=""/>
                                        </div>
                                        <div class="text-cente">
                                        <h3 class="pb-3 border-bottom text-center">Upcoming Events</h3>


                                        {
                                        eventList.map((data, index) => (
                                            <div class="pb-4 mt-4 border-bottom">
                                        <h4>{data.eventTitle}</h4>
                                        <span>{moment(data.eventDate).format("MM/DD/YYYY")}</span>
                                        <p>

                                            {`${data.eventDescription.substring(0, 100)}...`}<a href={`/eventDetails?eventId=`+data.eventId}>Read more</a>

                                        </p>
                                        </div>

                                        ))}

                                        </div>
                                    </div> */}


                                </div>
                                </div> <div class="card p-3 ">
                                   <div class="bg-heading">
                                       <h3 class="mb-0">External Links </h3>
                                    </div>
                                    <div class="mb-5 mt-5 text-center">
                                        <a href="https://ohiomeansjobs.ohio.gov/" target="_blank"><img src="images/OHIO MEANS JOBS.png" alt=""/></a>
                                    </div>
                                    {/* <div class="mb-5 text-center   ">
                                    <a href="https://www.edgefactor.com" target="_blank"><img src="images/EFLogo_Black.png" class="w-100" alt=""/> </a>
                                   </div> */}

                               </div>



                                    </>);
                                }

                            })()}

                           </div>
  </>
)};

SidebarProfile.propTypes = {};

SidebarProfile.defaultProps = {};

export default SidebarProfile;
