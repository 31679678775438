import React,{history,useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { set, useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Services from '../../services/services';
import UsNumberValidate from '../../utility/usNumberValidate';
import services from "../../services/services";
import { useNavigate,useParams } from "react-router-dom";
import SidebarProfile from "../../utility/sidebarProfile";
//import { Document, Page } from 'react-pdf';
//import { pdfjs } from 'react-pdf';
import { Document, Page } from "react-pdf";
const ViewResume = () => {

  const { pdf } = {};
    const [jobTypes, setJobTypes] = useState([]);
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [jobType, setJobType] = useState([]);

    const [jobSeekerResumePath, setJobSeekerPath] = useState([]);
    
    
    // const{jobSeekerId}=
    const [jobSeekerDetails, setJobSeekerDetails] = useState([]);
    //const jobSeekerId = 51;
    const jobSeekerId=sessionStorage.getItem("logProfileId");
    const [fileName, setFileName] = useState([]);
    const [fileExtension, setFileExtension] = useState([]);
    const [fileBase64, setFileBase64] = useState([]);

    const [pdfDownload, setPdfDownload] = useState([]);
    const navigate = useNavigate();

    function getImage(){
        var files = document.getElementById('resume').files;  
        if (files.length > 0) {
           
          var fname = files[0].name;
          // var fextn = fname.substr(0, fname.lastIndexOf('.'));
          var fextn = fname.split(".").pop();
          // alert(fextn);
          var fileActName = fname.split(".")[0];
          // alert(fileActName);
          setFileName(fileActName);
          setFileExtension(fextn);
          getBase64(files[0]);
    
          // if(fextn != "pdf"){
          if(fextn != "doc" && fextn != "docx" && fextn != "pdf"   ){
            
            $("#fileError").show();
                return false;
            }else{
                $("#fileError").hide();
                return true;
            }
    
        }
      }
    
      function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          // console.log(reader.result);
          var base64result = reader.result.split(',')[1];
          setFileBase64(base64result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }

     const submitValue=()=>
     {
       
       
       

      validateCategory();
   
       var categoriesArray = [];
   
       $.each($("input[name='category']:checked"), function(){
         categoriesArray.push($(this).val());
     });
   
       const requestObject={
        "profileId": jobSeekerId,
	"firstName":$("#firstName").val(),
	"lastName": $("#lastName").val(),
	"email": $("#email").val(),
	"phone": $("#phone").val(),
	"password": $("#password").val(),
	"personalProfile": $("#personalProfile").val(),
	"educationExperiance": $("#education").val(),
	"certificationTraining": "nothing",
	"jobType": $("#jobType").val(),
	"enrolledCollege": 3,
	"lookingFor": $("#lookingFor").val(),
	"resumePath": "resumepath",
   
    //"pdfByString":fileBase64,
    //"fileName":fileName,
    //"fileExtension":fileExtension,
	"siteId": process.env.React_App_SITE_ID,
	"status": "statusindi",
	"categoriesOfExpertise": categoriesArray
       }

       if(fileExtension != ""){
        //
        if(fileExtension != "doc" && fileExtension != "docx" && fileExtension != "pdf" && fileExtension != "txt"  ){
          //document.getElementById('logo').reset();
          $("#fileError").show();
          return false;
       }else{
          $("#fileError").hide();

          requestObject.fileName = fileName;
          requestObject.fileExtension = fileExtension;
          requestObject.pdfByString = fileBase64;


       }
        //
      }


       console.log("Resume update request object==="+JSON.stringify(requestObject));
       var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json',
                   'Authorization': "Bearer "+accessTokenIn 
                   //'Authorization': accessTokenIn 
                 },
         body: JSON.stringify(requestObject)
       };
   
       services.editProfile(requestOptions).then((res) => 
       {
         
        $("#addUserForm").trigger("reset");
        // navigate('/thankyou');
        getProfile(jobSeekerId);
        $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#successMessage").slideUp(1000);
        });
    
       });
   
     }

     var resumedownloaded =0;

     const getProfile = (jobSeekerId) => {
        services.getProfile(jobSeekerId).then((res) => {
            setJobSeekerDetails(res);
            $("#firstName").val(res.data.firstName);
            $("#lastName").val(res.data.lastName);
            $("#email").val(res.data.email);
            $("#phone").val(res.data.phone);
            $("#personalProfile").val(res.data.personalProfile);
            $("#education").val(res.data.educationExperiance);
            $("#lookingFor").val(res.data.lookingFor);
            $("#jobType").val(res.data.jobType);
            $("#resume").val(res.data.pdfByString);
            
            setJobSeekerPath(res.data.resumePath);
           
            setJobType(res.data.jobType);
            var rspname = res.data.resumePath;
            var rsext = rspname.split('.').pop();
            if(rsext == "pdf"){
                $("#pdfview").show();
                $("#docview").html('');

            }else{
              $("#pdfview").html();
              $("#docview").show();
            }
            console.log("jobseeker@@@@@@@@"+JSON.stringify(res.data));

            setCategoriesSelected(res.data.categoriesOfExpertise);
            var companyCatIn = res.data.categoriesOfExpertise;
           // alert(companyCatIn);

            //$('#jobType').prop('selectedIndex', res.data.jobType);  
            $('#jobType').val( res.data.jobType);  
            //alert("res.data.jobType"+res.data.jobType);


            
      
      
            setTimeout(() => {
              for (var i = 0; i < companyCatIn.length; i++) {
                //$("input[value='" + val + "']").prop('checked', true);
                console.log("###" + companyCatIn[i]);
                $('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
                //$('input[name="category"][value="' + companyCatIn[i] + '"]').trigger("click");
              }
              validateCheck();
              var downloadval = sessionStorage.getItem(resumedownloaded);
              //alert(downloadval);
              if(downloadval != 1){
                //alert('download');
               setPdfDownload(res.data.resumePath);
                sessionStorage.setItem("resumedownloaded", "1");
              }
              
              
            }, 2000);
      

        });
     }

    const [categories, setCategory] = useState([]);

 
  

    const {
        register,
        formState: { errors },
        handleSubmit
      } = useForm({
        criteriaMode: "all", mode: "onBlur"
      });
    

    useEffect(() => {
      // const jsonData = {
      //   "viewResume": {
          
      //   }
      // };
      // var jsondataformatted = JSON.stringify(jsonData);
      // sessionStorage.setItem('pagejsonObject', jsondataformatted);

      if(jobSeekerId == null || jobSeekerId == "null"){            
        //navigate('/login');
        navigate('/Home');
      }
      $("#pdfview").hide();
      $("#docview").hide();

      $("#successMessage").hide();
        $('#phone').usPhoneFormat();
      getIndustryData();
      getProfile(jobSeekerId);
      $("#fileError").hide();
      //setPdfDownload(jobSeekerResumePath);
      Services.getJobType().then((res) => {
        console.log("setJobType data=" + JSON.stringify(res.data));
        setJobTypes(res.data.content);
      });

    }, []);
  
    const getIndustryData = () => {
  
      Services.getIndustryData().then((res) => {
  
        console.log("Industry data=" + JSON.stringify(res.data));
  
        setCategory(res.data.content);
  
      });
  
    }


    function validateCategory() {
      // alert("@@category");
      var isCheck = true;
  
      var checkedValues = $("input[name='category']:checked");
      // var checkedValues = [];
      //     $.each($("input[name='category']:checked"), function () {
      //       checkedValues.push($(this).val());
      //     });
      //  alert(checkedValues);
      // alert(checkedValues.length);
      var lenghtCheck=checkedValues.length;
      if (lenghtCheck==0) {
        isCheck = false;
        $("#categoryError").html("Category field is required");
      } else {
        // alert(checkedValues);
        isCheck = true;
        $("#categoryError").html("");
      }
  
      return isCheck;
    }

   const validateCheck = () =>{
        
        const checked=$('input[name="category"]:checked').length;
    
        if (checked >= 3) {
          $('input[name="category"]').not(':checked').prop('disabled', true);
        } else {
          $('input[name="category"]').prop('disabled', false);
        }
      }

  
  return(
  <>
<main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                    <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb"> 
                            <ol class="breadcrumb">
                            <li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li>
                                <li class="breadcrumb-item headtitle ">View Resume</li>

                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">
                                {/* <!-- left side --> */}
                                <SidebarProfile/>
                               

                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">
                                 {/* <a href={jobSeekerResumePath} download="resume"><button type="button">Download</button></a>  */}
                                {/* {jobSeekerResumePath}  */}
                                  {/* <iframe src={jobSeekerResumePath} width="100%" height="750" ></iframe>     */}

                                  <div id="pdfview">
                                  <iframe src={jobSeekerResumePath} width="100%" height="750" ></iframe>  
                                  </div>
                                  

                                  <div id="docview">
                                    <iframe src={'https://view.officeapps.live.com/op/embed.aspx?src='+jobSeekerResumePath} width="100%" height="750" frameborder='0'></iframe>
                                  </div>
                               


                            


                            


    

                           


                              

                              
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>

    </main>
  </>
)};

ViewResume.propTypes = {};

ViewResume.defaultProps = {};

export default ViewResume;
