import React,{history,useEffect, useState, useMemo } from "react";
import Quick_links from "../../utility/Quick_links";
import Hiring from "../Jobs/Hiring";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import services from "../../services/services";
import { useNavigate, useParams } from "react-router-dom";

const HiringWidget = () => {
   const [itemName, setFieldName] = useState('');

   //const companyId = 24;
   const companyId=sessionStorage.getItem("logCompanyId");
   //const siteId = 2;
   const siteId = process.env.React_App_SITE_ID;

   const navigate = useNavigate();

   const [videoUrl, setVideo] = useState([]);
   const [jobList, setJobList] = useState([]);
   const staticData = require('../../constants/'+process.env.React_App_Static_Folder+'/static-data.json');

   useEffect(() => {
   //    const jsonData = {
   //       "hiring": {
           
   //       }
   //     };

   //   var jsondataformatted = JSON.stringify(jsonData);
   //   sessionStorage.setItem('pagejsonObject', jsondataformatted);
     
      getTopCompanyHiringList();

   }, []);



   const getTopCompanyHiringList = () => {
      services.getTopCompanyHiring(siteId).then((res) => {
         // console.log(JSON.stringify(res));
        if(res.data){
         setJobList(res.data);
        }

      });
   }

   const {
      register,
      formState: { errors },
      handleSubmit
   } = useForm({
      criteriaMode: "all", mode: "onBlur"
   });

   const submitValue = () => {



   }

  return(

    <>
        <section class="white-bg py-5 px-0">
            <div class="container">
               <div class="row mb-4">
                  <div class="col-12">
                     <div class="d-flex justify-content-between border-bottom  mb-4 pb-4 flex-lg-row flex-column">
                        <h4 class="headtitle">Top companies hiring in your area</h4>
                        <a href="/availableJobs" class="text-uppercase">See All</a>
                     </div>
                  </div>
               </div>
               <div class="row">
               {
                jobList.map((data, index) => (
                  <div class="col-lg-4">
                    <a href={`/companyDetails?companyId=`+data.companyId}><div class="card p-3">
                        {/* <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/> */}
                        {/* <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/> */}
                        {/* <img src={data.bannerUrl} class="w-100 mb-2 hiring-img " alt=""/> */}
                        <img src={data.bannerUrl? data.bannerUrl: staticData.default_logo} class="w-100 mb-2 hiring-img" alt=""/>
                        <img src={data.logoUrl? data.logoUrl:staticData.default_logo} class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>{data.name}</h3>
                              {data.jobShadowConcert == 'Y' ? <a style={{backgroundColor:"transparent"}} class="tool tool-pos-ab" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="This company provides job shadow opportunities"><div class="js-badge jsb-round"> <img src="images/badge.png" alt="" class="w-100" /></div></a> : ''}



                        <span>{data.address1 != '' ? data.address1 : ''}
                        {/*{data.address1 != '' && data.address2 != '' ? ', ' : ''}*/}
                        {data.address1 != '' && data.address2 != '' ? ', ' : ''}
                        {data.address2 != '' ? data.address2 : ''}<br></br>
                        {data.city != '' ? data.city : ''}
                        {data.city != '' && data.state != '' ? ', ' : ''}
                        {data.state != '' ? data.state : ''}
                        {data.state != '' && data.zip != '' ? ', ' : ''}
                        {data.zip != '' ? data.zip : ''}
                        </span>
                        <a href={`jobsByCompany?companyId=`+data.companyId}>{data.jobCount} OPEN JOBS</a>
                        </div>
                     </div></a>
                  </div>
                   ))}



                  {/* <div class="col-lg-4">
                  <a href="/companyDetails"><div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div></a>
                  </div>
                  <div class="col-lg-4">
                  <a href="/companyDetails"><div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div></a>
                  </div> */}

               </div>
            </div>
         </section>
        </>

)};

HiringWidget.propTypes = {};

HiringWidget.defaultProps = {};

export default HiringWidget;