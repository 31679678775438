import React, { history,useEffect, useState,  useMemo } from "react";
import { useNavigate,useParams, useLocation, Link } from "react-router-dom";
//import { useGlobalState } from 'use-global-state-react';
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import moment from "moment";
import UsNumberValidate from '../../utility/usNumberValidate';
import Services from "../../services/services";
//import PaginationComponent from "../../utility/Pagination/Pagination";
import Pagination from "../../utility/Pagination/Pagination";

import ReactExport from "react-data-export";
//import { format, compareAsc } from 'date-fns'

const StudentListBySchool = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var adpagenum = queryParams.get('adpage');

    const navigate = useNavigate();
    const [categories, setCategory] = useState([]);
    const [profileList, setProfileList] = useState([]);
    const [profileList_m, setProfileList_m] = useState([]);

    const [profileListFull2, setProfileListFull] = useState([]);

    const [currentYearList, setCurrentYearList] = useState([]);
    const [jobTypes, setJobType] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [itemName, setFieldName] = useState('');
    const ITEMS_PER_PAGE = 10; // Per page show the data pagination
    const Pagesize=5;
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [schoolId, setSchoolId] = useState('');
   const [schoolIdVal, setSchoolIdVal] = useState('');
   //const [schoolIdVal, setSchoolIdVal] = useGlobalState<string>('');
    
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage_m, setCurrentPage_m] = useState(1);
    const [totalItems_m, setTotalItems_m] = useState(0);
    const [totalpages_m, settotalPages_m] = useState();
    const companyId=sessionStorage.getItem("logCompanyId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");
    const chKRoleAr=sessionStorage.getItem("roleAr");
    var globalSchoolId = '';
    const [schoolIds, setSchoolInData] = useState([]);
   // const [schoolIdVal, setSchoolIdVal] = useState('');

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    var FileSaver = require('file-saver');
    var session_pageobj = '';
    var webfilters = 1;
    var mobfilters = 1;

    function download_excel(profileListFull){
     //   console.log(profileListFull);

        var today = new Date();
        var month = today.toLocaleString('en-US', {month: 'short'});
        var date= today.getDate();
        var year = today.getFullYear();

        var filename = "student-export-"+month+"-"+date+"-"+year+".csv";
       // console.log(filename);
        let csv = '';
        let row = '';
        let headers = '';

        var jobtypeslisthead = 'Created On'+','+'Updated On'+','+'Name'+','+'Email'+','+'Phone'+','+'Pursuing'+','+'School District'+','+'Class Year'+',';

        var jobtypes_sorted= jobTypes.sort((a, b)=> b.jobTypeId - a.jobTypeId)
        for(var jb=0; jb < jobtypes_sorted.length; jb++){
            jobtypeslisthead = jobtypeslisthead+jobtypes_sorted[jb].name+',';
        }
        jobtypeslisthead = jobtypeslisthead+'Reason for Select Career'+','+'Experience'+','+'Comments'+','+'Resume Uploaded'+','+'Parent Consent'+','+'Interested Categories';
        console.log(jobtypeslisthead);
        headers = headers.concat(jobtypeslisthead);
        csv = csv.concat(headers);
        row = '';
        for(var i=0; i<profileListFull.length; i++){
            var cats = profileListFull[i].categoryName;
            var jobtypenames = profileListFull[i].jobTypeName;
            var dateUpdated = new Date(profileListFull[i].dateUpdated);
            var dateCreated = new Date(profileListFull[i].dateCreated);
            //var displayupdated =  dateUpdated.getDate()+'-'+dateUpdated.toLocaleString('en-US', {month: 'short'})+'-'+dateUpdated.getFullYear();


            var fmonth = ("0" + (dateUpdated.getMonth() + 1)).slice(-2);
            var fdate = ("0" + dateUpdated.getDate()).slice(-2);
            var fyear = dateUpdated.getFullYear();
    
           var displayupdated =  fmonth+'/'+fdate+'/'+fyear;
            //var displayupdated = moment(dateUpdated).format('MM/DD/YYYY');
            console.log(displayupdated+' - '+dateUpdated);

            //{moment(data.dateUpdated).format("MM/DD/YYYY")}

            var cfmonth = ("0" + (dateCreated.getMonth() + 1)).slice(-2);
            var cfdate = ("0" + dateCreated.getDate()).slice(-2);
            var cfyear = dateCreated.getFullYear();
    
            var displaycreated =  cfmonth+'/'+cfdate+'/'+cfyear;
            console.log(displaycreated+' - '+dateCreated);

            var jobtypeslistrow = "";

            var selectedcategs = cats.join('; ');

            if(profileListFull[i].email == null || profileListFull[i].email == ''){
                var email = '--';
              }
              else {
                var email = profileListFull[i].email;
              }

              if(profileListFull[i].phoneno == null || profileListFull[i].phoneno == ''){
                var phoneno = '--';
              }
              else {
                var phoneno = profileListFull[i].phoneno;
              }

              if(profileListFull[i].educationId == 3){
                if(profileListFull[i].name == null || profileListFull[i].name == ''){
                    var schoolname = '--';
                  }
                  else {
                    var schoolname = profileListFull[i].name;
                  }
              } else {
                if(profileListFull[i].nameOfCollegeUniversity == null || profileListFull[i].nameOfCollegeUniversity == ''){
                    var schoolname = '--';
                  }
                  else {
                    var schoolname = profileListFull[i].nameOfCollegeUniversity;
                  }
              }

              if(profileListFull[i].classYear == null || profileListFull[i].classYear == ''){
                var classYear = '--';
              }
              else {
                var classYear = profileListFull[i].classYear;
              }

             /* var career_reason = profileListFull[i].reasonForSelectCareers;
              career_reason = career_reason.toString();
              career_reason = career_reason.replace( /(<([^>]+)>)/ig, '');
              career_reason = career_reason.replace( /(\n)/ig, '');

              var experiance_info = profileListFull[i].experiance;
              experiance_info = experiance_info.toString();
              experiance_info = experiance_info.replace( /(<([^>]+)>)/ig, '');
              experiance_info = experiance_info.replace( /(\n)/ig, '');

              var comments_info = profileListFull[i].comments;
              comments_info = comments_info.toString();
              comments_info = comments_info.replace( /(<([^>]+)>)/ig, '');
              comments_info = comments_info.replace( /(\n)/ig, ''); */


              var career_reason = profileListFull[i].reasonForSelectCareers;
              if(career_reason == null || career_reason == 'null'){
                career_reason = '--';
            }else {
                if(career_reason){
                    career_reason = career_reason.toString();
                    career_reason = career_reason.replace( /(<([^>]+)>)/ig, '');
                    career_reason = career_reason.replace( /(\n)/ig, '');
                    career_reason = career_reason.replace( /(\r)/ig, '');
                    career_reason = career_reason.replace(/,/g, "-");
                }
            }
              

              var experiance_info = profileListFull[i].experiance;
              if(experiance_info == null || experiance_info == 'null'){
                experiance_info = '--';
            }else {
                if(experiance_info){
                    experiance_info = experiance_info.toString();
                    experiance_info = experiance_info.replace( /(<([^>]+)>)/ig, '');
                    experiance_info = experiance_info.replace( /(\n)/ig, '');
                    experiance_info = experiance_info.replace( /(\r)/ig, '');
                    experiance_info = experiance_info.replace(/,/g, "-");
                    
                  }
            }
              

              var comments_info = profileListFull[i].comments;
              if(comments_info == null || comments_info == 'null'){
                comments_info = '--';
            } else {
                if(comments_info){
                    comments_info = comments_info.toString();
                    comments_info = comments_info.replace( /(<([^>]+)>)/ig, '');
                    comments_info = comments_info.replace( /(\n)/ig, '');
                    comments_info = comments_info.replace( /(\r)/ig, '');
                    comments_info = comments_info.replace(/,/g, "-");
                    
                  }
            }

            jobtypeslistrow = "\n"+displaycreated+',"'+displayupdated+'",'+profileListFull[i].firstName+" "+profileListFull[i].lastName+','+email+','+phoneno+','+profileListFull[i].educationName+','+schoolname+','+classYear+',';

            for(var jb=0; jb < jobtypes_sorted.length; jb++){
                if(jobtypenames.includes(jobtypes_sorted[jb].name)){
                    jobtypeslistrow = jobtypeslistrow+'Y ,';
                }
                else {
                    jobtypeslistrow = jobtypeslistrow+' ,';
                }

            }

            if(profileListFull[i].resumeFilePathUrl == null || profileListFull[i].resumeFilePathUrl == 'null' || profileListFull[i].resumeFilePathUrl == ''){
                var resume_uploaded = 'N';
            } else {
                var resume_uploaded = 'Y';
            }
            
            if(profileListFull[i].educationId == 3){
                //school
                if(profileListFull[i].authorization == 'yes' || profileListFull[i].authorization == 'Yes'){
                    var parent_consent = 'Y';
                }
                else if(profileListFull[i].authorization == 'no' || profileListFull[i].authorization == 'No'){
                    var parent_consent = 'N';
                } 
                else {
                    var parent_consent = '--';
                }
            }
            else {
                //college
                
                    var parent_consent = 'N/A';
                
            }

            jobtypeslistrow = jobtypeslistrow+career_reason+','+experiance_info+','+comments_info+','+resume_uploaded+','+parent_consent+','+selectedcategs.replace(/,/g, "-");

            row = row.concat(jobtypeslistrow);

        }
        csv = csv.concat(row);

        var file = new File([csv], filename, {type: "application/csv"});
        FileSaver.saveAs(file);
        $(".load-wrap").hide();
    }

    const getSchoolIdbyUserId = (userIdChkLogin) =>{
        Services.getSchoolAdminInfoByUserId(userIdChkLogin).then((res) => {
            console.log("Industry data=" + JSON.stringify(res.data));
            //alert(res.data.schoolId)
            var temp_id = res.data.schoolId;
            //alert(temp_id+"---.temp")
            setSchoolId(temp_id);
            setSchoolIdVal(temp_id);
            globalSchoolId = res.data.schoolId;

            setTimeout(() => {
               // alert("152-->"+schoolIdVal);
                //alert("155-->"+temp_id);
                filterContent(ITEMS_PER_PAGE,'1',globalSchoolId, '0','0');
                filterContent_m(ITEMS_PER_PAGE,'1',globalSchoolId, '0','0');
            }, 500);
                
          

        });
        //setCurrentYearList(years);
      }

    const getServiceSchoolIdbyUserId = (userIdChkLogin) =>{
        Services.getSchoolAdminInfoByUserId(userIdChkLogin).then((res) => {
            var temp_id = res.data.schoolId;
            setSchoolId(temp_id);
            setSchoolIdVal(temp_id);
            globalSchoolId = res.data.schoolId;
console.log(' *** 288 *** '+globalSchoolId);
            var serviceresponse = temp_id;
            //var storetypeval = JSON.stringify(serviceresponse);
            sessionStorage.setItem('adminschoolid', serviceresponse);
           /* setTimeout(() => {
               // alert("152-->"+schoolIdVal);
                //alert("155-->"+temp_id);
                filterContent(ITEMS_PER_PAGE,'1',globalSchoolId, '0','0');
                filterContent_m(ITEMS_PER_PAGE,'1',globalSchoolId, '0','0');
            }, 500);*/
        });
        //setCurrentYearList(years);
      }

    const getLocalSchoolIdbyUserId = () => {
        var localschoollistval = sessionStorage.getItem('adminschoolid');
       // var tempschoolslist = JSON.parse(localschoollistval);
        var schoolid= localschoollistval;
        setSchoolId(schoolid);
        setSchoolIdVal(schoolid);
        globalSchoolId = schoolid;
    }

    const getCurrentYear = () =>{
        var currentYear = new Date().getFullYear()
        var years = [];
        years.push(currentYear);
        for(var i=1;i<7;i++){
          years.push(currentYear+i);
        }
        setCurrentYearList(years);
        setTimeout(() => {
            //var loc_yearval = localStorage.getItem('sad_year_selected');
            var loc_yearval = session_pageobj['classYear'];
            yearSelCheckLS(loc_yearval);
            if(loc_yearval != ""){
               // filterContent(ITEMS_PER_PAGE,'1',schoolId, '0');
            }

            //var loc_yearval_m = localStorage.getItem('sad_year_selected_m');
            var loc_yearval_m = session_pageobj['classYear_m'];
            yearSelCheckLS_m(loc_yearval_m);
            if(loc_yearval_m != ""){
                //filterContent_m(ITEMS_PER_PAGE,'1',schoolId, '0');
            }
        }, 500);
      }

    function yearSelCheckLS(local_yeararray) {
        if(local_yeararray != undefined || local_yeararray != null){
            var local_yeararray = local_yeararray.toString();
            var schoolyearval = local_yeararray.split(",");
            for (var i = 0; i < schoolyearval.length; i++) {
                $('input[name="schoolYear"][value="' + schoolyearval[i] + '"]').prop("checked", true);
            }
        }
    }

    function yearSelCheckLS_m(local_yeararray_m) {
        if(local_yeararray_m != undefined || local_yeararray_m != null){
            var local_yeararray_m = local_yeararray_m.toString();
            var schoolyearval_m = local_yeararray_m.split(",");
            for (var i = 0; i < schoolyearval_m.length; i++) {
                $('input[name="schoolYear_m"][value="' + schoolyearval_m[i] + '"]').prop("checked", true);
            }
        }
    }


    function strCatArr(arr){
        if(Array.isArray(arr)){
          return arr.join(", ");
        }else{
          return "";
        }
      }


      const cardsloop = [1,2,3,4,5,6,7,8,9,10];
    useEffect(() => {
        $('.doublecards').show();
        if(chKRoleAr != "schooladmin" ){
            navigate('/Home');
        }

        $(".load-wrap").hide();
        // Getting the keys of the JSON object
        var keys = sessionStorage.getItem('pagejsonObject');
        if(keys == null || keys == undefined){
            navigate('/Home');
        } else {
        var keys_parsed = JSON.parse(keys);
        var pageobject = Object.keys(keys_parsed);
        // Checking if there is at least one key
        if (pageobject.length > 0) {
            // Accessing the first key
            var firstKey = pageobject[0];
            if(firstKey == 'studentListBySchool'){
                var pageobj = keys_parsed[firstKey];
                session_pageobj = keys_parsed[firstKey];
                var dev_poff = pageobj['pagination_val'];
                var dev_poff_m = pageobj['pagination_val_m'];
                if(dev_poff == ''){
                    if(dev_poff_m == ''){
                        adpagenum = '1';
                    }
                    else {
                        adpagenum = dev_poff_m; 
                    } 
                }
                else{
                    adpagenum = dev_poff; 
                }
            } else {
                adpagenum = '1';
                sessionStorage.removeItem('master_industry');
                sessionStorage.removeItem('master_jobtype');
                sessionStorage.removeItem('adminschoolid');
                sessionStorage.removeItem('school_list');
            }
        } else {
            //json empty
            adpagenum = '1';
            sessionStorage.removeItem('master_industry');
            sessionStorage.removeItem('master_jobtype');
            sessionStorage.removeItem('adminschoolid');
            sessionStorage.removeItem('school_list');
        }
    }

        if (adpagenum == null || adpagenum == '') {
            console.log('here');
            //alert("set")
            // localStorage.setItem('sad_industry_selected', '');
            // localStorage.setItem('sad_year_selected', '');
            // localStorage.setItem('sad_search_text', '');
            // localStorage.setItem('sad_industry_selected_m', '');
            // localStorage.setItem('sad_year_selected_m', '');
            // localStorage.setItem('sad_search_text_m', '');

            // sessionStorage.setItem('sad_industry_selected', '');
            // sessionStorage.setItem('sad_year_selected', '');
            // sessionStorage.setItem('sad_search_text', '');
            // sessionStorage.setItem('sad_industry_selected_m', '');
            // sessionStorage.setItem('sad_year_selected_m', '');
            // sessionStorage.setItem('sad_search_text_m', '');

            // sessionStorage.setItem('sad_page_offset', '');
            // sessionStorage.setItem('sad_page_offset_m', '');
            var web_searchtxt = session_pageobj['search_text'];
            var web_industry = session_pageobj['industry'];
            var web_classYear = session_pageobj['classYear'];
            if(web_searchtxt == '' && web_classYear == '' && web_industry == ''){
                var webfilters = 0;
            }

            var mob_searchtxt = session_pageobj['search_text_m'];
            var mob_industry = session_pageobj['industry_m'];
            var mob_classYear = session_pageobj['classYear_m'];
            if(mob_searchtxt == '' && mob_classYear == '' && mob_industry == ''){
                var mobfilters = 0;
            }

            if(webfilters == 0 && mobfilters == 0){
            var jsonData = {
                "studentListBySchool": {
                  "industry": '',
                  "classYear": '',
                  "search_text": "",
                  "pagination_val": '',
                  "industry_m": '',
                  "classYear_m": '',
                  "search_text_m": "",
                  "pagination_val_m": '',
                }
              };

            var jsondataformatted = JSON.stringify(jsonData);
            sessionStorage.setItem('pagejsonObject', jsondataformatted);
            }
        }

        
        $('#phone').usPhoneFormat();
        //getProfileInfo();
        //getIndustryData();
        //getJobType();
        getCurrentYear();
        //getSchoolnData();
        

        var ind_master = sessionStorage.getItem('master_industry');
        if(ind_master == null || ind_master == ''){
            getServiceIndustryData();
        }
        else {
            getLocalIndustryData();
        }

        var jobtype_master = sessionStorage.getItem('master_jobtype');
        if(jobtype_master == null || jobtype_master == ''){
            getServiceJobType();
        }
        else {
            getLocalJobType();
        }

        var masterschoolid = sessionStorage.getItem('adminschoolid');
        if(masterschoolid == null || masterschoolid == ''){
             getServiceSchoolIdbyUserId(userIdChkLogin);
        }
        else {
             getLocalSchoolIdbyUserId();
        } 

        var schools_master = sessionStorage.getItem('school_list');
        if(schools_master == null || schools_master == ''){
            getServiceSchoolnData();
        }
        else {
            getLocalSchoolnData();
        }

        

        if(chKRoleAr == "schooladmin" ){

           // getSchoolIdbyUserId(userIdChkLogin);

        // var t_sad_search_text = localStorage.getItem('sad_search_text');
        var t_sad_search_text = session_pageobj['search_text'];
        $(".searchKeyInput").val(t_sad_search_text);

        // var t_sad_search_text_m = localStorage.getItem('sad_search_text_m');
        var t_sad_search_text_m = session_pageobj['search_text_m'];
        $(".searchKeyInput_m").val(t_sad_search_text_m);

        // filterContent(ITEMS_PER_PAGE,'1');
        $( document ).on( "click", ".searchContent", function() {
            filterContent(ITEMS_PER_PAGE,'1',schoolId, '1','0');
        });
        $( document ).on( "change", ".searchKeyInput", function() {
            filterContent(ITEMS_PER_PAGE,'1',schoolId, '1','0');
        });

        $( document ).on( "click", ".searchContent_m", function() {
            filterContent_m(ITEMS_PER_PAGE,'1',schoolId, '1','0');
        });
        $( document ).on( "change", ".searchKeyInput_m", function() {
            filterContent_m(ITEMS_PER_PAGE,'1',schoolId, '1','0');
        });
    }else{
        navigate('/Home');
    }
        
        $(".paginationContent").hide();
        setTimeout(() => {
            if (adpagenum != null && adpagenum != "") {
                    // var web_ind = localStorage.getItem('sad_industry_selected');
                    // var web_yer = localStorage.getItem('sad_year_selected');
                    // var web_src = localStorage.getItem('sad_search_text');

                    var web_ind = session_pageobj['industry'];
                    var web_yer = session_pageobj['classYear'];
                    var web_src = session_pageobj['search_text'];
                    var pageoffset = session_pageobj['pagination_val'];

                    if (web_ind != "" || web_yer != '' || web_src != '' || pageoffset != ''){
                        setTimeout(() => {
                            filterContent(ITEMS_PER_PAGE,adpagenum,schoolId, '0','0');
                            //filterContent_m(ITEMS_PER_PAGE,adpagenum,schoolId, '0');
                        }, 500);
                    }
                    else {
                        // var mob_ind = localStorage.getItem('sad_industry_selected_m');
                        // var mob_yer = localStorage.getItem('sad_year_selected_m');
                        // var mob_src = localStorage.getItem('sad_search_text_m');

                        var mob_ind = session_pageobj['industry_m'];
                        var mob_yer = session_pageobj['classYear_m'];
                        var mob_src = session_pageobj['search_text_m'];
                        var pageoffset_m = session_pageobj['pagination_val_m'];

                        if (mob_ind != "" || mob_yer != '' || mob_src != '' || pageoffset_m != ''){
                            setTimeout(() => {
                                //filterContent(ITEMS_PER_PAGE,adpagenum,schoolId, '0');
                                filterContent_m(ITEMS_PER_PAGE,adpagenum,schoolId, '0','0');
                            }, 500);
                        }
                        else {
                            filterContent(ITEMS_PER_PAGE,adpagenum,schoolId, '0','0');
                            filterContent_m(ITEMS_PER_PAGE,adpagenum,schoolId, '0','0');
                        }
                    }
            } else{
                filterContent(ITEMS_PER_PAGE,'1',schoolId, '0','0');
                filterContent_m(ITEMS_PER_PAGE,'1',schoolId, '0','0');
            }
        }, 500);

    }, []);

    function getProfileInfo() {

        //var companyId = "24";


        Services.getCompanyProfile(companyId).then((res) => {


          setCategoriesSelected(res.data.companyCategory);
          var companyCatIn = res.data.companyCategory;

          //navigate('/thankyou');
        });
      }
    const handlePageChange2 = (offset,triggerevent) => {
        
        filterContent(ITEMS_PER_PAGE,offset,schoolId,'0','1');
      };
      const handlePageChange2_m = (offset,triggerevent) => {
        
        filterContent_m(ITEMS_PER_PAGE,offset,schoolId,'0','1');
      };

    const filterContent = (ITEMS_PER_PAGE,pageNumber,schoolId, setlcstorage, positiontop) => {
        var categoriesArray = [];

       //alert("globalSchoolId:"+globalSchoolId+"----schoolId:"+schoolId+"---schoolIdVal:"+schoolIdVal)
        $.each($("input[name='category']:checked"), function(){
            categoriesArray.push($(this).val());
        });
        //alert(JSON.stringify(categoriesArray));
        //alert("schoolId-->"+schoolId);
        if(schoolIdVal != ""){
            var schoolIdArray = [schoolIdVal];
        }else if(schoolId != ""){
            var schoolIdArray = [schoolId];
        }else{
            var schoolIdArray = [globalSchoolId];
        }
       
        //var schoolIdArray = [globalSchoolId];
       //var schoolIdArray = [schoolIdVal];
        


        var schoolYearArray = [];
        $.each($("input[name='schoolYear']:checked"), function(){
            schoolYearArray.push($(this).val());
        });

        var searchkey = $('.searchKeyInput').val();

        if (setlcstorage == "1") {
            var industries_val2 = '';
            if (categoriesArray != '') {
                //industries_val2 = JSON.parse(categoriesArray);
                industries_val2 = categoriesArray;
            }

            var year_val2 = '';
            if (schoolYearArray != '') {
                //year_val2 = JSON.parse(schoolYearArray);
                year_val2 = schoolYearArray;
            }

            // localStorage.setItem('sad_industry_selected', industries_val2);
            // localStorage.setItem('sad_year_selected', year_val2);
            // localStorage.setItem('sad_search_text', searchkey);

            // sessionStorage.setItem('sad_industry_selected', industries_val2);
            // sessionStorage.setItem('sad_year_selected', year_val2);
            // sessionStorage.setItem('sad_search_text', searchkey);
        }
        else {
            /*localStorage.setItem('av_search_text', '');
            localStorage.setItem('av_location_selected', '');
            localStorage.setItem('av_education_selected', '');
            localStorage.setItem('av_jobtype_selected', '');
            localStorage.setItem('av_industry_selected', ''); */
        }

        if(pageNumber > 1){
            var jsonData = {
                "studentListBySchool": {
                  "industry": categoriesArray,
                  "classYear": schoolYearArray,
                  "search_text": searchkey,
                  "pagination_val": pageNumber,
                  "industry_m": '',
                  "classYear_m": '',
                  "search_text_m": "",
                  "pagination_val_m": '',
                }
              };
        } else {
            var jsonData = {
                "studentListBySchool": {
                  "industry": categoriesArray,
                  "classYear": schoolYearArray,
                  "search_text": searchkey,
                  "pagination_val": '',
                  "industry_m": '',
                  "classYear_m": '',
                  "search_text_m": "",
                  "pagination_val_m": '',
                }
              };
        }

        

        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);
        var serachkeyinput = $(".searchKeyInput").val();
if(serachkeyinput != undefined){
            serachkeyinput = serachkeyinput.trim();
        }
        const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "classYear": schoolYearArray,
            "schoolId": schoolIdArray,
            "pageNumber" :pageNumber,
            "pageSize":ITEMS_PER_PAGE,
            "titleSummary":serachkeyinput,
            "sortBy" : "updatedOn",
            "jobType": [],
            "companyUser":"no",
            "educationId":'3'
          }

          var accessTokenIn=sessionStorage.getItem("accToken");
          console.log(requestObject);
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                      'Authorization': "Bearer "+accessTokenIn
                      //'Authorization': accessTokenIn
                    },
            body: JSON.stringify(requestObject)
            };
            $(".doublecards").show();
            Services.findFilterStudent(requestOptions).then((res) =>
            {


                if(res.content){
                    console.log(JSON.stringify(res));

                    $(".paginationContent").show()
                    if(positiontop == 1){
                        window.scrollTo({ top: 100, behavior: 'smooth' });
                    }
                    setTotalItems(res.totalElements);
                    settotalPages(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                    setCurrentPage(pageNumber);

                    var contAr = res.content;
                   
                    for (var i = 0; i < contAr.length; i++) {
                        var jobtypename = contAr[i].jobTypeName;
                        var dummay_jobtype = [];
                        for (var j = 0; j < jobtypename.length; j++) {
                            var stored_jobtype = jobtypename[j];
                            var typename = stored_jobtype.split("(");
                            dummay_jobtype.push(typename[0]);
                        }

                        contAr[i].jobtypename_new = dummay_jobtype;
                    } 
                    console.log(contAr);
                    setProfileList(contAr);
                   // filterContent_full();
                    if(contAr.length>0){
                        $(".paginationContent").show();
                    }else{
                        $(".paginationContent").hide();
                    }
               }
            });
            $(".doublecards").hide();
    }

    const filterContent_m = (ITEMS_PER_PAGE,pageNumber,schoolId, setlcstorage, positiontop) => {
        var categoriesArray = [];

       // alert(globalSchoolId)
        $.each($("input[name='category_m']:checked"), function(){
            categoriesArray.push($(this).val());
        });
        //var schoolIdArray = [globalSchoolId];
        //var schoolIdArray = [schoolId];

        if(schoolIdVal != ""){
            var schoolIdArray = [schoolIdVal];
        }else if(schoolId != ""){
            var schoolIdArray = [schoolId];
        }else{
            var schoolIdArray = [globalSchoolId];
        }


        var schoolYearArray = [];
        $.each($("input[name='schoolYear_m']:checked"), function(){
            schoolYearArray.push($(this).val());
        });

        var searchkey = $('.searchKeyInput_m').val();

        if (setlcstorage == "1") {
            var industries_val2 = '';
            if (categoriesArray != '') {
                //industries_val2 = JSON.parse(categoriesArray);
                industries_val2 = categoriesArray;
            }

            var year_val2 = '';
            if (schoolYearArray != '') {
                //year_val2 = JSON.parse(schoolYearArray);
                year_val2 = schoolYearArray;
            }

            // localStorage.setItem('sad_industry_selected_m', industries_val2);
            // localStorage.setItem('sad_year_selected_m', year_val2);
            // localStorage.setItem('sad_search_text_m', searchkey);

            // sessionStorage.setItem('sad_industry_selected_m', industries_val2);
            // sessionStorage.setItem('sad_year_selected_m', year_val2);
            // sessionStorage.setItem('sad_search_text_m', searchkey);
            
        }
        else {
            /*localStorage.setItem('av_search_text', '');
            localStorage.setItem('av_location_selected', '');
            localStorage.setItem('av_education_selected', '');
            localStorage.setItem('av_jobtype_selected', '');
            localStorage.setItem('av_industry_selected', ''); */
        }

        if(pageNumber > 1){
            var jsonData = {
                "studentListBySchool": {
                  "industry": '',
                  "classYear": '',
                  "search_text": '',
                  "pagination_val": '',
                  "industry_m": categoriesArray,
                  "classYear_m": schoolYearArray,
                  "search_text_m": searchkey,
                  "pagination_val_m": pageNumber,
                }
              };
        } else {
            var jsonData = {
                "studentListBySchool": {
                  "industry": '',
                  "classYear": '',
                  "search_text": '',
                  "pagination_val": '',
                  "industry_m": categoriesArray,
                  "classYear_m": schoolYearArray,
                  "search_text_m": searchkey,
                  "pagination_val_m": '',
                }
              };
        }

        

        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);
        var serachkeyinput = $(".searchKeyInput_m").val();
        if(serachkeyinput != undefined){
                    serachkeyinput = serachkeyinput.trim();
                }
        const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "classYear": schoolYearArray,
            "schoolId": schoolIdArray,
            "pageNumber" :pageNumber,
            "pageSize":ITEMS_PER_PAGE,
            "titleSummary":serachkeyinput,
            "sortBy" : "updatedOn",
            "jobType": [],
            "companyUser":"no",
            "educationId":'3',
          }

          var accessTokenIn=sessionStorage.getItem("accToken");
          console.log(requestObject);
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                      'Authorization': "Bearer "+accessTokenIn
                      //'Authorization': accessTokenIn
                    },
            body: JSON.stringify(requestObject)
            };
            $('.doublecards').show();
            Services.findFilterStudent(requestOptions).then((res) =>
            {


                if(res.content){
                    console.log(JSON.stringify(res));

                    $(".paginationContent").show()
                    if(positiontop == 1){
                        window.scrollTo({ top: 100, behavior: 'smooth' });
                    }
                    setTotalItems_m(res.totalElements);
                    settotalPages_m(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                    setCurrentPage_m(pageNumber);

                    var contAr = res.content;
                    for (var i = 0; i < contAr.length; i++) {
                        var jobtypename = contAr[i].jobTypeName;
                        var dummay_jobtype = [];
                        for (var j = 0; j < jobtypename.length; j++) {
                            var stored_jobtype = jobtypename[j];
                            var typename = stored_jobtype.split("(");
                            dummay_jobtype.push(typename[0]);
                        }

                        contAr[i].jobtypename_new = dummay_jobtype;
                    } 
                    setProfileList_m(contAr);
                   // filterContent_full();
                    if(contAr.length>0){
                        $(".paginationContent").show();
                    }else{
                        $(".paginationContent").hide();
                    }
               }
            });
            $('.doublecards').hide();
    }

    const filterContent_full = () => {
        var categoriesArray = [];

       //alert(schoolIdVal)
        $.each($("input[name='category']:checked"), function(){
            categoriesArray.push($(this).val());
        });
       // var schoolIdArray = [globalSchoolId];
       // var schoolIdArray = [schoolIdVal];
       // var schoolIdArray = [schoolId];

       if(schoolIdVal != ""){
            var schoolIdArray = [schoolIdVal];
        }else if(schoolId != ""){
            var schoolIdArray = [schoolId];
        }else{
            var schoolIdArray = [globalSchoolId];
        }

        var schoolYearArray = [];
        $.each($("input[name='schoolYear']:checked"), function(){
            schoolYearArray.push($(this).val());
        });

        var serachkeyinput = $(".searchKeyInput").val();
        if(serachkeyinput != undefined){
            serachkeyinput = serachkeyinput.trim();
        }
        const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "classYear": schoolYearArray,
            "schoolId": schoolIdArray,
            "pageNumber" :1,
            "pageSize":'5000',
            "titleSummary":serachkeyinput,
            "sortBy" : "updatedOn",
            "jobType": [],
            "companyUser":"no",
            "educationId":'3'
          }

          var accessTokenIn=sessionStorage.getItem("accToken");
          console.log(requestObject);
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                      'Authorization': "Bearer "+accessTokenIn
                      //'Authorization': accessTokenIn
                    },
            body: JSON.stringify(requestObject)
            };
            $(".load-wrap").show();
            Services.findFilterStudent(requestOptions).then((res) =>
            {


                if(res.content){
                    console.log(JSON.stringify(res));

                    var contAr = res.content;
                    setProfileListFull(res.content);
                    download_excel(contAr);
               }


            });



    }


    const filterContent_full1 = () => {
        var categoriesArray = [];

       //alert(schoolIdVal)
        $.each($("input[name='category']:checked"), function(){
            categoriesArray.push($(this).val());
        });
       // var schoolIdArray = [globalSchoolId];
       //var schoolIdArray = [schoolIdVal];
        //var schoolIdArray = [schoolId];

        if(schoolIdVal != ""){
            var schoolIdArray = [schoolIdVal];
        }else if(schoolId != ""){
            var schoolIdArray = [schoolId];
        }else{
            var schoolIdArray = [globalSchoolId];
        }

        var schoolYearArray = [];
        $.each($("input[name='schoolYear']:checked"), function(){
            schoolYearArray.push($(this).val());
        });

        const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "classYear": schoolYearArray,
            "schoolId": schoolIdArray,
            "pageNumber" :1,
            "pageSize":'5000',
            "titleSummary":($(".searchKeyInput").val()).trim(),
            "sortBy" : "updatedOn",
            "jobType": [],
            "companyUser":"no",
            "educationId":'3'
          }

          var accessTokenIn=sessionStorage.getItem("accToken");
          console.log(requestObject);
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                      'Authorization': "Bearer "+accessTokenIn
                      //'Authorization': accessTokenIn
                    },
            body: JSON.stringify(requestObject)
            };
            $(".load-wrap").show();
            Services.findFilterStudent(requestOptions).then((res) =>
            {
                if(res.content){
                    console.log(JSON.stringify(res));

                    var contAr = res.content;
                    console.log(contAr);
                    var newcontAr = [];
                    for(var i=0; i< contAr.length; i++){
                        var jobtypesarray = contAr[i].jobTypeName;
                        if(jobtypesarray.includes("Job Shadow")){
                            newcontAr.push(contAr[i]);
                        }
                    }
                    console.log(newcontAr);
                    //setProfileListFull(res.content);
                    download_excel(newcontAr);
               }
            });
    }

    const getServiceSchoolnData = () => {
        const requestObject={
            "siteId":process.env.React_App_SITE_ID,
            "pageNumber":1,
            "pageSize":150
         }

         var accessTokenIn=sessionStorage.getItem("accToken");
         const requestOptions = {
           method: 'POST',
           headers: { 'Content-Type': 'application/json',
                     //'Authorization': "Bearer "+accessTokenIn
                     //'Authorization': accessTokenIn
                   },
           body: JSON.stringify(requestObject)
         };

         Services.getSchoolList(requestOptions).then((res) =>
         {
            if(res){
                   var contAr = res.content;
                   if(res){
                          var tempschool = res;
                          console.log('*** 1091 ****** '+globalSchoolId);
                          tempschool = tempschool.filter(function(elem) {
                              return elem.schoolId == globalSchoolId;
                          });

                      setSchoolInData(tempschool);
                        var serviceresponse = tempschool;
                        var storetypeval = JSON.stringify(serviceresponse);
                        sessionStorage.setItem('school_list', storetypeval);
                   }
              }
         });
      }

      const getLocalSchoolnData = () => {
        var localschoollistval = sessionStorage.getItem('school_list');
        var tempschoolslist = JSON.parse(localschoollistval);
        setSchoolInData(tempschoolslist);
      }

    const getServiceIndustryData = () => {
        Services.getIndustryData().then((res) => {
            console.log("Industry data=" + JSON.stringify(res.data));
            setCategory(res.data.content);
            var serviceresponse = res.data.content;
            var storeval = JSON.stringify(serviceresponse);
            sessionStorage.setItem('master_industry', storeval);
        });
        loadSelectedindustries();
    }

    const getLocalIndustryData = () => {
        var localindustryval = sessionStorage.getItem('master_industry');
        localindustryval = JSON.parse(localindustryval);
        setCategory(localindustryval);
        loadSelectedindustries();
    }
    
    const loadSelectedindustries = () => {
        setTimeout(() => {
            //var loc_industryval = localStorage.getItem('sad_industry_selected');
            var loc_industryval = session_pageobj['industry'];
           // alert(loc_industryval);
            industrySelCheckLS(loc_industryval);
            if(loc_industryval != ""){
               // filterContent(ITEMS_PER_PAGE,'1',schoolId, '0');
            }

            //var loc_industryval_m = localStorage.getItem('sad_industry_selected_m');
            var loc_industryval_m = session_pageobj['industry_m'];
            industrySelCheckLS_m(loc_industryval_m);
            if(loc_industryval_m != ""){
                //filterContent_m(ITEMS_PER_PAGE,'1',schoolId, '0');
            }

        }, 500);
    }

    function industrySelCheckLS(local_indarray) {
        if(local_indarray != undefined || local_indarray != null){
            var local_indarray = local_indarray.toString();
            var companyCatIn = local_indarray.split(",");
            for (var i = 0; i < companyCatIn.length; i++) {
                $('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
            }
        }
    }

    function industrySelCheckLS_m(local_indarray_m) {
        if(local_indarray_m != undefined || local_indarray_m != null){
            var local_indarray_m = local_indarray_m.toString();
            var companyCatIn_m = local_indarray_m.split(",");
            for (var i = 0; i < companyCatIn_m.length; i++) {
                $('input[name="category_m"][value="' + companyCatIn_m[i] + '"]').prop("checked", true);
            }
        }
    }

    const getServiceJobType = () => {
        Services.getJobType().then((res) => {
            console.log("setJobType data=" + JSON.stringify(res.data));
            setJobType(res.data.content);
            var serviceresponse = res.data.content;
            var storetypeval = JSON.stringify(serviceresponse);
            sessionStorage.setItem('master_jobtype', storetypeval);
        });
    }

    const getLocalJobType = () => {
        var localjobtypeval = sessionStorage.getItem('master_jobtype');
        var tempjobtypes = JSON.parse(localjobtypeval);
        setJobType(tempjobtypes);
    }

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    const submitValue = () => {

    }

    return (
        <>
            <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                            <li class="breadcrumb-item headtitle"> <a href="/schoolAdminDashboard">School Admin</a> </li>
                                <li class="breadcrumb-item headtitle "><a href="#">Student Profiles</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5 cd-desktop">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5 ">
                            <div class="row">



                                {/* <!-- left side --> */}
                                <div class="col-lg-4 mb-2">
                                    <div class="card p-3 mb-0 ">


                                        <span className="mb-3  text-center">
                                        <a onClick={filterContent_full} id="export_studentbtn" class="reg-button text-center m-auto">Export to Excel</a>
                                        </span>
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Filter Profiles</h3>
                                        </div>
                                        <div class="py-3 text-start">
                                            <b class="text-uppercase">Keywords</b>

                                                <input class="form-control me-2 searchKeyInput" type="search" aria-label="Search" placeholder="Search"/>
                                                {/* <button class="border-0 p-0 dir-search" type="submit"><img src="images/search-icon.JPG" alt=""/></button> */}

                                        </div>
                                        <div class="py-3 text-start">
                                            <b class="text-uppercase">School District: </b>
                                          {  schoolIds.map((data, index) => (
                                            <span>{data.name} </span>
                                            ))}
                                        </div>



                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Categories of Interest</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                    categories.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data.catId} name="category"/> <label class="form-check-label" for="defaultCheck1"> {data.name}</label></li>
                                                ))}

                                                </ul>


                                            </div>
                                        </div>





                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Year</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                currentYearList.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data} name="schoolYear"/> <label class="form-check-label" for="defaultCheck1"> {data} </label></li>

                                                ))}
                                                </ul>
                                            </div>
                                        </div>



                                    </div>


                                </div>

                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">

                                    <div class="two-grid-wrap">

                                    {
                                                cardsloop.map((data, inde) => (
                                                    <div style={{"maxWidth": "600px"}} class="doublecards">
                                                    <div class="htl is-loading" style={{"gridTemplateColumns":"60px 1fr", "marginBottom":"0px", "paddingBottom":"0px"}}>
                                                        <div class="image" style={{"width":"60px","height":"60px","borderRadius":"100px"}}></div>
                                                        <div class="content">
                                                            <h2></h2>
                                                            <div class="spanl">
                                                                <span style={{"width":"80%", "height":"15px"}}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"0px"}}>
                                                        <span style={{"height":"3px","width":"90%","marginLeft":"auto","margin":"10px"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"100%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"90%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"70%"}}></span>
                                                    </div>
                                                </div>
                                            ))}


                                    {
                                       profileList.map((data, index) => (

                                        <div class="grid-item p-3 position-relative">
                                            <div class="grid-head">
                                                <div class="grid-img">
                                                    <img src={data.profilePicS3Url?data.profilePicS3Url:"images/user-default.jpg"} class="w-100" alt=""/>
                                                </div>
                                                <div class="grid-title-wrap">
                                                    <h4 class="margin-bottom-0">{data.firstName} {data.lastName}</h4>
                                                    <small class="d-flex">
                                                            <b class="mr-2" style={{minWidth:"65px"}}>Seeking:</b>
                                                            <p class="mb-0 clamp">{strCatArr(data.jobtypename_new)}</p>
                                                        </small>
                                                </div>
                                            </div>
                                            <div class="grid-body mb-4">
                                                <div class="grid-body-item charmin200">
                                                    <div class="grid-body-inn">
                                                        <span>Updated:</span>
                                                        <span>{moment(data.dateUpdated).utcOffset('-0400').format("MM/DD/YYYY")}</span>
                                                    </div>

                                                    <div class="grid-body-inn">
                                                        <span>School:</span>
                                                        <span>{data.name}</span>
                                                    </div>

                                                    <div class="grid-body-inn">
                                                        <span>Email:</span>
                                                        <span>{data.email}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Phone:</span>
                                                        <span>{data.phoneno}</span>
                                                    </div>
                                                    <div class="grid-body-inn ">
                                                        <span>Category:</span>
                                                        <span class="clamp">
                                                        {strCatArr(data.categoryName)}
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-center">
                                                <Link to={`/studentProfileView?studentId=`+data.studentId+`&adpage=`+currentPage} class="reg-button text-center mx-2">View</Link>
                                                <br></br>
                                                <Link to={`/editStudent?studentId=`+data.studentId+`&adpage=`+currentPage} class="reg-button text-center">Edit</Link>
                                            </div>
                                        </div>

                                        ))}


                                    </div>
                                       <div class="paginationContent mt-3">
                                       <Pagination
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange2}/>

                                       </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-3 cd-mobile">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">


                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">



                                </div>

                                {/* <!-- left side --> */}
                                <div class="col-lg-4 mb-2">
                                    <div class="card p-3 mb-0 ">
                                    <span className="mb-3 text-center">
                                        <a onClick={filterContent_full} id="export_studentbtn" class="reg-button text-center m-auto">Export to Excel</a>
                                        </span>
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Filter Profiles</h3>
                                        </div>
                                        <div class="py-3 text-start">
                                            <b class="text-uppercase">Keywords</b>

                                                <input class="form-control me-2 searchKeyInput_m" type="search" aria-label="Search" placeholder="Search"/>
                                                {/* <button class="border-0 p-0 dir-search" type="submit"><img src="images/search-icon.JPG" alt=""/></button> */}

                                        </div>
                                        <div class="py-3 text-start">
                                            <b class="text-uppercase">School District: </b>
                                          {  schoolIds.map((data, index) => (
                                            <span>{data.name} </span>
                                            ))}
                                        </div>
                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Categories of Interest</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                    categories.map((data, index) => (
                                                    <li><input class="form-check-input searchContent_m" type="checkbox" value={data.catId} name="category_m"/> <label class="form-check-label" for="defaultCheck1"> {data.name}</label></li>
                                                ))}

                                                </ul>
                                            </div>
                                        </div>
                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Year</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                currentYearList.map((data, index) => (
                                                    <li><input class="form-check-input searchContent_m" type="checkbox" value={data} name="schoolYear_m"/> <label class="form-check-label" for="defaultCheck1"> {data} </label></li>

                                                ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                </div>




                                <div class="col-lg-8 mb-4">

                                    <div class="two-grid-wrap">

                                    {
                                                cardsloop.map((data, inde) => (
                                                    <div style={{"maxWidth": "600px"}} class="doublecards">
                                                    <div class="htl is-loading" style={{"gridTemplateColumns":"60px 1fr", "marginBottom":"0px", "paddingBottom":"0px"}}>
                                                        <div class="image" style={{"width":"60px","height":"60px","borderRadius":"100px"}}></div>
                                                        <div class="content">
                                                            <h2></h2>
                                                            <div class="spanl">
                                                                <span style={{"width":"80%", "height":"15px"}}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"0px"}}>
                                                        <span style={{"height":"3px","width":"90%","marginLeft":"auto","margin":"10px"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"100%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"90%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"70%"}}></span>
                                                    </div>
                                                </div>
                                            ))}


                                    {
                                       profileList_m.map((data, index) => (

                                        <div class="grid-item p-3 position-relative">
                                            <div class="grid-head">
                                                <div class="grid-img">
                                                    <img src={data.profilePicS3Url?data.profilePicS3Url:"images/user-default.jpg"} class="w-100" alt=""/>
                                                </div>
                                                <div class="grid-title-wrap">
                                                    <h4 class="margin-bottom-0">{data.firstName} {data.lastName}</h4>
                                                    <small class="d-flex">
                                                            <b class="mr-2" style={{minWidth:"65px"}}>Seeking:</b>
                                                            <p class="mb-0 clamp">{strCatArr(data.jobtypename_new)}</p>
                                                        </small>
                                                </div>
                                            </div>
                                            <div class="grid-body mb-4">
                                                <div class="grid-body-item charmin200">
                                                    <div class="grid-body-inn">
                                                        <span>Updated:</span>
                                                        <span>{moment(data.dateUpdated).utcOffset('-0400').format("MM/DD/YYYY")}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>School:</span>
                                                        <span>{data.name}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Email:</span>
                                                        <span>{data.email}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Phone:</span>
                                                        <span>{data.phoneno}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Category:</span>
                                                        <span class="clamp">
                                                        {strCatArr(data.categoryName)}
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="grid-footer">
                                                <Link to={`/studentProfileView?studentId=`+data.studentId+`&adpage=`+currentPage} class="reg-button text-center m-auto">View</Link>
                                                <br></br>
                                                <Link to={`/editStudent?studentId=`+data.studentId+`&adpage=`+currentPage} class="reg-button text-center">Edit</Link>
                                            </div>
                                        </div>

                                        ))}


                                    </div>
                                       <div class="paginationContent mt-3">
                                       <Pagination
                                        total={totalItems_m}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage_m}
                                        onPageChange={handlePageChange2_m}/>

                                       </div>

                                </div>





                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
             </div>
        </section>
    </main>
        </>
    )
};

StudentListBySchool.propTypes = {};

StudentListBySchool.defaultProps = {};

export default StudentListBySchool;
