import React,{history,useEffect, useState, useMemo } from "react";
import Hiring from "../Jobs/Hiring";
import services from "../../services/services";
const Leisure = () => {

   const [leisureContent, setLeisureContent] = useState([]);
    const getPageInfo = (reqopt) => {

        
        services.getCMSPageContent('39').then((res) => {
            
            console.log(JSON.stringify(res));
            if(res.data){
               setLeisureContent(res.data.content.rendered);
            }
       
        });
       
     }

    useEffect(() => {        
        getPageInfo("downloadourapp");

        const jsonData = {
         "leisure": {
           
         }
       };

     var jsondataformatted = JSON.stringify(jsonData);
     sessionStorage.setItem('pagejsonObject', jsondataformatted);
  
    }, []);

  return(
  <>
    <main  class="empr-directory-main">
         <div class="main-bgpatten"></div>
         <section class="headtitle-wrap">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                     <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          {/* <li class="breadcrumb-item headtitle "><a href="#">Local life <span style={{color: "#6c757d"}}>&#62;</span></a></li> */}
                          <li class="breadcrumb-item active headtitle" aria-current="page"> Leisure</li>
                        </ol>
                      </nav>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- info typography --> */}
         <section class="company-detail-wrap py-5 ">
            <div class="container">
               <div class="row">
                  <div class="col-12 ">

                  <div class="white-bg px-5 pt-5 pb-4">
                            <div class="row mb-3">
                                <div class="col-lg-12 mb-4">
                                    <div dangerouslySetInnerHTML={{ __html: leisureContent  }} />
                                </div> 
                            </div> 
                         </div>
                     
                  </div>
               </div>
            </div>
         </section>
         
         
         {/* <!-- Top companies hiring in your area --> */}
         <section class="white-bg py-5">
            <div class="container">
            <Hiring/>
               {/* <div class="row mb-4">
                  <div class="col-12">
                     <div class="d-flex justify-content-between border-bottom  mb-4 pb-4 flex-lg-row flex-column">
                        <h4 class="headtitle">Top companies hiring in your area</h4>
                        <a href="#" class="text-uppercase black-link">See All</a>
                     </div>
                  </div>
                  
               </div>
               <div class="row">
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                   
                  </div>

                
               </div> */}
            </div>
         </section>
      </main>
  </>
)};

Leisure.propTypes = {};

Leisure.defaultProps = {};

export default Leisure;
