import React,{history,useEffect, useState, useMemo } from "react";
import Quick_links from "../../utility/Quick_links";
import Hiring from "./Hiring";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import services from "../../services/services";
import { useNavigate, useParams } from "react-router-dom";
//import Pagination3 from "../../utility/Pagination/Pagination";
import Pagination3 from "../../utility/Pagination/Pagination";
import Services from "../../services/services";
import moment from "moment";


const AnnouncementWidget = () => {
   const [itemName, setFieldName] = useState('');

   //const companyId = 24;
   const companyId=sessionStorage.getItem("logCompanyId");
   const siteId = process.env.React_App_SITE_ID;
 
   const navigate = useNavigate();
   const ITEMS_PER_PAGE = 3; // Per page show the data pagination
   const [totalItems, setTotalItems] = useState(0);
   const [totalpages, settotalPages] = useState();
   const [currentPage, setCurrentPage] = useState(1);

   const [videoUrl, setVideo] = useState([]);
   const [jobList, setJobList] = useState([]);
   const [eventList, setEventList] = useState([]);

   useEffect(() => {

      //getTopCompanyHiringList();
      //filterContent(ITEMS_PER_PAGE,offset);
      //filterEvents(ITEMS_PER_PAGE,'1');
      filterRecentJobs(ITEMS_PER_PAGE,'1');

      const jsonData = {
         "announcementWid": {
           
         }
       };

     var jsondataformatted = JSON.stringify(jsonData);
     sessionStorage.setItem('pagejsonObject', jsondataformatted);

   }, []);

  

  
   const handlePageChange3 = (offset,triggerevent) => {
      filterRecentJobs(ITEMS_PER_PAGE,offset)
    };

    const filterRecentJobs = (ITEMS_PER_PAGE,pageNumber) => {     
     
          
          var siteIdIn = process.env.React_App_SITE_ID;//"2";
         
          Services.getAnnouncementbySiteId(siteIdIn,ITEMS_PER_PAGE,pageNumber).then((res) => 
          {

            //console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@events widg 113");
              if(res.data.content){
               console.log(JSON.stringify(res.data));
                  $(".paginationContent").show();
                  setTotalItems(res.data.totalElements);         
                  settotalPages(Math.ceil(res.data.totalElements / ITEMS_PER_PAGE));                    
                  setCurrentPage(pageNumber);
      
                  var contAr = res.data.content;
                  //setProfileList(res.content);
                  if(res.data.content){
                     setEventList(res.data.content);
                  }                
                  

             }              

          });
  }  

   const {
      register,
      formState: { errors },
      handleSubmit
   } = useForm({
      criteriaMode: "all", mode: "onBlur"
   });

   const submitValue = () => {

     

   }

  return(
 
    <>
        <div class="card p-3 mt-5">
            <div class="announce-wrap">

               <div class="orange-ribbon d-flex mb-4">
                  {/* <img src="images/job-opp-icon.png" alt=""/> */}
                  <img src="images/reminders-icon.png" alt=""/>
                  <span>ANNOUNCEMENTS</span>
               </div>


               <div class="announce-wrap">
               {
                     eventList.map((data, index) => (                     
                        <div class="after-orange-ribbon mb-4">
                        <a href={'/notificationDetails?notificationId='+data.notificationId}> <h4>{data.notificationTitle}</h4></a>
                           <span>{moment(data.startDate).format("MM/DD/YYYY")}</span>                                       
                           {/* <p class="py-2">{`${data.notificationDescription.substring(0, 100)}...`}<a href={`/eventDetails?eventId=`+data.notificationId}></a></p> */}
                           <a href={'/notificationDetails?notificationId='+data.notificationId}> <p dangerouslySetInnerHTML={{ __html: data.notificationDescription.substring(0, 200)  }} /> </a>
                        </div>
                      ))}                                   
               </div>
                                
                       
         
            <div class="pagination-wrap text-center mt-3 paginationContent">
                           <Pagination3               
                     total={totalItems}
                     itemsPerPage={ITEMS_PER_PAGE}
                     currentPage={currentPage}
                     onPageChange={handlePageChange3}/>
                                 
            </div>
            
               </div>
            
            
         </div>
        </>
  
)};

AnnouncementWidget.propTypes = {};

AnnouncementWidget.defaultProps = {};

export default AnnouncementWidget;