import React, { history, useEffect, useState, useMemo } from "react";
import Quick_links from "../../utility/Quick_links";
import Hiring from "../Jobs/Hiring";
import services from "../../services/services";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useNavigate,useParams } from "react-router-dom";
import moment from "moment";
import Pagination3 from "../../utility/Pagination/Pagination";
const CompanyJobApplications = () => {

    // const { companyId } = useParams(); 
    //const companyId=24;
    const companyId=sessionStorage.getItem("logCompanyId");
   
    const navigate = useNavigate();
      
    const [companyUsers, setCompanyUsers] = useState([]);
    const [jobApplicationsList, setJobApplicationsLIst] = useState([]);

    const ITEMS_PER_PAGE = 10; // Per page show the data pagination
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    
    const handlePageChange3 = (offset,triggerevent) => {
        getCompanyJobApplications(ITEMS_PER_PAGE,offset)
      };
    useEffect(() => {
        const jsonData = {
            "companyJobApplications": {
              
            }
          };

        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);

        if(companyId == null || companyId == "null"){            
            //navigate('/login');
            navigate('/Home');
          }

        $("#successMessage").hide();
        $("#successMessageDel").hide();
        
        //getCompanyJobApplications();
        getCompanyJobApplications(ITEMS_PER_PAGE,'1')
        //
       

    }, []);


    

    const getCompanyJobApplications = (ITEMS_PER_PAGE,pageNumber) => {
        


        const requestObject=
        {         
            "siteId": process.env.React_App_SITE_ID,
            "companyId": companyId,
            "pageSize" : ITEMS_PER_PAGE,
            "pageNumber":pageNumber    
        }

      
        var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                  'Authorization': "Bearer "+accessTokenIn 
                  //'Authorization': accessTokenIn 
                },
        body: JSON.stringify(requestObject)
    };
    
    console.log(requestOptions);
    services.applicationlistbycompany(requestOptions).then((res) => 
    {
        /*
        //alert(JSON.stringify(res));
        console.log(res);
        if(res.data){
        setJobApplicationsLIst(res.data);  
       }
       */
       //console.log(JSON.stringify(res));
       if(res.content){
            //console.log(JSON.stringify(res.data));
            $(".paginationContent").show();
            setTotalItems(res.totalElements);         
            settotalPages(Math.ceil(res.totalElements / ITEMS_PER_PAGE));                    
            setCurrentPage(pageNumber);

            var contAr = res.content;
            //setProfileList(res.content);
            if(res.content){
                setJobApplicationsLIst(res.content);
            }                
            

        }  
        
    
    });



    }


    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    const submitValue = () => { 

      
    
   

    }


    return (
        <>
            <main class="empr-directory-main">
                <div class="main-bgpatten"></div>
                {/* <!-- info typography --> */}
                <section class="headtitle-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item headtitle "><a href="/companyJobs">Company Dashboard</a> </li>
                                        <li class="breadcrumb-item headtitle "><a href="#">Job Applications</a></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- info typography --> */}
                <section class="py-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="white-bg p-5">
                                    <div class="row">
                                        {/* <!-- left side --> */}

                                        <Quick_links />
                                        {/* <!-- right side --> */}
                                        <div class="col-lg-8 mb-4">
                                            <form id="addUserForm" onSubmit={handleSubmit(submitValue)}>
                                                
                                            </form>
                                            <div id="successMessage" class="alert alert-success" role="alert">
                                           User has been added successfully</div>

                                           
                                           
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="current-job-tbl mb-4" style={{maxWidth: '100%', overflow:'auto'}}>
                                                        <table class="table">
                                                            <thead>
                                                                <tr>
                                                                   
                                                                    <th scope="col">Job Title </th>
                                                                    <th scope="col">Applicant</th>
                                                                    {/* <th style={{minWidth: '120px'}}>Applied On</th> */}
                                                                    {/* <th>Email</th>
                                                                    <th style={{minWidth: '150px'}}>Mobile No</th> */}
                                                                    <th scope="col">View</th>
                                                                   

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    jobApplicationsList.map((data, index) => (

                                                                        <tr>
                                                                        <td style={{minWidth: '300px', textAlign:'left'}}>
                                                                            <div>{data.jobTitle}</div>
                                                                            <div><strong>Applied on:</strong> <span className="dt-highlt">{moment(data.createdDate).format("MM/DD/YYYY")}</span></div>
                                                                        </td>
                                                                        <td style={{minWidth: '200px', textAlign:'left'}}>
                                                                        <div> {data.firstName} {data.lastName}</div>
                                                                       <div>{data.phone}</div>
                                                                       <div> {data.email}</div>
                                                                        </td>
                                                                        {/* <td>{moment(data.createdDate).format("D-MMM-YY")}</td> */}
                                                                        {/* <td>{data.email}</td>
                                                                        <td style={{minWidth: '150px', textAlign:'left'}}>{data.phone}</td> */}
                                                                        <td><a href={"/jobApplicantProfileView?applicantId="+data.applicationJobId} className="apply-button">View</a></td>
                                                                       
                                                                        </tr>

                                                                    ))}
                                                               

                                                              
                                                            </tbody> 
                                                        </table>
                                                    </div>
                                                    <div class="pagination-wrap text-center mt-3 paginationContent">
                                                                <Pagination3               
                                                            total={totalItems}
                                                            itemsPerPage={ITEMS_PER_PAGE}
                                                            currentPage={currentPage}
                                                            onPageChange={handlePageChange3}/>
                                                                        
                                                    </div>
                                                    <div id="successMessageDel" class="alert alert-success" role="alert">
                                           Selected User(s) has been deleted successfully</div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
                {/* <Hiring /> */}
            </main>
        </>
    )
};

CompanyJobApplications.propTypes = {};

CompanyJobApplications.defaultProps = {};

export default CompanyJobApplications;
