import React,{history,useEffect, useState, useMemo } from "react";
import moment from "moment";
import Services from "../services/services";
// import { Link } from 'react-router-dom';

const Quick_links = () => {

    const[path,setPathName]=useState([]);
    const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");
    const [eventList, setEventList] = useState([]);
    const siteId = 2;
    const roleArCurrent=sessionStorage.getItem("roleAr");
    const getEvents = () => {
        Services.getEventsbySiteId(siteId).then((res) => {    
          console.log("Industry data=" + JSON.stringify(res.data));    
          if(res.data){
            //setEventList(res.data);
            var evArr = res.data;
            var evList = [];
            if(res.data.length >0){
                for(var i=0;i<3;i++){
                    evList[i]=res.data[i];
                }
            }
          
            //alert(JSON.stringify(evList));
            setEventList(evList);
          }    
        });    
    }
    useEffect(() => {
        
        const pathName=window.location.pathname;
        setPathName(pathName);
        //getEvents();
      }, []);
    

  return(
  <>
    <div class="col-lg-4 mb-2">
    {(() => {
        if (path=="/companyDashboard" ||path == "/companyJobs"
        || path == "/publicProfileView"
        || path == "/profileInfo"
        || path == "/companyLogo"
        || path == "/profileBanner"
        || path == "/videoUpload"
        || path == "/editAdminstrationLogin"
        ||path == "/companyUsers"
        ||path=="/companyJobApplications"
        ||path == "/emailNotification"   
        ||path == "/companyEditJob"   
        ||path == "/companyCopyJob"            
        ) {
          return (
           <div class="card p-3 mb-3 ">
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Resumes</h3>
                                        </div>

                                        <div class="qlinks">
                                            <ul>
                                                <li class="white-text">
                                                    <p>VIEW RESUMES! Just a friendly reminder that you have access to browse resumes on our website by clicking the link's below</p>
                                                        <ul>
                                                <li><a href="/personalProfiles" class={"/personalProfiles"!=path?'':"active"}>Search Job Seekers</a></li>
                                                <li><a href="/personalStudents" class={"/personalStudents"!=path?'':"active"}>Search Students</a></li>

                                            </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    )
        }
      })()}
                                    <div class="card p-3 mb-3 ">
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Company Jobs </h3>
                                        </div>
                                        

                                        <div class="qlinks">
                                            <ul>
                                                <li><a href="/companyJobs" class={"/companyJobs"!=path?'':"active"}>Company Jobs </a></li>
                                                <li><a href="/companyJobApplications" class={"/companyJobApplications"!=path?'':"active"}>Job Applications </a></li>
                                                <li><a href="/postJob" class={"/postJob"!=path?'':"active"}>Post a Job </a></li>

                                                {/* <Link className={"/postJob"!=path?'':"active"} to={`/postJob`}>Post a Job</Link> */}

                                            </ul>
                                        </div>
                                    </div> 
  
                                  

                                    <div class="card p-3 mb-3 ">
                                        <div class="bg-heading">
                                        {(() => {
        if (path!="/companyDashboard") {
          return ( <h3 class="mb-0">Quick Links </h3>) }else{
              return( <h3 class="mb-0">COMPANY OPTIONS </h3>)
          }
        })()}
                                           

                                           


                                        </div>

                                        <div class="qlinks">
                                            <ul>
                                                <li><a href="/publicProfileView" class={"/publicProfileView"!=path?'':"active"}>Preview Company Profile </a></li>

                                                {(() => {
                                                    if (roleArCurrent == "company" || roleArCurrent == "companyadmin") {
                                                        return(
                                                            <li><a href="/profileInfo" class={"/profileInfo"!=path?'':"active"}>Edit Company Profile </a></li>
                                                        )
                                                    }
                                                })()}

                                                {(() => {
                                                    if (roleArCurrent == "company" || roleArCurrent == "companyadmin") {
                                                        return(
                                                            <li><a href="/companyLogo"  class={"/companyLogo"!=path?'':"active"}>Logo</a></li>
                                                        )
                                                    }
                                                })()}

                                                {(() => {
                                                    if (roleArCurrent == "company" || roleArCurrent == "companyadmin") {
                                                        return(
                                                            <li><a href="/profileBanner" class={"/profileBanner"!=path?'':"active"}>Banner</a></li>
                                                        )
                                                    }
                                                })()}

                                                {(() => {
                                                    if (roleArCurrent == "company" || roleArCurrent == "companyadmin") {
                                                        return(
                                                            <li><a href="/videoUpload" class={"/videoUpload"!=path?'':"active"}>Videos</a></li>
                                                        )
                                                    }
                                                })()}

                                                {(() => {
                                                    if (roleArCurrent == "company" || roleArCurrent == "companyadmin") {
                                                        return(
                                                            <li><a href="/perksList" class={"/perksList"!=path?'':"active"}>Perks</a></li>
                                                        )
                                                    }
                                                })()}
                                               
                                            
                                                <li><a href="/editAdminstrationLogin" class={"/editAdminstrationLogin"!=path?'':"active"}>My User Profile </a></li>
                                                
                                                
                                                {(() => {
                                                        if (roleArCurrent == "company" || roleArCurrent == "companyadmin") {
                                                            return (
                                                    <li><a href="/companyUsers" class={"/companyUsers"!=path?'':"active"}>Authorized Users</a></li>
                                                    
                                                        )
                                                    }
                                                })()}  

{(() => {
                                                        if (roleArCurrent == "company") {
                                                            return (
                                                                <li><a href="/emailNotification" class={"/emailNotification"!=path?'':"active"}>Email Notifications</a></li>
                                                    
                                                        )
                                                    }
                                                })()}  
                                                
                                                
                                                {/* <li><a href="/emailNotification">Business Page Template</a></li> */}

                                            </ul>
                                            {/* <div class="p-5 white-bg">
                                                <div class="text-center">
                                                    <img src="images/Group 75.png" alt=""/>
                                                </div>
                                                <div class="text-cente">
                                                    <h3 class="pb-3 border-bottom text-center">Upcoming Events</h3>
                                                    {
                                                        eventList.map((data, index) => (
                                                            <div class="pb-4 mt-4 border-bottom">
                                                        <h4>{data.eventTitle}</h4>
                                                        <span>{moment(data.eventDate).format("MM/DD/YYYY")}</span>
                                                        <p>
                                                           
                                                            {`${data.eventDescription.substring(0, 100)}...`}<a href={`/eventDetails?eventId=`+data.eventId}>Read more</a>
                                                            
                                                        </p>
                                                        </div>

                                                        ))}                                                   
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>

                                    <div class="card p-3 mb-3 ">
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Wage Survey </h3>
                                        </div>
                                        

                                        <div class="qlinks">
                                            <ul>
                                                {/* <li><a href="/wageSurvey" class={"/wageSurvey"!=path?'':"active"}>Wage Survey </a></li> */}
                                                <li><a href="/wageData" class={"/wageData"!=path?'':"active"}>Wage Data </a></li>
                                                <li><a href="/wageSurveyData" class={"/wageSurveyData"!=path?'':"active"}>Wage Survey </a></li>
                                                

                                            </ul>
                                        </div>
                                    </div> 

                                </div>
  </>
)};

Quick_links.propTypes = {};

Quick_links.defaultProps = {};

export default Quick_links;