import React, { useEffect, useState } from 'react';

import { ErrorMessage } from "@hookform/error-message";
import { set, useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import StudentRegistration2 from "./student-registration2";
import StudentRegistration3 from "./student-registration3";
import Services from '../../services/services';
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup'
import Header from '../../layouts/header/header';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SunEditor, { buttonList } from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';

const StudentRegistrationCareerChoices = () => {
  const [jobTypes, setJobType] = useState([]);
  const [fileName, setFileName] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const [fileBase64, setFileBase64] = useState('');

  const [fileNameMobile, setFileNameMobile] = useState('');
  const [fileExtensionMobile, setFileExtensionMobile] = useState('');
  const [fileBase64Mobile, setFileBase64Mobile] = useState('');

  const [studentComments, setStudentComments] = useState('');
  const [studentCommentsMobile, setStudentCommentsMobile] = useState('');

  const [studentResumePath, setStudentResumePath] = useState('');
  const [studentResumePathMobile, setStudentResumePathMobile] = useState('');

  const navigate = useNavigate();

  const userIdChkLogin = sessionStorage.getItem("logUserId");
  const studentRegId = sessionStorage.getItem("studentRegId");

  function handleChangeStudentCommentsDesc(content) {
    setStudentComments(content);
  }
  function handleChangeStudentCommentsMobDesc(content) {
    setStudentCommentsMobile(content);
  }

  function handleImageUploadBefore(files, info, uploadHandler) {
    var reader1 = new FileReader();
    reader1.readAsDataURL(files[0]);
    reader1.onload = function () {
      var rdt1 = reader1.result;
      var base64result_banner = rdt1.split(',')[1];
      var bname = files[0].name;
      var bextn = bname.split(".").pop();
      var bannerActName = bname.split(".")[0];
      var fileext_banner = bextn;

      const requestObject = {
        //"imageName": files[0].name,
        "imageName": bannerActName,
        "imageExtension": fileext_banner,
        "imageByteString": base64result_banner
      };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "Bearer "+accessTokenIn 
          //'Authorization': accessTokenIn 
        },
        body: JSON.stringify(requestObject)
      };

      Services.fileUploadS3URL(requestOptions).then((res) => {
        const response = {
          // The response must have a "result" array.
          "result": [
            {
              "url": res.url,
              "temp": files[0],
              "name": bannerActName,
              "size": files[0].size
            },
          ]
        }
        uploadHandler(response);

      });
    };

  }

  useEffect(() => {
    const jsonData = {
      "studentRegistrationCareerChoices": {
        
      }
    };
    var jsondataformatted = JSON.stringify(jsonData);
    sessionStorage.setItem('pagejsonObject', jsondataformatted);

    if(studentRegId != null){
      getStudentProfile(studentRegId);
    }
    else{
      navigate('/StudentRegistrationPersonalInfo');
    } 

    $(".load-wrap").hide();
    $("#alertFailMessage").hide();
    $("#successMessage").hide();
    $("#studentCommentsValidMsg").hide();  
    $("#studentCommentsValidMsgMobile").hide();
    getJobType();
    $('#seekingValidMsg').hide();
    $('#seekingValidMsg_m').hide();
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
    $("#fileError").hide();
    $("#fileErrorMobile").hide();
  }, []);

  const getStudentProfile = (studentId) => {
    
    Services.getpubStudentProfile(studentId).then((res) => {
      var seekingIn = res.data.seekingEmploymentTypes;
      $("#resume").val(res.data.pdfByString);
      $("#resumeMobile").val(res.data.pdfByString);
      setStudentComments(res.data.comments);
      setStudentCommentsMobile(res.data.comments);
      setStudentResumePath(res.data.resumeUrl);
      setStudentResumePathMobile(res.data.resumeUrl);
      setTimeout(() => {
        for (var i = 0; i < seekingIn.length; i++) {
          $('input[name="seeking"][value="' + seekingIn[i] + '"]').trigger("click");
          $('input[name="seeking_m"][value="' + seekingIn[i] + '"]').trigger("click");
        }
        if (res.data.educationId == 3) {
          $('#parent_authorizationm').show();
          $('#parent_authorization').show();
        }
        else{
          $('#parent_authorizationm').hide();
          $('#parent_authorization').hide();
        }
        var isCheck = true;
        return isCheck;
      }, 1000);

    });
  }

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  const getJobType = () => {
    Services.getJobType().then((res) => {
      //console.log("setJobType data=" + JSON.stringify(res.data));
      var jobtype_unsort = res.data.content;

      var jobtypes_sorted = jobtype_unsort.sort((a, b) => b.jobTypeId - a.jobTypeId);
      array_move(jobtypes_sorted, 0, (jobtypes_sorted.length - 1));
      
      jobtypes_sorted = jobtypes_sorted.filter(function (elem) {
        return elem.name !== 'Continuing Education';
      });
      
      setJobType(jobtypes_sorted);
    });
  }

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // console.log(reader.result);
      var base64result = reader.result.split(',')[1];
      setFileBase64(base64result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  
  function getBase64Mobile(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // console.log(reader.result);
      var base64result = reader.result.split(',')[1];
      setFileBase64Mobile(base64result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  function getImage() {
    var files = document.getElementById('resume').files;
    if (files.length > 0) {

      var fname = files[0].name;
      // var fextn = fname.substr(0, fname.lastIndexOf('.'));
      var fextn = fname.split(".").pop();
      // alert(fextn);
      var fileActName = fname.split(".")[0];
      // alert(fileActName);
      setFileName(fileActName);
      setFileExtension(fextn);
      getBase64(files[0]);

      if (fextn != "doc" && fextn != "docx" && fextn != "pdf") {

        $("#fileError").show();
        return false;
      } else {
        $("#fileError").hide();
      }

    }
  }

  function getImageMobile() {
    var files = document.getElementById('resumeMobile').files;
    if (files.length > 0) {

      var fname = files[0].name;
      // var fextn = fname.substr(0, fname.lastIndexOf('.'));
      var fextn = fname.split(".").pop();
      // alert(fextn);
      var fileActName = fname.split(".")[0];
      // alert(fileActName);
      setFileNameMobile(fileActName);
      setFileExtensionMobile(fextn);
      getBase64Mobile(files[0]);

      if (fextn != "doc" && fextn != "docx" && fextn != "pdf") {

        $("#fileErrorMobile").show();
        return false;
      } else {
        $("#fileErrorMobile").hide();
      }

    }
  }

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    criteriaMode: "all",
    mode: "onBlur"
    //resolver: yupResolver(formSchema)
  });

  const {
    register: register4,
    formState: { errors: errors4 },
    handleSubmit: handleSubmit4,
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });

  const curr_date = Date.now();

  const submitValue = (data) => {
    var categoriesArray = [];
    var validateEditorTxt = 1;
    $.each($("input[name='seeking']:checked"), function () {
      categoriesArray.push($(this).val());
    });

    if(categoriesArray.length == 0){
      $('#seekingValidMsg').show();
      $(window).scrollTop($('#seekingValidMsg').position().top);
      validateEditorTxt = 0;
    }
    else{
      $('#seekingValidMsg').hide();
    }
    if (validateEditorTxt == 0) {
      return false;
    }


    const requestObject = {
      "studentId": studentRegId,
      //step1 fields
      "firstName": "",
      "lastName": "",
      "email": "",
      "password": "",
      "phone": "",
      //step2 fields
      "educationId": "",
      "classYear": "",
      "schoolId": "",
      "nameOfCollegeUniversity": "",
      "nameOfCollegeUniversityId": "",
      "schoolDistrictOther": "",
      "highSchoolDiplomaExtContinueEduStatus": "",
      "highSchoolDiplomaExtNameOfInstitutionCollegeId": "",
      "highSchoolDiplomaExtPursuingProgram": "",
      "studentInterestedIn": [],
      "schoolName": null,
      //step3 fields
      "categoriesOfExpertise": [],
      "seeking": null,
      "reasonForSelectCareers": "", 
      "experiance": "",
      //step4 fields
      "seekingEmploymentTypes": categoriesArray,
      "comments": studentComments,
      "resumeUrl": null,
      "resumeFilePath": null,
      "authorization": $('#authorization').val(),

      "dateUpdated": curr_date,
      "dateCreated": curr_date,
      "siteId": process.env.React_App_SITE_ID,
      "helpPrepare": "yes",
      "status": "published",
      "interested": null,
      "events": null,
      "userId": null,
      "countyId": null,
      "isStudent": true,
      "updatedOn": null,
      "securityRoleName": null,
      "course": "",
      "studentTalentConnectionForumIn": [],
      "totalElement": null,
      "searchText": null,
      "logo": null,
      "profileS3Pic": null,
      "partiallyRegistered":"N",
      "loggedInUser":"No"
  }
  
  
    if (fileName != "" && fileExtension != "") {
      requestObject.pdfByString = fileBase64;
      requestObject.fileName = fileName;
      requestObject.fileExtension = fileExtension;
    }
    console.log(requestObject);
   // return false;
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "Bearer "+accessTokenIn
        //'Authorization': accessTokenIn
      },
      body: JSON.stringify(requestObject)
    };
    $(".load-wrap").show();
    Services.updateStudentProfile(requestOptions).then((res) => {
      $(".load-wrap").hide();

      if (res.success == "false" || res.success == false) {
        $("#alertFailMessage").html(res.message);
        $("#alertFailMessage").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#alertFailMessage").slideUp(1000);
        });

      } else {
        $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#successMessage").slideUp(1000);
        });
        sessionStorage.removeItem('studentRegId');
        navigate('/thankyou?r=2');
      }
    });
  }
  
const submitValueStep4 = (data) => {
  var categoriesArraym = [];
var validateEditorTxt = 1;
    $.each($("input[name='seeking_m']:checked"), function () {
      categoriesArraym.push($(this).val());
    });

    if(categoriesArraym.length == 0){
      $('#seekingValidMsg_m').show();
      $(window).scrollTop($('#seekingValidMsg_m').position().top);
      validateEditorTxt = 0;
    }
    else{
      $('#seekingValidMsg_m').hide();
    }

    if (validateEditorTxt == 0) {
      return false;
    }

  const requestObject = {
    "studentId": studentRegId,
    //step1 fields
    "firstName": "",
    "lastName": "",
    "email": "",
    "password": "",
    "phone": "",
    //step2 fields
    "educationId": "",
    "classYear": "",
    "schoolId": "",
    "nameOfCollegeUniversity": "",
    "nameOfCollegeUniversityId": "",
    "schoolDistrictOther": "",
    "highSchoolDiplomaExtContinueEduStatus": "",
    "highSchoolDiplomaExtNameOfInstitutionCollegeId": "",
    "highSchoolDiplomaExtPursuingProgram": "",
    "studentInterestedIn": [],
    "schoolName": null,
    //step3 fields
    "categoriesOfExpertise": [],
    "seeking": null,
    "reasonForSelectCareers": "", 
    "experiance": "",
    //step4 fields
    "seekingEmploymentTypes": categoriesArraym,
    "comments": studentCommentsMobile,
    "resumeUrl": null,
    "resumeFilePath": null,
    "authorization": $('#authorization_m').val(),

    "dateUpdated": curr_date,
    "dateCreated": curr_date,
    "siteId": process.env.React_App_SITE_ID,
    "helpPrepare": "yes",
    "status": "published",
    "interested": null,
    "events": null,
    "userId": null,
    "countyId": null,
    "isStudent": true,
    "updatedOn": null,
    "securityRoleName": null,
    "course": "",
    "studentTalentConnectionForumIn": [],
    "totalElement": null,
    "searchText": null,
    "logo": null,
    "profileS3Pic": null,
    "partiallyRegistered":"N",
    "loggedInUser":"No"
}
if (fileNameMobile != "" && fileExtensionMobile != "") {
  requestObject.pdfByString = fileBase64Mobile;
  requestObject.fileName = fileNameMobile;
  requestObject.fileExtension = fileExtensionMobile;
}
  console.log(requestObject);
  var accessTokenIn = sessionStorage.getItem("accToken");
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      //'Authorization': "Bearer "+accessTokenIn
      //'Authorization': accessTokenIn
    },
    body: JSON.stringify(requestObject)
  };
  $(".load-wrap").show();
  Services.updateStudentProfile(requestOptions).then((res) => {
    $(".load-wrap").hide();

    if (res.success == "false" || res.success == false) {
      $("#alertFailMessage").html(res.message);
      $("#alertFailMessage").fadeTo(2000, 1000).slideUp(1000, function () {
        $("#alertFailMessage").slideUp(1000);
      });

    } else {
      $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
        $("#successMessage").slideUp(1000);
      });
      sessionStorage.removeItem('studentRegId');
      navigate('/thankyou?r=2');
    }
  });
}
  const go_back = () => {
    navigate('/studentRegistrationFieldsofInterest');
  }

  return (
    <>
      <main class="empr-directory-main cd-desktop">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item headtitle "><a href="#">Student Registration</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">
          <div class="container">
            <div class="row">
              <div class="col-12 ">
                <form onSubmit={handleSubmit(submitValue)}>
                  <div class="white-bg px-5 pt-4 pb-4">
                  <div class="row mb-0">
                      <div class="col-lg-12 m-auto">
                          <div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                              <h5 className='mb-0'>CAREER CHOICES</h5>
                              <div class="student-reg-step">STEP 4/4</div>
                            </div>
                      </div>
                    </div>
                    <div class="row mt-4 resume-wrap">

                    <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">I AM SUBMITTING THIS FORM BECAUSE I AM LOOKING FOR:<span class="red-text">*</span></label></div>
                        <div class="category-check-itm">
                          <ul>
                            {
                              jobTypes.map((data, index) => (


                                <li><input class="form-check-input" type="checkbox" name="seeking" value={data.jobTypeId} id="seeking" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                              ))}

                          </ul>
                        </div>
                        {errors.seeking && errors.seeking.type === "required" && <div class="error">Please check atleast one </div>}
                        <div id="seekingValidMsg" class="error">Please check atleast one</div>
                        {/* {errors.seeking && errors.seeking.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>} */}
                      </div>
                    <div class="col-lg-12 mb-4">

                      <div class="d-flex justify-content-between align-items-end choose">
                        <div>
                          <label for="resumePath" class="form-label">MY RESUME  (OPTIONAL) </label>
                          <div class="d-flex align-items-center">
                            {/* <input class="form-control form-control-lg choose-file mr-2" id="resumePath" type="file"/> */}
                            <input class="form-control" id="resume" type="file" onChange={() => getImage()} />

                          </div>
                          <small> 1MB Max, File Type: PDF,DOC</small>
                          <div id="fileError" class="error">Invalid file format</div>
                        </div>

                      </div>

                      </div>

                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">ANY ADDITIONAL THOUGHTS/COMMENTS THAT MIGHT BE HELPFUL? </label></div>
                        <small>(Leave blank if not known.)</small>
                        <SunEditor
                          setOptions={{
                            height: "120",
                            textAlign:"left",
                            //buttonList: [['font', 'align'], ['image']]

                            buttonList: [
                              /**['undo', 'redo'],
                              ['font', 'fontSize', 'formatBlock'],
                              ['paragraphStyle', 'blockquote'],
                              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                              ['fontColor', 'hiliteColor', 'textStyle'],
                              ['removeFormat'],
                              '/', // Line break
                              ['outdent', 'indent'],
                              ['align', 'horizontalRule', 'list', 'lineHeight'],
                              ['table', 'link', 'image' /** ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                              ['fullScreen', 'showBlocks', 'codeView'],
                              ['preview'],*/

                              /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                            ]
                            //buttonList: buttonList.complex
                            // plugins: [font] set plugins, all plugins are set by default
                            // Other option
                          }}
                          onChange={handleChangeStudentCommentsDesc}
                          //onImageUpload={handleImageUpload}
                          onImageUploadBefore={handleImageUploadBefore}
                        />
                        <div id="studentCommentsValidMsg" class="error">This field is required.</div>

                      </div>



                      <div class="col-lg-12 mb-5">

                        <div class="d-flex justify-content-between align-items-end">
                          <div>
                            <div id="parent_authorization">
                              <label for="firstName" class="form-label">MY PARENT/GUARDIAN AUTHORIZES MY INFORMATION TO BE SHARED (if under 18)</label>

                              <select class="form-select" aria-label="Default select example" style={{ maxWidth: "420px" }} id="authorization">
                                <option value="yes">Yes</option>
                                <option value="no">No</option>

                              </select>
                            </div>
                          </div>
                        </div>

                      </div>


                      <div class="col-lg-12 mb-4">

                        <div class="d-flex justify-content-end align-items-end">
                          <div>
                            {/* <a href="#" class="reg-button">Register</a> */}
                            <button type="button" onClick={go_back} class="reg-button" >Back</button>
                            <button type="submit" class="reg-button mx-2" >Submit</button>
                          </div>
                        </div>

                      </div>

                      <div id="successMessage" class="alert">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <img src="/images/success-alert.png" alt="" />
                            <strong class="mx-2">Success!</strong> Student  has been registered successfully.
                          </div>
                          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        </div>
                      </div>

                      <div id='alertFailMessage' class="error"></div>



                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
          </div>
        </section>
      </main>
    
      <main class="empr-directory-main cd-mobile">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item headtitle "><a href="#">Student Registration</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>


        <section class="company-detail-wrap " id="step3">
          <div>
            <div class="col-12 ">
              <form onSubmit={handleSubmit4(submitValueStep4)}>
                <div class="white-bg px-4 pt-4">
                <div class="row mb-0">
                      <div class="col-lg-12 m-auto">
                          <div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                              <h5 className='mb-0'>CAREER CHOICES</h5>
                              <div class="student-reg-step">STEP 4/4</div>
                            </div>
                      </div>
                    </div>
                  <div class="row stu-reg-st3 pt-3">

                  <div class="col-lg-12 mb-5">
                      <div><label for="firstName" class="form-label mr-2" >I AM SUBMITTING THIS FORM BECAUSE I AM LOOKING FOR:<span class="red-text">*</span></label> </div>
                      <div class="category-check-itm">
                        <ul>
                          {
                            jobTypes.map((data, index) => (
                              <li><input name="seeking_m" class="form-check-input seeking_mcls" type="checkbox" value={data.jobTypeId} id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                            ))}
                        </ul>
                      </div>
                      {errors4.seeking_m && errors4.seeking_m.type === "required" && <div class="error">Employment is required</div>}
                      <div id="seekingValidMsg_m" class="error">Please check atleast one</div>
                    </div>
                    <div class="col-lg-12 mb-5">
                      <div class="justify-content-between align-items-end">
                        <div>
                          <label for="resumePath" class="form-label">My RESUME (Optional) </label>
                          <div class="d-flex align-items-center">
                            {/* <input class="form-control form-control-lg choose-file mr-2" id="resumePath" type="file"/> */}
                            <input class="form-control" id="resumeMobile" type="file" onChange={() => getImageMobile()} />

                          </div>
                          <small> 1MB Max, File Type: PDF,DOC</small>
                          <div id="fileErrorMobile" class="error">Invalid file format</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12 mb-5">
                      <div><label for="firstName" class="form-label mr-2" >ANY ADDITIONAL THOUGHTS/COMMENTS THAT MIGHT BE HELPFUL? </label> </div>
                      <small>(Leave blank if not known.)</small>


                      <SunEditor
                        setOptions={{
                          height: "120",
                          textAlign:"left",
                          //buttonList: [['font', 'align'], ['image']]

                          buttonList: [
                           /** ['undo', 'redo'],
                            ['font', 'fontSize', 'formatBlock'],
                            ['paragraphStyle', 'blockquote'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/', // Line break
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link', 'image' /** ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                            ['fullScreen', 'showBlocks', 'codeView'],
                            ['preview'], */

                            /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                          ]
                          //buttonList: buttonList.complex
                          // plugins: [font] set plugins, all plugins are set by default
                          // Other option
                        }}
                        onChange={handleChangeStudentCommentsMobDesc}
                        //onImageUpload={handleImageUpload}
                        onImageUploadBefore={handleImageUploadBefore}
                      />
                      <div id="studentCommentsValidMsgMobile" class="error">This field is required.</div>
                      {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="comments_m" spellcheck="false" {...register4('thoughts', { required: true })}></textarea>
                                {errors4.thoughts && errors4.thoughts.type === "required" && <div class="error">Additional thoughts is required</div>} */}
                    </div>

                    <div class="col-lg-12" >
                      <div class="justify-content-between align-items-end">
                        <div id="parent_authorizationm">
                          <label for="firstName" class="form-label">MY PARENT/GUARDIAN AUTHORIZES MY INFORMATION TO BE SHARED (if under 18)</label>
                          <select class="form-select" aria-label="Default select example" style={{ maxWidth: "420px" }} id="authorization_m">
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>

                      </div>
                    </div>

                  <div class="mt-4 mb-4 grid-footer d-flex align-items-center">
                    <button type="button" onClick={go_back} class="reg-button" >Back</button>
                      <button type="submit" class="reg-button">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>


        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
          </div>
        </section>


      </main>

    </>
  )
};

StudentRegistrationCareerChoices.propTypes = {};

StudentRegistrationCareerChoices.defaultProps = {};

export default StudentRegistrationCareerChoices;
