//import React,{history,useEffect, useState, useMemo } from "react";
import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import $, { parseJSON } from 'jquery';

const Thankyou = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirectNum = queryParams.get('r');
    const [thankMsg, setThankMsg] = useState([]);
    const [disCompanyId, setDisCompanyId] = useState('');
    const [disUid, setDsUid] = useState('');
    const [disEmail, setDisEmail] = useState('');
    const [disUname, setDisUname] = useState('');
    const [disRole, setDisRole] = useState('');
    const [disKCDisplayName, setDisKCDisplayName] = useState('');

    var companyId = sessionStorage.getItem("logCompanyId");
    var dis_uid = sessionStorage.getItem("logUserId");
    var log_email = sessionStorage.getItem("logEmail");
    var lapp_email = sessionStorage.getItem("appEmail"); 
    var logDisplayRole =  sessionStorage.getItem("logDisplayRole"); 
    var logKCDisplayName = sessionStorage.getItem("logKCDisplayName"); 
   
    useEffect(() => {

        console.log(redirectNum);
        setDisUname(log_email);
        setDsUid(dis_uid);
        setDisCompanyId(companyId);
        setDisEmail(lapp_email);
        setDisRole(logDisplayRole);
        setDisKCDisplayName(logKCDisplayName);

        $("#goHomeSP1Login").hide();
        $("#logDebuMsg").hide();
        if(redirectNum=="9"){
            setThankMsg("Your application has been received and is in the screening process.");
            $("#goHomeSP1").show();
            $("#goHomeSP4").hide();
            $("#goHomeSP5").hide();
            $("#goHomeSP6").hide();
            $("#goHomeSP1Login").hide();
        }else if(redirectNum=="1"){
            setThankMsg("Thank you for applying Job.");
            $("#goHomeSP1").show();
            $("#goHomeSP4").hide();
            $("#goHomeSP5").hide();
            $("#goHomeSP6").hide();
            $("#goHomeSP1Login").hide();
        }else if(redirectNum=="2"){
            setThankMsg("Thank you for registering with us.");
            $("#goHomeSP1").show();
            $("#goHomeSP4").hide();
            $("#goHomeSP5").hide();
            $("#goHomeSP6").hide();
            $("#goHomeSP1Login").hide();
        }else if(redirectNum=="3"){
            setThankMsg("Thank you for Subscribing.");
            $("#goHomeSP4").hide();
            $("#goHomeSP1").hide();
            $("#goHomeSP5").hide();
            $("#goHomeSP6").show();
            $("#goHomeSP1Login").hide();
        }else if(redirectNum=="4"){
            setThankMsg("Applied Successfully.");
           // var dynHtml = '<a href="/profileDashboard" class="reg-button my-3">go to Dashboard</a>'
            //$("#goHomeSP").html(dynHtml);
            $("#goHomeSP4").show();
            $("#goHomeSP1").hide();
            $("#goHomeSP5").hide();
            $("#goHomeSP6").hide();
            $("#goHomeSP1Login").hide();
        }else if(redirectNum=="5"){
            setThankMsg("Applied Successfully.");
           // var dynHtml = '<a href="/profileDashboard" class="reg-button my-3">go to Dashboard</a>'
            //$("#goHomeSP").html(dynHtml);
            $("#goHomeSP4").hide();
            $("#goHomeSP1").hide();
            $("#goHomeSP5").show();
            $("#goHomeSP6").hide();
            $("#goHomeSP1Login").hide();
        }else if(redirectNum=="6"){
            setThankMsg("Applied Successfully.");
           // var dynHtml = '<a href="/profileDashboard" class="reg-button my-3">go to Dashboard</a>'
            //$("#goHomeSP").html(dynHtml);
            $("#goHomeSP4").hide();
            $("#goHomeSP1").hide();
            $("#goHomeSP5").hide();
            $("#goHomeSP6").show();
            $("#goHomeSP1Login").hide();
        }else if(redirectNum=="10"){
            setThankMsg("Your profile is Inactive.");
           // var dynHtml = '<a href="/profileDashboard" class="reg-button my-3">go to Dashboard</a>'
            //$("#goHomeSP").html(dynHtml);
            $("#goHomeSP4").hide();
            $("#goHomeSP5").hide();
            $("#goHomeSP6").hide();
            $("#goHomeSP1").show();
            $("#goHomeSP1Login").hide();
        }else if(redirectNum=="11"){
            setThankMsg("Network error please try login once again.");
           // var dynHtml = '<a href="/profileDashboard" class="reg-button my-3">go to Dashboard</a>'
            //$("#goHomeSP").html(dynHtml);
            $("#goHomeSP4").hide();
            $(".py-2").hide();
            $("#goHomeSP5").hide();
            $("#goHomeSP6").hide();
            $("#goHomeSP1").hide();
            $("#goHomeSP1Login").show();
            $("#logDebuMsg").show();
            
        }else{
            setThankMsg("Thank you for Contacting Us. We will get back to you very soon.");
            $("#goHomeSP1").show();
            $("#goHomeSP4").hide();
            $("#goHomeSP5").hide();
            $("#goHomeSP6").hide();
            $("#goHomeSP1Login").hide();
        }


      }, []);

  return(
  <>
  <main class="empr-directory-main">
        <div class="main-bgpatten"></div>

        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <div class="py-2">
                                        <img src="images/checked.png" width="90" alt=""/>
                                    </div>
                                    <h3 class="mb-4 red-text">{thankMsg}</h3>

                                    
                                    <span id="goHomeSP1"><a href="/home" class="reg-button my-3 m-auto">go to home</a></span>
                                    <span id="goHomeSP1Login"><a href="/Home?loginRedirect=1" class="reg-button my-3 m-auto">Login</a></span>
                                    <span id="goHomeSP4"><a href="/profileDashboard" class="reg-button my-3 m-auto">go to DashBoard</a></span>
                                    <span id="goHomeSP5"><a href="/studentDashboard" class="reg-button my-3 m-auto">go to DashBoard</a></span>
                                    <span id="goHomeSP6"><a href="/availableJobs" class="reg-button my-3 m-auto">go to Available Jobs</a></span>

                                    <div id="logDebuMsg">  
                                        <br></br>    <br></br>                                                                         
                                        CID:{disCompanyId}-UID:{disUid}-EM:{disEmail}-UN:{disUname}-Name:{disKCDisplayName}-Role:{disRole}-                 
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>

    </main>
  </>
)};

Thankyou.propTypes = {};

Thankyou.defaultProps = {};

export default Thankyou;