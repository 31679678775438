import React, { history, useEffect, useState, useMemo } from "react";
import Quick_links from "../../utility/Quick_links";
import Hiring from "../Jobs/Hiring";
import services from "../../services/services";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useNavigate, useParams } from "react-router-dom";
const CompanyLogo = () => {

   // const { companyId } = useParams(); 
   //const companyId = 24;
   const companyId=sessionStorage.getItem("logCompanyId");

   const navigate = useNavigate();

   const [logo, setLogo] = useState([]);
   const [logoBase64, setLogoBase64] = useState([]);
   const [imageName, setImageName] = useState([]);
   const [imageExtension, setImageExtension] = useState([]);
   const staticData = require('../../constants/'+process.env.React_App_Static_Folder+'/static-data.json');

 function getImage(){
       var files = document.getElementById('logo').files;

      //  const Filename=files[0].name.split('.').slice(0, -1).join('.');
      //  setImageName(Filename);
      
      //  console.log("@@@@@24@@@ =="+files[0]);
      //  console.log("@@@@@24@@@ =="+files[0].type);
      //  console.log("@@@@@24@@@ =="+JSON.stringify(files[0]));

   //    alert(files.substr(files.lastIndexOf('.') + 1));
   //    alert(files.split('.'));
   //    alert(/[^.]+$/.exec(filename));
   //    alert(files.split('.').pop());
   //    string.toString().split('/');
       // alert(files);
       if (files.length > 0) {
          
         console.log(files);
         var sizeInBytes = files[0].size;
         var sizeInMB = (sizeInBytes / (1024*1024)).toFixed(2);
         console.log(sizeInBytes+' - '+sizeInMB);
//return false;
         if(sizeInMB > "1"){
            $("#imageSizeError").show();
            return false;
        }
        else {
            $("#imageSizeError").hide();

            var fname = files[0].name;
            // var fextn = fname.substr(0, fname.lastIndexOf('.'));
            var fextn = fname.split(".").pop();
            // alert(fextn);
            var fileActName = fname.split(".")[0];
            // alert(fileActName);
            setImageName(fileActName);
            setImageExtension(fextn);
            getBase64(files[0]);
            if(fextn != "png" && fextn != "jpeg" && fextn != "jpg" && fextn != "gif"  ){
               //document.getElementById('logo').reset();
               $("#imgError").show();
               return false;
            }else{
               $("#imgError").hide();
            }
         }

       }
     }

   function getBase64(file) {
       var reader = new FileReader();
       reader.readAsDataURL(file);
       reader.onload = function () {
         // console.log(reader.result);
         var base64result = reader.result.split(',')[1];
         setLogoBase64(base64result)
       };
       reader.onerror = function (error) {
         console.log('Error: ', error);
       };
    }

   useEffect(() => {
      const jsonData = {
         "companyLogo": {
           
         }
       };

     var jsondataformatted = JSON.stringify(jsonData);
     sessionStorage.setItem('pagejsonObject', jsondataformatted);

      if(companyId == null || companyId == "null"){            
         //navigate('/login');
         navigate('/Home');
       }
      getCompanyProfile();
      $("#imgError").hide();
      $("#imageSizeError").hide();

   }, []);

   const getCompanyProfile = () => {
      services.getCompanyProfile(companyId).then((res) => {
        if(res.data.logoUrl==''){
          
          //setLogo('https://snv3-dev-fs.s3.us-east-2.amazonaws.com/HometownMigration/uploads/default-logo.png');
          setLogo(staticData.default_logo);
        }else{
         setLogo(res.data.logoUrl);
         //setLogo('/images/no-logo.jpg');
        }
     
      });
   }

   const {
      register,
      formState: { errors },
      handleSubmit
   } = useForm({
      criteriaMode: "all", mode: "onBlur"
   });

   const submitValue = () => {

      // const requestObject =
      // {
      //    "companyId": "24",
      //    "siteId": 2,
      //    // "logoUrl": "https://hometownopportunity.com"
      //    "logoUrl": $("#logo").val()

      // }

      if(imageExtension != "png" && imageExtension != "jpeg" && imageExtension != "jpg" && imageExtension != "gif"  ){
         //document.getElementById('logo').reset();
         $("#imgError").show();
         return false;
      }else{
         $("#imgError").hide();
      }

      const requestObject ={
         "logoName":imageName,
         "companyId": companyId,
         "logoExtension":imageExtension,
         "logoByteString":logoBase64 
       }

      console.log(requestObject);
      var accessTokenIn=sessionStorage.getItem("accToken");
      const requestOptions = {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
             'Authorization': "Bearer "+accessTokenIn 
             //'Authorization': accessTokenIn 
         },
         body: JSON.stringify(requestObject)
      };

      services.editCompanyLogo(requestOptions).then((res) => {
         // navigate('/thankyou');
         $("#addUserForm").trigger("reset");
         getCompanyProfile();
      });

   }



   return (
      <>
         <main class="empr-directory-main">
            <div class="main-bgpatten"></div>
            {/* <!-- info typography --> */}
            <section class="headtitle-wrap">
               <div class="container">
                  <div class="row">
                     <div class="col-lg-12">
                        <nav style={{ "--bs-breadcrumb-divider": '>' }} aria-label="breadcrumb">
                           <ol class="breadcrumb">
                              <li class="breadcrumb-item headtitle "> <a href="/companyJobs">Company Dashboard</a>  <span style={{ color: "#6c757d" }}>&#62;</span></li>
                              <li class="breadcrumb-item headtitle "><a href="#">Company Logo</a></li>
                           </ol>
                        </nav>
                     </div>
                  </div>
               </div>
            </section>
            {/* <!-- info typography --> */}
            <section class="py-5">
               <div class="container">
                  <div class="row">
                     <div class="col-12 ">
                        <div class="white-bg p-5">
                           <div class="row">
                              {/* <!-- left side --> */}
                              <Quick_links />

                              {/* <!-- right side --> */}
                              <div class="col-lg-8 mb-4">
                              <form id="addUserForm" onSubmit={handleSubmit(submitValue)}>
                                 <div class="row resume-wrap">
                                    <div class="col-lg-12 mb-4 ">

                                       <div class=" mb-4 ">
                                       {/* <div class="card p-3 mb-4"> */}
                                          {/* <img src="images/midmark-logo.jpg" class="p-4 mb-2 m-auto" alt=""/> */}
                                          <img src={logo} class="w-100 mb-2 m-auto com-logo-indv" alt="" />
                                       </div>

                                       <div class="d-flex justify-content-between align-items-center choose">
                                          <div>
                                             <label for="firstName" class="form-label">Change Logo </label>
                                             <div class="d-flex align-items-center">
                                                <input class="form-control form-control-lg" id="logo" type="file" onChange={()=>getImage()}/>
                                                
                                             </div>
                                             <small> 1 MB Max, File type: png or jpg</small>
                                             <div id="imgError"  class="error">Invalid file format</div>
                                             <div id="imageSizeError" class="error">Image size is greater than 1 MB. Please upload Image below 1MB.</div>







                                          </div>
                                          <div><button type="submit" class="reg-button">Save</button></div>
                                       </div>

                                    </div>
                                 </div>
                                 </form>

                              </div>
                           </div>
                        </div>

                     </div>

                  </div>
               </div>
            </section>
            <section class="white-bg py-5">
               <div class="container">
                  {/* <Hiring /> */}
                  {/* <div class="row mb-4">
                  <div class="col-12">
                     <div class="d-flex justify-content-between border-bottom  mb-4 pb-4 flex-lg-row flex-column">
                        <h4 class="headtitle">Top companies hiring in your area</h4>
                        <a href="#" class="text-uppercase">See All</a>
                     </div>
                  </div>
                  
               </div>
               <div class="row">
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <div class="card p-3">
                        <img src="images/midmark stock.png" class="w-100 mb-2" alt=""/>
                        <img src="images/midmark-logo.JPG" class="stock-logo" alt=""/>
                        <div class="text-center stock-wrap">
                           <h3>Midmark Corporation</h3>
                        <span>Versailles, Ohio</span>
                        <a href="#">15 OPEN JOBS</a>
                        </div>
                     </div>
                  </div>

                
               </div> */}
               </div>
            </section>
         </main>
      </>
   )
};

CompanyLogo.propTypes = {};

CompanyLogo.defaultProps = {};

export default CompanyLogo;
