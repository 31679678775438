//import React from "react";
import React, { history, useEffect, useState, useMemo } from "react";
import { useSearchParams } from 'react-router-dom';
import './../css/bootstrap.min.css';
import '../assets/css/custome.css';
import '../assets/css/media.css';
import '../assets/css/animate.min.css';
import { BrowserRouter } from "react-router-dom";
import MainContent from "./mainContent/MainContent";
import Appbar from "./header/header";
import PublicHeader from "./header/publicHeader";

import HeaderHTA from "./header/headerHTA";
import HeaderHTO from "./header/headerHTO";

import PublicHeaderHTA from "./header/publicHeaderHTA";
import PublicHeaderHTO from "./header/publicHeaderHTO";

import Footer from "./footer/index";
import MetaTags from 'react-meta-tags';

function Layout() {
  const [pageURLName, setPageURLName] = useState('');
  const [pageURLArray, setPageURLArray] = useState(['/jobSummary', '/jobsByCompany', '/ourlibrary', '/educationTraining', '/employerDirectory', '/companyRegister', '/studentRegistration', '/postResume', '/schoolDetails', '/schoolList', '/movingToTheArea', '/events', '/CompanyListByIndustry', '/industries', '/videoLibrary', '/marketSupport', '/locallifeCommunities', '/availableJobs', '/jobsummary', '/companyDetails']);
  const staticData = require('../constants/'+process.env.React_App_Static_Folder+'/static-data.json');
  useEffect(() => {

    //alert(window.location.pathname)
    // setPageURLName(window.location.pathname); 
    //alert("test");



  }, []);

  return (


    <BrowserRouter >
      {/* <Appbar/> */}
      {/* <PublicHeader/> */}



      {(() => {

        if(process.env.React_App_Static_Folder == "HTO"){
          if (pageURLArray.indexOf(window.location.pathname) > -1) {
            return (<> <PublicHeaderHTO /> </>);
          } else {
            return (<> <HeaderHTO /></>);
          }
        }else  if(process.env.React_App_Static_Folder == "HTA"){
          if (pageURLArray.indexOf(window.location.pathname) > -1) {
            return (<> <PublicHeaderHTA /> </>);
          } else {
            return (<> <HeaderHTA /></>);
          }
        }else{
          if (pageURLArray.indexOf(window.location.pathname) > -1) {
            return (<> <PublicHeader /> </>);
          } else {
            return (<> <Appbar /></>);
          }
        }
       

      })()}





      {/* <MetaTags>
      

      <title>Opportunity Awaits – Hometown Opportunity</title>
      <meta property="og:locale" content="en_US" />         
      <meta name="title" content="Opportunity Awaits – Hometown Opportunity" />
      <meta name="description" content="Opportunity Awaits – Hometown Opportunity" />

     
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.hometownopportunity.com/" />
      <meta property="og:title" content="Opportunity Awaits – Hometown Opportunity" />
      <meta property="og:description" content="Opportunity Awaits – Hometown Opportunity" />
      <meta property="og:image" content="https://snv3-hto-fs.s3.us-east-2.amazonaws.com/assets/meta/hto-fb.png" />

      
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="http://career-sites-v3.s3-website.us-east-2.amazonaws.com/" />
      <meta property="twitter:title" content="Opportunity Awaits – Hometown Opportunity" />
      <meta property="twitter:description" content="Opportunity Awaits – Hometown Opportunity" />
      <meta property="twitter:image" content="https://snv3-hto-fs.s3.us-east-2.amazonaws.com/assets/meta/hto-fb.png"></meta>


      </MetaTags> */}

      <MainContent />
      <Footer />
    </BrowserRouter>


  );
}

export default Layout;