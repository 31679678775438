import React, { useEffect, useState } from 'react';

import { ErrorMessage } from "@hookform/error-message";
import { set, useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import StudentRegistration2 from "./student-registration2";
import StudentRegistration3 from "./student-registration3";
import Services from '../../services/services';
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup'
import Header from '../../layouts/header/header';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SunEditor, { buttonList } from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';

const StudentRegistrationPersonalInfo = () => {

  const [pwd, setPwd] = useState('');
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealConfPwd, setIsRevealConfPwd] = useState(false);
  const [showPwdImg, setShowPwdImg] = useState('/images/hide-password.svg');
  const [hidePwdImg, setHidePwdImg] = useState('/images/show-password.svg');
  const [isRevealPwdMb, setIsRevealPwdMb] = useState(false);
  const [isRevealConfPwdMb, setIsRevealConfPwdMb] = useState(false);
  const [studentEmail, setStudentEmail] = useState('');

  const navigate = useNavigate();

  const userIdChkLogin = sessionStorage.getItem("logUserId");
  const studentRegId = sessionStorage.getItem("studentRegId");
  const {
    register: register4,
    formState: { errors: errors4 },
    handleSubmit: handleSubmit4,
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });

  const {
    register: register3,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });

  useEffect(() => {
    const jsonData = {
      "studentRegistrationPersonalInfo": {
        
      }
    };
    var jsondataformatted = JSON.stringify(jsonData);
    sessionStorage.setItem('pagejsonObject', jsondataformatted);

    if(studentRegId != null){
      getStudentProfile(studentRegId);
    }
    $(".se-dialog-tabs").hide();
    $("#fileErrorMobile").hide();
    $(".load-wrap").hide();
    $("#loginexists").hide();
    $("#successMessage").hide();
    $("#alertFailMessage").hide();
    $("#loginexists_m").hide();
    $("#successMessage_m").hide();
    $("#alertFailMessage_m").hide();
    $("#studentCareersValidMsg").hide();
    $("#coursesRelatedValidMsg").hide();
    $("#studentSkillsValidMsg").hide();
    $("#studentCoursesValidMsg").hide();
    $("#studentCommentsValidMsg").hide();
    $('#seekinginterestValidMsg').hide();
    $("#categoriesinterestedValidMsg").hide();
    $("#studentCareersValidMsgMobile").hide();
    $("#coursesRelatedValidMsgMobile").hide();
    $("#studentSkillsValidMsgMobile").hide();
    $("#studentCoursesValidMsgMobile").hide();
    $("#studentCommentsValidMsgMobile").hide();
    $('#school_districtValidMsg').hide();
    $('#university_nameValidMsg').hide();
    $('#school_districtValidMsg_m').hide();
    $('#university_nameValidMsg_m').hide();
    $('#pursuing_degreeValidMsg').hide();
    $('#pursuingValidMsg_m').hide();
    $("#matchPwd_m").hide();
    $("#matchPwd").hide();
    $('#yourphone2').usPhoneFormat();
    $('#mobile1_m').usPhoneFormat();
    $('#passwordValidMsg').hide();
    $('#confpasswordValidMsg').hide();
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
    $('#mobile1').usPhoneFormat();
    $('#step2').hide();
    $('#step3').hide();
    
    $('#college_div').hide();
    $('#college_divm').hide();
    $("#fileError").hide();
    $("#schoolIdOtherDiv").hide();
    $("#schoolIdOtherDiv_m").hide();
    $('#collegeIdOtherDiv').hide();
    $("#collegeIdOtherDiv_m").hide();
    $('#schoolIdOthermsg').hide();
    $('#collegeIdOthermsg').hide();
    $('#schoolIdOther_mmsg').hide();
    $('#collegeIdOther_mmsg').hide();
    $('#planedu').hide();
    $('#extensionvalidmsg').hide();
    $('#planedu_m').hide();
    $('#extensionvalidmsg_m').hide();
    setTimeout(() => {
      $('#pursuing_degree').val('3');
      $('#pursuing_m').val('3');
      $('input[id="inlineRadio2"]').prop('checked', true);
      $('input[id="inlineRadio2_m"]').prop('checked', true);
    }, 1000)


    $('#continueEduDiv').hide();
    $('#continueEduDiv_m').hide();
    $("#inlineRadio1, #inlineRadio2").click(function () {
      var selectedEdu = $('input[name="continueEducation"]:checked').val();
      // alert(selectedEdu);
      if (selectedEdu == "Y") {
        $("#continueEduDiv").show();
      } else if (selectedEdu == "N") {
        $('#collegeId').val('');
        $('#pursuingProgram').val('');
        $("#continueEduDiv").hide();
      }
    });

    $("#inlineRadio1_m, #inlineRadio2_m").click(function () {
      var selectedEdu = $('input[name="continueEducation_m"]:checked').val();
      // alert(selectedEdu);
      if (selectedEdu == "Y") {
        $("#continueEduDiv_m").show();
      } else if (selectedEdu == "N") {
        $('#collegeId_m').val('');
        $('#pursuingProgram_m').val('');
        $("#continueEduDiv_m").hide();
      }
    });
    //s$('input[name="pursuing_degree"][value="3"]').prop("selected", true);
  }, []);

  const getStudentProfile = (studentId) => {
    Services.getpubStudentProfile(studentId).then((res) => {
      var companyCatIn = res.data.categoriesOfExpertise;
      $("#firstName").val(res.data.firstName);
      $("#lastName").val(res.data.lastName);
      $("#email").val(res.data.email);
      $("#yourphone2").val(res.data.phone);
      setStudentEmail(res.data.email);
      $('#password').val(res.data.password);
      $('#passwordConf').val(res.data.password);
    });
  }

  function goLogin() {
    window.location.href = "/Home?loginRedirect=1";
  }

  
  

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };


  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(4, 'Password length should be at least 4 characters'),
    passwordConf: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords must and should match'),
  })

  const validationOpt = { resolver: yupResolver(formSchema) }
  const [itemName, setFieldName] = useState('');

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    criteriaMode: "all",
    mode: "onBlur"
    //resolver: yupResolver(formSchema)
  });

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });

  const submitValueStep1 = (data) => {
    var password_regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    var passwordConf = $("#passwordConf_m").val();
    var passwordCheck = $("#password_m").val();

    if (passwordCheck != "" && password_regex.test(passwordCheck) == false) {
      $("#passwordValidMsg").show();
      $(window).scrollTop($('#passwordValidMsg').position().top);
      return false;
    } else {
      $("#passwordValidMsg").hide();
    }

    if (passwordConf != "" && password_regex.test(passwordConf) == false) {
      $("#confpasswordValidMsg").show();
      $(window).scrollTop($('#confpasswordValidMsg').position().top);
      return false;
    } else {
      $("#confpasswordValidMsg").hide();
    }

    if (passwordConf != passwordCheck) {
      $("#matchPwd_m").show();
      $("#passwordConf_m").focus();
      return false;
    } else {
      $("#matchPwd_m").hide();
    }
    const requestObject = {
      "siteId": process.env.React_App_SITE_ID,
      "firstName": $('#firstName_m').val(),
      "lastName": $('#lastName_m').val(),
      "email": $('#email_m').val(),
      "password": $('#password_m').val(),
      "phone": $("#mobile1_m").val(),
      "isStudent": true,
    }
   // console.log(requestObject); return false;
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "Bearer "+accessTokenIn
        //'Authorization': accessTokenIn
      },
      body: JSON.stringify(requestObject)
    };
    $(".load-wrap").show();
    Services.studentRegistrationp1(requestOptions).then((res) => {
      $(".load-wrap").hide();
      if (res.success == "false" || res.success == false) {
        $("#loginexists_m").show();
        /**$("#alertFailMessage_m").html(res.message);
        $("#alertFailMessage_m").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#alertFailMessage_m").slideUp(1000);
        }); */

      } else {
        sessionStorage.setItem("studentRegId", res.id);
        $("#successMessage_m").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#successMessage_m").slideUp(1000);
        });
        navigate('/studentRegistrationSchoolInfo');
      }
    });
  }

  const submitValue = (data) => {
    var phone_val = $("#yourphone2").val();
    var passwordConf = $("#passwordConf").val();
    var passwordCheck = $("#password").val();
    if (passwordConf != passwordCheck) {
      $("#matchPwd").show();
      $("#passwordConf").focus();
      return false;
    } else {
      $("#matchPwd").hide();
    }
    //phone_val = "1234567899";
    const requestObject = {
      "siteId": process.env.React_App_SITE_ID,
      "firstName": $('#firstName').val(),
      "lastName": $('#lastName').val(),
      "email": $('#email').val(),
      "password": $('#password').val(),
      "phone": $("#yourphone2").val(),
      "isStudent": true,
    }
   // console.log(requestObject); return false;
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "Bearer "+accessTokenIn
        //'Authorization': accessTokenIn
      },
      body: JSON.stringify(requestObject)
    };
    $(".load-wrap").show();
    Services.studentRegistrationp1(requestOptions).then((res) => {
      $(".load-wrap").hide();
      if (res.success == "false" || res.success == false) {
        $("#loginexists").show();
        /*
        $("#alertFailMessage").html(res.message);
        $("#alertFailMessage").fadeTo(2000, 1000).slideUp(1000, function () {
         // $("#alertFailMessage").slideUp(1000);
        }); */

      } else {
        sessionStorage.setItem("studentRegId", res.id);
        $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#successMessage").slideUp(1000);
        });
        navigate('/studentRegistrationSchoolInfo');
      }
    });
  }
  
  return (
    <>
      <main class="empr-directory-main cd-desktop">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item headtitle "><a href="#">Student Registration</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">
          <div class="container">
            <div class="row">
              <div class="col-12 ">
                <form onSubmit={handleSubmit(submitValue)}>
                  <div class="white-bg px-5 pt-4 pb-4">
                    <div class="row mb-0">
                      <div class="col-lg-12 m-auto">


                        {(() => {
                          if (userIdChkLogin != null && userIdChkLogin != "null") {
                            return (<></>);
                          } else {
                            return (<><div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                              <h6 className='mb-0'>Do you already have a profile?</h6>
                              <a onClick={() => goLogin()} className='reg-button  text-center'>SIGN IN</a>
                            </div></>);
                          }
                        })()}


                        <h2 class="text-center ">
                          What if we told you  <span class=" red-text"> your hometown</span>  is a great place to plan your career?
                        </h2>
                        <div class="col-4 m-auto">
                          <hr />
                        </div>
                      </div>
                    </div>
                    <div style={{ margin: "0px -49px 30px -48px" }}>
                      <img src="images/student-montage.png" class="w-100" />
                    </div>
                    <div class="row text-center my-2">
                      <div class="col-12 m-auto">
                        <b>
                          Now, don’t go all scrunchy-faced on us. It’s true! Your local area is packed full of <span class="red-text">companies</span> who offer amazing jobs with great pay and plenty of room to grow.
                        </b>
                        <p class="my-3">
                          Hometown Opportunity is the place to learn who they are, what they do, and how they can offer you a chance to build the life you’ve always wanted… right here in your hometown. Plus, we have plenty of resources for you to learn more about your chosen career path, get internships and co-ops, get part-time jobs while you’re in school and build a strong foundation for your future.
                        </p>
                        {/* <b>
                          Register below and get started, then be sure to download the <span class="red-text">Hometown Opportunity</span> app to stay connected through your high school career.
                        </b> */}


                      </div>
                      {/* <div class="col-8 m-auto ">
                     <br></br>
                        Are you a college student? Please <a href="/postResume" class="red-text">click here</a> to register as a Job Seeker to view exciting career opportunities.

                      </div> */}
                    </div>
                    <div class="row mt-4 resume-wrap">
                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">First name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="firstName" onChange={e => setFieldName(e.target.value)} {...register("FirstName", {
                          required: "First name is required.",

                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="FirstName"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">Last name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="lastName" onChange={e => setFieldName(e.target.value)} {...register("lastName", {
                          required: "Last name is required.",

                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="lastName"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="email" class="form-label mr-2">Personal Email <span class="red-text">*</span></label> <small></small></div>
                        <p style={{ fontSize: "12px" }}>Please do not use your school email.</p>
                        <input type="text" class="form-control" id="email" onChange={e => setFieldName(e.target.value)} {...register("Email", {
                          required: "Email is required.",

                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address"
                          }
                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="Email"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                        <div id="loginexists" class="error">An account already exists with this email. Please <a onClick={() => goLogin()}>signin</a> to complete your profile.</div>
                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="phone" class="form-label mr-2">Phone<span class="red-text">*</span></label> <small>XXX-XXX-XXXX Format</small></div>
                        <p style={{ fontSize: "12px" }}>Use your parent's phone number if you do not have one.</p>
                        <input type="text" class="form-control" id="yourphone2" name="contactNumber" maxlength="14" onChangeText={(text) => this.onTextChange(text)} {...register("phone", {
                          required: "Phone field is required.",
                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="phone"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Password <span class="red-text">*</span></label></div>
                        <p style={{ fontSize: "12px" }}>Must be a minimum of 8 characters & MUST include all of the following: a lowercase letter, an uppercase letter, a number, and a special character</p>
                        <span class="position-relative d-block">
                          <input type={isRevealPwd ? "text" : "password"} class="form-control" id="password" onChange={e => setFieldName(e.target.value)} {...register("Password", {
                            required: "Password is required.",
                            pattern: {
                              value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                              message: "Password contain uppercase,lowercase and special character"
                            },
                            minLength: { value: 8, message: 'Too short' }
                          })} />
                          <img src={isRevealPwd ? hidePwdImg : showPwdImg} class="pwdShowHideClsPostResume"
                            onClick={() => setIsRevealPwd(prevState => !prevState)}
                            alt="" />
                        </span>
                        <ErrorMessage
                          errors={errors}
                          name="Password"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />

                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Confirm Password <span class="red-text">*</span></label>  </div>
                        <p style={{ fontSize: "12px" }}>Must be a minimum of 8 characters & MUST include all of the following: a lowercase letter, an uppercase letter, a number, and a special character</p>
                        <span class="position-relative d-block">
                          <input type={isRevealConfPwd ? "text" : "password"} class="form-control" id="passwordConf" onChange={e => setFieldName(e.target.value)} {...register("PasswordConf", {
                            required: "Password is required.",
                            pattern: {
                              value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                              message: "Password contain uppercase,lowercase and special character"
                            },
                            minLength: { value: 8, message: 'Too short' }
                          })} />
                          <img src={isRevealConfPwd ? hidePwdImg : showPwdImg} class="pwdShowHideClsPostResume"
                            onClick={() => setIsRevealConfPwd(prevState => !prevState)}
                            alt="" />
                        </span>
                        <ErrorMessage
                          errors={errors}
                          name="PasswordConf"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                        <p id="matchPwd" class="error">Passwords must match</p>

                      </div>

                      <div class="col-lg-12 mb-4">
                        <div class="d-flex justify-content-end align-items-end">
                          <div><button type="submit" class="reg-button" >Register</button></div>
                        </div>
                      </div>

                      <div id="successMessage" class="alert">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <img src="/images/success-alert.png" alt="" />
                            <strong class="mx-2">Success!</strong> Student Account has been created successfully.
                          </div>
                          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        </div>
                      </div>

                      <div id='alertFailMessage' class="error"></div>
                      


                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
          </div>
        </section>
      </main>

      <main class="empr-directory-main cd-mobile">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item headtitle "><a href="#">Student Registration</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- info typography Step 1--> */}
        <section class="company-detail-wrap py-5" id="step1">
          <div class="container">
            <div class="row">
              <div class="col-12 ">
                <form onSubmit={handleSubmit2(submitValueStep1)}>
                  <div class="white-bg px-5 pt-4 pb-4">
                    <img src="images/student-montage-mobile.jpg" class="w-100 mb-4" alt="" />
                    <div class="row mb-3">
                      <div class="col-lg-12 m-auto">
                        <h1 class="text-center ">
                          What if we told you <span class=" red-text"> your hometown</span> <br /> is a great place to plan your career?
                        </h1>
                        <div class="col-12 text-center m-auto mt-4">
                          <b><a href='#firstName_m' className='click'>Click Here to Register</a></b>
                        </div>
                      </div>
                    </div>

                    <div class="row text-center">
                      <div class="col-10 m-auto">
                        <b>
                          Now, don’t go all scrunchy-faced on us. It’s true! Your local area is packed full of <span class="red-text">companies</span> who offer amazing jobs with great pay and plenty of room to grow.
                        </b>
                        <p class="my-3">
                          Hometown Opportunity is the place to learn who they are, what they do, and how they can offer you a chance to build the life you’ve always wanted… right here in your hometown. Plus, we have plenty of resources for you to learn more about your chosen career
                          path, get internships and co-ops, get part-time jobs while you’re in school and build a strong foundation for your future.
                        </p>
                      </div>
                    </div>
                    <div class="row mt-4 resume-wrap">
                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">First name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="firstName_m" placeholder=""  {...register2('firstName', { required: true })} />
                        {errors2.firstName && errors2.firstName.type === "required" && <div class="error">First name is required</div>}
                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">Last name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="lastName_m" placeholder="" {...register2('lastName', { required: true })} />
                        {errors2.lastName && errors2.lastName.type === "required" && <div class="error">Last name is required</div>}
                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Personal Email <span class="red-text">*</span></label> <small>Please do not use your school email.</small></div>
                        <input type="text" class="form-control" id="email_m" placeholder="" {...register2('email', { required: true, pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ })} />
                        {errors2.email && errors2.email.type === "required" && <div class="error">Email is required</div>}
                        {errors2.email && errors2.email.type === "pattern" && <div class="error">Please provide valid email.</div>}
                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Phone <span class="red-text">*</span></label> <small>XXX-XXX-XXXX Format</small></div>
                        <input type="text" class="form-control" id="mobile1_m" {...register2('phone', { required: true })} />
                        {errors2.phone && errors2.phone.type === "required" && <div class="error">Phone is required</div>}
                      </div>

                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Password <span class="red-text">*</span></label> </div>
                        <p style={{ fontSize: "12px" }}>Must be a minimum of 8 characters & MUST include all of the following: a lowercase letter, an uppercase letter, a number, and a special character</p>
                        <span class="position-relative d-block">
                          <input type={isRevealPwdMb ? "text" : "password"} class="form-control" id="password_m" placeholder="" {...register2('password_m', { required: true })} />
                          <img src={isRevealPwdMb ? hidePwdImg : showPwdImg} class="pwdShowHideClsPostResume"
                            onClick={() => setIsRevealPwdMb(prevState => !prevState)}
                            alt="" />
                        </span>
                        {errors2.password_m && errors2.password_m.type === "required" && <div class="error">Password is required</div>}
                        <div id="passwordValidMsg" class="error" >Password contain uppercase,lowercase and special character.</div>
                      </div>

                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Confirm Password <span class="red-text">*</span></label></div>
                        <p style={{ fontSize: "12px" }}>Must be a minimum of 8 characters & MUST include all of the following: a lowercase letter, an uppercase letter, a number, and a special character</p>
                        <span class="position-relative d-block">
                          <input type={isRevealConfPwdMb ? "text" : "password"} class="form-control" id="passwordConf_m" placeholder="" {...register2('passwordConf_m', { required: true })} />
                          <img src={isRevealConfPwdMb ? hidePwdImg : showPwdImg} class="pwdShowHideClsPostResume"
                            onClick={() => setIsRevealConfPwdMb(prevState => !prevState)}
                            alt="" />
                        </span>
                        {errors2.passwordConf_m && errors2.passwordConf_m.type === "required" && <div class="error">Password is required</div>}
                        <div class="error" id="matchPwd_m">Passwords must match</div>
                        <div id="confpasswordValidMsg" class="error" >Password contain uppercase,lowercase and special character.</div>
                      </div>

                      <div class="mt-4 mb-4">
                        {/* <a href="#" class="reg-button">Next &#62;&#62;</a> */}
                        <button type="submit" class="reg-button"  >Register &#62;&#62;</button>
                      </div>

                      <div id="successMessage_m" class="alert">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <img src="/images/success-alert.png" alt="" />
                            <strong class="mx-2">Success!</strong> Student Account has been created successfully.
                          </div>
                          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        </div>
                      </div>

                      <div id='alertFailMessage_m' class="error"></div>
                      <div id="loginexists_m" class="error">An account already exists with this email. Please <a onClick={() => goLogin()}>signin</a> to complete your profile.</div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
          </div>
        </section>


      </main>
    </>
  )
};

StudentRegistrationPersonalInfo.propTypes = {};

StudentRegistrationPersonalInfo.defaultProps = {};

export default StudentRegistrationPersonalInfo;
