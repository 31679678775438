//import React,{history,useEffect, useState, useMemo } from "react";
import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { set, useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Services from '../../services/services';
import UsNumberValidate from '../../utility/usNumberValidate';
import services from "../../services/services";
import moment from "moment";
//import { useNavigate,useParams } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const PersonalProfileView = () => {

    const [jobTypes, setJobTypes] = useState([]);
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [jobType, setJobType] = useState([]);
    // const{jobSeekerId}=
    const [jobSeekerDetails, setJobSeekerDetails] = useState([]);
    //const jobSeekerId = 11;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const jobSeekerId = queryParams.get('profileId');
    const pageid = queryParams.get('pppage');
    const [fileName, setFileName] = useState([]);
    const [fileExtension, setFileExtension] = useState([]);
    const [fileBase64, setFileBase64] = useState([]);

    const [pdfDownload, setPdfDownload] = useState([]);
    const navigate = useNavigate();

    function strCatArr(arr){
      if(Array.isArray(arr)){
        //return arr.join(arr,", ");
        return arr.join(", ");
      }else{
        return "";
      }
    }
    function getImage(){
        var files = document.getElementById('resume').files;
        if (files.length > 0) {

          var fname = files[0].name;
          // var fextn = fname.substr(0, fname.lastIndexOf('.'));
          var fextn = fname.split(".").pop();
          // alert(fextn);
          var fileActName = fname.split(".")[0];
          // alert(fileActName);
          setFileName(fileActName);
          setFileExtension(fextn);
          getBase64(files[0]);

          // if(fextn != "pdf"){
          if(fextn != "doc" && fextn != "docx" && fextn != "pdf" ){

            $("#fileError").show();
                return false;
            }else{
                $("#fileError").hide();
                return true;
            }

        }
      }

      function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          // console.log(reader.result);
          var base64result = reader.result.split(',')[1];
          setFileBase64(base64result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }

     const getProfile = (jobSeekerId) => {
        services.getProfile(jobSeekerId).then((res) => {
            setJobSeekerDetails(res.data);
            $("#firstName").val(res.data.firstName);
            $("#lastName").val(res.data.lastName);
            $("#email").val(res.data.email);
            $("#phone").val(res.data.phone);
            $("#personalProfile").val(res.data.personalProfile);
            $("#education").val(res.data.educationExperiance);
            $("#lookingFor").val(res.data.lookingFor);
            $("#jobType").val(res.data.jobType);
            $("#resume").val(res.data.pdfByString);
            setPdfDownload(res.data.resumePath);

            var fext = res.data.fileExtension;
            if( (fext != ".doc" && fext != ".docx" && fext != ".pdf" ) || (fext == "")  ){
                //if(res.data.resumePath == ""){
                $("#resumeDiv").hide();
            }
            setJobType(res.data.jobType);
            console.log("jobseeker@@@@@@@@"+JSON.stringify(res.data));

            setCategoriesSelected(res.data.categoriesOfExpertise);
            var companyCatIn = res.data.categoriesOfExpertise;

            $('#jobType').prop('selectedIndex', res.data.jobType);


            setTimeout(() => {
              for (var i = 0; i < companyCatIn.length; i++) {
                //$("input[value='" + val + "']").prop('checked', true);
                console.log("###" + companyCatIn[i]);
                //$('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
                $('input[name="category"][value="' + companyCatIn[i] + '"]').trigger("click");
              }
              validateCheck();

              var isCheck = true;

              var checkedValues = $("input[name='category']").val();
              // var checkedValues = [];
              //     $.each($("input[name='category']:checked"), function () {
              //       checkedValues.push($(this).val());
              //     });
              // alert(checkedValues);
              if (!checkedValues) {
                isCheck = false;
                $("#categoryError").html("Category field is required");
              } else {
                // alert(checkedValues);
                isCheck = true;
                $("#categoryError").html("");
              }

              return isCheck;

            }, 500);


        });
     }

    const [categories, setCategory] = useState([]);




    const {
        register,
        formState: { errors },
        handleSubmit
      } = useForm({
        criteriaMode: "all", mode: "onBlur"
      });


    useEffect(() => {
      // const jsonData = {
      //   "personalProfileView": {
          
      //   }
      // };
      // var jsondataformatted = JSON.stringify(jsonData);
      // sessionStorage.setItem('pagejsonObject', jsondataformatted);

      $("#successMessage").hide();
        $('#phone').usPhoneFormat();
      getIndustryData();
      getProfile(jobSeekerId);
      $("#fileError").hide();

      Services.getJobType().then((res) => {
        console.log("setJobType data=" + JSON.stringify(res.data));
        setJobTypes(res.data.content);
      });

    }, []);

    const getIndustryData = () => {

      Services.getIndustryData().then((res) => {

        console.log("Industry data=" + JSON.stringify(res.data));

        setCategory(res.data.content);

      });

    }


    function validateCategory() {
      // alert("@@category");
      var isCheck = true;

      var checkedValues = $("input[name='category']:checked");
      // var checkedValues = [];
      //     $.each($("input[name='category']:checked"), function () {
      //       checkedValues.push($(this).val());
      //     });
      //  alert(checkedValues);
      // alert(checkedValues.length);
      var lenghtCheck=checkedValues.length;
      if (lenghtCheck==0) {
        isCheck = false;
        $("#categoryError").html("Category field is required");
      } else {
        // alert(checkedValues);
        isCheck = true;
        $("#categoryError").html("");
      }

      return isCheck;
    }

   const validateCheck = () =>{
        const checked=$('input[name="category"]:checked').length;

        if (checked >= 3) {
          $('input[name="category"]').not(':checked').prop('disabled', true);
        } else {
          $('input[name="category"]').prop('disabled', false);
        }
      }

  return(
  <>
    <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle ">
                                  {/* <a href={"/personalProfiles?pageid="+pageid}>Personal Profiles </a> */}
                                  <Link to={"/personalProfiles?pageid="+pageid} >Personal Profiles</Link>
                                  </li>
                                <li class="breadcrumb-item headtitle "><a href="#">{jobSeekerDetails.firstName} {jobSeekerDetails.lastName}</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">
            <div class="container">
            <div className="row pb-3">
                    <div className="d-flex">
                        <span className="mr-1 rotate-90">
                            <img src="images/mob-arrow.png" alt="" />
                        </span>
                        {(() => {
                        return (<><Link to={`/personalProfiles?pageid=`+pageid} style={{ color: "#fff" }}>Back to Profiles List </Link></>);
                      
                    })()}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-4">
                            <div class="grid-item p-3 border-0">
                                <div class="grid-head align-items-center">
                                    <div class="grid-img">
                                        <img src={jobSeekerDetails.profilePicS3URL?jobSeekerDetails.profilePicS3URL:"images/user-default.jpg"} class="w-100" alt=""/>
                                    </div>
                                    <div class="grid-title-wrap">
                                        <h4 class="margin-bottom-0">{jobSeekerDetails.firstName} {jobSeekerDetails.lastName}</h4>
                                        <small class="d-flex">
                                                <span class="mr-1"><b>Seeking: </b></span>
                                                <span>{jobSeekerDetails.jobTypeName == 'Internship/Coop' ? 'College Intern' : jobSeekerDetails.jobTypeName}</span>
                                            </small>
                                    </div>
                                </div>

                                <div class="grid-body mb-4">
                                    <div class="grid-body-item">
                                        <div class="grid-body-inn">
                                            <span>Updated:</span>
                                            <span>{moment(jobSeekerDetails.updatedDate).utcOffset('-0400').format("MM/DD/YYYY")}</span>
                                        </div>
                                        <div class="grid-body-inn">
                                            <span>Email:</span>
                                            <span>{jobSeekerDetails.email}</span>
                                        </div>
                                        <div class="grid-body-inn">
                                            <span>Phone:</span>
                                            <span>{jobSeekerDetails.phone}</span>
                                        </div>
                                        <div class="grid-body-inn">
                                            <span>Category:</span>
                                            <span>{strCatArr(jobSeekerDetails.categoriesOfExpertiseName)}</span>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div class="view-item ppv mb-4">
                                    <h5>Personal Profile:</h5>
                                    {/* <p>{jobSeekerDetails.personalProfile}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: jobSeekerDetails.personalProfile  }} />
                                </div>

                                <div class="view-item ppv mb-4">
                                    <h5>Education:</h5>
                                    {/* <p>{jobSeekerDetails.educationExperiance}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: jobSeekerDetails.educationExperiance  }} />
                                </div>

                                <div class="view-item ppv mb-4">
                                    <h5>Certifications and Training:</h5>
                                    {/* <p>{jobSeekerDetails.certificationTraining}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: jobSeekerDetails.certificationTraining  }} />
                                </div>

                                <div class="view-item ppv mb-4">
                                    <h5>I Am Looking For:</h5>
                                    {/* <p>{jobSeekerDetails.lookingFor}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: jobSeekerDetails.lookingFor  }} />
                                </div>

                                <div class="view-item ppv mb-4" id="resumeDiv">
                                    <h5>Resume:</h5>
                                    <p><a target="_blank" href={jobSeekerDetails.resumePath} download="resume"><button type="button" class="reg-button">Download</button></a></p>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
                </div>
        </section>



    </main>
  </>
)};

PersonalProfileView.propTypes = {};

PersonalProfileView.defaultProps = {};

export default PersonalProfileView;
