import React,{history,useEffect, useState, useMemo } from "react";
import Hiring from "../Jobs/Hiring";
import services from "../../services/services";
import Recent_Jobs from "../Jobs/Recent_Jobs";
import Pagination from "../pagination/pagination";
import Stay_Connected from "../home/Stay_Connected";

class LocallifeSchool extends React.Component
{
   constructor() 
   {
      super();

       // an example array of items to be paged
       var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));

       this.state = {
           exampleItems: exampleItems,
           pageOfItems: []
       };

       // bind function in constructor instead of render (https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md)
       this.onChangePage = this.onChangePage.bind(this);
   }
   onChangePage(pageOfItems) {
      // update state with new page of items
      this.setState({ pageOfItems: pageOfItems });
  }

render() 
{
  return(
  <>
<main  class="empr-directory-main">
         <div class="main-bgpatten"></div>
         {/* <!-- info typography --> */}
         <section class="headtitle-wrap">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                     <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item headtitle "><a href="#">Local life  <span style={{color: "#6c757d"}}> &#62;</span></a></li>
                           <li class="breadcrumb-item headtitle "><a href="#">Schools</a></li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- info typography --> */}
         <section class="info-typography py-5">
            <div class="container">
               <div class="row">
                  <div class="col-lg-8">
                     <div class="py-5 px-5 white-bg" style={{paddingBottom:"20px !important;"}}>
                        <div class="row">
                        {this.state.pageOfItems.map(item =>
                           <div class="col-lg-6 mb-5"  key={item.id}>
                              {/* {item.name} */}
                             <a href="/companyDetails"><div class="card p-3">
                                 <img src="images/Arcanum-School-Logo.png" style={{maxWidth: "200px"}} class="p-4 mb-2 m-auto" alt=""/>
                                
                                 <div class="text-center stock-wrap">
                                    <h3>Arcanum-Butler Local Schools</h3>
                                 <span>Versailles, Ohio</span>
                               
                                 </div>
                              </div></a> 
                           </div>

                    )}
                           
                        </div>
                        <div class="pagination-wrap text-center mt-3">
                        <Pagination items={this.state.exampleItems} onChangePage={this.onChangePage} />
                           {/* <nav aria-label="...">
                              <ul class="pagination m-auto d-inline-flex">
                                 <li class="page-item disabled">
                                    <span class="page-link"><img src="images/arrow-left.JPG" alt=""/></span>
                                 </li>
                                 <li class="page-item"><a class="page-link" href="#">1</a></li>
                                 <li class="page-item active" aria-current="page">
                                    <span class="page-link">2</span>
                                 </li>
                                 <li class="page-item"><a class="page-link" href="#">3</a></li>
                                 <li class="page-item">
                                    <a class="page-link" href="#"><img src="images/arrow-right.JPG" alt=""/></a>
                                 </li>
                              </ul>
                           </nav> */}
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <Recent_Jobs/>
                     {/* <div class="white-bg mb-4">
                        <img src="images/ad-1.png" class="w-100" alt=""/>
                     </div>
                     <div class="white-bg mb-4">
                        <img src="images/ad-2.png" class="w-100" alt=""/>
                     </div> */}
                     {/*<div class="p-4 white-bg mb-4">
                        <h2 class="title-stay-connect">Stay connected. Get the app!</h2>
                        <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                        <img src="images/HTO-app-face.png" class="w-100" alt="" />

                        <div class="d-flex justify-content-center  mt-3">
                           <img src="images/apple-app-store.png" alt="" style={{"width":" 130px;"}} />
                           <img src="images/google-play.png" alt="" class="ms-2" style={{"max-width":"130px;"}} />
                        </div>
                     </div>*/}
                  </div>
               </div>
            </div>
         </section>
         
         {/* <!-- Top companies hiring in your area --> */}
         <Hiring/>
      </main>
  </>
)}}

LocallifeSchool.propTypes = {};

LocallifeSchool.defaultProps = {};

export default LocallifeSchool;
