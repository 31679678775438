import React,{history,useEffect, useState, useMemo } from "react";

const CareerView = () => {
    useEffect(() => 
    {
       const jsonData = {
          "careerView": {
            
          }
        };

      var jsondataformatted = JSON.stringify(jsonData);
      sessionStorage.setItem('pagejsonObject', jsondataformatted);
    },[]);  
  return(
  <>
<main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>'"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="#">Career</a></li>
                                <li class="breadcrumb-item headtitle "><a href="#">Transportation</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-4">
                            <div class="grid-item p-3 border-0">
                                <div class="grid-head-career">
                                    <div class="grid-img-company">
                                        <img src="images/career-logo.JPG" class="w-100" alt=""/>
                                    </div>
                                    <div class="grid-title-wrap">
                                        <h4 class="margin-bottom-0">Transportation Aide</h4>
                                        <small class="d-flex">
                              <span class="mr-2">By:</span>
                              <span><a href="#">Green Hills Community</a></span>
                              </small>
                                    </div>
                                </div>

                                <div class="grid-body mb-4">
                                    <div class="grid-body-item">
                                        <div class="grid-body-inn">
                                            <span>Posted Title:</span>
                                            <span>Transportation Aide</span>
                                        </div>
                                        <div class="grid-body-inn">
                                            <span>Posted:</span>
                                            <span>December 14, 2021</span>
                                        </div>
                                        <div class="grid-body-inn">
                                            <span>Company:</span>
                                            <span>Green Hills Community</span>
                                        </div>
                                        <div class="grid-body-inn">
                                            <span>Website:</span>
                                            <span><a href="#" class="red-text text-underline">www.abcd.com</a></span>
                                        </div>

                                        <div class="grid-body-inn">
                                            <span>Country:</span>
                                            <span>Logan</span>
                                        </div>

                                        <div class="grid-body-inn">
                                            <span>Industry:</span>
                                            <span>Non-Profit,Healthcare/Medical</span>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div class="view-item mb-4">
                                    <h5>SUMMARY:</h5>
                                    <p>POSITION SUMMARY</p>
                                    <p>The Transportation Aide shall be responsible for transporting residents/clients to and from various destinations, including but not limited to medical appointments, activities, parades, and home. He/she must interact
                                        with the residents/clients, family members, and the public in a dignified, respectful manner. The Transportation Aide is responsible for scheduling appointments, scheduling maintenance and repairs for the vehicles,
                                        scheduling required inspections and maintaining the vehicles in a clean, neat manner. <br/> <br/>EOE <br/>
                                        <small> <b>Job Type: Part Time</b></small>
                                    </p>
                                </div>

                                <div class="view-item mb-4">
                                    <h5>Experience/Certification Required:</h5>
                                    <p>REQUIREMENTS: <br/><br/>
                                        <ul class="list-unstyled">
                                            <li>High School Education or its equivalent.</li>
                                            <li>Current, valid Ohio Driver’s License with a clean driving record for at least 2 years with 2+ years driving experience.</li>
                                            <li>Have relevant mechanical knowledge.</li>
                                            <li>Able to learn basic van maintenance.</li>
                                            <li>Able to keep and organize transportation records.</li>
                                            <li>Dedicated to a holistic approach to caring for and interacting with clients/residents/children.</li>
                                            <li>STNA certification is preferred. If not currently an STNA, may be required to become certified within a designated timeframe.</li>
                                        </ul>

                                    </p>
                                </div>

                                <div class="grid-body-item">
                                    <div class="grid-body-inn">
                                        <span>Education:</span>
                                        <span>Transportation Aide</span>
                                    </div>
                                    <div class="grid-body-inn">
                                        <span>Career Level:</span>
                                        <span>Entry Level</span>
                                    </div>
                                    <div class="grid-body-inn">
                                        <span>How to Apply:</span>
                                        <span><a href="#" class="red-text text-underline">https://recruiting.myapps.paychex.com/appone/MainInfoReq.asp?R_ID=4218501</a></span>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    </main>
  </>
)};

CareerView.propTypes = {};

CareerView.defaultProps = {};

export default CareerView;
