//import React,{history,useEffect, useState, useMemo } from "react";
import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { set, useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Services from '../../services/services';
import UsNumberValidate from '../../utility/usNumberValidate';
import services from "../../services/services";
import moment from "moment";
//import { useNavigate,useParams } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Modal from 'react-modal';
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup';

const StudentProfileView = () => {

    const [jobTypes, setJobTypes] = useState([]);
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [jobType, setJobType] = useState([]);
    // const{jobSeekerId}=
    const [jobSeekerDetails, setJobSeekerDetails] = useState([]);
    //const jobSeekerId = 11;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const jobSeekerId = queryParams.get('jobSeekerId');
    const studentId = queryParams.get('studentId');
    const stpage = queryParams.get('pageid');
    const adpage = queryParams.get('adpage');
    const [fileName, setFileName] = useState([]);
    const [fileExtension, setFileExtension] = useState([]);
    const [fileBase64, setFileBase64] = useState([]);
    const [categories, setCategory] = useState([]);
    const chKRoleAr=sessionStorage.getItem("roleAr");
    const [educationName, setEducationName] = useState('');

    const [pdfDownload, setPdfDownload] = useState([]);
    const navigate = useNavigate();
    const [itemName, setFieldName] = useState('');
    
  const [pwd, setPwd] = useState('');
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealConfPwd, setIsRevealConfPwd] = useState(false);
  const [showPwdImg, setShowPwdImg] = useState('/images/hide-password.svg');
  const [hidePwdImg, setHidePwdImg] = useState('/images/show-password.svg');
  const [isRevealPwdMb, setIsRevealPwdMb] = useState(false);
  const [isRevealConfPwdMb, setIsRevealConfPwdMb] = useState(false);
  const [studentuserid, setStudentUserid] = useState('');
  const [studentFirstName, setStudentFirstName] = useState('');
  const [studentLastName, setStudentLastName] = useState('');
  const [studentEmail, setStudentEmail] = useState('');
  const [studentPhone, setStudentPhone] = useState('');

  if( chKRoleAr == "schooladmin" ){   
    var viewpageid = adpage;
  }
  else{
    var viewpageid = stpage;
  }

    const customStyles = {
      content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          width: '100%',
          maxWidth: '768px',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '0px',
          border: '0px',
          textAlign: 'center',
          zIndex: 9
      },
  };
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
      setIsOpen(true);
      $('#password').val('');
      $('#passwordConf').val('');
      $("#matchPwd").hide();
      $("#successMessage_pwd").hide();
      $("#pwdvalidcheck").hide();
  }

  function afterOpenModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
      $("#matchPwd").hide();
      $("#successMessage_pwd").hide();
      $("#pwdvalidcheck").hide();
  }

  function closeModal() {
      
      setIsOpen(false);
  }


    function strCatArr(arr){
      if(Array.isArray(arr)){
        var arr2 = [];
        console.log(JSON.stringify(categories));
        for(var k=0; k<arr.length; k++){
          //console.log(categories[arr[k]].name);
          try{

            for (var i = 0; i < categories.length; i ++) {
              if (categories[i]['catId'] == arr[k]) {
                var temp = categories[i].name;
                console.log(temp+"-->"+k);
                arr2.push(temp);
              }
          }

            /*if(typeof(categories[arr[k]].name) != "undefined"){
              var temp = categories[arr[k]].name;
              console.log(temp+"-->"+k);
              arr2.push(temp);
            } */
          }catch(ex){

          }
          
         
        }
        console.log("42");
        console.log(JSON.stringify(arr2));
        //return arr.join(arr,", ");
        //return arr.join(", ");
        return arr2.join(", ");
      }else{
        return "";
      }
    }

    function streducationArr(eid){
      Services.getEducation().then((res) => {
        console.log("getEducation data=" + JSON.stringify(res));
        var tempedu = res.data.content;
        //console.log(tempjobtypes);
        var ename = '';
        tempedu = tempedu.filter(function(elem) {  
          if(elem.educationId == eid){
            ename = elem.name;
          } 
        });
        setEducationName(ename);
      });
    }
   
    function strseekingArr(arr_seeking){
      if(Array.isArray(arr_seeking)){
        var arr3 = [];
        
        for(var s=0; s<arr_seeking.length; s++){
          console.log(arr_seeking[s]+'----'+s);
          try{

            for (var i = 0; i < jobTypes.length; i ++) {
              console.log(jobTypes[i]['jobTypeId']+'---'+arr_seeking[s]);
              if (jobTypes[i]['jobTypeId'] == arr_seeking[s]) {
                var temp_seeking = jobTypes[i].name;
                console.log(temp_seeking+"-->"+s);
                arr3.push(temp_seeking);
              }
          }

           /* if(typeof(jobTypes[arr_seeking[s]].name) != "undefined"){
              var temp_seeking = jobTypes[arr_seeking[s]].name;
              console.log(temp_seeking+"-->"+s);
              arr3.push(temp_seeking);
            }  */
          }catch(ex){

          }
          
         
        }
        console.log("42");
        console.log(JSON.stringify(arr3));
        //return arr.join(arr,", ");
        //return arr.join(", ");
        return arr3.join(", ");
      }else{
        return "";
      }
    }
  
    function getImage(){
        var files = document.getElementById('resume').files;  
        if (files.length > 0) {
           
          var fname = files[0].name;
          // var fextn = fname.substr(0, fname.lastIndexOf('.'));
          var fextn = fname.split(".").pop();
          // alert(fextn);
          var fileActName = fname.split(".")[0];
          // alert(fileActName);
          setFileName(fileActName);
          setFileExtension(fextn);
          getBase64(files[0]);
    
          // if(fextn != "pdf"){
          if(fextn != "doc" && fextn != "docx" && fextn != "pdf" ){
            
            $("#fileError").show();
                return false;
            }else{
                $("#fileError").hide();
                return true;
            }
    
        }
      }
    
      function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          // console.log(reader.result);
          var base64result = reader.result.split(',')[1];
          setFileBase64(base64result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }

     const getStudentProfile = (studentId) => {
      services.getStudentProfile(studentId).then((res) => {
          setJobSeekerDetails(res.data);
          $("#firstName").val(res.data.firstName);
          $("#lastName").val(res.data.lastName);
          $("#email").val(res.data.email);
          $("#phone").val(res.data.phone);
          $("#personalProfile").val(res.data.personalProfile);
          $("#education").val(res.data.educationExperiance);
          $("#lookingFor").val(res.data.lookingFor);
          $("#jobType").val(res.data.jobType);
          $("#resume").val(res.data.pdfByString);
          if(res.data.resumeurl == "" || res.data.resumeurl == null){
            $(".ResumeShow").hide();
          }
          streducationArr(res.data.educationId);
          setPdfDownload(res.data.resumePath);
          setJobType(res.data.jobType);
          setStudentUserid(res.data.studentUserId);
          setStudentFirstName(res.data.firstName);
          setStudentLastName(res.data.lastName);
          setStudentEmail(res.data.email);
          setStudentPhone(res.data.phone);
          console.log("jobseeker@@@@@@@@"+JSON.stringify(res.data));

          setCategoriesSelected(res.data.categoriesOfExpertise);
          var companyCatIn = res.data.categoriesOfExpertise;

          $('#jobType').prop('selectedIndex', res.data.jobType);  
    
    
          setTimeout(() => {
            for (var i = 0; i < companyCatIn.length; i++) {
              //$("input[value='" + val + "']").prop('checked', true);
              console.log("###" + companyCatIn[i]);
              //$('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
              $('input[name="category"][value="' + companyCatIn[i] + '"]').trigger("click");
            }
            validateCheck();
    
            var isCheck = true;
    
            var checkedValues = $("input[name='category']").val();
            // var checkedValues = [];
            //     $.each($("input[name='category']:checked"), function () {
            //       checkedValues.push($(this).val());
            //     });
            // alert(checkedValues);
            if (!checkedValues) {
              isCheck = false;
              $("#categoryError").html("Category field is required");
            } else {
              // alert(checkedValues);
              isCheck = true;
              $("#categoryError").html("");
            }
    
            return isCheck;
    
            setTimeout(() => {
              console.log("141");
              $('input[name="category"]').trigger("change");
              $('input[name="state"]').trigger("change");
            }, 3000);
    
          }, 1000);
    

      });
   }
     const getProfile = (jobSeekerId) => {
        services.getProfile(jobSeekerId).then((res) => {
            setJobSeekerDetails(res.data);
            $("#firstName").val(res.data.firstName);
            $("#lastName").val(res.data.lastName);
            $("#email").val(res.data.email);
            $("#phone").val(res.data.phone);
            $("#personalProfile").val(res.data.personalProfile);
            $("#education").val(res.data.educationExperiance);
            $("#lookingFor").val(res.data.lookingFor);
            $("#jobType").val(res.data.jobType);
            $("#resume").val(res.data.pdfByString);
            setPdfDownload(res.data.resumePath);
            setJobType(res.data.jobType);
            console.log("jobseeker@@@@@@@@"+JSON.stringify(res.data));

            setCategoriesSelected(res.data.categoriesOfExpertise);
            var companyCatIn = res.data.categoriesOfExpertise;

            $('#jobType').prop('selectedIndex', res.data.jobType);  
      
      
            setTimeout(() => {
              for (var i = 0; i < companyCatIn.length; i++) {
                //$("input[value='" + val + "']").prop('checked', true);
                console.log("###" + companyCatIn[i]);
                //$('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
                $('input[name="category"][value="' + companyCatIn[i] + '"]').trigger("click");
              }
              validateCheck();
      
              var isCheck = true;
      
              var checkedValues = $("input[name='category']").val();
              // var checkedValues = [];
              //     $.each($("input[name='category']:checked"), function () {
              //       checkedValues.push($(this).val());
              //     });
              // alert(checkedValues);
              if (!checkedValues) {
                isCheck = false;
                $("#categoryError").html("Category field is required");
              } else {
                // alert(checkedValues);
                isCheck = true;
                $("#categoryError").html("");
              }
      
              return isCheck;
      
              setTimeout(() => {
                console.log("141");
                $('input[name="category"]').trigger("change");
                $('input[name="state"]').trigger("change");
              }, 3000);
      
            }, 1000);
      

        });
     }


 
  

    const {
        register,
        formState: { errors },
        handleSubmit
      } = useForm({
        criteriaMode: "all", mode: "onBlur"
      });
    

    useEffect(() => {
      // const jsonData = {
      //   "studentProfileView": {
          
      //   }
      // };
      // var jsondataformatted = JSON.stringify(jsonData);
      // sessionStorage.setItem('pagejsonObject', jsondataformatted);

      $("#successMessage").hide();
        $('#phone').usPhoneFormat();
        
      getIndustryData();
      
      $('#passwordValidMsg').hide();
    $('#confpasswordValidMsg').hide();
      
      //getProfile(jobSeekerId);
      getStudentProfile(studentId);
      $("#fileError").hide();
      
      Services.getJobType().then((res) => {
        console.log("setJobType data=" + JSON.stringify(res.data));
        setJobTypes(res.data.content);
      });

      $( "#update_pwd" ).click(function() {            
        openModal()
    });

    }, []);

  
    const formSchema = Yup.object().shape({
      password: Yup.string()
        .required('Password is required')
        .min(4, 'Password length should be at least 4 characters'),
      passwordConf: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password')], 'Passwords must and should match'),
    })
  
    const validationOpt = { resolver: yupResolver(formSchema) }

    const getIndustryData = () => {
  
      Services.getIndustryData().then((res) => {
  
        console.log("Industry data=" + JSON.stringify(res.data));
  
        setCategory(res.data.content);
  
      });
  
    }


    function validateCategory() {
      // alert("@@category");
      var isCheck = true;
  
      var checkedValues = $("input[name='category']:checked");
      // var checkedValues = [];
      //     $.each($("input[name='category']:checked"), function () {
      //       checkedValues.push($(this).val());
      //     });
      //  alert(checkedValues);
      // alert(checkedValues.length);
      var lenghtCheck=checkedValues.length;
      if (lenghtCheck==0) {
        isCheck = false;
        $("#categoryError").html("Category field is required");
      } else {
        // alert(checkedValues);
        isCheck = true;
        $("#categoryError").html("");
      }
  
      return isCheck;
    }

   const validateCheck = () =>{
        const checked=$('input[name="category"]:checked').length;
    
        if (checked >= 3) {
          $('input[name="category"]').not(':checked').prop('disabled', true);
        } else {
          $('input[name="category"]').prop('disabled', false);
        }
      }

     const changepwd = () =>{
      var passwordConf = $("#passwordConf").val();
      var passwordCheck = $("#password").val();

      if(passwordCheck == ''){
        $("#pwdvalidcheck").show();
        return false;
      }
      else{
        $('#pwdvalidcheck').hide();
      }
      
      if (passwordConf != passwordCheck) {
        $("#matchPwd").show();
        $("#passwordConf").focus();
        return false;
      } else {
        $("#matchPwd").hide();
      }
      
      const requestObject = {
        "companyId":"null",
        "siteId":process.env.React_App_SITE_ID,
        "userId":studentuserid,
        "firstName":studentFirstName,
        "lastName":studentLastName,
        "email":studentEmail,
        "phone":studentPhone,
        "password":passwordConf
      }
      var accessTokenIn = sessionStorage.getItem("accToken");
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "Bearer "+accessTokenIn
          //'Authorization': accessTokenIn
        },
        body: JSON.stringify(requestObject)
      };
      console.log(JSON.stringify(requestObject));
      
      Services.updateUserProfile(requestOptions).then((res) => {
        //console.log(res); return false;
        if (res.success) {
          $("#successMessage_pwd").fadeTo(2000, 1000).slideUp(1000, function () {
            $("#successMessage_pwd").slideUp(1000);
            closeModal();
          });
        }
  
      });

     }

  return(
  <>
    <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                
                            

                                {(() => {
                                  if( chKRoleAr == "companyadmin" ||  chKRoleAr == "companyuser" || chKRoleAr == "company"){                    
                                    return (<> <li class="breadcrumb-item headtitle "><Link to={`/personalStudents?pageid=`+stpage}>Student List </Link></li></>);
                                }


                                  if( chKRoleAr == "schooladmin" ){                    
                                      return (<> <li class="breadcrumb-item headtitle "><Link to={`/studentListBySchool?adpage=`+adpage}>Student List </Link></li></>);
                                  }

                                  if( chKRoleAr == "schoolsuper" ){                    
                                    return (<>  <li class="breadcrumb-item headtitle "><Link to={`/studentList?pageid=`+stpage}>Student List </Link></li></>);
                                }
                                })()}   

                                <li class="breadcrumb-item headtitle "><a href="#">{jobSeekerDetails.firstName} {jobSeekerDetails.lastName}</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">
            <div class="container">
                <div className="row pb-3">
                    <div className="d-flex">
                        <span className="mr-1 rotate-90">
                            <img src="images/mob-arrow.png" alt="" />
                        </span>
                            {(() => {
                                  if( chKRoleAr == "companyadmin" ||  chKRoleAr == "companyuser" || chKRoleAr == "company"){                    
                                    return (<><Link to={`/personalStudents?pageid=`+stpage} style={{ color: "#fff" }}>Back to Students List </Link></>);
                                }
                                  if( chKRoleAr == "schooladmin" ){                    
                                      return (<><Link to={`/studentListBySchool?adpage=`+adpage} style={{ color: "#fff" }}>Back to Students List </Link></>);
                                  }
                                  if( chKRoleAr == "schoolsuper" ){                    
                                    return (<><Link to={`/studentList?pageid=`+stpage} style={{ color: "#fff" }}>Back to Students List </Link></>);
                                }
                            })()} 

                    </div>
                </div>


                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-4" style={{position:"relative"}}>
                            <div class="grid-item p-3 border-0">
                                <div class="grid-head align-items-center">
                                    <div class="grid-img">
                                        <img src={jobSeekerDetails.profileS3Pic?jobSeekerDetails.profileS3Pic:"images/user-default.jpg"} class="w-100" alt=""/>
                                    </div>
                                    <div class="grid-title-wrap">
                                        <h4 class="margin-bottom-0">{jobSeekerDetails.firstName} {jobSeekerDetails.lastName}</h4>
                                        {/* <small class="d-flex">
                                                <span class="mr-1"><b>Seeking:</b></span>
                                                <span>{jobSeekerDetails.jobTypeName}</span>
                                        </small> */}
                                    </div>
                                   
                                </div>
                                <div class="spv-edit-update">
                                {(() => {
                                  if( chKRoleAr == "schooladmin" || chKRoleAr == "schoolsuper"){      
                                    return (<>   <Link to={`/editStudent?studentId=`+jobSeekerDetails.studentId+`&pageid=`+viewpageid+`&pageref=v`} class="reg-button text-center">Edit</Link>
                                        <br></br>
                                        <button  id="update_pwd" class="reg-button text-center mx-2">Update Password</button></>);
                                  }
                                })()}   
                                    </div>

                                <div class="grid-body mb-4">
                                    <div class="grid-body-item">
                                      <div class="grid-body-inn">
                                            <span>Created On:</span>
                                            <span>{(jobSeekerDetails.dateCreated == 'null' || jobSeekerDetails.dateCreated == null) ? '--' : moment(jobSeekerDetails.dateCreated).format("MM/DD/YYYY")}</span>
                                        </div>
                                        <div class="grid-body-inn">
                                            <span>Updated On:</span>
                                            <span>{moment(jobSeekerDetails.dateUpdated).format("MM/DD/YYYY")}</span>
                                        </div>
                                        
                                        <div class="grid-body-inn">
                                            <span>Personal Email:</span>
                                            <span>{jobSeekerDetails.email}</span>
                                        </div>
                                        <div class="grid-body-inn">
                                            <span>Phone:</span>
                                            <span>{jobSeekerDetails.phone}</span>
                                        </div>
                                        <div class="grid-body-inn">
                                            <span>Category:</span>
                                            <span>{strCatArr(jobSeekerDetails.categoriesOfExpertise)}</span>
                                        </div>
                                        <div class="grid-body-inn">
                                            <span>Seeking:</span>
                                            <span>{strseekingArr(jobSeekerDetails.seekingEmploymentTypes)}</span>
                                        </div>
                                        <div class="grid-body-inn">
                                            <span>Pursuing:</span>
                                            <span>{educationName}</span>
                                        </div>
                                        {jobSeekerDetails.educationId == 3 ? 
                                          <div class="grid-body-inn">
                                              <span>School:</span>
                                              <span>{jobSeekerDetails.schoolId == 33 ?  jobSeekerDetails.schoolDistrictOther : jobSeekerDetails.schoolName}</span>
                                          </div>
                                        : 
                                        <div class="grid-body-inn">
                                            <span>College / University:</span>
                                            <span>{jobSeekerDetails.nameOfCollegeUniversityId == 22 ?  jobSeekerDetails.nameOfCollegeUniversity : jobSeekerDetails.nameOfCollegeOrUniversity}</span>
                                        </div>
                                        }
                                        
                                        
                                        <div class="grid-body-inn">
                                            <span>Graduating Year:</span>
                                            <span>{jobSeekerDetails.classYear}</span>
                                        </div>
                                        
                                    </div>
                                </div>
                                <hr/>
                                <div class="view-item mb-4">
                                    <h5>Reason for select career:</h5>
                                    {/* <p>{jobSeekerDetails.personalProfile}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: jobSeekerDetails.reasonForSelectCareers  }} />
                                </div>

                                {/* <div class="view-item mb-4">
                                    <h5>Courses:</h5>
                                    <p dangerouslySetInnerHTML={{ __html: jobSeekerDetails.course  }} />
                                </div> */}

                                <div class="view-item mb-4">
                                    <h5>Skills/Experience:</h5>
                                    {/* <p>{jobSeekerDetails.certificationTraining}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: jobSeekerDetails.experiance  }} />
                                </div>

                                <div class="view-item mb-4">
                                    <h5>Additional Thoughts/Comments:</h5>
                                   
                                    <p dangerouslySetInnerHTML={{ __html: jobSeekerDetails.comments  }} />
                                </div>

                                

                                <div id="showResume" class="view-item mb-4">
                                  <br></br>                                  
                                  {(jobSeekerDetails.resumeUrl != '' && jobSeekerDetails.resumeUrl != null) ? <a href={jobSeekerDetails.resumeUrl} download="resume"><button type="button" class="reg-button">Download Resume</button></a>  : ''}
                                </div>

                                
                                
                                {(() => {
                                  if( jobSeekerDetails.resumeUrl != "" ){                    
                                      return (<><div class="view-item mb-4 ResumeShow">
                                      <h5>Resume:</h5>
                                      <p><a href={jobSeekerDetails.resumeUrl} download="resume"><button type="button" class="reg-button">Download</button></a></p>
                                  </div></>);
                                  }

                                  
                                })()}   
                                

                                
                            </div>
                        </div>

                    </div>
                </div>
                </div>
        </section>



    </main>

    <div>
                {/* <button onClick={openModal} class="reg-button mb-5">WAGE DATA</button> */}
                <Modal
                    isOpen={modalIsOpen}
                    id={"companyJobsModal"}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="p-4">
                        <button onClick={closeModal} class="wage-close">close</button>
                        <div className="hd_wp">
                            {/*<h2 ref={(_subtitle) => (subtitle = _subtitle)}>UPDATE PASSWORD</h2>*/}
                        </div>
                        <div className="hd_content ">
                            
                            <div class="col-lg-12 mb-3">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Password <span class="red-text">*</span></label></div>
                        <p style={{ textAlign: "left" }}>Must be a minimum of 8 characters & MUST include all of the following: a lowercase letter, an uppercase letter, a number, and a special character</p>
                        <span class="position-relative d-block">
                          <input type={isRevealPwd ? "text" : "password"} class="form-control" id="password" onChange={e => setFieldName(e.target.value)} {...register("Password", {
                            required: "Password is required.",
                            pattern: {
                              value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                              message: "Password contain uppercase,lowercase and special character"
                            },
                            minLength: { value: 8, message: 'Too short' }
                          })} />
                          <img src={isRevealPwd ? hidePwdImg : showPwdImg} class="pwdShowHideClsPostResume"
                            onClick={() => setIsRevealPwd(prevState => !prevState)}
                            alt="" />
                        </span>
                        <ErrorMessage
                          errors={errors}
                          name="Password"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error" style={{textAlign:"left"}}>{message}</p>
                              ))
                              : null;
                          }}
                        />
                        <p id="pwdvalidcheck" class="error" style={{textAlign:"left"}}>Password is required</p>
                      </div>

                      <div class="col-lg-12 mb-3">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Confirm Password <span class="red-text">*</span></label>  </div>
                        <p style={{ textAlign: "left" }}>Must be a minimum of 8 characters & MUST include all of the following: a lowercase letter, an uppercase letter, a number, and a special character</p>
                        <span class="position-relative d-block">
                          <input type={isRevealConfPwd ? "text" : "password"} class="form-control" id="passwordConf" onChange={e => setFieldName(e.target.value)} {...register("PasswordConf", {
                            required: "Password is required.",
                            pattern: {
                              value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                              message: "Password contain uppercase,lowercase and special character"
                            },
                            minLength: { value: 8, message: 'Too short' }
                          })} />
                          <img src={isRevealConfPwd ? hidePwdImg : showPwdImg} class="pwdShowHideClsPostResume"
                            onClick={() => setIsRevealConfPwd(prevState => !prevState)}
                            alt="" />
                        </span>
                        <ErrorMessage
                          errors={errors}
                          name="PasswordConf"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error" style={{textAlign:"left"}}>{message}</p>
                              ))
                              : null;
                          }}
                        />
                        <p id="matchPwd" class="error" style={{textAlign:"left"}}>Passwords must match</p>

                      </div>

                        </div>
                        <div className="hd_footer" >
                            <button onClick={changepwd} class="btn btn-primary" style={{ width: 200}}>Update Password</button>
                        </div>

                        <div id="successMessage_pwd" class="alert">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <img src="/images/success-alert.png" alt="" />
                            <strong class="mx-2">Success!</strong> Password updated successfully.
                          </div>
                          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        </div>
                      </div>

                    </div>






                </Modal>
            </div>
  </>
)};

StudentProfileView.propTypes = {};

StudentProfileView.defaultProps = {};

export default StudentProfileView;
