import React, { history,useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import Services from "../../services/services";
//import PaginationComponent from "../../utility/Pagination/Pagination";
import Pagination from "../../utility/Pagination/Pagination";
import { useNavigate,useParams, Link, useLocation } from "react-router-dom";
const PersonalProfiles = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const [categories, setCategory] = useState([]);
    const [profileList, setProfileList] = useState([]);
    const [profileList_m, setProfileList_m] = useState([]);
    const [educationsList, setEducations] = useState([]);

    const [jobTypes, setJobType] = useState([]);
    const [itemName, setFieldName] = useState('');
    const ITEMS_PER_PAGE = 10; // Per page show the data pagination
    const Pagesize=5;
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const [totalItems_m, setTotalItems_m] = useState(0);
    const [totalpages_m, settotalPages_m] = useState();
    const [currentPage_m, setCurrentPage_m] = useState(1);

    const [profileListFull, setProfileListFull] = useState([]);

    const companyId=sessionStorage.getItem("logCompanyId");
    var pppagenum = queryParams.get('pageid');
    var FileSaver = require('file-saver');

    var session_pageobj = '';
    var webfilters = 1;
    var mobfilters = 1;
    function strCatArr(arr){
        //console.log(arr);
        if(Array.isArray(arr)){
            arr = arr.join(", ");
            //console.log(arr);
          return arr;
        }else{
          return "";
        }
      }

      const cardsloop = [1,2,3,4,5,6,7,8,9,10];

    useEffect(() => {
        $('.doublecards').show();
        if(companyId == null || companyId == "null"){
            navigate('/Home');
        }
        var recreateobj='';
        // Getting the keys of the JSON object
        var keys = sessionStorage.getItem('pagejsonObject');
        if(keys == null || keys == undefined){
            navigate('/Home');
        } else {
        var keys_parsed = JSON.parse(keys);
        var pageobject = Object.keys(keys_parsed);
        console.log(pageobject);
        // Checking if there is at least one key
        if (pageobject.length > 0) {
            // Accessing the first key
            var firstKey = pageobject[0];
            if(firstKey == 'personal_Profiles'){
                var pageobj = keys_parsed[firstKey];
                session_pageobj = keys_parsed[firstKey];
                var dev_poff = pageobj['pagination_val'];
                var dev_poff_m = pageobj['pagination_val_m'];
                if(dev_poff == ''){
                    if(dev_poff_m == ''){
                        pppagenum = '1';
                    }
                    else {
                        pppagenum = dev_poff_m; 
                    } 
                }
                else{
                    pppagenum = dev_poff; 
                }
            } else {
                pppagenum = '1';
                recreateobj = 1;
                sessionStorage.removeItem('master_industry');
                sessionStorage.removeItem('master_jobtype');
                sessionStorage.removeItem('company_profile');
            }
        } else {
            //json empty
            pppagenum = '1';
            recreateobj = 1;
            sessionStorage.removeItem('master_industry');
            sessionStorage.removeItem('master_jobtype');
            sessionStorage.removeItem('company_profile');
        }

        }

        console.log('104 pppagenum -- '+pppagenum);

        if(pppagenum == null || pppagenum == ''){
            var web_searchtxt = session_pageobj['search_text'];
            var web_resumeage = session_pageobj['resumeage'];
            var web_industry = session_pageobj['industry'];
            var web_jobtype = session_pageobj['jobtype'];
            var web_education = session_pageobj['educationLevel'];
            if(web_searchtxt == '' && web_resumeage == '' && web_industry == '' && web_jobtype == '' && web_education == ''){
                var webfilters = 0;
            }

            var mob_searchtxt = session_pageobj['search_text_m'];
            var mob_resumeage = session_pageobj['resumeage_m'];
            var mob_industry = session_pageobj['industry_m'];
            var mob_jobtype = session_pageobj['jobtype_m'];
            var mob_education = session_pageobj['educationLevel_m'];
            if(mob_searchtxt == '' && mob_resumeage == '' && mob_industry == '' && mob_jobtype == '' && mob_education == ''){
                var mobfilters = 0;
            }

            console.log(webfilters+' - '+mobfilters);

            if(webfilters == 0 && mobfilters == 0){
                var jsonData = {
                    "personal_Profiles": {
                      "industry": '',
                      "resumeage": '',
                      "jobtype": '',
                      "search_text": "",
                      "pagination_val": '',
                      "educationLevel":'',
                      "industry_m": '',
                      "resumeage_m": '',
                      "jobtype_m": '',
                      "search_text_m": "",
                      "pagination_val_m": '',
                      "educationLevel_m": ''
                    }
                  };
    
                var jsondataformatted = JSON.stringify(jsonData);
                sessionStorage.setItem('pagejsonObject', jsondataformatted);
            }
            console.log(sessionStorage.getItem('pagejsonObject'));


       // if(recreateobj != ''){
            //alert('recreate');
            // localStorage.setItem('pp_industry_selected', '');
            // localStorage.setItem('pp_resumeage_selected', '');
            // localStorage.setItem('pp_jobtype_selected', '');
            // localStorage.setItem('pp_search_text', '');
            // localStorage.setItem('pp_industry_selected_m', '');
            // localStorage.setItem('pp_resumeage_selected_m', '');
            // localStorage.setItem('pp_jobtype_selected_m', '');
            // localStorage.setItem('pp_search_text_m', '');

            // sessionStorage.setItem('pp_industry_selected', '');
            // sessionStorage.setItem('pp_resumeage_selected', '');
            // sessionStorage.setItem('pp_jobtype_selected', '');
            // sessionStorage.setItem('pp_search_text', '');
            // sessionStorage.setItem('pp_industry_selected_m', '');
            // sessionStorage.setItem('pp_resumeage_selected_m', '');
            // sessionStorage.setItem('pp_jobtype_selected_m', '');
            // sessionStorage.setItem('pp_search_text_m', '');
            // sessionStorage.setItem('pp_page_offset', '');
            // sessionStorage.setItem('pp_page_offset_m', '');
        }

        
        $('#phone').usPhoneFormat();
        //getServiceProfileInfo();
        //getServiceIndustryData();
        //getServiceJobType();

        var companyprofileInfo = sessionStorage.getItem('company_profile');
            if(companyprofileInfo == null || companyprofileInfo == ''){
                getServiceProfileInfo();
            }
            else {
                getLocalProfileInfo();
            }

            var ind_master = sessionStorage.getItem('master_industry');
            if(ind_master == null || ind_master == ''){
                getServiceIndustryData();
            }
            else {
                getLocalIndustryData();
            }

            var jobtype_master = sessionStorage.getItem('master_jobtype');
            if(jobtype_master == null || jobtype_master == ''){
                getServiceJobType();
            }
            else {
                getLocalJobType();
            }

            console.log(session_pageobj['educationLevel']);
            var education_master = sessionStorage.getItem('master_educationsprof');
            if(education_master == null || education_master == ''){
                getServiceEdicationInfo();
            }
            else {
                getLocalEdicationInfo();
            }
            
            

                //var education_master = session_pageobj['educationLevel'];
                //$("#education").val(education_master);
                //$('select[name="educationselection"]').val(education_master);

        
        //var education_master_m = session_pageobj['educationLevel_m'];
        //$("#education_m").val(education_master_m);
       // $('select[name="educationselection_m"]').val(education_master_m);




        //var t_pp_search_text = localStorage.getItem('pp_search_text');
        //var t_pp_search_text = sessionStorage.getItem('pp_search_text');
        var t_pp_search_text = session_pageobj['search_text'];
        $(".searchKeyInput").val(t_pp_search_text);

        //var t_pp_search_text_m = localStorage.getItem('pp_search_text_m');
        // var t_pp_search_text_m = sessionStorage.getItem('pp_search_text_m');
        var t_pp_search_text_m = session_pageobj['search_text_m'];
        $(".searchKeyInput_m").val(t_pp_search_text_m);

        //var t_pp_resumeage = localStorage.getItem('pp_resumeage_selected');
        // var t_pp_resumeage = sessionStorage.getItem('pp_resumeage_selected');
        var t_pp_resumeage = session_pageobj['resumeage'];
        if(t_pp_resumeage == 1){
            $('input[name="archived_resumes"]').prop("checked", true);
        }


       // var t_pp_resumeage_m = localStorage.getItem('pp_resumeage_selected_m');
        // var t_pp_resumeage_m = sessionStorage.getItem('pp_resumeage_selected_m');
        var t_pp_resumeage_m = session_pageobj['resumeage_m'];
        if(t_pp_resumeage_m == 1){
            $('input[name="archived_resumes_m"]').prop("checked", true);
        }

        

        //loadSelectedindustries();
        //loadSelectedJobtypes();

        $( document ).on( "click", ".searchContent", function() {
            //sessionStorage.setItem('pp_page_offset', '1');
            filterContent(ITEMS_PER_PAGE,'1','1','0');
         });

         $( document ).on( "change", ".searchKeyInput", function() {
            //sessionStorage.setItem('pp_page_offset', '1');
            filterContent(ITEMS_PER_PAGE,'1','1','0');
         });

         $( document ).on( "click", ".searchContent_m", function() {
            //sessionStorage.setItem('pp_page_offset_m', '1');
            filterContent_m(ITEMS_PER_PAGE,'1','1','0');
         });

         $( document ).on( "change", ".searchKeyInput_m", function() {
            //sessionStorage.setItem('pp_page_offset_m', '1');
            filterContent_m(ITEMS_PER_PAGE,'1','1','0');
         });

         $(".paginationContent").hide();

         setTimeout(() => {
            
            if (pppagenum != null && pppagenum != "") {

                //var web_ind = localStorage.getItem('pp_industry_selected');
                //var web_age = localStorage.getItem('pp_resumeage_selected');
               // var web_typ = localStorage.getItem('pp_jobtype_selected');
                //var web_src = localStorage.getItem('pp_search_text');

                // var web_ind = sessionStorage.getItem('pp_industry_selected');
                // var web_age = sessionStorage.getItem('pp_resumeage_selected');
                // var web_typ = sessionStorage.getItem('pp_jobtype_selected');
                // var web_src = sessionStorage.getItem('pp_search_text');
                // var pageoffset = sessionStorage.getItem('pp_page_offset');

                var web_ind = session_pageobj['industry'];
                var web_age = session_pageobj['resumeage'];
                var web_typ = session_pageobj['jobtype'];
                var web_src = session_pageobj['search_text'];
                var web_edu = session_pageobj['educationLevel'];
                var pageoffset = session_pageobj['pagination_val'];

                if (web_ind != "" || web_age != '' || web_typ != '' || web_src != '' || web_edu != '' || pageoffset != ''){
                    setTimeout(() => {
                        //filterContent(ITEMS_PER_PAGE, pppagenum, '0','0');
                        console.log('249 -- web');
                        filterContent(ITEMS_PER_PAGE, pppagenum, '0','0');
                    }, 500);
                }
                else {

                    // var mob_ind = localStorage.getItem('pp_industry_selected_m');
                    // var mob_age = localStorage.getItem('pp_resumeage_selected_m');
                    // var mob_typ = localStorage.getItem('pp_jobtype_selected_m');
                    // var mob_src = localStorage.getItem('pp_search_text_m');

                    var mob_ind = session_pageobj['industry_m'];
                    var mob_age = session_pageobj['resumeage_m'];
                    var mob_typ = session_pageobj['jobtype_m'];
                    var mob_src = session_pageobj['search_text_m'];
                    var mob_edu = session_pageobj['educationLevel_m'];
                    var pageoffset_m = session_pageobj['pagination_val_m'];

                    if (mob_ind != "" || mob_age != '' || mob_typ != '' || mob_src != '' || mob_edu != '' || pageoffset_m != ''){
                        setTimeout(() => {
                            console.log('268 -- mob');
                            //filterContent(ITEMS_PER_PAGE, pppagenum, '0');
                            filterContent_m(ITEMS_PER_PAGE, pppagenum, '0','0');
                        }, 500);
                    }
                    else {

                        filterContent(ITEMS_PER_PAGE, pppagenum, '0','0');
                        filterContent_m(ITEMS_PER_PAGE, pppagenum, '0','0');
                    }
                }

            }else{
                filterContent(ITEMS_PER_PAGE, '1', '0','0');
                filterContent_m(ITEMS_PER_PAGE, '1', '0','0');
            } 

        }, 500);

    }, []);

    

    function getServiceProfileInfo() {

        //var companyId = "24";


        Services.getCompanyProfile(companyId).then((res) => {


          setCategoriesSelected(res.data.companyCategory);
          var companyCatIn = res.data.companyCategory;
          var serviceresponse = res.data.companyCategory;
          var storeval = JSON.stringify(serviceresponse);
          sessionStorage.setItem('company_profile', storeval);
          //navigate('/thankyou');
        });
      }

      function getLocalProfileInfo() {
        var localindustryval = sessionStorage.getItem('company_profile');
        localindustryval = JSON.parse(localindustryval);
        setCategoriesSelected(localindustryval);
    }

    const handlePageChange2 = (offset,triggerevent) => {
        //alert(offset);
        //sessionStorage.setItem('pp_page_offset', offset);
        filterContent(ITEMS_PER_PAGE,offset,'0','1');
        //last parameter for page top position scroll
      };

      const handlePageChange2_m = (offset,triggerevent) => {
        // alert(offset);
        //sessionStorage.setItem('pp_page_offset_m', offset);
        filterContent_m(ITEMS_PER_PAGE,offset,'0','1');
      };

    const filterContent = (ITEMS_PER_PAGE,pageNumber,setlcstorage,positiontop) => {
        var categoriesArray = [];
        console.log('web');
        $.each($("input[name='category']:checked"), function(){
            categoriesArray.push($(this).val());
        });

        var jobTypeArray = [];
        $.each($("input[name='jobtype']:checked"), function(){
            jobTypeArray.push($(this).val());
        });

        var showarchived = 0;
        if($("input[name='archived_resumes']").prop('checked')){
            showarchived = 1;
        }

        var educationId = $('select[name="educationselection"]').val();
        console.log(educationId);
        var searchkey = $('.searchKeyInput').val();
        if(setlcstorage == "1"){
            var industries_val2 = '';
            if(categoriesArray != ''){
                 //industries_val2 = JSON.parse(categoriesArray);
                 industries_val2 = categoriesArray;
            }

            var jobtype_val2 = '';
            if(jobTypeArray != ''){
                //jobtype_val2 = JSON.parse(jobTypeArray);
                jobtype_val2 = jobTypeArray;
            }

            // localStorage.setItem('pp_industry_selected', industries_val2);
            // localStorage.setItem('pp_resumeage_selected', showarchived);
            // localStorage.setItem('pp_jobtype_selected', jobtype_val2);
            // localStorage.setItem('pp_search_text', searchkey);

            // sessionStorage.setItem('pp_industry_selected', industries_val2);
            // sessionStorage.setItem('pp_resumeage_selected', showarchived);
            // sessionStorage.setItem('pp_jobtype_selected', jobtype_val2);
            // sessionStorage.setItem('pp_search_text', searchkey);

        }
        else {
            /*localStorage.setItem('av_search_text', '');
            localStorage.setItem('av_location_selected', '');
            localStorage.setItem('av_education_selected', '');
            localStorage.setItem('av_jobtype_selected', '');
            localStorage.setItem('av_industry_selected', ''); */
        }

        if(pageNumber > 1){
            var jsonData = {
                "personal_Profiles": {
                  "industry": categoriesArray,
                  "resumeage": showarchived,
                  "jobtype": jobTypeArray,
                  "search_text": searchkey,
                  "pagination_val": pageNumber,
                  "educationLevel" : educationId,
                  "industry_m": '',
                  "resumeage_m": '',
                  "jobtype_m": '',
                  "search_text_m": "",
                  "pagination_val_m": '',
                  "educationLevel_m" : ''
                }
              };
        }
        else {
            var jsonData = {
                "personal_Profiles": {
                  "industry": categoriesArray,
                  "resumeage": showarchived,
                  "jobtype": jobTypeArray,
                  "search_text": searchkey,
                  "educationLevel" : educationId,
                  "pagination_val": '',
                  "industry_m": '',
                  "resumeage_m": '',
                  "jobtype_m": '',
                  "search_text_m": "",
                  "pagination_val_m": '',
                  "educationLevel_m" : ''
                }
              };
        }
        console.log(jsonData);

        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);

        //console.log(categoriesArray);
        var serachkeyinput = $(".searchKeyInput").val();
        if(serachkeyinput != undefined){
            serachkeyinput = serachkeyinput.trim();
        }
        const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "type": jobTypeArray,
            "eduExpInfo" : serachkeyinput,
            "titleSummary":serachkeyinput,
            "pageNumber" :pageNumber,
           // "pageSize":"3",
           "educationId":educationId,
           "archivedResumes" : showarchived,
           "pageSize":ITEMS_PER_PAGE,
            "sortBy" : "updatedOn"
          }

          var accessTokenIn=sessionStorage.getItem("accToken");
         //console.log(requestObject); return false;
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                      'Authorization': "Bearer "+accessTokenIn
                      //'Authorization': accessTokenIn
                    },
            body: JSON.stringify(requestObject)
            };
            //$(".doublecards").show();
            Services.findFilterProfile(requestOptions).then((res) =>
            {


                if(res.content){
                    //console.log(JSON.stringify(res));

                    $(".paginationContent").show();
                    if(positiontop == 1){
                        window.scrollTo({ top: 100, behavior: 'smooth' });
                    }
                    setTotalItems(res.totalElements);
                    settotalPages(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                    setCurrentPage(pageNumber);

                    var contAr = res.content;
                   
                    for (var i = 0; i < contAr.length; i++) {
                        var jobtypename = contAr[i].strJobType;
                       var typename = jobtypename.split("(");
                       contAr[i].jobtypename_new = typename[0];
                      // console.log(contAr[i].strJobType+' -- '+contAr[i].jobtypename_new);
                    } 
                    //console.log(contAr);
                    setProfileList(contAr);
                    if(contAr.length>0){
                        $(".paginationContent").show();
                    }else{
                        $(".paginationContent").hide();
                    }

               }


            });
            $(".doublecards").hide();
    }

    const filterContent_m = (ITEMS_PER_PAGE,pageNumber, setlcstorage, positiontop) => {
        var categoriesArray = [];
        console.log('mob');
        $.each($("input[name='category_m']:checked"), function(){
            categoriesArray.push($(this).val());
        });

        var jobTypeArray = [];
        $.each($("input[name='jobtype_m']:checked"), function(){
            jobTypeArray.push($(this).val());
        });

        var showarchived = 0;
        if($("input[name='archived_resumes_m']").prop('checked')){
            showarchived = 1;
        }

        var searchkey = $('.searchKeyInput_m').val();
        var educationId = $('select[name="educationselection_m"]').val();

        if(setlcstorage == "1"){
            var industries_val2 = '';
            //console.log("###244###"+typeof(categoriesArray))
            //console.log(categoriesArray)
            if(categoriesArray != ''){
                try{
                    industries_val2 = JSON.parse(categoriesArray);
                }catch(ex){
                    industries_val2 = Object.values(categoriesArray).join(",");
                }

            }

            var jobtype_val2 = '';
            if(jobTypeArray != ''){
                jobtype_val2 = JSON.parse(jobTypeArray);
            }

            // localStorage.setItem('pp_industry_selected_m', industries_val2);
            // localStorage.setItem('pp_resumeage_selected_m', showarchived);
            // localStorage.setItem('pp_jobtype_selected_m', jobtype_val2);
            // localStorage.setItem('pp_search_text_m', searchkey);

            // sessionStorage.setItem('pp_industry_selected_m', industries_val2);
            // sessionStorage.setItem('pp_resumeage_selected_m', showarchived);
            // sessionStorage.setItem('pp_jobtype_selected_m', jobtype_val2);
            // sessionStorage.setItem('pp_search_text_m', searchkey);

            
            
        }
        else {
            /*localStorage.setItem('av_search_text', '');
            localStorage.setItem('av_location_selected', '');
            localStorage.setItem('av_education_selected', '');
            localStorage.setItem('av_jobtype_selected', '');
            localStorage.setItem('av_industry_selected', ''); */
        }

        var industries_val2_m = '';
            //console.log("###244###"+typeof(categoriesArray))
            //console.log(categoriesArray)
            if(categoriesArray != ''){
                try{
                    industries_val2_m = JSON.parse(categoriesArray);
                }catch(ex){
                    industries_val2_m = Object.values(categoriesArray).join(",");
                }

            }

            var jobtype_val2_m = '';
            if(jobTypeArray != ''){
                jobtype_val2_m = JSON.parse(jobTypeArray);
            }

            if(pageNumber > 1){
                var jsonData = {
                    "personal_Profiles": {
                      "industry": '',
                      "resumeage": '',
                      "jobtype": '',
                      "search_text": '',
                      "pagination_val": '',
                      "educationLevel" : '',
                      "industry_m": industries_val2_m,
                      "resumeage_m": showarchived,
                      "jobtype_m": jobtype_val2_m,
                      "search_text_m": searchkey,
                      "pagination_val_m": pageNumber,
                      "educationLevel_m" : educationId
                    }
                  };
            } else {
                var jsonData = {
                    "personal_Profiles": {
                      "industry": '',
                      "resumeage": '',
                      "jobtype": '',
                      "search_text": '',
                      "pagination_val": '',
                      "educationLevel" : '',
                      "industry_m": industries_val2_m,
                      "resumeage_m": showarchived,
                      "jobtype_m": jobtype_val2_m,
                      "search_text_m": searchkey,
                      "pagination_val_m": '',
                      "educationLevel_m" : educationId
                    }
                  };
            }
        

        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);

       // console.log(categoriesArray);
        var searchkeyinput_m = $(".searchKeyInput_m").val();
        if(searchkeyinput_m != undefined){
            searchkeyinput_m = searchkeyinput_m.trim();
        }

        const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "type": jobTypeArray,
            "eduExpInfo" : searchkeyinput_m,
            "titleSummary":searchkeyinput_m,
            "pageNumber" :pageNumber,
           // "pageSize":"3",
           "archivedResumes" : showarchived,
           "pageSize":ITEMS_PER_PAGE,
            "sortBy" : "updatedOn",
            "educationId":educationId,
          }
          var accessTokenIn=sessionStorage.getItem("accToken");
         // console.log(requestObject);
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                      'Authorization': "Bearer "+accessTokenIn
                      //'Authorization': accessTokenIn
                    },
            body: JSON.stringify(requestObject)
            };
            //$(".doublecards").show();
            Services.findFilterProfile(requestOptions).then((res) =>
            {


                if(res.content){
                    //console.log(JSON.stringify(res));

                    $(".paginationContent").show();
                    if(positiontop == 1){
                        window.scrollTo({ top: 100, behavior: 'smooth' });
                    }
                    setTotalItems_m(res.totalElements);
                    settotalPages_m(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                    setCurrentPage_m(pageNumber);

                    var contAr = res.content;
                   
                    for (var i = 0; i < contAr.length; i++) {
                        var jobtypename = contAr[i].strJobType;
                       var typename = jobtypename.split("(");
                       contAr[i].jobtypename_new = typename[0];
                      // console.log(contAr[i].strJobType+' -- '+contAr[i].jobtypename_new);
                    } 
                   // console.log(contAr);
                    setProfileList_m(contAr);
                    //setProfileList_m(res.content);
                    if(contAr.length>0){
                        $(".paginationContent").show();
                    }else{
                        $(".paginationContent").hide();
                    }

               }


            });

            $(".doublecards").hide();
    }

    function industrySelCheckLS(local_indarray){
        if(local_indarray != undefined || local_indarray != null){
            var local_indarray = local_indarray.toString();
            var companyCatIn = local_indarray.split(",");
            for (var i = 0; i < companyCatIn.length; i++) {
                $('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
            }
        }
    }

    function industrySelCheckLS_m(local_indarray_m){
        if(local_indarray_m != undefined || local_indarray_m != null){
            var local_indarray_m = local_indarray_m.toString();
            var companyCatIn_m = local_indarray_m.split(",");
            for (var i = 0; i < companyCatIn_m.length; i++) {
                $('input[name="category_m"][value="' + companyCatIn_m[i] + '"]').prop("checked", true);
            }
        }
    }

    const filterContent_full = () => {
        var categoriesArray = [];

        $.each($("input[name='category']:checked"), function(){
            categoriesArray.push($(this).val());
        });

        var jobTypeArray = [];
        $.each($("input[name='jobtype']:checked"), function(){
            jobTypeArray.push($(this).val());
        });

        var showarchived = 0;
        if($("input[name='archived_resumes']").prop('checked')){
            showarchived = 1;
        }

        var searchkey = $('.searchKeyInput').val();
        const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "type": jobTypeArray,
            "eduExpInfo" : searchkey.trim(),
            "titleSummary":searchkey.trim(),
            "pageNumber" :1,
           "archivedResumes" : showarchived,
           "pageSize":5000,
            "sortBy" : "updatedOn",
            "educationId" : ''
          }

          var accessTokenIn=sessionStorage.getItem("accToken");
          //console.log(requestObject);
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                      'Authorization': "Bearer "+accessTokenIn
                      //'Authorization': accessTokenIn
                    },
            body: JSON.stringify(requestObject)
            };

            Services.findFilterProfile(requestOptions).then((res) =>
            {


                if(res.content){

                    var contAr = res.content;
                    setProfileListFull(res.content);

                    download_excel(contAr);

               }
            });
    }

    function download_excel(profileListdata){
        //console.log(profileListdata);

        var today = new Date();
        var month = today.toLocaleString('en-US', {month: 'short'});
        var date= today.getDate();
        var year = today.getFullYear();

        var filename = "Jobseeker-export-"+month+"-"+date+"-"+year+".csv";
       // console.log(filename);
        let csv = '';
        let row = '';
        let headers = '';
       // headers = headers.concat('Updated On', ',', 'Name', ',', 'Email', ',', 'Phone', ',', 'School District', ',', 'Class Year', ',', 'Seeking Job Type', ',', 'Interested Categories');
       var jobtypeslisthead = 'Updated On'+','+'Name'+','+'Email'+','+'Phone'+','+'Seeking'+','+'Categories'+',';
        /*var jobtypes_sorted= jobTypes.sort((a, b)=> b.jobTypeId - a.jobTypeId)
        for(var jb=0; jb < jobtypes_sorted.length; jb++){
            jobtypeslisthead = jobtypeslisthead+jobtypes_sorted[jb].name+',';
        }
        jobtypeslisthead = jobtypeslisthead+'Interested Categories'; */
        headers = headers.concat(jobtypeslisthead);

        csv = csv.concat(headers);
        row = '';
        //alert(profileListdata.length);
        for(var i=0; i<profileListdata.length; i++){
          var cats = profileListdata[i].categoryName;
           // var jobtypenames = profileListdata[i].jobTypeName;
            var dateUpdated = new Date(profileListdata[i].updatedDate);
                var displayupdated =  dateUpdated.getDate()+'-'+dateUpdated.toLocaleString('en-US', {month: 'short'})+'-'+dateUpdated.getFullYear();
                var jobtypeslistrow = "";

           var selectedcategs = cats.join('; ');

               jobtypeslistrow = "\n"+displayupdated+','+profileListdata[i].firstName+" "+profileListdata[i].lastName+','+profileListdata[i].email+','+profileListdata[i].phoneno+','+profileListdata[i].strJobType+',';

              /* for(var jb=0; jb < jobtypes_sorted.length; jb++){
                if(jobtypenames.includes(jobtypes_sorted[jb].name)){
                    jobtypeslistrow = jobtypeslistrow+'Y ,';
                }
                else {
                    jobtypeslistrow = jobtypeslistrow+' ,';
                }

            }*/
            jobtypeslistrow = jobtypeslistrow+selectedcategs.replace(/,/g, "-");

            row = row.concat(jobtypeslistrow);

        }
        csv = csv.concat(row);

        var file = new File([csv], filename, {type: "application/csv"});
        FileSaver.saveAs(file);
        $(".load-wrap").hide();
    }

    const getServiceIndustryData = () => {
        Services.getIndustryData().then((res) => {
            //console.log("Industry data=" + JSON.stringify(res.data));
            setCategory(res.data.content);
            var serviceresponse = res.data.content;
            var storeval = JSON.stringify(serviceresponse);
            sessionStorage.setItem('master_industry', storeval);
        });
        loadSelectedindustries();
    }

    const loadSelectedindustries = () => {
        setTimeout(() => {
            var loc_industryval = session_pageobj['industry'];
            industrySelCheckLS(loc_industryval);
            if(loc_industryval != ""){
               // filterContent(ITEMS_PER_PAGE, pppagenum, '0');
            }

            var loc_industryval_m = session_pageobj['industry_m'];
            industrySelCheckLS_m(loc_industryval_m);
            if(loc_industryval != ""){
               // filterContent_m(ITEMS_PER_PAGE, pppagenum, '0');
            }
        }, 500);
    }

    const getLocalIndustryData = () => {
        var localindustryval = sessionStorage.getItem('master_industry');
        localindustryval = JSON.parse(localindustryval);
        setCategory(localindustryval);
        loadSelectedindustries();
    }

    function jobtypeSelCheckLS(local_jobtypearray){
        if(local_jobtypearray != undefined || local_jobtypearray != null){
            var local_jobtypearray = local_jobtypearray.toString();
            var companyjobtype = local_jobtypearray.split(",");
            for (var i = 0; i < companyjobtype.length; i++) {
                $('input[name="jobtype"][value="' + companyjobtype[i] + '"]').prop("checked", true);
            }
        }
    }

    function jobtypeSelCheckLS_m(local_jobtypearray_m){
        if(local_jobtypearray_m != undefined || local_jobtypearray_m != null){
            var local_jobtypearray_m = local_jobtypearray_m.toString();
            var companyjobtype_m = local_jobtypearray_m.split(",");
            for (var i = 0; i < companyjobtype_m.length; i++) {
                $('input[name="jobtype_m"][value="' + companyjobtype_m[i] + '"]').prop("checked", true);
            }
        }
    }

    const getServiceJobType = () => {
        Services.getJobType().then((res) => {
            //console.log("setJobType data=" + JSON.stringify(res.data));
            var tempjobtypes = res.data.content;
            var serviceresponse = res.data.content;
            const index = tempjobtypes.findIndex((el) => el.name === "Internship/Coop")
            try{
                var jtid = tempjobtypes[index].jobTypeId;
            }catch(ex){
                console.log(ex);
            }
            
            //console.log("##180  180-->"+index)
            //console.log("##180  180-->"+jtid)
            tempjobtypes[index] = {
              name: 'College Intern/Student',
              jobTypeId:jtid
            }
           // console.log(tempjobtypes);
            tempjobtypes = tempjobtypes.filter(function(elem) {
                return elem.name !== 'Job Shadow';
            });

            tempjobtypes = tempjobtypes.filter(function(elem) {
                return elem.name !== 'Continuing Education';
            });
           // setJobType(tempjobtypes);

            var jobtype_unsort = tempjobtypes;
            var jobtypes_sorted = jobtype_unsort.sort((a, b) => b.jobTypeId - a.jobTypeId);
          array_move(jobtypes_sorted, 0, (jobtypes_sorted.length-1));
          jobtypes_sorted = jobtypes_sorted.filter(function (elem) {
            return elem.name !== 'Continuing Education';
          });
    
            var dummArray = [];
            for (var i = 0; i < jobtypes_sorted.length; i++) {
                var jobtypename = jobtypes_sorted[i].name;
               var typename = jobtypename.split("(");
                dummArray.push({ "name": typename[0], "jobTypeId": jobtypes_sorted[i].jobTypeId })
            } 
            //jobtypes_sorted.reverse();
            setJobType(dummArray);
            var storetypeval = JSON.stringify(serviceresponse);
            sessionStorage.setItem('master_jobtype', storetypeval);
        });
        loadSelectedJobtypes();
    }

    const loadSelectedJobtypes = () => {
        setTimeout(() => {
            //var loc_jobtypeval = localStorage.getItem('pp_jobtype_selected');
            var loc_jobtypeval = session_pageobj['jobtype'];
            
            jobtypeSelCheckLS(loc_jobtypeval);

            //var loc_jobtypeval_m = localStorage.getItem('pp_jobtype_selected_m');
            var loc_jobtypeval_m = session_pageobj['jobtype_m'];
            jobtypeSelCheckLS_m(loc_jobtypeval_m);
        }, 500);
    }

    const getLocalJobType = () => {
        var localjobtypeval = sessionStorage.getItem('master_jobtype');
        var tempjobtypes = JSON.parse(localjobtypeval);
            const index = tempjobtypes.findIndex((el) => el.name === "Internship/Coop")
            try{
                var jtid = tempjobtypes[index].jobTypeId;
            }catch(ex){
                console.log(ex);
            }
            
            tempjobtypes[index] = {
              name: 'College Intern/Student',
              jobTypeId:jtid
            }
           // console.log(tempjobtypes);
            tempjobtypes = tempjobtypes.filter(function(elem) {
                return elem.name !== 'Job Shadow';
            });

            tempjobtypes = tempjobtypes.filter(function(elem) {
                return elem.name !== 'Continuing Education';
            });
           // setJobType(tempjobtypes);

            var jobtype_unsort = tempjobtypes;
            var jobtypes_sorted = jobtype_unsort.sort((a, b) => b.jobTypeId - a.jobTypeId);
            array_move(jobtypes_sorted, 0, (jobtypes_sorted.length-1));
            jobtypes_sorted = jobtypes_sorted.filter(function (elem) {
            return elem.name !== 'Continuing Education';
            });
    
            var dummArray = [];
            for (var i = 0; i < jobtypes_sorted.length; i++) {
                var jobtypename = jobtypes_sorted[i].name;
               var typename = jobtypename.split("(");
                dummArray.push({ "name": typename[0], "jobTypeId": jobtypes_sorted[i].jobTypeId })
            } 
            //jobtypes_sorted.reverse();
            setJobType(dummArray);
            loadSelectedJobtypes();   
    }

    const getServiceEdicationInfo = () => {
        Services.getEducation().then((res) => {
            console.log("getEducation data=" + JSON.stringify(res.data));
            setEducations(res.data.content);
            var serviceresponse = res.data.content;
            var storeval = JSON.stringify(serviceresponse);
            sessionStorage.setItem('master_educationsprof', storeval);

        });
    }

    const getLocalEdicationInfo = () => {
        console.log(session_pageobj['educationLevel']);
        var localeducationval = sessionStorage.getItem('master_educationsprof');
        var tempeducaions = JSON.parse(localeducationval);
        setEducations(tempeducaions);
        loadSelectedEducation();
    }

    const loadSelectedEducation = () => {
        setTimeout(() => {
            //var loc_jobtypeval = localStorage.getItem('pp_jobtype_selected');
            var loc_educationval = session_pageobj['educationLevel'];
            console.log(loc_educationval);
            jobeducationSelCheckLS(loc_educationval);

            //var loc_jobtypeval_m = localStorage.getItem('pp_jobtype_selected_m');
            var loc_educationval_m = session_pageobj['educationLevel_m'];
            jobeducationSelCheckLS_m(loc_educationval_m);
        }, 500);
    }

    function jobeducationSelCheckLS(local_educationarray){
        if(local_educationarray != undefined || local_educationarray != null){
            
            $('select[name="educationselection"]').val(local_educationarray);
            
        }
    }

    function jobeducationSelCheckLS_m(local_educationarray_m){
        if(local_educationarray_m != undefined || local_educationarray_m != null){
            
            $('select[name="educationselection_m"]').val(local_educationarray_m);
            
        }
    }

    function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    const submitValue = () => {

    }



    return (
        <>
            <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                {(() => {
                                    return (<><li class="breadcrumb-item headtitle"><Link to={"/companyJobs"}>Company Dashboard</Link> </li><li class="breadcrumb-item headtitle"><Link to={""}>Personal Profiles</Link></li></>);
                                    
                                    })()}
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5 cd-desktop">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5 ">
                            <div class="row">
                                {/* <!-- left side --> */}
                                <div class="col-lg-4 mb-2">
                                    <div class="card p-3 mb-0 ">
                                        {/*<span className="mb-3 text-center">
                                          <a onClick={filterContent_full} id="export_studentbtn" class="reg-button text-center m-auto">Export to Excel</a>
                                        </span> */}
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Filter Profiles</h3>
                                        </div>
                                        <div class="py-3 text-start">
                                            <b class="text-uppercase">Keywords</b>

                                                <input class="form-control me-2 searchKeyInput" type="search" aria-label="Search" placeholder="Search"/>
                                                {/* <button class="border-0 p-0 dir-search" type="submit"><img src="images/search-icon.JPG" alt=""/></button> */}

                                        </div>
                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Categories of Interest</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                    categories.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data.catId} name="category"/> <label class="form-check-label" for="defaultCheck1"> {data.name}</label></li>
                                                ))}

                                                </ul>


                                            </div>
                                        </div>


                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Type</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                jobTypes.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data.jobTypeId} name="jobtype"/> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                ))}


                                                </ul>
                                            </div>
                                        </div>

                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Education Level</b>
                                            <div class="category-check-itm mt-2">
                                                {/* <ul>
                                                {
                                                educationsList.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" type="radio" value={data.educationId} name="education"/> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                ))}


                                                </ul> */}

                                                <select name="educationselection" id="education" className="form-select searchContent">
                                                    <option value="">Select</option>
  {educationsList.map((data, index) => (
    <option key={data.educationId} value={data.educationId}>
      {data.name}
    </option>
  ))}
</select>
                                            </div>
                                        </div>

                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Resume Archive</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>

                                                    <li><input class="form-check-input searchContent" type="checkbox" value='1' name="archived_resumes"/> <label class="form-check-label" for="defaultCheck1"> Show Archived Resumes Only </label></li>


                                                </ul>
                                            </div>
                                        </div>

                                        

                                        
                                    </div>


                                </div>

                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">

                                    <div class="two-grid-wrap">

                                    {
                                                cardsloop.map((data, inde) => (
                                                    <div style={{"maxWidth": "600px"}} class="doublecards">
                                                    <div class="htl is-loading" style={{"gridTemplateColumns":"60px 1fr", "marginBottom":"0px", "paddingBottom":"0px"}}>
                                                        <div class="image" style={{"width":"60px","height":"60px","borderRadius":"100px"}}></div>
                                                        <div class="content">
                                                            <h2></h2>
                                                            <div class="spanl">
                                                                <span style={{"width":"80%", "height":"15px"}}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"0px"}}>
                                                        <span style={{"height":"3px","width":"90%","marginLeft":"auto","margin":"10px"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"100%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"90%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"70%"}}></span>
                                                    </div>
                                                </div>
                                            ))}


                                    {
                                       profileList.map((data, index) => (

                                        <div class="grid-item p-3 position-relative">
                                            <div class="grid-head">
                                                <div class="grid-img">
                                                    <img src={data.profilePicS3URL?data.profilePicS3URL:"images/user-default.jpg"} class="w-100" alt=""/>
                                                </div>
                                                <div class="grid-title-wrap">
                                                    <h4 class="margin-bottom-0">{data.firstName} {data.lastName}</h4>
                                                    <small class="d-flex">
                                                            <span class="mr-2">Seeking:</span>
                                                            <span>{data.jobtypename_new == 'Internship/Coop' ? 'College Intern/Student' : data.jobtypename_new}</span>
                                                        </small>
                                                </div>
                                            </div>
                                            <div class="grid-body mb-4">
                                                <div class="grid-body-item charmin200 ">
                                                    <div class="grid-body-inn">
                                                        <span>Updated:</span>
                                                        <span>{moment(data.updatedDate).utcOffset('-0400').format("MM/DD/YYYY")}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Email:</span>
                                                        <span>{data.email}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Phone:</span>
                                                        <span>{data.phoneno}</span>
                                                    </div>
                                                    <div class="grid-body-inn ">
                                                        <span>Category:</span>
                                                        <span class="clamp">
                                                        {strCatArr(data.categoryName)}
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="grid-footer pp-footer">
                                                <Link to={`/personalProfileView?profileId=`+data.profileId+`&pppage=`+currentPage} class="reg-button m-auto d-block">View</Link>
                                            </div>
                                        </div>

                                        ))}


                                    </div>
                                       <div class="paginationContent mt-3">
                                       <Pagination
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange2}/>

                                       </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-3 cd-mobile">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">


                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">



                                </div>

                                {/* <!-- left side --> */}
                                <div class="col-lg-4 mb-2">
                                    <div class="card p-3 mb-0 ">
                                  {/*  <span className="mb-3 text-center">
                                        <a onClick={filterContent_full} id="export_studentbtn" class="reg-button text-center m-auto">Export to Excel</a>
                                        </span> */}
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Filter Profiles</h3>
                                        </div>
                                        <div class="py-3 text-start">
                                            <b class="text-uppercase">Keywords</b>

                                                <input class="form-control me-2 searchKeyInput_m" type="search" aria-label="Search" placeholder="Search"/>
                                                {/* <button class="border-0 p-0 dir-search" type="submit"><img src="images/search-icon.JPG" alt=""/></button> */}

                                        </div>
                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Categories of Interest</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                    categories.map((data, index) => (
                                                    <li><input class="form-check-input searchContent_m" type="checkbox" value={data.catId} name="category_m"/> <label class="form-check-label" for="defaultCheck1"> {data.name}</label></li>
                                                ))}

                                                </ul>
                                            </div>
                                        </div>

                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Type</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>

                                                {
                                                jobTypes.map((data, index) => (
                                                    <li><input class="form-check-input searchContent_m" type="checkbox" value={data.jobTypeId} name="jobtype_m" /> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                ))}


                                                </ul>
                                            </div>
                                        </div>

                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Education Level</b>
                                            <div class="category-check-itm mt-2">
                                                {/* <ul>
                                                {
                                                educationsList.map((data, index) => (
                                                    <li><input class="form-check-input searchContent_m" type="radio" value={data.educationId} name="education_m"/> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                ))}


                                                </ul> */}
                                                <select name="educationselection_m" id="education_m" className="form-select searchContent_m">
                                                    <option value="">Select</option>
  {educationsList.map((data, index) => (
    <option key={data.educationId} value={data.educationId}>
      {data.name}
    </option>
  ))}
</select>
                                            </div>
                                        </div>

                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Resume Archive</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>

                                                    <li><input class="form-check-input searchContent_m" type="checkbox" value='1' name="archived_resumes_m"/> <label class="form-check-label" for="defaultCheck1"> Show Archived Resumes Only </label></li>


                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                </div>




                                <div class="col-lg-8 mb-4">

                                    <div class="two-grid-wrap">

                                    {
                                                cardsloop.map((data, inde) => (
                                                    <div style={{"maxWidth": "600px"}} class="doublecards">
                                                    <div class="htl is-loading" style={{"gridTemplateColumns":"60px 1fr", "marginBottom":"0px", "paddingBottom":"0px"}}>
                                                        <div class="image" style={{"width":"60px","height":"60px","borderRadius":"100px"}}></div>
                                                        <div class="content">
                                                            <h2></h2>
                                                            <div class="spanl">
                                                                <span style={{"width":"80%", "height":"15px"}}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"0px"}}>
                                                        <span style={{"height":"3px","width":"90%","marginLeft":"auto","margin":"10px"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"100%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"90%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"70%"}}></span>
                                                    </div>
                                                </div>
                                            ))}


                                    {
                                       profileList_m.map((data, index) => (

                                        <div class="grid-item p-3 position-relative">
                                            <div class="grid-head">
                                                <div class="grid-img">
                                                    <img src={data.profilePicS3URL?data.profilePicS3URL:"images/user-default.jpg"} class="w-100" alt=""/>
                                                </div>
                                                <div class="grid-title-wrap">
                                                    <h4 class="margin-bottom-0">{data.firstName} {data.lastName}</h4>
                                                    <small class="d-flex">
                                                            <span class="mr-2">Seeking:</span>
                                                            <span>{data.jobtypename_new == 'Internship/Coop' ? 'College Intern/Student' : data.jobtypename_new}</span>
                                                        </small>
                                                </div>
                                            </div>
                                            <div class="grid-body mb-4">
                                                <div class="grid-body-item charmin200 ">
                                                    <div class="grid-body-inn">
                                                        <span>Updated:</span>
                                                        <span>{moment(data.updatedDate).utcOffset('-0400').format("MM/DD/YYYY")}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Email:</span>
                                                        <span>{data.email}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Phone:</span>
                                                        <span>{data.phoneno}</span>
                                                    </div>
                                                    <div class="grid-body-inn ">
                                                        <span>Category:</span>
                                                        <span class="clamp">
                                                        {strCatArr(data.categoryName)}
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="grid-footer pp-footer">
                                                <Link to={`/personalProfileView?profileId=`+data.profileId+`&pppage=`+currentPage} class="reg-button  m-auto d-block">View</Link>
                                            </div>
                                        </div>

                                        ))}


                                    </div>
                                       <div class="paginationContent mt-3">
                                       <Pagination
                                        total={totalItems_m}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage_m}
                                        onPageChange={handlePageChange2_m}/>

                                       </div>

                                </div>





                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>
        </>
    )
};

PersonalProfiles.propTypes = {};

PersonalProfiles.defaultProps = {};

export default PersonalProfiles;
