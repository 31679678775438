import React, { history,useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import Services from "../../services/services";
//import PaginationComponent from "../../utility/Pagination/Pagination";
import Pagination from "../../utility/Pagination/Pagination";
import { useNavigate,useParams, Link, useLocation } from "react-router-dom";
import ReactExport from "react-data-export";
const StudentList = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const navigate = useNavigate();
    const [categories, setCategory] = useState([]);
    const [profileList, setProfileList] = useState([]);
    const [profileList_m, setProfileList_m] = useState([]);
    const [profileListFull2, setProfileListFull] = useState([]);

    var pagenum = queryParams.get('pageid');
    const [currentYearList, setCurrentYearList] = useState([]);
    const [jobTypes, setJobType] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [itemName, setFieldName] = useState('');
    const ITEMS_PER_PAGE = 10; // Per page show the data pagination
    const Pagesize=5;
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const [totalItems_m, setTotalItems_m] = useState(0);
    const [totalpages_m, settotalPages_m] = useState();
    const [currentPage_m, setCurrentPage_m] = useState(1);
    const companyId=sessionStorage.getItem("logCompanyId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");
    const chKRoleAr=sessionStorage.getItem("roleAr");

    const [educationsList, setEducations] = useState([]);

    var FileSaver = require('file-saver');
    var session_pageobj = '';
    var webfilters = 1;
    var mobfilters = 1;
    const getSchoolIdbyUserId = () =>{

        //setCurrentYearList(years);
      }

      function download_excel(profileListFull){
        console.log(profileListFull);

        var today = new Date();
        var month = today.toLocaleString('en-US', {month: 'short'});
        var date= today.getDate();
        var year = today.getFullYear();

        var filename = "student-export-"+month+"-"+date+"-"+year+".csv";
       // console.log(filename);
        let csv = '';
        let row = '';
        let headers = '';
       // headers = headers.concat('Updated On', ',', 'Name', ',', 'Email', ',', 'Phone', ',', 'School District', ',', 'Class Year', ',', 'Seeking Job Type', ',', 'Interested Categories');
       var jobtypeslisthead = 'Created On'+','+'Updated On'+','+'Name'+','+'Email'+','+'Phone'+','+'Pursuing'+','+'School District'+','+'Class Year'+',';
        var jobtypes_sorted= jobTypes.sort((a, b)=> b.jobTypeId - a.jobTypeId)
        for(var jb=0; jb < jobtypes_sorted.length; jb++){
            jobtypeslisthead = jobtypeslisthead+jobtypes_sorted[jb].name+',';
        }
        jobtypeslisthead = jobtypeslisthead+'Reason for Select Career'+','+'Experience'+','+'Comments'+','+'Resume Uploaded'+','+'Parent Consent'+','+'Interested Categories';
        headers = headers.concat(jobtypeslisthead);

        csv = csv.concat(headers);
        row = '';
        //alert(profileListFull.length);
        for(var i=0; i<profileListFull.length; i++){
            var cats = profileListFull[i].categoryName;
            var jobtypenames = profileListFull[i].jobTypeName;
            var dateUpdated = new Date(moment(profileListFull[i].dateUpdated).utcOffset('-0400'));
            var dateCreated = new Date(moment(profileListFull[i].dateCreated).utcOffset('-0400'));
               // var displayupdated =  dateUpdated.getDate()+'-'+dateUpdated.toLocaleString('en-US', {month: 'short'})+'-'+dateUpdated.getFullYear();
                //var displayupdated =  dateUpdated.getMonth()+'/'+dateUpdated.getDate()+'/'+dateUpdated.getFullYear();
                //var displaycreated =  dateCreated.getMonth()+'/'+dateCreated.getDate()+'/'+dateCreated.getFullYear();

            //dateUpdated = moment(dateUpdated).utcOffset('-0400');
            var fmonth = ("0" + (dateUpdated.getMonth() + 1)).slice(-2);
            var fdate = ("0" + dateUpdated.getDate()).slice(-2);
            var fyear = dateUpdated.getFullYear();
    
            var displayupdated =  fmonth+'/'+fdate+'/'+fyear;

            //dateCreated = moment(dateCreated).utcOffset('-0400');
            var cfmonth = ("0" + (dateCreated.getMonth() + 1)).slice(-2);
            var cfdate = ("0" + dateCreated.getDate()).slice(-2);
            var cfyear = dateCreated.getFullYear();
    
            var displaycreated =  cfmonth+'/'+cfdate+'/'+cfyear;
                var jobtypeslistrow = "";

            var selectedcategs = cats.join('; ');

               // row = row.concat("\n",                displayupdated+","+profileListFull[i].firstName+" "+profileListFull[i].lastName+","+profileListFull[i].email+","+profileListFull[i].name+","+profileListFull[i].phoneno+","+profileListFull[i].classYear+","+jobtypenames.join("/")+","+'"'+cats.join("/")+'"')

               if(profileListFull[i].email == null || profileListFull[i].email == ''){
                var email = '--';
              }
              else {
                var email = profileListFull[i].email;
              }

              if(profileListFull[i].phoneno == null || profileListFull[i].phoneno == ''){
                var phoneno = '--';
              }
              else {
                var phoneno = profileListFull[i].phoneno;
              }


              if(profileListFull[i].educationId == 3){
                if(profileListFull[i].name == null || profileListFull[i].name == ''){
                    var schoolname = '--';
                  }
                  else {
                    var schoolname = profileListFull[i].name;
                  }
              } else {
                if(profileListFull[i].nameOfCollegeUniversity == null || profileListFull[i].nameOfCollegeUniversity == ''){
                    var schoolname = '--';
                  }
                  else {
                    var schoolname = profileListFull[i].nameOfCollegeUniversity;
                  }
              }
              

              if(profileListFull[i].classYear == null || profileListFull[i].classYear == ''){
                var classYear = '--';
              }
              else {
                var classYear = profileListFull[i].classYear;
              }

              var career_reason = profileListFull[i].reasonForSelectCareers;
              if(career_reason == null || career_reason == 'null'){
                career_reason = '--';
            }else {
                if(career_reason){
                    career_reason = career_reason.toString();
                    career_reason = career_reason.replace( /(<([^>]+)>)/ig, '');
                    career_reason = career_reason.replace( /(\n)/ig, '');
                    career_reason = career_reason.replace( /(\r)/ig, '');
                    career_reason = career_reason.replace(/,/g, "-");
                }
            }
              

              var experiance_info = profileListFull[i].experiance;
              if(experiance_info == null || experiance_info == 'null'){
                experiance_info = '--';
            }else {
                if(experiance_info){
                    experiance_info = experiance_info.toString();
                    experiance_info = experiance_info.replace( /(<([^>]+)>)/ig, '');
                    experiance_info = experiance_info.replace( /(\n)/ig, '');
                    experiance_info = experiance_info.replace( /(\r)/ig, '');
                    experiance_info = experiance_info.replace(/,/g, "-");
                    
                  }
            }
              

              var comments_info = profileListFull[i].comments;
              if(comments_info == null || comments_info == 'null'){
                comments_info = '--';
            } else {
                if(comments_info){
                    comments_info = comments_info.toString();
                    comments_info = comments_info.replace( /(<([^>]+)>)/ig, '');
                    comments_info = comments_info.replace( /(\n)/ig, '');
                    comments_info = comments_info.replace( /(\r)/ig, '');
                    comments_info = comments_info.replace(/,/g, "-");
                    
                  }
            }
              

               jobtypeslistrow = "\n"+displaycreated+','+displayupdated+','+profileListFull[i].firstName+" "+profileListFull[i].lastName+','+email+','+phoneno+','+profileListFull[i].educationName+','+schoolname+','+classYear+',';

                for(var jb=0; jb < jobtypes_sorted.length; jb++){
                    if(jobtypenames.includes(jobtypes_sorted[jb].name)){
                        jobtypeslistrow = jobtypeslistrow+'Y ,';
                    }
                    else {
                        jobtypeslistrow = jobtypeslistrow+' ,';
                    }

                }

                if(profileListFull[i].resumeFilePathUrl == null || profileListFull[i].resumeFilePathUrl == 'null' || profileListFull[i].resumeFilePathUrl == ''){
                    var resume_uploaded = 'N';
                } else {
                    var resume_uploaded = 'Y';
                }

                if(profileListFull[i].educationId == 3){
                    //school
                    if(profileListFull[i].authorization == 'yes' || profileListFull[i].authorization == 'Yes'){
                        var parent_consent = 'Y';
                    }
                    else if(profileListFull[i].authorization == 'no' || profileListFull[i].authorization == 'No'){
                        var parent_consent = 'N';
                    } 
                    else {
                        var parent_consent = '--';
                    }
                }
                else {
                    //college
                    
                        var parent_consent = 'N/A';
                    
                }
                
                

            jobtypeslistrow = jobtypeslistrow+career_reason+','+experiance_info+','+comments_info+','+resume_uploaded+','+parent_consent+','+selectedcategs.replace(/,/g, "-");

            row = row.concat(jobtypeslistrow);

        }
        csv = csv.concat(row);

        var file = new File([csv], filename, {type: "application/csv"});
        FileSaver.saveAs(file);
        $(".load-wrap").hide();
    }

    const getServiceEdicationInfo = () => {
        Services.getEducation().then((res) => {
            //console.log("getEducation data=" + JSON.stringify(res.data));
            var serviceresponse = res.data.content;
            serviceresponse = serviceresponse.filter(function (elem) {
                return elem.name != 'PhD';
              });
            setEducations(serviceresponse);
            
            var storeval = JSON.stringify(serviceresponse);
            sessionStorage.setItem('master_educations', storeval);

        });

    }


    function strCatArr(arr){
        if(Array.isArray(arr)){
          return arr.join(", ");
        }else{
          return "";
        }
      }

      const cardsloop = [1,2,3,4,5,6,7,8,9,10];
    useEffect(() => {
        $('.doublecards').show();
        if( chKRoleAr != "schoolsuper"){
            navigate('/Home');
        }
        $(".load-wrap").hide();
        // Getting the keys of the JSON object
        var keys = sessionStorage.getItem('pagejsonObject');
        if(keys == null || keys == undefined){
            navigate('/Home');
        } else {
        var keys_parsed = JSON.parse(keys);
        var pageobject = Object.keys(keys_parsed);
        // Checking if there is at least one key
        if (pageobject.length > 0) {
            // Accessing the first key
            var firstKey = pageobject[0];
            if(firstKey == 'totalStudentsList'){
                var pageobj = keys_parsed[firstKey];
                session_pageobj = keys_parsed[firstKey];
                var dev_poff = pageobj['pagination_val'];
                var dev_poff_m = pageobj['pagination_val_m'];
                if(dev_poff == ''){
                    if(dev_poff_m == ''){
                        pagenum = '1';
                    }
                    else {
                        pagenum = dev_poff_m; 
                    } 
                }
                else{
                    pagenum = dev_poff; 
                }
            } else {
                pagenum = '1';
                sessionStorage.removeItem('master_industry');
                sessionStorage.removeItem('master_jobtype');
                sessionStorage.removeItem('school_profile');
                sessionStorage.removeItem('school_list');
            }
        } else {
            //json empty
            pagenum = '1';
            sessionStorage.removeItem('master_industry');
                sessionStorage.removeItem('master_jobtype');
                sessionStorage.removeItem('school_profile');
                sessionStorage.removeItem('school_list');
        }
    }

        if (pagenum == null || pagenum == '') {
            
            // localStorage.setItem('st_industry_selected', '');
            // localStorage.setItem('st_school_selected', '');
            // localStorage.setItem('st_year_selected', '');
            // localStorage.setItem('st_search_text', '');

            // localStorage.setItem('st_industry_selected_m', '');
            // localStorage.setItem('st_school_selected_m', '');
            // localStorage.setItem('st_year_selected_m', '');
            // localStorage.setItem('st_search_text_m', '');

            var web_searchtxt = session_pageobj['search_text'];
            var web_school = session_pageobj['school'];
            var web_industry = session_pageobj['industry'];
            var web_classYear = session_pageobj['classYear'];
            if(web_searchtxt == '' && web_school == '' && web_industry == '' && web_classYear == ''){
                var webfilters = 0;
            }

            var mob_searchtxt = session_pageobj['search_text_m'];
            var mob_school = session_pageobj['school_m'];
            var mob_industry = session_pageobj['industry_m'];
            var mob_classYear = session_pageobj['classYear_m'];
            if(mob_searchtxt == '' && mob_school == '' && mob_industry == '' && mob_classYear == ''){
                var mobfilters = 0;
            }

            if(webfilters == 0 && mobfilters == 0){
            var jsonData = {
                "totalStudentsList": {
                  "industry": '',
                  "school": '',
                  "classYear": '',
                  "search_text": "",
                  "pagination_val": '',
                  "educationLevel": '',
                  "industry_m": '',
                  "school_m": '',
                  "classYear_m": '',
                  "search_text_m": "",
                  "pagination_val_m": '',
                  "educationLevel_m": '',
                }
              };

            var jsondataformatted = JSON.stringify(jsonData);
            sessionStorage.setItem('pagejsonObject', jsondataformatted);
            }
        }

        getServiceEdicationInfo();
        var education_master = sessionStorage.getItem('master_educations');
                
                

                var education_master = session_pageobj['educationLevel'];
                $("#education").val(education_master);

        //var t_st_search_text_m = localStorage.getItem('st_search_text_m');
        var education_master_m = session_pageobj['educationLevel_m'];
        $("#education_m").val(education_master_m);

       // var t_st_search_text = localStorage.getItem('st_search_text');
        var t_st_search_text = session_pageobj['search_text'];
        $(".searchKeyInput").val(t_st_search_text);

        //var t_st_search_text_m = localStorage.getItem('st_search_text_m');
        var t_st_search_text_m = session_pageobj['search_text_m'];
        $(".searchKeyInput_m").val(t_st_search_text_m);
        
        if( chKRoleAr == "schoolsuper"){
           // filterContent(ITEMS_PER_PAGE,'1');
            $( document ).on( "click", ".searchContent", function() {
                filterContent(ITEMS_PER_PAGE,'1','1','0');
                //filterContent_full();
            });
            $( document ).on( "change", ".searchKeyInput", function() {
               filterContent(ITEMS_PER_PAGE,'1','1','0');
               // filterContent_full();
            });

            $( document ).on( "click", ".searchContent_m", function() {
                filterContent_m(ITEMS_PER_PAGE,'1','1','0');
            });

            $( document ).on( "change", ".searchKeyInput_m", function() {
                filterContent_m(ITEMS_PER_PAGE,'1','1','0');
            });
            //navigate('/login');
            //navigate('/Home');
        }else{
            navigate('/Home');
        }


        $('#phone').usPhoneFormat();
        //getServiceProfileInfo();
        //getServiceIndustryData();
        //getServiceJobType();
       // getServiceSchoolList();
        getCurrentYear();

        // var schoolprofileInfo = sessionStorage.getItem('school_profile');
        // if(schoolprofileInfo == null || schoolprofileInfo == ''){
        //     getServiceProfileInfo();
        // }
        // else {
        //     getLocalProfileInfo();
        // }

            var ind_master = sessionStorage.getItem('master_industry');
            if(ind_master == null || ind_master == ''){
                getServiceIndustryData();
            }
            else {
                getLocalIndustryData();
            }

            var jobtype_master = sessionStorage.getItem('master_jobtype');
            if(jobtype_master == null || jobtype_master == ''){
                getServiceJobType();
            }
            else {
                getLocalJobType();
            }

            var schools_master = sessionStorage.getItem('school_list');
            if(schools_master == null || schools_master == ''){
                getServiceSchoolList();
            }
            else {
                getLocalSchoolList();
            }


         $(".paginationContent").hide();
         setTimeout(() => {
            if (pagenum != null && pagenum != "") {
                    // var web_ind = localStorage.getItem('st_industry_selected');
                    // var web_sch = localStorage.getItem('st_school_selected');
                    // var web_yer = localStorage.getItem('st_year_selected');
                    // var web_src = localStorage.getItem('st_search_text');

                    var web_ind = session_pageobj['industry'];
                    var web_sch = session_pageobj['school'];
                    var web_yer = session_pageobj['classYear'];
                    var web_src = session_pageobj['search_text'];
                    var pageoffset = session_pageobj['pagination_val'];

                    if (web_ind != "" || web_sch != '' || web_yer != '' || web_src != '' || pageoffset != ''){
                        setTimeout(() => {
                            filterContent(ITEMS_PER_PAGE, pagenum, '0','0');
                           // filterContent_m(ITEMS_PER_PAGE, pagenum, '0');
                        }, 2000);
                    }
                    else {
                        // var mob_ind = localStorage.getItem('st_industry_selected_m');
                        // var mob_sch = localStorage.getItem('st_school_selected_m');
                        // var mob_yer = localStorage.getItem('st_year_selected_m');
                        // var mob_src = localStorage.getItem('st_search_text_m');

                        var mob_ind = session_pageobj['industry_m'];
                        var mob_sch = session_pageobj['school_m'];
                        var mob_yer = session_pageobj['classYear_m'];
                        var mob_src = session_pageobj['search_text_m'];
                        var pageoffset_m = session_pageobj['pagination_val_m'];

                        if (mob_ind != "" || mob_sch != '' || mob_yer != '' || mob_src != '' || pageoffset_m != ''){
                            setTimeout(() => {
                               // filterContent(ITEMS_PER_PAGE, pagenum, '0');
                                filterContent_m(ITEMS_PER_PAGE, pagenum, '0','0');
                            }, 2000);
                        }
                        else {
                           filterContent(ITEMS_PER_PAGE, pagenum, '0','0');
                           filterContent_m(ITEMS_PER_PAGE, pagenum, '0','0');
                        }
                    }
            }else{
                 filterContent(ITEMS_PER_PAGE, '1', '0','0');
                 filterContent_m(ITEMS_PER_PAGE, '1', '0','0');
            }

        }, 3000);

    }, []);

    function getServiceProfileInfo() {
        Services.getCompanyProfile(companyId).then((res) => {
            setCategoriesSelected(res.data.companyCategory);
            var companyCatIn = res.data.companyCategory;
          var storeval = JSON.stringify(companyCatIn);
          sessionStorage.setItem('school_profile', storeval);
        });
      }

      function getLocalProfileInfo () {
        var localindustryval = sessionStorage.getItem('school_profile');
        localindustryval = JSON.parse(localindustryval);
        setCategoriesSelected(localindustryval);
      }
      
    const handlePageChange2 = (offset,triggerevent) => {
        filterContent(ITEMS_PER_PAGE,offset, '0','1');
      };

      const handlePageChange2_m = (offset,triggerevent) => {
        filterContent_m(ITEMS_PER_PAGE,offset, '0','1');
      };

    const filterContent = (ITEMS_PER_PAGE,pageNumber, setlcstorage, positiontop) => {
        var categoriesArray = [];

        $.each($("input[name='category']:checked"), function(){
            categoriesArray.push($(this).val());
        });
        //alert(JSON.stringify(categoriesArray));

        var schoolIdArray = [];
        $.each($("input[name='schoolId']:checked"), function(){
            schoolIdArray.push($(this).val());
        });

        var schoolYearArray = [];
        $.each($("input[name='schoolYear']:checked"), function(){
            schoolYearArray.push($(this).val());
        });

        var searchkey = $('.searchKeyInput').val();

        //var educationId = $('select[name="education"]').val();
        var educationId = '3';

        if (setlcstorage == "1") {
            var industries_val2 = '';
            if (categoriesArray != '') {
                //industries_val2 = JSON.parse(categoriesArray);
                industries_val2 = categoriesArray;
            }

            var school_val2 = '';
            if (schoolIdArray != '') {
                //school_val2 = JSON.parse(schoolIdArray);
                school_val2 = schoolIdArray;
            }

            var year_val2 = '';
            if (schoolYearArray != '') {
                //year_val2 = JSON.parse(schoolYearArray);
                year_val2 = schoolYearArray;
            }

            // localStorage.setItem('st_industry_selected', industries_val2);
            // localStorage.setItem('st_school_selected', school_val2);
            // localStorage.setItem('st_year_selected', year_val2);
            // localStorage.setItem('st_search_text', searchkey);

            // sessionStorage.setItem('st_industry_selected', industries_val2);
            // sessionStorage.setItem('st_school_selected', school_val2);
            // sessionStorage.setItem('st_year_selected', year_val2);
            // sessionStorage.setItem('st_search_text', searchkey);
        }
        else {
            /*localStorage.setItem('av_search_text', '');
            localStorage.setItem('av_location_selected', '');
            localStorage.setItem('av_education_selected', '');
            localStorage.setItem('av_jobtype_selected', '');
            localStorage.setItem('av_industry_selected', ''); */
        }

        if(pageNumber > 1){
            var jsonData = {
                "totalStudentsList": {
                  "industry": categoriesArray,
                  "school": schoolIdArray,
                  "classYear": schoolYearArray,
                  "search_text": searchkey,
                  "pagination_val": pageNumber,
                  "educationLevel" : educationId,
                  "industry_m": '',
                  "school_m": '',
                  "classYear_m": '',
                  "search_text_m": "",
                  "pagination_val_m": '',
                  "educationLevel_m" : '',
                }
              };
        } else {
            var jsonData = {
                "totalStudentsList": {
                  "industry": categoriesArray,
                  "school": schoolIdArray,
                  "classYear": schoolYearArray,
                  "search_text": searchkey,
                  "educationLevel" : educationId,
                  "pagination_val": '',
                  "industry_m": '',
                  "school_m": '',
                  "classYear_m": '',
                  "search_text_m": "",
                  "pagination_val_m": '',
                  "educationLevel_m" : '',
                }
              };
        }
        

        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);
        var serachkeyinput = $(".searchKeyInput").val();
        if(serachkeyinput != undefined){
            serachkeyinput = serachkeyinput.trim();
        }
        const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "classYear": schoolYearArray,
            "schoolId": schoolIdArray,
            "pageNumber" :pageNumber,
            "pageSize":ITEMS_PER_PAGE,
            "titleSummary":serachkeyinput,
            "sortBy" : "updatedOn",
            "jobType": [],
            "companyUser":"no",
            "educationId":educationId
          }

          var accessTokenIn=sessionStorage.getItem("accToken");
          console.log(requestObject);
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                      'Authorization': "Bearer "+accessTokenIn
                      //'Authorization': accessTokenIn
                    },
            body: JSON.stringify(requestObject)
            };
            $(".doublecards").show();
            Services.findFilterStudent(requestOptions).then((res) =>
            {


                if(res.content){
                    console.log(JSON.stringify(res));

                    $(".paginationContent").show();
                    if(positiontop == 1){
                        window.scrollTo({ top: 100, behavior: 'smooth' });
                    }
                    setTotalItems(res.totalElements);
                    settotalPages(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                    setCurrentPage(pageNumber);

                    var contAr = res.content;
                    for (var i = 0; i < contAr.length; i++) {
                        var jobtypename = contAr[i].jobTypeName;
                        var dummay_jobtype = [];
                        for (var j = 0; j < jobtypename.length; j++) {
                            var stored_jobtype = jobtypename[j];
                            var typename = stored_jobtype.split("(");
                            dummay_jobtype.push(typename[0]);
                        }

                        contAr[i].jobtypename_new = dummay_jobtype;
                    } 
                    setProfileList(contAr);
                    if(contAr.length>0){
                        $(".paginationContent").show();
                    }else{
                        $(".paginationContent").hide();
                    }
                    //filterContent_full();
               }


            });

            $(".doublecards").hide();

    }

    const filterContent_m = (ITEMS_PER_PAGE,pageNumber, setlcstorage, positiontop) => {
        var categoriesArray_m = [];

        $.each($("input[name='category_m']:checked"), function(){
            categoriesArray_m.push($(this).val());
        });
        //alert(JSON.stringify(categoriesArray));

        var schoolIdArray_m = [];
        $.each($("input[name='schoolId_m']:checked"), function(){
            schoolIdArray_m.push($(this).val());
        });

        var schoolYearArray_m = [];
        $.each($("input[name='schoolYear_m']:checked"), function(){
            schoolYearArray_m.push($(this).val());
        });

        var searchkey_m = $('.searchKeyInput_m').val();
        //var educationId_m = $('select[name="education_m"]').val();
        var educationId_m = '3';

//alert(setlcstorage);
        if (setlcstorage == "1") {
            var industries_val2 = '';
            if (categoriesArray_m != '') {
                //industries_val2 = JSON.parse(categoriesArray);
                industries_val2 = categoriesArray_m;
            }

            var school_val2 = '';
            if (schoolIdArray_m != '') {
              //  school_val2 = JSON.parse(schoolIdArray_m);
                school_val2 = schoolIdArray_m;
            }

            var year_val2 = '';
            if (schoolYearArray_m != '') {
               // year_val2 = JSON.parse(schoolYearArray_m);
                year_val2 = schoolYearArray_m;
            }

            // localStorage.setItem('st_industry_selected_m', industries_val2);
            // localStorage.setItem('st_school_selected_m', school_val2);
            // localStorage.setItem('st_year_selected_m', year_val2);
            // localStorage.setItem('st_search_text_m', searchkey_m);

            // sessionStorage.setItem('st_industry_selected_m', industries_val2);
            // sessionStorage.setItem('st_school_selected_m', school_val2);
            // sessionStorage.setItem('st_year_selected_m', year_val2);
            // sessionStorage.setItem('st_search_text_m', searchkey_m);
            
        }
        else {
            /*localStorage.setItem('av_search_text', '');
            localStorage.setItem('av_location_selected', '');
            localStorage.setItem('av_education_selected', '');
            localStorage.setItem('av_jobtype_selected', '');
            localStorage.setItem('av_industry_selected', ''); */
        }

        if(pageNumber > 1){
            var jsonData = {
                "totalStudentsList": {
                  "industry": '',
                  "school": '',
                  "classYear": '',
                  "search_text": '',
                  "pagination_val": '',
                  "educationLevel" : '',
                  "industry_m": categoriesArray_m,
                  "school_m": schoolIdArray_m,
                  "classYear_m": schoolYearArray_m,
                  "search_text_m": searchkey_m,
                  "pagination_val_m": pageNumber,
                  "educationLevel_m" : educationId_m
                }
              };
        } else {
            var jsonData = {
                "totalStudentsList": {
                  "industry": '',
                  "school": '',
                  "classYear": '',
                  "search_text": '',
                  "pagination_val": '',
                  "educationLevel" : '',
                  "industry_m": categoriesArray_m,
                  "school_m": schoolIdArray_m,
                  "classYear_m": schoolYearArray_m,
                  "search_text_m": searchkey_m,
                  "pagination_val_m": '',
                  "educationLevel_m" : educationId_m
                }
              };
        }

        

        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);
        var serachkeyinput = $(".searchKeyInput_m").val();
        if(serachkeyinput != undefined){
            serachkeyinput = serachkeyinput.trim();
        }
        const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray_m,
            "classYear": schoolYearArray_m,
            "schoolId": schoolIdArray_m,
            "pageNumber" :pageNumber,
            "pageSize":ITEMS_PER_PAGE,
            "titleSummary":serachkeyinput,
            "sortBy" : "updatedOn",
            "jobType": [],
            "companyUser":"no",
            "educationId":educationId_m,
          }

          var accessTokenIn=sessionStorage.getItem("accToken");
          console.log(requestObject);
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                      'Authorization': "Bearer "+accessTokenIn
                      //'Authorization': accessTokenIn
                    },
            body: JSON.stringify(requestObject)
            };
$('.doublecards').show();
            Services.findFilterStudent(requestOptions).then((res) =>
            {


                if(res.content){
                    console.log(JSON.stringify(res));

                    $(".paginationContent").show();
                    if(positiontop == 1){
                        window.scrollTo({ top: 100, behavior: 'smooth' });
                    }
                    setTotalItems_m(res.totalElements);
                    settotalPages_m(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                    setCurrentPage_m(pageNumber);

                    var contAr = res.content;
                    for (var i = 0; i < contAr.length; i++) {
                        var jobtypename = contAr[i].jobTypeName;
                        var dummay_jobtype = [];
                        for (var j = 0; j < jobtypename.length; j++) {
                            var stored_jobtype = jobtypename[j];
                            var typename = stored_jobtype.split("(");
                            dummay_jobtype.push(typename[0]);
                        }

                        contAr[i].jobtypename_new = dummay_jobtype;
                    } 
                    setProfileList_m(contAr);
                    if(contAr.length>0){
                        $(".paginationContent").show();
                    }else{
                        $(".paginationContent").hide();
                    }
                    //filterContent_full();
               }


            });


$('.doublecards').hide();
    }

    const getCurrentYear = () =>{
        var currentYear = new Date().getFullYear()
        var years = [];
        years.push(currentYear);
        for(var i=1;i<7;i++){
          years.push(currentYear+i);
        }
        //const now = new Date().getUTCFullYear();
        //const years = Array(now - (now + 5)).fill('').map((v, idx) => now - idx);
        console.log("#41#41-->"+JSON.stringify(years));
        setCurrentYearList(years);
        setTimeout(() => {
           // var loc_yearval = localStorage.getItem('st_year_selected');
            var loc_yearval = session_pageobj['classYear'];
            yearSelCheckLS(loc_yearval);
            if(loc_yearval != ""){
                //filterContent(ITEMS_PER_PAGE, pagenum, '0');
            }

            // var loc_yearval_m = localStorage.getItem('st_year_selected_m');
            var loc_yearval_m = session_pageobj['classYear_m'];
            yearSelCheckLS_m(loc_yearval_m);
            if(loc_yearval_m != ""){
               // filterContent_m(ITEMS_PER_PAGE, pagenum, '0');
            }
        }, 2000);
      }
      
      function yearSelCheckLS(local_yeararray) {
        if(local_yeararray != undefined || local_yeararray != null){
            local_yeararray = local_yeararray.toString();
            var schoolyearval = local_yeararray.split(",");
            for (var i = 0; i < schoolyearval.length; i++) {
                $('input[name="schoolYear"][value="' + schoolyearval[i] + '"]').prop("checked", true);
            }
        }
    }

    function yearSelCheckLS_m(local_yeararray_m) {
        if(local_yeararray_m != undefined || local_yeararray_m != null){
            local_yeararray_m = local_yeararray_m.toString();
            var schoolyearval_m = local_yeararray_m.split(",");
            for (var i = 0; i < schoolyearval_m.length; i++) {
                $('input[name="schoolYear_m"][value="' + schoolyearval_m[i] + '"]').prop("checked", true);
            }
        }
    }

    const getServiceSchoolList = () => {
      const requestObject={
          "siteId":process.env.React_App_SITE_ID,
          "pageNumber":'1',
          "pageSize":'500'
       }

       var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json',
                   //'Authorization': "Bearer "+accessTokenIn
                   //'Authorization': accessTokenIn
                 },
         body: JSON.stringify(requestObject)
       };

       Services.getSchoolList(requestOptions).then((res) =>
       {
            if(res){
                if(res){
                    setSchoolList(res);
                    var serviceresponse = res;
                    var storetypeval = JSON.stringify(serviceresponse);
                    sessionStorage.setItem('school_list', storetypeval);
                 }
            }
        });
       loadschoolsselected();
   }

   const getLocalSchoolList = () => {
        var localschoollistval = sessionStorage.getItem('school_list');
        var tempschoolslist = JSON.parse(localschoollistval);
        setSchoolList(tempschoolslist);
        loadschoolsselected();
   }

   const loadschoolsselected = () => {
    setTimeout(() => {
        //var loc_schoolval = localStorage.getItem('st_school_selected');
        var loc_schoolval = session_pageobj['school'];
        schoolSelCheckLS(loc_schoolval);
        if(loc_schoolval != ""){
           // filterContent(ITEMS_PER_PAGE, pagenum, '0');
        }
        
        //var loc_schoolval_m = localStorage.getItem('st_school_selected_m');
        var loc_schoolval_m = session_pageobj['school_m'];
        schoolSelCheckLS_m(loc_schoolval_m);
        if(loc_schoolval_m != ""){
           // filterContent_m(ITEMS_PER_PAGE, pagenum, '0');
        }
    }, 500);
   }
function schoolSelCheckLS(local_schoolarray) {
    if(local_schoolarray != undefined || local_schoolarray != null){
        local_schoolarray = local_schoolarray.toString();
        var schoolselval = local_schoolarray.split(",");
        for (var i = 0; i < schoolselval.length; i++) {
            $('input[name="schoolId"][value="' + schoolselval[i] + '"]').prop("checked", true);
        }
    }
}

function schoolSelCheckLS_m(local_schoolarray_m) {
    if(local_schoolarray_m != undefined || local_schoolarray_m != null){
        local_schoolarray_m = local_schoolarray_m.toString();
        var schoolselval_m = local_schoolarray_m.split(",");
        for (var i = 0; i < schoolselval_m.length; i++) {
            $('input[name="schoolId_m"][value="' + schoolselval_m[i] + '"]').prop("checked", true);
        }
    }
}



    const filterContent_full = () => {
        var categoriesArray = [];

        $.each($("input[name='category']:checked"), function(){
            categoriesArray.push($(this).val());
        });
        //alert(JSON.stringify(categoriesArray));

        var schoolIdArray = [];
        $.each($("input[name='schoolId']:checked"), function(){
            schoolIdArray.push($(this).val());
        });

        var schoolYearArray = [];
        $.each($("input[name='schoolYear']:checked"), function(){
            schoolYearArray.push($(this).val());
        });

        var serachkeyinput = $(".searchKeyInput").val();
        var educationId = $('select[name="education"]').val();
        if(serachkeyinput != undefined){
            serachkeyinput = serachkeyinput.trim();
        }
        const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "classYear": schoolYearArray,
            "schoolId": schoolIdArray,
            "pageNumber" :1,
            "pageSize":'5000',
            "titleSummary":serachkeyinput,
            "sortBy" : "updatedOn",
            "jobType": [],
            "companyUser":"no",
            "educationId":educationId
          }

          var accessTokenIn=sessionStorage.getItem("accToken");
          console.log(requestObject);
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                      'Authorization': "Bearer "+accessTokenIn
                      //'Authorization': accessTokenIn
                    },
            body: JSON.stringify(requestObject)
            };
            $(".load-wrap").show();
            Services.findFilterStudent(requestOptions).then((res) =>
            {


                if(res.content){
                   // console.log(JSON.stringify(res));
//alert(res.content.length);
                    var contAr = res.content;
                    setProfileListFull(res.content);
                    //$('#export_studentbtn').show();
                    download_excel(contAr);
                    /*setTimeout(() => {
                        download_excel(contAr);
                        }, 2000) */



               }


            });



    }

    function industrySelCheckLS(local_indarray) {
        if(local_indarray != undefined || local_indarray != null){
            local_indarray = local_indarray.toString();
            var companyCatIn = local_indarray.split(",");
            for (var i = 0; i < companyCatIn.length; i++) {
                $('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
            }
        }
    }

    function industrySelCheckLS_m(local_indarray_m) {
        if(local_indarray_m != undefined || local_indarray_m != null){
            local_indarray_m = local_indarray_m.toString();
            var companyCatIn_m = local_indarray_m.split(",");
            for (var i = 0; i < companyCatIn_m.length; i++) {
                $('input[name="category_m"][value="' + companyCatIn_m[i] + '"]').prop("checked", true);
            }
        }
    }

    const getServiceIndustryData = () => {
        Services.getIndustryData().then((res) => {
            console.log("Industry data=" + JSON.stringify(res.data));
            setCategory(res.data.content);
            var serviceresponse = res.data.content;
            var storeval = JSON.stringify(serviceresponse);
            sessionStorage.setItem('master_industry', storeval);
        });
        loadselectedindustries();
    }

    const loadselectedindustries = () => {
        setTimeout(() => {
            // var loc_industryval = localStorage.getItem('st_industry_selected');
            var loc_industryval = session_pageobj['industry'];
            //alert(loc_industryval);
            industrySelCheckLS(loc_industryval);
            if(loc_industryval != ""){
                //filterContent(ITEMS_PER_PAGE, pagenum, '0');
            }
            
            // var loc_industryval_m = localStorage.getItem('st_industry_selected_m');
            var loc_industryval_m = session_pageobj['industry_m'];
            industrySelCheckLS_m(loc_industryval_m);
            if(loc_industryval_m != ""){
                //filterContent_m(ITEMS_PER_PAGE, pagenum, '0');
            }

        }, 2000);
    }

    const getLocalIndustryData = () => {
        var localindustryval = sessionStorage.getItem('master_industry');
        localindustryval = JSON.parse(localindustryval);
        setCategory(localindustryval);
        loadselectedindustries();
    }

    const getServiceJobType = () => {
        Services.getJobType().then((res) => {
            console.log("setJobType data=" + JSON.stringify(res.data));
            setJobType(res.data.content);
            var serviceresponse = res.data.content;
            var storetypeval = JSON.stringify(serviceresponse);
            sessionStorage.setItem('master_jobtype', storetypeval);
        });
    }

    const getLocalJobType = () => {
        var localjobtypeval = sessionStorage.getItem('master_jobtype');
        var tempjobtypes = JSON.parse(localjobtypeval);
        setJobType(tempjobtypes);
    }

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    const submitValue = () => {

    }

    return (
        <>
            <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                            <li class="breadcrumb-item headtitle"> <a href="/schoolAdminDashboard">School Admin</a> </li>
                                <li class="breadcrumb-item headtitle "><a href="#">Student Profiles</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5 cd-desktop">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5 ">
                            <div class="row">
                                {/* <!-- left side --> */}
                                <div class="col-lg-4 mb-2">
                                    <div class="card p-3 mb-0 ">
                                    <span className="mb-3 text-center">
                                        <a onClick={filterContent_full} id="export_studentbtn" class="reg-button text-center m-auto">Export to Excel</a>
                                        </span>
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Filter Profiles</h3>
                                        </div>
                                        <div class="py-3 text-start">
                                            <b class="text-uppercase">Keywords</b>

                                                <input class="form-control me-2 searchKeyInput" type="search" aria-label="Search" placeholder="Search"/>
                                                {/* <button class="border-0 p-0 dir-search" type="submit"><img src="images/search-icon.JPG" alt=""/></button> */}

                                        </div>
                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Categories of Interest</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                    categories.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data.catId} name="category"/> <label class="form-check-label" for="defaultCheck1"> {data.name}</label></li>
                                                ))}

                                                </ul>


                                            </div>
                                        </div>


                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">School District</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                schoolList.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data.schoolId} name="schoolId"/> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                ))}
                                                </ul>
                                            </div>
                                        </div>


                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Year</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                currentYearList.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" id="schoolYear" type="checkbox" value={data} name="schoolYear"/> <label class="form-check-label" for="defaultCheck1"> {data} </label></li>

                                                ))}
                                                </ul>
                                            </div>
                                        </div>

                                        {/* <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Education Level</b>
                                            <div class="category-check-itm mt-2">
                                            <select name="education" id="education" className="form-select searchContent">
                                                    <option value="">Select</option>
  {educationsList.map((data, index) => (
    <option key={data.educationId} value={data.educationId}>
      {data.name}
    </option>
  ))}
</select>
                                            </div>
                                        </div> */}

                                    </div>


                                </div>

                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">

                                    <div class="two-grid-wrap">

                                    {
                                            cardsloop.map((data, inde) => (
                                                <div style={{"maxWidth": "600px"}} class="doublecards">
                                                    <div class="htl is-loading" style={{"gridTemplateColumns":"60px 1fr", "marginBottom":"0px", "paddingBottom":"0px"}}>
                                                        <div class="image" style={{"width":"60px","height":"60px","borderRadius":"100px"}}></div>
                                                        <div class="content">
                                                            <h2></h2>
                                                            <div class="spanl">
                                                                <span style={{"width":"80%", "height":"15px"}}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"0px"}}>
                                                        <span style={{"height":"3px","width":"90%","marginLeft":"auto","margin":"10px"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"100%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"90%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"70%"}}></span>
                                                    </div>
                                                </div>
                                            ))}


                                    {
                                       profileList.map((data, index) => (

                                        <div class="grid-item p-3 position-relative">
                                            <div class="grid-head">
                                                <div class="grid-img">
                                                    <img src={data.profilePicS3Url?data.profilePicS3Url:"images/user-default.jpg"} class="w-100" alt=""/>
                                                </div>
                                                <div class="grid-title-wrap">
                                                    <h4 class="margin-bottom-0">{data.firstName} {data.lastName}</h4>
                                                    <small class="d-flex">
                                                    <b class="mr-2" style={{minWidth:"65px"}}>Seeking:</b>
                                                            <p class="mb-0 clamp">{strCatArr(data.jobtypename_new)}</p>
                                                        </small>
                                                </div>
                                            </div>
                                            <div class="grid-body mb-4">
                                                <div class="grid-body-item charmin200">
                                                    <div class="grid-body-inn">
                                                        <span>Updated:</span>
                                                        <span>{moment(data.dateUpdated).utcOffset('-0400').format("MM/DD/YYYY")}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>School/College:</span>
                                                        <span>{(data.educationId == 3) ? 
                                                            (data.schoolId == 33) ? data.schoolDistrictOther : ((data.name =='' || data.name ==null) ? '--' : data.name) : 
                                                            (data.nameOfCollegeUniversityId == 22) ? data.nameOfCollegeUniversity : ((data.nameOfCollegeOrUniversity =='' || data.nameOfCollegeOrUniversity ==null) ? '--' : data.nameOfCollegeOrUniversity) }</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Email:</span>
                                                        <span>{data.email}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Phone:</span>
                                                        <span>{data.phoneno}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Category:</span>
                                                        <span class="clamp">
                                                        {strCatArr(data.categoryName)}
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-center">
                                                <Link to={`/studentProfileView?studentId=`+data.studentId+`&pageid=` + currentPage} class="reg-button text-center mx-2">View</Link>
                                                <br></br>
                                                <Link to={`/editStudent?studentId=`+data.studentId+`&pageid=` + currentPage} class="reg-button text-center">Edit</Link>
                                            </div>
                                        </div>

                                        ))}


                                    </div>
                                       <div class="paginationContent mt-3">
                                       <Pagination
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange2}/>

                                       </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-3 cd-mobile">
            <div class="container">
                <div class="row">

                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">


                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">



                                </div>

                                {/* <!-- left side --> */}
                                <div class="col-lg-4 mb-2">
                                    <div class="card p-3 mb-0 ">
                                        <span className="mb-3 text-center">
                                        <a onClick={filterContent_full} id="export_studentbtn" class="reg-button text-center m-auto">Export to Excel</a>
                                        </span>
                                        <div class="bg-heading">
                                            <h3 class="mb-0">Filter Profiles</h3>
                                        </div>
                                        <div class="py-3 text-start">
                                            <b class="text-uppercase">Keywords</b>

                                                <input class="form-control me-2 searchKeyInput_m" type="search" aria-label="Search" placeholder="Search"/>
                                                {/* <button class="border-0 p-0 dir-search" type="submit"><img src="images/search-icon.JPG" alt=""/></button> */}

                                        </div>
                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Categories of Interest</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                    categories.map((data, index) => (
                                                    <li><input class="form-check-input searchContent_m" type="checkbox" value={data.catId} name="category_m"/> <label class="form-check-label" for="defaultCheck1"> {data.name}</label></li>
                                                ))}

                                                </ul>
                                            </div>
                                        </div>

                                       {/* <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Type</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>

                                                {
                                                jobTypes.map((data, index) => (
                                                    <li><input class="form-check-input searchContent" type="checkbox" value={data.jobTypeId} name="jobtype" /> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                ))}


                                                </ul>
                                            </div>
                                        </div>  */}

                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">School District</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                schoolList.map((data, index) => (
                                                    <li><input class="form-check-input searchContent_m" type="checkbox" value={data.schoolId} name="schoolId_m"/> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                ))}
                                                </ul>
                                            </div>
                                        </div>


                                        <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Year</b>
                                            <div class="category-check-itm mt-2">
                                                <ul>
                                                {
                                                currentYearList.map((data, index) => (
                                                    <li><input class="form-check-input searchContent_m" type="checkbox" value={data} name="schoolYear_m"/> <label class="form-check-label" for="defaultCheck1"> {data} </label></li>

                                                ))}
                                                </ul>
                                            </div>
                                        </div>

                                        {/* <div class="mb-4">
                                            <b class="text-uppercase mb-3 w-100">Education Level</b>
                                            <div class="category-check-itm mt-2">
                                            <select name="education_m" id="education_m" className="form-select searchContent_m">
                                                    <option value="">Select</option>
  {educationsList.map((data, index) => (
    <option key={data.educationId} value={data.educationId}>
      {data.name}
    </option>
  ))}
</select>
                                            </div>
                                        </div> */}
                                    </div>


                                </div>




                                <div class="col-lg-8 mb-4">

                                    <div class="two-grid-wrap">


                                    {
                                        cardsloop.map((data, inde) => (
                                            <div style={{"maxWidth": "600px"}} class="doublecards">
                                                <div class="htl is-loading" style={{"gridTemplateColumns":"60px 1fr", "marginBottom":"0px", "paddingBottom":"0px"}}>
                                                    <div class="image" style={{"width":"60px","height":"60px","borderRadius":"100px"}}></div>
                                                    <div class="content">
                                                        <h2></h2>
                                                        <div class="spanl">
                                                            <span style={{"width":"80%", "height":"15px"}}></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="htl is-loading" style={{"padding":"0px","margin":"0px"}}>
                                                    <span style={{"height":"3px","width":"90%","marginLeft":"auto","margin":"10px"}}></span>
                                                </div>

                                                <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                    <span style={{"width":"100%"}}></span>
                                                    <span style={{"width":"100%"}}></span>
                                                </div>

                                                <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                    <span style={{"width":"100%"}}></span>
                                                    <span style={{"width":"90%"}}></span>
                                                </div>

                                                <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                    <span style={{"width":"100%"}}></span>
                                                    <span style={{"width":"70%"}}></span>
                                                </div>
                                            </div>
                                        ))
                                    }

                                    {
                                       profileList_m.map((data, index) => (

                                        <div class="grid-item p-3 position-relative">
                                            <div class="grid-head">
                                                <div class="grid-img">
                                                    <img src={data.profilePicS3Url?data.profilePicS3Url:"images/user-default.jpg"} class="w-100" alt=""/>
                                                </div>
                                                <div class="grid-title-wrap">
                                                    <h4 class="margin-bottom-0">{data.firstName} {data.lastName}</h4>
                                                    <small class="d-flex">
                                                    <b class="mr-2" style={{minWidth:"65px"}}>Seeking:</b>
                                                            <p class="mb-0">{strCatArr(data.jobtypename_new)}</p>
                                                        </small>
                                                </div>
                                            </div>
                                            <div class="grid-body mb-4">
                                                <div class="grid-body-item charmin200">
                                                    <div class="grid-body-inn">
                                                        <span>Updated:</span>
                                                        <span>{moment(data.dateUpdated).utcOffset('-0400').format("MM/DD/YYYY")}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>School/College:</span>
                                                        <span>{(data.educationId == 3) ? 
                                                            (data.schoolId == 33) ? data.schoolDistrictOther : ((data.name =='' || data.name ==null) ? '--' : data.name) : 
                                                            (data.nameOfCollegeUniversityId == 22) ? data.nameOfCollegeUniversity : ((data.nameOfCollegeOrUniversity =='' || data.nameOfCollegeOrUniversity ==null) ? '--' : data.nameOfCollegeOrUniversity) }</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Email:</span>
                                                        <span>{data.email}</span>
                                                    </div>
                                                    <div class="grid-body-inn">
                                                        <span>Phone:</span>
                                                        <span>{data.phoneno}</span>
                                                    </div>
                                                    <div class="grid-body-inn clamp">
                                                        <span>Category:</span>
                                                        <span class="clamp">
                                                        {strCatArr(data.categoryName)}
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="grid-footer d-flex align-items-center" style={{maxWidth:"260px"}}>
                                            <Link to={`/studentProfileView?studentId=`+data.studentId+`&pageid=` + currentPage} class="reg-button text-center m-auto">View</Link>
                                                <br></br>
                                                <Link to={`/editStudent?studentId=`+data.studentId+`&pageid=` + currentPage} class="reg-button text-center">Edit</Link>
                                            </div>
                                        </div>

                                        ))}


                                    </div>
                                       <div class="paginationContent mt-3">
                                       <Pagination
                                        total={totalItems_m}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage_m}
                                        onPageChange={handlePageChange2_m}/>

                                       </div>

                                </div>





                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
             </div>
        </section>
    </main>
        </>
    )
};

StudentList.propTypes = {};

StudentList.defaultProps = {};

export default StudentList;
