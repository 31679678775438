import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams, useLocation, useHistory } from "react-router-dom";
import ReactDOM from "react-dom";
//import { useParams } from "react-router";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
//import { useSearchParams } from "react-router-dom";
import TopCompaniesHiring from "../Jobs/top_comp_hiring";
import services from "../../services/services";
import Hiring from "../Jobs/Hiring";
import $, { data, parseJSON } from 'jquery';
import moment from "moment";
import Pagination3 from "../../utility/Pagination/Pagination";
import ReactPlayer from 'react-player';


const CompanyDetails = () => {
    // const { companyId22 } = useParams();
    //const companyId=24;
    //alert("test");

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const companyId = queryParams.get('companyId');
    const pagenum = queryParams.get('page');
    var fav_compayid = '';
    const siteId  = process.env.React_App_SITE_ID;
    const ITEMS_PER_PAGE = 5; // Per page show the data pagination

    const companyIdChkLogin = sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin = sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin = sessionStorage.getItem("logProfileId");
    const userIdChkLogin = sessionStorage.getItem("logUserId");

    const [companyProfile, setCompanyProfile] = useState([]);
    const [companyMiniVideosVO, setCompanyMiniVideosVO] = useState([]);
    const [availableJobsList, setAvailableJobsList] = useState([]);

    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const [indCategories, setIndCategories] = useState([]);
    const [companyIdVal, setCompanyIdVal] = useState('');
    const [googleMapAddress, setGoogleMapAddress] = useState('');
    const [perksGroupList, setPerksGroupList] = useState([]);
    const [jobsCountPostedByCompany, setJobsCountPostedByCompany] = useState([]);
    const staticData = require('../../constants/'+process.env.React_App_Static_Folder+'/static-data.json');

    function checkFavStatus(companyIdV, userIdV) {
        if(userIdV == null){
            return;
        }
       // alert(userIdV);
        //  console.log("companyId-->"+companyId);
        const requestObject = {
            "userId": userIdV,
            "companyId": companyIdV,
            "favFlag": "1"
        }
        console.log("Post Resume request object===" + requestObject);
        var accessTokenIn = sessionStorage.getItem("accToken");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn
                //'Authorization': accessTokenIn
            },
            body: JSON.stringify(requestObject)
        };

        services.checkFavoriteCompoany(requestOptions).then((res) => {

           // console.log(res[0].favFlag);
           //alert("test");
            if (res) {
                var reslen = res.length;
                //if (res.length > 0) {


                    try{
                        fav_compayid = res[(reslen-1)].favCompanyId;
                        if(res[(reslen-1)].favFlag == 1){
                           // alert("test");

                           //$("#addToFavoriteBtn_mob").hide();
                           // $("#favCompanyCurrent").show();
                            //$("#favCompanyCurrent_mob").show();

                            $("#addToFavoriteBtn").hide();
                            $("#removeFromoFavoriteBtn").show();
                           // $('.addFav').hide();
                        }
                        else{
                            /*
                            $('#favCompanyCurrent').hide();
                            $('#favCompanyCurrent_mob').hide();
                            $("#addToFavoriteBtn").show();
                            $("#addToFavoriteBtn_mob").show();
                            $("#removeFromoFavoriteBtn").hide();
                            */

                            $("#addToFavoriteBtn").show();
                            $("#removeFromoFavoriteBtn").hide();
                        }
                    }catch(ex){
                        $("#addToFavoriteBtn").show();
                        $("#removeFromoFavoriteBtn").hide();
                        console.log("e->"+ex);
                    }

            }else{

                $("#addToFavoriteBtn").show();
                $("#removeFromoFavoriteBtn").hide();
            }

            //navigate('/thankyou');

        });
    }
    function addToFavorite(companyIdV, userIdV) {
        //  console.log("companyId-->"+companyId);
        const requestObject = {
            "userId": userIdV,
            "companyId": companyIdV,
            "favFlag": "1"
        }
        console.log("Post Resume request object===" + requestObject);
        var accessTokenIn = sessionStorage.getItem("accToken");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn
                //'Authorization': accessTokenIn
            },
            body: JSON.stringify(requestObject)
        };

        services.addFavoriteCompoany(requestOptions).then((res) => {
            $("#addToFavoriteBtn").hide();
            $("#addToFavoriteBtn_mob").hide();
            $("#successMessage, #successMessage_mob").fadeTo(2000, 1000).slideUp(1000, function () {
                $("#favCompanyCurrent").show();
                $("#favCompanyCurrent_mob").show();
                $('#removeFromoFavoriteBtn').show();
                $('#removeFromoFavoriteBtn_mob').show();
                $("#successMessage, #successMessage_mob").slideUp(1000);
            });

            //navigate('/thankyou');

        });
    }

    function removeFromFavorite(companyIdV, userIdV) {
        //  console.log("companyId-->"+companyId);
        const requestObject = {
            "userId": userIdV,
            "companyId": companyIdV,
            "favFlag": "0",
            "favCompanyId" : fav_compayid
        }
        console.log("Post Resume request object===" + requestObject);
        var accessTokenIn = sessionStorage.getItem("accToken");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn
                //'Authorization': accessTokenIn
            },
            body: JSON.stringify(requestObject)
        };

        services.removeFavoriteCompany(requestOptions).then((res) => {
            $("#removeFromoFavoriteBtn").hide();
            $("#removeFromoFavoriteBtn_mob").hide();
            $("#successMessage_removal, #successMessage_mobremoval").fadeTo(2000, 1000).slideUp(1000, function () {
                $("#favCompanyCurrent_mob").show();
                $("#favCompanyCurrent").show();
                $("#successMessage_removal, #successMessage_mobremoval").slideUp(1000);
                checkFavStatus(companyIdV, userIdV);
            });

            //navigate('/thankyou');

        });
    }

    const getIndustryData = () => {
        services.getIndustryData().then((res) => {
            //console.log("Industry data=" + JSON.stringify(res.data));
            setIndCategories(res.data.content);
        });
    }
    function strcatIdArr(arr_seeking) {
        if (Array.isArray(arr_seeking)) {
            var arr3 = [];
            var arr_id = [];

            for (var s = 0; s < arr_seeking.length; s++) {
               // console.log(arr_seeking[s] + '----' + s);
                try {

                    for (var i = 0; i < indCategories.length; i++) {
                       // console.log(indCategories[i]['catId'] + '---' + arr_seeking[s]);
                        if (indCategories[i]['catId'] == arr_seeking[s]) {
                            var temp_seeking = indCategories[i].name;
                            //console.log(temp_seeking + "-->" + s);
                            arr3.push(temp_seeking);
                            arr_id.push(indCategories[i]['catId']);
                        }
                    }

                    /* if(typeof(jobTypes[arr_seeking[s]].name) != "undefined"){
                       var temp_seeking = jobTypes[arr_seeking[s]].name;
                       console.log(temp_seeking+"-->"+s);
                       arr3.push(temp_seeking);
                     }  */
                } catch (ex) {

                }


            }
          //  console.log("42");
          //  console.log(JSON.stringify(arr3));
            //return arr.join(arr,", ");
            //return arr.join(", ");

            var rtstr = '';
            for (var k = 0; k < arr3.length; k++) {
                //rtstr += '<span class="px-3 py-2 border mx-1">'+arr3[k]+'</span>';
                rtstr += '<span class="px-2 py-1 border mx-1"><a href="/CompanyListByIndustry?catId=' + arr_id[k] + '">' + arr3[k] + '</a></span>';
            }
            return rtstr;
            //return arr3.join(", ");
        } else {
            return "";
        }
    }

    const handlePageChange3 = (offset,triggerevent) => {
        getCurrent(ITEMS_PER_PAGE,offset)
      };


      function getCurrent(items_per_page,pagenum){


        var status = "published";
        var title = "";

        var sortBy = "";
        var sortDir=    "";

        services.getCompanyJobExpiredCurrentSpPaging(companyId,siteId,items_per_page,pagenum).then((res) =>
        {
            if(res.data.content){
                setAvailableJobsList(res.data.content);
                setTotalItems(res.data.totalElements);
                settotalPages(Math.ceil(res.data.totalElements / ITEMS_PER_PAGE));
                setCurrentPage(pagenum);
            }



        });
    }
    useEffect(() => {
        //alert("test");
        //alert(siv);
        //const [searchParams, setSearchParams] = useSearchParams();
        //var companyId = searchParams.get("companyId");
        //console.log("companyId--->"+companyId);
        const jsonData = {
            "companyDetails": {
              
            }
          };

        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);

        getIndustryData();

        $("#favCompanyCurrent").hide();
        $("#favCompanyCurrent_mob").hide();
        $("#successMessage").hide();
        $('#successMessage_mob').hide();
        $("#successMessage_removal").hide();
        $('#successMessage_mobremoval').hide();
        $(".successMessageCls").hide();
        $("#addToFavoriteBtn").hide();
        $("#removeFromoFavoriteBtn").hide();
        //$('.addFav').hide();

        getCompanyProfile();
        getTotalJobsPostedByCompany();
        getCurrent(ITEMS_PER_PAGE,'1');

        $("#addToFavoriteBtn").click(function () {
            if (userIdChkLogin != null && userIdChkLogin != "null") {
                addToFavorite(companyId, userIdChkLogin);
            }
        });

        $("#removeFromoFavoriteBtn").click(function(){
            if (userIdChkLogin != null && userIdChkLogin != "null") {
                removeFromFavorite(companyId, userIdChkLogin);
            }
        });

        $("#addToFavoriteBtn_mob").click(function () {
            if (userIdChkLogin != null && userIdChkLogin != "null") {
                addToFavorite(companyId, userIdChkLogin);
            }
        });



        if (userIdChkLogin != "" && userIdChkLogin != null ) {
            checkFavStatus(companyId, userIdChkLogin);
        }

        // console.log("companyId--->"+companyId22);
        //alert(companyId);

    }, []);

    // Accepts the array and key
    const groupBy = (array, key) => {
        // Return the end result
        return array.reduce((result, currentValue) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result;
        }, {}); // empty object is the initial value for result object
    };


    const getCompanyProfile = () => {
        services.getCompanyProfile(companyId).then((res) => {
            // console.log(res);
            setCompanyProfile(res.data);
            setCompanyIdVal(res.data.companyId);
            setCompanyMiniVideosVO(res.data.companyMiniVideosVO);

            if (res.data.perksList) {
                var perksList = res.data.perksList;
                var perksList_sorted = perksList.sort((a, b) => a.perkCategoryOrderId - b.perkCategoryOrderId);
                var perksTarr = [];
                console.log(JSON.stringify(perksList));
                const perksGroupedByPerksCatTitle = groupBy(perksList_sorted, 'perksCatTitle');
                console.log(JSON.stringify(perksGroupedByPerksCatTitle));

                setPerksGroupList(perksGroupedByPerksCatTitle);
                /*
                 var address = encodeURI(res.data.address1);
                 var city = encodeURI(res.data.city);
                  var state = encodeURI(res.data.state);
                 var zip = encodeURI(res.data.zip);
                */
                var address = res.data.address1;
                var city = res.data.city;
                var state = res.data.state;
                var zip = res.data.zip;
                var urlcode = address + '+' + city + '+' + state + '+' + zip;
                var urlcode2 = "https://www.google.com/maps/embed/v1/search?key=AIzaSyAIdz2pY_NwIEnW6T4kxtHIMpMizrVdePg&q=" + urlcode;

                setGoogleMapAddress(urlcode2);
                /*
                for(var j=0;j<perksList.length;j++){
                    var pobj ={
                        "perksId": perksList[j].perksId,
                        "perksTitle": perksList[j].perksTitle,
                        "perksImageUrl": perksList[j].perksImageUrl,
                        "perksdescription": perksList[j].perksdescription,
                        "perksCategoryId":perksList[j].perksCategoryId,
                        "createdDate": perksList[j].createdDate,
                        "perksCatTitle":perksList[j].perksCatTitle,
                    }
                   // perksTarr[perksList[j].perksCatTitle].push(pobj);
                   perksTarr[perksList[j].perksCatTitle].push(perksList[j]);
                }
                */
                // console.log("$$$$$$$$$$$$$$$$$$$");
                // console.log(JSON.stringify(perksTarr))
            }

        });
    }


    const getTotalJobsPostedByCompany = () => {
        services.getNoJobInSite(companyId).then((res) => {
            setJobsCountPostedByCompany(res.data);
        });
    }

    return (<>
        <main class="empr-directory-main">
            <div class="main-bgpatten"></div>
             <section class="headtitle-wrap">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">

                            <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                                <ol class="breadcrumb">

                                    {(() => {

                                        if (jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null") {
                                            return (<><li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li> </>);
                                        }

                                        if (studentIdChkLogin != null && studentIdChkLogin != "null") {
                                            return (<><li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li> </>);
                                        }

                                    })()}


                                    <li class="breadcrumb-item headtitle "><a href="#">Company Details </a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section class="company-detail-wrap py-5 ">
                <div class="container">
                    <div className="row pb-3">
                        <div className="d-flex">
                            <span className="mr-1 rotate-90">
                                <img src="images/mob-arrow.png" alt="" />
                            </span> {/*<a href="#" style={{color:"#fff"}}>Back to Company List</a>*/}
                            {/* <a href={"/availableJobs?page="+pagenum} style={{color:"#fff"}}>Back to Job List</a> */}
                            <a href={"/availableJobs"} style={{ color: "#fff" }}>Back to Job List</a>
                        </div>


                    </div>
                    <div class="row">
                            <div className="col-12 mb-4">
                                <div class="v2-company-detail-banner ">
                                <img src={companyProfile.bannerUrl ? companyProfile.bannerUrl : staticData.default_banner} class="w-100 h-100 object-fit" alt="" /> 

                                                {/* <img src={companyProfile.bannerUrl} class="w-100" alt="" /> */}
                                </div>
                                <div className="white-bg v2-company-detail-wrap p-4  ">
                                     <div className="v2-company-detail-logo mr-2">
                                     <img src={companyProfile.logoUrl ? companyProfile.logoUrl : staticData.default_logo} alt="" />
                                                    {/* <img src={companyProfile.logoUrl} alt="" /> */}
                                     </div>
                                     <div className="v2-company-detail-info">
                                                <div>
                                                    <div className="d-flex">

                                                        <span class="d-flex align-items-start">
                                                        <h4 className="mr-2">{companyProfile.name}</h4>
                                                        {/* <a href="#" class="tool" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="This company provides job shadow opportunities">
                                                             {companyProfile.jobShadowConcert == 'Y' ? <div style={{ width: "55px" }}> <img src="images/badge.png" alt="" class="w-100" /></div> : ''}
                                                        </a> */}
                                                    </span>
                                                    </div>

                                                    {/* <h4>Midmark Corporation</h4> */}
                                                    <div class="d-flex align-items-start mt-2 v2-info-mob">
                                                        {/* <span><a href="#">{companyProfile.name}</a> </span>
                                                    <span class="mx-3">|</span>*/}
                                                     <span className="border-right pe-3 me-3 v2-sm-info"><img src="images/map-red.png" alt="" /></span>
                                                     <div className="w-100">
                                                        <span class="mr-2 f18 fw600">
                                                            {companyProfile.address1 != '' ? companyProfile.address1 : ''}
                                                            <br/>
                                                           {/* {companyProfile.address1 != '' && companyProfile.city != '' ? ', ' : ''} */}
                                                            {companyProfile.city != '' ? companyProfile.city : ''}
                                                            {companyProfile.city != '' && companyProfile.state != '' ? ', ' : ''}
                                                            {companyProfile.state != '' ? companyProfile.state : ''}{companyProfile.state != '' && companyProfile.zip != '' ? ', ' : ''}
                                                            {companyProfile.zip != '' ? companyProfile.zip : ''}
                                                        </span>
                                                        <div className="d-flex  v2-social my-3 dis_none">
                                                            <a href="#"><img src="images/FACEBOOK 2.png" alt="" /></a>
                                                            <a href="#"><img src="images/LINKEDIN 2.png" alt="" /></a>
                                                            <a href="#"><img src="images/TWITTER 2.png" alt="" /></a>
                                                            <a href="#"><img src="images/EMAIL 2.png" alt="" /></a>
                                                        </div>
                                                        <div class="industry-tag my-3" dangerouslySetInnerHTML={{ __html: strcatIdArr(companyProfile.companyCategory) }} />
                                                        </div>


                                                    </div>

                                                </div>
                                     </div>
                                     <div className="d-flex justify-content-end v2-mob-oj">
                                     <a href="#" class="tool" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="This company provides job shadow opportunities">
                                                             {companyProfile.jobShadowConcert == 'Y' ? <div style={{ width: "55px" }}> <img src="images/badge.png" alt="" class="w-100" /></div> : ''}
                                                        </a>
                                                        <div class="stock-wrap d-flex align-items-start position-relative">
                                                    {/* {companyProfile.jobShadowConcert == 'Y' ?  <div class="js-badge-d"> <img src="images/badge.svg" alt="" class="w-100"/></div> : ''} */}

                                                    <a href={`/jobsByCompany?companyId=` + companyProfile.companyId}>{jobsCountPostedByCompany} OPEN JOBS</a>

                                                </div>
                                     </div>

                                </div>
                            </div>
                            <div class="col-lg-4 cd-mobile-qi mb-3">
                                        <div class="white-bg p-4">
                                            <div class="bg-heading">
                                                <h3 class="mb-0">Quick Info</h3>
                                            </div>
                                            <ul class="quick-info">
                                                <li>
                                                    {/* <span><img src={companyProfile.logoUrl==''?"images/map.png":companyProfile.logoUrl} alt="" /></span> */}
                                                    <span className="mr-2"><img src="images/map-v2.png" alt="" /></span>
                                                    <span className="fw600">
                                                        {companyProfile.address1 != '' ? companyProfile.address1 : ''}
                                                        {companyProfile.address1 != '' ? ', ' : ''}<br></br>
                                                        {companyProfile.city != '' ? companyProfile.city : ''}
                                                        {companyProfile.city != '' && companyProfile.state != '' ? ', ' : ''}
                                                        {companyProfile.state != '' ? companyProfile.state : ''}
                                                        {companyProfile.state != '' && companyProfile.zip != '' ? ', ' : ''}
                                                        {companyProfile.zip != '' ? companyProfile.zip : ''}</span>
                                                </li>
                                                <li>
                                                    <span  className="mr-2"><img src="images/cal-v2.png" alt="" /></span>
                                                    <span className="fw600">{companyProfile.phone} </span>
                                                </li>
                                                <li>
                                                    <span  className="mr-2"><img src="images/link-v2.png" alt="" /></span>
                                                    <span className="fw600"><a target="_blank" href={companyProfile.webSite ? companyProfile.webSite : '#'}>Website </a></span>
                                                </li>
                                                <li>
                                                    <span  className="mr-2"><img src="images/user-v2.png" alt="" /></span>
                                                    <span className="fw600"><b>{companyProfile.employeeCount}</b> Local Employees</span>
                                                </li>



                                                {(() => {



                                                if ( (studentIdChkLogin != null && studentIdChkLogin != "null") || (jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null")) {
                                                    return (<> <li>
                                                        <button class="addFav" id="addToFavoriteBtn_mob">
                                                            <span>

                                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                                                                </svg>

                                                            </span>
                                                            <span ><a  >Add to Favorite</a></span>
                                                        </button>
                                                    </li></>);
                                                }

                                                })()}





                                                <li id="favCompanyCurrent">
                                                    <button class="addFav" id="removeFromoFavoriteBtn_mob">
                                                        <span>
                                                             <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#d71216" class="bi bi-heart-fill" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                                            </svg>
                                                            {/* style={{cursor:"none"}} */}
                                                        </span>
                                                        <span ><a  >Favorite Company</a></span>
                                                    </button>
                                                </li>





                                            </ul>

                                            <div id="successMessage" class="alert successMessageCls">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div class="d-flex align-items-center">
                                                        <img src="/images/success-alert.png" alt="" />
                                                        <strong class="mx-2">Success!</strong> Added to favorite list.
                                                    </div>
                                                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                                </div>
                                            </div>

                                            <div id="successMessage_removal" class="alert successMessageCls">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div class="d-flex align-items-center">
                                                        <img src="/images/success-alert.png" alt="" />
                                                        <strong class="mx-2">Success!</strong> Removed from favorite list.
                                                    </div>
                                                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                                </div>
                                            </div>



                                            {/* <div class="quickinfo-social ">
                                            <a href="#"><img src="images/FACEBOOK 2.png" alt="" /></a>
                                            <a href="#"><img src="images/INSTA 2.png" alt="" /></a>
                                            <a href="#"><img src="images/TWITTER 2.png" alt="" /></a>
                                            <a href="#"><img src="images/LINKEDIN 2.png" alt="" /></a>
                                            <a href="#"><img src="images/YOUTUBE 2.png" alt="" /></a>
                                        </div> */}


                                            <div class="mt-3">
                                                <iframe src={googleMapAddress} allowfullscreen frameborder='0' class="w-100">
                                                </iframe>

                                            </div>
                                        </div>
                                    </div>
                            <div className="col-lg-8 mb-2">
                                <div className="white-bg px-5 py-4 v2-control">
                                <div class=" company-detrail-cont">
                                     <div className="cp-image" dangerouslySetInnerHTML={{ __html: companyProfile.description }} />
                                </div>


                                <div   class={companyMiniVideosVO.length>0?"mb-5":"mb-5 dis_none"}>
                                    <h3 class="border-bottom mb-4 py-3 headtitle ">Corporate Videos</h3>
                                    <div id="carouselExampleInterval" class="carousel slide slide up-vid v2-vid-cd" data-bs-ride="carousel">
                                        <div class="carousel-inner">


                                        {
                                        companyMiniVideosVO.map((datav, indexv) => (

                                            <div class={indexv == 0 ? "carousel-item active" : "carousel-item "} >
                                                {/* <iframe width="100%" height="460px" src={datav.url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe> */}
                                                <ReactPlayer url={datav.url} className="vid-cont-all" controls="true"  config = {
	{
		facebook: {
			attributes: {
				'data-width': 'auto',
				'data-height': 'auto'
			}
		}
	}
} />
                                            </div>


                                        ))}
                                        </div>

                                            {/* <div class="carousel-item active" data-bs-interval="10000">
                                            <iframe width="100%" height="460px" src="https://www.youtube.com/embed/pmhcSukfx-8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                            <div class="carousel-item" data-bs-interval="2000">
                                            <iframe width="100%" height="460px" src="https://www.youtube.com/embed/L14f3JvESEE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                            <div class="carousel-item">
                                            <iframe width="100%" height="460px" src="https://www.youtube.com/embed/iFHhk-pLCmw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div> */}

                                        {/* <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Previous</span>
                                        </button>
                                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Next</span>
                                        </button> */}

                                            <button
                                            class={companyMiniVideosVO.length>1?"carousel-control-prev":"carousel-control-prev dis_none"}
                                             type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Previous</span>
                                             </button>
                                             <button class={companyMiniVideosVO.length>1?"carousel-control-next":"carousel-control-next dis_none"} type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Next</span>
                                             </button>
                                        </div>


                                </div>
                                {/* <div class=" company-detrail-cont">
                                     <div dangerouslySetInnerHTML={{ __html: companyProfile.description }} />
                                </div> */}

                                <div class={Object.entries(perksGroupList).length>0?"mb-5 mt-4 " : "mb-5 mt-4  d-none"}>
                                    <h3 class="border-bottom mb-4 py-3 headtitle ">Perks & Benefits</h3>

                                    <div class="culture-wrap">

                                       <div class={Object.entries(perksGroupList).length>0?"abc dis_none":"abc text-center"}>
                                        <img src="images/no-perks.png"></img>
                                        <p>No perks available</p>
                                       </div>

                                        {Object.entries(perksGroupList).map(([key, value]) => {
                                            return (
                                                <div class="culture-inn">
                                                    <div><span class="culture-head">{key}</span></div>
                                                    <div class="vh-line"></div>
                                                    <div class="culture-item">
                                                        {
                                                            value.map((datap, index) => (
                                                                <div class="d-flex align-items-center">
                                                                    <span><img src={datap.perksImageUrl} alt="" /></span>
                                                                    <span>{datap.perksTitle}</span>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                         </div>
                                </div>

                                <div
                                className={availableJobsList.length>0?"mb-4":"mb-4 dis_none"}
                                >
                                        <div className="d-flex justify-content-between align-items-center border-bottom">
                                        <h3 class="mb-3 headtitle ">Company Jobs</h3>
                                         <a href={"/jobsByCompany?companyId="+companyProfile.companyId} class="text-uppercase">See All</a>
                                        </div>


                                        {
                                            availableJobsList.map((data, index) => (

                                        <div className="card mt-3">
                                            <div className="d-flex justify-content-between p-4 align-items-center v2-company-job">
                                            <div className="v2-br">
                                               <a href={`/jobsummary?jobId=${data.jobId}`} className="text-underline"> <h4>{data.jobTitle?data.jobTitle:""}</h4></a>
                                                    <small className="d-block mb-2">
                                                    <b class="d-flex text-uppercase">
                                                    <span>{moment(data.createdDate).format("MM/DD/YYYY")}</span>
                                                    </b>
                                                    </small>
                                                    <span className="mt-3"><p class="mt-2" dangerouslySetInnerHTML={{ __html: data.summary.substring(0, 150)  }} /></span>
                                                </div>
                                            <div style={{marginLeft:"20px"}} className="mt-4"><a href={`/jobsummary?jobId=${data.jobId}`} className="reg-button d-block " style={{minWidth:"150px"}}>View Job</a></div>
                                            </div>
                                        </div>

                                    ))}


<div class="pagination-wrap text-center mt-3 paginationContent">
                           <Pagination3
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={handlePageChange3}/>

                        </div>

                                        {/* <div className="card mt-3">
                                            <div className="d-flex justify-content-between p-4 align-items-center v2-company-job">
                                            <div className="v2-br">
                                               <a href="#" className="text-underline"> <h4>Software Engineer - Applications Developer</h4></a>
                                                    <small className="d-block mb-2">
                                                    <b class="d-flex text-uppercase">
                                                    <span>August 19</span>,
                                                    <span class="ms-1"> 2021</span>
                                                    </b>
                                                    </small>
                                                    <span className="mt-3">Software Developer is a critical role in the development of InfoLink, Crown’s global fleet management software solution.</span>
                                                </div>
                                            <div style={{marginLeft:"20px"}} className="mt-4"><a href="#" className="reg-button d-block " style={{minWidth:"150px"}}>View Job</a></div>
                                            </div>
                                        </div> */}
                                </div>

                                </div>
                            </div>
                            <div class="col-lg-4 cd-desktop-qi">
                                        <div class="white-bg p-4">
                                            <div class="bg-heading">
                                                <h3 class="mb-0">Quick Info</h3>
                                            </div>
                                            <ul class="quick-info">
                                                <li>
                                                    {/* <span><img src={companyProfile.logoUrl==''?"images/map.png":companyProfile.logoUrl} alt="" /></span> */}
                                                    <span className="mr-2"><img src="images/map-v2.png" alt="" /></span>
                                                    <span className="fw600">
                                                        {companyProfile.address1 != '' ? companyProfile.address1 : ''}
                                                        {companyProfile.address1 != '' ? ', ' : ''}<br></br>
                                                        {companyProfile.city != '' ? companyProfile.city : ''}
                                                        {companyProfile.city != '' && companyProfile.state != '' ? ', ' : ''}
                                                        {companyProfile.state != '' ? companyProfile.state : ''}
                                                        {companyProfile.state != '' && companyProfile.zip != '' ? ', ' : ''}
                                                        {companyProfile.zip != '' ? companyProfile.zip : ''}</span>
                                                </li>
                                                <li>
                                                    <span  className="mr-2"><img src="images/cal-v2.png" alt="" /></span>
                                                    <span className="fw600">{companyProfile.phone} </span>
                                                </li>
                                                <li>
                                                    <span  className="mr-2"><img src="images/link-v2.png" alt="" /></span>
                                                    <span className="fw600"><a target="_blank" href={companyProfile.webSite ? companyProfile.webSite : '#'}>Website </a></span>
                                                </li>
                                                <li>
                                                    <span  className="mr-2"><img src="images/user-v2.png" alt="" /></span>
                                                    <span className="fw600"><b>{companyProfile.employeeCount}</b> Local Employees</span>
                                                </li>



                                                {(() => {



                                                if ( (studentIdChkLogin != null && studentIdChkLogin != "null") || (jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null")) {
                                                    return (<> <li>
                                                        <button class="addFav" id="addToFavoriteBtn">
                                                            <span>

                                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                                                                </svg>

                                                            </span>
                                                            <span ><a  >Add to Favorite</a></span>
                                                        </button>
                                                    </li></>);
                                                }

                                                })()}





                                                <li id="favCompanyCurrent">
                                                    <button class="addFav" id="removeFromoFavoriteBtn">
                                                        <span>
                                                             <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#d71216" class="bi bi-heart-fill" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                                            </svg>
                                                            {/* style={{cursor:"none"}} */}
                                                        </span>
                                                        <span ><a  >Favorite Company</a></span>
                                                    </button>
                                                </li>





                                            </ul>

                                            <div id="successMessage" class="alert successMessageCls">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div class="d-flex align-items-center">
                                                        <img src="/images/success-alert.png" alt="" />
                                                        <strong class="mx-2">Success!</strong> Added to favorite list.
                                                    </div>
                                                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                                </div>
                                            </div>

                                            <div id="successMessage_removal" class="alert successMessageCls">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div class="d-flex align-items-center">
                                                        <img src="/images/success-alert.png" alt="" />
                                                        <strong class="mx-2">Success!</strong> Removed from favorite list.
                                                    </div>
                                                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                                </div>
                                            </div>



                                            {/* <div class="quickinfo-social ">
                                            <a href="#"><img src="images/FACEBOOK 2.png" alt="" /></a>
                                            <a href="#"><img src="images/INSTA 2.png" alt="" /></a>
                                            <a href="#"><img src="images/TWITTER 2.png" alt="" /></a>
                                            <a href="#"><img src="images/LINKEDIN 2.png" alt="" /></a>
                                            <a href="#"><img src="images/YOUTUBE 2.png" alt="" /></a>
                                        </div> */}


                                            <div class="mt-3">
                                                <iframe src={googleMapAddress} allowfullscreen frameborder='0' class="w-100">
                                                </iframe>

                                            </div>
                                        </div>
                                    </div>

                    </div>

                </div>
            </section>



        </main>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">JOB PERKS</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body py-5">
                        <div class="job-seek-modal">
                            <div class="d-flex mb-2 align-items-center">
                                <div> <img src="images/health.png" alt="" /></div>
                                <p>Health Insurance</p>
                            </div>

                            <div class="d-flex mb-2 align-items-center">
                                <div> <img src="images/lunch-plate.png" alt="" /></div>
                                <p>1-Hour Lunch Breaks</p>
                            </div>

                            <div class="d-flex mb-2 align-items-center">
                                <div> <img src="images/dental.png" alt="" /></div>
                                <p>Dental Insurance</p>
                            </div>

                            <div class="d-flex mb-2 align-items-center">
                                <div> <img src="images/cofee.png" alt="" /></div>
                                <p>Break Room Coffee Bar</p>
                            </div>

                            <div class="d-flex mb-2 align-items-center">
                                <div> <img src="images/sick.png" alt="" /></div>
                                <p>Unlimited Sick Leave</p>
                            </div>

                            <div class="d-flex mb-2 align-items-center">
                                <div> <img src="images/lunch-plate.png" alt="" /></div>
                                <p>1-Hour Lunch Breaks</p>
                            </div>

                            <div class="d-flex mb-2 align-items-center">
                                <div> <img src="images/life-insurance.png" alt="" /></div>
                                <p>Life Insurance</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>);


}

CompanyDetails.propTypes = {};

CompanyDetails.defaultProps = {};

export default CompanyDetails;