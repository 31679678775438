import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';

import Recent_Jobs from "./Jobs/Recent_Jobs";
import services from "../services/services";
import Hiring from "./Jobs/Hiring";
import Pagination from "../utility/Pagination/Pagination";


const ShadowCompanyList = () => {


    const [jobShadowList, setJobShadowList] = useState([]);

    const [jstotalItems, setjsTotalItems] = useState(0);
    const [jstotalpages, setjstotalPages] = useState();
    const [jscurrentPage, setjsCurrentPage] = useState(1);
    const JSITEMS_PER_PAGE = 9;
    const FVITEMS_PER_PAGE = 2;
    const ITEMS_PER_PAGE = 4; // Per page show the data pagination
    const ITEMS_PER_PAGEFVC = 6;

    const siteId = process.env.React_App_SITE_ID;
    const staticData = require('../constants/'+process.env.React_App_Static_Folder+'/static-data.json');

      const jshandlePageChange2 = (offset,triggerevent) => {
        // alert(offset);
        jobShadowCompanies(FVITEMS_PER_PAGE,offset);
      };
      const jobShadowCompanies = (ITEMS_PER_PAGE,pageNumber) => {

        var siteidval = process.env.React_App_SITE_ID;
              const requestObject={
                 "userId": '2',
                 "siteId": process.env.React_App_SITE_ID,

                  "pageNumber" :pageNumber,

                 "pageSize":ITEMS_PER_PAGE,
                  "sortBy" : "updatedOn"
                }
                var accessTokenIn=sessionStorage.getItem("accToken");
                console.log(requestObject);
                  const requestOptions = {
                  method: 'GET',
                  headers: { 'Content-Type': 'application/json',
                           // 'Authorization': "Bearer "+accessTokenIn
                            //'Authorization': accessTokenIn
                          },
                  body: JSON.stringify(requestObject)
                  };

                  var pageSize = JSITEMS_PER_PAGE;
                  //var pageSize = "1";

                  services.jobShadowCompanyListByUserIdGlobal(siteidval,pageNumber,pageSize).then((res) =>
                  {

                      if(res){
                       console.log(JSON.stringify(res));
                          $(".jspaginationContent").show();
                         // alert("fvtotalItems9-->"+res.data.totalElements);
                          setjsTotalItems(res.data.totalElements);
                          setjstotalPages(Math.ceil(res.data.totalElements / JSITEMS_PER_PAGE));
                         //alert(pageNumber)
                          setjsCurrentPage(pageNumber);

                          var contAr = res.content;
                          //setProfileList(res.content);
                          if(res.data.content){
                             setJobShadowList(res.data.content);
                             //alert("fvtotalItems-->"+fvtotalItems);
                          }

                     }

                  });

          }

    useEffect(() => {
        const jsonData = {
            "jobShadowCompaniesList": {
              
            }
          };
          var jsondataformatted = JSON.stringify(jsonData);
          sessionStorage.setItem('pagejsonObject', jsondataformatted);

        //alert("test")
        jobShadowCompanies(ITEMS_PER_PAGE,'1')
        //getIndustryData();
       // getCompaniesByIndustryCatId(siteId,catId);
    }, []);

    return (<>
           <main class="empr-directory-main">
        <div class="main-bgpatten"></div>

        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">

                        <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="/industries"> Job Shadow Opportunities</a><span style={{color: "#6c757d"}}>&#62;</span></li>

                            </ol>
                        </nav>


                    </div>
                </div>
            </div>
        </section>

        <section class="info-typography py-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 mb-4">
                        <div style={{"padding-bottom":"20px !important;"}}>

                        <div class="p-4 white-bg">
                        <div class="row">

                        {
                        jobShadowList.map((data, index) => (
                            <div class="col-lg-4 col-md-6 mb-4" >

                                {/* {item.name} */}
                                <a href={"/companyDetails?companyId="+data.companyId}>
                                    <div class="card p-3 school-card justify-content-center align-items-center"  style={{minHeight: "220px", position:"relative"}}>
                                        <a href="#" class="tool tool-pos-ab" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="This company provides job shadow opportunities">
                                        {data.jobShadowConcert == 'Y' ?  <div class="js-badge"> <img src="images/badge.png" alt="" class="w-100"/></div> : ''}
                                        </a>


                                    {/* <img src="images/Arcanum-School-Logo.png" style={{maxWidth: "200px"}} class="p-4 mb-2 m-auto" alt=""/> */}
                                    <div class="school-img-wrp" >
                                    <img src={data.companyLogo?data.companyLogo:staticData.default_logo } style={{maxWidth: "160px"}} class="mb-2" alt=""/>

                                   </div>

                                    <div class="text-center stock-wrap">
                                        <h3>{data.name}</h3>
                                    {/* <span>{data.city != '' ? data.city : ''}{data.city != '' && data.state != '' ? ', ' : '' }{data.state != '' ? data.state : ''}</span> */}

                                    </div>
                                </div></a>
                            </div>
                         ))}


                                <div class="pagination-wrap text-center mt-5 fvpaginationContent">
                                    <Pagination
                                        total={jstotalItems}
                                        itemsPerPage={JSITEMS_PER_PAGE}
                                        currentPage={jscurrentPage}
                                        onPageChange={jshandlePageChange2}/>
                                    </div>


                        </div></div>

                        </div>
                    </div>
                    <div class="col-lg-4">
                    <Recent_Jobs/>
                        {/* <div class="white-bg mb-4">
                            <img src="images/ad-1.png" class="w-100" alt="" />
                        </div>
                        <div class="white-bg mb-4">
                            <img src="images/ad-2.png" class="w-100" alt="" />
                        </div> */}
                        {/*<div class="p-4 white-bg mb-4">
                            <h2 class="title-stay-connect">Stay connected. Get the app!</h2>
                            <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                            <img src="images/HTO-app-face.png" class="w-100" alt=""/>

                            <div class="d-flex justify-content-center  mt-3">
                                <img src="images/apple-app-store.png" alt="" style={{"width":" 130px;"}} />
                                <img src="images/google-play.png" alt="" class="ms-2" style={{"max-width": "130px;"}} />
                            </div>
                        </div>*/}


                    </div>
                </div>
            </div>

            <Hiring/>

        </section>


    </main>
    </>);

}

ShadowCompanyList.propTypes = {};

ShadowCompanyList.defaultProps = {};

export default ShadowCompanyList;