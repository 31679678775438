import React,{history,useEffect, useState, useMemo } from "react";
import services from "../services/services";
import Recent_Jobs from "./Jobs/Recent_Jobs";
import Hiring from "./Jobs/Hiring";


const StatsFacts = () => {
    const [statsContent, setStatsContent] = useState([]);

    const getPageInfo = (reqopt) => {        
        services.getCMSPageContent('41').then((res) => {            
            console.log(JSON.stringify(res));
            if(res.data){
                setStatsContent(res.data.content.rendered);
            }       
        });       
     }

    useEffect(() => 
    {
        const jsonData = {
            "statsContetList": {
              
            }
          };
          var jsondataformatted = JSON.stringify(jsonData);
          sessionStorage.setItem('pagejsonObject', jsondataformatted);

       getPageInfo();
    },[]);  

  return(
  <>
     <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="#">Stats & Facts</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="white-bg px-5 pt-5">
                            <div class="row mb-3">
                                <div class="col-lg-12 mb-4">
                                <div dangerouslySetInnerHTML={{ __html: statsContent }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                    <Recent_Jobs/>
                        {/* <div class="white-bg mb-4">
                            <img src="images/ad-1.png" class="w-100" alt=""/>
                        </div>
                        <div class="white-bg mb-4">
                            <img src="images/ad-2.png" class="w-100" alt=""/>
                        </div> */}
                        {/*<div class="p-4 white-bg mb-4">
                            <h2 class="title-stay-connect">Stay connected. Get the app!</h2>
                            <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                            <img src="images/HTO-app-face.png" class="w-100" alt=""/>

                            <div class="d-flex justify-content-center  mt-3">
                                <img src="images/apple-app-store.png" alt="" style={{width: "130px"}}/>
                                <img src="images/google-play.png" alt="" class="ms-2" style={{maxWidth: "130px"}}/>
                            </div>
                        </div>*/}
                        {/*<div class="p-4 white-bg mb-4">
                            <div class="bg-heading">
                                <h3 class="mb-0">Recent Posted</h3>
                            </div>
                            <ul class="mt-4 recent-job">
                                <li>
                                    <a href="#">
                                        <span>Quality Checker - $14.50/Hr</span>
                                        <span>By: Spherion Staffing</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Quality Operations Clerk - $17.95/Hr</span>
                                        <span>By: Spherion Staffing</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Mechanical Design Engineer</span>
                                        <span>By: PowerBuilt Material Handling Solutions</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Production</span>
                                        <span>Pete DeLuke &amp; Associates, Inc.</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Quality Operations Clerk - $17.95/Hr</span>
                                        <span>By: Spherion Staffing</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Mechanical Design Engineer</span>
                                        <span>By: PowerBuilt Material Handling Solutions</span>
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
        <Hiring/>
    </main>
  </>
)};

StatsFacts.propTypes = {};

StatsFacts.defaultProps = {};

export default StatsFacts;
