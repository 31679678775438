import React, { history, useEffect, useState, useMemo } from "react";
import Sidebar from "../../utility/sidebar";
import AnnouncementWidget from "../Jobs/announcement-wid";
import Hiring from "../Jobs/Hiring";
import Services from "../../services/services";
import $, { parseJSON } from 'jquery';
import moment from "moment";
import Pagination from "../../utility/Pagination/Pagination";
import EventWidget from "../Jobs/eventwid";
import ReactPlayer from 'react-player';

import { useNavigate, useParams, useLocation, useHistory } from "react-router-dom";
const StudentDashboard = () => {
   const navigate = useNavigate();
   const [jobList, setJobList] = useState([]);
   const [favCatCompanyList, setFavCatCompanyList] = useState([]);
   const [educationType, setEducation] = useState([]);

   const [categories, setCategory] = useState([]);
   const [totalItems, setTotalItems] = useState(0);
   const [totalpages, settotalPages] = useState();
   const [currentPage, setCurrentPage] = useState(1);

   const [totalItemsFVC, setTotalItemsFVC] = useState(0);
   const [totalpagesFVC, settotalPagesFVC] = useState();
   const [currentPageFVC, setCurrentPageFVC] = useState(1);

   const [categoriesSelected, setCategoriesSelected] = useState([]);

   const [studGraduateYear, setGraduateYear] = useState('');
   const [studSchoolName, setStudentSchoolName] = useState('--');

   const [pursuingDisplayName, setPursuingDisplayName] = useState('');





   const [typeSelectedIn, setTypeSelectedIn] = useState([]);

   const ITEMS_PER_PAGE = 4; // Per page show the data pagination
   const ITEMS_PER_PAGEFVC = 6;
   const Pagesize = 5;
   //const studentId = 56;
   const studentId = sessionStorage.getItem("logStudentId");
   const loginDisplayName = sessionStorage.getItem("displayName");
   const userId = sessionStorage.getItem("logUserId");

   const FVITEMS_PER_PAGE = 2;
   const [fvtotalItems, setFvTotalItems] = useState(0);
   const [fvtotalpages, setFvtotalPages] = useState();
   const [fvcurrentPage, setFvCurrentPage] = useState(1);
   const [favVideoList, setFavVideoList] = useState([]);

   const JSITEMS_PER_PAGE = 6;
   const [jstotalItems, setjsTotalItems] = useState(0);
   const [jstotalpages, setjstotalPages] = useState();
   const [jscurrentPage, setjsCurrentPage] = useState(1);
   const [jobShadowList, setJobShadowList] = useState([]);

   const staticData = require('../../constants/'+process.env.React_App_Static_Folder+'/static-data.json');

   useEffect(() => {

      const jsonData = {
         "studentDashboard": {
           
         }
       };
       var jsondataformatted = JSON.stringify(jsonData);
       sessionStorage.setItem('pagejsonObject', jsondataformatted);

      if (studentId == null || studentId == "null") {
         //navigate('/login');
         navigate('/Home');
      }
      getEducation();
      getIndustryData();

      //setTimeout(function() { getStudentProfile(studentId);}, 1000);
      //getStudentProfile(studentId);
      //filterContent(ITEMS_PER_PAGE,'1');
      filterContentFav(ITEMS_PER_PAGE, '1');
      //filterCompanyContent(categoriesSelected);
      jobShadowCompanies(ITEMS_PER_PAGE, '1')

   }, []);


   const jobShadowCompanies = (ITEMS_PER_PAGE, pageNumber) => {
      var siteidval = process.env.React_App_SITE_ID;
      const requestObject = {
         "userId": userId,
         "siteId": process.env.React_App_SITE_ID,

         "pageNumber": pageNumber,

         "pageSize": ITEMS_PER_PAGE,
         "sortBy": "updatedOn"
      }
      var accessTokenIn = sessionStorage.getItem("accToken");
      console.log(requestObject);
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json',
            // 'Authorization': "Bearer "+accessTokenIn
            //'Authorization': accessTokenIn
         },
         body: JSON.stringify(requestObject)
      };

      var pageSize = JSITEMS_PER_PAGE;
      //var pageSize = "1";

      Services.jobShadowCompanyListByUserId(siteidval, pageNumber, pageSize, userId).then((res) => {

         if (res) {
            console.log(JSON.stringify(res));
            $(".jspaginationContent").show();
            // alert("fvtotalItems9-->"+res.data.totalElements);
            setjsTotalItems(res.data.totalElements);
            setjstotalPages(Math.ceil(res.data.totalElements / JSITEMS_PER_PAGE));
            //alert(pageNumber)
            setjsCurrentPage(pageNumber);

            var contAr = res.content;
            //setProfileList(res.content);
            if (res.data.content) {
               setJobShadowList(res.data.content);
               //alert("fvtotalItems-->"+fvtotalItems);
            }

         }

      });

   }


   const filterContentFav = (ITEMS_PER_PAGE, pageNumber) => {

      const requestObject = {
         "userId": userId,
         "siteId": process.env.React_App_SITE_ID,

         "pageNumber": pageNumber,

         "pageSize": ITEMS_PER_PAGE,
         "sortBy": "updatedOn"
      }
      var accessTokenIn = sessionStorage.getItem("accToken");
      console.log(requestObject);
      const requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json',
            // 'Authorization': "Bearer "+accessTokenIn
            //'Authorization': accessTokenIn
         },
         body: JSON.stringify(requestObject)
      };

      var pageSize = FVITEMS_PER_PAGE;
      //var pageSize = "1";

      Services.favCompanyMultipleVideosListByUserId(userId, pageNumber, pageSize).then((res) => {

         if (res) {
            console.log(JSON.stringify(res));
            $(".fvpaginationContent").show();
            // alert("fvtotalItems9-->"+res.data.totalElements);
            setFvTotalItems(res.data.totalElements);
            setFvtotalPages(Math.ceil(res.data.totalElements / FVITEMS_PER_PAGE));
            //alert(pageNumber)
            setFvCurrentPage(pageNumber);

            var contAr = res.content;
            //setProfileList(res.content);
            if (res.data.content) {
               setFavVideoList(res.data.content);
               //alert("fvtotalItems-->"+fvtotalItems);
            }

         }

      });

     /*
      Services.favCompanyVideosListByUserId(userId, pageNumber, pageSize).then((res) => {

         if (res) {
            console.log(JSON.stringify(res));
            $(".fvpaginationContent").show();
            // alert("fvtotalItems9-->"+res.data.totalElements);
            setFvTotalItems(res.data.totalElements);
            setFvtotalPages(Math.ceil(res.data.totalElements / FVITEMS_PER_PAGE));
            //alert(pageNumber)
            setFvCurrentPage(pageNumber);

            var contAr = res.content;
            //setProfileList(res.content);
            if (res.data.content) {
               setFavVideoList(res.data.content);
               //alert("fvtotalItems-->"+fvtotalItems);
            }

         }

      });
     */

   }


   const getEducation = () => {
      Services.getEducation().then((res) => {
         console.log("getEducation data=" + JSON.stringify(res));
         var tempedu = res.data.content;
         //console.log(tempjobtypes);
         tempedu = tempedu.filter(function (elem) {
            return elem.name != 'PhD';
         });
         getStudentProfile(studentId, tempedu);
         //setTimeout(function() { getStudentProfile(studentId,tempedu);}, 1000);
         setEducation(tempedu);
      });
   }


   function strEducationName(eduId) {
      //alert(JSON.stringify(educationType));
      var temp_seeking = "";
      if (eduId != "") {

         for (var i = 0; i < educationType.length; i++) {
            //console.log(jobTypes[i]['jobTypeId']+'---'+arr_seeking[s]);
            if (educationType[i]['educationId'] == eduId) {
               temp_seeking = educationType[i].name;
            }
         }
         // setPursuingDisplayName(temp_seeking);
         return temp_seeking;
      } else {
         return "";
      }
   }

   const getStudentProfile = (studentId, educationType_d) => {
      Services.getStudentProfile(studentId).then((res) => {
         // setJobSeekerDetails(res);
         console.log("31-->" + JSON.stringify(res.data.categoriesOfExpertise))
         console.log("31-->" + JSON.stringify(res.data.seekingEmploymentTypes))
         setGraduateYear(res.data.classYear);
         //alert(typeof(res.data.schoolName))
         
         if(res.data.educationId == 3){
            if(res.data.schoolId == 33){
               setStudentSchoolName(res.data.schoolDistrictOther);
            }
            else {
               if(res.data.schoolName == "" || typeof(res.data.schoolName) == "object" || typeof(res.data.schoolName) == "null"){
                  setStudentSchoolName("--");
                  
               }else{
                  setStudentSchoolName(res.data.schoolName);
               }
            }
         }
         else {
            if(res.data.nameOfCollegeUniversityId == 22){
               setStudentSchoolName(res.data.nameOfCollegeUniversity);
            }
            else {
               if(res.data.nameOfCollegeOrUniversity == "" || typeof(res.data.nameOfCollegeOrUniversity) == "object" || typeof(res.data.nameOfCollegeOrUniversity) == "null"){
                  setStudentSchoolName("--");
                  
               }else{
                  setStudentSchoolName(res.data.nameOfCollegeOrUniversity);
               }
            }
         }
         
        
         setCategoriesSelected(res.data.categoriesOfExpertise);
         setTypeSelectedIn(res.data.seekingEmploymentTypes);
         console.log("222" + res.data.educationId);

         //
         // var stEd = strEducationName(res.data.educationId);
         var eduId = res.data.educationId;
         var temp_seeking = "";
         for (var i = 0; i < educationType_d.length; i++) {
            //console.log(jobTypes[i]['jobTypeId']+'---'+arr_seeking[s]);
            if (educationType_d[i]['educationId'] == eduId) {
               temp_seeking = educationType_d[i].name;
            }
         }
         console.log("222----" + temp_seeking);
         setPursuingDisplayName(temp_seeking);
         //
         setTimeout(function () {



         }, 3000);


         filterContent(ITEMS_PER_PAGE, '1', res.data.categoriesOfExpertise, res.data.seekingEmploymentTypes);
         filterCompanyContent(ITEMS_PER_PAGEFVC, '1', res.data.categoriesOfExpertise);
      });
   }

   const getCatNameByCatID = (catId) => {
      return categories[catId];
      //return setCategory(catId)
   }
   const getIndustryData = () => {
      Services.getIndustryData().then((res) => {
         console.log("Industry data=" + JSON.stringify(res.data));

         console.log("getLocationCounty data=" + JSON.stringify(res.data));
         var lc = res.data.content;
         var myObject = {}
         for (var i = 0; i < lc.length; i++) {
            var dstrVal = lc[i]['name'];
            myObject[lc[i]['catId']] = dstrVal;
            //lcObject.push(myObject);
         }
         setCategory(myObject);

         //setCategory(res.data);
      });

   }
   const handlePageChange2 = (offset, triggerevent) => {
      // alert(offset);
      filterContent(ITEMS_PER_PAGE, offset, categoriesSelected, typeSelectedIn);
   };

   const handlePageChange3 = (offset, triggerevent) => {
      // alert(offset);
      filterCompanyContent(ITEMS_PER_PAGEFVC, offset, categoriesSelected);
   };

   const fvhandlePageChange2 = (offset, triggerevent) => {
      // alert(offset);
      filterContentFav(FVITEMS_PER_PAGE, offset);
   };

   const jshandlePageChange2 = (offset, triggerevent) => {
      // alert(offset);
      jobShadowCompanies(FVITEMS_PER_PAGE, offset);
   };


   const filterCompanyContent = (ITEMS_PER_PAGEFVC, pageNumber, categoriesSelectedIn) => {

      const requestObject = {
         "siteId": process.env.React_App_SITE_ID,
         "categoryIds": categoriesSelectedIn,
         "pageNumber": pageNumber,
         // "pageSize":"3",
         "pageSize": ITEMS_PER_PAGEFVC,
         "sortBy": "created_date",
         "sortDir": "desc"
      }

      var accessTokenIn = sessionStorage.getItem("accToken");
      console.log(requestObject);
      const requestOptions = {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + accessTokenIn
            //'Authorization': accessTokenIn
         },
         body: JSON.stringify(requestObject)
      };

      Services.getCompaniesByMultiIndustryCatId(requestOptions).then((res) => {
         console.log("245");
         console.log(JSON.stringify(res));
         if (res) {

            $(".paginationContent").show();
            setTotalItemsFVC(res.totalElements);

            settotalPagesFVC(Math.ceil(res.totalElements / ITEMS_PER_PAGEFVC));
            // alert(pageNumber)
            setCurrentPageFVC(pageNumber);

            var contAr = res.content;
            //setProfileList(res.content);
            if (res.content) {

               setFavCatCompanyList(res.content);
            }



         }


      });
   }

   function getId(url) {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = url.match(regExp);

      if (match && match[2].length == 11) {
         return match[2];
      } else {
         return 'error';
      }
   }

  function getEmbedVideo(myvidurl){
            
      var vidId = getId(myvidurl);
      if(vidId == 'error'){
         var embed_videourl = myvidurl;
      }
      else {

         //console.log(myvidurl+"---"+vidId);

         var embed_videourl = "https://www.youtube.com/embed/"+vidId; 
      }
      console.log(embed_videourl);
      return embed_videourl;
  }

   const filterContent = (ITEMS_PER_PAGE, pageNumber, categoriesSelectedIn, typeSelectedIn) => {
      const requestObject = {
         "siteId": process.env.React_App_SITE_ID,
         //"categoriesOfExpertise": [],
         "categoriesOfExpertise": categoriesSelectedIn,
         // "type": ["2","5"],
         // "type": ["8"],
         "type": typeSelectedIn,
         "titleSummary": "",
         "pageNumber": pageNumber,
         // "pageSize":"3",
         "pageSize": ITEMS_PER_PAGE,
         "sortBy": "created_date",
         "sortDir": "desc"
      }

      var accessTokenIn = sessionStorage.getItem("accToken");
      console.log(requestObject);
      const requestOptions = {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + accessTokenIn
            //'Authorization': accessTokenIn
         },
         body: JSON.stringify(requestObject)
      };

      Services.findFilterJob(requestOptions).then((res) => {

         console.log(JSON.stringify(res));
         if (res) {

            $(".paginationContent").show();
            setTotalItems(res.totalElements);
            settotalPages(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
            setCurrentPage(pageNumber);

            var contAr = res.content;
            //setProfileList(res.content);
            if (res.content) {
               setJobList(res.content);
            }



         }


      });
   }
   return (
      <>
         <main class="empr-directory-main">
            <div class="main-bgpatten"></div>
            {/* <!-- info typography --> */}
            <section class="headtitle-wrap">
               <div class="container">
                  <div class="row">
                     <div class="col-lg-12">
                        <nav style={{ "--bs-breadcrumb-divider": '>' }} aria-label="breadcrumb">
                           <ol class="breadcrumb">
                              <li class="breadcrumb-item headtitle "><a href="#">Student Dashboard </a></li>
                           </ol>
                        </nav>
                     </div>
                  </div>
               </div>
            </section>
            {/* <!-- info typography --> */}
            <section class="py-5">
               <div class="container">
                  <div class="row">
                     <div class="col-12 ">
                        <div class="white-bg p-5">
                           <div class="row">
                              <Sidebar />
                              <div class="col-lg-8 mb-4">
                                 <h4>Welcome back, {loginDisplayName}!</h4>
                                 <p>This is your Hometown Opportunity Dashboard. Here you’ll see all of the most important information about your job or career search, all in one handy location.</p>



                                 <div>
                                    CURRENTLY PURSUING : <strong>{pursuingDisplayName}</strong>
                                 </div>
                                 <div>
                                    GRADUATING YEAR : <strong>{studGraduateYear}</strong>
                                 </div>
                                 <div>
                                    NAME OF THE INSTITUTION / SCHOOL : <strong>{studSchoolName}</strong>
                                 </div>
<br/>
                                 <div>
                                    <small class="text_abovehead" style={{color:"red", fontSize:13, display:"block"}}>If you graduated from your school/college and are no longer in school, make sure to promote yourself to <a href="/convertToProfile" style={{textDecorationLine:"underline", color:"#FF0000"}}>Job Seeker</a> by clicking on the link to the left so local companies can reach out to you for possible job openings. Please be sure to attach a current resume so we can connect you with the most relevant jobs.</small>
                                 </div>


                                 <div class="card p-3 mt-5">
                                    <div class="announce-wrap mb-4">

                                       <div class="orange-ribbon d-flex mb-4">
                                          <img src="images/categories-icon.png" alt="" class="mr-2" />
                                          <span>YOUR FIELDS OF INTEREST</span>
                                       </div>

                                       <div class="after-orange-ribbon mb-5">
                                          {
                                             categoriesSelected.map((data, index) => (
                                                <h4>{getCatNameByCatID(data)}   </h4>
                                             ))
                                          }
                                          {/* <h4>ARCHITECTUREAL AND DESIGN SERVICES</h4>
                                       <h4>ART</h4>
                                       <h4>GRAPHIC DESIGN</h4> */}
                                       </div>
                                       <div class="d-flex flex-md-row flex-column">
                                          <a href="/updateStudent" class="grey-button mt-3 mr-2  text-uppercase text-cente">update my categories</a>
                                          <a href={"/careerExploreJobs?cat=" + categoriesSelected} class="carousel-button  mt-3 text-uppercase text-cente">explore jobs</a>
                                       </div>
                                    </div>


                                 </div>

                                 <div class="card p-3 mt-5">
                                    <div class="announce-wrap">
                                       <div class="orange-ribbon d-flex mb-4">
                                          <img src="images/company-icon.png" alt="" class="mr-2" />
                                          <span>COMPANIES OF INTEREST</span>
                                       </div>
                                       <div className="row mt-4 ">


                                          {
                                             favCatCompanyList.map((data, index) => (
                                                <div className="col-md-4">




                                                   <a href={"/companyDetails?companyId=" + data.companyid}>
                                                      <div class="card py-3 px-2" style={{ minHeight: "230px" }}>


                                                         <a class="tool tool-pos-ab" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="This company provides job shadow opportunities">
                                                            {data.job_Shadow_Concert == 'Y' ? <div class="js-badge"> <img src="images/badge.png" alt="" class="w-100" /></div> : ''}
                                                         </a>
                                                         <img src={data.logoURL?data.logoURL:staticData.default_logo } class="stock-logo mt-0 mb-2" alt=""/>

                                                         {/* <img src={data.logo_URL} class="stock-logo" alt=""/> */}
                                                         <div class="text-center stock-wrap">
                                                            <h3>{data.companyName}</h3>
                                                            {/* <span>6783 Steager Rd<br/>Celina, OH, 45822</span> */}
                                                            {/* <a href="jobsByCompany?companyId=382">2 OPEN JOBS</a> */}
                                                         </div>


                                                         <small style={{ fontWeight: "400", fontSize: "12px", lineHeight: "14px", textAlign: "center", margin: "auto", minHeight: "40px" }}> {`${data.catTitle.substring(0, 42)}...`}</small>

                                                      </div>
                                                   </a>
                                                </div>
                                             ))
                                          }

                                          <div class="pagination-wrap text-center mt-5 paginationContent">
                                             <Pagination
                                                total={totalItemsFVC}
                                                itemsPerPage={ITEMS_PER_PAGEFVC}
                                                currentPage={currentPageFVC}
                                                onPageChange={handlePageChange3} />

                                          </div>



                                       </div>
                                    </div>
                                 </div>



                                 <div class="card p-3 mt-5">
                                    <div class="announce-wrap">

                                       <div class="orange-ribbon d-flex mb-4">
                                          <img src="images/job-opp-icon.png" alt="" class="mr-2" />
                                          <span>CAREER OPPORTUNITIES OF INTEREST</span>
                                       </div>                                                                          

                                       {(() => {
                                       if( jobList.length == 0 ){                    
                                          return (<> <div class="text-center"><a class="carousel-button m-auto  mt-3 text-uppercase text-cente d-block" style={{width:'250px'}} href="/availableJobs">Explore all available jobs</a></div></>);
                                       }                                  
                                       })()}   
                                       
                                       {
                                          jobList.map((data, index) => (
                                             <div class="oppertunity-card">
                                                <div class="logo-org">
                                                   {/* <img src={data.logo_url} alt=""/> */}
                                                   <img src={data.logo_url?data.logo_url:staticData.default_logo } alt=""/>

                                                </div>
                                                <div class="right-info">
                                                   <a href={`/jobsummary?jobId=` + data.jobId}><h4> {`${data.title.substring(0, 40)}`}{data.title.length>40?"...":""}</h4></a>
                                                   <div class="d-flex2">
                                                      <span><a href={`/companyDetails?companyId=` + data.companyId}>{data.name}</a> </span>
                                                      <span class="mx-3">|</span>
                                                      <span> {data.state}, {data.address1}</span>
                                                   </div>
                                                   <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                                                   <b class="text-uppercase">{moment(data.createdDate).format("MM/DD/YYYY")}</b>
                                                </div>
                                             </div>

                                          ))
                                       }

                                       <div class="pagination-wrap text-center mt-5 paginationContent">
                                          <Pagination
                                             total={totalItems}
                                             itemsPerPage={ITEMS_PER_PAGE}
                                             currentPage={currentPage}
                                             onPageChange={handlePageChange2} />
                                          {/* <Pagination items={this.state.exampleItems} onChangePage={this.onChangePage} /> */}
                                       </div>



                                    </div>


                                 </div>

                                 {/* Job Shadow Oppertunities (use code here)  */}


                                 <div class="card p-3 mt-5">
                                    <div class="announce-wrap">

                                       <div class="orange-ribbon d-flex mb-4">
                                          <img src="images/job-shadow-oppertunity-icon.png" alt="" class="mr-2" />
                                          <span>JOB SHADOW OPPORTUNITIES</span>
                                       </div>
                                       <div className="row mt-4 ">
                                          
                                          
                                       {(() => {
                                       if( jobShadowList.length == 0 ){                    
                                          return (<> <div class="text-center"><a class="carousel-button m-auto  mt-3 text-uppercase text-cente d-block" style={{width:'250px'}} href="/employerDirectory">Explore local company's </a></div></>);
                                       }                                  
                                       })()}   


                                          {
                                             jobShadowList.map((data, index) => (
                                                <div className="col-md-4">




                                                   <a href={"/companyDetails?companyId=" + data.companyId}>
                                                      <div class="card p-3" style={{ minHeight: "230px" }}>


                                                         <a class="tool tool-pos-ab" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="This company provides job shadow opportunities">
                                                            {data.jobShadowConcert == 'Y' ? <div class="js-badge"> <img src="images/badge.png" alt="" class="w-100" /></div> : ''}
                                                         </a>
                                                         <img src={data.companyLogo?data.companyLogo:staticData.default_logo } class="stock-logo" alt=""/>
                                                         

                                                         {/* <img src={data.logo_URL} class="stock-logo" alt=""/> */}
                                                         <div class="text-center stock-wrap">
                                                            <h3>{data.name}</h3>
                                                            {/* <span>6783 Steager Rd<br/>Celina, OH, 45822</span> */}
                                                            {/* <a href="jobsByCompany?companyId=382">2 OPEN JOBS</a> */}
                                                         </div>
                                                      </div>
                                                   </a>
                                                </div>
                                             ))
                                          }



                                          <div class="pagination-wrap text-center mt-5 fvpaginationContent">
                                             <Pagination
                                                total={jstotalItems}
                                                itemsPerPage={JSITEMS_PER_PAGE}
                                                currentPage={jscurrentPage}
                                                onPageChange={jshandlePageChange2} />
                                          </div>
                                       </div>

                                    </div>


                                 </div>



                                 <div class="card p-3 mt-5">
                                    <div class="announce-wrap">

                                       <div class="orange-ribbon d-flex mb-4">
                                          <img src="images/heart-icon.png" alt="" class="mr-2" />
                                          <span>YOUR FAVORITE COMPANIES</span>
                                       </div>

                                       

                                             {(() => {
                                             if( favVideoList.length == 0 ){                    
                                                return (<> <div class="text-center"><a class="carousel-button m-auto  mt-3 text-uppercase text-cente d-block" style={{width:'250px'}} href="/videoLibrary">Checkout Our video library</a></div></>);
                                             }                                  
                                             })()}         

                                       {
                                          favVideoList.map((data, index) => (
                                             <div class="align-items-start">
                                                {(data.videoUrl != '' && data.videoUrl != null || 1==1) ?
                                                   <div class="video-section2 mb-4">
                                                      {/* <div class="video-item"> <img src="images/screen-shot.png" class="w-100" alt=""/></div> */}
                                                      
                                                      
                                                      {/* {(() => {
                                                      if( data.videoUrl != "" && data.videoUrl != null ){                    
                                                         return (<><div class="video-item"><iframe width="100%" height="100%" src={getEmbedVideo(data.videoUrl)} allowfullscreen="allowfullscreen"/></div></>);
                                                      }else{
                                                         return (<><div class="video-item border d-flex justify-content-center align-items-center">{(data.logoUrl == null || data.logoUrl == "") ? <img src="https://snv3-dev-fs.s3.us-east-2.amazonaws.com/HometownMigration/uploads/default-logo.png" class="stock-logo" alt="" /> : <img src={data.logoUrl} class="stock-logo" alt="" />}</div></>);
                                                      }                                  
                                                      })()}   */}


{(() => {
                                                      if( data.companyMiniVideosVO.length==0 ){                    
                                                         return (<><div class="video-item border d-flex justify-content-center align-items-center">
                                                            <img src={data.logoUrl?data.logoUrl:staticData.default_logo } class="stock-logo" alt=""/>
                                                            </div></>);
                                                      }                               
                                                      })()}  


                                                   <div id={"carouselExampleIndicators" + index} 
                                                   class={data.companyMiniVideosVO.length>0?"carousel slide vid-gal":"carousel slide vid-gal dis_none"} 
                                                    data-bs-ride="carousel">


                                                                                                                                                   


                                                   <div class="carousel-inner" data-bs-target={"#carouselExampleIndicators" + index}>
                                                      {
                                                         data.companyMiniVideosVO.map((data3, index3) => (

                                                            <div class={index3 == 0 ? "carousel-item active" : "carousel-item "}>

                                                               {/* <iframe width="100%" height="100%" src={data.companyMiniVideosVO[index3].url} allowfullscreen="allowfullscreen" /> */}
                                                               <ReactPlayer url={data.companyMiniVideosVO[index3].url} controls="true" width="100%" height="100%" />
                                                            </div>


                                                         ))}
                                                   </div>




                                                   <button 
                                                   class={data.companyMiniVideosVO.length>1?"carousel-control-prev":"carousel-control-prev dis_none"} type="button" href={"#carouselExampleIndicators" + index} data-bs-target={"#carouselExampleIndicators" + index} data-bs-slide="prev">
                                                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                      <span class="visually-hidden">Previous</span>
                                                   </button>
                                                   <button
                                                   class={data.companyMiniVideosVO.length>1?"carousel-control-next":"carousel-control-next dis_none"}
                                                   type="button" href={"#carouselExampleIndicators" + index} data-bs-target={"#carouselExampleIndicators" + index} data-bs-slide="next">
                                                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                      <span class="visually-hidden">Next</span>
                                                   </button>
                                                   </div>

                                                      {/* <div class="video-item"><iframe width="100%" height="100%" src={data.videoUrl} /></div> */}
                                                      <div class="info-vid position-relative align-self-start">
                                                         <h4><a href={`/companyDetails?companyId=` + data.companyId}>
                                                           
                                                            {`${data.name.substring(0, 35)}`}{data.name.length>35?"...":""}
                                                            </a>
                                                            <a class="tool tool-pos-ab" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="This company provides job shadow opportunities">
                                                               {data.jobShadowConcert == 'Y' ? <div class="js-badge"> <img src="images/badge.png" alt="" style={{ maxWidth: '40px' }} /></div> : ''}
                                                            </a>
                                                         </h4>

                                                         <span> 
                                                         {data.address1 != '' ? data.address1 : '' }
                                                         {data.address1 != '' ? ', ' : ''}
                                                         {data.address2 != '' ? data.address2 : '' }<br></br>
                                                         {data.city != '' ? data.city : '' } 
                                                         {data.city != '' && data.state != '' ? ', ' : '' }{data.state != '' ? data.state : ''}
                                                         </span>
                                                         <div class="stock-wrap justify-content-between align-items-center">
                                                            <a href={`/jobsByCompany?companyId=` + data.companyId}>{data.jobCount} OPEN JOBS</a>
                                                         </div>
                                                      </div>
                                                      {/* <div class="vid-close d-flex align-items-center">
                                                <a href="#"> <img src="images/close.jpg" alt=""/></a>
                                             </div> */}
                                                   </div>
                                                   : ''}

                                             </div>

                                          ))}

                                       <div class="pagination-wrap text-center mt-5 fvpaginationContent">
                                          <Pagination
                                             total={fvtotalItems}
                                             itemsPerPage={FVITEMS_PER_PAGE}
                                             currentPage={fvcurrentPage}
                                             onPageChange={fvhandlePageChange2} />
                                       </div>



                                    </div>


                                 </div>


                              </div>
                           </div>
                        </div>

                     </div>

                  </div>
               </div>
            </section>
            <section class="white-bg py-5">
               <div class="container">
                  <Hiring />

               </div>
            </section>
         </main>

      </>
   )
};

StudentDashboard.propTypes = {};

StudentDashboard.defaultProps = {};

export default StudentDashboard;
