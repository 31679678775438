import React from "react";



const Footer = () => {
    const staticData = require('../../constants/'+process.env.React_App_Static_Folder+'/static-data.json');
    
  return (
   <>
    <footer class="cd-desktop">
         <div class="container">
            <div class="row">
               <div class="col-lg-5">
                  <h4>About {staticData.site_display_name}</h4>
                  <p>{staticData.about_site}</p>
               </div>
               <div class="col-lg-3">
                  <h4>Find it Fast</h4>
                  <ul class="footer-link">
                     <li> <a href="/industries">Industries </a></li>
                     <li> <a href="/availableJobs">Careers </a></li>
                     <li> <a href="/postResume">Resumes </a></li>
                     <li> <a href="/studentRegistrationPersonalInfo">Students </a></li>
                     <li> <a href="/aboutus">About Us </a></li>
                     <li> <a href="/contactus">Contact Us </a></li>
                     <li> <a href="/privacyPolicy">Privacy Policy</a></li>
                  </ul>
               </div>
               <div class="col-lg-4">
                  {/* <img src="/images/hta-company-logo.png" class="footer-logo" alt=""/> */}
                  <img src={staticData.company_logo} alt="" class="footer-logo" />
                  <div class="social">
                     <ul>
                        <li> <a href={staticData.social_links.fb_page} target="_blank"><img src="/images/facebook.png" alt=""/></a> </li>
                        <li> <a href={staticData.social_links.twitter_page} target="_blank"><img src="/images/twitter.png" alt=""/></a> </li>
                        <li> <a href={staticData.social_links.linkedin_page} target="_blank"><img src="/images/linkedin.png" alt=""/></a> </li>
                        <li> <a href={staticData.social_links.youtube_page} target="_blank"><img src="/images/youtube.png" alt=""/></a> </li>
                        {/* <li> <a href="#"><img src="images/email.png" alt=""/></a> </li> */}
                        <li> <a href={staticData.social_links.insta_page} target="_blank"><img src="/images/insta.png" alt=""/></a> </li>
                     </ul>
                  </div>
                   <div class="app-icons">
                   <h4>Download Our APP</h4>
                     <a href="https://apps.apple.com/us/app/hometown-opportunity-hto/id6449290101" target="_blank"><img src="images/ios-app.png" alt="" style={{width:'130px', marginRight:"10px"}}/></a>
                     <a href="https://play.google.com/store/apps/details?id=com.hto" target="_blank"><img src="images/google-app.png" alt="" style={{width:'130px'}}/></a>
                  </div> 
               </div>
               <div class="col-12 mt-4 text-center" >
                    <p>©2024 {staticData.site_display_name}. All Rights Reserved. Site Design by <a href="https://smallnationstrong.com/" target="_blank" class="footer-sn-lnk">SMALL NATION</a></p>
                </div>
            </div>
         </div>
      </footer>


<footer class="cd-mobile py-5">
<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            {/* <img src="/images/hta-company-logo.png" class="footer-logo" alt=""/> */}
            <img src={staticData.company_logo} alt="" class="footer-logo" />

            <div class="social text-center m-auto justify-content-center ">
                <ul>
                    <li>
                        <a href={staticData.social_links.fb_page} target="_blank"><img src="/images/facebook.png" alt=""/></a>
                    </li>
                    <li>
                        <a href={staticData.social_links.linkedin_page} target="_blank"><img src="/images/twitter.png" alt=""/></a>
                    </li>
                    <li>
                        <a href={staticData.social_links.fb_page} target="_blank"><img src="/images/linkedin.png" alt=""/></a>
                    </li>
                    <li>
                        <a href={staticData.social_links.youtube_page} target="_blank"><img src="/images/youtube.png" alt=""/></a>
                    </li>
                    <li>
                        {/* <a href="https://plus.google.com/u/0/102780503099775611423"><img src="images/email.png" alt=""/></a> */}
                    </li>
                    <li>
                        <a href={staticData.social_links.insta_page} target="_blank"><img src="/images/insta.png" alt=""/></a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-12 text-center my-4">
            <h4>Find it Fast</h4>
            <ul class="footer-link">
                <li> <a href="/industries">Industries </a></li>
                <li> <a href="/availableJobs">Careers </a></li>
                <li> <a href="/postResume">Resumes </a></li>
                <li> <a href="/studentRegistrationPersonalInfo">Students </a></li>
                <li> <a href="/aboutus">About Us </a></li>
                <li> <a href="/contactus">Contact Us </a></li>
                <li> <a href="/privacyPolicy">Privacy Policy</a></li>
            </ul>
        </div>
        <div class="app-icons">
                   <h4>Download Our APP</h4>
                   <div class="appicons">
                     <a href="https://apps.apple.com/us/app/hometown-opportunity-hto/id6449290101" target="_blank"><img src="images/ios-app.png" alt="" style={{width:'130px', marginRight:"10px"}}/></a>
                     <a href="https://play.google.com/store/apps/details?id=com.hto" target="_blank"><img src="images/google-app.png" alt="" style={{width:'130px'}}/></a>
                   </div>
                     
                  </div>
        <div class="col-12 m-auto text-center">
            <p>©2024 {staticData.site_display_name}. All Rights Reserved. Site Design by <a href="#" class="footer-sn-lnk">SMALL NATION</a></p>
        </div>
    </div>
</div>
</footer>
</>
  );
};

export default Footer;
