import React,{history,useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
const Forgotpassword = () => 
{
   const [itemName, setFieldName] = useState('');
   const {
     register,
     formState: { errors },
     handleSubmit
   } = useForm({
     criteriaMode: "all",mode: "onBlur" 
   });
   const submitValue=()=>
   {
     
   }

   useEffect(() => {

      const jsonData = {
          "forgotPwd": {
            
          }
        };
        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);

      }, []);
  
  return(
  <>
<main  class="empr-directory-main">
         {/* <!-- <div class="main-bgpatten"></div> --> */}
      
         {/* <!-- info typography --> */}
         <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                       <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="#">Password Reset </a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
         <section class="login-wrap py-4 ">
            <div class="container">
               <div class="row">
                  <div class="col-lg-5 col-md-10 col-sm-12 m-auto">
                  <form onSubmit={handleSubmit(submitValue)}>
                     <div class="white-bg px-4 pt-2 pb-2">

                        <div class="row mt-4 resume-wrap">
                           <div class="col-lg-12 mb-3">
                              <label for="firstName" class="form-label">Emai Address </label>
                              <input type="text" class="form-control" id="firstName" onChange={e => setFieldName(e.target.value)} {...register("Email", {
                                    required: "Email is required.",
                                   
                                    pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "invalid email address"
                                    }
                                    })}/>
                                 <ErrorMessage
                                    errors={errors}
                                    name="Email"
                                    class="invalid-feedback"
                                    render={({ messages }) => {
                                    console.log("messages", messages);
                                    return messages
                                        ? Object.entries(messages).map(([type, message]) => (
                                            <p key={type}  class="error">{message}</p>
                                        ))
                                        : null;
                                    }}
                                /> 
                           </div>
                         
                     
                           <div class="col-lg-12 mb-1">
                              <button type="submit" class="login-button-pg">Reset Password</button>
                           </div>

                        </div>
                     </div>
                   </form>
                  </div>
               </div>
            </div>
         </section>



      </main>
  </>
)};

Forgotpassword.propTypes = {};

Forgotpassword.defaultProps = {};

export default Forgotpassword;
