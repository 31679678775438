import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams, useLocation, useHistory, Link } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import moment from "moment";
import UsNumberValidate from '../../utility/usNumberValidate';
import Services from "../../services/services";
//import PaginationComponent from "../../utility/Pagination/Pagination";
import Pagination from "../../utility/Pagination/Pagination";
const CareerExploreJobs = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const catInput = queryParams.get('cat');

    const companyIdChkLogin = sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin = sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin = sessionStorage.getItem("logProfileId");
    const userIdChkLogin = sessionStorage.getItem("logUserId");
    const chKRoleAr = sessionStorage.getItem("roleAr");

    const navigate = useNavigate();
    const [categories, setCategory] = useState([]);
    const [profileList, setProfileList] = useState([]);
    const [jobList, setJobList] = useState([]);
    const [jobList_m, setJobList_m] = useState([]);

    const [jobTypes, setJobType] = useState([]);
    const [itemName, setFieldName] = useState('');
    const ITEMS_PER_PAGE = 10; // Per page show the data pagination
    const Pagesize = 5;
    const siteID = process.env.React_App_SITE_ID;//2;

    const [totalItems, setTotalItems] = useState(0);
    const [educationData, setEducations] = useState([]);

    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const [totalItems_m, setTotalItems_m] = useState(0);
    const [totalpages_m, settotalPages_m] = useState();
    const [currentPage_m, setCurrentPage_m] = useState(1);

    const [locationCounty, setLocationCounty] = useState([]);
	const staticData = require('../../constants/'+process.env.React_App_Static_Folder+'/static-data.json');
    var ejpagenum = queryParams.get('pageid');
    var session_pageobj = '';
    var webfilters = 1;
    var mobfilters = 1;
    const cardsloop = [1,2,3,4,5,6,7,8,9,10];

    const joinJsxArray2 = (arr, joinWith) => {
        if (!arr || arr.length < 2) { return arr; }

        const out = [arr[0]];
        for (let i = 1; i < arr.length; i += 1) {
            out.push(joinWith, arr[i]);
        }
        return out;
    };

    const joinJsxArray = (arr, joinWith) => {
        let chk = Array.isArray(arr);
        if (chk) {
            let text = arr.join(", ");
            return text;
        } else {
            return "";
        }

    };
    useEffect(() => {
        $('.doublecards').show();
        if (userIdChkLogin == null || userIdChkLogin == "null") {
            //navigate('/login');
            navigate('/Home');
        }
        
        // Getting the keys of the JSON object
        var keys = sessionStorage.getItem('pagejsonObject');
        if(keys == null || keys == undefined){
            navigate('/Home');
        } else {
        var keys_parsed = JSON.parse(keys);
        var pageobject = Object.keys(keys_parsed);
        // Checking if there is at least one key
        if (pageobject.length > 0) {
            // Accessing the first key
            var firstKey = pageobject[0];
            if(firstKey == 'careerExploreJobs'){
                var pageobj = keys_parsed[firstKey];
                session_pageobj = keys_parsed[firstKey];
                var dev_poff = pageobj['pagination_val'];
                var dev_poff_m = pageobj['pagination_val_m'];
                if(dev_poff == ''){
                    if(dev_poff_m == ''){
                        ejpagenum = '1';
                    }
                    else {
                        ejpagenum = dev_poff_m; 
                    } 
                }
                else{
                    ejpagenum = dev_poff; 
                }
            } else {
                ejpagenum = '1';
                sessionStorage.removeItem('master_industry');
                sessionStorage.removeItem('master_jobtype');
                sessionStorage.removeItem('master_education');
                sessionStorage.removeItem('master_locationcounty');
            }
        } else {
            //json empty
            ejpagenum = '1';
            sessionStorage.removeItem('master_industry');
            sessionStorage.removeItem('master_jobtype');
            sessionStorage.removeItem('master_education');
            sessionStorage.removeItem('master_locationcounty');
        }
    }

        console.log(ejpagenum);
        if (ejpagenum == null || ejpagenum == '') {

            var web_searchtxt = session_pageobj['search_text'];
            var web_jobtype = session_pageobj['jobtype'];
            var web_industry = session_pageobj['industry'];
            var web_education = session_pageobj['education'];
            if(web_searchtxt == '' && web_education == '' && web_industry == '' && web_jobtype == ''){
                var webfilters = 0;
            }

            var mob_searchtxt = session_pageobj['search_text_m'];
            var mob_jobtype = session_pageobj['jobtype_m'];
            var mob_industry = session_pageobj['industry_m'];
            var mob_education = session_pageobj['education_m'];
            if(mob_searchtxt == '' && mob_education == '' && mob_industry == '' && mob_jobtype == ''){
                var mobfilters = 0;
            }

            if(webfilters == 0 && mobfilters == 0){
            const jsonData = {
                "careerExploreJobs": {
                    "industry": '',
                    "education": '',
                    "jobtype": '',
                    "search_text": "",
                    "pagination_val": '',
                    "industry_m": '',
                    "education_m": '',
                    "jobtype_m": '',
                    "search_text_m": "",
                    "pagination_val_m": '',
                }
              };
    
            var jsondataformatted = JSON.stringify(jsonData);
            sessionStorage.setItem('pagejsonObject', jsondataformatted);
            }
        }

        //getIndustryData();
        //getJobType();
        //getLocationCounty();
        //getEdicationInfo();

        var ind_master = sessionStorage.getItem('master_industry');
        if(ind_master == null || ind_master == ''){
            getServiceIndustryData();
        }
        else {
            getLocalIndustryData();
        }

        var jobtype_master = sessionStorage.getItem('master_jobtype');
        if(jobtype_master == null || jobtype_master == ''){
            getServiceJobType();
        }
        else {
            getLocalJobType();
        }

        var education_master = sessionStorage.getItem('master_education');
        if(education_master == null || education_master == ''){
            getServiceEdicationInfo();
        }
        else {
            getLocalEdicationInfo();
        }

        var locationcountylist = sessionStorage.getItem('master_locationcounty');
        if(locationcountylist == null || locationcountylist == ''){
            getServiceLocationCounty();
        }
        else {
            getLocalLocationCounty();
        }


        //var t_ej_search_text = localStorage.getItem('ej_search_text');
        var t_ej_search_text = session_pageobj['search_text'];
        $(".searchKeyInput").val(t_ej_search_text);

        // var t_ej_search_text_m = localStorage.getItem('ej_search_text_m');
        var t_ej_search_text_m = session_pageobj['search_text_m'];
        $(".searchKeyInput_mobile").val(t_ej_search_text_m);


        $(document).on("click", ".searchContent", function () {
            filterContent(ITEMS_PER_PAGE, '1', '1','0');
        });

        $(document).on("change", ".searchKeyInput", function () {
            filterContent(ITEMS_PER_PAGE, '1', '1','0');
        });

        $(document).on("click", ".searchContent_mobile", function () {
            filterContent_mobile(ITEMS_PER_PAGE, '1', '1','0');
        });

        $(document).on("change", ".searchKeyInput_mobile", function () {
            filterContent_mobile(ITEMS_PER_PAGE, '1', '1','0');
        });



        $(".paginationContent").hide();
        setTimeout(() => {
            setIndustryCheck(catInput);
            setTimeout(() => {
                if (ejpagenum != null && ejpagenum != "") {
                    // var web_ind = localStorage.getItem('ej_industry_selected');
                    // var web_edu = localStorage.getItem('ej_education_selected');
                    // var web_typ = localStorage.getItem('ej_jobtype_selected');
                    // var web_src = localStorage.getItem('ej_search_text');

                    var web_ind = session_pageobj['industry'];
                    var web_edu = session_pageobj['education'];
                    var web_typ = session_pageobj['jobtype'];
                    var web_src = session_pageobj['search_text'];
                    var pageoffset = session_pageobj['pagination_val'];

                    if (web_ind != "" || web_edu != '' || web_typ != '' || web_src != '' || pageoffset != ''){
                        setTimeout(() => {
                            filterContent(ITEMS_PER_PAGE, ejpagenum, '0','0');
                        }, 500);
                    }
                    else {

                        // var mob_ind = localStorage.getItem('ej_industry_selected_m');
                        // var mob_edu = localStorage.getItem('ej_education_selected_m');
                        // var mob_typ = localStorage.getItem('ej_jobtype_selected_m');
                        // var mob_src = localStorage.getItem('ej_search_text_m');

                        var mob_ind = session_pageobj['industry_m'];
                        var mob_edu = session_pageobj['education_m'];
                        var mob_typ = session_pageobj['jobtype_m'];
                        var mob_src = session_pageobj['search_text_m'];
                        var pageoffset_m = session_pageobj['pagination_val_m'];

                        if (mob_ind != "" || mob_edu != '' || mob_typ != '' || mob_src != '' || pageoffset_m != ''){
                            setTimeout(() => {
                                filterContent_mobile(ITEMS_PER_PAGE, ejpagenum, '0','0');
                            }, 500);
                        }
                        else {
                            filterContent(ITEMS_PER_PAGE, ejpagenum, '0','0');
                            filterContent_mobile(ITEMS_PER_PAGE, ejpagenum, '0','0');
                        }
                    }
                }else{
                    filterContent(ITEMS_PER_PAGE, '1', '0','0');
                    filterContent_mobile(ITEMS_PER_PAGE, '1', '0','0');
                }
            }, 500);
        }, 500);
    }, []);

    function industrySelCheck(local_indarray) {
        console.log('*** 143 ************ ' + local_indarray);
        var companyCatIn = local_indarray.split(",");
        console.log('*** 145 ************ ' + companyCatIn);
        for (var i = 0; i < companyCatIn.length; i++) {
            //$("input[value='" + val + "']").prop('checked', true);
            console.log("###" + companyCatIn[i]);
            $('input[name="categoryind"][value="' + companyCatIn[i] + '"]').prop("checked", true);
            $('input[name="category_m"][value="' + companyCatIn[i] + '"]').prop("checked", true);
            //$('input[name="categoryind"][value="' + companyCatIn[i] + '"]').trigger("click");
        }

    }

    const setIndustryCheck = (catInput) => {
        console.log(catInput);
        if (catInput) {
            var companyCatIn = catInput.split(",");
            for (var i = 0; i < companyCatIn.length; i++) {
                //$("input[value='" + val + "']").prop('checked', true);
                console.log("###" + companyCatIn[i]);
                $('input[name="categoryind"][value="' + companyCatIn[i] + '"]').prop("checked", true);
                $('input[name="category_m"][value="' + companyCatIn[i] + '"]').prop("checked", true);
                //$('input[name="category"][value="' + companyCatIn[i] + '"]').trigger("click");
            }
        }

    }

    /*const getCountyNameById = (countyId) => {
        console.log(locationCounty);
        return locationCounty[countyId];
    } */

    function getCountyNameById(countyId) {
        if (countyId != '' && countyId != null) {
            var temp_seeking = '';

            for (var i = 0; i < locationCounty.length; i++) {
                if (locationCounty[i]['jobLocationCountyId'] == countyId) {
                    temp_seeking = locationCounty[i].name;

                }
            }

            return temp_seeking;
        } else {
            return "";
        }
    }

    const getServiceLocationCounty = () => {
        Services.getLocationCounty().then((res) => {
            console.log("setJobType data=" + JSON.stringify(res.data));
            setLocationCounty(res.data.content);
            var serviceresponse = res.data.content;
            var storetypeval = JSON.stringify(serviceresponse);
            sessionStorage.setItem('master_locationcounty', storetypeval);
        });
    }

    const getLocalLocationCounty = () => {
        var locallocationcountyval = sessionStorage.getItem('master_locationcounty');
        locallocationcountyval = JSON.parse(locallocationcountyval);
        setLocationCounty(locallocationcountyval);
    }

    const handlePageChange2 = (offset, triggerevent) => {
        // alert(offset);
        filterContent(ITEMS_PER_PAGE, offset, '0','1');
    };

    const handlePageChange2_mobile = (offset, triggerevent) => {
        // alert(offset);
        filterContent_mobile(ITEMS_PER_PAGE, offset, '0','1');
    };



    const filterContent_mobile = (ITEMS_PER_PAGE, pageNumber, setlcstorage, positiontop) => {
        var categoriesArray = [];

        $.each($("input[name='category_m']:checked"), function () {
            categoriesArray.push($(this).val());
        });

        var jobTypeArray = [];
        $.each($("input[name='jobType_mobile']:checked"), function () {
            jobTypeArray.push($(this).val());
        });

        var levelofEduArr = [];
        $.each($("input[name='educationId_mobile']:checked"), function () {
            levelofEduArr.push($(this).val());
        });

        var searchkey = $('.searchKeyInput_mobile').val();

        if (setlcstorage == "1") {
            var industries_val2 = '';
            console.log("###244###" + typeof (categoriesArray))
            console.log(categoriesArray)
            if (categoriesArray != '') {
                try {
                    industries_val2 = JSON.parse(categoriesArray);
                } catch (ex) {
                    industries_val2 = Object.values(categoriesArray).join(",");
                }

            }

            var education_val2 = '';
            console.log('** 246 ***** ' + levelofEduArr);
            if (levelofEduArr != '') {
                education_val2 = JSON.parse(levelofEduArr);
            }

            var jobtype_val2 = '';
            if (jobTypeArray != '') {
                jobtype_val2 = JSON.parse(jobTypeArray);
            }

            // localStorage.setItem('ej_industry_selected_m', industries_val2);
            // localStorage.setItem('ej_education_selected_m', education_val2);
            // localStorage.setItem('ej_jobtype_selected_m', jobtype_val2);
            // localStorage.setItem('ej_search_text_m', searchkey);
        }
        else {
            /*localStorage.setItem('av_search_text', '');
            localStorage.setItem('av_location_selected', '');
            localStorage.setItem('av_education_selected', '');
            localStorage.setItem('av_jobtype_selected', '');
            localStorage.setItem('av_industry_selected', ''); */
        }

        var industries_val2_m = '';
            if (categoriesArray != '') {
                try {
                    industries_val2_m = JSON.parse(categoriesArray);
                } catch (ex) {
                    industries_val2_m = Object.values(categoriesArray).join(",");
                }
            }

            var education_val2_m = '';
            if (levelofEduArr != '') {
                education_val2_m = levelofEduArr;
            }

            var jobtype_val2_m = '';
            if (jobTypeArray != '') {
                jobtype_val2_m = jobTypeArray;
            }

            if(pageNumber > 1){
                var jsonData = {
                    "careerExploreJobs": {
                        "industry": '',
                        "education": '',
                        "jobtype": '',
                        "search_text": "",
                        "pagination_val": '',
                        "industry_m": industries_val2_m,
                        "education_m": education_val2_m,
                        "jobtype_m": jobtype_val2_m,
                        "search_text_m": searchkey,
                        "pagination_val_m": pageNumber,
                    }
                  };
            } else {
                var jsonData = {
                    "careerExploreJobs": {
                        "industry": '',
                        "education": '',
                        "jobtype": '',
                        "search_text": "",
                        "pagination_val": '',
                        "industry_m": industries_val2_m,
                        "education_m": education_val2_m,
                        "jobtype_m": jobtype_val2_m,
                        "search_text_m": searchkey,
                        "pagination_val_m": '',
                    }
                  };
            }
        

        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);

        var titlesummaryval = $(".searchKeyInput_mobile").val();
        if(titlesummaryval != undefined){
            titlesummaryval = titlesummaryval.trim();
        }
        const requestObject = {
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "type": jobTypeArray,
            "levelOfEducation": levelofEduArr,
            "titleSummary": titlesummaryval,
            "pageNumber": pageNumber,
            // "pageSize":"3",
            "pageSize": ITEMS_PER_PAGE,
            "sortBy": "updatedOn"
        }
        /*
      const requestObject_new = {
        "jobCategory": categoriesArray,
        "jobLocation": locationArray,
        "jobEducation": educationArray,
        "jobType": jobtypeArray,
        "pageSize": ITEMS_PER_PAGE,
        "pageNumber": pageNumber,
        "sortBy": "updatedOn",
        "sortDir": "asc",
        "siteId":siteID

    }'
    */

        var accessTokenIn = sessionStorage.getItem("accToken");
        console.log(requestObject);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn
                //'Authorization': accessTokenIn
            },
            body: JSON.stringify(requestObject)
        };
        $(".doublecards").show();
        Services.findFilterJob(requestOptions).then((res) => {
            console.log(JSON.stringify(res));
            if (res) {
                $(".paginationContent").show();
                if(positiontop == 1){
                    window.scrollTo({ top: 100, behavior: 'smooth' });
                }
                setTotalItems_m(res.totalElements);
                settotalPages_m(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                setCurrentPage_m(pageNumber);

                //var contAr = res.content;
                //setProfileList(res.content);
                if (res.content) {
                    var contAr = res.content;
                   
                    for (var i = 0; i < contAr.length; i++) {
                        var jobtypename = contAr[i].strJobType;
                       var typename = jobtypename.split("(");
                       contAr[i].jobtypename_new = typename[0];
                       console.log(contAr[i].strJobType+' -- '+contAr[i].jobtypename_new);
                    } 
                    setJobList_m(contAr);

                    if (contAr.length > 0) {
                        $(".paginationContent").show();
                    } else {
                        $(".paginationContent").hide();
                    }
                }
            }


        });
        $(".doublecards").hide();


    }

    const filterContent = (ITEMS_PER_PAGE, pageNumber, setlcstorage, positiontop) => {
        var categoriesArray = [];

        $.each($("input[name='categoryind']:checked"), function () {
            categoriesArray.push($(this).val());
        });

        var jobTypeArray = [];
        $.each($("input[name='jobType']:checked"), function () {
            jobTypeArray.push($(this).val());
        });

        var levelofEduArr = [];
        $.each($("input[name='educationIdArr']:checked"), function () {
            levelofEduArr.push($(this).val());
        });

        var searchkey = $('.searchKeyInput').val();

        if (setlcstorage == "1") {
            var industries_val2 = '';
            if (categoriesArray != '') {
                //industries_val2 = JSON.parse(categoriesArray);
                industries_val2 = categoriesArray;
            }

            var education_val2 = '';
            if (levelofEduArr != '') {
                education_val2 = JSON.parse(levelofEduArr);
            }

            var jobtype_val2 = '';
            if (jobTypeArray != '') {
                jobtype_val2 = jobTypeArray;
            }

            // localStorage.setItem('ej_industry_selected', industries_val2);
            // localStorage.setItem('ej_education_selected', education_val2);
            // localStorage.setItem('ej_jobtype_selected', jobtype_val2);
            // localStorage.setItem('ej_search_text', searchkey);
        }
        else {
            /*localStorage.setItem('av_search_text', '');
            localStorage.setItem('av_location_selected', '');
            localStorage.setItem('av_education_selected', '');
            localStorage.setItem('av_jobtype_selected', '');
            localStorage.setItem('av_industry_selected', ''); */
        }
        var industries_val2_desk = '';
            if (categoriesArray != '') {
                industries_val2_desk = categoriesArray;
            }

            var education_val2_desk = '';
            if (levelofEduArr != '') {
                education_val2_desk = levelofEduArr;
            }

            var jobtype_val2_desk = '';
            if (jobTypeArray != '') {
                jobtype_val2_desk = jobTypeArray;
            }

            if(pageNumber > 1){
                var jsonData = {
                    "careerExploreJobs": {
                        "industry": industries_val2_desk,
                        "education": education_val2_desk,
                        "jobtype": jobtype_val2_desk,
                        "search_text": searchkey,
                        "pagination_val": pageNumber,
                        "industry_m": '',
                        "education_m": '',
                        "jobtype_m": '',
                        "search_text_m": '',
                        "pagination_val_m": '',
                    }
                  };
            }
            else {
                var jsonData = {
                    "careerExploreJobs": {
                        "industry": industries_val2_desk,
                        "education": education_val2_desk,
                        "jobtype": jobtype_val2_desk,
                        "search_text": searchkey,
                        "pagination_val": '',
                        "industry_m": '',
                        "education_m": '',
                        "jobtype_m": '',
                        "search_text_m": '',
                        "pagination_val_m": '',
                    }
                  };
            }

        

        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);

        var titlesummaryinfo = $(".searchKeyInput").val();
        if(titlesummaryinfo != undefined){
            titlesummaryinfo = titlesummaryinfo.trim();
        }
        const requestObject = {
            "siteId": process.env.React_App_SITE_ID,
            "categoriesOfExpertise": categoriesArray,
            "type": jobTypeArray,
            "levelOfEducation": levelofEduArr,
            "titleSummary": titlesummaryinfo,
            "pageNumber": pageNumber,
            // "pageSize":"3",
            "pageSize": ITEMS_PER_PAGE,
            "sortBy": "updatedOn"
        }
        /*
      const requestObject_new = {
        "jobCategory": categoriesArray,
        "jobLocation": locationArray,
        "jobEducation": educationArray,
        "jobType": jobtypeArray,
        "pageSize": ITEMS_PER_PAGE,
        "pageNumber": pageNumber,
        "sortBy": "updatedOn",
        "sortDir": "asc",
        "siteId":siteID

    }'
    */

        var accessTokenIn = sessionStorage.getItem("accToken");
        console.log(requestObject);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn
                //'Authorization': accessTokenIn
            },
            body: JSON.stringify(requestObject)
        };
        $(".doublecards").show();
        Services.findFilterJob(requestOptions).then((res) => {
            console.log(JSON.stringify(res));
            if (res) {
                $(".paginationContent").show();
                if(positiontop == 1){
                    window.scrollTo({ top: 100, behavior: 'smooth' });
                }
                setTotalItems(res.totalElements);
                settotalPages(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                setCurrentPage(pageNumber);

               // var contAr = res.content;
                //setProfileList(res.content);
                if (res.content) {
                    var contAr = res.content;
                   
                    for (var i = 0; i < contAr.length; i++) {
                        var jobtypename = contAr[i].strJobType;
                       var typename = jobtypename.split("(");
                       contAr[i].jobtypename_new = typename[0];
                       console.log(contAr[i].strJobType+' -- '+contAr[i].jobtypename_new);
                    } 
                    setJobList(contAr);

                    if (contAr.length > 0) {
                        $(".paginationContent").show();
                    } else {
                        $(".paginationContent").hide();
                    }
                }
            }


        });
        $(".doublecards").hide();


    }

    function industrySelCheckLS(local_indarray) {
        if(local_indarray != undefined || local_indarray != null){
            var local_indarray = local_indarray.toString();
            var companyCatIn = local_indarray.split(",");
            for (var i = 0; i < companyCatIn.length; i++) {
                $('input[name="categoryind"][value="' + companyCatIn[i] + '"]').prop("checked", true);
            }
        }
    }

    function industrySelCheckLS_m(local_indarray_m) {
        if(local_indarray_m != undefined || local_indarray_m != null){
            var local_indarray_m = local_indarray_m.toString();
            var companyCatIn_m = local_indarray_m.split(",");
            for (var i = 0; i < companyCatIn_m.length; i++) {
                $('input[name="category_m"][value="' + companyCatIn_m[i] + '"]').prop("checked", true);
            }
        }
    }

    function jobtypeSelCheckLS(local_jobtypearray) {
        if(local_jobtypearray != undefined || local_jobtypearray != null){
            var local_jobtypearray = local_jobtypearray.toString();
            var companyjobtype = local_jobtypearray.split(",");
            for (var i = 0; i < companyjobtype.length; i++) {
                $('input[name="jobType"][value="' + companyjobtype[i] + '"]').prop("checked", true);
            }
        }
    }

    function jobtypeSelCheckLS_m(local_jobtypearray_m) {
        if(local_jobtypearray_m != undefined || local_jobtypearray_m != null){
            var local_jobtypearray_m = local_jobtypearray_m.toString();
            var companyjobtype_m = local_jobtypearray_m.split(",");
            for (var i = 0; i < companyjobtype_m.length; i++) {
                $('input[name="jobType_mobile"][value="' + companyjobtype_m[i] + '"]').prop("checked", true);
            }
        }
    }

    function educationSelCheckLS(local_eduarray) {
        if(local_eduarray != undefined || local_eduarray != null){
            var local_eduarray = local_eduarray.toString();
            var companyeducation = local_eduarray.split(",");
            for (var i = 0; i < companyeducation.length; i++) {
                $('input[name="educationIdArr"][value="' + companyeducation[i] + '"]').prop("checked", true);
            }
        }
    }

    function educationSelCheckLS_m(local_eduarray_m) {
        if(local_eduarray_m != undefined || local_eduarray_m != null){
            var local_eduarray_m = local_eduarray_m.toString();
            var companyeducation_m = local_eduarray_m.split(",");
            for (var i = 0; i < companyeducation_m.length; i++) {
                $('input[name="educationId_mobile"][value="' + companyeducation_m[i] + '"]').prop("checked", true);
            }
        }
    }

    const getServiceIndustryData = () => {
        Services.getIndustryData().then((res) => {
            console.log("Industry data=" + JSON.stringify(res.data));
            setCategory(res.data.content);
            var serviceresponse = res.data.content;
            var storeval = JSON.stringify(serviceresponse);
            sessionStorage.setItem('master_industry', storeval);
        });
    }

    const getLocalIndustryData = () => {
        var localindustryval = sessionStorage.getItem('master_industry');
        localindustryval = JSON.parse(localindustryval);
        setCategory(localindustryval);
        loadselectedIndustries();
    }

    const loadselectedIndustries = () => {
        setTimeout(() => {
            //var loc_industryval = localStorage.getItem('ej_industry_selected');
            var loc_industryval = session_pageobj['industry'];
            industrySelCheckLS(loc_industryval);
            if(loc_industryval != ""){
               // filterContent(ITEMS_PER_PAGE, ejpagenum, '0');
            }

            //var loc_industryval_m = localStorage.getItem('ej_industry_selected_m');
            var loc_industryval_m = session_pageobj['industry_m'];
            industrySelCheckLS_m(loc_industryval_m);
            if(loc_industryval_m != ""){
              //  filterContent_mobile(ITEMS_PER_PAGE, ejpagenum, '0');
            }
        }, 500);
    }

    const getServiceJobType = () => {
        Services.getJobType().then((res) => {
            console.log("setJobType data=" + JSON.stringify(res.data));
            var tempjobtypes = res.data.content;
            var serviceresponse = res.data.content;
            tempjobtypes = tempjobtypes.filter(function(elem) {
                return elem.name !== 'Continuing Education';
            });

            var jobtype_unsort = tempjobtypes;
            var jobtypes_sorted = jobtype_unsort.sort((a, b) => b.jobTypeId - a.jobTypeId);
          array_move(jobtypes_sorted, 0, (jobtypes_sorted.length-1));
          jobtypes_sorted = jobtypes_sorted.filter(function (elem) {
            return elem.name !== 'Continuing Education';
          });

          var dummArray = [];
            for (var i = 0; i < jobtypes_sorted.length; i++) {
                var jobtypename = jobtypes_sorted[i].name;
               var typename = jobtypename.split("(");
                dummArray.push({ "name": typename[0], "jobTypeId": jobtypes_sorted[i].jobTypeId })
            } 
            //setJobType(jobtypes_sorted);
            setJobType(dummArray);
            var storetypeval = JSON.stringify(serviceresponse);
            sessionStorage.setItem('master_jobtype', storetypeval);
        });
        loadSelectedjobtypes();
    }

    const getLocalJobType = () => {
        var localjobtypeval = sessionStorage.getItem('master_jobtype');
        var tempjobtypes = JSON.parse(localjobtypeval);
        tempjobtypes = tempjobtypes.filter(function(elem) {
            return elem.name !== 'Continuing Education';
        });

        var jobtype_unsort = tempjobtypes;
        var jobtypes_sorted = jobtype_unsort.sort((a, b) => b.jobTypeId - a.jobTypeId);
      array_move(jobtypes_sorted, 0, (jobtypes_sorted.length-1));
      jobtypes_sorted = jobtypes_sorted.filter(function (elem) {
        return elem.name !== 'Continuing Education';
      });

      var dummArray = [];
        for (var i = 0; i < jobtypes_sorted.length; i++) {
            var jobtypename = jobtypes_sorted[i].name;
           var typename = jobtypename.split("(");
            dummArray.push({ "name": typename[0], "jobTypeId": jobtypes_sorted[i].jobTypeId })
        } 
        //setJobType(jobtypes_sorted);
        setJobType(dummArray);
        loadSelectedjobtypes();
    }

    const loadSelectedjobtypes = () => {
        setTimeout(() => {
            // var loc_jobtypeval = localStorage.getItem('ej_jobtype_selected');
             var loc_jobtypeval = session_pageobj['jobtype'];
             jobtypeSelCheckLS(loc_jobtypeval);
             if(loc_jobtypeval != ""){
               //  filterContent(ITEMS_PER_PAGE, ejpagenum, '0');
             }

             //var loc_jobtypeval_m = localStorage.getItem('ej_jobtype_selected_m');
             var loc_jobtypeval_m = session_pageobj['jobtype_m'];
             jobtypeSelCheckLS_m(loc_jobtypeval_m);
             if(loc_jobtypeval_m != ""){
                // filterContent_mobile(ITEMS_PER_PAGE, ejpagenum, '0');
             }
         }, 500);
    }

    function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

    const getServiceEdicationInfo = () => {
        Services.getEducation().then((res) => {
            //console.log("getEducation data=" + JSON.stringify(res.data));
            setEducations(res.data.content);
            var serviceresponse = res.data.content;
            var storetypeval = JSON.stringify(serviceresponse);
            sessionStorage.setItem('master_education', storetypeval);
        });
        loadselectedEducation();
    }

    const getLocalEdicationInfo = () => {
        var localeducationval = sessionStorage.getItem('master_education');
        var tempeducations = JSON.parse(localeducationval);
        setEducations(tempeducations);
        loadselectedEducation();
    }

    const loadselectedEducation = () => {
        setTimeout(() => {
            //var loc_educationval = localStorage.getItem('ej_education_selected');
            var loc_educationval = session_pageobj['education'];
            educationSelCheckLS(loc_educationval);
            if(loc_educationval != ""){
               // filterContent(ITEMS_PER_PAGE, ejpagenum, '0');
            }

            //var loc_educationval_m = localStorage.getItem('ej_education_selected_m');
            var loc_educationval_m = session_pageobj['education_m'];
            educationSelCheckLS_m(loc_educationval_m);
            if(loc_educationval_m != ""){
               // filterContent_mobile(ITEMS_PER_PAGE, ejpagenum, '0');
            }
        }, 500);
    }

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    const submitValue = () => {

    }

    return (
        <>
            <main class="empr-directory-main">
                <div class="main-bgpatten"></div>
                {/* <!-- info typography --> */}
                <section class="headtitle-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                                    <ol class="breadcrumb">




                                        {(() => {

                                            if (jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null") {
                                                return (<><li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li> </>);
                                            }

                                            if (studentIdChkLogin != null && studentIdChkLogin != "null") {
                                                return (<><li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li> </>);
                                            }

                                            if (chKRoleAr == "schooladmin" || chKRoleAr == "schoolsuper") {
                                                return (<><li class="breadcrumb-item headtitle"> <a href="/schoolAdminDashboard">School Admin</a> </li> </>);
                                            }

                                        })()}


                                        <li class="breadcrumb-item headtitle "><a href="#">Careers</a></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- info typography --> */}
                <section class="py-5 cd-desktop">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="white-bg p-5">
                                    <div class="row">
                                        {/* <!-- left side --> */}
                                        <div class="col-lg-4 mb-4">
                                            <div class="card p-3 mb-3 ">
                                                <div class="bg-heading">
                                                    <h3 class="mb-0">Filter Jobs</h3>
                                                </div>
                                                <div class="py-3 text-start">
                                                    <b class="text-uppercase">Key words</b>

                                                    <input class="form-control me-2 searchKeyInput" type="search" aria-label="Search" placeholder="" />
                                                    {/* <button class="border-0 p-0 dir-search" type="submit"><img src="images/search-icon.JPG" alt="" /></button> */}

                                                </div>
                                                <div class="mb-4">
                                                    <b class="text-uppercase mb-3 w-100">Categories of Interest</b>
                                                    <div class="category-check-itm mt-2">
                                                        <ul>
                                                            {
                                                                categories.map((data, index) => (


                                                                    <li><input class="form-check-input searchContent" type="checkbox" name="categoryind" value={data.catId} />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                                ))}
                                                            {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Accounting </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> CNC  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Advertising and PR Services  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Agriculture and Food Processing  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Architectural and Design Services  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Occupational Therapy  </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Auto/Diesel </label></li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="mb-4">
                                                    <b class="text-uppercase mb-3 w-100">Type</b>
                                                    <div class="category-check-itm mt-2">
                                                        <ul>

                                                            {
                                                                jobTypes.map((data, index) => (


                                                                    <li><input class="form-check-input searchContent" type="checkbox" name="jobType" value={data.jobTypeId} />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                                ))}
                                                            {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Community Service </label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">  Full Time</label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">  Internship/Coop</label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">  Job Shadow</label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">  Part Time</label></li>
                                                    <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">  Summer/Seasonal</label></li> */}
                                                        </ul>
                                                    </div>
                                                </div>



                                                <div class="mb-4">
                                                    <b class="text-uppercase mb-3 w-100">Education</b>
                                                    <div class="category-check-itm mt-2">
                                                        <ul>

                                                            {
                                                                educationData.map((data, index) => (
                                                                    <li><input class="form-check-input searchContent" type="checkbox" name="educationIdArr" value={data.educationId} id="educationId" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                                ))}

                                                        </ul>
                                                    </div>
                                                </div>

                                                <input type="hidden" id="ejindustry_selected" />
                                                <input type="hidden" id="ejeducation_selected" />
                                                <input type="hidden" id="ejjobtype_selected" />
                                            </div>
                                        </div>
                                        {/* <!-- right side --> */}
                                        <div class="col-lg-8">

                                            <div class="two-grid-wrap">
                                            {
                                                cardsloop.map((data, inde) => (
                                                    <div style={{"maxWidth": "600px"}} class="doublecards">
                                                    <div class="htl is-loading" style={{"gridTemplateColumns":"60px 1fr", "marginBottom":"0px", "paddingBottom":"0px"}}>
                                                        <div class="image" style={{"width":"60px","height":"60px","borderRadius":"100px"}}></div>
                                                        <div class="content">
                                                            <h2></h2>
                                                            <div class="spanl">
                                                                <span style={{"width":"80%", "height":"15px"}}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"0px"}}>
                                                        <span style={{"height":"3px","width":"90%","marginLeft":"auto","margin":"10px"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"100%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"90%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"70%"}}></span>
                                                    </div>
                                                </div>
                                            ))}

                                                {
                                                    jobList.map((data, index) => (
                                                        <>
                                                            <div class="grid-item p-3 position-relative">
                                                                <div class="grid-head-career">
                                                                    <div class="grid-img-company">
                                                                        <img src={data.logo_url ? data.logo_url : staticData.default_logo} class="w-100" alt="" />
                                                                    </div>
                                                                    <div class="grid-title-wrap">
                                                                        <h4 class="margin-bottom-0"> {`${data.title.substring(0, 40)}`}{data.title.length > 40 ? "..." : ""}</h4>
                                                                        <small class="d-flex">
                                                                            <span class="mr-2" style={{ minWidth: "25px" }}>By:</span>
                                                                            <span className="clamp"><a href={`/companyDetails?companyId=` + data.companyId}>{data.name}</a></span>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <div class="grid-body mb-4">
                                                                    <div class="grid-body-item charmin235 ">
                                                                        <div class="grid-body-inn">
                                                                            <span>Posted:</span>
                                                                            <span>{moment(data.createdDate).format("MM/DD/YYYY")}</span>
                                                                        </div>
                                                                        <div class="grid-body-inn">
                                                                            <span>Website:</span>
                                                                            <span><a target="_blank" href={data.website} class="red-text text-underline">View Website</a></span>
                                                                        </div>
                                                                        <div class="grid-body-inn">
                                                                            <span>Type:</span>
                                                                            <span>{data.jobtypename_new}</span>
                                                                        </div>
                                                                        <div class="grid-body-inn">
                                                                            <span>Industry:</span>
                                                                            {/* <span>Non-Profit, Healthcare/Medical</span> */}
                                                                            <span className="clamp">
                                                                            {joinJsxArray(data.jobCategoryName, ', ')}
                                                                            </span>
                                                                        </div>
                                                                        <div class="grid-body-inn">
                                                                            <span>County:</span>
                                                                            <span>{getCountyNameById(data.countyId)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="text-center grid-footer pp-footer">
                                                                    <Link to={`/jobsummary?jobId=${data.jobId}&ejpage=` + currentPage} class="reg-button m-auto d-block">View</Link>

                                                                    {/* <a href={`/jobsummary?jobId=${data.jobId}&ejpage=`+currentPage} class="reg-button m-auto d-block">View</a> */}
                                                                </div>
                                                            </div>

                                                        </>
                                                    ))
                                                }
                                            </div>
                                            <div class="pagination-wrap text-center mt-5 paginationContent">
                                                <Pagination
                                                    total={totalItems}
                                                    itemsPerPage={ITEMS_PER_PAGE}
                                                    currentPage={currentPage}
                                                    onPageChange={handlePageChange2} />
                                                {/* <Pagination items={this.state.exampleItems} onChangePage={this.onChangePage} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="py-3 cd-mobile">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="white-bg p-5">
                                    <div class="row">
                                        {/* <!-- right side --> */}
                                        <div class="col-lg-8 mb-4">
                                            <div class="border-top border-bottom py-2 d-flex justify-content-end mb-4">
                                                <span class="float-end ">
                                                    24 Profiles - Page 1 of 2
                                                </span>
                                            </div>
                                            <div class="two-grid-wrap">
                                            {
                                                cardsloop.map((data, inde) => (
                                                    <div style={{"maxWidth": "600px"}} class="doublecards">
                                                    <div class="htl is-loading" style={{"gridTemplateColumns":"60px 1fr", "marginBottom":"0px", "paddingBottom":"0px"}}>
                                                        <div class="image" style={{"width":"60px","height":"60px","borderRadius":"100px"}}></div>
                                                        <div class="content">
                                                            <h2></h2>
                                                            <div class="spanl">
                                                                <span style={{"width":"80%", "height":"15px"}}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"0px"}}>
                                                        <span style={{"height":"3px","width":"90%","marginLeft":"auto","margin":"10px"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"100%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"90%"}}></span>
                                                    </div>
    
                                                    <div class="htl is-loading" style={{"padding":"0px","margin":"10px 0px 20px 0px","gridTemplateColumns":"1fr 2fr","gap":"10px","maxWidth":"90%","marginLeft":"auto"}}>
                                                        <span style={{"width":"100%"}}></span>
                                                        <span style={{"width":"70%"}}></span>
                                                    </div>
                                                </div>
                                            ))}
                                            
                                                {
                                                    jobList_m.map((data, index) => (
                                                        <>
                                                            <div class="grid-item p-3">
                                                                <div class="grid-head-career">
                                                                    <div class="grid-img-company">
                                                                        <img src={data.logo_url ? data.logo_url : staticData.default_logo} class="w-100" alt="" />
                                                                    </div>
                                                                    <div class="grid-title-wrap">
                                                                        <h4 class="margin-bottom-0">
                                                                            {`${data.title.substring(0, 40)}`}{data.title.length > 40 ? "..." : ""}
                                                                        </h4>
                                                                        <small class="d-flex">
                                                                            <span class="mr-2" style={{ minWidth: "25px" }}>By:</span>
                                                                            <span><a href={`/companyDetails?companyId=` + data.companyId}>{data.name}</a></span>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <div class="grid-body mb-4">
                                                                    <div class="grid-body-item">
                                                                        <div class="grid-body-inn">
                                                                            <span>Posted:</span>
                                                                            <span>
                                                                                {moment(data.createdDate).format("MM/DD/YYYY")}
                                                                            </span>
                                                                        </div>
                                                                        <div class="grid-body-inn">
                                                                            <span>Website:</span>
                                                                            <span><a href={data.website} class="red-text text-underline">View Website</a></span>
                                                                        </div>
                                                                        <div class="grid-body-inn">
                                                                            <span>Type:</span>
                                                                            <span>{data.jobtypename_new}</span>
                                                                        </div>
                                                                        <div class="grid-body-inn">
                                                                            <span>Industry:</span>
                                                                            <span> {joinJsxArray(data.jobCategoryName, ', ')}</span>
                                                                        </div>
                                                                        <div class="grid-body-inn">
                                                                            <span>County:</span>
                                                                            <span>{getCountyNameById(data.countyId)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="grid-footer">
                                                                    <Link to={`/jobsummary?jobId=${data.jobId}&ejpage=` + currentPage} class="reg-button">View</Link>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))
                                                }


                                            </div>
                                            <div class="pagination-wrap text-center mt-5 paginationContent">
                                                <Pagination
                                                    total={totalItems_m}
                                                    itemsPerPage={ITEMS_PER_PAGE}
                                                    currentPage={currentPage_m}
                                                    onPageChange={handlePageChange2_mobile} />
                                                {/* <Pagination items={this.state.exampleItems} onChangePage={this.onChangePage} /> */}
                                            </div>
                                        </div>
                                        {/* <!-- left side --> */}
                                        <div class="col-lg-4 mb-4">
                                            <div class="card p-3 mb-3 ">
                                                <div class="bg-heading">
                                                    <h3 class="mb-0">Filter Profiles</h3>
                                                </div>
                                                <div class="py-3 text-start">
                                                    <b class="text-uppercase">Key words</b>


                                                    <input class="form-control me-2 searchKeyInput_mobile" type="search" aria-label="Search" placeholder="" />
                                                    {/* <button class="border-0 p-0 dir-search" type="submit"><img src="images/search-icon.JPG" alt="" /></button> */}

                                                </div>
                                                <div class="mb-4">
                                                    <b class="text-uppercase mb-3 w-100">Categories of Interest</b>
                                                    <div class="category-check-itm mt-2">
                                                        <ul>
                                                            {
                                                                categories.map((data, index) => (
                                                                    <li><input class="form-check-input searchContent_mobile" type="checkbox" name="category_m" value={data.catId} />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                                                                ))}
                                                            {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Accounting </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> CNC  </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Advertising and PR Services  </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Agriculture and Food Processing  </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Architectural and Design Services  </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Occupational Therapy  </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Auto/Diesel </label></li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="mb-4">
                                                    <b class="text-uppercase mb-3 w-100">Type</b>
                                                    <div class="category-check-itm mt-2">
                                                        <ul>
                                                            {
                                                                jobTypes.map((data, index) => (
                                                                    <li><input class="form-check-input searchContent_mobile" type="checkbox" name="jobType_mobile" value={data.jobTypeId} />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                                                                ))}
                                                            {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> Community Service </label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1">  Full Time</label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1">  Internship/Coop</label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1">  Job Shadow</label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1">  Part Time</label></li>
                                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1">  Summer/Seasonal</label></li> */}
                                                        </ul>
                                                    </div>
                                                </div>


                                                <div class="mb-4">
                                                    <b class="text-uppercase mb-3 w-100">Education</b>
                                                    <div class="category-check-itm mt-2">
                                                        <ul>
                                                            {
                                                                educationData.map((data, index) => (
                                                                    <li><input class="form-check-input searchContent_mobile" type="checkbox" name="educationId_mobile" value={data.educationId} />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                                                                ))}

                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
};

CareerExploreJobs.propTypes = {};

CareerExploreJobs.defaultProps = {};

export default CareerExploreJobs;