import React, { useEffect, useState } from 'react';
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import services from '../../services/services';
import { useNavigate, useParams } from "react-router-dom";
//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SunEditor, { buttonList } from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
//import showPwdImg from '/images/show-password.svg';
//import hidePwdImg from '/images/hide-password.svg';

const PostResume = () => {
  const [categories, setCategory] = useState([]);
  const [jobTypes, setJobType] = useState([]);
  const [educations, setEducation] = useState([]);
  const [itemName, setFieldName] = useState('');
  const [fileName, setFileName] = useState('');
  const [schoolList, setSchoolList] = useState([]);
  const [collegeInfo, setCollegeInfo] = useState([]);

  const [fileExtension, setFileExtension] = useState('');
  const [fileBase64, setFileBase64] = useState('');
  const [profilePersonalProfile, setProfilePersonalProfile] = useState('');
  const [profileEducationExperiance, setProfileEducationExperiance] = useState('');
  const [profileCertificationTraining, setProfileCertificationTraining] = useState('');
  const [profileLookingFor, setProfileLookingFor] = useState("");
  const siteId = process.env.React_App_SITE_ID;
  const userIdChkLogin = sessionStorage.getItem("logUserId");

  const [pwd, setPwd] = useState('');
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealConfPwd, setIsRevealConfPwd] = useState(false);
  const [showPwdImg, setShowPwdImg] = useState('/images/hide-password.svg');
  const [hidePwdImg, setHidePwdImg] = useState('/images/show-password.svg');

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });
  const navigate = useNavigate();
  function validateCheck() {
    const checked = $('input[name="category"]:checked').length;


    if (checked >= 3) {
      $('input[name="category"]').not(':checked').prop('disabled', true);
    } else {
      $('input[name="category"]').prop('disabled', false);
    }
  }
  function goLogin() {
    //navigate("/Home?loginRedirect=1");
    window.location.href = "/Home?loginRedirect=1";
  }

  const getSchoolList = () => {
    //alert("14");
    const requestObject = {
      "siteId": process.env.React_App_SITE_ID,
      "pageNumber": "1",
      "pageSize": 150,
      "orderBy": "name"
    }

    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "Bearer "+accessTokenIn
        //'Authorization': accessTokenIn
      },
      body: JSON.stringify(requestObject)
    };

    services.getSchoolList(requestOptions).then((res) => {
      console.log("############36");
      console.log(JSON.stringify(res))
      if (res) {
        console.log(JSON.stringify(res.data));
        var contAr = res.content;
        //setProfileList(res.content);
        if (res) {
          setSchoolList(res);
        }
      }
    });
  }


  function handleChangeCertificationTrainingDesc(content) {
    setProfileCertificationTraining(content);
  }
  function handleChangeCertTrainingDesc(content) {
    setProfileEducationExperiance(content);
  }

  function handleChangeProfileLookingForDesc(content) {
    setProfileLookingFor(content);
  }

  function handleChangePersonalProfileDesc(content) {
    setProfilePersonalProfile(content);
  }


  function handleImageUploadBefore(files, info, uploadHandler) {
    var reader1 = new FileReader();
    reader1.readAsDataURL(files[0]);
    reader1.onload = function () {
      var rdt1 = reader1.result;
      var base64result_banner = rdt1.split(',')[1];
      var bname = files[0].name;
      var bextn = bname.split(".").pop();
      var bannerActName = bname.split(".")[0];
      var fileext_banner = bextn;

      const requestObject = {
        //"imageName": files[0].name,
        "imageName": bannerActName,
        "imageExtension": fileext_banner,
        "imageByteString": base64result_banner
      };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "Bearer "+accessTokenIn 
          //'Authorization': accessTokenIn 
        },
        body: JSON.stringify(requestObject)
      };

      services.fileUploadS3URL(requestOptions).then((res) => {
        const response = {
          // The response must have a "result" array.
          "result": [
            {
              "url": res.url,
              "temp": files[0],
              "name": bannerActName,
              "size": files[0].size
            },
          ]
        }
        uploadHandler(response);

      });
    };

  }



  function getImage() {
    var files = document.getElementById('resume').files;
    if (files.length > 0) {

      var fname = files[0].name;
      // var fextn = fname.substr(0, fname.lastIndexOf('.'));
      var fextn = fname.split(".").pop();
      // alert(fextn);
      var fileActName = fname.split(".")[0];
      // alert(fileActName);
      setFileName(fileActName);
      setFileExtension(fextn);
      getBase64(files[0]);

      if (fextn != "doc" && fextn != "docx" && fextn != "pdf") {

        $("#fileError").show();
        return false;
      } else {
        $("#fileError").hide();
      }

    }
  }

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // console.log(reader.result);
      var base64result = reader.result.split(',')[1];
      setFileBase64(base64result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const submitValue = () => {

    //var passwordConf = $("#passwordConf").val();
    //var passwordCheck = $("#password").val();

    var passwordConf = $("#PasswordConf").val();
    var passwordCheck = $("#password").val();

    if (passwordConf != passwordCheck) {
      $("#matchPwd").show();
      $("#passwordConf").focus();
      return false;
    } else {
      $("#matchPwd").hide();
    }



    var validateEditorTxt = 1;
    if (profilePersonalProfile == "") {
      // alert("profilePersonalProfile");
      $("#profilePersonalProfileValidMsg").show();
      $(window).scrollTop($('#profilePersonalProfileValidMsg').position().top);
      validateEditorTxt = 0;
    } else {
      $("#profilePersonalProfileValidMsg").hide();
    }

    /*
    if(profileEducationExperiance == ""){
     alert("profileEducationExperiance");
     $("#profileEducationExperianceValidMsg").show();
    $(window).scrollTop($('#profileEducationExperianceValidMsg').position().top);
     validateEditorTxt = 0;
   }else{
     $("#profileEducationExperianceValidMsg").hide();
   }
    */

    if (profileCertificationTraining == "") {
      // alert("profileCertificationTraining");
      $("#profileCertificationTrainingValidMsg").show();
      $(window).scrollTop($('#profileCertificationTrainingValidMsg').position().top);
      validateEditorTxt = 0;
    } else {
      $("#profileCertificationTrainingValidMsg").hide();
    }

    if (profileLookingFor == "") {
      //alert("profileLookingFor");
      $("#profileLookingForValidMsg").show();
      $(window).scrollTop($('#profileLookingForValidMsg').position().top);
      validateEditorTxt = 0;
    } else {
      $("#profileLookingForValidMsg").hide();
    }

    if ($('#educationId').val() == "") {
      $("#educationValidMsg").show();
      $(window).scrollTop($('#educationValidMsg').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#educationValidMsg").hide();
      if ($('#educationId').val() == 3) {
        if ($('#schoolId').val() == '') {
          $("#school_districtValidMsg").show();
          $(window).scrollTop($('#school_districtValidMsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#school_districtValidMsg").hide();
        }

        if ($('#schoolId').val() == 33 && $('#schoolIdOther').val() == '') {
          $("#schoolIdOtherValidMsg").show();
          $(window).scrollTop($('#schoolIdOtherValidMsg').position().top);
          validateEditorTxt = 0;
        } else {
          $("#schoolIdOtherValidMsg").hide();
        }

      }
      else {
        console.log('here');
        if ($('#university_name').val() == '') {
          $("#university_nameValidMsg").show();
          $(window).scrollTop($('#university_nameValidMsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#university_nameValidMsg").hide();
        }

        if ($('#university_name').val() == 22 && $('#collegeIdOther').val() == '') {
          $("#collegeIdOtherValidMsg").show();
          $(window).scrollTop($('#collegeIdOtherValidMsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#collegeIdOtherValidMsg").hide();
        }
      }
    }
    var selectedEduction = $('input[name="continueEducation"]:checked').val();
    if(selectedEduction == '' || selectedEduction == null){
      $("#continuingEducationValidMsg").show();
      $(window).scrollTop($('#continuingEducationValidMsg').position().top);
          validateEditorTxt = 0;
    } else {
      $("#continuingEducationValidMsg").hide();
      if (selectedEduction == 'Y') {
        if ($('#collegeId').val() == '') {
          $("#continuedegreeValidMsg").show();
          $(window).scrollTop($('#continuedegreeValidMsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#continuedegreeValidMsg").hide();
        }
  
        if ($('#pursuingProgram').val() == '') {
          $("#continueprogramValidMsg").show();
          $(window).scrollTop($('#continueprogramValidMsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#continueprogramValidMsg").hide();
        }
      }
    }
    
    if (validateEditorTxt == 0) {
      return false;
    }




    if ((fileExtension != "doc" && fileExtension != "docx" && fileExtension != "pdf") || (fileExtension == "")) {
      //document.getElementById('logo').reset();
      $("#fileError").show();
      return false;
    } else {
      $("#fileError").hide();
    }

    var categoriesArray = [];

    $.each($("input[name='category']:checked"), function () {
      categoriesArray.push($(this).val());
    });

    var eduId = $("#educationId").val();
    console.log("eduId-->" + eduId);
    const requestObject = {
      "firstName": $("#firstName").val(),
      "lastName": $("#lastName").val(),
      "email": $("#email").val(),
      "phone": $("#yourphone2").val(),
      "password": $("#password").val(),
      //"personalProfile": $("#personalProfile").val(),
      "personalProfile": profilePersonalProfile,
      //"educationExperiance":$("#educationExperiance").val(),
      "educationExperiance": profileEducationExperiance,
      //"certificationTraining": $("#certificationTraining").val(),
      "certificationTraining": profileCertificationTraining,
      "jobType": $("#jobType").val(),
      "enrolledCollege": $("#collegeId").val(),
      "enrolledCollegeOther": "",
      "pursuingProgram": $("#pursuingProgram").val(),
      //"educationId": $("#educationId").val(),
      "levelEducation": eduId,

      //"schoolId": $("#schoolId").val(),
      "listSchool": $("#schoolId").val(),
      "continueEducation": selectedEduction,

      "listSchoolOther": $('#schoolIdOther').val(),
      "listCollegeId": $('#university_name').val(),
      "listCollegeOther": $('#collegeIdOther').val(),

      "schoolIdOther": $("#schoolIdOther").val(),
      // "lookingFor": $("#lookingFor").val(),
      "lookingFor": profileLookingFor,
      "resumePath": $("#resumePath").val(),
      "siteId": siteId,
      "status": "published",
      "categoriesOfExpertise": categoriesArray,
      "pdfByString": fileBase64,
      "fileName": fileName,
      "fileExtension": fileExtension
    }
    console.log("Post Resume request object===" + requestObject);
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "Bearer "+accessTokenIn
        //'Authorization': accessTokenIn
      },
      body: JSON.stringify(requestObject)
    };
    $(".load-wrap").show();
    services.postProfile(requestOptions).then((res) => {

      $(".load-wrap").hide();

      if (res.success == "false" || res.success == false) {
        //$("#alertFailMessage").html(res.message);
        //$("#alertFailMessage").show();
        $("#alertFailMessage").html(res.message);
        $("#alertFailMessage").fadeTo(2000, 1000).slideUp(1000, function () {

          $("#alertFailMessage").slideUp(1000);
        });

      } else {
        $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#successMessage").slideUp(1000);
        });
        navigate('/thankyou?r=2');
      }
      //navigate('/thankyou');

    });

  }

  function handleChangeSchool(event) {
    //alert("teste")
    //alert(event.target.value);
    var sch = event.target.value;
    $('#schoolIdOther').val('');
    //console.log(event.target.value);
    //var sch = $("#schoolId'").val();
    if (sch == "33") {
      $("#schoolIdOtherDiv").show();
    } else {
      $("#schoolIdOtherDiv").hide();
    }
  }



  function handleChangeContinueEdu(event) {
    /*
    var sch = event.target.value;
    alert(sch)
    var selectedEdu = $('input[name="continueEducation"]:checked').val();
    alert(selectedEdu);
    if( selectedEdu == "Y"){
      $("#continueEduDiv").show();
    }else  if( selectedEdu == "N"){
      $("#continueEduDiv").hide();
    }
    */
  }
  useEffect(() => {
    const jsonData = {
      "postResume": {
        
      }
    };

  var jsondataformatted = JSON.stringify(jsonData);
  sessionStorage.setItem('pagejsonObject', jsondataformatted);

    $(".load-wrap").hide();
    $(".se-dialog-tabs").hide();
    $("#profilePersonalProfileValidMsg").hide();
    $("#profileEducationExperianceValidMsg").hide();
    $("#profileCertificationTrainingValidMsg").hide();
    $("#profileLookingForValidMsg").hide();

    $("#continueEduDiv").hide();
    $('#college_div').hide();
    $("#schoolIdOtherDiv").hide();
    $('#collegeIdOtherDiv').hide();

    $("#PasswordConf").blur(function () {
      var passwordConf = $("#PasswordConf").val();
      var passwordCheck = $("#password").val();
      if (passwordConf != passwordCheck) {
        $("#matchPwd").show();
      } else {
        $("#matchPwd").hide();
      }
    });

    $("#matchPwd").hide();
    $('#yourphone2').usPhoneFormat();
    $("#successMessage").hide();
    $("#alertFailMessage").hide();
    getIndustryData();
    getJobType();
    getEducation();
    getCollegeInfo();
    getSchoolList();
    $("#fileError").hide();
    $('#educationValidMsg').hide();
    $('#school_districtValidMsg').hide();
    $('#university_nameValidMsg').hide();
    $('#school_districtValidMsg_m').hide();
    $('#university_nameValidMsg_m').hide();
    $('#schoolIdOtherValidMsg').hide();
    $('#collegeIdOtherValidMsg').hide();
    $("#continuingEducationValidMsg").hide();

    $("#inlineRadio1, #inlineRadio2").click(function () {
      var selectedEdu = $('input[name="continueEducation"]:checked').val();
      // alert(selectedEdu);
      if (selectedEdu == "Y") {
        $("#continueEduDiv").show();
      } else if (selectedEdu == "N") {
        $('#collegeId').val('');
        $('#pursuingProgram').val('');
        $("#continueEduDiv").hide();
      }
    });
    /*
    $( "#schoolId" ).change(function() {
      var sch = $("#schoolId'").val();
      alert(sch);
      if(sch == "0"){
        $("#schoolIdOtherDiv").show();
      }else{
        $("#schoolIdOtherDiv").hide();
      }
    });
    */
    $("#continuedegreeValidMsg").hide();
    $("#continueprogramValidMsg").hide();

    $("#schoolIdOtherDiv").hide();
    setTimeout(() => {
      // $('#educationId').val('3');
      $('input[id="inlineRadio2"]').prop('checked', true);
    }, 1000)

  }, []);

  const getIndustryData = () => {
    services.getIndustryData().then((res) => {
      console.log("Industry data=" + JSON.stringify(res));
      setCategory(res.data.content);
    });
  }

  const getJobType = () => {
    services.getJobType().then((res) => {
      console.log("setJobType data=" + JSON.stringify(res));
      var tempjobtypes = res.data.content;
      //console.log(tempjobtypes);
      tempjobtypes = tempjobtypes.filter(function (elem) {
        return elem.name !== 'Job Shadow';
      });
      tempjobtypes = tempjobtypes.filter(function (elem) {
        return elem.name !== 'Continuing Education';
      });
      //console.log(tempjobtypes);
      setJobType(tempjobtypes);
    });
  }

  $('#educationId').change(function () {
    $('#school_districtValidMsg').hide();
    $('#university_nameValidMsg').hide();
    $('#university_name').val('');
    $('#schoolId').val('');
    $('#collegeIdOtherDiv').hide();
    $('#schoolIdOtherDiv').hide();
    if ($('#educationId').val() == 3) {
      $('#school_div').show();
      $('#college_div').hide();
      $('#schoolId').prop('disabled', false);
    }
    else {
      $('#school_div').hide();
      $('#college_div').show();
      $('#schoolId').prop('disabled', true);
    }
  })

  function handleChangeCollege(event) {
    //alert("teste")
    //alert(event.target.value);
    var clg = event.target.value;
    $('#collegeIdOther').val('');
    //console.log(event.target.value);
    //var sch = $("#schoolId'").val();
    if (clg == "22") {
      $("#collegeIdOtherDiv").show();
    } else {
      $("#collegeIdOtherDiv").hide();
    }
  }

  function handleChangeCollege_m(event) {
    //alert("teste")
    //alert(event.target.value);
    var clg_m = event.target.value;
    //console.log(event.target.value);
    //var sch = $("#schoolId'").val();
    if (clg_m == "22") {
      $("#collegeIdOtherDiv_m").show();
    } else {
      $("#collegeIdOtherDiv_m").hide();
    }
  }

  const getEducation = () => {
    services.getEducation().then((res) => {
      console.log("getEducation data=" + JSON.stringify(res));
      setEducation(res.data.content);
    });
  }

  const getCollegeInfo = () => {
    services.getEnrolledCollege().then((res) => {
      //console.log("getEducation data=" + JSON.stringify(res.data));
      setCollegeInfo(res.data);
    });
  }

  function validateMedium() {
    var mediumCheckboxes = document.getElementsByName("exp");
    var okay = false;

    for (var i = 0, len = mediumCheckboxes.length; i < len; i++) {
      if (mediumCheckboxes[i].checked) {
        okay = true;
        break;
      }
    }

    if (okay) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <>
      <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav style={{ "--bs-breadcrumb-divider": '>' }} aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item headtitle "><a href="#">Job Seeker Registration </a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">

          <div class="container">
            <div class="row">
              <div class="col-12 ">
                <div class="white-bg px-5 pt-4 pb-4">
                  <div class="row">
                    <div class="col-lg-12 m-auto">


                      {(() => {
                        if (userIdChkLogin != null && userIdChkLogin != "null") {
                          return (<></>);
                        } else {
                          return (<><div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                            <h6 className='mb-0'>Do you already have a profile?</h6>
                            <a onClick={() => goLogin()} className='reg-button  text-center'>SIGN IN</a>
                          </div></>);
                        }
                      })()}

                      {/* <div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                                    <h6 className='mb-0'>Do you already have a profile?</h6>
                                    <a onClick={() => goLogin() } className='reg-button  text-center'>Sign In</a>
                            </div> */}
                      <h1 class="text-center ">
                        Connect with <span class=" red-text">local employers</span> <br /> by posting your resume!
                      </h1>
                      <div class="col-3 m-auto">
                        <hr />
                      </div>
                    </div>
                    <div class="col-lg-9 m-auto text-center">
                      <p>
                        Getting a great job or finding your next career means making your skills and talent visible. Post your resume on Hometown Opportunity and local employers will be able to <b>find you</b> as they search for new team members. <br /><br />
                        <b>Get noticed!</b> Create a profile to be seen by all of our local companies! Your resume profile will stay active on Hometown Opportunity for 6 months.
                      </p>
                    </div>

                  </div>
                  <form onSubmit={handleSubmit(submitValue)}>
                    <div class="row mt-4 resume-wrap">

                      <div class="col-lg-6 mb-4">

                        <label for="firstName" class="form-label">First name <span class="red-text">*</span></label>
                        {/* <input type="text" class="form-control" id="firstName" placeholder="" value="" required=""/> */}
                        <input type="text" class="form-control" id="firstName"  {...register("FirstName", {
                          required: "First name is required.",

                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="FirstName"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />

                      </div>

                      <div class="col-lg-6 mb-4">
                        <label for="lastName" class="form-label">Last name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="lastName" onChange={e => setFieldName(e.target.value)} {...register("LastName", {
                          required: "Last name is required.",

                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="LastName"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />

                      </div>

                      <div class="col-lg-6 mb-4">
                        <div class="d-flex"><label for="email" class="form-label mr-2">Email <span class="red-text">*</span></label> <small>Valid email address for notifications</small></div>
                        <input type="text" class="form-control" id="email" onChange={e => setFieldName(e.target.value)} {...register("Email", {
                          required: "Email is required.",

                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address"
                          }
                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="Email"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />

                      </div>

                      <div class="col-lg-6 mb-4">
                        <div class="d-flex"><label for="phone" class="form-label mr-2">Phone <span class="red-text">*</span></label> <small>XXX-XXX-XXXX Format</small></div>
                        <input type="text" class="form-control" id="yourphone2" name="contactNumber" maxlength="14" onChangeText={(text) => this.onTextChange(text)} {...register("phone", {
                          required: "Phone is required.",
                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="phone"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />

                      </div>

                      <div class="col-lg-6 mb-4 position-relative">
                        <div class="d-flex"><label for="password" class="form-label mr-2">Password <span class="red-text">*</span></label>
                          {/* <small style={{fontSize: "11px"}}>8-15 Characters, Must contain 1 Uppercase 1 Lowercase and 1 Special characters</small> */}
                        </div>
                        <p style={{ fontSize: "12px" }}>Must be a minimum of 8 characters & MUST include all of the following: a lowercase letter, an uppercase letter, a number, and a special character</p>
                        <span class="position-relative d-block">
                          <input type={isRevealPwd ? "text" : "password"} class="form-control" id="password" onChange={e => setFieldName(e.target.value)} {...register("Password", {
                            required: "Password is required.",
                            pattern: {
                              value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                              message: "Password contain uppercase,lowercase and special character"
                            },
                            minLength: { value: 8, message: 'Too short' }
                          })} />

                          <img src={isRevealPwd ? hidePwdImg : showPwdImg} class="pwdShowHideClsPostResume"
                            onClick={() => setIsRevealPwd(prevState => !prevState)}
                            alt="" />
                          {/* <img class="pwdShowHideClsPostResume"
                                        title={isRevealPwd ? "Hide password" : "Show password"}
                                        src={isRevealPwd ? hidePwdImg : showPwdImg}
                                        onClick={() => setIsRevealPwd(prevState => !prevState)}
                                        onChange={e => setPwd(e.target.value)}
                                      /> */}
                        </span>
                        <ErrorMessage
                          errors={errors}
                          name="Password"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />

                      </div>

                      <div class="col-lg-6 mb-4">
                        <div className='d-flex'>
                          <label for="passwordConf" class="form-label mr-2">Confirm Password <span class="red-text">*</span></label>

                        </div>
                        <p style={{ fontSize: "12px" }}>Must be a minimum of 8 characters & MUST include all of the following: a lowercase letter, an uppercase letter, a number, and a special character</p>
                        <span class="position-relative d-block">
                          <input type={isRevealConfPwd ? "text" : "password"} class="form-control" id="PasswordConf" onChange={e => setFieldName(e.target.value)} {...register("PasswordConf", {
                            required: "Password is required.",
                            pattern: {
                              value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                              message: "Password contain uppercase,lowercase and special character"
                            },
                            minLength: { value: 8, message: 'Too short' }
                          })} />


                          <img src={isRevealConfPwd ? hidePwdImg : showPwdImg} class="pwdShowHideClsPostResume"
                            onClick={() => setIsRevealConfPwd(prevState => !prevState)}
                            alt="" />
                        </span>
                        <ErrorMessage
                          errors={errors}
                          name="PasswordConf"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                        <p id="matchPwd" class="error">Passwords must match</p>
                      </div>

                      <div class="col-lg-12 mb-4">
                        <div class="d-flex"><label for="personal" class="form-label mr-2">Personal Profile <span class="red-text">*</span></label> <small>Tell Us About Your Professional Background</small></div>

                        {/* <CKEditor
                            editor={ ClassicEditor }
                            config={{
                              removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
                            }}
                            id="profilePersonalProfile"
                            onReady={ editor => {
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setProfilePersonalProfile(data);
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        /> */}
                        <SunEditor
                          setOptions={{
                            height: "400",
                            //buttonList: [['font', 'align'], ['image']]

                            buttonList: [
                              ['undo', 'redo'],
                              ['font', 'fontSize', 'formatBlock'],
                              ['paragraphStyle', 'blockquote'],
                              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                              ['fontColor', 'hiliteColor', 'textStyle'],
                              ['removeFormat'],
                              '/', // Line break
                              ['outdent', 'indent'],
                              ['align', 'horizontalRule', 'list', 'lineHeight'],
                              ['table', 'link', 'image' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.                    
                              ['fullScreen', 'showBlocks', 'codeView'],
                              ['preview'],

                              /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                            ]
                            //buttonList: buttonList.complex
                            // plugins: [font] set plugins, all plugins are set by default
                            // Other option
                          }}
                          onChange={handleChangePersonalProfileDesc}
                          // setContents={profilePersonalProfile}
                          //onImageUpload={handleImageUpload}
                          onImageUploadBefore={handleImageUploadBefore}
                        />
                        <div id="profilePersonalProfileValidMsg" class="error">Personal Profile is required.</div>

                      </div>

                      <div class="col-lg-12 mb-4 d-none">
                        <div class="d-flex">
                          <label for="education" class="form-label mr-2">Education/Experience <span class="red-text">*</span></label></div>


                        {/* <CKEditor
                            editor={ ClassicEditor }
                            config={{
                              removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
                            }}
                            id="profileEducationExperiance"
                            onReady={ editor => {
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setProfileEducationExperiance(data);
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        /> */}

                        <SunEditor
                          setOptions={{
                            height: "400",
                            //buttonList: [['font', 'align'], ['image']]

                            buttonList: [
                              ['undo', 'redo'],
                              ['font', 'fontSize', 'formatBlock'],
                              ['paragraphStyle', 'blockquote'],
                              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                              ['fontColor', 'hiliteColor', 'textStyle'],
                              ['removeFormat'],
                              '/', // Line break
                              ['outdent', 'indent'],
                              ['align', 'horizontalRule', 'list', 'lineHeight'],
                              ['table', 'link', 'image' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.                    
                              ['fullScreen', 'showBlocks', 'codeView'],
                              ['preview'],

                              /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                            ]
                            //buttonList: buttonList.complex
                            // plugins: [font] set plugins, all plugins are set by default
                            // Other option
                          }}
                          onChange={handleChangeCertTrainingDesc}
                          // setContents={profileCertificationTraining}
                          //onImageUpload={handleImageUpload}
                          onImageUploadBefore={handleImageUploadBefore}
                        />
                        <div id="profileEducationExperianceValidMsg" class="error">Education/Experience is required.</div>

                        {/* <textarea class="form-control textarea" maxlength="500" placeholder="Leave a comment here" id="educationExperiance" onChange={e => setFieldName(e.target.value)} {...register("experience", {
                                    required: "Education/Experience is required.",
                                    maxLength: { value: 500, message: 'Should not allow more than 500 character' }
                                    })}/>
                                 <ErrorMessage
                                    errors={errors}
                                    name="experience"
                                    class="invalid-feedback"
                                    render={({ messages }) => {
                                    console.log("messages", messages);
                                    return messages
                                        ? Object.entries(messages).map(([type, message]) => (
                                            <p key={type}  class="error">{message}</p>
                                        ))
                                        : null;
                                    }}
                                />  */}
                      </div>

                      <div class="col-lg-12 mb-4">

                        <div className="row">
                          <div class="col-md-6 mb-4">
                            <div class="d-flex">
                              <label for="education" class="form-label mr-2">Level of education Completed<span class="red-text">*</span></label>
                            </div>
                            <select class="form-select" aria-label="Default select example" id="educationId" >
                              <option selected value="">Select</option>
                              {
                                educations.map((data, index) => (
                                  <option value={data.educationId}>{data.name}</option>
                                ))}
                            </select>
                            {errors.educationId && errors.educationId.type === "required" && <div class="error">This Field is required</div>}
                            <div id="educationValidMsg" class="error">Level of Education is required.</div>
                          </div>

                          <div class="col-md-6 mb-4" id="school_div">
                            <div class="d-flex">
                              <label for="schoolId" class="form-label mr-2">Graduated From <span class="red-text">*</span></label>
                            </div>
                            {/* {...register("schoolValid", {
                                    required: "School is required.",

                                    })} */}
                            <select class="form-select" aria-label="Default select example" id="schoolId" onChange={handleChangeSchool} >
                              <option selected value="">Select</option>
                              {
                                schoolList.map((data, index) => (
                                  <option value={data.schoolId}>{data.name}</option>
                                ))}
                              {/*<option value="0" >Other</option> */}
                            </select>
                            <div id="school_districtValidMsg" class="error">School is required.</div>
                            {/* <ErrorMessage
                                    errors={errors}
                                    name="schoolValid"
                                    class="invalid-feedback"
                                    render={({ messages }) => {
                                    console.log("messages", messages);
                                    return messages
                                        ? Object.entries(messages).map(([type, message]) => (
                                            <p key={type}  class="error">{message}</p>
                                        ))
                                        : null;
                                    }}
                                /> */}
                          </div>

                          <div class="col-md-6 " id="schoolIdOtherDiv">
                            <div class="d-flex ">
                              <label for="schoolIdOther" class="form-label mr-2">Name of The Institution<span class="red-text">*</span></label>
                            </div>
                            <input type="text" class="form-control " id="schoolIdOther" aria-describedby="emailHelp" placeholder="Other" />
                            <div id="schoolIdOtherValidMsg" class="error">Name of the Institution is required.</div>
                          </div>

                          <div class="col-md-6 mb-4" id="college_div">
                            <div class="d-flex"><label for="firstName" class="form-label mr-2">College / University <span class="red-text">*</span></label></div>
                            <select class="form-select" id="university_name" onChange={handleChangeCollege}>
                              <option value="">Select College</option>
                              {
                                collegeInfo.map((data, index) => (
                                  <option value={data.enrolledCollegeId}>{data.name}</option>
                                ))}
                            </select>


                            <div id="university_nameValidMsg" class="error">College / Universities is required.</div>
                          </div>


                          <div class="col-md-6 " id="collegeIdOtherDiv">
                            <div class="d-flex ">
                              <label for="collegeIdOther" class="form-label mr-2">Name of the Institution<span class="red-text">*</span></label>
                            </div>
                            <input type="text" class="form-control " id="collegeIdOther" aria-describedby="emailHelp" placeholder="Other" />
                            <div id="collegeIdOtherValidMsg" class="error">Name of the Institution is required.</div>
                          </div>

                        </div>
                      </div>



                      <div class="col-lg-12 mb-4">

                        <div class="d-flex">
                          <label for="education" class="form-label mr-2">Are you currently continuing education? <span class="red-text">*</span></label>
                        </div>

                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="continueEducation" id="inlineRadio1" onChange={handleChangeContinueEdu} value="Y" style={{ borderRadius: "30px" }} />
                          <label class="form-check-label" for="inlineRadio1">YES</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="continueEducation" id="inlineRadio2" onChange={handleChangeContinueEdu} value="N" />
                          <label class="form-check-label" for="inlineRadio2">NO</label>
                        </div>
                        <div id="continuingEducationValidMsg" class="error">Are you currently continuing education is required.</div>

                        <div className="row" id="continueEduDiv">
                          <div className="col-md-6 mt-4">
                            <div class="d-flex"><label for="lookingFor" class="form-label mr-2">Name of The Institution <span class="red-text">*</span></label></div>
                            <select class="form-select" id="collegeId">
                              <option value="">Select College</option>
                              {
                                collegeInfo.map((data, index) => (
                                  <option value={data.enrolledCollegeId}>{data.name}</option>
                                ))}
                            </select>
                            <div id="continuedegreeValidMsg" class="error">Name of the Institution is required.</div>
                          </div>

                          <div className="col-md-6 mt-4">
                            <div class="d-flex"><label for="pursuingProgram" class="form-label mr-2">Pursuing Program<span class="red-text">*</span></label></div>
                            <input type="text" class="form-control " id="pursuingProgram" aria-describedby="emailHelp" placeholder="pursuing" />
                            <div id="continueprogramValidMsg" class="error">Pursuing Program is required.</div>
                          </div>
                        </div>

                      </div>




                      <div class="col-lg-12 mb-4">
                        <div class="d-flex"><label for="certification" class="form-label mr-2">Certifications/training <span class="red-text">*</span></label> </div>


                        {/* <CKEditor
                            editor={ ClassicEditor }
                            config={{
                              removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
                            }}
                            id="profileCertificationTraining"
                            onReady={ editor => {
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setProfileCertificationTraining(data);
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        /> */}

                        <SunEditor
                          setOptions={{
                            height: "400",
                            //buttonList: [['font', 'align'], ['image']]

                            buttonList: [
                              ['undo', 'redo'],
                              ['font', 'fontSize', 'formatBlock'],
                              ['paragraphStyle', 'blockquote'],
                              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                              ['fontColor', 'hiliteColor', 'textStyle'],
                              ['removeFormat'],
                              '/', // Line break
                              ['outdent', 'indent'],
                              ['align', 'horizontalRule', 'list', 'lineHeight'],
                              ['table', 'link', 'image' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.                    
                              ['fullScreen', 'showBlocks', 'codeView'],
                              ['preview'],

                              /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                            ]
                            //buttonList: buttonList.complex
                            // plugins: [font] set plugins, all plugins are set by default
                            // Other option
                          }}
                          onChange={handleChangeCertificationTrainingDesc}
                          //setContents={profileLookingFor}
                          //onImageUpload={handleImageUpload}
                          onImageUploadBefore={handleImageUploadBefore}
                        />
                        <div id="profileCertificationTrainingValidMsg" class="error">Certifications/training is required.</div>


                        {/* <textarea class="form-control textarea"   maxlength="500"placeholder="Leave a comment here" id="certificationTraining" onChange={e => setFieldName(e.target.value)} {...register("certificate", {
                                    required: "Certification/training is required.",
                                    maxLength: { value: 500, message: 'Should not allow more than 500 character' }
                                    })}/>
                                 <ErrorMessage
                                    errors={errors}
                                    name="certificate"
                                    class="invalid-feedback"
                                    render={({ messages }) => {
                                    console.log("messages", messages);
                                    return messages
                                        ? Object.entries(messages).map(([type, message]) => (
                                            <p key={type}  class="error">{message}</p>
                                        ))
                                        : null;
                                    }}
                                />  */}
                      </div>

                      <div class="col-lg-12 mb-4">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">CATEGORIES OF EXPERTISE <span class="red-text">*</span></label> <small>Max 3 Categories</small></div>

                        <div >
                          <div class="category-check-itm">
                            <ul class="category-check">
                              {
                                categories.map((data, index) => (


                                  <li><input class="form-check-input" type="checkbox" name="category" value={data.catId} id="category" {...register('category', { required: true })} onClick={() => validateCheck()} />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                ))}
                              {/* <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Accounting </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> CNC  </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Advertising and PR Services  </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Agriculture and Food Processing  </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Architectural and Design Services  </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Occupational Therapy  </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Auto/Diesel </label></li> */}
                            </ul>
                          </div>
                          {/* <div class="category-check-itm">
                                    <ul>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Accounting </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> CNC  </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Advertising and PR Services  </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Agriculture and Food Processing  </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Architectural and Design Services  </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Occupational Therapy  </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Auto/Diesel </label></li>
                                    </ul>
                                </div>
                                <div class="category-check-itm">
                                    <ul>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Accounting </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> CNC  </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Advertising and PR Services  </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Agriculture and Food Processing  </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Architectural and Design Services  </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Occupational Therapy  </label></li>
                                        <li><input class="form-check-input" type="checkbox" name="exp" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Auto/Diesel </label></li>
                                    </ul>
                                </div> */}
                        </div>
                        {errors.category && errors.category.type === "required" && <div class="error">Please check atleast one Category</div>}
                      </div>

                      <div class="col-lg-6 mb-4">
                        <label for="jobType" class="form-label">JOB TYPE <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" id="jobType" onChange={e => setFieldName(e.target.value)} {...register("jobtype", {
                          required: "Job type is required.",

                        })}>
                          <option selected value="">Select</option>
                          {
                            jobTypes.map((data, index) => (
                              <option value={data.jobTypeId}>{data.name}</option>
                            ))}
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name="jobtype"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                      </div>

                      <div class="col-lg-12 mb-4">
                        <div class="d-flex"><label for="lookingFor" class="form-label mr-2">TELL US WHAT YOU’RE LOOKING FOR <span class="red-text">*</span></label> </div>

                        {/* <CKEditor
                                              editor={ ClassicEditor }
                                              config={{
                                                removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
                                              }}
                                              id="profileLookingFor"
                                              data={profileLookingFor}
                                              onReady={ editor => {
                                                  console.log( 'Editor is ready to use!', editor );
                                              } }
                                              onChange={ ( event, editor ) => {
                                                  const data = editor.getData();
                                                  setProfileLookingFor(data);
                                                  console.log( { event, editor, data } );
                                              } }
                                              onBlur={ ( event, editor ) => {
                                                  console.log( 'Blur.', editor );
                                              } }
                                              onFocus={ ( event, editor ) => {
                                                  console.log( 'Focus.', editor );
                                              } }
                                          /> */}


                        <SunEditor
                          setOptions={{
                            height: "400",
                            //buttonList: [['font', 'align'], ['image']]

                            buttonList: [
                              ['undo', 'redo'],
                              ['font', 'fontSize', 'formatBlock'],
                              ['paragraphStyle', 'blockquote'],
                              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                              ['fontColor', 'hiliteColor', 'textStyle'],
                              ['removeFormat'],
                              '/', // Line break
                              ['outdent', 'indent'],
                              ['align', 'horizontalRule', 'list', 'lineHeight'],
                              ['table', 'link', 'image' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.                    
                              ['fullScreen', 'showBlocks', 'codeView'],
                              ['preview'],

                              /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                            ]
                            //buttonList: buttonList.complex
                            // plugins: [font] set plugins, all plugins are set by default
                            // Other option
                          }}
                          onChange={handleChangeProfileLookingForDesc}
                          // setContents={jobSummary}
                          //onImageUpload={handleImageUpload}
                          onImageUploadBefore={handleImageUploadBefore}
                        />
                        <div id="profileLookingForValidMsg" class="error">What You're Looking For is required.</div>

                        {/* <textarea class="form-control textarea" maxlength="500" placeholder="Leave a comment here" id="lookingFor" spellcheck="false" {...register("looking", {
                                    required: "This field is required.",
                                    maxLength: { value: 500, message: 'Should not allow more than 500 character' }
                                    })}></textarea>
                                 <ErrorMessage
                                    errors={errors}
                                    name="looking"
                                    class="invalid-feedback"
                                    render={({ messages }) => {
                                    console.log("messages", messages);
                                    return messages
                                        ? Object.entries(messages).map(([type, message]) => (
                                            <p key={type}  class="error">{message}</p>
                                        ))
                                        : null;
                                    }}
                                />  */}
                      </div>


                      <div class="col-lg-12 mb-4">

                        <div class="d-flex justify-content-between align-items-center choose">
                          <div>
                            <label for="resumePath" class="form-label">UPLOAD A RESUME <span class="red-text">*</span></label>
                            <div class="d-flex align-items-center">
                              {/* <input class="form-control form-control-lg choose-file mr-2" id="resumePath" type="file"/> */}
                              <input class="form-control form-control-lg" id="resume" type="file" onChange={() => getImage()} />
                            </div>
                            <small> 1MB Max, File Type: PDF,DOC</small>
                            <div id="fileError" class="error">Invalid file format</div>
                          </div>
                          <div> <button type="submit" class="reg-button"  >post your resume</button></div>
                        </div>

                      </div>

                    </div>

                  </form>


                  <div id="successMessage" class="alert">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <img src="/images/success-alert.png" alt="" />
                        <strong class="mx-2">Success!</strong> Resume  has been posted successfully.
                      </div>
                      <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                    </div>
                  </div>

                  <div id='alertFailMessage' class="alert alert-danger mt-3"></div>

                </div>






              </div>

            </div>
          </div>
        </section>

        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
          </div>
        </section>

      </main>
    </>

  )
};

PostResume.propTypes = {};

PostResume.defaultProps = {};

export default PostResume;
