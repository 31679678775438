import React, { Component, PropTypes } from 'react';
import Pagination from '../pagination/pagination';
class TopCompaniesHiring extends React.Component 
{

      constructor() 
      {
         super();
  
          // an example array of items to be paged
          var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
  
          this.state = {
              exampleItems: exampleItems,
              pageOfItems: []
          };
  
          // bind function in constructor instead of render (https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md)
          this.onChangePage = this.onChangePage.bind(this);
      }
  
      onChangePage(pageOfItems) {
          // update state with new page of items
          this.setState({ pageOfItems: pageOfItems });
      }
      render() 
      {
        return (
            <>
            <div class="col-lg-12">
            <div class="d-flex justify-content-between border-bottom  mb-4 pb-4 flex-lg-row flex-column">
                <h4 class="headtitle">Top companies hiring in your area</h4>
                <span>Total of <b>35</b> Jobs</span>
            </div>
        </div>
        {this.state.pageOfItems.map(item =>
        <>
        <div class="oppertunity-card2">
            <div class="logo-org">
                <img src="images/midmark-logo.JPG" alt=""/>
            </div>
            <div class="right-info">
                <div class="d-flex justify-content-between flex-lg-row flex-column">
                    <h4>PRODUCT AND SERVICES MANAGER {item.id}</h4>
                    <div class="date">
                        <b class="d-flex text-uppercase">
                    <span>August 19</span>,
                    <span class="ms-1"> 2021</span>
                </b>
                    </div>
                </div>
                <div class="d-flex mb-3">
                    <span><a href="#" class="company-name">Midmark Corporation </a></span>
                    <span class="mx-3">|</span>
                    <span> Versailles, Ohio</span>
                </div>
                <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>

            </div>
            <div class="job-seeker">
                <b class="text-uppercase mb-2">job-seeker</b>
                <div class="job-seek-icon mb-3">
                    <div> <img src="images/health.png" alt=""/></div>
                    <div> <img src="images/lunch-plate.png" alt=""/></div>
                    <div> <img src="images/dental.png" alt=""/></div>
                    <div> <img src="images/cofee.png" alt=""/></div>
                    <div> <img src="images/cofee.png" alt=""/></div>
                    <div><a href="#" class="js-more" data-bs-toggle="modal" data-bs-target="#exampleModal">more &#62;</a></div>

                </div>
                <div class="text-center">
                    <a href="#" class="reg-button">View JOB</a>
                </div>
            </div>
        </div>

      
        </>
        )}
        <div class="pagination-wrap text-center mt-3">
         <Pagination items={this.state.exampleItems} onChangePage={this.onChangePage} />
        </div>
        </>)
      }
}
export default TopCompaniesHiring;