import React, { useEffect, useState } from 'react';

import { ErrorMessage } from "@hookform/error-message";
import { set, useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import StudentRegistration2 from '../student/student-registration2';
import StudentRegistration3 from '../student/student-registration3';
import { useNavigate, useParams, useLocation, useHistory } from "react-router-dom";
import Services from '../../services/services';
//import { CKEditor } from '@ckeditor/ckeditor5-react';
import SunEditor, { buttonList } from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Modal from 'react-modal';

const UpdateStudent = () => {
  const navigate = useNavigate();
  const [categories, setCategory] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [jobTypes, setJobType] = useState([]);
  const [schoolIds, setSchoolInData] = useState([]);

  const [currentYearList, setCurrentYearList] = useState([]);
  const [itemName, setFieldName] = useState('');
  const [studentResumePath, setStudentResumePath] = useState('');
  const [firstNameMobile, setFirstNameMobile] = useState('');

  const [fileNameMobile, setFileNameMobile] = useState('');
  const [fileExtensionMobile, setFileExtensionMobile] = useState('');
  const [fileBase64Mobile, setFileBase64Mobile] = useState('');

  const [fileName, setFileName] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const [fileBase64, setFileBase64] = useState('');

  const [studentCareers, setStudentCareers] = useState('');
  const [studentCareersMobile, setStudentCareersMobile] = useState('');

  const [coursesRelated, setCoursesRelated] = useState([]);
  const [studentSkills, setStudentSkills] = useState('');
  const [studentSkillsMobile, setStudentSkillsMobile] = useState('');

  const [studentCourses, setStudentCourses] = useState([]);
  const [studentCoursesMobile, setStudentCoursesMobile] = useState([]);

  const [studentComments, setStudentComments] = useState('');
  const [studentCommentsMobile, setStudentCommentsMobile] = useState('');

  const [studentEmail, setStudentEmail] = useState('');

  const [collegeInfo, setCollegeInfo] = useState([]);
  // const studenEmail = '';
  //const studentId = 56;

  const [educations, setEducation] = useState([]);
const [studentUserId, setstudentUserId] = useState('');

const [studentCareers_popup, setStudentCareers_popup] = useState([]);
  const [studentSkills_popup, setStudentSkills_popup] = useState('');
  const [studentComments_popup, setStudentComments_popup] = useState('');
  const [studentResumePath_popup, setStudentResumePath_popup] = useState('');

  const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: '100%',
        maxWidth: '768px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0px',
        border: '0px',
        textAlign: 'center',
        zIndex: 9
    },
};
let subtitle;
const [modalIsOpen, setIsOpen] = React.useState(false);
function openModal() {
    Services.getpubStudentProfile(studentId).then((res) => {
        var resdata = res.data;
        
        $('#firstName_popup').val(resdata.firstName);
        $("#lastName_popup").val(resdata.lastName);
        $("#email_popup").val(resdata.email);
        $("#phone_popup").val(resdata.phone);
    });
    setIsOpen(true);
    $('#phone_popup').usPhoneFormat();
    $("#successMessage_pwd").hide();
}

function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
    $("#matchPwd").hide();
    $("#successMessage_pwd").hide();
    $("#pwdvalidcheck").hide();
}

function closeModal() {  
    setIsOpen(false);
}
function closepersonalModal() {  
  setIsOpen(false);
}

const [modalSchoolIsOpen, setSchoolIsOpen] = React.useState(false);
function openSchoolModal() {
  
  Services.getpubStudentProfile(studentId).then((res) => {
      var resdata = res.data;
      $("#class_typepopup").val(resdata.classYear);
    $('#schoolIdOthermsg_popup').hide();
    $('#collegeIdOthermsg_popup').hide();
    $('#extensionvalidmsg_popup').hide();
    $('#school_districtValidMsg_popup').hide();
    $('#university_nameValidMsg_popup').hide();
    $('#pursuingValidMsg_popup').hide();
    $('#class_typeValidMsg').hide();
      setTimeout(() => {
        $('#pursuing_degreepopup').val(resdata.educationId);
        $('#collegeIdOther_popup').val(resdata.nameOfCollegeUniversity);
        $('#schoolIdOther_popup').val(resdata.schoolDistrictOther);
          $('input[id="inlineRadio2_popup"]').prop('checked', true);
          $("#continueEduDiv_popup").hide();
        
        if (resdata.educationId == 3) {
          var selectedyearval = resdata.classYear;
          var currentyearval = new Date().getFullYear();

          //if ((selectedyearval == currentyearval) || (selectedyearval == currentyearval + 1)) {
            $('#planedu_popup').show();
            $("#continueEducation_popup").val(resdata.highSchoolDiplomaExtContinueEduStatus);

            if (resdata.highSchoolDiplomaExtContinueEduStatus == "Y") {
              $('#inlineRadio1_popup').prop("checked", true);
              $("#continueEduDiv_popup").show();
            } else if (resdata.highSchoolDiplomaExtContinueEduStatus == "N") {
              $('#inlineRadio2_popup').prop("checked", true);
              $("#continueEduDiv_popup").hide();
            }
            $("#pursuingProgram_popup").val(resdata.highSchoolDiplomaExtPursuingProgram);
            $("#collegeId_popup").val(resdata.highSchoolDiplomaExtNameOfInstitutionCollegeId);
         /* }
          else {
            $('#planedu_popup').hide();
          } */
          $('#school_div_popup').show();
          $('#schoolIdOtherDiv_popup').hide();
          $('#college_div_popup').hide();
          $('#collegeIdOtherDiv_popup').hide();
          $("#school_district_popup").val(resdata.schoolId);
          
          if (resdata.schoolId == '33') {
            $('#schoolIdOtherDiv_popup').show();
          }
          $('#university_name_popup').val();
        }
        else {
          $('#planedu_popup').hide();
          $('#school_div_popup').hide();
           $('#schoolIdOtherDiv_popup').hide();
          $('#college_div_popup').show();
          $('#collegeIdOtherDiv_popup').hide();
          $("#school_district_popup").val();
          $('#university_name_popup').val(resdata.nameOfCollegeUniversityId);

          if (resdata.nameOfCollegeUniversityId == '22') {
            $('#collegeIdOtherDiv_popup').show();
          }
        } 
      });
  });
  setSchoolIsOpen(true);
  $('#pursuing_degreepopup').change(function () {
    $('#school_districtValidMsg_popup').hide();
    $('#university_nameValidMsg_popup').hide();
    $('#schoolIdOther_popup').val('');
    $('#collegeIdOther_popup').val('');
    $('#school_district_popup').val('');
    $('#schoolIdOtherDiv_popup').hide();
    $('#university_name_popup').val('');
    $('#collegeIdOtherDiv_popup').hide();
    if ($('#pursuing_degreepopup').val() == 3) {
      $('#school_div_popup').show();
      $('#college_div_popup').hide();
      $('#school_district_popup').prop('disabled', false);
      $('#university_name_popup').prop('disabled', true);
      $('#class_typepopup').change();
    }
    else {
      $('#planedu_popup').hide();
      $('#school_div_popup').hide();
      $('#college_div_popup').show();
      $('#school_district_popup').prop('disabled', true);
      $('#university_name_popup').prop('disabled', false);
    }
  })

  $('#class_typepopup').change(function () {
    var classyr = $('#class_typepopup').val();
    var currentyearval = new Date().getFullYear();

    //alert(currentyearval);
    /*if (($('#pursuing_degreepopup').val() == 3) && ((classyr == currentyearval) || (classyr == (currentyearval + 1)))) { */
    if (($('#pursuing_degreepopup').val() == 3)) {
      $('#planedu_popup').show();
    }
    else {
      $('#planedu_popup').hide();
    }
  })

  $("#inlineRadio1_popup, #inlineRadio2_popup").click(function () {
    var selectedEdu = $('input[name="continueEducation_popup"]:checked').val();
    // alert(selectedEdu);
    if (selectedEdu == "Y") {
      $("#continueEduDiv_popup").show();
    } else if (selectedEdu == "N") {
      $('#collegeId_popup').val('');
      $('#pursuingProgram_popup').val('');
      $("#continueEduDiv_popup").hide();
    }
  });

    $("#successMessage_school").hide();
}

function afterOpenSchoolModal() {
    $("#successMessage_school").hide();
}

function closeSchoolModal() {
  setSchoolIsOpen(false);
}

const [modalCareerIsOpen, setCareerIsOpen] = React.useState(false);
function openCareerModal() {
  Services.getpubStudentProfile(studentId).then((res) => {
    var resdata = res.data;
    setTimeout(() => {
      setCategoriesSelected(resdata.categoriesOfExpertise);
      var companyCatIn_popup = resdata.categoriesOfExpertise;
      for (var i = 0; i < companyCatIn_popup.length; i++) {
        $('input[name="category_popup"][value="' + companyCatIn_popup[i] + '"]').prop("checked", true);
      }
      setStudentCareers_popup(resdata.reasonForSelectCareers);
      setStudentSkills_popup(resdata.experiance);

    });
  });
  setCareerIsOpen(true);
  $("#categoriesinterestedValidMsg_popup").hide();
  $("#studentCareersValidMsg_popup").hide();
  $("#studentSkillsValidMsg_popup").hide();
  $("#successMessage_career").hide();
}

function afterOpenCareerModal() {
    $("#successMessage_career").hide();
}

function closeCareerModal() {
    
  setCareerIsOpen(false);
}

const [modalResumeIsOpen, setResumeIsOpen] = React.useState(false);
function openResumeModal() {
  Services.getpubStudentProfile(studentId).then((res) => {
    $("#seekinginterestValidMsg_popup").hide();
  $("#fileError_popup").hide();
    var resdata = res.data;
    setTimeout(() => {
      var seekingIn = resdata.seekingEmploymentTypes;
      for (var i = 0; i < seekingIn.length; i++) {
        $('input[name="seeking_popup"][value="' + seekingIn[i] + '"]').prop("checked", true);
      }
      $("#resume_popup").val(resdata.pdfByString);
      setStudentComments_popup(resdata.comments);
      setStudentResumePath_popup(resdata.resumeUrl);
      if (resdata.resumeUrl != "") {
        $("#showResume_popup").show();
      }
      $('#authorization_popup').val(resdata.authorization);
      if (resdata.educationId == 3) {
        $('#parent_authorization_popup').show();
      }
      else {
        $('#parent_authorization_popup').hide();
      }
    });
  });
  $("#seekinginterestValidMsg_popup").hide();
  $("#fileError_popup").hide();
  setResumeIsOpen(true);
    $("#successMessage_resume").hide();
}

function afterOpenResumeModal() {
    $("#successMessage_resume").hide();
}

function closeResumeModal() {
    
  setResumeIsOpen(false);
}


  const {
    register: register3,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });

  function handleChangeStudentCommentsDesc(content) {
    setStudentComments(content);
  }

  function handleChangeStudentCommentsDesc_popup(content) {
    setStudentComments_popup(content);
  }

  function handleChangeStudentCareersDesc(content) {
    setStudentCareers(content);
  }
  function handleChangeStudentSkillsDesc(content) {
    setStudentSkills(content);
  }

  function handleChangeStudentCareersDesc_popup(content){
    setStudentCareers_popup(content);
  }
  function handleChangeStudentSkillsDesc_popup(content) {
    setStudentSkills_popup(content);
  }

  function handleChangeStudentCommentsMobDesc(content) {
    setStudentCommentsMobile(content);
  }
  function handleChangeStudentSkillsMobDesc(content) {
    setStudentSkillsMobile(content);
  }

  function handleChangeStudentCareersMobDesc(content) {
    setStudentCareersMobile(content);
  }

  function handleImageUploadBefore(files, info, uploadHandler) {
    var reader1 = new FileReader();
    reader1.readAsDataURL(files[0]);
    reader1.onload = function () {
      var rdt1 = reader1.result;
      var base64result_banner = rdt1.split(',')[1];
      var bname = files[0].name;
      var bextn = bname.split(".").pop();
      var bannerActName = bname.split(".")[0];
      var fileext_banner = bextn;

      const requestObject = {
        //"imageName": files[0].name,
        "imageName": bannerActName,
        "imageExtension": fileext_banner,
        "imageByteString": base64result_banner
      };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "Bearer "+accessTokenIn 
          //'Authorization': accessTokenIn 
        },
        body: JSON.stringify(requestObject)
      };

      Services.fileUploadS3URL(requestOptions).then((res) => {
        const response = {
          // The response must have a "result" array.
          "result": [
            {
              "url": res.url,
              "temp": files[0],
              "name": bannerActName,
              "size": files[0].size
            },
          ]
        }
        uploadHandler(response);

      });
    };

  }


  const getEducation = () => {
    Services.getEducation().then((res) => {
      console.log("getEducation data=" + JSON.stringify(res));
      var tempedu = res.data.content;
      //console.log(tempjobtypes);
      tempedu = tempedu.filter(function (elem) {
        return elem.name != 'PhD';
      });
      setEducation(tempedu);
    });
  }

  const {
    register: register4,
    formState: { errors: errors4 },
    handleSubmit: handleSubmit4,
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });
  
  const studentId = sessionStorage.getItem("logStudentId");
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });


  useEffect(() => {
    // const jsonData = {
    //   "updateStudent": {
        
    //   }
    // };
    // var jsondataformatted = JSON.stringify(jsonData);
    // sessionStorage.setItem('pagejsonObject', jsondataformatted);

    if (studentId == null || studentId == "null") {
      //navigate('/login');
      navigate('/Home');
    }
    $("#school_div_popup").hide();
  $("#schoolIdOtherDiv_popup").hide();
  $("#college_div_popup").hide();
  $("#collegeIdOtherDiv_popup").hide();
  $("#planedu_popup").hide();
  $("#continueEduDiv_popup").hide();
    $(".se-dialog-tabs").hide();
    $('#seekinginterestValidMsg').hide();
    $("#categoriesinterestedValidMsg").hide();
    $(".load-wrap").hide();
    $("#fileErrorMobile").hide();
    $("#studentCareersValidMsg").hide();
    $("#studentCareersValidMsgMobile").hide();
    $("#coursesRelatedValidMsg").hide();
    $("#coursesRelatedValidMsgMobile").hide();
    $("#studentSkillsValidMsg").hide();
    $("#studentSkillsValidMsgMobile").hide();
    $("#studentCoursesValidMsg").hide();
    $("#studentCoursesValidMsgMobile").hide();
    $("#studentCommentsValidMsg").hide();
    $("#studentCommentsValidMsgMobile").hide();
    $('#school_districtValidMsg').hide();
    $('#university_nameValidMsg').hide();
    $('#school_districtValidMsg_m').hide();
    $('#university_nameValidMsg_m').hide();
    $("#pursuingValidMsg").hide();
    $('#pursuingValidMsg_m').hide();
    $("#showResume").hide();
    $("#successMessage").hide();
    $("#successMessage_m").hide();
    $('#yourphone2').usPhoneFormat();
    $('#mobile1').usPhoneFormat();
    $('#mobile1_m').usPhoneFormat();
    $('#step2').hide();
    $('#step3').hide();
    $('#step4').hide();
    getJobType();
    getIndustryData();
    getSchoolnData();
    getCurrentYear();
    
    getEducation();
    getCollegeInfo();
    $('#college_div').hide();
    $("#fileError").hide();

    $("#schoolIdOtherDiv").hide();
    $("#schoolIdOtherDiv_m").hide();
    $('#collegeIdOtherDiv').hide();
    $("#collegeIdOtherDiv_m").hide();
    $('#schoolIdOthermsg').hide();
    $('#collegeIdOthermsg').hide();
    $('#schoolIdOther_mmsg').hide();
    $('#collegeIdOther_mmsg').hide();
    $('#planedu').hide();
    $('#extensionvalidmsg').hide();
    $('#planedu_m').hide();
    $('#extensionvalidmsg_m').hide();
    setTimeout(() => {
      $('#pursuing_degree').val('3');
      $('#pursuing_m').val('3');
      $('input[id="inlineRadio2"]').prop('checked', true);
      $('input[id="inlineRadio2_m"]').prop('checked', true);
    }, 1000)
    getStudentProfile(studentId);

    $('#continueEduDiv').hide();
    $('#continueEduDiv_m').hide();
    $("#inlineRadio1, #inlineRadio2").click(function () {
      var selectedEdu = $('input[name="continueEducation"]:checked').val();
      // alert(selectedEdu);
      if (selectedEdu == "Y") {
        $("#continueEduDiv").show();
      } else if (selectedEdu == "N") {
        $('#collegeId').val('');
        $('#pursuingProgram').val('');
        $("#continueEduDiv").hide();
      }
    });

    $("#inlineRadio1_m, #inlineRadio2_m").click(function () {
      var selectedEdu = $('input[name="continueEducation_m"]:checked').val();
      // alert(selectedEdu);
      if (selectedEdu == "Y") {
        $("#continueEduDiv_m").show();
      } else if (selectedEdu == "N") {
        $('#collegeId_m').val('');
        $('#pursuingProgram_m').val('');
        $("#continueEduDiv_m").hide();
      }
    });
    $( "#edit_personalinfo, #edit_personalinfo_m" ).click(function() {            
      openModal()
    });

    $('#update_done, #update_done_m').click(function(){
      navigate('/studentDashboard');
    });

    $( "#edit_schoolinfo, #edit_schoolinfo_m" ).click(function() {            
      openSchoolModal()
    });

    $( "#edit_careerinfo, #edit_careerinfo_m" ).click(function() {   
      $("#categoriesinterestedValidMsg_popup").hide();
      $("#studentCareersValidMsg_popup").hide();
      $("#studentSkillsValidMsg_popup").hide();
      openCareerModal()
    });

    $( "#edit_resumeinfo, #edit_resumeinfo_m" ).click(function() {            
      openResumeModal()
    });
    
  }, []);

  $('#class_type').change(function () {
    var classyr = $('#class_type').val();
    var currentyearval = new Date().getFullYear();

    //alert(currentyearval);
    //if (($('#pursuing_degree').val() == 3) && ((classyr == currentyearval) || (classyr == (currentyearval + 1)))) {
    if (($('#pursuing_degree').val() == 3)) {
      $('#planedu').show();
    }
    else {
      $('#planedu').hide();
    }
  })

  $('#classType_m').change(function () {
    var classyr = $('#classType_m').val();
    var currentyearval = new Date().getFullYear();

    //alert(currentyearval);
    //if (($('#pursuing_m').val() == 3) && ((classyr == currentyearval) || (classyr == (currentyearval + 1)))) 
    if (($('#pursuing_m').val() == 3)) {
      $('#planedu_m').show();
    }
    else {
      $('#planedu_m').hide();
    }
  })

  $('#pursuing_degree').change(function () {
    //alert($('#pursuing_degree').val());
    $('#school_districtValidMsg').hide();
    $('#university_nameValidMsg').hide();
    $('#schoolIdOther').val('');
    $('#collegeIdOther').val('');
    $('#university_name').val('');
    $('#school_district').val('');
    $('#collegeIdOtherDiv').hide();
    $('#schoolIdOtherDiv').hide();
    if ($('#pursuing_degree').val() == 3) {
      $('#parent_authorization').show();
      $('#school_div').show();
      $('#college_div').hide();
      $('#school_district').prop('disabled', false);
      $('#university_name').prop('disabled', true);
      $('#class_type').change();
    }
    else {
      $('#parent_authorization').hide();
      $('#planedu').hide();
      $('#school_div').hide();
      $('#college_div').show();
      $('#school_district').prop('disabled', true);
      $('#university_name').prop('disabled', false);
    }
  })

  $('#pursuing_m').change(function () {
    //alert($('#pursuing_degree').val());
    $('#school_districtValidMsg_m').hide();
    $('#university_nameValidMsg_m').hide();
    $('#schoolIdOther_m').val('');
    $('#collegeIdOther_m').val('');
    $('#university_m').val('');
    $('#collegeIdOtherDiv_m').hide();
    $('#schoolIdOtherDiv_m').hide();
    $('#school_district_m').val('');
    if ($('#pursuing_m').val() == 3) {
      $('#parent_authorizationm').show();
      $('#school_div_m').show();
      $('#college_div_m').hide();
      $('#school_district_m').prop('disabled', false);
      $('#university_m').prop('disabled', true);
      $('#classType_m').change();
    }
    else {
      $('#parent_authorizationm').hide();
      $('#planedu_m').hide();
      $('#school_div_m').hide();
      $('#college_div_m').show();
      $('#school_district_m').prop('disabled', true);
      $('#university_m').prop('disabled', false);
    }
  })

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // console.log(reader.result);
      var base64result = reader.result.split(',')[1];
      setFileBase64(base64result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  function getImage() {
    var files = document.getElementById('resume').files;
    if (files.length > 0) {

      var fname = files[0].name;
      // var fextn = fname.substr(0, fname.lastIndexOf('.'));
      var fextn = fname.split(".").pop();
      // alert(fextn);
      var fileActName = fname.split(".")[0];
      // alert(fileActName);
      setFileName(fileActName);
      setFileExtension(fextn);
      getBase64(files[0]);

      if (fextn != "doc" && fextn != "docx" && fextn != "pdf") {

        $("#fileError").show();
        return false;
      } else {
        $("#fileError").hide();
      }

    }
  }

  function getImage_popup() {
    var files = document.getElementById('resume_popup').files;
    if (files.length > 0) {

      var fname = files[0].name;
      // var fextn = fname.substr(0, fname.lastIndexOf('.'));
      var fextn = fname.split(".").pop();
      // alert(fextn);
      var fileActName = fname.split(".")[0];
      // alert(fileActName);
      setFileName(fileActName);
      setFileExtension(fextn);
      getBase64(files[0]);

      if (fextn != "doc" && fextn != "docx" && fextn != "pdf") {

        $("#fileError_popup").show();
        return false;
      } else {
        $("#fileError_popup").hide();
      }

    }
  }

  function getBase64Mobile(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // console.log(reader.result);
      var base64result = reader.result.split(',')[1];
      setFileBase64Mobile(base64result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  function handleChangeSchool_m(event) {
    //alert("teste")
    //alert(event.target.value);
    var sch_m = event.target.value;
    $('#schoolIdOther_m').val('');
    //console.log(event.target.value);
    //var sch = $("#schoolId'").val();    
    if (sch_m == "33") {
      $("#schoolIdOtherDiv_m").show();
    } else {
      $("#schoolIdOtherDiv_m").hide();
    }
  }

  function handleChangeCollege(event) {
    //alert("teste")
    //alert(event.target.value);
    var clg = event.target.value;
    $('#collegeIdOther').val('');
    //console.log(event.target.value);
    //var sch = $("#schoolId'").val();    
    if (clg == "22") {
      $("#collegeIdOtherDiv").show();
    } else {
      $("#collegeIdOtherDiv").hide();
    }
  }

  

  function getImageMobile() {
    var files = document.getElementById('resumeMobile').files;
    if (files.length > 0) {

      var fname = files[0].name;
      // var fextn = fname.substr(0, fname.lastIndexOf('.'));
      var fextn = fname.split(".").pop();
      // alert(fextn);
      var fileActName = fname.split(".")[0];
      // alert(fileActName);
      setFileNameMobile(fileActName);
      setFileExtensionMobile(fextn);
      getBase64Mobile(files[0]);

      if (fextn != "doc" && fextn != "docx" && fextn != "pdf") {

        $("#fileErrorMobile").show();
        return false;
      } else {
        $("#fileErrorMobile").hide();
      }

    }
  }


  const getIndustryData = () => {

    Services.getIndustryData().then((res) => {

      console.log("Industry data=" + JSON.stringify(res.data));

      setCategory(res.data.content);

    });

  }


  const getCurrentYear = () => {
    var currentYear = new Date().getFullYear()
    var years = [];
    years.push(currentYear);
    for (var i = 1; i < 7; i++) {
      years.push(currentYear + i);
    }

    //const now = new Date().getUTCFullYear();    
    //const years = Array(now - (now + 5)).fill('').map((v, idx) => now - idx);
    console.log("#41#41-->" + JSON.stringify(years));
    setCurrentYearList(years);
  }

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  const getJobType = () => {
    Services.getJobType().then((res) => {
      //console.log("setJobType data=" + JSON.stringify(res.data));
      var jobtype_unsort = res.data.content;

      var jobtypes_sorted = jobtype_unsort.sort((a, b) => b.jobTypeId - a.jobTypeId);
      array_move(jobtypes_sorted, 0, (jobtypes_sorted.length - 1));
      
      jobtypes_sorted = jobtypes_sorted.filter(function (elem) {
        return elem.name !== 'Continuing Education';
      });
      
      setJobType(jobtypes_sorted);
    });
  }

  const getSchoolnData = () => {
    //alert("14");
    const requestObject = {
      "siteId": process.env.React_App_SITE_ID,
      "pageNumber": 1,
      "pageSize": 150
    }

    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "Bearer "+accessTokenIn 
        //'Authorization': accessTokenIn 
      },
      body: JSON.stringify(requestObject)
    };

    Services.getSchoolList(requestOptions).then((res) => {
      console.log("############36");
      console.log(JSON.stringify(res))
      if (res) {
        console.log(JSON.stringify(res.data));
        var contAr = res.content;
        //setProfileList(res.content);
        if (res) {
          setSchoolInData(res);
        }
      }
      //navigate('/thankyou');
    });
  }



  const getStudentProfile = (studentId) => {
    Services.getpubStudentProfile(studentId).then((res) => {
      var companyCatIn = res.data.categoriesOfExpertise;

      setStudentComments(res.data.comments);
        setStudentCommentsMobile(res.data.comments);

        setStudentSkills(res.data.experiance);
        setStudentSkillsMobile(res.data.experiance);

        setStudentCourses(res.data.course);
        setStudentCoursesMobile(res.data.course);
        setstudentUserId(res.data.studentUserId);
      ////
      $("#firstName_m").val(res.data.firstName);
      setFirstNameMobile(res.data.firstName);
      $("#lastName_m").val(res.data.lastName);
      $("#email_m").val(res.data.email);
      $("#mobile1_m").val(res.data.phone);
      $("#class_type_m").val(res.data.classYear);
      $('#pursuing_degree').val(res.data.educationId);
      $('#university_name').val(res.data.nameOfCollegeUniversity);
      ////////



      // setJobSeekerDetails(res);
      $("#firstName").val(res.data.firstName);
      $("#lastName").val(res.data.lastName);
      $("#email").val(res.data.email);
      $("#yourphone2").val(res.data.phone);
      $("#personalProfile").val(res.data.personalProfile);
      $("#education").val(res.data.educationExperiance);
      $("#lookingFor").val(res.data.lookingFor);
      $("#jobType").val(res.data.jobType);
      $("#resume").val(res.data.pdfByString);
      setStudentEmail(res.data.email);
      setStudentCareers(res.data.reasonForSelectCareers);
      setStudentCareersMobile(res.data.reasonForSelectCareers);

      $("#careers_m").val(res.data.reasonForSelectCareers);

      $("#courses_related").val(res.data.course);
      $("#class_type").val(res.data.classYear);
      $("#classType_m").val(res.data.classYear);

      $("#authorization").val(res.data.authorization);
      $("#authorization_m").val(res.data.authorization);

      //$("#skills").val(res.data.experiance);
     

      $("#skills_m").val(res.data.experiance);

      //$('#courses').val(res.data.course);
    
      $('#additional_course_m').val(res.data.course);

      //$('#comments').val(res.data.comments);
      setStudentComments(res.data.comments);
      setStudentCommentsMobile(res.data.comments);
      $('#comments_m').val(res.data.comments);

      $('#password').val(res.data.password);
      //setPdfDownload(res.data.resumePath);
      //setJobType(res.data.jobType);
      console.log("jobseeker@@@@@@@@" + JSON.stringify(res.data));
      setCategoriesSelected(res.data.categoriesOfExpertise);
      var seekingIn = res.data.seekingEmploymentTypes;
      setStudentResumePath(res.data.resumeUrl);
      if (res.data.resumeUrl != "") {
        $("#showResume").show();
      }


      setTimeout(() => {
       // alert(res.data.comments)
       //$("#firstName_popup").val(res.data.firstName);

        setStudentComments(res.data.comments);
        setStudentCommentsMobile(res.data.comments);

        setStudentSkills(res.data.experiance);
        setStudentSkillsMobile(res.data.experiance);

        setStudentCourses(res.data.course);
        setStudentCoursesMobile(res.data.course);

        $('#pursuing_degree').val(res.data.educationId);
        $('#pursuing_m').val(res.data.educationId);
        $('#collegeIdOther').val(res.data.nameOfCollegeUniversity);
        $('#schoolIdOther').val(res.data.schoolDistrictOther);
        $('#collegeIdOther_m').val(res.data.nameOfCollegeUniversity);
        $('#schoolIdOther_m').val(res.data.schoolDistrictOther);
        console.log(res.data.educationId);
        //alert(res.data.authorization);
        
        $('input[name="authorization"][value="' + res.data.authorization + '"]').prop("selected", true);
        $('input[name="authorization_m"][value="' + res.data.authorization + '"]').prop("selected", true);

        if (res.data.educationId == 3) {
          $('#parent_authorizationm').show();
          $('#parent_authorization').show();
          var selectedyearval = res.data.classYear;
          var currentyearval = new Date().getFullYear();
          //if ((selectedyearval == currentyearval) || (selectedyearval == currentyearval + 1)) {
            $('#planedu_m').show();
            $('#planedu').show();

            $("#continueEducation").val(res.data.highSchoolDiplomaExtContinueEduStatus);
            $("#continueEducation_m").val(res.data.highSchoolDiplomaExtContinueEduStatus);

            if (res.data.highSchoolDiplomaExtContinueEduStatus == "Y") {
              $('#inlineRadio1').prop("checked", true);
              $('#inlineRadio1_m').prop("checked", true);
              $("#continueEduDiv").show();
              $("#continueEduDiv_m").show();
            } else if (res.data.highSchoolDiplomaExtContinueEduStatus == "N") {
              $('#inlineRadio2').prop("checked", true);
              $('#inlineRadio2_m').prop("checked", true);
              $("#continueEduDiv").hide();
              $("#continueEduDiv_m").hide();
            }
            $("#pursuingProgram").val(res.data.highSchoolDiplomaExtPursuingProgram);
            $("#pursuingProgram_m").val(res.data.highSchoolDiplomaExtPursuingProgram);
            $("#collegeId").val(res.data.highSchoolDiplomaExtNameOfInstitutionCollegeId);
            $("#collegeId_m").val(res.data.highSchoolDiplomaExtNameOfInstitutionCollegeId);
          /*}
          else {
            $('#planedu_m').hide();
            $('#planedu').hide();
          }*/

          $('#school_div_m').show();
          $('#school_div').show();

          $('#college_div_m').hide();
          $('#college_div').hide();
          $('#collegeIdOtherDiv').hide();
            $('#collegeIdOtherDiv_m').hide();

          $("#school_district").val(res.data.schoolId);
          $("#school_district_m").val(res.data.schoolId);
          if (res.data.schoolId == '33' || res.data.schoolId == null) {
            $('#schoolIdOtherDiv').show();
            $('#schoolIdOtherDiv_m').show();
          }
          $('#university_name').val();
          $('#university_m').val();
        }
        else {
          console.log('here');
          $('#parent_authorizationm').hide();
          $('#parent_authorization').hide();

          $('#planedu_m').hide();
            $('#planedu').hide();
          
          $('#school_div_m').hide();
          $('#school_div').hide();

          $('#college_div_m').show();
          $('#college_div').show();

          $("#school_district").val();
          $("#school_district_m").val();

          $('#schoolIdOtherDiv').hide();
            $('#schoolIdOtherDiv_m').hide();
            $('#schoolIdOther').val();
          $('#schoolIdOther_m').val();

          $('#university_name').val(res.data.nameOfCollegeUniversityId);
          $('#university_m').val(res.data.nameOfCollegeUniversityId);
          if (res.data.nameOfCollegeUniversityId == '22') {
            $('#collegeIdOtherDiv').show();
            $('#collegeIdOtherDiv_m').show();
          }
        }

        $('#authorization').val(res.data.authorization);
        //$('#authorization').prop('selectedIndex', res.data.authorization);
        console.log("###" + seekingIn);
        for (var i = 0; i < seekingIn.length; i++) {
          console.log("###" + seekingIn[i]);
          $('input[name="seeking"][value="' + seekingIn[i] + '"]').prop("checked", true);
          $('input[name="seeking_m"][value="' + seekingIn[i] + '"]').prop("checked", true);

          $('input[name="seeking"][value="' + seekingIn[i] + '"]').trigger("click");
          $('input[name="seeking_m"][value="' + seekingIn[i] + '"]').trigger("click");
        }

        for (var i = 0; i < companyCatIn.length; i++) {
          console.log("company catein ###" + companyCatIn[i]);
          $('input[name="exp"][value="' + companyCatIn[i] + '"]').prop("checked", true);
          $('input[name="category_m"][value="' + companyCatIn[i] + '"]').prop("checked", true);
          //$('input[name="exp"][value="' + companyCatIn[i] + '"]').trigger("click");
        }

        var isCheck = true;

        var checkedValues = $("input[name='category']").val();
        if (!checkedValues) {
          isCheck = false;
          $("#categoryError").html("Category field is required");
        } else {
          // alert(checkedValues);
          isCheck = true;
          $("#categoryError").html("");
        }

        return isCheck;

        setTimeout(() => {
          console.log("141");
          $('input[name="category"]').trigger("change");
          $('input[name="state"]').trigger("change");
        }, 3000);

      }, 1000);
    });
  }

  function handleChangeSchool(event) {
    var sch = event.target.value;
    $('#schoolIdOther').val('');
    $("#schoolIdOthermsg").hide();   
    if (sch == "33") {
      $("#schoolIdOtherDiv").show();
    } else {
      $("#schoolIdOtherDiv").hide();
    }
  }

  function handleChangeSchool_popup(event) {
    var sch = event.target.value;
    setTimeout(() => {
      $('input[id="inlineRadio2_popup"]').prop('checked', true);
    }, 1000)
    $('#schoolIdOther_popup').val('');
    $("#schoolIdOthermsg_popup").hide();    
    if (sch == "33") {
      $("#schoolIdOtherDiv_popup").show();
    } else {
      $("#schoolIdOtherDiv_popup").hide();
    }
  }

  function handleChangeCollege_popup(event) {
    var clg = event.target.value;
    $('#collegeIdOther_popup').val('');  
    if (clg == "22") {
      $("#collegeIdOtherDiv_popup").show();
    } else {
      $("#collegeIdOtherDiv_popup").hide();
    }
  }

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });


  const submitValueStep1 = () => {

    if ($('#pursuing_m').val() == '') {
      $("#pursuingValidMsg_m").show();
      $(window).scrollTop($('#pursuingValidMsg_m').position().top);
      return false;
    }
    else {
      $("#pursuingValidMsg_m").hide();
      if ($('#pursuing_m').val() == 3) {
        if ($('#school_district_m').val() == '') {
          $("#school_districtValidMsg_m").show();
          $('#school_district_m').focus();
          return false;
        } else {
          $("#school_districtValidMsg_m").hide();
        }

        if ($('#school_district_m').val() == '33' && $('#schoolIdOther_m').val() == '') {
          $("#schoolIdOther_mmsg").show();
          $('#schoolIdOther_m').focus();
          return false;
        } else {
          $("#schoolIdOther_mmsg").hide();
        }

        var classyr = $('#classType_m').val();
        var currentyearval = new Date().getFullYear();
        var selectedEduction = $('input[name="continueEducation_m"]:checked').val();
        //if (((classyr == currentyearval) || (classyr == (currentyearval + 1))) && selectedEduction == 'Y' && ($('#collegeId_m').val() == '')) {
        if (selectedEduction == 'Y' && ($('#collegeId_m').val() == '')) {
          $("#extensionvalidmsg_m").show();
          $(window).scrollTop($('#extensionvalidmsg_m').position().top);
          return false;
        }
        else {
          $("#extensionvalidmsg_m").hide();
        }
      }
      else {
        console.log('here');
        if ($('#university_m').val() == '') {
          $("#university_nameValidMsg_m").show();
          $(window).scrollTop($('#university_nameValidMsg_m').position().top);
          return false;
        }
        else {
          $("#university_nameValidMsg_m").hide();
        }

        if ($('#university_m').val() == 22 && $('#collegeIdOther_m').val() == '') {
          $("#collegeIdOther_mmsg").show();
          $(window).scrollTop($('#collegeIdOther_mmsg').position().top);
          return false;
        }
        else {
          $("#collegeIdOther_mmsg").hide();
        }
      }
    }
    $("#step1").hide();
    $("#step2").show();
    $("#step3").hide();
    $("#step4").hide();
  }


  const getCollegeInfo = () => {
    Services.getEnrolledCollege().then((res) => {
      setCollegeInfo(res.data);
    });
  }

  const submitValue = (data) => {


    var validateEditorTxt = 1;
    if (studentCareers == "") {
      $("#studentCareersValidMsg").show();
      $(window).scrollTop($('#studentCareersValidMsg').position().top);
      validateEditorTxt = 0;
    } else {
      $("#studentCareersValidMsg").hide();
    }

    if (studentSkills == "") {
      $("#studentSkillsValidMsg").show();
      $(window).scrollTop($('#studentSkillsValidMsg').position().top);
      validateEditorTxt = 0;
    } else {
      $("#studentSkillsValidMsg").hide();
    }

    if (studentComments == "") {
      $("#studentCommentsValidMsg").show();
      $(window).scrollTop($('#studentCommentsValidMsg').position().top);
      validateEditorTxt = 0;
    } else {
      $("#studentCommentsValidMsg").hide();
    }

    if ($('#pursuing_degree').val() == '') {
      $("#pursuingValidMsg").show();
      $(window).scrollTop($('#pursuingValidMsg').position().top);
      validateEditorTxt = 0;
    } else {
      $("#pursuingValidMsg").hide();
      if ($('#pursuing_degree').val() == 3) {
        if ($('#school_district').val() == '') {
          $("#school_districtValidMsg").show();
          $(window).scrollTop($('#school_districtValidMsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#school_districtValidMsg").hide();
        }

        if ($('#school_district').val() == '33' && $('#schoolIdOther').val() == '') {
          $("#schoolIdOthermsg").show();
          $(window).scrollTop($('#schoolIdOthermsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#schoolIdOthermsg").hide();
        }

        var classyr = $('#class_type').val();
        var currentyearval = new Date().getFullYear();
        var selectedEduction = $('input[name="continueEducation"]:checked').val();
       // if (((classyr == currentyearval) || (classyr == (currentyearval + 1))) && selectedEduction == 'Y' && ($('#collegeId').val() == '')) {
        if (selectedEduction == 'Y' && ($('#collegeId').val() == '')) {
          $("#extensionvalidmsg").show();
          $(window).scrollTop($('#extensionvalidmsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#extensionvalidmsg").hide();
        }
      }
      else {
        console.log('here');
        if ($('#university_name').val() == '') {
          $("#university_nameValidMsg").show();
          $(window).scrollTop($('#university_nameValidMsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#university_nameValidMsg").hide();
        }

        if ($('#university_name').val() == 22 && $('#collegeIdOther').val() == '') {
          $("#collegeIdOthermsg").show();
          $(window).scrollTop($('#collegeIdOthermsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#collegeIdOthermsg").hide();
        }
      }
    }

    var categoriesArray = [];

    $.each($("input[name='seeking']:checked"), function () {
      categoriesArray.push($(this).val());
    });
    if (categoriesArray.length <= 0) {
      $("#seekinginterestValidMsg").show();
      $(window).scrollTop($('#seekinginterestValidMsg').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#seekinginterestValidMsg").hide();
    }

    var catofExpertiseArray = [];
    $.each($("input[name='exp']:checked"), function () {
      catofExpertiseArray.push($(this).val());
    });
    if (catofExpertiseArray.length <= 0) {
      $("#categoriesinterestedValidMsg").show();
      $(window).scrollTop($('#categoriesinterestedValidMsg').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#categoriesinterestedValidMsg").hide();
    }

    if (validateEditorTxt == 0) {
      return false;
    }

    var phone_val = $("#yourphone2").val();
    var extclgid = $("#collegeId").val();
    ///phone_val = "1234567899";
    const requestObject = {

      "studentId": studentId,
      "siteId": process.env.React_App_SITE_ID,
      "classYear": $("#class_type").val(),
      "firstName": $('#firstName').val(),
      "lastName": $('#lastName').val(),
      "email": studentEmail,
      "educationId": $('#pursuing_degree').val(),
      "nameOfCollegeUniversity": $('#collegeIdOther').val(),
      "nameOfCollegeUniversityId": $('#university_name').val(),
      "schoolDistrictOther": $('#schoolIdOther').val(),
      //"phone":$('#yourphone2').val(),
      "phone": phone_val,
      "isStudent": true,////////////////////////////
      //"reasonForSelectCareers":$('#careers').val(),
      "reasonForSelectCareers": studentCareers,
      //
      "highSchoolDiplomaExtContinueEduStatus": selectedEduction,
      "highSchoolDiplomaExtNameOfInstitutionCollegeId": extclgid,
      "highSchoolDiplomaExtPursuingProgram": $('#pursuingProgram').val(),
      //"course": $('#courses').val(),
      "course": 'studentCourses',

      //"experiance":$('#skills').val(),
      "experiance": studentSkills,
      "helpPrepare": "yes",
      //"comments": $('#comments').val(),
      "comments": studentComments,
      "status": "1",
      "schoolId": $("#school_district").val(),

      //"password": $('#password').val(),
      "categoriesOfExpertise": catofExpertiseArray,
      // ///////////////////////////////////
      // "interested": $('#interested').val(),
      "seekingEmploymentTypes": categoriesArray,
      // "seeking": $('#skills').val(),
      //"events": $('#events').val(),
      "authorization": $('#authorization').val(),
      "studentTalentConnectionForumIn": [1, 2],
      "studentInterestedIn": [3, 4]
    }
    if (fileName != "" && fileExtension != "") {
      requestObject.pdfByString = fileBase64;
      requestObject.fileName = fileName;
      requestObject.fileExtension = fileExtension;
    }
    console.log(requestObject);
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + accessTokenIn
        //'Authorization': accessTokenIn 
      },
      body: JSON.stringify(requestObject)
    };

    $(".load-wrap").show();
    Services.editStudentProfile(requestOptions).then((res) => {
      $(".load-wrap").hide();
      $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
        $("#successMessage").slideUp(1000);
      });
    });
  }

  function handleChangeCollege_m(event) {
    var clg_m = event.target.value;
    $('#collegeIdOther_m').val('');   
    if (clg_m == "22") {
      $("#collegeIdOtherDiv_m").show();
    } else {
      $("#collegeIdOtherDiv_m").hide();
    }
  }
  
  const curr_date = Date.now();
  const change_personalinfo = () =>{
    var firstname = $("#firstName_popup").val();
    var lastname = $("#lastName_popup").val();
    var phone = $("#phone_popup").val();
    var email = $("#email_popup").val();
    
    const requestObject = {
      "studentId": studentId,
      //step1 fields
      "firstName": firstname,
      "lastName": lastname,
      "email": email,
      "password": "",
      "phone": phone,
      //step2 fields
      "educationId": "",
      "classYear": "",
      "schoolId": "",
      "nameOfCollegeUniversity": "",
      "nameOfCollegeUniversityId": "",
      "schoolDistrictOther": "",
      "highSchoolDiplomaExtContinueEduStatus": "",
      "highSchoolDiplomaExtNameOfInstitutionCollegeId": "",
      "highSchoolDiplomaExtPursuingProgram": "",
      "categoriesOfExpertise": [],
      "studentInterestedIn": [],
      "schoolName": null,
      //step3 fields
      "seekingEmploymentTypes": [],
      "seeking": null,
      "reasonForSelectCareers": "", 
      "experiance": "",
      //step4 fields
      "comments": "",
      "fileName": null,
      "fileExtension": null,
      "pdfByString": null,
      "resumeUrl": null,
      "resumeFilePath": null,

      "dateUpdated": curr_date,
      "dateCreated": "",
      "siteId": process.env.React_App_SITE_ID,
      "helpPrepare": "yes",
      "status": "published",
      "interested": null,
      "events": null,
      "authorization": "",
      "userId": null,
      "countyId": null,
      "isStudent": true,
      "updatedOn": null,
      "securityRoleName": null,
      "course": "studentCourses",
      "studentTalentConnectionForumIn": [],
      "totalElement": null,
      "searchText": null,
      "logo": null,
      "profileS3Pic": null,
      "partiallyRegistered":"N",
      "loggedInUser":"Yes"
    }
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "Bearer "+accessTokenIn
        //'Authorization': accessTokenIn
      },
      body: JSON.stringify(requestObject)
    };
    console.log(JSON.stringify(requestObject));
    
    Services.updateStudentProfile(requestOptions).then((res) => {
      //console.log(res); return false;
      if (res.success) {
        $("#successMessage_pwd").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#successMessage_pwd").slideUp(1000);
          getStudentProfile(studentId);
          closeModal();
        });
      }

    });

   }

   const change_schoolinfo = () =>{
    var validateEditorTxt = 1;

    if ($('#pursuing_degreepopup').val() == '') {
      $("#pursuingValidMsg_popup").show();
      $(window).scrollTop($('#pursuingValidMsg_popup').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#pursuingValidMsg_popup").hide();
      if ($('#pursuing_degreepopup').val() == 3) {
        if ($('#school_district_popup').val() == '') {
          $("#school_districtValidMsg_popup").show();
          $(window).scrollTop($('#school_districtValidMsg_popup').position().top);
          validateEditorTxt = 0;
        } else {
          $("#school_districtValidMsg_popup").hide();
        }

        if ($('#school_district_popup').val() == 33 && $('#schoolIdOther_popup').val() == '') {
          $("#schoolIdOthermsg_popup").show();
          $(window).scrollTop($('#schoolIdOthermsg_popup').position().top);
          validateEditorTxt = 0;
        } else {
          $("#schoolIdOthermsg_popup").hide();
        }

        var classyr = $('#class_typepopup').val();
        var currentyearval = new Date().getFullYear();
        var selectedEduction = $('input[name="continueEducation_popup"]:checked').val();
        //if (((classyr == currentyearval) || (classyr == (currentyearval + 1))) && selectedEduction == 'Y' && ($('#collegeId_popup').val() == '')) {
        if (selectedEduction == 'Y' && ($('#collegeId_popup').val() == '')) {
          $("#extensionvalidmsg_popup").show();
          $(window).scrollTop($('#extensionvalidmsg_popup').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#extensionvalidmsg_popup").hide();
        }
      }
      else {
        if ($('#university_name_popup').val() == '') {
          $("#university_nameValidMsg_popup").show();
          $(window).scrollTop($('#university_nameValidMsg_popup').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#university_nameValidMsg_popup").hide();
        }

        if ($('#university_name_popup').val() == 22 && $('#collegeIdOther_popup').val() == '') {
          $("#collegeIdOthermsg_popup").show();
          $(window).scrollTop($('#collegeIdOthermsg_popup').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#collegeIdOthermsg_popup").hide();
        }
      }
    }

    if ($('#class_typepopup').val() == '') {
      $("#class_typeValidMsg_popup").show();
      $(window).scrollTop($('#class_typeValidMsg_popup').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#class_typeValidMsg_popup").hide();
    }

    if (validateEditorTxt == 0) {
      return false;
    }

    var selectedEduction = $('input[name="continueEducation_popup"]:checked').val();
    var extclgid = $("#collegeId_popup").val();
    const requestObject = {
      "studentId": studentId,
      //step1 fields
      "firstName": "",
      "lastName": "",
      "email": "",
      "password": "",
      "phone": "",
      //step2 fields
      "educationId": $('#pursuing_degreepopup').val(),
      "classYear": $("#class_typepopup").val(),
      "schoolId": $("#school_district_popup").val(),
      "nameOfCollegeUniversity": $('#collegeIdOther_popup').val(),
      "nameOfCollegeUniversityId": $('#university_name_popup').val(),
      "schoolDistrictOther": $('#schoolIdOther_popup').val(),
      "highSchoolDiplomaExtContinueEduStatus": selectedEduction,
      "highSchoolDiplomaExtNameOfInstitutionCollegeId": extclgid,
      "highSchoolDiplomaExtPursuingProgram": $('#pursuingProgram_popup').val(),
      "studentInterestedIn": [],
      "schoolName": null,
      //step3 fields
      "categoriesOfExpertise": [],
      "seeking": null,
      "reasonForSelectCareers": "", 
      "experiance": "",
      //step4 fields
      "seekingEmploymentTypes": [],
      "comments": "",
      "fileName": null,
      "fileExtension": null,
      "pdfByString": null,
      "resumeUrl": null,
      "resumeFilePath": null,

      "dateUpdated": curr_date,
      "dateCreated": "",
      "siteId": process.env.React_App_SITE_ID,
      "helpPrepare": "yes",
      "status": "published",
      "interested": null,
      "events": null,
      "authorization": "",
      "userId": null,
      "countyId": null,
      "isStudent": true,
      "updatedOn": null,
      "securityRoleName": null,
      "course": "studentCourses",
      "studentTalentConnectionForumIn": [],
      "totalElement": null,
      "searchText": null,
      "logo": null,
      "profileS3Pic": null,
      "partiallyRegistered":"N",
      "loggedInUser":"Yes"
    }
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "Bearer "+accessTokenIn
        //'Authorization': accessTokenIn
      },
      body: JSON.stringify(requestObject)
    };
    console.log(JSON.stringify(requestObject));
    
    Services.updateStudentProfile(requestOptions).then((res) => {
      //console.log(res); return false;
      if (res.success) {
        $("#successMessage_school").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#successMessage_school").slideUp(1000);
          getStudentProfile(studentId);
          closeSchoolModal();
        });
      }
    });
   }

   const change_fieldsinfo = () =>{
      var catofExpertiseArray = [];
      var validateEditorTxt = 1;
      $.each($("input[name='category_popup']:checked"), function () {
        catofExpertiseArray.push($(this).val());
      });
      //alert(catofExpertiseArray.length);
      if(catofExpertiseArray.length == 0){
        $('#categoriesinterestedValidMsg_popup').show();
        $(window).scrollTop($('#categoriesinterestedValidMsg_popup').position().top);
        validateEditorTxt = 0;
      }
      else{
        $('#categoriesinterestedValidMsg_popup').hide();
      }

      if (validateEditorTxt == 0) {
        return false;
      }

      const requestObject = {
        "studentId": studentId,
        //step1 fields
        "firstName": "",
        "lastName": "",
        "email": "",
        "password": "",
        "phone": "",
        //step2 fields
        "educationId": "",
        "classYear": "",
        "schoolId": "",
        "nameOfCollegeUniversity": "",
        "nameOfCollegeUniversityId": "",
        "schoolDistrictOther": "",
        "highSchoolDiplomaExtContinueEduStatus": "",
        "highSchoolDiplomaExtNameOfInstitutionCollegeId": "",
        "highSchoolDiplomaExtPursuingProgram": "",
        "studentInterestedIn": [],
        "schoolName": null,
        //step3 fields
        "categoriesOfExpertise": catofExpertiseArray,
        "seeking": null,
        "reasonForSelectCareers": studentCareers_popup, 
        "experiance": studentSkills_popup,
        //step4 fields
        "seekingEmploymentTypes": [],
        "comments": "",
        "fileName": null,
        "fileExtension": null,
        "pdfByString": null,
        "resumeUrl": null,
        "resumeFilePath": null,
  
        "dateUpdated": curr_date,
        "dateCreated": "",
        "siteId": process.env.React_App_SITE_ID,
        "helpPrepare": "yes",
        "status": "published",
        "interested": null,
        "events": null,
        "authorization": "",
        "userId": null,
        "countyId": null,
        "isStudent": true,
        "updatedOn": null,
        "securityRoleName": null,
        "course": "studentCourses",
        "studentTalentConnectionForumIn": [],
        "totalElement": null,
        "searchText": null,
        "logo": null,
        "profileS3Pic": null,
        "partiallyRegistered":"N",
        "loggedInUser":"Yes"
      }
      var accessTokenIn = sessionStorage.getItem("accToken");
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "Bearer "+accessTokenIn
          //'Authorization': accessTokenIn
        },
        body: JSON.stringify(requestObject)
      };
      console.log(JSON.stringify(requestObject));
      
      Services.updateStudentProfile(requestOptions).then((res) => {
        //console.log(res); return false;
        if (res.success) {
          $("#successMessage_career").fadeTo(2000, 1000).slideUp(1000, function () {
            $("#successMessage_career").slideUp(1000);
            getStudentProfile(studentId);
            closeCareerModal();
          });
        }
      });
   }

   const change_careerinfo = () =>{
    var seekingArray = [];
    var validateEditorTxt = 1;
    $.each($("input[name='seeking_popup']:checked"), function () {
      seekingArray.push($(this).val());
    });

    if(seekingArray.length == 0){
      $('#seekinginterestValidMsg_popup').show();
      $(window).scrollTop($('#seekinginterestValidMsg_popup').position().top);
      validateEditorTxt = 0;
    }
    else{
      $('#seekinginterestValidMsg_popup').hide();
    }

    if (validateEditorTxt == 0) {
      return false;
    }

    const requestObject = {
      "studentId": studentId,
      //step1 fields
      "firstName": "",
      "lastName": "",
      "email": "",
      "password": "",
      "phone": "",
      //step2 fields
      "educationId": "",
      "classYear": "",
      "schoolId": "",
      "nameOfCollegeUniversity": "",
      "nameOfCollegeUniversityId": "",
      "schoolDistrictOther": "",
      "highSchoolDiplomaExtContinueEduStatus": "",
      "highSchoolDiplomaExtNameOfInstitutionCollegeId": "",
      "highSchoolDiplomaExtPursuingProgram": "",
      "studentInterestedIn": [],
      "schoolName": null,
      //step3 fields
      "categoriesOfExpertise": [],
      "seeking": null,
      "reasonForSelectCareers": '', 
      "experiance": '',
      //step4 fields
      "seekingEmploymentTypes": seekingArray,
      "comments": studentComments_popup,
      "resumeUrl": null,
      "resumeFilePath": null,
      "authorization": $('#authorization_popup').val(),

      "dateUpdated": curr_date,
      "dateCreated": "",
      "siteId": process.env.React_App_SITE_ID,
      "helpPrepare": "yes",
      "status": "published",
      "interested": null,
      "events": null,
      "userId": null,
      "countyId": null,
      "isStudent": true,
      "updatedOn": null,
      "securityRoleName": null,
      "course": "studentCourses",
      "studentTalentConnectionForumIn": [],
      "totalElement": null,
      "searchText": null,
      "logo": null,
      "profileS3Pic": null,
      "partiallyRegistered":"N",
      "loggedInUser":"Yes"
    }
    if (fileName != "" && fileExtension != "") {
      requestObject.pdfByString = fileBase64;
      requestObject.fileName = fileName;
      requestObject.fileExtension = fileExtension;
    }
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "Bearer "+accessTokenIn
        //'Authorization': accessTokenIn
      },
      body: JSON.stringify(requestObject)
    };
    console.log(JSON.stringify(requestObject));
    
    Services.updateStudentProfile(requestOptions).then((res) => {
      //console.log(res); return false;
      if (res.success) {
        $("#successMessage_resume").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#successMessage_resume").slideUp(1000);
          getStudentProfile(studentId);
          closeResumeModal();
        });
      }
    });
 }

 function validateCheck() {
  const checked = $('input[name="category_popup"]:checked').length;


  if (checked >= 3) {
    $('input[name="category_popup"]').not(':checked').prop('disabled', true);
  } else {
    $('input[name="category_popup"]').prop('disabled', false);
  }
}

  return (
    <>
      <main class="empr-directory-main cd-desktop">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li>
                    <li class="breadcrumb-item headtitle "><a href="#">Update Student</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">
          <div class="container">
            <div class="row">
              <div class="col-12 ">
                <form onSubmit={handleSubmit(submitValue)}>
                  <div class="white-bg px-5 pt-4 pb-4">

                  <p class="text_abovehead mt-3">Review your information below. You may click on Edit buttons to the right to update corresponding information.</p>

                    <div class="row mt-4 resume-wrap">
                      <div class="row mb-0 pe-0">
                        <div class="col-lg-12 m-auto pe-0">
                            <div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                                <h5 className='mb-0'>PERSONAL INFORMATION</h5>
                                <a id="edit_personalinfo" class="reg-button text-uppercase text-center editbutton">Edit</a>
                              </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">First name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" defaultValue="firstName" id="firstName" onChange={e => setFieldName(e.target.value)}
                          {...register("FirstName", {
                            required: "First name is required.",

                          })} readOnly={true} />
                        <ErrorMessage
                          errors={errors}
                          name="FirstName"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                      </div>
                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">Last name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" defaultValue="lastName" id="lastName" onChange={e => setFieldName(e.target.value)} {...register("lastName", {
                          required: "Last name is required.",

                        })} readOnly={true} />
                        <ErrorMessage
                          errors={errors}
                          name="lastName"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                      </div>
                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="email" class="form-label mr-2">Personal Email <span class="red-text">*</span></label> <small></small></div>
                        <p style={{ fontSize: "12px" }}>Please do not use your school email.</p>
                        <input type="text" class="form-control" defaultValue="email@email.com" id="email" onChange={e => setFieldName(e.target.value)}
                          readOnly={true} />
                        <ErrorMessage
                          errors={errors}
                          name="Email"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                      </div>
                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="phone" class="form-label mr-2">Phone <span class="red-text">*</span></label> <small>XXX-XXX-XXXX Format</small></div>
                        <p style={{ fontSize: "12px" }}>Use your parent's phone number if you do not have one.</p>
                        <input type="text" class="form-control" defaultValue="yourphone2" id="yourphone2" name="contactNumber" maxlength="14" onChangeText={(text) => this.onTextChange(text)} {...register("phone", {
                          required: "Phone field is required.",
                        })} readOnly={true}/>
                        <ErrorMessage
                          errors={errors}
                          name="phone"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                      </div>
                    </div>

                    <div class="row mt-4 resume-wrap">
                      <div class="row mb-0 pe-0">
                        <div class="col-lg-12 m-auto pe-0">
                            <div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                                <h5 className='mb-0'>SCHOOL INFORMATION</h5>
                                <a id="edit_schoolinfo" class="reg-button text-uppercase text-center editbutton">Edit</a>
                              </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">I am Currently Pursuing <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" id="pursuing_degree" disabled={true}>

                          {
                            educations.map((data, index) => (
                              <option value={data.educationId}>{data.name}</option>
                            ))}
                        </select>
                        <div id="pursuingValidMsg" class="error">This Field is required.</div>
                      </div>
                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">GRADUATING YEAR <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" defaultValue="2023" id="class_type" disabled={true}>
                          {/* <option selected="" value=''>Select</option>      */}
                          {
                            currentYearList.map((data, index) => (
                              <option value={data}>{data}</option>
                            ))}
                        </select>
                        {errors.class_type && errors.class_type.type === "required" && <div class="error">Class type is required</div>}
                      </div>
                      <div class="col-lg-6 mb-5" id="school_div">
                        <label for="firstName" class="form-label">school district<span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" defaultValue="1" id="school_district" onChange={handleChangeSchool} disabled={true}>
                          <option selected="" value=''>Select</option>
                          {
                            schoolIds.map((data, index) => (
                              <option value={data.schoolId}>{data.name}</option>
                            ))
                          }
                         {/* <option value="0" >Other</option> */}
                        </select>
                        <div id="school_districtValidMsg" class="error">School District is required.</div>
                      </div>
                      <div class="col-md-6 " id="schoolIdOtherDiv">
                        <div class="d-flex ">
                          <label for="schoolIdOther" class="form-label mr-2">Name of the Institution<span class="red-text">*</span></label>
                        </div>
                        <input type="text" class="form-control " id="schoolIdOther" aria-describedby="emailHelp" placeholder="Other" readOnly={true}/>
                        <div id="schoolIdOthermsg" class="error">Name of the Institution is required.</div>
                      </div>

                      <div class="col-lg-6 mb-5" id="college_div">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">College / University <span class="red-text">*</span></label></div>
                        <select class="form-select" id="university_name" onChange={handleChangeCollege} disabled={true}>
                          <option value="">Select College</option>
                          {
                            collegeInfo.map((data, index) => (
                              <option value={data.enrolledCollegeId}>{data.name}</option>
                            ))}
                        </select>
                        <div id="university_nameValidMsg" class="error">College / Universities is required.</div>
                      </div>
                      <div class="col-md-6 " id="collegeIdOtherDiv">
                        <div class="d-flex ">
                          <label for="collegeIdOther" class="form-label mr-2">Name of the Institution<span class="red-text">*</span></label>
                        </div>
                        <input type="text" class="form-control " id="collegeIdOther" aria-describedby="emailHelp" placeholder="Other" readOnly={true}/>
                        <div id="collegeIdOthermsg" class="error">Name of the Institution is required.</div>
                      </div>

                      <div class="col-lg-12 mb-4" id="planedu">

                        <div class="d-flex">
                          <label for="education" class="form-label mr-2">Are you planning to go to college after high school? <span class="red-text">*</span></label>
                        </div>

                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="continueEducation" id="inlineRadio1" value="Y" style={{ borderRadius: "30px" }} disabled={true}/>
                          <label class="form-check-label" for="inlineRadio1">YES</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="continueEducation" id="inlineRadio2" value="N"  disabled={true}/>
                          <label class="form-check-label" for="inlineRadio2">NO</label>
                        </div>

                        <div className="row" id="continueEduDiv">
                          <div className="col-md-6 mt-4">
                            <div class="d-flex"><label for="lookingFor" class="form-label mr-2">Name of The Institution <span class="red-text">*</span></label></div>
                            <select class="form-select" id="collegeId" disabled={true}>
                              <option value="">Select College</option>
                              <option value="0">I Don't know yet</option>
                              {
                                collegeInfo.map((data, index) => (
                                  <option value={data.enrolledCollegeId}>{data.name}</option>
                                ))}
                            </select>
                            <div id="extensionvalidmsg" class="error">Name of the Institution is required.</div>
                          </div>

                          <div className="col-md-6 mt-4">
                            <div class="d-flex"><label for="pursuingProgram" class="form-label mr-2">Pursuing Program</label></div>
                            <input type="text" class="form-control " id="pursuingProgram" aria-describedby="emailHelp" placeholder="Program Name" readOnly={true}/>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4 resume-wrap">
                      <div class="row mb-0 pe-0">
                        <div class="col-lg-12 m-auto pe-0">
                            <div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                                <h5 className='mb-0'>FIELDS OF INTEREST</h5>
                                <a id="edit_careerinfo" class="reg-button text-uppercase text-center editbutton">Edit</a>
                              </div>
                        </div>
                      </div>
                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">FIELDS I AM INTERESTED IN<span class="red-text">*</span></label><small>(Select up to three)</small> </div>
                        <div >
                          <div class="category-check-itm">
                            <ul class="category-check">
                              {
                                categories.map((data, index) => (
                                  <li><input class="form-check-input" type="checkbox" name="exp" value={data.catId} id="defaultCheck1" disabled={true}/>  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                                ))}
                            </ul>
                          </div>
                          <div id="categoriesinterestedValidMsg" class="error">Please check atleast one category.</div>
                          {errors.category && errors.category.type === "required" && <div class="error">Please check atleast one category</div>}
                        </div>
                      </div>
                      
                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">I AM INTERESTED IN THESE FIELDS FOR THESE REASONS </label></div>
                         <small className='d-flex mt-2'>(Leave blank if not known.)</small>

                        <SunEditor
                        disable={true}
                          setOptions={{
                            height: "120",
                            textAlign:"left",
                            //buttonList: [['font', 'align'], ['image']]

                            buttonList: [
                             /** ['undo', 'redo'], 
                              ['font', 'fontSize', 'formatBlock'],
                              ['paragraphStyle', 'blockquote'], 
                              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript' ],
                             /** ['fontColor', 'hiliteColor', 'textStyle'],
                              ['removeFormat'],
                              '/', // Line break 
                              ['outdent', 'indent'],
                              ['align', 'horizontalRule', 'list'/**, 'lineHeight' ],
                             /** ['table', 'link', 'image' /** ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                              ['fullScreen', 'showBlocks', 'codeView'],
                              ['preview'], */

                              /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                            ]
                            //buttonList: buttonList.complex
                            // plugins: [font] set plugins, all plugins are set by default
                            // Other option
                          }}
                          disabled={true}
                          onChange={handleChangeStudentCareersDesc}
                          setContents={studentCareers}
                          //onImageUpload={handleImageUpload}
                          onImageUploadBefore={handleImageUploadBefore}
                        
                        />
                        <div id="studentCareersValidMsg" class="error">This field is required.</div>
                      </div>
                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-0">I HAVE THE FOLLOWING RELEVANT SKILLS /EXPERIENCE.</label></div>
                        <small>(Leave blank if not known.)</small>
                        <SunEditor
                        disable={true}
                          setOptions={{
                            height: "120",
                            textAlign:"left",
                            //buttonList: [['font', 'align'], ['image']]

                            buttonList: [
                              /** ['undo', 'redo'], 
                              ['font', 'fontSize', 'formatBlock'],
                             /** ['paragraphStyle', 'blockquote'],  
                              ['bold', 'underline', 'italic' /**, 'strike', 'subscript', 'superscript'/],
                             /** ['fontColor', 'hiliteColor', 'textStyle'],
                              ['removeFormat'],
                              '/', // Line break 
                              ['outdent', 'indent'],
                              ['align', 'horizontalRule', 'list' /**, 'lineHeight' ],
                            /**  ['table', 'link', 'image'  ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                              ['fullScreen', 'showBlocks', 'codeView'],
                              ['preview'], */

                              /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                            ]
                            //buttonList: buttonList.complex
                            // plugins: [font] set plugins, all plugins are set by default
                            // Other option
                          }}
                          onChange={handleChangeStudentSkillsDesc}
                          setContents={studentSkills}
                          //onImageUpload={handleImageUpload}
                          onImageUploadBefore={handleImageUploadBefore}
                        />
                        <div id="studentSkillsValidMsg" class="error">This field is required.</div>
                      </div>
                    </div>

                    <div class="row mt-4 resume-wrap">
                    <div class="row mb-0 pe-0">
                        <div class="col-lg-12 m-auto pe-0">
                            <div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                                <h5 className='mb-0'>CAREER CHOICES</h5>
                                <a id="edit_resumeinfo" class="reg-button text-uppercase text-center editbutton">Edit</a>
                              </div>
                        </div>
                      </div>
                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">I AM SUBMITTING THIS FORM BECAUSE I AM LOOKING FOR:<span class="red-text">*</span></label></div>
                        <div class="category-check-itm">
                          <ul>
                            {
                              jobTypes.map((data, index) => (
                                <li><input class="form-check-input" type="checkbox" name="seeking" value={data.jobTypeId} disabled={true}/>  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                              ))}
                          </ul>
                        </div>
                        <div id="seekinginterestValidMsg" class="error">Please check atleast one</div>
                        {errors.seeking && errors.seeking.type === "required" && <div class="error">Please check atleast one </div>}
                      </div>
                      <div class="col-lg-12 mb-4">
                        <div class="d-flex justify-content-between align-items-end choose">
                          <div>
                            <label for="resumePath" class="form-label">MY RESUME (Optional) </label>
                            <div class="d-flex align-items-center">
                              <input class="form-control form-control-lg " id="resume" type="file" onChange={() => getImage()} readOnly={true} disabled={true}/>
                            </div>
                            <small> 1MB Max, File Type: PDF,DOC, DOCX</small>
                            <div id="fileError" class="error">Invalid file format</div>
                          </div>
                        </div>
                        <div id="showResume" >
                          <br></br>
                          {(studentResumePath != '' && studentResumePath != null) ? <a href={studentResumePath} download="resume" target='_blank'><button type="button" class="reg-button">Download Current Resume</button></a> : ''}
                        </div>
                      </div>
                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">ANY ADDITIONAL THOUGHTS/COMMENTS THAT MIGHT BE HELPFUL? </label></div>
                        <small className='d-flex'>(Leave blank if not known.)</small>
                        <SunEditor
                        disable={true}
                          setOptions={{
                            height: "120",
                            textAlign:"left",
                            //buttonList: [['font', 'align'], ['image']]

                            buttonList: [
                             /**  ['undo', 'redo'],
                              ['font', 'fontSize', 'formatBlock'],
                              /** ['paragraphStyle', 'blockquote'], 
                              ['bold', 'underline', 'italic'/** , 'strike', 'subscript', 'superscript' ],
                             /** ['fontColor', 'hiliteColor', 'textStyle'],
                              ['removeFormat'],  */
                             /** '/', // Line break 
                              ['outdent', 'indent'],
                              ['align', 'horizontalRule', 'list' /** , 'lineHeight' ],
                             /** ['table', 'link', 'image'  ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                              ['fullScreen', 'showBlocks', 'codeView'],
                              ['preview'],  */

                              /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                            ]
                            //buttonList: buttonList.complex
                            // plugins: [font] set plugins, all plugins are set by default
                            // Other option
                          }}
                          onChange={handleChangeStudentCommentsDesc}
                          setContents={studentComments}
                          //onImageUpload={handleImageUpload}
                          onImageUploadBefore={handleImageUploadBefore}
                        />
                        <div id="studentCommentsValidMsg" class="error">This field is required.</div>
                      </div>

                      <div class="col-lg-12 mb-5">
                        <div class="d-flex justify-content-between align-items-end">
                          <div>
                            <div id="parent_authorization">
                              <label for="firstName" class="form-label">MY PARENT/GUARDIAN AUTHORIZES MY INFORMATION TO BE SHARED (if under 18)</label>
                              <select class="form-select" aria-label="Default select example" id="authorization" name="authorization" disabled={true}>
                                <option value="0">Select</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                            </div>
                          </div>
                          <a id="update_done" class="reg-button text-uppercase text-center editbutton">Done</a>
                          <div>
                          </div>
                        </div>
                      </div>

                      <div id="successMessage" class="alert">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <img src="/images/success-alert.png" alt="" />
                            <strong class="mx-2">Success!</strong> Profile info  has been updated successfully.
                          </div>
                          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
          </div>
        </section>
      </main>

      <main class="empr-directory-main cd-mobile">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li>
                    <li class="breadcrumb-item headtitle "><a href="#">Update Student</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- info typography Step 1--> */}
        <section class="company-detail-wrap py-5" id="step1">
          <div class="container">
            <div class="row">
              <div class="col-12 ">
                <form onSubmit={handleSubmit2(submitValueStep1)}>
                  <div class="white-bg px-5 pt-4 pb-4">

                  <p class="text_abovehead mt-3">Review your information below. You may click on Edit buttons to the right to update corresponding information.</p>

                      <div class="row mb-0 pe-0">
                        <div class="col-lg-12 m-auto pe-0">
                            <div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                                <h5 className='mb-0'> PERSONAL INFORMATION</h5>
                                <a id="edit_personalinfo_m" style={{maxWidth:100, marginRight:15}} class="reg-button text-uppercase text-center editbutton">Edit</a>
                              </div>
                        </div>
                      </div>
                      <div class="row mt-4 resume-wrap">
                        <div class="col-lg-6 mb-5">
                          <label for="firstName_m" class="form-label">First name <span class="red-text">*</span></label>
                          <input type="text" class="form-control" id="firstName_m" defaultValue={"First Name"} placeholder="" {...register2('firstName_m', { required: true })} readOnly={true}/>
                          {errors2.firstName_m && errors2.firstName_m.type === "required" && <div class="error">First name is required</div>}
                        </div>
                        <div class="col-lg-6 mb-5">
                          <label for="lastName_m" class="form-label">Last name <span class="red-text">*</span></label>
                          <input type="text" class="form-control" id="lastName_m" defaultValue={"Last Name"} placeholder="" {...register2('lastName_m', { required: true })} readOnly={true}/>
                          {errors2.lastName_m && errors2.lastName_m.type === "required" && <div class="error">Last name is required</div>}
                        </div>

                        <div class="col-lg-6 mb-5">
                          <div class="d-flex"><label for="email_m" class="form-label mr-2">Personal Email <span class="red-text">*</span></label> <small>Please do not use your school email.</small></div>
                          <input type="text" disabled class="form-control" id="email_m" defaultValue={"demo@demo.com"} placeholder="" readOnly={true}/>
                          {errors2.email_m && errors2.email_m.type === "required" && <div class="error">Email is required</div>}
                          {errors2.email_m && errors2.email_m.type === "pattern" && <div class="error">Please provide valid email.</div>}
                        </div>

                        <div class="col-lg-6 mb-5">
                          <div class="d-flex"><label for="mobile1_m" class="form-label mr-2">Phone<span class="red-text">*</span></label> <small>XXX-XXX-XXXX Format</small></div>
                          <input type="text" class="form-control" id="mobile1_m" defaultValue={"1234567899"} {...register2('mobile1_m', { required: true })} readOnly={true}/>
                          {errors2.mobile1_m && errors2.mobile1_m.type === "required" && <div class="error">Phone is required</div>}
                        </div>

                    </div>
                  </div>

                  <div class="white-bg px-5 pt-4 pb-4">
                    <div class="row mb-0 pe-0">
                        <div class="col-lg-12 m-auto pe-0">
                            <div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                                <h5 className='mb-0'> SCHOOL INFORMATION</h5>
                                <a id="edit_schoolinfo_m" style={{maxWidth:100, marginRight:15}} class="reg-button text-uppercase text-center editbutton">Edit</a>
                              </div>
                        </div>
                      </div>
                      <div class="row mt-4 resume-wrap">
                        <div class="col-lg-6 mb-5">
                          <label for="firstName" class="form-label">I am Currently Pursuing <span class="red-text">*</span></label>
                          <select class="form-select" aria-label="Default select example" id="pursuing_m" disabled={true}>
                            {
                              educations.map((data, index) => (
                                <option value={data.educationId}>{data.name}</option>
                              ))}
                          </select>
                          <div id="pursuingValidMsg_m" class="error">This Field is required.</div>
                        </div>

                        <div class="col-lg-6 mb-5">
                          <label for="classType_m" class="form-label">GRADUATING YEAR <span class="red-text">*</span></label>
                          <select class="form-select" aria-label="Default select example" id="classType_m" disabled={true}>
                            
                            <option selected="" value=''>Select</option>
                            {
                              currentYearList.map((data, index) => (
                                <option value={data}>{data}</option>
                              ))}
                          </select>
                          {errors2.classType_m && errors2.classType_m.type === "required" && <div class="error">Class type is required</div>}
                        </div>

                        <div class="col-lg-6 mb-5" id="school_div_m">
                          <label for="school_district_m" class="form-label">school district <span class="red-text">*</span></label>
                          <select class="form-select" aria-label="Default select example" id="school_district_m" onChange={handleChangeSchool_m} defaultValue="1" disabled={true} >
                            
                            <option selected="" value=''>Select</option>
                            {
                              schoolIds.map((data, index) => (
                                <option value={data.schoolId}>{data.name}</option>
                              ))
                            }
                           {/* <option value="0" >Other</option> */}
                          </select>
                          <div id="school_districtValidMsg_m" class="error">School District is required.</div>
                        </div>

                        <div class="col-lg-6" id="schoolIdOtherDiv_m">
                          <div class="d-flex"><label for="schoolIdOther" class="form-label mr-2">Name of the Institution <span class="red-text">*</span></label> </div>
                          <input type="text" class="form-control" id="schoolIdOther_m" aria-describedby="emailHelp" placeholder="Other" readOnly={true}/>
                          <div id="schoolIdOther_mmsg" class="error">Name of the Institution is required.</div>
                        </div>

                        <div class="col-lg-6 mb-5" id="college_div_m">
                          <label for="firstName" class="form-label">College / University<span class="red-text">*</span></label>
                          <select class="form-select" aria-label="Default select example" id="university_m" onChange={handleChangeCollege_m} disabled={true}>
                            
                            <option value="">Select College</option>
                            {
                              collegeInfo.map((data, index) => (
                                <option value={data.enrolledCollegeId}>{data.name}</option>
                              ))}
                          </select>
                          <div id="university_nameValidMsg_m" class="error">College / Universities is required.</div>
                        </div>

                        <div class="col-lg-6" id="collegeIdOtherDiv_m">
                          <div class="d-flex"><label for="collegeIdOther" class="form-label mr-2">Name of the Institution<span class="red-text">*</span></label> </div>
                          <input type="text" class="form-control" id="collegeIdOther_m" aria-describedby="emailHelp" placeholder="Other" readOnly={true}/>
                          <div id="collegeIdOther_mmsg" class="error">Name of the Institution is required.</div>
                        </div>

                        <div class="col-lg-12 mb-4" id="planedu_m">
                          <div class="d-flex">
                            <label for="education" class="form-label mr-2">Are you planning to go to college after high school? <span class="red-text">*</span></label>
                          </div>

                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="continueEducation_m" id="inlineRadio1_m" value="Y" style={{ borderRadius: "30px" }} />
                            <label class="form-check-label" for="inlineRadio1_m">YES</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="continueEducation_m" id="inlineRadio2_m" value="N" />
                            <label class="form-check-label" for="inlineRadio2_m">NO</label>
                          </div>

                          <div className="row" id="continueEduDiv_m">
                            <div className="col-md-6 mt-4">
                              <div class="d-flex"><label for="lookingFor" class="form-label mr-2">Name of The Institution <span class="red-text">*</span></label></div>
                              <select class="form-select" id="collegeId_m" disabled={true}>
                                <option value="">Select College</option>
                                <option value="0">I Don't know yet</option>
                                {
                                  collegeInfo.map((data, index) => (
                                    <option value={data.enrolledCollegeId}>{data.name}</option>
                                  ))}
                              </select>
                              <div id="extensionvalidmsg_m" class="error">Name of the Institution is required.</div>
                            </div>

                            <div className="col-md-6 mt-4">
                              <div class="d-flex"><label for="pursuingProgram_m" class="form-label mr-2">Pursuing Program</label></div>
                              <input type="text" class="form-control " id="pursuingProgram_m" aria-describedby="emailHelp" placeholder="Program Name" readOnly={true}/>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                
                  <div class="white-bg px-4">
              <div class="row mb-0 pe-0">
                        <div class="col-lg-12 m-auto pe-0">
                            <div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                                <h5 className='mb-0'> FIELDS OF INTEREST</h5>
                                <a id="edit_careerinfo_m" style={{maxWidth:100, marginRight:15}} class="reg-button text-uppercase text-center editbutton">Edit</a>
                              </div>
                        </div>
                      </div>
                  <div class="row stu-reg-st3 pt-3">
                  <div class="col-lg-6 mb-5">
                          <label for="firstName" class="form-label">FIELDS I AM INTERESTED IN<span class="red-text">*</span><small>(Select up to three)</small></label>
                          
                          <div class="mobile-form-avl p-3">
                            <div class="category-check-itm">
                              {/* {...register3('category_m', { required: true })} */}
                              <ul>
                                {
                                  categories.map((data, index) => (
                                   <li><input class="form-check-input" type="checkbox" name="category_m" value={data.catId} id="category_m" disabled={true}/>  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                                ))}
                             </ul>
                            </div>
                          </div>
                          {errors3.category && errors3.category.type === "required" && <div class="error">Category is required</div>}
                        </div>
                  
                    <div class="col-lg-12 mb-5">
                      <div><label for="career_m" class="form-label mr-2" >I AM INTERESTED IN THESE FIELDS FOR THESE REASONS <span class="red-text">*</span> <small>(Leave blank if not known.)</small></label> </div>
                      <SunEditor
                      disable={true}
                        setOptions={{
                          height: "120",
                          textAlign:"left",
                          //buttonList: [['font', 'align'], ['image']]

                          buttonList: [
                            /**['undo', 'redo'],
                            ['font', 'fontSize', 'formatBlock'],
                            ['paragraphStyle', 'blockquote'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/', // Line break
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link', 'image' /** ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                            ['fullScreen', 'showBlocks', 'codeView'],
                            ['preview'], */

                            /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                          ]
                          //buttonList: buttonList.complex
                          // plugins: [font] set plugins, all plugins are set by default
                          // Other option
                        }}
                        onChange={handleChangeStudentCareersMobDesc}
                        setContents={studentCareersMobile}
                        //onImageUpload={handleImageUpload}
                        onImageUploadBefore={handleImageUploadBefore}
                      />

                      <div id="studentCareersValidMsgMobile" class="error">This field is required.</div>
                    </div>
                    <div class="col-lg-12 mb-5">
                      <div><label for="skills_m" class="form-label mr-2 mb-0" >I HAVE THE FOLLOWING RELEVANT SKILLS /EXPERIENCE.<span class="red-text">*</span></label> </div>
                      {/* <span class="red-text">Required for Juniors and Seniors</span> */}
                      <small>(Leave blank if not known.)</small>
                      <SunEditor
                      disable={true}
                        setOptions={{
                          height: "120",
                          textAlign:"left",
                          //buttonList: [['font', 'align'], ['image']]

                          buttonList: [
                           /** ['undo', 'redo'],
                            ['font', 'fontSize', 'formatBlock'],
                            ['paragraphStyle', 'blockquote'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/', // Line break
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link', 'image' /** ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                            ['fullScreen', 'showBlocks', 'codeView'],
                            ['preview'], */

                            /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                          ]
                          //buttonList: buttonList.complex
                          // plugins: [font] set plugins, all plugins are set by default
                          // Other option
                        }}
                        onChange={handleChangeStudentSkillsMobDesc}
                        setContents={studentSkillsMobile}
                        //onImageUpload={handleImageUpload}
                        onImageUploadBefore={handleImageUploadBefore}
                      />

                      <div id="studentSkillsValidMsgMobile" class="error">This field is required.</div>
                    </div>
                
{/**
                    <div class="mt-4 mb-4 grid-footer d-flex align-items-center">
                    <button type="button"  onClick={back_step2} class="reg-button"  >Back &#62;&#62;</button>
                      <button type="submit" class="reg-button">Next</button>
                    </div> */}
                  </div>
                </div>
               
                <div class="white-bg px-4 pb-4">
                <div class="row mb-0 pe-0">
                        <div class="col-lg-12 m-auto pe-0">
                            <div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                                <h5 className='mb-0'> CAREER CHOICES</h5>
                                <a id="edit_resumeinfo_m" style={{maxWidth:100, marginRight:15}} class="reg-button text-uppercase text-center editbutton">Edit</a>
                              </div>
                        </div>
                      </div>
                  <div class="row stu-reg-st3 pt-3">
                  <div class="col-lg-12 mb-5">
                      <div><label for="seeking_m" class="form-label mr-2" >I AM SUBMITTING THIS FORM BECAUSE I AM LOOKING FOR:<span class="red-text">*</span></label> </div>
                      <div class="category-check-itm">
                        <ul>
                          {
                            jobTypes.map((data, index) => (
                              <li><input name="seeking_m" class="form-check-input" type="checkbox" value={data.jobTypeId} id="defaultCheck1" disabled={true}/> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                            ))}
                        </ul>
                      </div>
                      {errors4.seeking_m && errors4.seeking_m.type === "required" && <div class="error">Employment is required</div>}
                    </div>
                    <div class="col-lg-12">
                      <div class="justify-content-between align-items-end">
                        <div>
                          <label for="resumePath" class="form-label">My Resume(Optional) </label>
                          <div class="d-flex align-items-center">
                            {/* <input class="form-control form-control-lg choose-file mr-2" id="resumePath" type="file"/> */}
                            <input class="form-control" id="resumeMobile" type="file" onChange={() => getImageMobile()} readOnly={true} disabled={true}/>

                          </div>
                          <small> 1MB Max, File Type: PDF,DOC, DOCX</small>
                          <div id="fileErrorMobile" class="error">Invalid file format</div>
                        </div>
                      </div>

                      <div id="showResume" >
                        <br></br>

                        {(studentResumePath != '' && studentResumePath != null) ? <a href={studentResumePath} download="resume" target='_blank'><button type="button" class="reg-button">Download Current Resume</button></a> : ''}



                      </div>
                    </div>
                    <div class="col-lg-12 mb-5">
                      <div><label for="comments_m" class="form-label mr-2" >ANY ADDITIONAL THOUGHTS/COMMENTS THAT MIGHT BE HELPFUL? <span class="red-text">*</span></label> </div>
                      <small>(Leave blank if not known.)</small>
                      <SunEditor
                      disable={true}
                        setOptions={{
                          height: "120",
                          textAlign:"left",
                          //buttonList: [['font', 'align'], ['image']]

                          buttonList: [
                            /**['undo', 'redo'],
                            ['font', 'fontSize', 'formatBlock'],
                            ['paragraphStyle', 'blockquote'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/', // Line break
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link', 'image' /** ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                            ['fullScreen', 'showBlocks', 'codeView'],
                            ['preview'], */

                            /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                          ]
                          //buttonList: buttonList.complex
                          // plugins: [font] set plugins, all plugins are set by default
                          // Other option
                        }}
                        onChange={handleChangeStudentCommentsMobDesc}
                        setContents={studentComments}
                        //onImageUpload={handleImageUpload}
                        onImageUploadBefore={handleImageUploadBefore}
                      />
                      <div id="studentCommentsValidMsgMobile" class="error">This field is required.</div>
                    </div>


                    <div class="col-lg-12">

                      <div class="justify-content-between align-items-end">
                        <div>
                          <div id="parent_authorizationm">
                            <label for="authorization_m" class="form-label">MY PARENT/GUARDIAN AUTHORIZES MY INFORMATION TO BE SHARED (if under 18)</label>
                            <select class="form-select" aria-label="Default select example" style={{ maxWidth: "420px" }} id="authorization_m" name="authorization_m" disabled={true}>
                              
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>

                        </div>
                        <div>
                          <a id="update_done_m" class="reg-button text-uppercase text-center editbutton">Done</a>
                          </div>
                      </div>
                    </div>
{/**
                    <div class="mt-4 mb-4 grid-footer d-flex align-items-center">
                    <button type="button"  onClick={back_step3} class="reg-button"  >Back &#62;&#62;</button>
                      <button type="submit" class="reg-button">Update</button>
                    </div>
*/}

                    <div id="successMessage_m" class="alert">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <img src="/images/success-alert.png" alt="" />
                          <strong class="mx-2">Success!</strong> Profile info  has been updated successfully.
                        </div>
                        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                      </div>
                    </div>

                  </div>
                </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
          </div>
        </section>


      </main>

      <div>
                {/* <button onClick={openModal} class="reg-button mb-5">WAGE DATA</button> */}
                <Modal
                    isOpen={modalIsOpen}
                    id={"companyJobsModal"}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closepersonalModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="px-4 py-4">
                        <button onClick={closepersonalModal} class="wage-close">close</button>
                        <div className="hd_wp mb-4" style={{display: "flex", position : "relative" , top: -10, maxWidth: "80%" }}>
                            <h5 ref={(_subtitle) => (subtitle = _subtitle)}>UPDATE PERSONAL INFORMATION</h5>
                        </div>
                        <div className="row hd_content ">
                            
                            <div class="col-md-6 mb-3">
                            <label for="firstName" class="form-label d-flex" >First name <span class="red-text">*</span></label>
                            <input type="text" class="form-control" id="firstName_popup" onChange={e => setFieldName(e.target.value)} {...register("firstName_popup", {
                              required: "First name is required.",

                            })} />
                            <ErrorMessage
                              errors={errors}
                              name="firstName_popup"
                              class="invalid-feedback"
                              render={({ messages }) => {
                                console.log("messages", messages);
                                return messages
                                  ? Object.entries(messages).map(([type, message]) => (
                                    <p key={type} class="error">{message}</p>
                                  ))
                                  : null;
                              }}
                            />
                          </div>

                          <div class="col-md-6 mb-3">
                            <label for="firstName" class="form-label d-flex">Last name <span class="red-text">*</span></label>
                            <input type="text" class="form-control" id="lastName_popup" onChange={e => setFieldName(e.target.value)} {...register("lastName_popup", {
                              required: "Last name is required.",

                            })} />
                            <ErrorMessage
                              errors={errors}
                              name="lastName_popup"
                              class="invalid-feedback"
                              render={({ messages }) => {
                                console.log("messages", messages);
                                return messages
                                  ? Object.entries(messages).map(([type, message]) => (
                                    <p key={type} class="error">{message}</p>
                                  ))
                                  : null;
                              }}
                            />
                          </div>

                          <div class="col-md-6 mb-4">
                            <div class="d-flex"><label for="email" class="form-label mr-2">Personal Email <span class="red-text">*</span></label> <small></small></div>
                            <p style={{ fontSize: 11, display: "flex", justifyContent: "flex-start" }}>Your email address cannot be updated.</p>
                            <input type="text" class="form-control" id="email_popup" onChange={e => setFieldName(e.target.value)} {...register("email_popup", {
                              required: "Email is required.",

                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address"
                              }
                            })} readOnly={true}/>
                            <ErrorMessage
                              errors={errors}
                              name="email_popup"
                              class="invalid-feedback"
                              render={({ messages }) => {
                                console.log("messages", messages);
                                return messages
                                  ? Object.entries(messages).map(([type, message]) => (
                                    <p key={type} class="error">{message}</p>
                                  ))
                                  : null;
                              }}
                            />
                          </div>

                          <div class="col-md-6 mb-4">
                            <div class="d-flex"><label for="phone" class="form-label mr-2">Phone<span class="red-text">*</span></label> <small>XXX-XXX-XXXX Format</small></div>
                            <p style={{ fontSize: 11, display: "flex", justifyContent: "flex-start" }}>Use your parent's phone number if you do not have one.</p>
                            <input type="text" class="form-control" id="phone_popup" name="contactNumber" maxlength="14" onChangeText={(text) => this.onTextChange(text)} {...register("phone_popup", {
                              required: "Phone field is required.",
                            })} />
                            <ErrorMessage
                              errors={errors}
                              name="phone_popup"
                              class="invalid-feedback"
                              render={({ messages }) => {
                                console.log("messages", messages);
                                return messages
                                  ? Object.entries(messages).map(([type, message]) => (
                                    <p key={type} class="error">{message}</p>
                                  ))
                                  : null;
                              }}
                            />

                          </div>
                        </div>
                        <div className="hd_footer" >
                            <button onClick={change_personalinfo} class="btn btn-primary" style={{ width: 200}}>Update</button>
                        </div>

                        <div id="successMessage_pwd" class="alert">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <img src="/images/success-alert.png" alt="" />
                            <strong class="mx-2">Success!</strong> Personal Informaton updated successfully.
                          </div>
                          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        </div>
                      </div>

                    </div>
                </Modal>
            </div>
            
            <div>
                {/* <button onClick={openModal} class="reg-button mb-5">WAGE DATA</button> */}
                <Modal
                    isOpen={modalSchoolIsOpen}
                    id={"schoolInfoModal"}
                    onAfterOpen={afterOpenSchoolModal}
                    onRequestClose={closeSchoolModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="px-4 py-4">
                        <button onClick={closeSchoolModal} class="wage-close">close</button>
                        <div className="hd_wp mb-4" style={{display: "flex", position : "relative" , top: -10, maxWidth: "80%"}}>
                            <h5 ref={(_subtitle) => (subtitle = _subtitle)}>UPDATE SCHOOL INFORMATION</h5>
                        </div>
                        <div className="row hd_content ">
                            
                          <div class="col-md-6 mb-3">
                            <label for="firstName" class="form-label d-flex" >I am Currently Pursuing <span class="red-text">*</span></label>
                            <select class="form-select" aria-label="Default select example" id="pursuing_degreepopup">
                            {
                              educations.map((data, index) => (
                                <option value={data.educationId}>{data.name}</option>
                              ))}
                            </select>
                            <div id="pursuingValidMsg_popup" class="error">This Field is required.</div>
                          </div>

                          <div class="col-md-6 mb-3">
                            <label for="firstName" class="form-label d-flex">GRADUATING YEAR <span class="red-text">*</span></label>
                            <select class="form-select" aria-label="Default select example" defaultValue="2023" id="class_typepopup" >
                            {
                              currentYearList.map((data, index) => (
                                <option value={data}>{data}</option>
                              ))}
                            </select>
                            {errors.class_typepopup && errors.class_typepopup.type === "required" && <div class="error">Class type is required</div>}
                          </div>

                          <div class="col-md-6 mb-4" id="school_div_popup">
                            <div class="d-flex"><label for="email" class="form-label mr-2">school district <span class="red-text">*</span></label> <small></small></div>
                            <select class="form-select" aria-label="Default select example" defaultValue="1" id="school_district_popup" onChange={handleChangeSchool_popup}>
                              <option selected="" value=''>Select</option>
                              {
                                schoolIds.map((data, index) => (
                                  <option value={data.schoolId}>{data.name}</option>
                                ))
                              }
                             {/* <option value="0" >Other</option> */}
                            </select>
                            <div id="school_districtValidMsg_popup" class="error">School District is required.</div>
                          </div>
                          
                          <div class="col-md-6 mb-4" id="schoolIdOtherDiv_popup">
                            <div class="d-flex"><label for="phone" class="form-label mr-2">Name of the Institution<span class="red-text">*</span></label></div>
                            <input type="text" class="form-control " id="schoolIdOther_popup" aria-describedby="emailHelp" placeholder="Other" />
                            <div id="schoolIdOthermsg_popup" class="error">Name of the Institution is required.</div>
                          </div>

                          <div class="col-md-6 mb-4" id="college_div_popup">
                            <div class="d-flex"><label for="email" class="form-label mr-2">College / University <span class="red-text">*</span></label> <small></small></div>
                            <select class="form-select" id="university_name_popup" onChange={handleChangeCollege_popup}>
                            <option value="">Select College</option>
                            {
                              collegeInfo.map((data, index) => (
                                <option value={data.enrolledCollegeId}>{data.name}</option>
                              ))}
                            </select>
                            <div id="university_nameValidMsg_popup" class="error">College / Universities is required.</div>
                          </div>

                          <div class="col-md-6 mb-4" id="collegeIdOtherDiv_popup">
                            <div class="d-flex"><label for="phone" class="form-label mr-2">Name of the Institution<span class="red-text">*</span></label></div>
                            <input type="text" class="form-control " id="collegeIdOther_popup" aria-describedby="emailHelp" placeholder="Other" />
                            <div id="collegeIdOthermsg_popup" class="error">Name of the Institution is required.</div>
                          </div>
                          
                          <div class="col-md-12 mb-4" id="planedu_popup">

                            <div class="d-flex">
                              <label for="education" class="form-label mr-2">Are you planning to go to college after high school? <span class="red-text">*</span></label>
                            </div>

                            <div style={{textAlign:"left"}}>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="continueEducation_popup" id="inlineRadio1_popup" value="Y" style={{ borderRadius: "30px" }} />
                                <label class="form-check-label" for="inlineRadio1_popup">YES</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="continueEducation_popup" id="inlineRadio2_popup" value="N" />
                                <label class="form-check-label" for="inlineRadio2_popup">NO</label>
                              </div>
                            </div>

                            <div className="row" id="continueEduDiv_popup">
                              <div className="col-md-6 mt-4">
                                <div class="d-flex"><label for="lookingFor" class="form-label mr-2">Name of The Institution <span class="red-text">*</span></label></div>
                                <select class="form-select" id="collegeId_popup">
                                  <option value="">Select College</option>
                                  <option value="0">I Don't know yet</option>
                                  {
                                    collegeInfo.map((data, index) => (
                                      <option value={data.enrolledCollegeId}>{data.name}</option>
                                    ))}
                                </select>
                                <div id="extensionvalidmsg_popup" class="error">Name of the Institution is required.</div>
                              </div>

                              <div className="col-md-6 mt-4">
                                <div class="d-flex"><label for="pursuingProgram_popup" class="form-label mr-2">Pursuing Program</label></div>
                                <input type="text" class="form-control " id="pursuingProgram_popup" aria-describedby="emailHelp" placeholder="Program Name" />
                              </div>
                            </div>
                          </div>
                        
                        </div>
                        <div className="hd_footer" >
                            <button onClick={change_schoolinfo} class="btn btn-primary" style={{ width: 200}}>Update</button>
                        </div>
                        <div id="successMessage_school" class="alert">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <img src="/images/success-alert.png" alt="" />
                            <strong class="mx-2">Success!</strong> School Information updated successfully.
                          </div>
                          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        </div>
                      </div>

                    </div>
                </Modal>
            </div>

            <div>
                {/* <button onClick={openModal} class="reg-button mb-5">WAGE DATA</button> */}
                <Modal
                    isOpen={modalCareerIsOpen}
                    id={"careerInfoModal"}
                    onAfterOpen={afterOpenCareerModal}
                    onRequestClose={closeCareerModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="px-4 py-4">
                        <button onClick={closeCareerModal} class="wage-close">close</button>
                        <div className="hd_wp mb-4" style={{display: "flex", position : "relative" , top: -10, maxWidth: "80%"}}>
                            <h5 ref={(_subtitle) => (subtitle = _subtitle)}>UPDATE FIELDS OF INTEREST</h5>
                        </div>
                        <div className="row hd_content ">
                        <div class="col-md-12 mb-5">
                          <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">FIELDS I AM INTERESTED IN<span class="red-text">*</span></label><small>(Select up to three)</small> </div>
                          <div >
                            <div class="category-check-itm">
                              <ul class="category-check">
                                {
                                  categories.map((data, index) => (
                                    <li><input class="form-check-input" type="checkbox" name="category_popup" value={data.catId} id="defaultCheck1" onClick={() => validateCheck()}/>  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                  ))}
                              </ul>
                            </div>
                            <div id="categoriesinterestedValidMsg_popup" class="error">Please check atleast one category.</div>
                            {errors.category && errors.category.type === "required" && <div class="error">Please check atleast one category</div>}
                          </div>
                        </div>
                          

                          <div class="col-md-12 mb-3">
                            <div><label for="firstName" class="form-label d-flex">I AM INTERESTED IN THESE FIELDS FOR THESE REASONS </label><small></small></div>
                            <p style={{ fontSize: 11, display: "flex", justifyContent: "flex-start" }}>(Leave blank if not known.)</p>
                            <SunEditor
                              setOptions={{
                                height: "120",
                                textAlign:"left",
                                //buttonList: [['font', 'align'], ['image']]

                                buttonList: [
                                /** ['undo', 'redo'], 
                                  ['font', 'fontSize', 'formatBlock'],
                                  ['paragraphStyle', 'blockquote'], 
                                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript' ],
                                /** ['fontColor', 'hiliteColor', 'textStyle'],
                                  ['removeFormat'],
                                  '/', // Line break 
                                  ['outdent', 'indent'],
                                  ['align', 'horizontalRule', 'list'/**, 'lineHeight' ],
                                /** ['table', 'link', 'image' /** ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                                  ['fullScreen', 'showBlocks', 'codeView'],
                                  ['preview'], */

                                  /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                                ]
                                //buttonList: buttonList.complex
                                // plugins: [font] set plugins, all plugins are set by default
                                // Other option
                              }}
                              onChange={handleChangeStudentCareersDesc_popup}
                              setContents={studentCareers_popup}
                              //onImageUpload={handleImageUpload}
                              onImageUploadBefore={handleImageUploadBefore}
                            />
                            <div id="studentCareersValidMsg_popup" class="error">This field is required.</div>
                          </div>

                          <div class="col-md-12 mb-3">
                            <div><label for="email" class="form-label d-flex">I HAVE THE FOLLOWING RELEVANT SKILLS /EXPERIENCE. </label></div>
                            <p style={{ fontSize: 11, display: "flex", justifyContent: "flex-start" }}>(Leave blank if not known.)</p>
                            <SunEditor
                              setOptions={{
                                height: "120",
                                textAlign:"left",
                                //buttonList: [['font', 'align'], ['image']]

                                buttonList: [
                                  /** ['undo', 'redo'], 
                                  ['font', 'fontSize', 'formatBlock'],
                                /** ['paragraphStyle', 'blockquote'],  
                                  ['bold', 'underline', 'italic' /**, 'strike', 'subscript', 'superscript'/],
                                /** ['fontColor', 'hiliteColor', 'textStyle'],
                                  ['removeFormat'],
                                  '/', // Line break 
                                  ['outdent', 'indent'],
                                  ['align', 'horizontalRule', 'list' /**, 'lineHeight' ],
                                /**  ['table', 'link', 'image'  ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                                  ['fullScreen', 'showBlocks', 'codeView'],
                                  ['preview'], */

                                  /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                                ]
                                //buttonList: buttonList.complex
                                // plugins: [font] set plugins, all plugins are set by default
                                // Other option
                              }}
                              onChange={handleChangeStudentSkillsDesc_popup}
                              setContents={studentSkills_popup}
                              //onImageUpload={handleImageUpload}
                              onImageUploadBefore={handleImageUploadBefore}
                            />
                            <div id="studentSkillsValidMsg_popup" class="error">This field is required.</div>
                          </div>

                        </div>
                        <div className="hd_footer" >
                            <button onClick={change_fieldsinfo} class="btn btn-primary" style={{ width: 200}}>Update</button>
                        </div>

                        <div id="successMessage_career" class="alert">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <img src="/images/success-alert.png" alt="" />
                            <strong class="mx-2">Success!</strong> Fields of Interests updated successfully.
                          </div>
                          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        </div>
                      </div>

                    </div>
                </Modal>
            </div>

            <div>
                {/* <button onClick={openModal} class="reg-button mb-5">WAGE DATA</button> */}
                <Modal
                    isOpen={modalResumeIsOpen}
                    id={"resumeInfoModal"}
                    onAfterOpen={afterOpenResumeModal}
                    onRequestClose={closeResumeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="px-4 py-4">
                        <button onClick={closeResumeModal} class="wage-close">close</button>
                        <div className="hd_wp mb-4" style={{display: "flex", position : "relative" , top: -10, maxWidth: "80%"}}>
                            <h5 ref={(_subtitle) => (subtitle = _subtitle)}>UPDATE CAREER CHOICES</h5>
                        </div>
                        <div className="row hd_content ">
                        <div class="col-md-12 mb-3">
                            <label for="firstName" class="form-label d-flex" >I AM SUBMITTING THIS FORM BECAUSE I AM LOOKING FOR: <span class="red-text">*</span></label>
                            <div class="category-check-itm">
                              <ul>
                                {
                                  jobTypes.map((data, index) => (
                                    <li><input class="form-check-input" type="checkbox" name="seeking_popup" value={data.jobTypeId}  />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                                  ))}
                              </ul>
                            </div>
                            <div id="seekinginterestValidMsg_popup" class="error">Please check atleast one</div>
                            {errors.seeking_popup && errors.seeking_popup.type === "required" && <div class="error">Please check atleast one </div>}
                          </div>
                            <div class="col-md-12 mb-3">
                            <label for="firstName" class="form-label d-flex" >MY RESUME (Optional) </label>
                            <div class="d-flex align-items-center">
                              {/* <input class="form-control form-control-lg choose-file mr-2" id="resumePath" type="file"/> */}
                              <input class="form-control form-control-lg " id="resume_popup" type="file" onChange={() => getImage_popup()} />
                            </div>
                            <small className='d-flex mt-2'> 1MB Max, File Type: PDF,DOC, DOCX</small>
                            <div id="fileError_popup" class="error">Invalid file format</div>
                          </div>

                          <div class="col-md-12 mb-3">
                            <div><label for="firstName" class="form-label d-flex">ANY ADDITIONAL THOUGHTS/COMMENTS THAT MIGHT BE HELPFUL? </label><small></small></div>
                            <p style={{ fontSize: 11, display: "flex", justifyContent: "flex-start" }}>(Leave blank if not known.)</p>
                            <SunEditor
                              setOptions={{
                                height: "120",
                                textAlign:"left",
                                //buttonList: [['font', 'align'], ['image']]

                                buttonList: [
                                /**  ['undo', 'redo'],
                                  ['font', 'fontSize', 'formatBlock'],
                                  /** ['paragraphStyle', 'blockquote'], 
                                  ['bold', 'underline', 'italic'/** , 'strike', 'subscript', 'superscript' ],
                                /** ['fontColor', 'hiliteColor', 'textStyle'],
                                  ['removeFormat'],  */
                                /** '/', // Line break 
                                  ['outdent', 'indent'],
                                  ['align', 'horizontalRule', 'list' /** , 'lineHeight' ],
                                /** ['table', 'link', 'image'  ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                                  ['fullScreen', 'showBlocks', 'codeView'],
                                  ['preview'],  */

                                  /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                                ]
                                //buttonList: buttonList.complex
                                // plugins: [font] set plugins, all plugins are set by default
                                // Other option
                              }}
                              onChange={handleChangeStudentCommentsDesc_popup}
                              setContents={studentComments_popup}
                              //onImageUpload={handleImageUpload}
                              onImageUploadBefore={handleImageUploadBefore}
                            />
                            
                          </div>

                          <div class="col-md-12 mb-3" id="parent_authorization_popup">
                            <div><label for="email" class="form-label d-flex">MY PARENT/GUARDIAN AUTHORIZES MY INFORMATION TO BE SHARED (if under 18) <span class="red-text">*</span></label> <small></small></div>
                            <select class="form-select" aria-label="Default select example"  id="authorization_popup">
                                <option value="yes">Yes</option>
                                <option value="no">No</option>

                              </select>
                          </div>


                        </div>
                        <div className="hd_footer" >
                            <button onClick={change_careerinfo} class="btn btn-primary" style={{ width: 200}}>Update</button>
                        </div>

                        <div id="successMessage_resume" class="alert">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <img src="/images/success-alert.png" alt="" />
                            <strong class="mx-2">Success!</strong> Career choices updated successfully.
                          </div>
                          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        </div>
                      </div>

                    </div>
                </Modal>
            </div>

    </>
  )
};

UpdateStudent.propTypes = {};

UpdateStudent.defaultProps = {};

export default UpdateStudent;
