import React,{history,useEffect, useState, useMemo } from "react";
import Sidebar from "../../utility/sidebar";
import Hiring from "./Hiring";

const ResumeDashboard = () => {

  return(
  <>
    <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="#">Resume Dashboard </a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">

                                {/* <!-- left side --> */}
                                <Sidebar/>
                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">
                                    <h4>Welcome back, Adam!</h4>
                                    <p>This is your Hometown Opportunity Dashboard. Here you’ll see all of the most important information about your job or career search, all in one handy location.</p>

                                    <div class="card p-3">
                                        <div class="announce-wrap">
                                            <div class="orange-ribbon d-flex mb-4">
                                                <img src="images/reminders-icon.png" alt=""/>
                                                <span>REMINDERS & ANNOUNCEMENTS</span>
                                            </div>
                                            <div class="after-orange-ribbon mb-4">
                                                <a href="#">
                                                    <h4>ANNOUNCEMENT NUMBER 1</h4>
                                                </a>
                                                <span>September 29, 2019</span>
                                                <p class="py-2">Wright State University Lake Campus in Celina is proud to announce the starting of the 15th MBA program. This program…</p>
                                            </div>

                                            <div class="after-orange-ribbon mb-4">
                                                <a href="#">
                                                    <h4>ANNOUNCEMENT NUMBER 1</h4>
                                                </a>
                                                <span>September 29, 2019</span>
                                                <p class="py-2">Wright State University Lake Campus in Celina is proud to announce the starting of the 15th MBA program. This program…</p>
                                            </div>
                                        </div>

                                        <div class="pagination-wrap text-center mt-3">
                                            <nav aria-label="...">
                                                <ul class="pagination m-auto d-inline-flex">
                                                    <li class="page-item disabled">
                                                        <span class="page-link"><img src="images/arrow-left.JPG" alt=""/></span>
                                                    </li>
                                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                    <li class="page-item active" aria-current="page">
                                                        <span class="page-link">2</span>
                                                    </li>
                                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                    <li class="page-item">
                                                        <a class="page-link" href="#"><img src="images/arrow-right.JPG" alt=""/></a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>

                                    <div class="card p-3 mt-5">
                                        <div class="announce-wrap mb-4">

                                            <div class="orange-ribbon d-flex mb-4">
                                                <img src="images/categories-icon.png" alt=""/>
                                                <span>YOUR CATEGORIES OF INTEREST</span>
                                            </div>

                                            <div class="after-orange-ribbon mb-5">

                                                <a href="#">
                                                    <h4>ADVERTISING & PR SERVICES </h4>
                                                </a>
                                                <a href="#">
                                                    <h4>ARCHITECTUREAL AND DESIGN SERVICES</h4>
                                                </a>
                                                <a href="#">
                                                    <h4>ART</h4>
                                                </a>
                                                <a href="#">
                                                    <h4>GRAPHIC DESIGN</h4>
                                                </a>
                                            </div>
                                            <div class="d-flex flex-md-row flex-column">
                                                <a href="#" class="grey-button mt-3 mr-2  text-uppercase text-cente">update my categories</a>
                                                <a href="#" class="carousel-button  mt-3 text-uppercase text-cente">explore jobs</a>
                                            </div>
                                        </div>

                                        <div class="pagination-wrap text-center mt-3">
                                            <nav aria-label="...">
                                                <ul class="pagination m-auto d-inline-flex">
                                                    <li class="page-item disabled">
                                                        <span class="page-link"><img src="images/arrow-left.JPG" alt=""/></span>
                                                    </li>
                                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                    <li class="page-item active" aria-current="page">
                                                        <span class="page-link">2</span>
                                                    </li>
                                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                    <li class="page-item">
                                                        <a class="page-link" href="#"><img src="images/arrow-right.JPG" alt=""/></a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>

                                    <div class="card p-3 mt-5">
                                        <div class="announce-wrap">

                                            <div class="orange-ribbon d-flex mb-4">
                                                <img src="images/job-opp-icon.png" alt=""/>
                                                <span>YOUR FAVORITE CAREER OPPORTUNITIES</span>
                                            </div>

                                            <div class="oppertunity-card">
                                                <div class="logo-org">
                                                    <img src="images/midmark-logo.JPG" alt=""/>
                                                </div>
                                                <div class="right-info">
                                                    <a href="#">
                                                        <h4>ANNOUNCEMENT NUMBER 1</h4>
                                                    </a>
                                                    <div class="d-flex">
                                                        <span>Midmark Corporation </span>
                                                        <span class="mx-3">|</span>
                                                        <span> Versailles, Ohio</span>
                                                    </div>
                                                    <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                                                    <b class="text-uppercase">August 19, 2021</b>
                                                </div>
                                            </div>
                                            <div class="oppertunity-card">
                                                <div class="logo-org">
                                                    <img src="images/midmark-logo.JPG" alt=""/>
                                                </div>
                                                <div class="right-info">
                                                    <a href="#">
                                                        <h4>ANNOUNCEMENT NUMBER 1</h4>
                                                    </a>
                                                    <div class="d-flex">
                                                        <span>Midmark Corporation </span>
                                                        <span class="mx-3">|</span>
                                                        <span> Versailles, Ohio</span>
                                                    </div>
                                                    <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                                                    <b class="text-uppercase">August 19, 2021</b>
                                                </div>
                                            </div>
                                            <div class="oppertunity-card">
                                                <div class="logo-org">
                                                    <img src="images/midmark-logo.JPG" alt=""/>
                                                </div>
                                                <div class="right-info">
                                                    <a href="#">
                                                        <h4>ANNOUNCEMENT NUMBER 1</h4>
                                                    </a>
                                                    <div class="d-flex">
                                                        <span>Midmark Corporation </span>
                                                        <span class="mx-3">|</span>
                                                        <span> Versailles, Ohio</span>
                                                    </div>
                                                    <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                                                    <b class="text-uppercase">August 19, 2021</b>
                                                </div>
                                            </div>
                                            <div class="oppertunity-card">
                                                <div class="logo-org">
                                                    <img src="images/midmark-logo.JPG" alt=""/>
                                                </div>
                                                <div class="right-info">
                                                    <a href="#">
                                                        <h4>ANNOUNCEMENT NUMBER 1</h4>
                                                    </a>
                                                    <div class="d-flex">
                                                        <span>Midmark Corporation </span>
                                                        <span class="mx-3">|</span>
                                                        <span> Versailles, Ohio</span>
                                                    </div>
                                                    <p class="mt-2">The Senior Product and Services Manager researches and develops the market for services to support the organization.…</p>
                                                    <b class="text-uppercase">August 19, 2021</b>
                                                </div>
                                            </div>


                                            <div class="pagination-wrap text-center mt-3">
                                                <nav aria-label="...">
                                                    <ul class="pagination m-auto d-inline-flex">
                                                        <li class="page-item disabled">
                                                            <span class="page-link"><img src="images/arrow-left.JPG" alt=""/></span>
                                                        </li>
                                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                        <li class="page-item active" aria-current="page">
                                                            <span class="page-link">2</span>
                                                        </li>
                                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#"><img src="images/arrow-right.JPG" alt=""/></a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>

                                        </div>


                                    </div>

                                    <div class="card p-3 mt-5">
                                        <div class="announce-wrap">

                                            <div class="orange-ribbon d-flex mb-4">
                                                <img src="images/play-icon.png" alt=""/>
                                                <span>YOUR FAVORITE COMPANY VIDEOS</span>
                                            </div>


                                            <div class="video-section2 mb-4">
                                                <div class="video-item"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                                <div class="info-vid">
                                                    <h4>AGRANA FRUIT US, INC</h4>
                                                    <span> Botkins, Ohio</span>
                                                    <div class="stock-wrap justify-content-between align-items-center">
                                                        <a href="#">15 OPEN JOBS</a>
                                                    </div>
                                                </div>
                                                <div class="vid-close d-flex align-items-center">
                                                    <a href="#"> <img src="images/close.jpg" alt=""/></a>
                                                </div>
                                            </div>
                                            <div class="video-section2 mb-4">
                                                <div class="video-item"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                                <div class="info-vid">
                                                    <h4>AGRANA FRUIT US, INC</h4>
                                                    <span> Botkins, Ohio</span>
                                                    <div class="stock-wrap justify-content-between align-items-center">
                                                        <a href="#">15 OPEN JOBS</a>
                                                    </div>
                                                </div>
                                                <div class="vid-close d-flex align-items-center">
                                                    <a href="#"> <img src="images/close.jpg" alt=""/></a>
                                                </div>
                                            </div>
                                            <div class="video-section2 mb-4">
                                                <div class="video-item"> <img src="images/screen-shot.png" class="w-100" alt=""/></div>
                                                <div class="info-vid">
                                                    <h4>AGRANA FRUIT US, INC</h4>
                                                    <span> Botkins, Ohio</span>
                                                    <div class="stock-wrap justify-content-between align-items-center">
                                                        <a href="#">15 OPEN JOBS</a>
                                                    </div>
                                                </div>
                                                <div class="vid-close d-flex align-items-center">
                                                    <a href="#"> <img src="images/close.jpg" alt=""/></a>
                                                </div>
                                            </div>


                                            <div class="pagination-wrap text-center mt-3">
                                                <nav aria-label="...">
                                                    <ul class="pagination m-auto d-inline-flex">
                                                        <li class="page-item disabled">
                                                            <span class="page-link"><img src="images/arrow-left.JPG" alt=""/></span>
                                                        </li>
                                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                        <li class="page-item active" aria-current="page">
                                                            <span class="page-link">2</span>
                                                        </li>
                                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#"><img src="images/arrow-right.JPG" alt=""/></a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>

                                        </div>


                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
       <Hiring/>
    </main>
  </>
)};

ResumeDashboard.propTypes = {};

ResumeDashboard.defaultProps = {};

export default ResumeDashboard;
