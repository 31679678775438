import React, { useEffect, useState } from 'react';

import { ErrorMessage } from "@hookform/error-message";
import { set, useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import StudentRegistration2 from '../student/student-registration2';
import StudentRegistration3 from '../student/student-registration3';
import { useNavigate, useParams, useLocation, useHistory, Link } from "react-router-dom";
import Services from '../../services/services';
//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SunEditor, { buttonList } from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';

const EditStudent = () => {
  const navigate = useNavigate();
  const [categories, setCategory] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [jobTypes, setJobType] = useState([]);
  const [schoolIds, setSchoolInData] = useState([]);

  const [currentYearList, setCurrentYearList] = useState([]);
  const [itemName, setFieldName] = useState('');
  const [studentResumePath, setStudentResumePath] = useState('');
  const [firstNameMobile, setFirstNameMobile] = useState('');

  const [fileNameMobile, setFileNameMobile] = useState('');
  const [fileExtensionMobile, setFileExtensionMobile] = useState('');
  const [fileBase64Mobile, setFileBase64Mobile] = useState('');

  const [fileName, setFileName] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const [fileBase64, setFileBase64] = useState('');

  const [studentCareers, setStudentCareers] = useState('');
  const [studentCareersMobile, setStudentCareersMobile] = useState([]);

  const [coursesRelated, setCoursesRelated] = useState([]);
  const [studentSkills, setStudentSkills] = useState('');
  const [studentSkillsMobile, setStudentSkillsMobile] = useState([]);

  const [studentCourses, setStudentCourses] = useState('');
  const [studentCoursesMobile, setStudentCoursesMobile] = useState([]);

  const [studentComments, setStudentComments] = useState('');
  const [studentCommentsMobile, setStudentCommentsMobile] = useState([]);

  const [studentEmail, setStudentEmail] = useState('');
  const roleArChck = sessionStorage.getItem("roleAr");
  const chKRoleAr = sessionStorage.getItem("roleAr");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const jobSeekerId = queryParams.get('jobSeekerId');
  const studentIdGet = queryParams.get('studentId');
  const stpage = queryParams.get('pageid');
  const adpage = queryParams.get('adpage');
  const pageref = queryParams.get('pageref');

  
  // const studenEmail = '';
  //const studentId = 56;
  const [collegeInfo, setCollegeInfo] = useState([]);
  const [educations, setEducation] = useState([]);

  const {
    register: register3,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });

  const submitValueStep3 = () => {

    $("#step1").hide();
    $("#step2").hide();
    $("#step3").show();

  }


  const {
    register: register4,
    formState: { errors: errors4 },
    handleSubmit: handleSubmit4,
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });


  function handleChangeSchool(event) {
    //alert("teste")
    //alert(event.target.value);
    var sch = event.target.value;
    $('#schoolIdOther').val('');
    $("#schoolIdOthermsg").hide();
    //console.log(event.target.value);
    //var sch = $("#schoolId'").val();    
    if (sch == "0") {
      $("#schoolIdOtherDiv").show();
    } else {
      $("#schoolIdOtherDiv").hide();
    }
  }

  const getCollegeInfo = () => {
    Services.getEnrolledCollege().then((res) => {
      //console.log("getEducation data=" + JSON.stringify(res.data));
      setCollegeInfo(res.data);
    });
  }

  function handleChangeSchool_m(event) {
    //alert("teste")
    //alert(event.target.value);
    var sch_m = event.target.value;
    $('#schoolIdOther_m').val('');
    //console.log(event.target.value);
    //var sch = $("#schoolId'").val();    
    if (sch_m == "0") {
      $("#schoolIdOtherDiv_m").show();
    } else {
      $("#schoolIdOtherDiv_m").hide();
    }
  }


  function handleChangeStudentCareersDesc(content) {
    setStudentCareers(content);
  }

  function handleChangeStudentSkillsDesc(content) {
    setStudentSkills(content);
  }


  //
  function handleImageUploadBefore(files, info, uploadHandler) {
    var reader1 = new FileReader();
    reader1.readAsDataURL(files[0]);
    reader1.onload = function () {
      var rdt1 = reader1.result;
      var base64result_banner = rdt1.split(',')[1];
      var bname = files[0].name;
      var bextn = bname.split(".").pop();
      var bannerActName = bname.split(".")[0];
      var fileext_banner = bextn;

      const requestObject = {
        //"imageName": files[0].name,
        "imageName": bannerActName,
        "imageExtension": fileext_banner,
        "imageByteString": base64result_banner
      };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "Bearer "+accessTokenIn 
          //'Authorization': accessTokenIn 
        },
        body: JSON.stringify(requestObject)
      };

      Services.fileUploadS3URL(requestOptions).then((res) => {
        const response = {
          // The response must have a "result" array.
          "result": [
            {
              "url": res.url,
              "temp": files[0],
              "name": bannerActName,
              "size": files[0].size
            },
          ]
        }
        uploadHandler(response);

      });
    };

  }
  function handleChangeStudentCommentsDesc(content) {
    setStudentComments(content);
  }

  function handleChangeStudentCareersMobDesc(content) {
    setStudentCareersMobile(content);
  }
  function handleChangeStudentSkillsMobDesc(content) {
    setStudentSkillsMobile(content);
  }



  function handleChangeStudentCommentsMobDesc(content) {
    setStudentCommentsMobile(content);
  }
  function handleChangeCollege(event) {
    //alert("teste")
    //alert(event.target.value);
    var clg = event.target.value;
    $('#collegeIdOther').val('');
    //console.log(event.target.value);
    //var sch = $("#schoolId'").val();    
    if (clg == "22") {
      $("#collegeIdOtherDiv").show();
    } else {
      $("#collegeIdOtherDiv").hide();
    }
  }

  function handleChangeCollege_m(event) {
    //alert("teste")
    //alert(event.target.value);
    var clg_m = event.target.value;
    //console.log(event.target.value);
    //var sch = $("#schoolId'").val();    
    if (clg_m == "22") {
      $("#collegeIdOtherDiv_m").show();
    } else {
      $("#collegeIdOtherDiv_m").hide();
    }
  }

  //   const history = useHistory();
  // const navigate = useNavigate();
  const submitValueStep4 = () => {


    var validateEditorTxt = 1;
    /*if (studentCareersMobile == "") {
      $("#studentCareersValidMsgMobile").show();
      $(window).scrollTop($('#studentCareersValidMsgMobile').position().top);
      validateEditorTxt = 0;
    } else {
      $("#studentCareersValidMsgMobile").hide();
    }


    if (studentSkillsMobile == "") {
      $("#studentSkillsValidMsgMobile").show();
      $(window).scrollTop($('#studentSkillsValidMsgMobile').position().top);
      validateEditorTxt = 0;
    } else {
      $("#studentSkillsValidMsgMobile").hide();
    } */

    /*
    if(studentCoursesMobile == ""){
      $("#studentCoursesValidMsgMobile").show();  
     $(window).scrollTop($('#studentCoursesValidMsgMobile').position().top);
      validateEditorTxt = 0;     
    }else{
      $("#studentCoursesValidMsgMobile").hide();  
    }

    */
/*
    if (studentCommentsMobile == "") {
      $("#studentCommentsValidMsgMobile").show();
      $(window).scrollTop($('#studentCommentsValidMsgMobile').position().top);
      validateEditorTxt = 0;
    } else {
      $("#studentCommentsValidMsgMobile").hide();
    } */

    if ($('#pursuing_m').val() == 3) {
      if ($('#school_district_m').val() == '') {
        $("#school_districtValidMsg_m").show();
        $(window).scrollTop($('#school_districtValidMsg_m').position().top);
        validateEditorTxt = 0;
      }
    }
    else {
      console.log('here');
      if ($('#university_m').val() == '') {
        $("#university_nameValidMsg_m").show();
        $(window).scrollTop($('#university_nameValidMsg_m').position().top);
        validateEditorTxt = 0;
      }
    }

    if (validateEditorTxt == 0) {
      return false;
    }

    var categoriesArray = [];

    $.each($("input[name='seeking_m']:checked"), function () {
      categoriesArray.push($(this).val());
    });
    var catofExpertiseArray = [];
    $.each($("input[name='category_m']:checked"), function () {
      catofExpertiseArray.push($(this).val());
    });
    var phone_val = $("#yourphone2").val();

    var selectedEduction = $('input[name="continueEducation_m"]:checked').val();
    var extclgid = $("#collegeId_m").val();

    const requestObject = {

      "studentId": studentIdGet,
      "siteId": process.env.React_App_SITE_ID,
      "classYear": $("#class_type").val(),
      "firstName": $('#firstName_m').val(),
      "lastName": $('#lastName_m').val(),
      "email": $('#email_m').val(),
      "educationId": $('#pursuing_m').val(),
      "nameOfCollegeUniversity": $('#collegeIdOther_m').val(),
      "nameOfCollegeUniversityId": $('#university_m').val(),
      "schoolDistrictOther": $('#schoolIdOther_m').val(),

      //"phone":$('#yourphone2').val(),
      "phone": $('#mobile1_m').val(),
      "isStudent": true,////////////////////////////
      //"reasonForSelectCareers":$('#careers').val(),
      //"reasonForSelectCareers":$("#careers_m").val(),
      "reasonForSelectCareers": studentCareersMobile,

      "course": studentCoursesMobile,

      //"experiance":$('#skills').val(),
      //"experiance":$("#skills_m").val(),
      "experiance": studentSkillsMobile,


      "helpPrepare": "yes",
      //"comments": $('#comments').val(),
      //"comments":$("#comments_m").val(),
      "comments": studentCommentsMobile,

      "status": "1",

      "schoolId": $("#school_district_m").val(),

      //"password": $('#password').val(),
      "categoriesOfExpertise": catofExpertiseArray,
      //
      "highSchoolDiplomaExtContinueEduStatus": selectedEduction,
      "highSchoolDiplomaExtNameOfInstitutionCollegeId": extclgid,
      "highSchoolDiplomaExtPursuingProgram": $('#pursuingProgram_m').val(),
      // ///////////////////////////////////
      // "interested": $('#interested').val(),
      "seekingEmploymentTypes": categoriesArray,
      // "seeking": $('#skills').val(),
      //"events": $('#events').val(),
      "authorization": $('#authorization_m').val(),
      "studentTalentConnectionForumIn": [1, 2],
      "studentInterestedIn": [3, 4]
    }


    if (fileNameMobile != "" && fileExtensionMobile != "") {
      requestObject.pdfByString = fileBase64Mobile;
      requestObject.fileName = fileNameMobile;
      requestObject.fileExtension = fileExtensionMobile;
    }

    console.log(requestObject);
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + accessTokenIn
        //'Authorization': accessTokenIn 
      },
      body: JSON.stringify(requestObject)
    };
    console.log(JSON.stringify(requestObject));
    $(".load-wrap").show();
    Services.editStudentProfile(requestOptions).then((res) => {
      $(".load-wrap").hide();
      $("#successMessage_m").fadeTo(2000, 1000).slideUp(1000, function () {
        $("#successMessage_m").slideUp(1000);
      });

      setTimeout(function () { 
        if(pageref == 'v'){
          navigate('/studentProfileView?studentId='+studentIdGet+'&pageid='+stpage); 
        } else {
          navigate('/studentList'); 
        }
      }, 1000);

    });

    //navigate('/home');
    // history.push("/studentRegistration");
  }






  const studentId = sessionStorage.getItem("logStudentId");


  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });


  $('#class_type').change(function () {
    var classyr = $('#class_type').val();
    var currentyearval = new Date().getFullYear();

    //alert(currentyearval);
    /*if (($('#pursuing_degree').val() == 3) && ((classyr == currentyearval) || (classyr == (currentyearval + 1)))) {*/
    if (($('#pursuing_degree').val() == 3)) {
      $('#planedu').show();
    }
    else {
      $('#planedu').hide();
    }
  })

  $('#classType_m').change(function () {
    var classyr = $('#classType_m').val();
    var currentyearval = new Date().getFullYear();

    //alert(currentyearval);
    //if (($('#pursuing_m').val() == 3) && ((classyr == currentyearval) || (classyr == (currentyearval + 1)))) 
    if (($('#pursuing_m').val() == 3)) 
    {
      $('#planedu_m').show();
    }
    else {
      $('#planedu_m').hide();
    }
  })

  $('#pursuing_degree').change(function () {
    //alert($('#pursuing_degree').val());
    $('#school_districtValidMsg').hide();
    $('#university_nameValidMsg').hide();
    $('#schoolIdOther').val('');
    $('#collegeIdOther').val('');
    $('#school_district').val('');
    $('#schoolIdOtherDiv').hide();
    $('#university_name').val('');
    $('#collegeIdOtherDiv').hide();
    if ($('#pursuing_degree').val() == 3) {
      $('#school_div').show();
      $('#college_div').hide();
      $('#school_district').prop('disabled', false);
      $('#university_name').prop('disabled', true);
      $('#class_type').change();
      $('#parent_authorizationw').show();
    }
    else {
      $('#planedu').hide();
      $('#school_div').hide();
      $('#college_div').show();
      $('#school_district').prop('disabled', true);
      $('#university_name').prop('disabled', false);
      $('#parent_authorizationw').hide();
    }
  })

  $('#pursuing_m').change(function () {
    //alert($('#pursuing_degree').val());
    $('#school_districtValidMsg_m').hide();
    $('#university_nameValidMsg_m').hide();
    $('#schoolIdOther_m').val('');
    $('#collegeIdOther_m').val('');
    $('#schoolIdOtherDiv_m').hide();
    $('#school_district_m').val('');
    $('#university_m').val('');
    $('#collegeIdOtherDiv_m').hide();
    if ($('#pursuing_m').val() == 3) {
      $('#school_div_m').show();
      $('#college_div_m').hide();
      $('#school_district_m').prop('disabled', false);
      $('#university_m').prop('disabled', true);
      $('#classType_m').change();
      $('#parent_authorization').show();
    }
    else {
      $('#planedu_m').hide();
      $('#school_div_m').hide();
      $('#college_div_m').show();
      $('#school_district_m').prop('disabled', true);
      $('#university_m').prop('disabled', false);
      $('#parent_authorization').hide();
    }
  })

  useEffect(() => {
  //   const jsonData = {
  //     "editStudent": {
        
  //     }
  //   };

  // var jsondataformatted = JSON.stringify(jsonData);
  // sessionStorage.setItem('pagejsonObject', jsondataformatted);

    //alert(roleArChck);
    if (roleArChck != "schoolsuper" && roleArChck != "schooladmin") {
      //navigate('/login');
      navigate('/Home');
    }
    $(".load-wrap").hide();
    $(".se-dialog-tabs").hide();

    $('#seekinginterestValidMsg').hide();
    $("#categoriesinterestedValidMsg").hide();
    $("#studentCareersValidMsg").hide();
    $("#studentCareersValidMsgMobile").hide();
    $("#coursesRelatedValidMsg").hide();
    $("#coursesRelatedValidMsgMobile").hide();
    $("#studentSkillsValidMsg").hide();
    $("#studentSkillsValidMsgMobile").hide();
    $("#studentCoursesValidMsg").hide();
    $("#studentCoursesValidMsgMobile").hide();
    $("#studentCommentsValidMsg").hide();
    $("#studentCommentsValidMsgMobile").hide();

    $('#pursuingValidMsg_m').hide();
    $("#showResume").hide();
    $("#successMessage").hide();
    $("#successMessage_m").hide();
    $('#yourphone2').usPhoneFormat();
    $('#mobile1').usPhoneFormat();
    $('#mobile1_m').usPhoneFormat();
    $('#step2').hide();
    $('#step3').hide();
    $('#school_districtValidMsg').hide();
    $('#university_nameValidMsg').hide();
    $('#school_districtValidMsg_m').hide();
    $('#university_nameValidMsg_m').hide();
    getJobType();
    getIndustryData();
    getSchoolnData();
    getCurrentYear();
    getStudentProfile(studentIdGet);
    getEducation();
    getCollegeInfo();
    $('#college_div').hide();
    $("#fileError").hide();

    $("#schoolIdOtherDiv").hide();
    $("#schoolIdOtherDiv_m").hide();
    $('#collegeIdOtherDiv').hide();
    $("#collegeIdOtherDiv_m").hide();
    $('#schoolIdOthermsg').hide();
    $('#collegeIdOthermsg').hide();
    $('#schoolIdOther_mmsg').hide();
    $('#collegeIdOther_mmsg').hide();
    $('#planedu').hide();
    $('#extensionvalidmsg').hide();
    $('#planedu_m').hide();
    $('#extensionvalidmsg_m').hide();
    setTimeout(() => {
      $('#pursuing_degree').val('3');
      $('#pursuing_m').val('3');
      $('#parent_authorization').show();
      $('#parent_authorizationw').show();

      $('input[id="inlineRadio2"]').prop('checked', true);
      $('input[id="inlineRadio2_m"]').prop('checked', true);
    }, 1000)


    $('#continueEduDiv').hide();
    $('#continueEduDiv_m').hide();
    $("#inlineRadio1, #inlineRadio2").click(function () {
      var selectedEdu = $('input[name="continueEducation"]:checked').val();
      // alert(selectedEdu);
      if (selectedEdu == "Y") {
        $("#continueEduDiv").show();
      } else if (selectedEdu == "N") {
        $('#collegeId').val('');
        $('#pursuingProgram').val('');
        $("#continueEduDiv").hide();
      }
    });

    $("#inlineRadio1_m, #inlineRadio2_m").click(function () {
      var selectedEdu = $('input[name="continueEducation_m"]:checked').val();
      // alert(selectedEdu);
      if (selectedEdu == "Y") {
        $("#continueEduDiv_m").show();
      } else if (selectedEdu == "N") {
        $('#collegeId_m').val('');
        $('#pursuingProgram_m').val('');
        $("#continueEduDiv_m").hide();
      }
    });

  }, []);


  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // console.log(reader.result);
      var base64result = reader.result.split(',')[1];
      setFileBase64(base64result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  function getImage() {
    var files = document.getElementById('resume').files;
    if (files.length > 0) {

      var fname = files[0].name;
      // var fextn = fname.substr(0, fname.lastIndexOf('.'));
      var fextn = fname.split(".").pop();
      // alert(fextn);
      var fileActName = fname.split(".")[0];
      // alert(fileActName);
      setFileName(fileActName);
      setFileExtension(fextn);
      getBase64(files[0]);

      if (fextn != "doc" && fextn != "docx" && fextn != "pdf") {

        $("#fileError").show();
        return false;
      } else {
        $("#fileError").hide();
      }

    }
  }

  function getBase64Mobile(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // console.log(reader.result);
      var base64result = reader.result.split(',')[1];
      setFileBase64Mobile(base64result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  function getImageMobile() {
    var files = document.getElementById('resumeMobile').files;
    if (files.length > 0) {

      var fname = files[0].name;
      // var fextn = fname.substr(0, fname.lastIndexOf('.'));
      var fextn = fname.split(".").pop();
      // alert(fextn);
      var fileActName = fname.split(".")[0];
      // alert(fileActName);
      setFileNameMobile(fileActName);
      setFileExtensionMobile(fextn);
      getBase64Mobile(files[0]);

      if (fextn != "doc" && fextn != "docx" && fextn != "pdf") {

        $("#fileErrorMobile").show();
        return false;
      } else {
        $("#fileErrorMobile").hide();
      }

    }
  }


  const getIndustryData = () => {

    Services.getIndustryData().then((res) => {

      console.log("Industry data=" + JSON.stringify(res.data));

      setCategory(res.data.content);

    });

  }
  const getCurrentYear = () => {
    var currentYear = new Date().getFullYear()
    var years = [];
    years.push(currentYear);
    for (var i = 1; i < 7; i++) {
      years.push(currentYear + i);
    }

    //const now = new Date().getUTCFullYear();    
    //const years = Array(now - (now + 5)).fill('').map((v, idx) => now - idx);
    console.log("#41#41-->" + JSON.stringify(years));
    setCurrentYearList(years);
  }

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  /* const getJobType = () => {
    Services.getJobType().then((res) => {
      //console.log("setJobType data=" + JSON.stringify(res.data));
      //setJobType(res.data.content); 

      var jobtype_unsort = res.data.content;
      var jobtypes_sorted = jobtype_unsort.sort((a, b) => b.jobTypeId - a.jobTypeId);
      array_move(jobtypes_sorted, 0, (jobtypes_sorted.length - 1));
      setJobType(jobtypes_sorted);


    });
  } */

  const getJobType = () => {
    Services.getJobType().then((res) => {
      //console.log("setJobType data=" + JSON.stringify(res.data));
      var jobtype_unsort = res.data.content;

      var jobtypes_sorted = jobtype_unsort.sort((a, b) => b.jobTypeId - a.jobTypeId);
      array_move(jobtypes_sorted, 0, (jobtypes_sorted.length - 1));
      
      jobtypes_sorted = jobtypes_sorted.filter(function (elem) {
        return elem.name !== 'Continuing Education';
      });
      
      setJobType(jobtypes_sorted);
    });
  }
  /*
  const getSchoolnData = () => {
    Services.getSchoolnData().then((res) => {
      console.log("setSchoolInData data=" + JSON.stringify(res.data));
      setSchoolInData(res.data);          
    });
  }
  */

  const getSchoolnData = () => {
    //alert("14");
    const requestObject = {
      "siteId": process.env.React_App_SITE_ID,
      "pageNumber": 1,
      "pageSize": 150
    }

    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "Bearer "+accessTokenIn 
        //'Authorization': accessTokenIn 
      },
      body: JSON.stringify(requestObject)
    };

    Services.getSchoolList(requestOptions).then((res) => {
      console.log("############36");
      console.log(JSON.stringify(res))
      if (res) {
        console.log(JSON.stringify(res.data));



        var contAr = res.content;
        //setProfileList(res.content);
        if (res) {
          setSchoolInData(res);
        }


      }
      //navigate('/thankyou');

    });


  }



  const getStudentProfile = (studentId) => {
    Services.getStudentProfile(studentId).then((res) => {
      var companyCatIn = res.data.categoriesOfExpertise;

      setStudentComments(res.data.comments);
      setStudentCommentsMobile(res.data.comments);

      ////
      $("#firstName_m").val(res.data.firstName);
      setFirstNameMobile(res.data.firstName);
      $("#lastName_m").val(res.data.lastName);
      $("#email_m").val(res.data.email);
      $("#mobile1_m").val(res.data.phone);
      $("#class_type_m").val(res.data.classYear);
      $("#classType_m").val(res.data.classYear);



      // setJobSeekerDetails(res);
      $("#firstName").val(res.data.firstName);
      $("#lastName").val(res.data.lastName);
      $("#email").val(res.data.email);
      $("#yourphone2").val(res.data.phone);
      $("#personalProfile").val(res.data.personalProfile);
      $("#education").val(res.data.educationExperiance);
      $("#lookingFor").val(res.data.lookingFor);
      $("#jobType").val(res.data.jobType);
      $("#resume").val(res.data.pdfByString);
      setStudentEmail(res.data.email);
      setStudentCareers(res.data.reasonForSelectCareers);
      setStudentCareersMobile(res.data.reasonForSelectCareers);

      $("#careers_m").val(res.data.reasonForSelectCareers);

      $("#courses_related").val(res.data.course);
      $("#class_type").val(res.data.classYear);
      //$("#skills").val(res.data.experiance);
      setStudentSkills(res.data.experiance);
      setStudentSkillsMobile(res.data.experiance);

      $("#skills_m").val(res.data.experiance);

      //$('#courses').val(res.data.course);
      setStudentCourses(res.data.course);
      setStudentCoursesMobile(res.data.course);
      $('#additional_course_m').val(res.data.course);

      //$('#comments').val(res.data.comments);
      
      $('#comments_m').val(res.data.comments);

      $('#password').val(res.data.password);
      //setPdfDownload(res.data.resumePath);
      //setJobType(res.data.jobType);
      console.log("jobseeker@@@@@@@@" + JSON.stringify(res.data));
      setCategoriesSelected(res.data.categoriesOfExpertise);
      var seekingIn = res.data.seekingEmploymentTypes;
      setStudentResumePath(res.data.resumeUrl);
      if (res.data.resumeUrl != "") {
        $("#showResume").show();
      }
      setStudentComments(res.data.comments);
      setStudentCommentsMobile(res.data.comments);

      setTimeout(() => {

        var comments_val = res.data.comments;
        setStudentComments(comments_val);
        setStudentCommentsMobile(comments_val);
        
        $('#pursuing_degree').val(res.data.educationId);
        $('#pursuing_m').val(res.data.educationId);
        $('#collegeIdOther').val(res.data.nameOfCollegeUniversity);
        $('#schoolIdOther').val(res.data.schoolDistrictOther);
        $('#collegeIdOther_m').val(res.data.nameOfCollegeUniversity);
        $('#schoolIdOther_m').val(res.data.schoolDistrictOther);
        console.log(res.data.educationId);

        if (res.data.educationId == 3) {
          $('#parent_authorization').show();
          $('#parent_authorizationw').show();
          var selectedyearval = res.data.classYear;
          var currentyearval = new Date().getFullYear();

         // if ((selectedyearval == currentyearval) || (selectedyearval == currentyearval + 1)) {
            $('#planedu_m').show();
            $('#planedu').show();

            $("#continueEducation").val(res.data.highSchoolDiplomaExtContinueEduStatus);
            $("#continueEducation_m").val(res.data.highSchoolDiplomaExtContinueEduStatus);

            if (res.data.highSchoolDiplomaExtContinueEduStatus == "Y") {
              $('#inlineRadio1').prop("checked", true);
              $('#inlineRadio1_m').prop("checked", true);
              $("#continueEduDiv").show();
              $("#continueEduDiv_m").show();
            } else if (res.data.highSchoolDiplomaExtContinueEduStatus == "N") {
              $('#inlineRadio2').prop("checked", true);
              $('#inlineRadio2_m').prop("checked", true);
              $("#continueEduDiv").hide();
              $("#continueEduDiv_m").hide();
            }
            $("#pursuingProgram").val(res.data.highSchoolDiplomaExtPursuingProgram);
            $("#pursuingProgram_m").val(res.data.highSchoolDiplomaExtPursuingProgram);
            $("#collegeId").val(res.data.highSchoolDiplomaExtNameOfInstitutionCollegeId);
            $("#collegeId_m").val(res.data.highSchoolDiplomaExtNameOfInstitutionCollegeId);
/*
          }
          else {
            $('#planedu_m').hide();
            $('#planedu').hide();
          }*/

          $('#school_div_m').show();
          $('#school_div').show();

          $('#college_div_m').hide();
          $('#college_div').hide();

          $("#school_district").val(res.data.schoolId);
          $("#school_district_m").val(res.data.schoolId);
          if (res.data.schoolId == 0) {
            $('#schoolIdOtherDiv').show();
            $('#schoolIdOtherDiv_m').show();
          }
          $('#university_name').val();
          $('#university_m').val();
        }
        else {
          console.log('here');
          $('#parent_authorization').hide();
          $('#parent_authorizationw').hide();
          
          $('#school_div_m').hide();
          $('#school_div').hide();

          $('#college_div_m').show();
          $('#college_div').show();

          $("#school_district").val();
          $("#school_district_m").val();
          $('#university_name').val(res.data.nameOfCollegeUniversityId);
          $('#university_m').val(res.data.nameOfCollegeUniversityId);
          if (res.data.nameOfCollegeUniversityId == '22') {
            $('#collegeIdOtherDiv').show();
            $('#collegeIdOtherDiv_m').show();
          }
        }

        

        $('#authorization').val(res.data.authorization);
        // $('#authorization').prop('selectedIndex', res.data.authorization);  
        for (var i = 0; i < seekingIn.length; i++) {
          //$("input[value='" + val + "']").prop('checked', true);
          console.log("###" + seekingIn[i]);
          //$('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
          $('input[name="seeking"][value="' + seekingIn[i] + '"]').trigger("click");
          $('input[name="seeking_m"][value="' + seekingIn[i] + '"]').trigger("click");
        }

        for (var i = 0; i < companyCatIn.length; i++) {
          //$("input[value='" + val + "']").prop('checked', true);
          //console.log("company catein ###" + companyCatIn[i]);
          $('input[name="exp"][value="' + companyCatIn[i] + '"]').prop("checked", true);
          $('input[name="category_m"][value="' + companyCatIn[i] + '"]').prop("checked", true);
          //$('input[name="exp"][value="' + companyCatIn[i] + '"]').trigger("click");
        }
        //validateCheck();

        var isCheck = true;
        validateCheck();
        var checkedValues = $("input[name='category']").val();
        // var checkedValues = [];
        //     $.each($("input[name='category']:checked"), function () {
        //       checkedValues.push($(this).val());
        //     });
        // alert(checkedValues);
        if (!checkedValues) {
          isCheck = false;
          $("#categoryError").html("Category field is required");
        } else {
          // alert(checkedValues);
          isCheck = true;
          $("#categoryError").html("");
        }

        return isCheck;

        setTimeout(() => {
          console.log("141");
          $('input[name="category"]').trigger("change");
          $('input[name="state"]').trigger("change");
        }, 3000);

      }, 1000);



    });
  }

  const getEducation = () => {
    Services.getEducation().then((res) => {
      console.log("getEducation data=" + JSON.stringify(res));
      var tempedu = res.data.content;
      //console.log(tempjobtypes);
      tempedu = tempedu.filter(function (elem) {
        return elem.name != 'PhD';
      });
      setEducation(tempedu);
    });
  }

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });



  const submitValueStep1 = () => {

    if ($('#pursuing_m').val() == '') {
      $("#pursuingValidMsg_m").show();
      $(window).scrollTop($('#pursuingValidMsg_m').position().top);
      return false;
    }
    else {
      $("#pursuingValidMsg_m").hide();
      if ($('#pursuing_m').val() == 3) {
        if ($('#school_district_m').val() == '') {
          $("#school_districtValidMsg_m").show();
          $('#school_district_m').focus();
          return false;
        } else {
          $("#school_districtValidMsg_m").hide();
        }

        if ($('#school_district_m').val() == 0 && $('#schoolIdOther_m').val() == '') {
          $("#schoolIdOther_mmsg").show();
          $('#schoolIdOther_m').focus();
          return false;
        } else {
          $("#schoolIdOther_mmsg").hide();
        }

        var classyr = $('#classType_m').val();
        var currentyearval = new Date().getFullYear();
        var selectedEduction = $('input[name="continueEducation_m"]:checked').val();
        //if (((classyr == currentyearval) || (classyr == (currentyearval + 1))) && selectedEduction == 'Y' && ($('#collegeId_m').val() == '')) {
        if (selectedEduction == 'Y' && ($('#collegeId_m').val() == '')) {
          $("#extensionvalidmsg_m").show();
          $(window).scrollTop($('#extensionvalidmsg_m').position().top);
          return false;
        }
        else {
          $("#extensionvalidmsg_m").hide();
        }
      }
      else {
        console.log('here');
        if ($('#university_m').val() == '') {
          $("#university_nameValidMsg_m").show();
          $(window).scrollTop($('#university_nameValidMsg_m').position().top);
          return false;
        }
        else {
          $("#university_nameValidMsg_m").hide();
        }

        if ($('#university_m').val() == 22 && $('#collegeIdOther_m').val() == '') {
          $("#collegeIdOther_mmsg").show();
          $(window).scrollTop($('#collegeIdOther_mmsg').position().top);
          return false;
        }
        else {
          $("#collegeIdOther_mmsg").hide();
        }
      }
    }


    $("#step1").hide();
    $("#step2").show();
    $("#step3").hide();

  }


  const submitValue = (data) => {


    var validateEditorTxt = 1;
    /*if (studentCareers == "") {
      $("#studentCareersValidMsg").show();
      $(window).scrollTop($('#studentCareersValidMsg').position().top);
      validateEditorTxt = 0;
    } else {
      $("#studentCareersValidMsg").hide();
    } */
    /*
    if(coursesRelated == ""){
      $("#coursesRelatedValidMsg").show();  
     $(window).scrollTop($('#coursesRelatedValidMsg').position().top);
      validateEditorTxt = 0;     
    }else{
      $("#coursesRelatedValidMsg").hide();  
    }
    */
/*
    if (studentSkills == "") {
      $("#studentSkillsValidMsg").show();
      $(window).scrollTop($('#studentSkillsValidMsg').position().top);
      validateEditorTxt = 0;
    } else {
      $("#studentSkillsValidMsg").hide();
    } */

    /*
    if(studentCourses == ""){
      $("#studentCoursesValidMsg").show();  
     $(window).scrollTop($('#studentCoursesValidMsg').position().top);
      validateEditorTxt = 0;     
    }else{
      $("#studentCoursesValidMsg").hide();  
    }
    */
/*
    if (studentComments == "") {
      $("#studentCommentsValidMsg").show();
      $(window).scrollTop($('#studentCommentsValidMsg').position().top);
      validateEditorTxt = 0;
    } else {
      $("#studentCommentsValidMsg").hide();
    } */

    if ($('#pursuing_degree').val() == '') {
      $("#pursuingValidMsg").show();
      $(window).scrollTop($('#pursuingValidMsg').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#pursuingValidMsg").hide();
      console.log($('#pursuing_degree').val());
      if ($('#pursuing_degree').val() == 3) {
        if ($('#school_district').val() == '') {
          $("#school_districtValidMsg").show();
          $(window).scrollTop($('#school_districtValidMsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#school_districtValidMsg").hide();
        }

        if ($('#school_district').val() == 0 && $('#schoolIdOther').val() == '') {
          $("#schoolIdOthermsg").show();
          $(window).scrollTop($('#schoolIdOthermsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#schoolIdOthermsg").hide();
        }

        var classyr = $('#class_type').val();
        var currentyearval = new Date().getFullYear();
        var selectedEduction = $('input[name="continueEducation"]:checked').val();
        //if (((classyr == currentyearval) || (classyr == (currentyearval + 1))) && selectedEduction == 'Y' && ($('#collegeId').val() == '')) {
        if (selectedEduction == 'Y' && ($('#collegeId').val() == '')) {
          $("#extensionvalidmsg").show();
          $(window).scrollTop($('#extensionvalidmsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#extensionvalidmsg").hide();
        }
      }
      else {
        console.log('here');
        if ($('#university_name').val() == '') {
          $("#university_nameValidMsg").show();
          $(window).scrollTop($('#university_nameValidMsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#university_nameValidMsg").hide();
        }

        if ($('#university_name').val() == 22 && $('#collegeIdOther').val() == '') {
          $("#collegeIdOthermsg").show();
          $(window).scrollTop($('#collegeIdOthermsg').position().top);
          validateEditorTxt = 0;
        }
        else {
          $("#collegeIdOthermsg").hide();
        }
      }
    }


    var categoriesArray = [];

    $.each($("input[name='seeking']:checked"), function () {
      categoriesArray.push($(this).val());
    });
    if (categoriesArray.length <= 0) {

      $("#seekinginterestValidMsg").show();
      $(window).scrollTop($('#seekinginterestValidMsg').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#seekinginterestValidMsg").hide();
    }

    var catofExpertiseArray = [];
    $.each($("input[name='exp']:checked"), function () {
      catofExpertiseArray.push($(this).val());
    });
    if (catofExpertiseArray.length <= 0) {

      $("#categoriesinterestedValidMsg").show();
      $(window).scrollTop($('#categoriesinterestedValidMsg').position().top);
      validateEditorTxt = 0;
    }
    else {
      $("#categoriesinterestedValidMsg").hide();
    }


    if (validateEditorTxt == 0) {
      return false;
    }



    var phone_val = $("#yourphone2").val();
    ///phone_val = "1234567899";


    var extclgid = $("#collegeId").val();

    var t_course = studentCourses;
    t_course = t_course == "" ? " " : t_course;
    const requestObject = {

      "studentId": studentIdGet,
      "siteId": process.env.React_App_SITE_ID,
      "classYear": $("#class_type").val(),
      "firstName": $('#firstName').val(),
      "lastName": $('#lastName').val(),
      "email": studentEmail,
      "educationId": $('#pursuing_degree').val(),
      "nameOfCollegeUniversity": $('#collegeIdOther').val(),
      "nameOfCollegeUniversityId": $('#university_name').val(),
      "schoolDistrictOther": $('#schoolIdOther').val(),

      //"phone":$('#yourphone2').val(),
      "phone": phone_val,
      "isStudent": true,////////////////////////////
      //"reasonForSelectCareers":$('#careers').val(),
      "reasonForSelectCareers": studentCareers,

      //"course": $('#courses').val(),
      "course": t_course,

      //"experiance":$('#skills').val(),
      "experiance": studentSkills,

      "helpPrepare": "yes",
      //"comments": $('#comments').val(),
      "comments": studentComments,

      "status": "1",

      "schoolId": $("#school_district").val(),

      //"password": $('#password').val(),
      "categoriesOfExpertise": catofExpertiseArray,
      //
      "highSchoolDiplomaExtContinueEduStatus": selectedEduction,
      "highSchoolDiplomaExtNameOfInstitutionCollegeId": extclgid,
      "highSchoolDiplomaExtPursuingProgram": $('#pursuingProgram_m').val(),
      // ///////////////////////////////////
      // "interested": $('#interested').val(),
      "seekingEmploymentTypes": categoriesArray,
      // "seeking": $('#skills').val(),
      //"events": $('#events').val(),
      "authorization": $('#authorization').val(),
      "studentTalentConnectionForumIn": [1, 2],
      "studentInterestedIn": [3, 4]
    }
    if (fileName != "" && fileExtension != "") {
      requestObject.pdfByString = fileBase64;
      requestObject.fileName = fileName;
      requestObject.fileExtension = fileExtension;
    }
    console.log(requestObject);
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + accessTokenIn
        //'Authorization': accessTokenIn 
      },
      body: JSON.stringify(requestObject)
    };

    $(".load-wrap").show();
    Services.editStudentProfile(requestOptions).then((res) => {
      $(".load-wrap").hide();
      // alert(res.error);
      //  if(res.error){
      //    console.log(res.error);
      //  }else{
      //   console.log(res.success);
      //  }
      $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
        $("#successMessage").slideUp(1000);
      });

      setTimeout(function () {
       
          if (roleArChck == "schooladmin") {
            if(pageref == 'v'){
              navigate('/studentProfileView?studentId='+studentIdGet+'&adpage='+stpage); 
            } else {
              navigate('/studentListBySchool');
            }
          }
          else {
            if(pageref == 'v'){
              navigate('/studentProfileView?studentId='+studentIdGet+'&pageid='+stpage); 
            } else {
              navigate('/studentList');
            }
          }


      }, 1000);

    });


  }

  function handleChangeCollege_m(event) {
    //alert("teste")
    //alert(event.target.value);
    var clg_m = event.target.value;
    $('#collegeIdOther_m').val('');
    //console.log(event.target.value);
    //var sch = $("#schoolId'").val();    
    if (clg_m == "22") {
      $("#collegeIdOtherDiv_m").show();
    } else {
      $("#collegeIdOtherDiv_m").hide();
    }
  }

  function validateMedium() {
    var mediumCheckboxes = document.getElementsByName("exp");
    var okay = false;

    for (var i = 0, len = mediumCheckboxes.length; i < len; i++) {
      if (mediumCheckboxes[i].checked) {
        okay = true;
        break;
      }
    }

    if (okay) {
      return true;
    } else {
      return false;
    }
  }

  function validateCheck() {
    const checked = $('input[name="exp"]:checked').length;
  
  
    if (checked >= 3) {
      $('input[name="exp"]').not(':checked').prop('disabled', true);
    } else {
      $('input[name="exp"]').prop('disabled', false);
    }
  }

  return (
    <>
      <main class="empr-directory-main cd-desktop">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    {/* <li class="breadcrumb-item headtitle"> <a href="/studentList">Student List</a> </li>     */}

                    {(() => {
                      
                      if (chKRoleAr == "schooladmin") {
                        if(pageref == 'v'){
                          return (<>  <li class="breadcrumb-item headtitle "><Link to={`/studentProfileView?studentId=`+studentIdGet+`&adpage=` + stpage}>View Student </Link></li></>);
                        } else {
                          return (<> <li class="breadcrumb-item headtitle "><Link to={`/studentListBySchool?adpage=` + adpage}>Student List </Link></li></>);
                        }
                        
                      }

                      if (chKRoleAr == "schoolsuper") {
                        if(pageref == 'v'){
                          return (<>  <li class="breadcrumb-item headtitle "><Link to={`/studentProfileView?studentId=`+studentIdGet+`&pageid=` + stpage}>View Student </Link></li></>);
                        } else {
                          return (<>  <li class="breadcrumb-item headtitle "><Link to={`/studentList?pageid=` + stpage}>Student List </Link></li></>);
                        }
                      }
                    })()}


                    <li class="breadcrumb-item headtitle "><a href="#">Update Student</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">
          <div class="container">
            <div class="row">
              <div class="col-12 ">
                <form onSubmit={handleSubmit(submitValue)}>
                  <div class="white-bg px-5 pt-4 pb-4">



                    <div class="row mt-4 resume-wrap">
                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">First name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" defaultValue="firstName" id="firstName" onChange={e => setFieldName(e.target.value)}
                          {...register("FirstName", {
                            required: "First name is required.",

                          })} />
                        <ErrorMessage
                          errors={errors}
                          name="FirstName"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">Last name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" defaultValue="lastName" id="lastName" onChange={e => setFieldName(e.target.value)} {...register("lastName", {
                          required: "Last name is required.",

                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="lastName"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="email" class="form-label mr-2">Personal Email <span class="red-text">*</span></label> <small></small></div>
                        <p style={{ fontSize: "12px" }}>Please do not use your school email.</p>
                        <input type="text" class="form-control" defaultValue="email@email.com" id="email" onChange={e => setFieldName(e.target.value)} {...register("Email", {
                          required: "Email is required.",

                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address"
                          }
                        })} readOnly={true} />
                        <ErrorMessage
                          errors={errors}
                          name="Email"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="phone" class="form-label mr-2">Phone<span class="red-text">*</span></label> <small>XXX-XXX-XXXX Format</small></div>
                        <p style={{ fontSize: "12px" }}>Use your parent's phone number if you do not have one.</p>
                        <input type="text" class="form-control" defaultValue="yourphone2" id="yourphone2" name="contactNumber" maxlength="14" onChangeText={(text) => this.onTextChange(text)} {...register("phone", {
                          required: "Phone field is required.",
                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="phone"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />

                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">I am Currently Pursuing <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" id="pursuing_degree" >

                          {
                            educations.map((data, index) => (
                              <option value={data.educationId}>{data.name}</option>
                            ))}
                        </select>
                        {errors.pursuing_degree && errors.pursuing_degree.type === "required" && <div class="error">This Field is required</div>}
                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">Graduating year <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" defaultValue="2023" id="class_type" >
                          {/* <option selected="" value=''>Select</option>      */}
                          {
                            currentYearList.map((data, index) => (

                              <option value={data}>{data}</option>


                            ))}
                        </select>
                        {errors.class_type && errors.class_type.type === "required" && <div class="error">Class type is required</div>}
                      </div>

                      <div class="col-lg-6 mb-5" id="school_div">
                        <label for="firstName" class="form-label">school district <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" defaultValue="1" id="school_district" onChange={handleChangeSchool}>
                          <option selected="" value=''>Select</option>
                          {
                            schoolIds.map((data, index) => (
                              <option value={data.schoolId}>{data.name}</option>
                            ))
                          }
                          <option value="0" >Other</option>
                        </select>
                        <div id="school_districtValidMsg" class="error">School District is required.</div>
                      </div>

                      <div class="col-md-6 " id="schoolIdOtherDiv">
                        <div class="d-flex ">
                          <label for="schoolIdOther" class="form-label mr-2">Name of the Institution<span class="red-text">*</span></label>
                        </div>
                        <input type="text" class="form-control " id="schoolIdOther" aria-describedby="emailHelp" placeholder="Other" />
                        <div id="schoolIdOthermsg" class="error">Name of the Institution is required.</div>
                      </div>

                      <div class="col-lg-6 mb-5" id="college_div">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">College / University <span class="red-text">*</span></label></div>
                        <select class="form-select" id="university_name" onChange={handleChangeCollege}>
                          <option value="">Select College</option>
                          {
                            collegeInfo.map((data, index) => (
                              <option value={data.enrolledCollegeId}>{data.name}</option>
                            ))}
                        </select>
                        <div id="university_nameValidMsg" class="error">College / Universities is required.</div>
                      </div>

                      <div class="col-md-6 " id="collegeIdOtherDiv">
                        <div class="d-flex ">
                          <label for="collegeIdOther" class="form-label mr-2">Name of the Institution<span class="red-text">*</span></label>
                        </div>
                        <input type="text" class="form-control " id="collegeIdOther" aria-describedby="emailHelp" placeholder="Other" />
                        <div id="collegeIdOthermsg" class="error">Name of the Institution is required.</div>
                      </div>

                      <div class="col-lg-12 mb-4" id="planedu">

                        <div class="d-flex">
                          <label for="education" class="form-label mr-2">Are you planning to go to college after high school? <span class="red-text">*</span></label>
                        </div>

                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="continueEducation" id="inlineRadio1" value="Y" style={{ borderRadius: "30px" }} />
                          <label class="form-check-label" for="inlineRadio1">YES</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="continueEducation" id="inlineRadio2" value="N" />
                          <label class="form-check-label" for="inlineRadio2">NO</label>
                        </div>

                        <div className="row" id="continueEduDiv">
                          <div className="col-md-6 mt-4">
                            <div class="d-flex"><label for="lookingFor" class="form-label mr-2">Name of The Institution <span class="red-text">*</span></label></div>
                            <select class="form-select" id="collegeId">
                              <option value="">Select College</option>
                              <option value="0">I Don't know yet</option>
                              {
                                collegeInfo.map((data, index) => (
                                  <option value={data.enrolledCollegeId}>{data.name}</option>
                                ))}
                            </select>
                            <div id="extensionvalidmsg" class="error">Name of the Institution is required.</div>
                          </div>

                          <div className="col-md-6 mt-4">
                            <div class="d-flex"><label for="pursuingProgram" class="form-label mr-2">Pursuing Program</label></div>
                            <input type="text" class="form-control " id="pursuingProgram" aria-describedby="emailHelp" placeholder="Program Name" />
                          </div>
                        </div>

                      </div>

                      {/* <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Password <span class="red-text">*</span></label> <small>8-15 Characters, Must contain 1 letter and 1 number</small></div>
                        <input type="password" class="form-control" defaultValue="password" id="password" onChange={e => setFieldName(e.target.value)} {...register("Password", {
                          required: "Password is required.",
                          pattern: {
                            value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                            message: "Password contain uppercase,lowercase and special character"
                          },
                          minLength: { value: 8, message: 'Too short' }
                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="Password"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}
                        />
                      </div>  */}


                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">FIELDS I am INTERESTED IN<span class="red-text">*</span></label><small>(Select up to three)</small> </div>

                        <div >
                          <div class="category-check-itm">
                            <ul class="category-check">
                              {
                                categories.map((data, index) => (


                                  <li><input class="form-check-input" type="checkbox" name="exp" value={data.catId} id="defaultCheck1" onClick={() => validateCheck()}/>  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                ))}
                              
                            </ul>
                          </div>
                          <div id="categoriesinterestedValidMsg" class="error">Please check atleast one category.</div>


                        </div>

                      </div>


                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">I AM INTERESTED IN THESE FIELDS FOR THESE REASONS </label></div>
                        <small>(Leave blank if not known.)</small>

                        {/* <CKEditor
                            editor={ ClassicEditor }
                            config={{
                              removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
                            }}
                            id="careers"
                            data={ studentCareers }                            
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                                //editor.setData(profileDescription);
                                //editor.setData(profileDescription);
                                
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setStudentCareers(data);
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        /> */}

                        <SunEditor
                          setOptions={{
                            height: "120",
                            //buttonList: [['font', 'align'], ['image']]

                            buttonList: [
                              /**['undo', 'redo'],
                              ['font', 'fontSize', 'formatBlock'],
                              ['paragraphStyle', 'blockquote'],
                              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                              ['fontColor', 'hiliteColor', 'textStyle'],
                              ['removeFormat'],
                              '/', // Line break
                              ['outdent', 'indent'],
                              ['align', 'horizontalRule', 'list', 'lineHeight'],
                              ['table', 'link', 'image' /** ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                              ['fullScreen', 'showBlocks', 'codeView'],
                              ['preview'], */

                              /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                            ]
                            //buttonList: buttonList.complex
                            // plugins: [font] set plugins, all plugins are set by default
                            // Other option
                          }}
                          onChange={handleChangeStudentCareersDesc}
                          setContents={studentCareers}
                          //onImageUpload={handleImageUpload}
                          onImageUploadBefore={handleImageUploadBefore}
                        />

                        <div id="studentCareersValidMsg" class="error">This field is required.</div>



                        {/* <textarea class="form-control textarea" placeholder="Leave a comment here" placeholder="Leave a comment here" defaultValue='description' id="careers" onChange={e => setFieldName(e.target.value)} {...register("personalinfo", {
                          required: "This field is required",
                          maxLength: { value: 500, message: 'Should not allow more than 500 character' }
                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="personalinfo"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}

                        /> */}
                      </div>

                      {/* <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-0">WHAT COURSES HAVE YOU TAKEN THAT ARE RELATED TO THIS CAREER? <span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                       
                        <textarea class="form-control textarea" placeholder="Leave a comment here" defaultValue='description' id="courses_related" spellcheck="false" {...register('courses_related', { required: true,maxLength: 500 })}></textarea>
                        {errors.courses_related && errors.courses_related.type === "required" && <div class="error">This field is required</div>}
                        {errors.courses_related && errors.courses_related.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>}
                      </div> */}

                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-0">I HAVE THE FOLLOWING RELEVANT SKILLS /EXPERIENCE.</label></div>
                        <small>(Leave blank if not known.)</small>

                        {/* <CKEditor
                            editor={ ClassicEditor }
                            config={{
                              removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
                            }}
                            id="skills"   
                            data={ studentSkills }                         
                            onReady={ editor => {              
                                console.log( 'Editor is ready to use!', editor );                                
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setStudentSkills(data);
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        /> */}

                        <SunEditor
                          setOptions={{
                            height: "120",
                            //buttonList: [['font', 'align'], ['image']]

                            buttonList: [
                             /** ['undo', 'redo'],
                              ['font', 'fontSize', 'formatBlock'],
                              ['paragraphStyle', 'blockquote'],
                              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                              ['fontColor', 'hiliteColor', 'textStyle'],
                              ['removeFormat'],
                              '/', // Line break
                              ['outdent', 'indent'],
                              ['align', 'horizontalRule', 'list', 'lineHeight'],
                              ['table', 'link', 'image' /** ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                              ['fullScreen', 'showBlocks', 'codeView'],
                              ['preview'],
*/
                              /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                            ]
                            //buttonList: buttonList.complex
                            // plugins: [font] set plugins, all plugins are set by default
                            // Other option
                          }}
                          onChange={handleChangeStudentSkillsDesc}
                          setContents={studentSkills}
                          //onImageUpload={handleImageUpload}
                          onImageUploadBefore={handleImageUploadBefore}
                        />

                        <div id="studentSkillsValidMsg" class="error">This field is required.</div>

                        {/* <textarea class="form-control textarea" placeholder="Leave a comment here" defaultValue='description' id="skills" spellcheck="false" {...register('skills', { required: true,maxLength: 500 })}></textarea>
                        {errors.skills && errors.skills.type === "required" && <div class="error">This field is required</div>}
                        {errors.skills && errors.skills.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>} */}
                      </div>


                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">I AM SUBMITTING THIS FORM BECAUSE I AM LOOKING FOR: <span class="red-text">*</span></label></div>
                        <div class="category-check-itm">
                          <ul>
                            {
                              jobTypes.map((data, index) => (


                                <li><input class="form-check-input" type="checkbox" name="seeking" value={data.jobTypeId} id={data.jobTypeId} />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                              ))}


                          </ul>
                        </div>
                        <div id="seekinginterestValidMsg" class="error">Please check atleast one</div>
                        {errors.seeking && errors.seeking.type === "required" && <div class="error">Please check atleast one </div>}
                        {/* {errors.seeking && errors.seeking.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>} */}
                      </div>

                      <div class="col-lg-12 mb-4">

                        <div class="d-flex justify-content-between align-items-end choose">
                          <div>
                            <label for="resumePath" class="form-label">MY RESUME (Optional) </label>
                            <div class="d-flex align-items-center">
                              {/* <input class="form-control form-control-lg choose-file mr-2" id="resumePath" type="file"/> */}
                              <input class="form-control form-control-lg " id="resume" type="file" onChange={() => getImage()} />

                            </div>
                            <small> 1MB Max, File Type: PDF,DOC</small>
                            <div id="fileError" class="error">Invalid file format</div>
                          </div>

                        </div>
                        <div id="showResume" >
                          <br></br>
                          {(studentResumePath != '' && studentResumePath != null) ? <a href={studentResumePath} download="resume" target="_blank"><button type="button" class="reg-button">Download Current Resume</button></a> : ''}
                        </div>

                      </div>
                  

                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">ANY ADDITIONAL THOUGHTS/COMMENTS THAT MIGHT BE HELPFUL? </label></div>
                        <small>(Leave blank if not known.)</small>

                        <SunEditor
                          setOptions={{
                            height: "120",
                            //buttonList: [['font', 'align'], ['image']]

                            buttonList: [
                              /** ['undo', 'redo'],
                              ['font', 'fontSize', 'formatBlock'],
                              ['paragraphStyle', 'blockquote'],
                              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                              ['fontColor', 'hiliteColor', 'textStyle'],
                              ['removeFormat'],
                              '/', // Line break
                              ['outdent', 'indent'],
                              ['align', 'horizontalRule', 'list', 'lineHeight'],
                              ['table', 'link', 'image' /** ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                              ['fullScreen', 'showBlocks', 'codeView'],
                              ['preview'], */

                              /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                            ]
                            //buttonList: buttonList.complex
                            // plugins: [font] set plugins, all plugins are set by default
                            // Other option
                          }}
                          onChange={handleChangeStudentCommentsDesc}
                          setContents={studentComments}
                          //onImageUpload={handleImageUpload}
                          onImageUploadBefore={handleImageUploadBefore}
                        />

                        <div id="studentCommentsValidMsg" class="error">This field is required.</div>

                      </div>

                      

                      <div class="col-lg-12 mb-5" >

                        <div class="d-flex justify-content-between align-items-end">
                          <div>
                            <div id="parent_authorizationw">
                              <label for="firstName" class="form-label">MY PARENT/GUARDIAN AUTHORIZES MY INFORMATION TO BE SHARED (if under 18)</label>

                              <select class="form-select" aria-label="Default select example" style={{ maxWidth: "420px" }} id="authorization">
                                <option value="yes">Yes</option>
                                <option value="no">No</option>

                              </select>
                            </div>
                          </div>
                          <div>
                            {/* <a href="#" class="reg-button">Register</a> */}
                            <button type="submit" class="reg-button" >Update</button>
                          </div>
                        </div>
                      </div>


                      <div id="successMessage" class="alert">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <img src="/images/success-alert.png" alt="" />
                            <strong class="mx-2">Success!</strong> Profile info  has been updated successfully.
                          </div>
                          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        </div>
                      </div>


                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
          </div>
        </section>

      </main>

      <main class="empr-directory-main cd-mobile">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">

                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    {/*  <li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li>    
*/}

                    {(() => {
                      if (chKRoleAr == "schooladmin") {
                        return (<> <li class="breadcrumb-item headtitle "><Link to={`/studentListBySchool?adpage=` + adpage}>Student List </Link></li></>);
                      }

                      if (chKRoleAr == "schoolsuper") {
                        return (<>  <li class="breadcrumb-item headtitle "><Link to={`/studentList?pageid=` + stpage}>Student List </Link></li></>);
                      }
                    })()}

                    <li class="breadcrumb-item headtitle "><a href="#">Update Student</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- info typography Step 1--> */}
        <section class="company-detail-wrap py-5" id="step1">
          <div class="container">
            <div className="row pb-3">
                    <div className="d-flex">
                        <span className="mr-1 rotate-90">
                            <img src="images/mob-arrow.png" alt="" />
                        </span>
                        {(() => {
                      if (chKRoleAr == "schooladmin") {
                        return (<><Link to={`/studentListBySchool?adpage=` + adpage} style={{ color: "#fff" }}>Back to Students List </Link></>);
                      }

                      if (chKRoleAr == "schoolsuper") {
                        return (<><Link to={`/studentList?pageid=` + stpage} style={{ color: "#fff" }}>Back to Students List </Link></>);
                      }
                    })()}
                    </div>
                </div>
            <div class="row">
              <div class="col-12 ">
                <form onSubmit={handleSubmit2(submitValueStep1)}>
                  <div class="white-bg px-5 pt-4 pb-4">



                    <div class="row mt-4 resume-wrap">
                      <div class="col-lg-6 mb-5">
                        <label for="firstName_m" class="form-label">First name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="firstName_m" defaultValue={"First Name"} placeholder="" {...register2('firstName_m', { required: true })} />
                        {errors2.firstName_m && errors2.firstName_m.type === "required" && <div class="error">First name is required</div>}
                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="lastName_m" class="form-label">Last name <span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="lastName_m" defaultValue={"Last Name"} placeholder="" {...register2('lastName_m', { required: true })} />
                        {errors2.lastName_m && errors2.lastName_m.type === "required" && <div class="error">Last name is required</div>}
                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="email_m" class="form-label mr-2">Personal Email <span class="red-text">*</span></label> <small>Please do not use your school email.</small></div>
                        <input type="text" class="form-control" id="email_m" defaultValue={"demo@demo.com"} placeholder="" {...register2('email_m', { required: true, pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ })} readOnly={true} />
                        {errors2.email_m && errors2.email_m.type === "required" && <div class="error">Email is required</div>}
                        {errors2.email_m && errors2.email_m.type === "pattern" && <div class="error">Please provide valid email.</div>}
                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="mobile1_m" class="form-label mr-2">Phone<span class="red-text">*</span></label> <small>XXX-XXX-XXXX Format</small></div>
                        <input type="text" class="form-control" id="mobile1_m" defaultValue={"1234567899"} {...register2('mobile1_m', { required: true })} />
                        {errors2.mobile1_m && errors2.mobile1_m.type === "required" && <div class="error">Phone is required</div>}
                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="firstName" class="form-label">I am Currently Pursuing <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" id="pursuing_m" >
                          <option selected="" value=''>Select</option>
                          {
                            educations.map((data, index) => (
                              <option value={data.educationId}>{data.name}</option>
                            ))}
                        </select>
                        <div id="pursuingValidMsg_m" class="error">This Field is required.</div>
                      </div>

                      <div class="col-lg-6 mb-5">
                        <label for="classType_m" class="form-label">GRADUATING YEAR  <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" id="classType_m" >
                          {/* {...register2('classType_m', { required: true })} */}
                          <option selected="" value=''>Select</option>
                          {
                            currentYearList.map((data, index) => (
                              <option value={data}>{data}</option>
                            ))}
                        </select>
                        {errors2.classType_m && errors2.classType_m.type === "required" && <div class="error">Class type is required</div>}
                      </div>

                      <div class="col-lg-6 mb-5" id="school_div_m">
                        <label for="school_district_m" class="form-label">school district <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" id="school_district_m" onChange={handleChangeSchool_m} defaultValue="1"  >
                          {/* {...register2('school_district_m', { required: true })} */}
                          <option selected="" value=''>Select</option>
                          {
                            schoolIds.map((data, index) => (
                              <option value={data.schoolId}>{data.name}</option>
                            ))
                          }
                          <option value="0" >Other</option>
                        </select>
                        <div id="school_districtValidMsg_m" class="error">School District is required.</div>
                      </div>

                      <div class="col-lg-6" id="schoolIdOtherDiv_m">
                        <div class="d-flex"><label for="schoolIdOther" class="form-label mr-2">Name of the Institution <span class="red-text">*</span></label> </div>
                        <input type="text" class="form-control" id="schoolIdOther_m" aria-describedby="emailHelp" placeholder="Other" />
                        <div id="schoolIdOther_mmsg" class="error">Name of the Institution is required.</div>
                      </div>

                      <div class="col-lg-6 mb-5" id="college_div_m">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">College / University<span class="red-text">*</span></label> </div>
                        <select class="form-select" aria-label="Default select example" id="university_m" onChange={handleChangeCollege_m}>
                          {/* {...register2('school_district_m', { required: true })} */}
                          <option value="">Select College</option>
                          {
                            collegeInfo.map((data, index) => (
                              <option value={data.enrolledCollegeId}>{data.name}</option>
                            ))}
                        </select>
                        <div id="university_nameValidMsg_m" class="error">College / Universities is required.</div>
                      </div>

                      <div class="col-lg-6" id="collegeIdOtherDiv_m">
                        <div class="d-flex"><label for="collegeIdOther" class="form-label mr-2">Name of the Institution<span class="red-text">*</span></label> </div>
                        <input type="text" class="form-control" id="collegeIdOther_m" aria-describedby="emailHelp" placeholder="Other" />
                        <div id="collegeIdOther_mmsg" class="error">Name of the Institution is required.</div>
                      </div>

                      <div class="col-lg-12 mb-4" id="planedu_m">

                        <div class="d-flex">
                          <label for="education" class="form-label mr-2">Are you currently continuing education? <span class="red-text">*</span></label>
                        </div>

                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="continueEducation_m" id="inlineRadio1_m" value="Y" style={{ borderRadius: "30px" }} />
                          <label class="form-check-label" for="inlineRadio1_m">YES</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="continueEducation_m" id="inlineRadio2_m" value="N" />
                          <label class="form-check-label" for="inlineRadio2_m">NO</label>
                        </div>

                        <div className="row" id="continueEduDiv_m">
                          <div className="col-md-6 mt-4">
                            <div class="d-flex"><label for="lookingFor" class="form-label mr-2">Name of The Institution <span class="red-text">*</span></label></div>
                            <select class="form-select" id="collegeId_m">
                              <option value="">Select College</option>
                              <option value="0">I Don't know yet</option>
                              {
                                collegeInfo.map((data, index) => (
                                  <option value={data.enrolledCollegeId}>{data.name}</option>
                                ))}
                            </select>
                            <div id="extensionvalidmsg_m" class="error">Name of the Institution is required.</div>
                          </div>

                          <div className="col-md-6 mt-4">
                            <div class="d-flex"><label for="pursuingProgram_m" class="form-label mr-2">Pursuing Program</label></div>
                            <input type="text" class="form-control " id="pursuingProgram_m" aria-describedby="emailHelp" placeholder="Program Name" />
                          </div>
                        </div>

                      </div>



                      {/* <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Password <span class="red-text">*</span></label> <small>8-15 Characters, Must contain 1 letter and 1 number</small></div>
                        <input type="password_m" class="form-control" defaultValue="Nexa@123$" id="password_m" placeholder="" {...register2('password_m', { required: true })} />
                        {errors2.password_m && errors2.password_m.type === "required" && <div class="error">Password is required</div>}
                      </div> */}

                      <div class="mt-4 mb-4">
                        {/* <a href="#" class="reg-button">Next &#62;&#62;</a> */}
                        <button type="submit" class="reg-button"  >Next &#62;&#62;</button>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* Step 2 */}
        {/* <StudentRegistration2 /> */}




        <section class="company-detail-wrap" id="step2">
          <div>
            <div class="col-12 ">
              <form onSubmit={handleSubmit3(submitValueStep3)}>
                <div class="white-bg px-4">
                  <div class="row">
                    <div class="text-center">
                      <h2 class="headtitle ">FIELDS I AM INTERESTED IN</h2>
                    </div>
                    <div class="col-12 p-3 pb-0">
                      <div class="mobile-form-avl p-3">
                        <div class="category-check-itm">
                          {/* {...register3('category_m', { required: true })} */}
                          <ul>
                            {
                              categories.map((data, index) => (
                                <li><input class="form-check-input" type="checkbox" name="category_m" value={data.catId} id="category_m" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                              ))}
                          </ul>

                        </div>

                      </div>
                      {errors3.category && errors3.category.type === "required" && <div class="error">Category is required</div>}
                    </div>
                    <div class="mt-4 mb-4">
                      <button type="submit" class="reg-button">Next &gt;&gt;</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>



        {/* Step 3 */}
        {/* <StudentRegistration3 /> */}


        <section class="company-detail-wrap " id="step3">
          <div>
            <div class="col-12 ">
              <form onSubmit={handleSubmit4(submitValueStep4)}>
                <div class="white-bg px-4">
                  <div class="row stu-reg-st3 pt-3">

                    <div class="col-lg-12 mb-5">
                      <div><label for="career_m" class="form-label mr-2" >I AM INTERESTED IN THESE FIELDS FOR THESE REASONS </label> </div>
                      <small>(Leave blank if not known.)</small>


                      {/* <CKEditor
                                editor={ ClassicEditor }
                                config={{
                                  removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
                                }}
                                id="careers"
                                data={ studentCareersMobile }                            
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log( 'Editor is ready to use!', editor );
                                    //editor.setData(profileDescription);
                                    //editor.setData(profileDescription);
                                    
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setStudentCareersMobile(data);
                                    console.log( { event, editor, data } );
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }
                            /> */}


                      <SunEditor
                        setOptions={{
                          height: "120",
                          //buttonList: [['font', 'align'], ['image']]

                          buttonList: [
                            /**['undo', 'redo'],
                            ['font', 'fontSize', 'formatBlock'],
                            ['paragraphStyle', 'blockquote'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/', // Line break
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link', 'image' /** ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                            ['fullScreen', 'showBlocks', 'codeView'],
                            ['preview'], */

                            /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                          ]
                          //buttonList: buttonList.complex
                          // plugins: [font] set plugins, all plugins are set by default
                          // Other option
                        }}
                        onChange={handleChangeStudentCareersMobDesc}
                        setContents={studentCareersMobile}
                        //onImageUpload={handleImageUpload}
                        onImageUploadBefore={handleImageUploadBefore}
                      />

                      <div id="studentCareersValidMsgMobile" class="error">This field is required.</div>

                      {/* <textarea class="form-control textarea" placeholder="Leave a comment here"  defaultValue={"demo"} id="careers_m" spellcheck="false" {...register4('career_m', { required: true })}></textarea>
                                {errors4.career_m && errors4.career_m.type === "required" && <div class="error">Careers is required</div>} */}
                    </div>





                    {/* <div class="col-lg-12 mb-5">
                                <div><label for="courses_related_m" class="form-label mr-2 mb-0">WHAT COURSES HAVE YOU TAKEN THAT ARE RELATED TO THIS CAREER? <span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                                <textarea class="form-control textarea" placeholder="Leave a comment here" id="courses_related_m" spellcheck="false" {...register4('courses_related_m', { required: true })}></textarea>
                                {errors4.courses_related_m && errors4.courses_related_m.type === "required" && <div class="error">Courses is required</div>}
                            </div> */}
                    <div class="col-lg-12 mb-5">
                      <div><label for="skills_m" class="form-label mr-2 mb-0" >I HAVE THE FOLLOWING RELEVANT SKILLS /EXPERIENCE.</label> </div>
                      <small>(Leave blank if not known.)</small>
                      {/* <span class="red-text">Required for Juniors and Seniors</span> */}


                      {/* <CKEditor
                                editor={ ClassicEditor }
                                config={{
                                  removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
                                }}
                                id="skills"   
                                data={ studentSkillsMobile }                         
                                onReady={ editor => {              
                                    console.log( 'Editor is ready to use!', editor );                                
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setStudentSkillsMobile(data);
                                    console.log( { event, editor, data } );
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }
                            /> */}


                      <SunEditor
                        setOptions={{
                          height: "120",
                          //buttonList: [['font', 'align'], ['image']]

                          buttonList: [
                            /**['undo', 'redo'],
                            ['font', 'fontSize', 'formatBlock'],
                            ['paragraphStyle', 'blockquote'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/', // Line break
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link', 'image' /** ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                            ['fullScreen', 'showBlocks', 'codeView'],
                            ['preview'], */

                            /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                          ]
                          //buttonList: buttonList.complex
                          // plugins: [font] set plugins, all plugins are set by default
                          // Other option
                        }}
                        onChange={handleChangeStudentSkillsMobDesc}
                        setContents={studentSkillsMobile}
                        //onImageUpload={handleImageUpload}
                        onImageUploadBefore={handleImageUploadBefore}
                      />

                      <div id="studentSkillsValidMsgMobile" class="error">This field is required.</div>

                      {/* <textarea class="form-control textarea" placeholder="Leave a comment here" defaultValue={"demo"} id="skills_m" spellcheck="false" {...register4('skills_m', { required: true })}></textarea>
                                {errors4.skills_m && errors4.skills_m.type === "required" && <div class="error">Skills is required</div>} */}
                    </div>




                    {/* <div class="col-lg-12 mb-5">
                                <div><label for="additional_course_m" class="form-label mr-2" >WHAT ADDITIONAL COURSES MIGHT HELP YOU PREPARE FOR THIS CAREER? <span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                               
                               
                               
                                <CKEditor
                                editor={ ClassicEditor }
                                id="studentCourses"   
                                data={studentCoursesMobile}                         
                                onReady={ editor => {              
                                    console.log( 'Editor is ready to use!', editor );                                
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setStudentCoursesMobile(data);
                                    console.log( { event, editor, data } );
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }
                            />
                         <div id="studentCoursesValidMsgMobile" class="error">This field is required.</div>
                               
                               
                            </div> */}

                    <div class="col-lg-12 mb-5">
                      <div><label for="seeking_m" class="form-label mr-2" >I AM SUBMITTING THIS FORM BECAUSE I AM LOOKING FOR: <span class="red-text">*</span></label> </div>
                      <div class="category-check-itm">
                        <ul>
                          {
                            jobTypes.map((data, index) => (
                              <li><input name="seeking_m" class="form-check-input" type="checkbox" value={data.jobTypeId} id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                            ))}
                          {/* {...register4('seeking_m', { required: true })} */}
                          {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register4('employment', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Full Time </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register4('employment', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Part Time</label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register4('employment', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Summer/Season work </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" {...register4('employment', { required: true })}/> <label class="form-check-label" for="defaultCheck1"> Internship/Co-opportunity  </label></li> */}
                        </ul>
                      </div>
                      {errors4.seeking_m && errors4.seeking_m.type === "required" && <div class="error">Employment is required</div>}
                    </div>

                    <div class="col-lg-12">
                      <div class="justify-content-between align-items-end">
                        <div>
                          <label for="resumePath" class="form-label">My Resume(Optional) </label>
                          <div class="d-flex align-items-center">
                            {/* <input class="form-control form-control-lg choose-file mr-2" id="resumePath" type="file"/> */}
                            <input class="form-control" id="resumeMobile" type="file" onChange={() => getImageMobile()} />

                          </div>
                          <small> 1MB Max, File Type: PDF,DOC</small>
                          <div id="fileErrorMobile" class="error">Invalid file format</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12 mb-5">
                      <div><label for="comments_m" class="form-label mr-2" >ANY ADDITIONAL THOUGHTS/COMMENTS THAT MIGHT BE HELPFUL? </label> </div>
                      <small>(Leave blank if not known.)</small>

                      {/* <CKEditor
                                editor={ ClassicEditor }
                                config={{
                                  removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
                                }}
                                id="studentCommentsMobile"    
                                data={ studentComments }                        
                                onReady={ editor => {              
                                    console.log( 'Editor is ready to use!', editor );                                
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setStudentCommentsMobile(data);
                                    console.log( { event, editor, data } );
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }
                            /> */}


                      <SunEditor
                        setOptions={{
                          height: "120",
                          //buttonList: [['font', 'align'], ['image']]

                          buttonList: [
                            /**['undo', 'redo'],
                            ['font', 'fontSize', 'formatBlock'],
                            ['paragraphStyle', 'blockquote'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/', // Line break
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link', 'image' /** ,'math' /], // You must add the 'katex' library at options to use the 'math' plugin.                    
                            ['fullScreen', 'showBlocks', 'codeView'],
                            ['preview'], */

                            /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                          ]
                          //buttonList: buttonList.complex
                          // plugins: [font] set plugins, all plugins are set by default
                          // Other option
                        }}
                        onChange={handleChangeStudentCommentsMobDesc}
                        setContents={studentComments}
                        //onImageUpload={handleImageUpload}
                        onImageUploadBefore={handleImageUploadBefore}
                      />
                      <div id="studentCommentsValidMsgMobile" class="error">This field is required.</div>

                      {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="comments_m"  defaultValue={"demo"} spellcheck="false" {...register4('comments_m', { required: true })}></textarea>
                                {errors4.comments_m && errors4.comments_m.type === "required" && <div class="error">Additional thoughts is required</div>} */}
                    </div>

                    <div class="col-lg-12" id="parent_authorization">
                      <div class="justify-content-between align-items-end">
                        <div>
                          <label for="authorization_m" class="form-label">MY PARENT/GUARDIAN AUTHORIZES MY INFORMATION TO BE SHARED (if under 18)</label>
                          <select class="form-select" aria-label="Default select example" style={{ maxWidth: "420px" }} id="authorization_m">
                            <option selected="">Select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>

                      </div>
                    </div>

                    <div class="mt-4 mb-4">
                      <button type="submit" class="reg-button">Update</button>
                    </div>


                    <div id="successMessage_m" class="alert">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <img src="/images/success-alert.png" alt="" />
                          <strong class="mx-2">Success!</strong> Profile info  has been updated successfully.
                        </div>
                        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                      </div>
                    </div>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>


        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
          </div>
        </section>
















      </main>
    </>
  )
};

EditStudent.propTypes = {};

EditStudent.defaultProps = {};

export default EditStudent;
