import React,{history,useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Services from "../../services/services";
import Recent_Jobs from "../Jobs/Recent_Jobs";
import { useNavigate } from 'react-router-dom';
import Hiring from "../Jobs/Hiring";
const Ticket=()=>
{
      const [itemName, setFieldName] = useState('');
      const navigate = useNavigate();
      const [contactUsContent, setContactusContent] = useState([]);
      const {
        register,
        formState: { errors },
        handleSubmit
      } = useForm({
        criteriaMode: "all",mode: "onBlur" 
      });

      const getPageInfo = () => {        
        Services.getCMSPageContent('77').then((res) => {            
            console.log(JSON.stringify(res));
            if(res.data){
                setContactusContent(res.data.content.rendered);
            }       
        });       
     }

      const submitValue=()=>
      {
        const requestObject = {
                                "firstName": $("#firstName").val(),
                                "lastName": $("#lastName").val(),
                                "phone": $("#yourphone2").val(),
                                "email": $("#email").val(),
                                "comment": $("#comment").val(),
                                "siteId":process.env.React_App_SITE_ID
                             };
        const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json',
                                          //'Authorization': "Bearer "+accessTokenIn 
                                          //'Authorization': accessTokenIn 
                                        },
                                body: JSON.stringify(requestObject)
                            }; 
        Services.contactNotification(requestOptions).then((res) => 
        {
            console.log(JSON.stringify(res));
            navigate('/thankyou');
        });                                        
      }
      useEffect(() => 
      {
        const jsonData = {
            "ticket": {
              
            }
          };

        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);

        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/shell.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "20109775",
                    formId: "fab1131d-97a3-42fe-a77e-4015df326c3b",
                    target: '#hubspotForm'
                })
            }
        });

          
      },[]);  

   return(<>
            <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                       <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="#">Submit a Request</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
    
        <section class="company-detail-wrap py-5 ">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="white-bg px-5 pt-5 pb-4">
                           
                            <form onSubmit={handleSubmit(submitValue)}>
                            <div class="row resume-wrap" id="hubspotForm">
                            </div>
                            
                            </form>
                        </div>
                      
                    </div>
                    <div class="col-lg-4">
                          <Recent_Jobs/>
                         
                       



                    </div>
                </div>
            </div>
        </section>
        <Hiring/>
    </main>
   </>);
 
}

Ticket.propTypes = {};

Ticket.defaultProps = {};

export default Ticket;