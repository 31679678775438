import React, { history, useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import Services from "../../services/services";
const ManageProfile = () => {


    const [categories, setCategory] = useState([]);
    const [jobTypes, setJobType] = useState([]);
    const [itemName, setFieldName] = useState('');

    useEffect(() => {
        const jsonData = {
            "manageProfile": {
              
            }
          };
          var jsondataformatted = JSON.stringify(jsonData);
          sessionStorage.setItem('pagejsonObject', jsondataformatted);

        window.location.href=('/editAdminstrationLogin');
        $('#phone').usPhoneFormat();
        getIndustryData();
        getJobType();
       

    }, []);

    const getIndustryData = () => {
        Services.getIndustryData().then((res) => {
            console.log("Industry data=" + JSON.stringify(res.data));
            setCategory(res.data.content);
        });
    }

    const getJobType = () => {
        Services.getJobType().then((res) => {
            console.log("setJobType data=" + JSON.stringify(res.data));
            setJobType(res.data.content);
        });
    }

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    const submitValue = () => {

    }

    return (
        <>
            <main class="empr-directory-main">
                <div class="main-bgpatten"></div>
                <section class="headtitle-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <nav style={{ "--bs-breadcrumb-divider": '>' }} aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item headtitle "><a href="#">Manage Profile</a></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- info typography --> */}
                <section class="company-detail-wrap py-5 ">
                    <div class="container">
                        <div class="row">

                            <div class="col-12 ">
                                <div class="white-bg px-5 pt-4 pb-4">

                                    <b>Senior at Marysville Exempted Village School District </b>
                                    <form onSubmit={handleSubmit(submitValue)}>
                                    <div class="row mt-4 resume-wrap">
                                        <div class="col-lg-6 mb-5">
                                        <label for="firstName" class="form-label">First Name<span class="red-text">*</span></label>
                                            <input type="text" class="form-control" id="firstName" placeholder="" {...register('firstName', { required: true })}/>
                                            {errors.firstName && errors.firstName.type === "required" && <div class="error">First name is required</div>}
                                        </div>

                                        <div class="col-lg-6 mb-5">
                                        <label for="lastName" class="form-label">Last Name<span class="red-text">*</span></label>
                                            <input type="text" class="form-control" id="lastName" placeholder="" {...register('lastName', { required: true })}/>
                                            {errors.lastName && errors.lastName.type === "required" && <div class="error">Last name is required</div>}
                                        </div>

                                        <div class="col-lg-6 mb-5">
                                        <label for="email" class="form-label">Email<span class="red-text">*</span></label>
                                            <input type="text" class="form-control" id="firstName" placeholder=""  {...register('email', { required: true, pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ })}/>
                                            {errors.email && errors.email.type === "required" && <div class="error">Email is required</div>}
                                            {errors.email && errors.email.type === "pattern" && <div class="error">Invalid email address.</div> }
                                        </div>

                                        <div class="col-lg-6 mb-5">
                                            <div class="d-flex"><label for="phone" class="form-label mr-2">Phone</label> <small>XXX-XXX-XXXX Format</small></div>
                                            <input type="text" class="form-control" id="phone" placeholder="" {...register('phone', { required: true })}/>
                                            {errors.phone && errors.phone.type === "required" && <div class="error">Phone number is required</div>}
                                        </div>

                                        <div class="col-lg-6 mb-5">
                                            <label for="firstName" class="form-label">Class Year<span class="red-text">*</span></label>
                                            <select class="form-select" aria-label="Default select example" {...register('classYear', { required: true })}>
                                                <option selected="" value="">Select</option>
                                                <option value="2018">2018</option>
                                                <option value="2019">2019</option>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                            </select>
                                            {errors.classYear && errors.classYear.type === "required" && <div class="error">Class Year is required</div>}
                                        </div>


                                        {/* <div class="col-lg-12 mb-5">
                                    <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">CATEGORIES OF INTEREST <span class="red-text">*</span></label> <small>Max Categories Freshman(5), Sophomore(4), Junior(3), Senior(2)                                    </small></div>

                                    <div class="category-check">
                                        <div class="category-check-itm">
                                            <ul>

                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">Agriculture</label></li>

                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">   Construction/Skilled Trades</label></li>

                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">  Government & Public Safety</label></li>

                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">Hospitality, Restaurant & Retail</label></li>

                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">  Manufacturing and Logistics</label></li>

                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">Professional Services</label></li>

                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> Sales and Marketing</label></li>

                                            </ul>
                                        </div>
                                        <div class="category-check-itm">
                                            <ul>

                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">   Banking, Finance & Insurance</label></li>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">  Education</label></li>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">Healthcare/Medical</label></li>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">  IT</label></li>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">Non-Profit</label></li>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">  Real Estate/Property Management</label></li>
                                                <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1">Social Work/Human Services</label></li>

                                            </ul>
                                        </div>


                                    </div>

                                </div> */}

<div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">TELL US WHY YOU SELECTED THESE CAREERS?<span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                        <textarea class="form-control textarea" placeholder="Leave a comment here"  id="careers" onChange={e => setFieldName(e.target.value)} {...register("personalinfo", {
                          required: "Personal profile is required.",
                          maxLength: { value: 500, message: 'Should not allow more than 500 character' }
                        })} />
                        <ErrorMessage
                          errors={errors}
                          name="personalinfo"
                          class="invalid-feedback"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(([type, message]) => (
                                <p key={type} class="error">{message}</p>
                              ))
                              : null;
                          }}

                        />
                      </div>

                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-0">WHAT COURSES HAVE YOU TAKEN THAT ARE RELATED TO THIS CAREER? <span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                        {/* <span class="red-text">Required for Juniors and Seniors</span> */}
                        <textarea class="form-control textarea" placeholder="Leave a comment here" id="courses_related" spellcheck="false" {...register('courses_related', { required: true,maxLength:500 })}></textarea>
                        {errors.courses_related && errors.courses_related.type === "required" && <div class="error">This field is required</div>}
                        {errors.courses_related && errors.courses_related.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>}
                      </div>

                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-0"> LIST SOME SKILLS/EXPERIENCES THAT MAKE THIS A GOOD CHOICE FOR YOU.<span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                        {/* <span class="red-text">Required for Juniors and Seniors</span> */}
                        <textarea class="form-control textarea" placeholder="Leave a comment here" id="skills" spellcheck="false" {...register('skills', { required: true,maxLength:500 })}></textarea>
                        {errors.skills && errors.skills.type === "required" && <div class="error">This field is required</div>}
                        {errors.skills && errors.skills.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>}
                      </div>

                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">WHAT ADDITIONAL COURSES MIGHT HELP YOU PREPARE FOR THIS CAREER? <span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                        <textarea class="form-control textarea" placeholder="Leave a comment here" id="courses" spellcheck="false" {...register('courses', { required: true,maxLength:500 })}></textarea>
                        {errors.courses && errors.courses.type === "required" && <div class="error">This field is required</div>}
                        {errors.coursecoursess_related && errors.courses_related.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>}
                      </div>

                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">ANY ADDITIONAL THOUGHTS/COMMENTS THAT MIGHT BE HELPFUL? <span class="red-text">*</span></label> <small>Max 500 Characters</small></div>
                        <textarea class="form-control textarea" placeholder="Leave a comment here" id="comments" spellcheck="false"  {...register('comments', { required: true,maxLength:500 })}></textarea>
                        {errors.comments && errors.comments.type === "required" && <div class="error">This field is required</div>}
                        {errors.comments && errors.comments.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>}
                      </div>

                                        <div class="col-lg-12 mb-5">
                                            <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">CATEGORIES OF INTEREST</label></div>

                                            <div >
                                                <div class="category-check-itm">
                                                    <ul class="category-check">
                                                    {
                                categories.map((data, index) => (


                                  <li><input class="form-check-input" type="checkbox" name="exp" value={data.catId} id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                  ))}
                                                        {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1">Agriculture</label></li>
                                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1">Internship/Co-Op Opportunity</label></li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 mb-5">
                                            <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">Currently seeking employment for:</label></div>

                                            <div class="category-check">
                                                <div class="category-check-itm">
                                                    <ul>
                                                    {
                                jobTypes.map((data, index) => (


                                  <li><input class="form-check-input" type="checkbox" name="exp" value={data.catId} id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                  ))}
                                                        {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1">Full Time</label></li>
                                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1">Part Time</label></li>
                                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1">Summer/Seasonal Work</label></li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 mb-5">
                                            <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">I plan to attend the Talent Connection Forum:</label></div>

                                            <div class="category-check">
                                                <div class="category-check-itm">
                                                    <ul>
                                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> <label class="form-check-label" for="defaultCheck1">Yes i'm  Planning on it</label></li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 mb-5">

                                            <div class="d-flex justify-content-between align-items-end">
                                                <div>
                                                    <label for="firstName" class="form-label">My parent/guardian authorizes my information to be shared:</label>

                                                    <select class="form-select" aria-label="Default select example" style={{ maxWidth: "420px" }}>

                                                        <option value="1">Yes</option>
                                                        <option value="2">No</option>

                                                    </select>
                                                </div>
                                                {/* <div><a href="#" class="reg-button">Save</a></div> */}
                                                <div><button type="submit" class="reg-button">Save</button></div>
                                            </div>

                                        </div>

                                        {/* <!-- step 3 --> */}

                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </>
    )
};

ManageProfile.propTypes = {};

ManageProfile.defaultProps = {};

export default ManageProfile;
