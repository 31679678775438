import React,{history,useEffect, useState, useMemo } from "react";
import services from "../../services/services";
import Recent_Jobs from "../Jobs/Recent_Jobs";
import Hiring from "../Jobs/Hiring";
import $, { parseJSON } from 'jquery';

const Faq = () => {
    const [faqContent, setFaqContent] = useState([]);
    const getPageInfo = () => {        
        services.getCMSPageContent('89').then((res) => {            
            console.log(JSON.stringify(res));
            if(res.data){
                setFaqContent(res.data.content.rendered);
            }       
        });       
     }
    useEffect(() => {        
        getPageInfo();
        const jsonData = {
            "faq": {
              
            }
          };

        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);
  
    }, []);
  return(
  <>
<main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                    <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="#">FAQ</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
 
        <section class="company-detail-wrap py-5 ">
            <div class="container">
            <div class="row">
            <div class="col-lg-8">
                        <div class="white-bg px-5 pt-5 pb-4">
                            <div class="row mb-3">
                                <div class="col-lg-12 mb-4">
                
                <div dangerouslySetInnerHTML={{ __html: faqContent }} />
                </div> </div> </div> 
            </div> 

            <div class="col-lg-4">
                    <Recent_Jobs/>
                        {/* <div class="white-bg mb-4">
                            <img src="images/ad-1.png" class="w-100" alt=""/>
                        </div>
                        <div class="white-bg mb-4">
                            <img src="images/ad-2.png" class="w-100" alt=""/>
                        </div> */}
                        {/*<div class="p-4 white-bg mb-4">
                            <h2 class="title-stay-connect">Stay connected. Get the app!</h2>
                            <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                            <img src="images/HTO-app-face.png" class="w-100" alt=""/>

                            <div class="d-flex justify-content-center  mt-3">
                                <img src="images/apple-app-store.png" alt="" style={{width: "130px"}}/>
                                <img src="images/google-play.png" alt="" class="ms-2" style={{maxWidth: "130px"}}/>
                            </div>
                        </div> */}


                    </div>

                </div> 
            
            </div>
           
        </section>

        <Hiring/>

    </main>
  </>
)};

Faq.propTypes = {};

Faq.defaultProps = {};

export default Faq;
// JavaScript Document