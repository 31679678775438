import React,{history,useEffect, useState, useMemo } from "react";

import SidebarSchoolAdmin from "../../utility/sidebarSchoolAdmin";
import AnnouncementWidget from "../Jobs/announcement-wid";
import Hiring from "../Jobs/Hiring";

import Services from "../../services/services";
import $, { parseJSON } from 'jquery';
import moment from "moment";
import Pagination from "../../utility/Pagination/Pagination";
import EventWidget from "../Jobs/eventwid";

import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
const SchoolAdminDashboard = () => {
   const navigate = useNavigate();
   const [jobList, setJobList] = useState([]);
   const [categories, setCategory] = useState([]);
   const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const ITEMS_PER_PAGE = 4; // Per page show the data pagination
    const Pagesize=5;
    //const studentId = 56;
    const studentId=sessionStorage.getItem("logStudentId");
    const loginDisplayName=sessionStorage.getItem("displayName");
    const userId=sessionStorage.getItem("logUserId");

    const FVITEMS_PER_PAGE = 2;
    const [fvtotalItems, setFvTotalItems] = useState(0);  
    const [fvtotalpages, setFvtotalPages] = useState();      
    const [fvcurrentPage, setFvCurrentPage] = useState(1);
    const [favVideoList, setFavVideoList] = useState([]);
    const chKRoleAr=sessionStorage.getItem("roleAr");

   useEffect(() => {
      const jsonData = {
         "schoolAdminDashboard": {
           
         }
       };
       var jsondataformatted = JSON.stringify(jsonData);
       sessionStorage.setItem('pagejsonObject', jsondataformatted);
      
      if( (chKRoleAr == "schoolsuper") || (chKRoleAr == "schooladmin") ){                     
         //navigate('/login');         
         getIndustryData();
       }else{
         navigate('/Home');
       }

      //getIndustryData();
      //getStudentProfile(studentId);
      //filterContent(ITEMS_PER_PAGE,'1');
      //filterContentFav(ITEMS_PER_PAGE,'1');

   }, []);


   


  
   const getIndustryData = () => {
      Services.getIndustryData().then((res) => {  
        console.log("Industry data=" + JSON.stringify(res.data));  

        console.log("getLocationCounty data=" + JSON.stringify(res.data));
        var lc = res.data.content;          
        var myObject = {}
        for(var i=0;i<lc.length;i++){             
            var dstrVal = lc[i]['name'];           
          myObject[lc[i]['catId']] = dstrVal;           
          //lcObject.push(myObject);
        }         
        setCategory(myObject);

        //setCategory(res.data);  
      });
  
    }
   

    

   
  return(
  <>
   <main  class="empr-directory-main">
         <div class="main-bgpatten"></div>
         {/* <!-- info typography --> */}
         <section class="headtitle-wrap">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                    <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item headtitle "><a href="#">School Admin </a></li>
                        </ol>
                      </nav>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- info typography --> */}
         <section class="py-5">
            <div class="container">
               <div class="row">
                  <div class="col-12 ">
                     <div class="white-bg p-5">
                        <div class="row">
                           <SidebarSchoolAdmin/>
                           <div class="col-lg-8 mb-4">
                               <h4>Welcome back, {loginDisplayName}!</h4>
                               <p>This is your Hometown Opportunity Dashboard. Here you’ll see all of the most important information about your job or career search, all in one handy location.</p>
                              
                               <EventWidget></EventWidget>


                               <AnnouncementWidget></AnnouncementWidget>

                              
   
                              
   
                             
   
                             
   
   
                           </div>
                       </div>
                     </div>
                  
                  </div>
                 
               </div>
            </div>
         </section>
         <section class="white-bg py-5">
            <div class="container">
            <Hiring/>
              
            </div>
         </section>
      </main>

  </>
)};

SchoolAdminDashboard.propTypes = {};

SchoolAdminDashboard.defaultProps = {};

export default SchoolAdminDashboard;
