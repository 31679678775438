import React, { useEffect, useState } from 'react';

import { ErrorMessage } from "@hookform/error-message";
import { set, useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import StudentRegistration2 from "./student-registration2";
import StudentRegistration3 from "./student-registration3";
import Services from '../../services/services';
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup'
import Header from '../../layouts/header/header';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SunEditor, { buttonList } from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';

const StudentRegistrationFieldsofInterest = () => {

  const [jobTypes, setJobType] = useState([]);
  const [categories, setCategory] = useState([]);
  const [studentCareers, setStudentCareers] = useState([]);
  const [studentSkills, setStudentSkills] = useState('');
  const [studentCareersMobile, setStudentCareersMobile] = useState('');
  const [studentSkillsMobile, setStudentSkillsMobile] = useState('');
  const [categoriesSelected, setCategoriesSelected] = useState([]);

  const navigate = useNavigate();

  const userIdChkLogin = sessionStorage.getItem("logUserId");
  const studentRegId = sessionStorage.getItem("studentRegId");

  function handleChangeStudentCareersDesc(content) {
    setStudentCareers(content);
  }
  function handleChangeStudentSkillsDesc(content) {
    setStudentSkills(content);
  }

  function handleChangeStudentCareersMobDesc(content) {
    setStudentCareersMobile(content);
  }

  function handleChangeStudentSkillsMobDesc(content) {
    setStudentSkillsMobile(content);
  }

  function handleImageUploadBefore(files, info, uploadHandler) {
    var reader1 = new FileReader();
    reader1.readAsDataURL(files[0]);
    reader1.onload = function () {
      var rdt1 = reader1.result;
      var base64result_banner = rdt1.split(',')[1];
      var bname = files[0].name;
      var bextn = bname.split(".").pop();
      var bannerActName = bname.split(".")[0];
      var fileext_banner = bextn;

      const requestObject = {
        //"imageName": files[0].name,
        "imageName": bannerActName,
        "imageExtension": fileext_banner,
        "imageByteString": base64result_banner
      };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "Bearer "+accessTokenIn 
          //'Authorization': accessTokenIn 
        },
        body: JSON.stringify(requestObject)
      };

      Services.fileUploadS3URL(requestOptions).then((res) => {
        const response = {
          // The response must have a "result" array.
          "result": [
            {
              "url": res.url,
              "temp": files[0],
              "name": bannerActName,
              "size": files[0].size
            },
          ]
        }
        uploadHandler(response);

      });
    };

  }
  const getIndustryData = () => {

    Services.getIndustryData().then((res) => {

      console.log("Industry data=" + JSON.stringify(res.data));

      setCategory(res.data.content);

    });

  }

  useEffect(() => {
    
    const jsonData = {
      "studentRegistrationFieldsofInterest": {
        
      }
    };
    var jsondataformatted = JSON.stringify(jsonData);
    sessionStorage.setItem('pagejsonObject', jsondataformatted);

    if(studentRegId != null){
      getStudentProfile(studentRegId);
    }
    else{
      navigate('/StudentRegistrationPersonalInfo');
    }  
    
    $(".se-dialog-tabs").hide();
    $(".load-wrap").hide();
    $("#successMessage").hide();
    $("#alertFailMessage").hide();
    $("#studentCareersValidMsg").hide();
    $("#studentSkillsValidMsg").hide();
    $("#studentSkillsValidMsgMobile").hide();
    $("#studentCareersValidMsgMobile").hide();
    $('#seekingValidMsg').hide();
    $('#seekingValidMsg_m').hide();
    $('#categoryvalidmsg').hide();
    $('#categoryvalidmsg_m').hide();
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
    getJobType();
    getIndustryData();
  }, []);


  const getStudentProfile = (studentId) => {
    Services.getpubStudentProfile(studentId).then((res) => {
      
      var companyCatIn = res.data.categoriesOfExpertise;
      var seekingIn = res.data.seekingEmploymentTypes;
      setTimeout(() => {
        setCategoriesSelected(res.data.categoriesOfExpertise);
        setStudentCareers(res.data.reasonForSelectCareers);
        setStudentSkills(res.data.experiance);
  
        setStudentCareersMobile(res.data.reasonForSelectCareers);
        setStudentSkillsMobile(res.data.experiance);

        for (var i = 0; i < seekingIn.length; i++) {
          $('input[name="seeking"][value="' + seekingIn[i] + '"]').trigger("click");
          $('input[name="seeking_m"][value="' + seekingIn[i] + '"]').trigger("click");
        }

        for (var i = 0; i < companyCatIn.length; i++) {
          $('input[name="category"][value="' + companyCatIn[i] + '"]').prop("checked", true);
          $('input[name="category_m"][value="' + companyCatIn[i] + '"]').prop("checked", true);
        }

        var isCheck = true;
        return isCheck;
      }, 1000);

    });
  }


  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  const getJobType = () => {
    Services.getJobType().then((res) => {
      //console.log("setJobType data=" + JSON.stringify(res.data));
      var jobtype_unsort = res.data.content;

      var jobtypes_sorted = jobtype_unsort.sort((a, b) => b.jobTypeId - a.jobTypeId);
      array_move(jobtypes_sorted, 0, (jobtypes_sorted.length - 1));
      
      jobtypes_sorted = jobtypes_sorted.filter(function (elem) {
        return elem.name !== 'Continuing Education';
      });
      
      setJobType(jobtypes_sorted);
    });
  }
  const curr_date = Date.now();

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    criteriaMode: "all",
    mode: "onBlur"
    //resolver: yupResolver(formSchema)
  });

  const {
    register: register3,
    formState: { errors: errors4 },
    handleSubmit: handleSubmit3,
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });

  const submitValue = (data) => {
    var catofExpertiseArray = [];
    var validateEditorTxt = 1;

    $.each($("input[name='category']:checked"), function () {
      catofExpertiseArray.push($(this).val());
    });
    if(catofExpertiseArray.length == 0){
      $('#categoryvalidmsg').show();
      $(window).scrollTop($('#categoryvalidmsg').position().top);
      validateEditorTxt = 0;
    }
    else{
      $('#categoryvalidmsg').hide();
    }

    var categoriesArray = [];
    
    $.each($("input[name='seeking']:checked"), function () {
      categoriesArray.push($(this).val());
    });

    /*if(categoriesArray.length == 0){
      $('#seekingValidMsg').show();
      $(window).scrollTop($('#seekingValidMsg').position().top);
      validateEditorTxt = 0;
    }
    else{
      $('#seekingValidMsg').hide();
    } */

    if (validateEditorTxt == 0) {
      return false;
    }

    const requestObject = {
      "studentId": studentRegId,
      //step1 fields
      "firstName": "",
      "lastName": "",
      "email": "",
      "password": "",
      "phone": "",
      //step2 fields
      "educationId": "",
      "classYear": "",
      "schoolId": "",
      "nameOfCollegeUniversity": "",
      "nameOfCollegeUniversityId": "",
      "schoolDistrictOther": "",
      "highSchoolDiplomaExtContinueEduStatus": "",
      "highSchoolDiplomaExtNameOfInstitutionCollegeId": "",
      "highSchoolDiplomaExtPursuingProgram": "",
      "studentInterestedIn": [],
      "schoolName": null,
      //step3 fields
      "categoriesOfExpertise": catofExpertiseArray,
      "seeking": null,
      "reasonForSelectCareers": studentCareers, 
      "experiance": studentSkills,
      //step4 fields
      "seekingEmploymentTypes": [],
      "comments": "",
      "fileName": null,
      "fileExtension": null,
      "pdfByString": null,
      "resumeUrl": null,
      "resumeFilePath": null,

      "dateUpdated": curr_date,
      "dateCreated": curr_date,
      "siteId": process.env.React_App_SITE_ID,
      "helpPrepare": "yes",
      "status": "published",
      "interested": null,
      "events": null,
      "authorization": "",
      "userId": null,
      "countyId": null,
      "isStudent": true,
      "updatedOn": null,
      "securityRoleName": null,
      "course": "",
      "studentTalentConnectionForumIn": [],
      "totalElement": null,
      "searchText": null,
      "logo": null,
      "profileS3Pic": null,
      "partiallyRegistered":"Y",
      "loggedInUser":"No"
  }
    console.log(requestObject);
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "Bearer "+accessTokenIn
        //'Authorization': accessTokenIn
      },
      body: JSON.stringify(requestObject)
    };
    
    $(".load-wrap").show();
    Services.updateStudentProfile(requestOptions).then((res) => {
      $(".load-wrap").hide();

      if (res.success == "false" || res.success == false) {
        $("#alertFailMessage").html(res.message);
        $("#alertFailMessage").fadeTo(2000, 1000).slideUp(1000, function () {

          $("#alertFailMessage").slideUp(1000);
        });

      } else {
        $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#successMessage").slideUp(1000);
        });
        navigate('/studentRegistrationCareerChoices');
      }
    });

  }

  const submitValueStep3 = (data) => {
    var validateEditorTxt = 1;
    var catofExpertiseArray = [];
    $.each($("input[name='category_m']:checked"), function () {
      catofExpertiseArray.push($(this).val());
    });
    if(catofExpertiseArray.length == 0){
      $('#categoryvalidmsg_m').show();
      $(window).scrollTop($('#categoryvalidmsg_m').position().top);
      validateEditorTxt = 0;
    }
    else{
      $('#categoryvalidmsg_m').hide();
    }

    var categoriesArraym = [];

    $.each($("input[name='seeking_m']:checked"), function () {
      categoriesArraym.push($(this).val());
    });

    /*if(categoriesArraym.length == 0){
      $('#seekingValidMsg_m').show();
      $(window).scrollTop($('#seekingValidMsg_m').position().top);
      validateEditorTxt = 0;
    }
    else{
      $('#seekingValidMsg_m').hide();
    } */

    if (validateEditorTxt == 0) {
      return false;
    }

    const requestObject = {
      "studentId": studentRegId,
      //step1 fields
      "firstName": "",
      "lastName": "",
      "email": "",
      "password": "",
      "phone": "",
      //step2 fields
      "educationId": "",
      "classYear": "",
      "schoolId": "",
      "nameOfCollegeUniversity": "",
      "nameOfCollegeUniversityId": "",
      "schoolDistrictOther": "",
      "highSchoolDiplomaExtContinueEduStatus": "",
      "highSchoolDiplomaExtNameOfInstitutionCollegeId": "",
      "highSchoolDiplomaExtPursuingProgram": "",
      "studentInterestedIn": [],
      "schoolName": null,
      //step3 fields
      "categoriesOfExpertise": catofExpertiseArray,
      "seeking": null,
      "reasonForSelectCareers": studentCareersMobile, 
      "experiance": studentSkillsMobile,
      //step4 fields
      "seekingEmploymentTypes": [],
      "comments": "",
      "fileName": null,
      "fileExtension": null,
      "pdfByString": null,
      "resumeUrl": null,
      "resumeFilePath": null,

      "dateUpdated": curr_date,
      "dateCreated": curr_date,
      "siteId": process.env.React_App_SITE_ID,
      "helpPrepare": "yes",
      "status": "published",
      "interested": null,
      "events": null,
      "authorization": "",
      "userId": null,
      "countyId": null,
      "isStudent": true,
      "updatedOn": null,
      "securityRoleName": null,
      "course": "",
      "studentTalentConnectionForumIn": [],
      "totalElement": null,
      "searchText": null,
      "logo": null,
      "profileS3Pic": null,
      "partiallyRegistered":"Y",
      "loggedInUser":"No"
  }
    console.log(requestObject);
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "Bearer "+accessTokenIn
        //'Authorization': accessTokenIn
      },
      body: JSON.stringify(requestObject)
    };
    
    $(".load-wrap").show();
    Services.updateStudentProfile(requestOptions).then((res) => {
      $(".load-wrap").hide();

      if (res.success == "false" || res.success == false) {
        $("#alertFailMessage").html(res.message);
        $("#alertFailMessage").fadeTo(2000, 1000).slideUp(1000, function () {

          $("#alertFailMessage").slideUp(1000);
        });

      } else {
        $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
          $("#successMessage").slideUp(1000);
        });
        navigate('/studentRegistrationCareerChoices');
      }
    });

  }
  
  function validateCheck() {
    const checked = $('input[name="category"]:checked').length;


    if (checked >= 3) {
      $('input[name="category"]').not(':checked').prop('disabled', true);
    } else {
      $('input[name="category"]').prop('disabled', false);
    }
  }
  function validateCheck_m() {
    const checked = $('input[name="category_m"]:checked').length;


    if (checked >= 3) {
      $('input[name="category_m"]').not(':checked').prop('disabled', true);
    } else {
      $('input[name="category_m"]').prop('disabled', false);
    }
  }
  const go_back = () => {
    navigate('/studentRegistrationSchoolInfo');
  }

  return (
    <>
      <main class="empr-directory-main cd-desktop">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item headtitle "><a href="#">Student Registration</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">
          <div class="container">
            <div class="row">
              <div class="col-12 ">
                <form onSubmit={handleSubmit(submitValue)}>
                  <div class="white-bg px-5 pt-4 pb-4">
                  <div class="row mb-0">
                      <div class="col-lg-12 m-auto">
                          <div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                              <h5 className='mb-0'>FIELDS OF INTEREST</h5>
                              <div class="student-reg-step">STEP 3/4</div>
                            </div>
                      </div>
                    </div>
                
                    <div class="row mt-4 resume-wrap">
                    <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">FIELDS I AM INTERESTED IN<span class="red-text">*</span></label><small>(Select up to three)</small></div>

                        <div >
                          <div class="category-check-itm">
                            <ul class="category-check">
                              {
                                categories.map((data, index) => (


                                  <li><input class="form-check-input" type="checkbox" name="category" value={data.catId} id="defaultCheck1" onClick={() => validateCheck()}/>  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                ))}

                            </ul>
                          </div>
                          {errors.category && errors.category.type === "required" && <div class="error">Please check atleast one category</div>}
                          <div id="categoryvalidmsg" class="error">Please check atleast one category</div>
                        </div>

                      </div>
                    {/*<div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">I AM SUBMITTING THIS FORM BECAUSE I AM LOOKING FOR:<span class="red-text">*</span></label></div>
                        <div class="category-check-itm">
                          <ul>
                            {
                              jobTypes.map((data, index) => (


                                <li><input class="form-check-input" type="checkbox" name="seeking" value={data.jobTypeId} id="seeking" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                              ))}

                          </ul>
                        </div>
                        {errors.seeking && errors.seeking.type === "required" && <div class="error">Please check atleast one </div>}
                        <div id="seekingValidMsg" class="error">Please check atleast one</div>
                        /* {errors.seeking && errors.seeking.type === "maxLength" && <div class="error">Should not allow more than 500 character</div>} *
                      </div> */}

                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">I AM INTERESTED IN THESE FIELDS FOR THESE REASONS </label></div>
                        <small>(Leave blank if not known.)</small>

                        {/* <CKEditor
                          editor={ClassicEditor}
                          config={{
                            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                          }}
                          id="careers"
                          onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                            //editor.setData(profileDescription);
                            //editor.setData(profileDescription);

                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setStudentCareers(data);
                            console.log({ event, editor, data });
                          }}
                          onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                          }}
                        /> */}

                        <SunEditor
                          setOptions={{
                            height: "120",
                            textAlign:"left",
                            //buttonList: [['font', 'align'], ['image']]

                            buttonList: [
                              /**['undo', 'redo'],
                              ['font', 'fontSize', 'formatBlock'],
                              ['paragraphStyle', 'blockquote'],
                              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                              ['fontColor', 'hiliteColor', 'textStyle'],
                              ['removeFormat'],
                              '/', // Line break
                              ['outdent', 'indent'],
                              ['align', 'horizontalRule', 'list', 'lineHeight'],
                              ['table', 'link', 'image' /** ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                              ['fullScreen', 'showBlocks', 'codeView'],
                              ['preview'], */

                              /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                            ]
                            //buttonList: buttonList.complex
                            // plugins: [font] set plugins, all plugins are set by default
                            // Other option
                          }}
                          onChange={handleChangeStudentCareersDesc}
                          setContents={studentCareers}
                          //onImageUpload={handleImageUpload}
                          onImageUploadBefore={handleImageUploadBefore}
                        />
                        <div id="studentCareersValidMsg" class="error">This field is required.</div>
                      </div>


                      <div class="col-lg-12 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-0">I HAVE THE FOLLOWING SKILLS /EXPERIENCE THAT IS RELEVANT.</label></div>
                        <small>(Leave blank if not known.)</small>

                        <SunEditor
                          setOptions={{
                            height: "120",
                            textAlign:"left",
                            //buttonList: [['font', 'align'], ['image']]

                            buttonList: [
                              /**['undo', 'redo'],
                              ['font', 'fontSize', 'formatBlock'],
                              ['paragraphStyle', 'blockquote'],
                              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                              ['fontColor', 'hiliteColor', 'textStyle'],
                              ['removeFormat'],
                              '/', // Line break
                              ['outdent', 'indent'],
                              ['align', 'horizontalRule', 'list', 'lineHeight'],
                              ['table', 'link', 'image' /** ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                              ['fullScreen', 'showBlocks', 'codeView'],
                              ['preview'], */
                              /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                            ]
                            //buttonList: buttonList.complex
                            // plugins: [font] set plugins, all plugins are set by default
                            // Other option
                          }}
                          onChange={handleChangeStudentSkillsDesc}
                          setContents={studentSkills}
                          
                          //onImageUpload={handleImageUpload}
                          onImageUploadBefore={handleImageUploadBefore}
                        />

                        <div id="studentSkillsValidMsg" class="error">This field is required.</div>
                      </div>


                      <div class="col-lg-12 mb-4">

                        <div class="d-flex justify-content-end align-items-end">
                          <div>
                            {/* <a href="#" class="reg-button">Register</a> */}
                            <button type="button" onClick={go_back} class="reg-button" >Back</button>
                            <button type="submit" class="reg-button mx-2" >Next</button>
                          </div>
                        </div>

                      </div>

                      <div id="successMessage" class="alert">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <img src="/images/success-alert.png" alt="" />
                            <strong class="mx-2">Success!</strong> Student career choices submitted successfully.
                          </div>
                          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                        </div>
                      </div>

                      <div id='alertFailMessage' class="error"></div>



                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
          </div>
        </section>
      </main>

      <main class="empr-directory-main cd-mobile">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item headtitle "><a href="#">Student Registration</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section class="company-detail-wrap " id="step3">
          <div>
            <div class="col-12 ">
              <form onSubmit={handleSubmit3(submitValueStep3)}>
                <div class="white-bg px-4 pt-4">
                <div class="row mb-0">
                      <div class="col-lg-12 m-auto">
                          <div className='border-bottom pb-4 mb-3 d-flex align-items-center justify-content-between'>
                              <h5 className='mb-0'>FIELDS OF INTEREST</h5>
                              <div class="student-reg-step">STEP 3/4</div>
                            </div>
                      </div>
                    </div>
                  <div class="row stu-reg-st3 pt-3">
                  <div class="col-lg-12 mb-5">
                        <div><label for="firstName" class="form-label mr-2" >FIELDS I AM INTERESTED IN<span class="red-text">*</span></label><small>(Select up to three)</small></div>
                        
                        <div class="category-check-itm">
                        <ul>
                              {
                                categories.map((data, index) => (
                                  <li><input class="form-check-input category_mcls" type="checkbox" name="category_m" onClick={() => validateCheck_m()} value={data.catId} />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                                ))}
                            </ul>
                        </div>
                        {errors4.category_m && errors4.category_m.type === "required" && <div class="error">Category is required</div>}
                        <div id="categoryvalidmsg_m" class="error">Category is required.</div>
                      </div>
                    {/*<div class="col-lg-12 mb-5">
                      <div><label for="firstName" class="form-label mr-2" >I AM SUBMITTING THIS FORM BECAUSE I AM LOOKING FOR:<span class="red-text">*</span></label> </div>
                      <div class="category-check-itm">
                        <ul>
                          {
                            jobTypes.map((data, index) => (
                              <li><input name="seeking_m" class="form-check-input seeking_mcls" type="checkbox" value={data.jobTypeId} id="defaultCheck1"/> <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>
                            ))}
                        </ul>
                      </div>
                      {errors4.seeking_m && errors4.seeking_m.type === "required" && <div class="error">Employment is required</div>}
                      <div id="seekingValidMsg_m" class="error">Please check atleast one</div>
                    </div> */}

                    <div class="col-lg-12 mb-5">
                      <div><label for="firstName" class="form-label mr-2" >I AM INTERESTED IN THESE FIELDS FOR THESE REASONS </label> </div>
                      <small>(Leave blank if not known.)</small>

                      <SunEditor
                        setOptions={{
                          height: "120",
                          textAlign:"left",
                          //buttonList: [['font', 'align'], ['image']]

                          buttonList: [
                            /**['undo', 'redo'],
                            ['font', 'fontSize', 'formatBlock'],
                            ['paragraphStyle', 'blockquote'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/', // Line break
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link', 'image' /** ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                            ['fullScreen', 'showBlocks', 'codeView'],
                            ['preview'], */

                            /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                          ]
                          //buttonList: buttonList.complex
                          // plugins: [font] set plugins, all plugins are set by default
                          // Other option
                        }}
                        onChange={handleChangeStudentCareersMobDesc}
                        setContents={studentCareersMobile} 
                        //onImageUpload={handleImageUpload}
                        onImageUploadBefore={handleImageUploadBefore}
                      />

                      <div id="studentCareersValidMsgMobile" class="error">This field is required.</div>

                      {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="careers_m" spellcheck="false" {...register4('career', { required: true })}></textarea>
                                {errors4.career && errors4.career.type === "required" && <div class="error">Careers is required</div>} */}
                    </div>
                    <div class="col-lg-12 mb-5">
                      <div><label for="firstName" class="form-label mr-2 mb-0" >I HAVE THE FOLLOWING RELEVANT SKILLS /EXPERIENCE.</label> </div>
                      {/* <span class="red-text">Required for Juniors and Seniors</span> */}
                      <small>(Leave blank if not known.)</small>

                      {/* <CKEditor
                        editor={ClassicEditor}
                        config={{
                          removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                        }}
                        id="skills"
                        data={studentSkillsMobile}
                        onReady={editor => {
                          console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setStudentSkillsMobile(data);
                          console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log('Focus.', editor);
                        }}
                      /> */}

                      <SunEditor
                        setOptions={{
                          height: "120",
                          textAlign:"left",
                          //buttonList: [['font', 'align'], ['image']]

                          buttonList: [
                            /**['undo', 'redo'],
                            ['font', 'fontSize', 'formatBlock'],
                            ['paragraphStyle', 'blockquote'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/', // Line break
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link', 'image' /** ,'math' ], // You must add the 'katex' library at options to use the 'math' plugin.                    
                            ['fullScreen', 'showBlocks', 'codeView'],
                            ['preview'], */

                            /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                          ]
                          //buttonList: buttonList.complex
                          // plugins: [font] set plugins, all plugins are set by default
                          // Other option
                        }}
                        onChange={handleChangeStudentSkillsMobDesc}
                        setContents={studentSkillsMobile} 
                        //onImageUpload={handleImageUpload}
                        onImageUploadBefore={handleImageUploadBefore}
                      />
                      <div id="studentSkillsValidMsgMobile" class="error">This field is required.</div>

                      {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="skills_m" spellcheck="false" {...register4('skills', { required: true })}></textarea>
                                {errors4.skills && errors4.skills.type === "required" && <div class="error">Skills is required</div>} */}
                    </div>

                    <div class="mt-4 mb-4 grid-footer d-flex align-items-center">
                    <button type="button" onClick={go_back} class="reg-button" >Back</button>
                      <button type="submit" class="reg-button">Next</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>


        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
          </div>
        </section>


      </main>

    
    </>
  )
};

StudentRegistrationFieldsofInterest.propTypes = {};

StudentRegistrationFieldsofInterest.defaultProps = {};

export default StudentRegistrationFieldsofInterest;
