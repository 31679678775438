import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate,useParams,useLocation,useHistory } from "react-router-dom";
import Services from "../services/services";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import moment from "moment";

const NotificationDetails = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const notificationId = queryParams.get('notificationId');
    const [eventDetails, setEventDetails] = useState([]);

    const companyIdChkLogin=sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin=sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");


    const getNotificationDetailsById = () => {
        Services.getNotificationDetailsById(notificationId).then((res) => {
          console.log("Industry data=" + JSON.stringify(res.data));
          if(res){
            setEventDetails(res.data);
          }


        });

      }

    useEffect(() => {
        const jsonData = {
            "notificationViewDetails": {
              
            }
          };
          var jsondataformatted = JSON.stringify(jsonData);
          sessionStorage.setItem('pagejsonObject', jsondataformatted);

        getNotificationDetailsById();
    }, []);
  return(
  <>
   <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                    <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">


                                {(() => {



                                if( jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null"){
                                    return (<><li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li>
                                    <li class="breadcrumb-item headtitle "><a href="#">Announcement Details</a></li></>);
                                }

                                if( studentIdChkLogin != null && studentIdChkLogin != "null"){
                                    return (<> <li class="breadcrumb-item headtitle "><a href="/studentDashboard">Student Dashboard</a> </li>
                                    <li class="breadcrumb-item headtitle "><a href="#">Announcement Details</a></li></>);
                                }

                                })()}

                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-4">
                            <div class="grid-item p-3 border-0">
                                <div class="d-flex align-items-center">

                                    <div class="grid-title-wrap">
                                        <h2 class="margin-bottom-0">{eventDetails.notificationTitle} </h2>

                                    </div>
                                </div>
                                <hr/>



                                <div class="grid-body mb-4">
                                    <div class="grid-body-item">

                                        {/* <div class="grid-body-inn">
                                            <span>When:</span>
                                            <span>{moment(eventDetails.eventDate).format("D-MMM-YY")}</span>
                                        </div>
                                        <div class="grid-body-inn">
                                            <span>Where:</span>
                                            <span>
                                                    {eventDetails.eventAddress}, {eventDetails.eventCity}, {eventDetails.eventZip}
                                            </span>
                                        </div> */}


                                    </div>
                                </div>
                                {/* <div class="quickinfo-social justify-content-start ">
                                    <a href="#"><img src="images/FACEBOOK 2.png" alt=""/></a>
                                    <a href="#"><img src="images/INSTA 2.png" alt=""/></a>
                                    <a href="#"><img src="images/TWITTER 2.png" alt=""/></a>
                                    <a href="#"><img src="images/LINKEDIN 2.png" alt=""/></a>
                                    <a href="#"><img src="images/YOUTUBE 2.png" alt=""/></a>
                                </div>
                                <hr/> */}
                                <div class="view-item ppv mb-4">
                                    <h5>Information</h5>
                                    <p>


                                    <p dangerouslySetInnerHTML={{ __html: eventDetails.notificationDescription  }} />


                                    </p>
                                    {/* <div class="my-4">
                                        <img src="images/Bitmap.png" class="w-100" alt=""/>
                                    </div> */}

                                </div>




                            </div>
                        </div>

                    </div>
                </div>
                </div>
        </section>



    </main>
  </>
)};

NotificationDetails.propTypes = {};

NotificationDetails.defaultProps = {};

export default NotificationDetails;
