import React,{history,useEffect, useState, useMemo } from "react";
import services from "../../services/services";
import $, { parseJSON } from 'jquery';

const HelpLink = () => {
    const [ourlibrariesContent, setOurlibrariesContent] = useState([]);
    const getPageInfo = (reqopt) => {        
        services.getCMSPageContent('23').then((res) => {            
            console.log(JSON.stringify(res));
            if(res.data){
                setOurlibrariesContent(res.data.content.rendered);
            }       
        });       
     }
    useEffect(() => {        
        getPageInfo("downloadourapp");

        const jsonData = {
            "helpLinks": {
              
            }
          };

        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);
  
    }, []);
  return(
  <>
<main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                    <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item headtitle "><a href="#">Helpful Links</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
 
        <section class="company-detail-wrap py-5 ">
            <div class="container">
            <div class="row">
            <div class="col-lg-8">
                        <div class="white-bg px-5 pt-5 pb-4">
                            <div class="row mb-3">
                                <div class="col-lg-12 mb-4">
                
                <div dangerouslySetInnerHTML={{ __html: ourlibrariesContent }} />
                </div> </div> </div> </div> 
                </div> 
            
            </div>
            {/* <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="white-bg px-5 pt-5 pb-4">
                            <div class="row mb-3">
                                <div class="col-lg-12 mb-4">
                                    <h4 class="red-text">Logan County Chamber of Commerce</h4>
                                    <address>
                                        220 N. Main St., Bellefontaine <br/>
                                        (937) 599-4189<br/>
                                        Website: www.logancountylibraries.org
                                     </address>
                                </div>

                                <div class="col-lg-12">
                                    <h4>Branches</h4>
                                    <hr/>
                                    <div class="row">
                                        <div class="col-lg-4 col-md-12">
                                            <b>DeGraff</b>
                                            <p>
                                                122 S. Main St. <br/> (937) 585-5010
                                            </p>
                                        </div>
                                        <div class="col-lg-4 col-md-12">
                                            <b>Lakeview</b>
                                            <p>
                                                130 N. Main St.<br/> (937) 842-4144
                                            </p>
                                        </div>
                                        <div class="col-lg-4 col-md-12">
                                            <b>Rushsylvania</b>
                                            <p>
                                                113 N. Sandusky St. <br/> (937) 468-9963
                                            </p>
                                        </div>
                                        <div class="col-lg-4 col-md-12">
                                            <b>West Liberty</b>
                                            <p>
                                                117 N. Detroit St. <br/>(937) 465-3656
                                            </p>
                                        </div>
                                        <div class="col-lg-4 col-md-12">
                                            <b>West Mansfield</b>
                                            <p>
                                                127 N. Main St. <br/>(937) 355-0033
                                            </p>
                                        </div>
                                        <div class="col-lg-4 col-md-12">
                                            <b>Sloan Library</b>
                                            <p>
                                                2817 Sandusky St., <br/> Zanesfield <br/> (937) 592-8343
                                            </p>
                                        </div>
                                        <div class="col-lg-4 col-md-12">
                                            <b>Belle Center <br/>
                                                Free Public Library</b>
                                            <p>
                                                103 S. Elizabeth St., <br/> Belle Center <br/> (937) 464-3611
                                            </p>
                                        </div>

                                    </div>

                                </div>

                               

                            </div>

                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="p-4 white-bg mb-4">
                            <div class="bg-heading">
                                <h3 class="mb-0">Recent Careers Posted</h3>
                            </div>
                            <ul class="mt-4 recent-job">
                                <li>
                                    <a href="#">
                                        <span>Quality Checker - $14.50/Hr</span>
                                        <span>By: Spherion Staffing</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Quality Operations Clerk - $17.95/Hr</span>
                                        <span>By: Spherion Staffing</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Mechanical Design Engineer</span>
                                        <span>By: PowerBuilt Material Handling Solutions</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Production</span>
                                        <span>Pete DeLuke &amp; Associates, Inc.</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Quality Operations Clerk - $17.95/Hr</span>
                                        <span>By: Spherion Staffing</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Mechanical Design Engineer</span>
                                        <span>By: PowerBuilt Material Handling Solutions</span>
                                    </a>
                                </li>

                            </ul>
                        </div>



                    </div>
                </div>
            </div> */}
        </section>

    </main>
  </>
)};

HelpLink.propTypes = {};

HelpLink.defaultProps = {};

export default HelpLink;
