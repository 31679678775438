import React, { history,useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import Services from "../../services/services";
//import PaginationComponent from "../../utility/Pagination/Pagination";
import Pagination from "../../utility/Pagination/Pagination";
import { useNavigate,useParams } from "react-router-dom";
import ReactExport from "react-data-export";

const StudentReportBySchool = () => {

    const navigate = useNavigate();
    const [educations, setEducation] = useState([]);
    const [schoolIds, setSchoolInData] = useState([]);
    const [currentYearList, setCurrentYearList] = useState([]);
    const [jobTypes, setJobType] = useState([]);

    const [categories, setCategory] = useState([]);
    const [profileList, setProfileList] = useState([]);

    const ITEMS_PER_PAGE = 10; // Per page show the data pagination
    const Pagesize=5;
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [adminschoolinfo, setadminSchool] = useState();
    const [schoolId, setSchoolId] = useState('');
    const [schoolIdVal, setSchoolIdVal] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const companyId=sessionStorage.getItem("logCompanyId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");
    const chKRoleAr=sessionStorage.getItem("roleAr");
    var globalSchoolId = '';


    var FileSaver = require('file-saver');

    const getEducation = () => {
        Services.getEducation().then((res) => {
            var tempeducation = res.data.content;
            tempeducation = tempeducation.filter(function(elem) {
                return elem.name !== 'PhD';
            });
            setEducation(tempeducation);
        });
      }


   const getSchoolnData = () => {
      const requestObject={
          "siteId":process.env.React_App_SITE_ID,
          "pageNumber":1,
          "pageSize":150
       }

       var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json',
                   //'Authorization': "Bearer "+accessTokenIn
                   //'Authorization': accessTokenIn
                 },
         body: JSON.stringify(requestObject)
       };

       Services.getSchoolList(requestOptions).then((res) =>
       {
          if(res){
                 var contAr = res.content;
                 console.log(globalSchoolId);
                 if(res){
                    if(chKRoleAr == "schooladmin"){
                        var tempschool = res;
                        tempschool = tempschool.filter(function(elem) {
                            return elem.schoolId == globalSchoolId;
                        });

                    }else{
                        var tempschool = res;
                    }
                    console.log(tempschool);
                    setSchoolInData(tempschool);
                 }
            }
       });
    }

    $('#educationId').change(function(){
        if(chKRoleAr == "schoolsuper" ){
            if($('#educationId').val() == 3){
               $('#schoolList_div').show();
            }
            else{
              $('#schoolList_div').hide();
            }
        }

    })

    const getCurrentYear = () =>{
        var currentYear = new Date().getFullYear()
        var years = [];
        years.push(currentYear);
        for(var i=1;i<7;i++){
          years.push(currentYear+i);
        }

        //const now = new Date().getUTCFullYear();
        //const years = Array(now - (now + 5)).fill('').map((v, idx) => now - idx);
        console.log("#41#41-->"+JSON.stringify(years));
        setCurrentYearList(years);
      }

      const getJobType = () => {
        Services.getJobType().then((res) => {
            console.log("setJobType data=" + JSON.stringify(res.data));
            var jobtype_unsort = res.data.content;
          var jobtypes_sorted = jobtype_unsort.sort((a, b) => b.jobTypeId - a.jobTypeId);
          array_move(jobtypes_sorted, 0, (jobtypes_sorted.length-1));
          jobtypes_sorted = jobtypes_sorted.filter(function (elem) {
            return elem.name !== 'Continuing Education';
          });
          setJobType(jobtypes_sorted);
        });
    }

    function array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
  };
  
    function download_excel(profileListFull){
     //   console.log(profileListFull);

        var today = new Date();
        var month = today.toLocaleString('en-US', {month: 'short'});
        var date= today.getDate();
        var year = today.getFullYear();

        var filename = "student-export-"+month+"-"+date+"-"+year+".csv";
       // console.log(filename);
        let csv = '';
        let row = '';
        let headers = '';
        var jobtypeslisthead = 'Updated On'+','+'Name'+','+'Email'+','+'Phone'+','+'School District'+','+'Class Year'+',';
        var jobtypes_sorted= jobTypes.sort((a, b)=> b.jobTypeId - a.jobTypeId)
        for(var jb=0; jb < jobtypes_sorted.length; jb++){
            jobtypeslisthead = jobtypeslisthead+jobtypes_sorted[jb].name+',';
        }
        jobtypeslisthead = jobtypeslisthead+'Interested Categories';
        console.log(jobtypeslisthead);
        headers = headers.concat(jobtypeslisthead);
        csv = csv.concat(headers);
        row = '';
        for(var i=0; i<profileListFull.length; i++){
            var cats = profileListFull[i].categoryName;
            var jobtypenames = profileListFull[i].jobTypeName;
            var dateUpdated = new Date(profileListFull[i].dateUpdated);
            var displayupdated =  dateUpdated.getMonth()+'/'+dateUpdated.getDate()+'/'+dateUpdated.getFullYear();
            var jobtypeslistrow = "";

            var selectedcategs = cats.join('; ');

            if(profileListFull[i].email == null || profileListFull[i].email == ''){
              var email = '--';
            }
            else {
              var email = profileListFull[i].email;
            }

            if(profileListFull[i].phoneno == null || profileListFull[i].phoneno == ''){
              var phoneno = '--';
            }
            else {
              var phoneno = profileListFull[i].phoneno;
            }

            if(profileListFull[i].name == null || profileListFull[i].name == ''){
              var schoolname = '--';
            }
            else {
              var schoolname = profileListFull[i].name;
            }

            if(profileListFull[i].classYear == null || profileListFull[i].classYear == ''){
              var classYear = '--';
            }
            else {
              var classYear = profileListFull[i].classYear;
            }

            jobtypeslistrow = "\n"+displayupdated+','+profileListFull[i].firstName+" "+profileListFull[i].lastName+','+email+','+phoneno+','+schoolname+','+classYear+',';

            for(var jb=0; jb < jobtypes_sorted.length; jb++){
                if(jobtypenames.includes(jobtypes_sorted[jb].name)){
                    jobtypeslistrow = jobtypeslistrow+'Y ,';
                }
                else {
                    jobtypeslistrow = jobtypeslistrow+' ,';
                }

            }
            jobtypeslistrow = jobtypeslistrow+selectedcategs.replace(/,/g, "-")

            row = row.concat(jobtypeslistrow);

        }
        csv = csv.concat(row);

        var file = new File([csv], filename, {type: "application/csv"});
        FileSaver.saveAs(file);
        $(".load-wrap").hide();
    }





    function strCatArr(arr){
        if(Array.isArray(arr)){
          return arr.join(", ");
        }else{
          return "";
        }
      }



    useEffect(() => {
      const jsonData = {
        "studentReportBySchool": {
          
        }
      };
      var jsondataformatted = JSON.stringify(jsonData);
      sessionStorage.setItem('pagejsonObject', jsondataformatted);

        $(".load-wrap").hide();
       // $('#export_studentbtn').hide();
        if(chKRoleAr == "schooladmin" ){
            getSchoolIdbyUserId(userIdChkLogin);
        }else{

        }


        $('#phone').usPhoneFormat();
        getEducation();
        getSchoolnData();
        getCurrentYear();
        getJobType();
       // $('#schoolList_div').hide();

         $(".paginationContent").hide();

    }, []);

    const getSchoolIdbyUserId = (userIdChkLogin) =>{

        var schoolidvalue ='';
        Services.getSchoolAdminInfoByUserId(userIdChkLogin).then((res) => {
            console.log("Industry data=" + JSON.stringify(res.data));
            //alert(res.data.schoolId)
            setSchoolId(res.data.schoolId);
            setSchoolIdVal(res.data.schoolId);

            schoolidvalue = res.data.schoolId;

            globalSchoolId = res.data.schoolId;
        });


        //setCurrentYearList(years);
      }

    const filterContent_forexport = () => {
        var schoolidarray = [];
        $.each($("input[name='schoolid']:checked"), function(){
            schoolidarray.push($(this).val());
        });

        var yearinfoarray = [];
        $.each($("input[name='yearinfo']:checked"), function(){
            yearinfoarray.push($(this).val());
        });

        var seekingarray = [];
        $.each($("input[name='seeking']:checked"), function(){
            seekingarray.push($(this).val());
        });

        var schoolIdArray = [];
        if(chKRoleAr == "schooladmin" ){
            schoolIdArray = [schoolIdVal];
        }else{
            schoolIdArray = schoolidarray
        }


       var education = $('#educationId').val();

        const requestObject={
            "siteId": process.env.React_App_SITE_ID,
            "classYear": yearinfoarray,
            "schoolId": schoolIdArray,
            //"seeking" : seekingarray,
            "jobTypeId" : seekingarray,
            "pageNumber" :1,
            "pageSize":'5000',
           // "education":education,
           "educationId":education,
            "sortBy" : "updatedOn"
          }

          var accessTokenIn=sessionStorage.getItem("accToken");
          console.log(requestObject);
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                      'Authorization': "Bearer "+accessTokenIn
                      //'Authorization': accessTokenIn
                    },
            body: JSON.stringify(requestObject)
            };

          // return false;
            $(".load-wrap").show();
            Services.findWithExtraFilterStudent(requestOptions).then((res) =>
            {
                if(res.content){
                    console.log(JSON.stringify(res));

                    var contAr = res.content;
                    console.log("#292");
                    console.log(contAr);
                    var newcontAr = [];
                    for(var i=0; i< contAr.length; i++){
                        var jobtypesarray = contAr[i].jobTypeName;
                        if(jobtypesarray.includes("Job Shadow")){
                            newcontAr.push(contAr[i]);
                        }
                    }
                    console.log("#300");
                    console.log(newcontAr);
                    //setProfileListFull(res.content);
                    //download_excel(newcontAr);
                    download_excel(contAr);
               }
            });
    }


    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    const submitValue = () => {

    }

    return (
        <>
            <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                            <li class="breadcrumb-item headtitle"> <a href="/schoolAdminDashboard">School Admin</a> </li>
                                <li class="breadcrumb-item headtitle "><a href="#">Student Report</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5 ">
                            <div class="row">



                                {/* <!-- left side --> */}
                                <div class="col-lg-12 mb-2">
                                <form onSubmit={handleSubmit(submitValue)}>
                  <div class="white-bg">

                    <div class="row resume-wrap">
                      <div class="col-lg-12 mb-5">
                        <label for="educationId" class="form-label">Students Pursuing : <span>High School Diploma</span></label>
                        <input type="hidden" id="educationId" name="educationId" value="3" />
                       {/* <select class="form-select" aria-label="Default select example" id="educationId">
                                <option selected value="">Select</option>
                                {
                                educations.map((data, index) => (
                                  <option value={data.educationId}>{data.name}</option>
                                  ))}
                              </select>
                              */}
                      </div>

                      {chKRoleAr == "schooladmin" ?
                       <div class="col-lg-12 mb-5" id="schoolList_div">
                       <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">school district : {
                             schoolIds.map((data, index) => (


                                <span>{data.name} </span>

                                ))}</label></div>
                     </div> :
                      <div class="col-lg-12 mb-5" id="schoolList_div">
                      <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">school district</label></div>

                      <div >
                        <div class="category-check-itm">
                        <ul class="category-check">
                        {
                             schoolIds.map((data, index) => (


                                <li><input class="form-check-input" type="checkbox" name="schoolid" value={data.schoolId} id="schoolid" {...register('schoolid', { required: true })}/>  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                ))}
                        </ul>
                        </div>
                        {errors.schoolid && errors.schoolid.type === "required" && <div class="error">Please check atleast one School</div>}

                      </div>

                    </div>
                    }


                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">GRADUATING YEAR</label></div>

                        <div >
                          <div class="category-check-itm">
                          <ul>
                          {
                               currentYearList.map((data, index) => (


                                  <li><input class="form-check-input" type="checkbox" name="yearinfo" value={data} id="yearinfo" />  <label class="form-check-label" for="defaultCheck1"> {data} </label></li>

                                  ))}
                          </ul>
                          </div>

                        </div>

                      </div>

                      <div class="col-lg-6 mb-5">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Students SEEKING</label></div>
                        <div class="category-check-itm">
                          <ul>
                          {
                               jobTypes.map((data, index) => (


                                  <li><input class="form-check-input" type="checkbox" name="seeking" value={data.jobTypeId} id="seeking" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                  ))}
                          </ul>
                        </div>
                      </div>




                      <div class="col-lg-12 mb-0">

                        <div class="d-flex">


                          <a onClick={filterContent_forexport} id="export_studentbtn" class="reg-button text-center mx-1">Export</a> &nbsp; &nbsp;
                          <a href="/schoolAdminDashboard" class="reg-button text-center mx-1">Cancel</a>

                        </div>

                      </div>
                    </div>
                  </div>
                </form>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
             </div>
        </section>
    </main>
        </>
    )
};

StudentReportBySchool.propTypes = {};

StudentReportBySchool.defaultProps = {};

export default StudentReportBySchool;
