import React, { history, useEffect, useState, useMemo } from "react";
import Quick_links from "../utility/Quick_links";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import Hiring from "./Jobs/Hiring";
import services from "../services/services";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useNavigate, useParams } from "react-router-dom";
import SidebarStudent from "../utility/sidebar";
import SidebarProfile from "../utility/sidebarProfile";

const WageSurveyData = () => {
    const navigate = useNavigate();
    const [profileDetails, setProfileDetails] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [countyList, setCountyList] = useState([]);

    const {
        register,
        formState: { errors },
        handleSubmit
      } = useForm({
        criteriaMode: "all",mode: "onBlur" 
      });
      const validateDecimalThree = (elem) => {
         // alert(elem.value);
        var ex=  /^\d{0,3}(\.\d{0,2})?$/;
        if(ex.test(elem.value)==false){
        elem.value = elem.value.substring(0,elem.value.length - 1);
        }
      }
    
    //const userId=138;
    const userId = sessionStorage.getItem("logUserId");
    const companyId = sessionStorage.getItem("logCompanyId");

    const companyIdChkLogin = sessionStorage.getItem("logCompanyId");
    const emailLogin = sessionStorage.getItem("logEmail");
    const userIdLogin = sessionStorage.getItem("logUserId");
    const studentIdChkLogin = sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin = sessionStorage.getItem("logProfileId");
    const userIdChkLogin = sessionStorage.getItem("logUserId");   
   
    



    const [imageName, setImageName] = useState('');
    const [imageExtension, setImageExtension] = useState('');
    const [banner, setBanner] = useState('');
    const [emaildefault, setemaildefault] = useState('');
    const [bannerBase64, setBannerBase64] = useState('');
    const [profilePicS3URL, setProfilePicS3URL] = useState('');
    const [collab, setCollabs] = useState('');
    const [socCode, setSocCode] = useState('');
    const [socCodeTitle, setSocCodeTitle] = useState('');
    const [jobCodeTitle, setJobCode] = useState('');
    const [wageList, setWageListObj] = useState('');

    const [dolWageLevel1, setDolWageLevel1] = useState('');
    const [dolWageLevel2, setDolWageLevel2] = useState('');
    const [dolWageLevel3, setDolWageLevel3] = useState('');
    const [dolWageLevel4, setDolWageLevel4] = useState('');

    const [dolWageHourlyLevel1, setDolWageHourlyLevel1] = useState('');
    const [dolWageHourlyLevel2, setDolWageHourlyLevel2] = useState('');
    const [dolWageHourlyLevel3, setDolWageHourlyLevel3] = useState('');
    const [dolWageHourlyLevel4, setDolWageHourlyLevel4] = useState('');

    const [surveyWageLevel1, setsurveyWageLevel1] = useState('');
    const [surveyWageLevel2, setsurveyWageLevel2] = useState('');
    const [surveyWageLevel3, setsurveyWageLevel3] = useState('');
    const [surveyWageLevel4, setsurveyWageLevel4] = useState('');

    const [surveyWageHourlyLevel1, setsurveyWageHourlyLevel1] = useState('');
    const [surveyWageHourlyLevel2, setsurveyWageHourlyLevel2] = useState('');
    const [surveyWageHourlyLevel3, setsurveyWageHourlyLevel3] = useState('');
    const [surveyWageHourlyLevel4, setsurveyWageHourlyLevel4] = useState('');


   
    //set default query terms
    const [query, setQuery] = useState("");
    let jobTitleGlobal = '';

    //get animated components wrapper
    const animatedComponents = makeAnimated();

    //var emaildefault = '';

   




    // fetch filteres search results for dropdown
    /*const loadOptions = () => {
        return fetch(`https://wagesurvey.smallnation.site/api/jobSugList?title=${query}`)
            .then((res) => res.json());
    }; */

    
  const loadOptions = () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Set the content type based on your API requirements
      },
      body: JSON.stringify({ title: query }), // Convert data to JSON format
    };
   
    return fetch('https://wagesurvey.hometownopportunity.com/api/jobSugList', requestOptions)
      .then((res) => res.json());
  };


    function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            console.log(reader.result);

            var base64result = reader.result.split(',')[1];
            //setBannerBase64(reader.result)
            setBannerBase64(base64result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    function getImage() {
        var files = document.getElementById('banner').files;

        if (files.length > 0) {
            var fname = files[0].name;
            var fextn = fname.split(".").pop();
            var fileActName = fname.split(".")[0];
            setImageName(fileActName);
            setImageExtension(fextn);
            getBase64(files[0]);
        }
    }
    

    function validateDecimalThreeFunc() {
        var elemv = "test";
       
        var elem = $("#"+elemv);
        var ex=  /^\d{0,3}(\.\d{0,2})?$/;
      if(ex.test(elem.value)==false){
      elem.value = elem.value.substring(0,elem.value.length - 1);
      }
      }
    
    useEffect(() => {
        const jsonData = {
            "wageSurveyData": {
              
            }
          };
          var jsondataformatted = JSON.stringify(jsonData);
          sessionStorage.setItem('pagejsonObject', jsondataformatted);

        $('#statecheck').hide();
        $('#countycheck').hide();
        $('#citycheck').hide();
        $(".allow_numeric3").keyup(function(){
            
            var ex=  /^\d{0,3}(\.\d{0,2})?$/;
            if(ex.test(this.value)==false){
            this.value = this.value.substring(0,this.value.length - 1);
            }

          });


          $(".allow_numericd").keyup(function(){            
            //var ex = /^[0-9]+\.?[0-3]*$/;
            var ex=  /^\d{0,7}(\.\d{0,2})?$/;
            if(ex.test(this.value)==false){
                this.value = this.value.substring(0,this.value.length - 1);
            }
          });


        $(".allow_numeric").on("input", function(evt) {
            /*
             var self = $(this);
             self.val(self.val().replace(/\D/g, ""));
             if ((evt.which < 48 || evt.which > 57)) 
              {
                evt.preventDefault();
              }
              */
              var elem = $(this);
              var ex=  /^\d{0,3}(\.\d{0,2})?$/;
              if(ex.test(elem.value)==false){
              elem.value = elem.value.substring(0,elem.value.length - 1);
              }
          });

        $(".allow_numeric").on("input", function(evt) {
           /*
            var self = $(this);
            self.val(self.val().replace(/\D/g, ""));
            if ((evt.which < 48 || evt.which > 57)) 
             {
               evt.preventDefault();
             }
             */
             var elem = $(this);
             var ex=  /^\d{0,3}(\.\d{0,2})?$/;
             if(ex.test(elem.value)==false){
             elem.value = elem.value.substring(0,elem.value.length - 1);
             }
         });

        $("#junior_hcheck").hide();
        $("#midlevel_hcheck").hide();
        $("#manager_hcheck").hide();
        $("#excutive_hcheck").hide();
        $("#soc_code_check").hide();
        $("#junior_ycheck").hide();
        $("#midlevel_ycheck").hide();
        $("#manager_ycheck").hide();
        $("#excutive_ycheck").hide();


        $("#matchPwd").hide();
       // alert("131")
        $('#phone').usPhoneFormat();
        $("#passwordTxtConf").blur(function () {
            var passwordConf = $("#passwordTxtConf").val();
            var passwordCheck = $("#passwordTxt").val();
            if (passwordConf != passwordCheck) {
                $("#matchPwd").show();
            } else {
                $("#matchPwd").hide();
            }
        });

        if (companyIdChkLogin == null || companyIdChkLogin == "null") {
            //navigate('/login');
            navigate('/Home');
        }
        //getUserProfile();
        getStatesWage();

        setTimeout(() => {
            $( "#stateId" ).val('OH');
            getCountyByState('OH');
        }, 1000)

        $("#stateId").change(function () {
            var st = $("#stateId").val()
            getCountyByState(st)
        });

        $("#getWageInfo").click(function () {
            getWageInfoCall()
        });



        $("#successMessage").hide();
        $("#imgError").hide();

    }, []);



    const getWageInfoCall = () => {
        var state = $("#stateId").val();
        var county = $("#countyId").val();
        var job_code = $("#jobCodeTitle").html();
        var soc_code = $("#socCodeVal").html();


        //alert(socCodeTitle);      
        // alert(job_code);
        const requestObject = {
            "stateCode": state,
            "county": county,
            //"job_code": jobCode, 
            "job_code": job_code,
            "soccode": soc_code,

        }
        // console.log(JSON.stringify(objInputAvailableJobs));
        var accessTokenIn = sessionStorage.getItem("accToken");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn 
                //'Authorization': accessTokenIn 
            },
            body: JSON.stringify(requestObject)
        };

        services.getSurveyWageList(requestOptions).then((res) => {
            console.log(JSON.stringify(res))
            if (res) {
                setWageListObj(res);
                setDolWageLevel1('');
                setDolWageLevel2('');
                setDolWageLevel3('');
                setDolWageLevel4('');

                setDolWageHourlyLevel1('');
                setDolWageHourlyLevel2('');
                setDolWageHourlyLevel3('');
                setDolWageHourlyLevel4('');

                setsurveyWageLevel1('');
                setsurveyWageLevel2('');
                setsurveyWageLevel3('');
                setsurveyWageLevel4('');

                setsurveyWageHourlyLevel1('');
                setsurveyWageHourlyLevel2('');
                setsurveyWageHourlyLevel3('');
                setsurveyWageHourlyLevel4('');
                try {
                    if (typeof (res.dolWage[0].level1) != "undefined" && res.dolWage[0].level1 != null) {
                        // alert(res.dolWage[0].level1);
                        setDolWageLevel1(res.dolWage[0].level1);
                        setDolWageLevel2(res.dolWage[0].level2);
                        setDolWageLevel3(res.dolWage[0].level3);
                        setDolWageLevel4(res.dolWage[0].level4);
                    }
                } catch (ex) {

                }


                try {
                    if (typeof (res.dolWageHourly[0].level1) != "undefined" && res.dolWageHourly[0].level1 != null) {

                        setDolWageHourlyLevel1(res.dolWageHourly[0].level1);
                        setDolWageHourlyLevel2(res.dolWageHourly[0].level2);
                        setDolWageHourlyLevel3(res.dolWageHourly[0].level3);
                        setDolWageHourlyLevel4(res.dolWageHourly[0].level4);
                    }
                } catch (ex) {

                }



                try {
                    if (typeof (res.surveyWage[0].level1) != "undefined" && res.surveyWage[0].level1 != null) {
                        setsurveyWageLevel1(res.surveyWage[0].level1);
                        setsurveyWageLevel2(res.surveyWage[0].level2);
                        setsurveyWageLevel3(res.surveyWage[0].level3);
                        setsurveyWageLevel4(res.surveyWage[0].level4);
                    }
                } catch (ex) {

                }



                try {
                    if (typeof (res.surveyWageHourly[0].level1) != "undefined" && res.surveyWageHourly[0].level1 != null) {
                        setsurveyWageHourlyLevel1(res.surveyWageHourly[0].level1);
                        setsurveyWageHourlyLevel2(res.surveyWageHourly[0].level2);
                        setsurveyWageHourlyLevel3(res.surveyWageHourly[0].level3);
                        setsurveyWageHourlyLevel4(res.surveyWageHourly[0].level4);
                    }
                } catch (ex) {

                }


            }

        });



    }

    const getCountyByState = (stateId) => {

        const requestObject = {
            "stateCode": stateId

        }
        // console.log(JSON.stringify(objInputAvailableJobs));
        var accessTokenIn = sessionStorage.getItem("accToken");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn 
                //'Authorization': accessTokenIn 
            },
            body: JSON.stringify(requestObject)
        };

        services.getCountyByStateFromWage(requestOptions).then((res) => {

            if (res) {

                var tempcounties = res;
                if(stateId == 'OH'){
                   // console.log(tempcounties);
                    tempcounties = tempcounties.filter(function(elem) {  
                    return (elem.countyName == 'Auglaize County' || elem.countyName == 'Darke County' || elem.countyName == 'Shelby County' || elem.countyName == 'Mercer County'); 
                    });
                    //console.log(tempcounties);
                }
                

                setCountyList(tempcounties);
            }

        });



    }

    const handleChange = (selectedOption) => {
        //alert(JSON.stringify(selectedOption));
        var title = selectedOption.title;
        jobTitleGlobal = selectedOption.title;
        setJobCode(title);
        setSocCodeTitle(title);
        setSocCode(selectedOption.socCode);

    }

    const getStatesWage = () => {
        services.getStatesFromWage().then((res) => {
            setStateList(res.data);
        });
    }

    const getUserProfile = () => {
        services.getUserInfo(userId).then((res) => {

            setProfileDetails(res.data);
            $("#firstNameTxt").val(res.data.firstName);
            $("#lastNameTxt").val(res.data.lastName);
            $("#emailTxt").val(res.data.email);
            $("#phone").val(res.data.phone);
            //emaildefault = res.data.email;
            setemaildefault(res.data.email)
            setProfilePicS3URL(res.data.profilePicS3URL)
            // alert(profileDetails.name);
        });
    }

    function validateJunior_hD() {
        var i_val = "";
           var dValue = $('#junior_h1'+i_val).val();
           if(dValue != ""){
             $('#junior_hcheck'+i_val).hide();
           return true;
           }else{
             $('#junior_hcheck'+i_val).css("color", "red").html("*Level 1 hourly field is required");
            $('#junior_hcheck'+i_val).show();
           return false;
           }
       }        

        function validateJunior_yD() {
         var i_val = "";
           var dValue = $('#junior_y1'+i_val).val();
           if(dValue != ""){
             $('#junior_ycheck'+i_val).hide();
           return true;
           }else{
             $('#junior_ycheck'+i_val).css("color", "red").html("*Level 1 yearly field is required");
            $('#junior_ycheck'+i_val).show();
           return false;
           }
       }      

        function validatemidlevel_hD() {
         var i_val = "";
           var dValue = $('#midlevel_h1'+i_val).val();
           if(dValue != ""){
             $('#midlevel_hcheck'+i_val).hide();
           return true;
           }else{
             $('#midlevel_hcheck'+i_val).css("color", "red").html("*Level 2 hourly field is required");
            $('#midlevel_hcheck'+i_val).show();
           return false;
           }
       }        

        function validatemidlevel_yD() {
         var i_val = "";
           var dValue = $('#midlevel_y1'+i_val).val();
           if(dValue != ""){
             $('#midlevel_ycheck'+i_val).hide();
           return true;
           }else{
             $('#midlevel_ycheck'+i_val).css("color", "red").html("*Level 2 yearly field is required");
            $('#midlevel_ycheck'+i_val).show();
           return false;
           }
       }    

        function validatemanager_hD() {
         var i_val = "";
           var dValue = $('#manager_h1'+i_val).val();
           if(dValue != ""){
             $('#manager_hcheck'+i_val).hide();
           return true;
           }else{
             $('#manager_hcheck'+i_val).css("color", "red").html("*Level 3 hourly field is required");
            $('#manager_hcheck'+i_val).show();
           return false;
           }
       }        

        function validatemanager_yD() {
         var i_val = "";
           var dValue = $('#manager_y1'+i_val).val();
           if(dValue != ""){
             $('#manager_ycheck'+i_val).hide();
           return true;
           }else{
             $('#manager_ycheck'+i_val).css("color", "red").html("*Level 3 yearly field is required");
            $('#manager_ycheck'+i_val).show();
           return false;
           }
       }    

        function validateexcutive_hD() {
         var i_val = "";
           var dValue = $('#excutive_h1'+i_val).val();
           if(dValue != ""){
             $('#excutive_hcheck'+i_val).hide();
           return true;
           }else{
             $('#excutive_hcheck'+i_val).css("color", "red").html("*Level 4 hourly field is required");
            $('#excutive_hcheck'+i_val).show();
           return false;
           }
       }        

        function validateexcutive_yD() {
         var i_val = "";
           var dValue = $('#excutive_y1'+i_val).val();
           if(dValue != ""){
           $('#excutive_ycheck'+i_val).hide();
           return true;
           }else{
             $('#excutive_ycheck'+i_val).css("color", "red").html("*Level 4 yearly field is required");
            $('#excutive_ycheck'+i_val).show();
           return false;
           }
       }              
    
   
    const submitValue = () => {
        //////////////////

        //var validateStateV = validateState();
        //var validateCountyV = validateCounty();        
        //var validateCityV = validateCity();
        //var validateTitleDV = validateTitleD();
        //var validateCodeDV = validateCodeD();
if($('#stateId').val() == ''){
    $('#statecheck').show();
    $(window).scrollTop($('#statecheck').position().top);
    return false;
}
else{
    $('#statecheck').hide();
}

if($('#countyId').val() == ''){
    $('#countycheck').show();
    $(window).scrollTop($('#countycheck').position().top);
    return false;
} else{
    $('#countycheck').hide();
}

if($('#cityId').val() == ''){
    $('#citycheck').show();
    $(window).scrollTop($('#citycheck').position().top);
    return false;
} else{
    $('#citycheck').hide();
}

        var validateStateV = true;
        var validateCountyV = true;       
        var validateCityV = true;
        var validateTitleDV = true;
        var validateCodeDV = true;
        if(socCode == ""){
            $("#soc_code_check").show();
            return false;
        }else{
            $("#soc_code_check").hide();
        }


        var junior_h1 = $("#junior_h1").val();
        var midlevel_h1 = $("#midlevel_h1").val();
        var manager_h1 = $("#manager_h1").val();
        var excutive_h1 = $("#excutive_h1").val();

        var junior_y1 = $("#junior_y1").val();
        var midlevel_y1 = $("#midlevel_y1").val();
        var manager_y1 = $("#manager_y1").val();
        var excutive_y1 = $("#excutive_y1").val();

        var hState = 0;
        var yState = 0;
        var i_val = "";
        if(junior_h1 != "" && midlevel_h1 != "" && manager_h1 !="" && excutive_h1 !=""){
            hState = 1;
         
            validateJunior_hD();
            validatemidlevel_hD();
            validatemanager_hD();
            validateexcutive_hD();
            
            $('#junior_ycheck'+i_val).hide();
            $('#midlevel_ycheck'+i_val).hide();
            $('#manager_ycheck'+i_val).hide();
            $('#excutive_ycheck'+i_val).hide();
        } 

        if(junior_y1 != "" && midlevel_y1 != "" && manager_y1 !="" && excutive_y1 !=""){
            yState = 1;
         
            validateJunior_yD();
            validatemidlevel_yD();
            validatemanager_yD();
            validateexcutive_yD();
            
            $('#junior_hcheck'+i_val).hide();
            $('#midlevel_hcheck'+i_val).hide();
            $('#manager_hcheck'+i_val).hide();
            $('#excutive_hcheck'+i_val).hide();
        } 

        if(hState == "0" && yState == "0"){
		
            var validateJunior_hDV = validateJunior_hD();
            var validatemidlevel_hDV = validatemidlevel_hD();
            var validatemanager_hDV = validatemanager_hD();
            var validateexcutive_hDV = validateexcutive_hD();               
               
            var validateJunior_yDV = validateJunior_yD();
            var validatemidlevel_yDV = validatemidlevel_yD();
            var validatemanager_yDV = validatemanager_yD();
            var validateexcutive_yDV = validateexcutive_yD();
               
           }else{
               var validateJunior_hDV = true;
               var validatemidlevel_hDV = true;
               var validatemanager_hDV = true;
               var validateexcutive_hDV = true;               
               var validateJunior_yDV = true;
               var validatemidlevel_yDV = true;
               var validatemanager_yDV = true;
               var validateexcutive_yDV = true;
           }
        
        if(  !validateJunior_hDV || !validateJunior_yDV  || (!validatemidlevel_hDV || !validatemidlevel_yDV) || !validatemanager_hDV || !validatemanager_yDV|| !validateexcutive_hDV || !validateexcutive_yDV) {
            //alert("Please fill all the required fields");            
            return false;
        }
        
        const requestObject =
        {
            "sourceDomain": "hometownopportunity.com",
           // "emailAddress": "xyz@gmail.com",
           "emailAddress": emailLogin,
            "uid": userIdLogin,
            "companyIdRef": companyIdChkLogin,
            "wageArr": [{
                //"state": "DC",
                "state": $("#stateId").val(),
                //"city": "hyderabad",
                "city": $("#cityId").val(),                
                "county": $("#countyId").val(),
                //"county": "District of Columbia",
                //"job_title": "abc",
                //"job_code": "Print Binding and Finishing Workers",
                "job_title": jobCodeTitle,
                "job_code": jobCodeTitle,
                
                //"socCode": "51-5113",
                "socCode": socCode,                
                "junior_h": junior_h1,
                "junior_y": junior_y1,
                "midlevel_h": midlevel_h1,
                "midlevel_y": midlevel_y1,
                "manager_h": manager_h1,
                "manager_y": manager_y1,
                "excutive_h":excutive_h1,
                "excutive_y": excutive_y1,
            }]
        }
      
     

        console.log(requestObject);
        console.log(JSON.stringify(requestObject));
       
       
        var accessTokenIn = sessionStorage.getItem("accToken");
        const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + accessTokenIn
            //'Authorization': accessTokenIn 
        },
        body: JSON.stringify(requestObject)
        };
        $(".load-wrap").show();
        services.postWageSurvey(requestOptions).then((res) => {
        

       console.log(res);
         $("#successMessage").show();
         //navigate('/companyJobs');
        setTimeout(function () { navigate('/companyJobs'); }, 2000);

        });
      // console.log(JSON.stringify(requestObject));
  



        //////////////
    }

    return (
        <>
            <main class="empr-directory-main">
                <div class="main-bgpatten"></div>
                {/* <!-- info typography --> */}
                <section class="headtitle-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                                    <ol class="breadcrumb">

                                        {(() => {

                                            if (companyIdChkLogin != null && companyIdChkLogin != "null") {
                                                return (<> <li class="breadcrumb-item headtitle "><a href="/companyJobs">Company Dashboard</a> </li>
                                                    <li class="breadcrumb-item headtitle "><a href="#">Wage Survey</a></li></>);
                                            }



                                        })()}



                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- info typography --> */}
                <section class="py-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="white-bg p-5">
                                    <div class="row">
                                        {/* <!-- left side --> */}

                                        {(() => {

                                            if (companyIdChkLogin != null && companyIdChkLogin != "null") {
                                                return (<><Quick_links /></>);
                                            }



                                        })()}


                                        {/* <!-- right side --> */}
                                        <div class="col-lg-8 mb-4">
                                            <form onSubmit={handleSubmit(submitValue)} id="editCompanyUserForm">

                                                <div class="row resume-wrap">
                                                    <div class="col-lg-12 mb-4">
                                                    <p>
                                                        Please help us serve you better by providing wage information for your jobs. This information remains private and will not be shared with any other company or person. We only use this to calculate aggregated statistics at county and state level.
<br></br>Please submit an entry for each Job type/Job code.</p>
                                                    </div>
                                                    <div class="col-lg-12 mb-4">
                                                        <label for="firstName" class="form-label">State<span class="red-text">*</span></label>


                                                        <select class="form-select" id="stateId" >
                                                            <option selected value=''>List of States</option>
                                                            {
                                                                stateList.map((data, index) => (
                                                                    <option value={data.stateCode}>{data.stateName}</option>
                                                                ))}
                                                        </select>
                                                        <div id="statecheck" class="error">State is required</div> 
                                                        {errors.state && errors.state.type === "required" && <span class="error">State is required</span>}

                                                    </div>

                                                    <div class="col-lg-12 mb-4">
                                                        <label for="firstName" class="form-label">County<span class="red-text">*</span></label>
                                                        <select class="form-select" id="countyId" >
                                                            <option selected value="">List of Counties</option>
                                                            {
                                                                countyList.map((data, index) => (
                                                                    <option value={data.countyName}>{data.countyName}</option>
                                                                ))}
                                                        </select>
                                                        <div id="countycheck" class="error">County is required</div> 
                                                        {errors.county && errors.county.type === "required" && <span class="error">County is required</span>}
                                                    </div>

                                                    <div class="col-lg-12 mb-4">
                                                        <label for="cityId" class="form-label">City<span class="red-text">*</span></label>
                                                        <input type="text" class="form-control" id="cityId" name="cityId" placeholder="" />
                                                        <div id="citycheck" class="error">City is required</div> 
                                                         {errors.cityId && errors.cityId.type === "required" && <div class="error">City is required</div>}
                                                    </div>

                                                    <div class="col-lg-12 mb-4">
                                                        <label for="firstName" class="form-label">Standardized job code<span class="red-text">*</span></label>
                                                        {/* <input type="text"  class="form-control" id="stjobcode"  defaultValue=""  {...register('stjobcode', { required: true, pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ })}/> */}


                                                        <AsyncSelect
                                                            cacheOptions
                                                            components={animatedComponents}
                                                            getOptionLabel={(e) => e.title}
                                                            getOptionValue={(e) => e.socCode}
                                                            loadOptions={loadOptions}
                                                            onInputChange={(value) => setQuery(value)}
                                                            onChange={handleChange}
                                                        // onChange={(value) => setSocCode(value)}
                                                        />

                                                        <div id="soc_code_check" class="error">Standardized job code is required.</div> 
                                                    </div>
                                                   

                                                    <div class="col-lg-12 mb-4">
                                                        <label for="phone" class="form-label">Soc Code:</label>
                                                        <span id="socCodeVal">{socCode}</span>
                                                    </div>


                                                    <div class="col-lg-12 mb-4">
                                                        <label for="phone" class="form-label">Job Title:</label>
                                                        <span id="jobCodeTitle">{jobCodeTitle}</span>
                                                    </div>



                                                    <div className="wage">
                                                        <div className="wage-item">
                                                            <div className="d-grid">
                                                                <b>Level 1 <span className="red-text">*</span></b>
                                                                <small>Junior</small>
                                                            </div>

                                                        </div>
                                                        <div className="wage-item">
                                                            <div className="d-grid">
                                                                <label htmlFor="">Hourly</label>
                                                                <input type="text" class="form-control allow_numeric3"  name="junior_h1" id="junior_h1" />
                                                            </div>
                                                            <div id="junior_hcheck" class="error">Level1 junior field is required.</div>
                                                        </div>

                                                        <div className="wage-item">
                                                            <div className="d-grid">
                                                                <label htmlFor="">yearly</label>
                                                                <input type="text" class="form-control allow_numericd" name="junior_y1" id="junior_y1" />
                                                            </div>
                                                            <div id="junior_ycheck" class="error">Level1 yearly field is required.</div>
                                                        </div>
                                                    </div>

                                                    <div className="wage">
                                                        <div className="wage-item">
                                                            <div className="d-grid">
                                                                <b>Level 2 <span className="red-text">*</span></b>
                                                                <small>Mid Level</small>
                                                            </div>
                                                                   
                                                        </div>
                                                        <div className="wage-item">
                                                            <div className="d-grid">
                                                                <label htmlFor="">Hourly</label>
                                                                <input type="text" class="form-control allow_numeric3" name="midlevel_h1" id="midlevel_h1" />
                                                            </div>
                                                            <div id="midlevel_hcheck" class="error">Level2 Hourly field is required.</div> 
                                                        </div>

                                                        <div className="wage-item">
                                                            <div className="d-grid">
                                                                <label htmlFor="">yearly</label>
                                                                <input type="text" class="form-control allow_numericd" id="midlevel_y1" name="midlevel_y1"/>
                                                            </div>
                                                            <div id="midlevel_ycheck" class="error">Level2 Yearly field is required.</div> 
                                                        </div>
                                                    </div>

                                                    <div className="wage">
                                                        <div className="wage-item">
                                                            <div className="d-grid">
                                                                <b>Level 3 <span className="red-text">*</span></b>
                                                                <small>Managerial</small>
                                                            </div>

                                                        </div>
                                                        <div className="wage-item">
                                                            <div className="d-grid">
                                                                <label htmlFor="">Hourly</label>
                                                                <input type="text" class="form-control allow_numeric3" id="manager_h1" name="manager_h1" />
                                                            </div>
                                                            <div id="manager_hcheck" class="error">Level3 Hourly field is required.</div> 
                                                        </div>

                                                        <div className="wage-item">
                                                            <div className="d-grid">
                                                                <label htmlFor="">yearly</label>
                                                                <input type="text" class="form-control allow_numericd" id="manager_y1" name="manager_y1" />
                                                            </div>
                                                            <div id="manager_ycheck" class="error">Level3 Yearly field is required.</div> 
                                                        </div>
                                                    </div>

                                                    <div className="wage">
                                                        <div className="wage-item">
                                                            <div className="d-grid">
                                                                <b>Level 4<span className="red-text">*</span></b>
                                                                <small>Executive</small>
                                                            </div>

                                                        </div>
                                                        <div className="wage-item">
                                                            <div className="d-grid">
                                                                <label htmlFor="">Hourly</label>
                                                                <input type="text" class="form-control allow_numeric3" name="excutive_h1" id="excutive_h1" />
                                                            </div>
                                                            <div id="excutive_hcheck" class="error">Level4 Hourly field is required.</div> 
                                                        </div>

                                                        <div className="wage-item">
                                                            <div className="d-grid">
                                                                <label htmlFor="">yearly</label>
                                                                <input type="text" class="form-control allow_numericd" name="excutive_y1" id="excutive_y1" />
                                                            </div>
                                                            <div id="excutive_ycheck" class="error">Level4 Yearly field is required.</div> 
                                                        </div>
                                                    </div>



                                                    <div class="col-lg-12 mb-4">
                                                        <button type="submit" id="getWageInfo" class="reg-button">Submit</button>
                                                    </div>
                                                    <div id="successMessage" class="alert alert-success" role="alert">
                                                        Wage survey data has been inserted successfully
                                                    </div>






                                                    {/* <div class="col-lg-12 mb-4">
                                      <table class="table wage-data-tb" >
                                                    <thead class="thead-light">
                                                    <tr style={{ border:"1px"}}>
                                                        <th scope="col">SOURCE</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">LEVEL-1</th>
                                                        <th scope="col">LEVEL-2</th>
                                                        <th scope="col">LEVEL-3</th>
                                                        <th scope="col">LEVEL-4</th>
                                                        
                                                    
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                       
                                                    <th scope="row"  rowspan="2">DOL Wage Data</th> 
                                                        <td id="">Yearly</td>
                                                        <td id="dol_level1">{dolWageLevel1}</td>
                                                        <td id="dol_level2">{dolWageLevel2}</td>
                                                        <td id="dol_level3">{dolWageLevel3}</td>
                                                        <td id="dol_level4">{dolWageLevel4}</td>
                                                       
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td id="">Hourly</td>
                                                        <td id="dol_level1_hourly">{dolWageHourlyLevel1}</td>
                                                        <td id="dol_level2_hourly">{dolWageHourlyLevel2}</td>
                                                        <td id="dol_level3_hourly">{dolWageHourlyLevel3}</td>
                                                        <td id="dol_level4_hourly">{dolWageHourlyLevel4}</td>
                                                       
                                                    </tr>
                                                    
                                                    <tr>
                                                        <th scope="row"  rowspan="2">Local Survey Data</th>

                                                        <td id="">Yearly</td>
                                                        <td id="com_level1">{surveyWageLevel1}</td>
                                                        <td id="com_level2">{surveyWageLevel2}</td>
                                                        <td id="com_level3">{surveyWageLevel3}</td>
                                                        <td id="com_level4">{surveyWageLevel4}</td>
                                                        
                                                    </tr>
                                                    
                                                    
                                                    <tr>
                                                        <td id="">Hourly</td>
                                                        <td id="com_level1_hourly">{surveyWageHourlyLevel1}</td>
                                                        <td id="com_level2_hourly">{surveyWageHourlyLevel2}</td>
                                                        <td id="com_level3_hourly">{surveyWageHourlyLevel3}</td>
                                                        <td id="com_level4_hourly">{surveyWageHourlyLevel4}</td>
                                                   
                                                    </tr>
                                                    
                                                    </tbody>
                                                </table>
                                      </div> */}






                                                    {/* <div id="successMessage" class="alert">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <div class="d-flex align-items-center">
                                                                <img src="/images/success-alert.png" alt="" />
                                                                <strong class="mx-2">Success!</strong> Details updated successfully.
                                                            </div>
                                                            <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                                        </div>
                                                    </div> */}



                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
                {/* <Hiring/> */}
            </main>
        </>
    )
};

WageSurveyData.propTypes = {};

WageSurveyData.defaultProps = {};

export default WageSurveyData;
