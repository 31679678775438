import React,{history,useEffect, useState, useMemo } from "react";
import Carousel from "./carousel";
import Stay_Connected from "./Stay_Connected";
import services from "../../services/services";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

const Home = () => {
    const companyIdChkLogin=sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin=sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");
    const [videoList, setVideoList] = useState([]);
    const [companyVideoURL1, setCompanyVideoURL1] = useState('');
    const [companyVideoURL2, setCompanyVideoURL2] = useState('');
    const [companyVideoURL3, setCompanyVideoURL3] = useState('');
    const [resetPwdLink, setResetPwdLink] = useState('');
    const navigate = useNavigate();
    const staticData = require('../../constants/'+process.env.React_App_Static_Folder+'/static-data.json');
    const [featuredvideos, setFeaturedVideos] = useState([]);
    const [featuredVideosLength, setFeaturedVideosLength] = useState([]);

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          width:'100%',
          maxWidth:'768px',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding:'0px',
          border:'0px',
          textAlign:'center',
          zIndex:9
        },
      };

      let subtitle;
      const [modalIsOpen, setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
      }

      function afterOpenModal() {
        // references are now sync'd and can be accessed.
       // subtitle.style.color = '#f00';
      }

      function closeModal() {
        setIsOpen(false);
      }


      const getDefaultVideosList = () => {
        services.getCompanyFeaturedVideos().then((response) => {
          if(response){
            console.log(response.data);
            var dummArray_default = [];
            for (var i = 0; i < response.data.length; i++) {
              dummArray_default.push({ "label": response.data[i].companyName, "value": response.data[i].companyId })
            }
            setFeaturedVideos(response.data);
            setFeaturedVideosLength(response.data.length);
          }
        });
      }

      const convertToEmbedUrl = (youtubeUrl) => {
        let videoId;
        const urlParts = youtubeUrl.split(/[\/?&]+/);
        console.log(urlParts);
        if (youtubeUrl.includes('youtu.be')) {
          // Shortened URL format
          videoId = urlParts[urlParts.length - 1];
        } else if (youtubeUrl.includes('youtube.com/watch')) {
          // Regular URL format
          var match = youtubeUrl.match(/[?&]v=([^&]+)/);
            if (match) {
                videoId = match[1];
            }
        } 
    console.log(videoId);
        if (videoId) {
          return `https://www.youtube.com/embed/${videoId}`;
        }
        return youtubeUrl;
      };

    const getVideoList = (itemcount,pageNumber) => {
        //alert("14");
      const requestObject={
          "siteId":process.env.React_App_SITE_ID,
          "pageNumber":pageNumber,
          "pageSize":itemcount
       }

       var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json',
                   //'Authorization': "Bearer "+accessTokenIn
                   //'Authorization': accessTokenIn
                 },
         body: JSON.stringify(requestObject)
       };

       services.getVideosBySite(requestOptions).then((res) =>
       {
          console.log("############36");
         console.log(JSON.stringify(res))
         if(res.content){
           console.log(JSON.stringify(res));



              var contAr = res.content;
              //setProfileList(res.content);
              if(res.content){
                 setVideoList(res.content);
                 if(res.content.length>0){
                    if(res.content[0].videoUrl){
                        setCompanyVideoURL1(res.content[0].videoUrl)
                    }
                }

                if(res.content.length>1){
                    if(res.content[1].videoUrl){
                        setCompanyVideoURL2(res.content[1].videoUrl)
                    }
                }

                if(res.content.length>2){
                    if(res.content[2].videoUrl){
                        setCompanyVideoURL3(res.content[2].videoUrl)
                    }
                }




              }


         }
        //navigate('/thankyou');

       });


    }

    const getVideos = (siteId) => {


        services.getHomePageVideos(siteId).then((res) => {


            console.log(JSON.stringify(res));
            if(res.data){
                setVideoList(res.data);
                if(res.data[0].videoUrl){
                    setCompanyVideoURL1(res.data[0].videoUrl)
                }
                if(res.data[0].videoUrl){
                    setCompanyVideoURL2(res.data[1].videoUrl)
                }
                if(res.data[0].videoUrl){
                    setCompanyVideoURL3(res.data[2].videoUrl)
                }

            }

        });

     }

     function goLogin(){
        window.location.href = "/Home?loginRedirect=1";
       // navigate("/Home?loginRedirect=1");
      }
    useEffect(() => {
        //getVideos("2");
        const jsonData = {
            "home": {
              
            }
          };

        var jsondataformatted = JSON.stringify(jsonData);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);

        setResetPwdLink(process.env.React_App_RESET_PWD_LINK);

       //
       var hideFlashPageVal=sessionStorage.getItem("hideFlashPage");
       if(hideFlashPageVal !="1"){
        //openModal();
       }
       if( companyIdChkLogin != null && companyIdChkLogin != "null"){
        
            //openModal();
        }

        getVideoList('3','1');
        getDefaultVideosList();

    }, []);

  return(
  <>
   <main>
       <Carousel />

         {/* <!-- info typography --> */}


         <div>
                          {/* <button onClick={openModal} class="reg-button mb-5">WAGE DATA</button> */}
                          <Modal
                            isOpen={modalIsOpen}
                            onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                          >
                            <div className="welcome-new">
                            <button onClick={closeModal} class="wage-close">close</button>
                                <div className="hd_wp">
                                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Welcome to the new home of</h2>
                                    {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>WAGE DATA</h2> */}
                                    <img src="images/logo-white.svg" alt=""/>
                                </div>
                                <div className="hd_content my-2">
                                    <p>Please make sure to update your profile, logo, banner and company information using below links.
                                        <br />

                                    You can browse the available jobs by clicking <a href="/availableJobs">here</a>.
                                    <br />

                                    You can also simply close this window and browse jobs.
                                    </p>
                                </div>
                                <div className="hd_footer">
                                    <h4>UPDATE THE BELOW ITEMS</h4>
                                    <hr />
                                    {/* <button onClick={closeModal} class="btn btn-secondary">close</button> */}
                                    <a href="/editAdminstrationLogin" class="btn btn-primary mb-2">Update Profile</a>
                                    <a href="/companyLogo" class="btn btn-primary mx-2 mb-2">Update Logo</a>
                                    <a href="/profileBanner" class="btn btn-primary mb-2">Update Banner</a>
                                    <a href="/profileInfo" class="btn btn-primary mb-2">Update Company Information</a>
                                </div>
                            </div>






                          </Modal>
                        </div>



         <section class="info-typography">
            <div class="container">
               <div class="row">
                  <div class="col-lg-10 m-auto py-5 text-center">
                     <h2 class="px-4">
                        {staticData.site_display_name} connects job-seekers with employers <span class="red-text">right here</span> in your local area.
                     </h2>
                     <b>How does it work? Just create an account and start searching! </b>
                     <p class="py-3">{staticData.site_display_name} is all local, all the time, so all of the companies and job opportunities are right here in your back yard! Look for full time, part-time, or seasonal work in virtually any industry, or post your resume and become visible to local employers who are actively looking for new members of their team. Register today… your new career starts here.</p>
                     <div class="col-xl-8 col-lg-10 d-flex m-auto  flex-md-row flex-column justify-content-between">
                        {/* <a href="/postJob" class="btn btn-primary mb-2 spl-btn">POST AN OPEN POSITION</a> */}
                        <a href="/availableJobs" class="btn btn-primary mb-2 spl-btn text-uppercase">Available Jobs</a>


                        <a href="/postResume" class="btn btn-primary mb-2 spl-btn">UPLOAD YOUR RESUME</a>

                        {(() => {

                            if( companyIdChkLogin != null && companyIdChkLogin != "null"){
                                return (<><a href="/postJob" class="btn btn-primary mb-2 spl-btn text-uppercase">POST AN OPEN POSITION</a></>);
                            }else{
                                return (<><a onClick={() => goLogin() } class="btn btn-primary mb-2 spl-btn text-uppercase">POST AN OPEN POSITION</a></>);
                            }

                        })()}



                     </div>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- featured-companies --> */}
         {/* <section class="featured-companies mb-3">
            <img src="images/featured-companies.png" class="w-100" alt=""/>
         </section> */}
         {/* <!-- info-typography-picture --> */}
         <section class="typography-picture mb-3">
            <div class="typo-item">
               <h2>Are you a local student? <br/> Sign up for career resources.</h2>
               <b>{staticData.site_display_name} has a special section designed just for you!</b>
               <p class="my-4">{staticData.site_display_name} has a special section designed just for you! Check out sample career paths, research local companies, colleges, trade schools or even apply for internships!
                  <br/>
                  <br/>Be sure to upload your resume (it’s never too early) and make yourself visible. Local companies are looking for entry level positions. Build your professional relationships now… it’s the path to a successful future.
               </p>
               <a href="/studentRegistrationPersonalInfo" class="orange-text">REGISTER AS A STUDENT &#62;&#62;</a>
               {/* <a href="#" class="orange-text">REGISTER AS A STUDENT>></a> */}
            </div>
            <div class="typo-item">
               <img src="images/student1.png" class="object-img" alt=""/>
            </div>
         </section>
         {/* <!-- features --> */}
         <section class="features mb-3 p-5 features-desktop ">
            <div class="container">
                <div class="row">
                    <div class="col-lg-2 col-md-4 col-xs-6">
                        <div class="feature-item p-y-3 text-center">
                            <img src="images/post-icon.png" alt="" />
                            <div class="title py-3">
                                <h4>Post a job</h4>
                            </div>
                            <p>Register to create your company profile and post open positions.</p>
                            <a href="/postJob" class="reg-button">GO</a>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-xs-6">
                        <div class="feature-item p-y-3 text-center">
                            <img src="images/find-job-icon.png" alt="" />
                            <div class="title py-3">
                                <h4>Find a job</h4>
                            </div>
                            <p>Browse local jobs in your area. Part-time, full-time, and even internships.</p>
                            <a href="/availableJobs" class="reg-button">GO</a>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-xs-6">
                        <div class="feature-item p-y-3 text-center">
                            <img src="images/email-icon.png" alt="" />
                            <div class="title py-3">
                                <h4>jobs via email</h4>
                            </div>
                            <p>Sign up to have job opportunities in your specialty delivered to your inbox</p>
                            <a href="/subscribetocareer" class="reg-button">GO</a>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-xs-6">
                        <div class="feature-item p-y-3 text-center">
                            <img src="images/resume-icon.png" alt="" />
                            <div class="title py-3">
                                <h4>Post a Resume</h4>
                            </div>
                            <p>Upload your resume and information and let local employers contact you!</p>
                            <a href="/postResume" class="reg-button">GO</a>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-xs-6">
                        <div class="feature-item p-y-3 text-center">
                            <img src="images/events-icon.png" alt="" />
                            <div class="title py-3">
                                <h4>Events</h4>
                            </div>
                            <p>Check out local industry-focused career events in your area.</p>
                            <a href="/events" class="reg-button">GO</a>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-xs-6">
                        <div class="feature-item p-y-3 text-center">
                            <img src="images/student-icon.png" alt="" />
                            <div class="title py-3">
                                <h4>Students</h4>
                            </div>
                            <p>Learn more about your potential career path and what it can offer you.</p>
                            <a href="/studentRegistrationPersonalInfo" class="reg-button">GO</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="features-mobile">
            <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="images/post-icon.png" alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            <h5>Post a Job</h5>
                            <p>Register to create your company profile and post open positions.</p>
                            <a href="/postJob" class="reg-button">GO</a>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="images/find-job-icon.png" alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            <h5>Find a job</h5>
                            <p>Browse local jobs in your area. Part-time, full-time, and even internships.</p>
                            <a href="/availableJobs" class="reg-button">GO</a>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="images/email-icon.png" alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            <h5>jobs via email</h5>
                            <p>Sign up to have job opportunities in your specialty delivered to your inbox</p>
                            <a href="/subscribetocareer" class="reg-button">GO</a>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="images/resume-icon.png" alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            <h5>Post a Resume </h5>
                            <p>Upload your resume and information and let local employers contact you!</p>
                            <a href="/postResume" class="reg-button">GO</a>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="images/events-icon.png" alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            <h5>Events</h5>
                            <p>Check out local industry-focused career events in your area.</p>
                            <a href="/events" class="reg-button">GO</a>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="images/student-icon.png" alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            <h5>Students</h5>
                            <p>earn more about your potential career path and what it can offer you.</p>
                            <a href="/studentRegistrationPersonalInfo" class="reg-button">GO</a>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
            </div>

        </section>

        <section class=" featured-companies-mobile d-none">
            <h2 class="p-4 text-center">Check out this month's featured local employers</h2>

            <div id="carouselExampleCaptionsemp" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">

                        <img src="images/employers/handshoe.png" alt="" />

                    </div>
                    <div class="carousel-item">
                        <img src="images/employers/whirlpool.png" alt="..." />

                    </div>
                    <div class="carousel-item">
                        <img src="images/employers/crown.png" alt="..." />

                    </div>

                    <div class="carousel-item">
                        <img src="images/employers/wayne.png" alt="..." />

                    </div>

                    <div class="carousel-item">
                        <img src="images/employers/smallnation.png" alt="..." />

                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
            </div>
        </section>
         {/* <!-- info-typography-picture 2 --> */}
         <section class="typography-picture-2 mb-3">
            <div class="typo-item">
               <img src="images/lady-books.png" class="object-img" alt=""/>
            </div>
            <div class="typo-item">
               <h2>Looking for team members? <br/> Post your job openings here.</h2>
               <b>{staticData.site_display_name} was designed to help local employers share the many values and opportunities that exist in our loal area. </b>
               <p class="my-4">Register today and create a company profile. Share your story, culture, benefits and the quality of life in our area. Post open positions or actively research resumes. {staticData.site_display_name} is more than just a job board… it’s your local tool for connecting, recruiting, and building your team.</p>
               <a href="/companyRegister" class="orange-text">REGISTER AS A LOCAL EMPLOYER &#62;&#62;</a>
               {/* <a href="#" class="orange-text">REGISTER AS A LOCAL EMPLOYER >></a> */}
            </div>
         </section>
         <section class="career-box p-5 text-center mb-3">
            <div class="container">
                <div class="row">
                  <div class="col-lg-10 m-auto">
                  <h1 class="oa">Opportunity Awaits!</h1>
                  </div>
                </div>
                <div class="row">
                    <div class="col-lg-7 m-auto">
                        <h2>Find your career in your inbox!</h2>
                        <p>Looking for a job? Sign up now to have job openings delivered directly to your inbox. Choose your industry and watch your email every Tuesday for new opportunities!</p>
                        <a href="/subscribetocareer" class="reg-button mb-3">SIGN UP</a>
                    </div>
                </div>
            </div>
        </section>
         {/* <!-- careerBox -->
         <section class="career-box p-4 text-center mb-3">
            <div class="container">
               <div class="row">
                  <div class="col-lg-7 m-auto">
                     <h2>Find your career in your inbox!</h2>
                     <p>Looking for a job? Sign up now to have job openings delivered directly to your inbox. Choose your industry and watch your email every Tuesday for new opportunities!</p>
                     <a href="#" class="reg-button">SIGN UP</a>
                  </div>
               </div>
            </div>
         </section>
         <!-- careerBox --> */}
         
         {featuredVideosLength > 0 ? 
         <section class="video-section text-center p-4">
         <div class="container">
            <div class="row">
               <div class="title">
                  <h2>{staticData.site_display_name} <br/> works in local areas!</h2>
                  <p>Listen to others share their {staticData.site_display_name} experiences.</p>
               </div>
               <div class="homevideowrapper">
               {
                 featuredvideos.map((data, index) => (
                     
                         <div class="homefeaturedvideoitem">
                           
                                 <ReactPlayer url={data.url} class="vidlib-cont-all fb-video" controls="true"  width="100%" height="200px" />
                                           
                                         </div>
         
                 ))}
                 </div>

            </div>
         </div>
      </section>
         : ''}

         <section>
            <div class="container">
               {/* <hr/> */}
            </div>
         </section>
         {/* <Stay_Connected/> */}
      </main>
  </>
)};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
