import React, { history, useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import moment from "moment";
import Services from "../../services/services";
import $, { parseJSON } from 'jquery';
import UsNumberValidate from '../../utility/usNumberValidate';
import Pagination from "../../utility/Pagination/Pagination";
import { components } from "react-select";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { default as ReactSelect } from "react-select";
import Modal from 'react-modal';

const Option = (props) => {
    return (
        <div>
            {
                //console.log("results=="+JSON.stringify(props))
            }
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const AvailableJobs = () => {
    const [itemName, setFieldName] = useState('');
    const navigate = useNavigate();
    const [stateData, setStateData] = useState([]);
    // const siteID = 2;
    const siteID = process.env.React_App_SITE_ID;
    const ITEMS_PER_PAGE = 20; // Per page show the data pagination
    //const [categories, setCategory] = useState([]);
    //const [locationCounty, setLocationCounty] = useState([]);

    const [availableJobsList, setAvailableJobsList] = useState([]);
    const [availableJobsList_m, setAvailableJobsList_m] = useState([]);
    const [availableJobsList_mobile, setAvailableJobsList_mobile] = useState([]);
    const [locationCounty, setLocationCounty] = useState([]);
    const [educations, setEducations] = useState([]);
    const [jobTypes, setJobTypes] = useState([]);
    const [pageOfItems, setPageOfItems] = useState([]);
    const [exampleItems, setExampleItems] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems_m, setTotalItems_m] = useState(0);
    const [totalpages_m, settotalPages_m] = useState();
    const [currentPage_m, setCurrentPage_m] = useState(1);

    ///mobile job filter variables/////////////////
    const [optionSelected, setoptionSelected] = useState(null);
    const [optionEduSelected, setoptionEduSelected] = useState(null);
    const [optionCatSelected, setoptionCatSelected] = useState(null);
    const [muleducation, setMulEducationTypes] = useState([]);

    const [categories, setCategory] = useState([]);
    const [mulcategories, setMulCategory] = useState([]);
    const [mullocationCounty, setMulLocationCounty] = useState([]);
    const [mullocationCountyselected, setMulLocationCountyOptionSelected] = useState([]);
    const [muljobTypes, setMulJobTypes] = useState([]);

    const [industryRequestArray, setIndustryRequestArray] = useState([]);
    const [locationRequestArray, setLocationRequestArray] = useState([]);
    const [jobtypeRequestArray, setJobTypeRequestArray] = useState([]);
    const [eduRequestArray, setEduTypeRequestArray] = useState([]);

    ////Web New Design Variables
    const [industryRequestArray_d, setIndustryRequestArrayd] = useState([]);
    const [optionCatSelected_d, setoptionCatSelectedd] = useState([]);
    const [mulcategories_d, setMulCategory_d] = useState([]);

    const [mullocationCountyselected_d, setMulLocationCountyOptionSelectedd] = useState([]);
    const [locationRequestArray_d, setLocationRequestArrayd] = useState([]);
    const [jobtypeRequestArray_d, setJobTypeRequestArrayd] = useState([]);
    const [optionSelected_d, setoptionSelectedd] = useState(null);
    const [eduRequestArray_d, setEduTypeRequestArrayd] = useState([]);
    const [optionEduSelected_d, setoptionEduSelectedd] = useState(null);
    const [optionEduSelected_d_m, setoptionEduSelecteddm] = useState(null);

    const [perks_popup, setPerks_popup] = useState([]);

    var industry_webvar = [];
    var location_webvar = [];
    var jobtype_webvar = [];
    var education_webvar = [];

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var pagenum = queryParams.get('page');

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [summary, setSummary] = useState('');
    const [url, setUrl] = useState('');
    const [title, setTitle] = useState('');
    var session_pageobj = '';
    var webfilters = 1;
    var mobfilters = 1;

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    const handleChange = (selected) => {
        var dummArray = [];
        var dummArray_label = [];
        for (var i = 0; i < selected.length; i++) {
            dummArray.push(selected[i].value.toString());
            dummArray_label.push(selected[i].label.toString());
        }
        setoptionSelected(selected);

        $('#jobtype_selected_m').val(JSON.stringify(dummArray));
        $('#jobtypeLabel_selected_m').val(JSON.stringify(dummArray_label));
        setJobTypeRequestArray(dummArray);
    };

    const handleChange_d = (selected) => {
        var dummArray = [];
        var dummArray_label = [];
        for (var i = 0; i < selected.length; i++) {
            dummArray.push(selected[i].value.toString());
            dummArray_label.push(selected[i].label.toString());
            jobtype_webvar.push(selected[i].value.toString());
        }
        setoptionSelectedd(selected);
        //jobtype_webvar = dummArray;
        $('#jobtype_selected').val(JSON.stringify(dummArray));
        $('#jobtypeLabel_selected').val(JSON.stringify(dummArray_label));
        setJobTypeRequestArrayd(dummArray);

    };

    const handleChangeForEdu = (selected) => {
        var dummArray = [];
        for (var i = 0; i < selected.length; i++) {
            dummArray.push(selected[i].value.toString());
        }
        setoptionEduSelected(selected);
        setEduTypeRequestArray(dummArray);
    };

    const handleChangeForEdu_d = (selected) => {
        var dummArray = [];
        var dummArray_label = [];
        for (var i = 0; i < selected.length; i++) {
            dummArray.push(selected[i].value.toString());
            dummArray_label.push(selected[i].label.toString());
            education_webvar.push(selected[i].value.toString());
        }
        //setoptionEduSelectedd(selected);
        //education_webvar = dummArray;
        $('#education_selected').val(JSON.stringify(dummArray));
        $('#educationLabel_selected').val(JSON.stringify(dummArray_label));
        setEduTypeRequestArrayd(dummArray);

        //var stored_locs = localStorage.getItem('av_education_selected');
        var stored_locs = sessionStorage.getItem('av_education_selected');
        setoptionEduSelectedd(selected);

    };

    const handleChangeForEdu_d_m = (selected) => {
        var dummArray = [];
        var dummArray_label = [];
        for (var i = 0; i < selected.length; i++) {
            dummArray.push(selected[i].value.toString());
            dummArray_label.push(selected[i].label.toString());
            education_webvar.push(selected[i].value.toString());
        }
        setoptionEduSelecteddm(selected);
        //education_webvar = dummArray;
        $('#education_selected_m').val(JSON.stringify(dummArray));
        $('#educationLabel_selected_m').val(JSON.stringify(dummArray_label));
        setEduTypeRequestArrayd(dummArray);

    };

    const handleChangeForCate = (selected) => {
        //alert();
        var dummArray = [];
        var dummArray_label = [];
        for (var i = 0; i < selected.length; i++) {
            dummArray.push(selected[i].value.toString());
            dummArray_label.push(selected[i].label.toString());
        }
        setIndustryRequestArray(dummArray);

        $('#industry_selected_m').val(JSON.stringify(dummArray));
        $('#industryLabel_selected_m').val(JSON.stringify(dummArray_label));
        setoptionCatSelected(selected);
    };

    const handleChangeForCate_d = (selected) => {
        //alert();
        var dummArray = [];
        var dummArray_label = [];
        for (var i = 0; i < selected.length; i++) {
            //alert(selected[i].label.toString());
            dummArray.push(selected[i].value.toString());
            dummArray_label.push(selected[i].label.toString());
            industry_webvar.push(selected[i].value.toString());
        }
        console.log(industry_webvar);
        setIndustryRequestArrayd(dummArray);
        //industry_webvar = dummArray;
        $('#industry_selected').val(JSON.stringify(dummArray));


        $('#industryLabel_selected').val(JSON.stringify(dummArray_label));

        //var stored_locs = localStorage.getItem('av_industry_selected');
        var stored_locs = sessionStorage.getItem('av_industry_selected');
        console.log(JSON.stringify(dummArray));
        console.log('*********************');
        console.log(JSON.stringify(dummArray_label));
        setoptionCatSelectedd(selected);
    };



    const handleChangeForLocation = (selected) => {
        var dummArray = [];
        var dummArray_label = [];
        for (var i = 0; i < selected.length; i++) {
            dummArray.push(selected[i].value.toString());
            dummArray_label.push(selected[i].label.toString());
        }
        setMulLocationCountyOptionSelected(selected);

        $('#location_selected_m').val(JSON.stringify(dummArray));
        $('#locationLabel_selected_m').val(JSON.stringify(dummArray_label));
        setLocationRequestArray(dummArray);

    };

    const handleChangeForLocation_d = (selected) => {
        var dummArray = [];
        var dummArray_label = [];
        for (var i = 0; i < selected.length; i++) {
            dummArray.push(selected[i].value.toString());
            dummArray_label.push(selected[i].label.toString());
            location_webvar.push(selected[i].value.toString());
        }
        setMulLocationCountyOptionSelectedd(selected);
        //location_webvar = dummArray;
        $('#location_selected').val(JSON.stringify(dummArray));
        $('#locationLabel_selected').val(JSON.stringify(dummArray_label));
        setLocationRequestArrayd(dummArray);


    };
    const submitStep1 = () => {
        $("#step1").hide();
        $("#step2").show();
        $("#step3").hide();
        $("#step4").hide();
        $("#step5").hide();
        return false;
    }

    const submitStep2 = () => {
        $("#step1").hide();
        $("#step2").hide();
        $("#step3").show();
        $("#step4").hide();
        $("#step5").hide();
    }

    const submitStep3 = () => {
        $("#step1").hide();
        $("#step2").hide();
        $("#step3").hide();
        $("#step4").show();
        $("#step5").hide();
    }

    const submitStep4 = () => {
        $("#step1").hide();
        $("#step2").hide();
        $("#step3").hide();
        $("#step4").hide();
        $("#step5").show();
        searchInit_m(ITEMS_PER_PAGE, '1');
    }




    const filterFuncInit = () => {
        //$("#availableJobsList_mobileResult").hide();
        //$(".company-detail-wrap").hide();
    };


    const handlePageChange2_m = (offset, triggerevent) => {
        mobileFilterCall(ITEMS_PER_PAGE, offset,'0');
    };
    const handlePageChange2 = (offset, triggerevent) => {
        searchInit(ITEMS_PER_PAGE, offset, '0');
    };

    function searchInit_m(ITEMS_PER_PAGE, pageNumber) {
        ///////////
        console.log("test--75");

        var searchkey = $('#search_textm').val().trim();

        var industryArray = [];

        $.each($("input[name='industry_m']:checked"), function () {
            industryArray.push($(this).val());
        });

        var locationArray = [];

        $.each($("input[name='location_m']:checked"), function () {
            locationArray.push($(this).val());
        });

        var jobtypeArray = [];

        $.each($("input[name='jobtype_m']:checked"), function () {
            jobtypeArray.push($(this).val());
        });


        var educationArray = [];

        $.each($("input[name='education_m']:checked"), function () {
            educationArray.push($(this).val());
        });
        const requestObject = {
            "searchTxt": searchkey,
            "jobCategory": industryArray,
            "jobLocation": locationArray,
            "jobEducation": educationArray,
            "jobType": jobtypeArray,
            "pageSize": ITEMS_PER_PAGE,
            "pageNumber": pageNumber,
            "sortBy": "jobId",
            "sortDir": "desc",
            "siteId": siteID

        }
        console.log(requestObject);
        var accessTokenIn = sessionStorage.getItem("accToken");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn
                //'Authorization': accessTokenIn
            },
            body: JSON.stringify(requestObject)
        };

        Services.getAvailableJobs(requestOptions).then((res) => {
            //  alert(JSON.stringify(res));
            if (res.content) {
                setAvailableJobsList_m(res.content);
                window.scrollTo({ top: 100, behavior: 'smooth' });

                //  console.log("company jobs list@@@@@@@" + JSON.stringify(res.content));

                setTotalItems_m(res.totalElements);
                settotalPages_m(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                setCurrentPage_m(pageNumber);
            }



        });

        ////////
    }
    const submitValue = () => {
        searchInit(ITEMS_PER_PAGE, '1', '0');
    }

    const submitValue_m = () => {
        mobileFilterCall(ITEMS_PER_PAGE, '1', '0');
    }

    function searchInit(ITEMS_PER_PAGE, pageNumber, setlcstorage) {
//console.log('web filter - 397');
//console.log("Current Time:", new Date().toLocaleTimeString());
        var searchkey = $('#search_text').val().trim();
        var searchkey_m = $('#search_textm').val().trim();
        var industries_filter = [];
        var selected_ind = $('#industry_selected').val();
        console.log('403 ***** ' + selected_ind);
        if (selected_ind != '') {
            var industries_filter = JSON.parse(selected_ind);
        }

        var locations_filter = [];
        var selected_loc = $('#location_selected').val();
        if (selected_loc != '') {
            var locations_filter = JSON.parse(selected_loc);
        }

        var educations_filter = [];
        var selected_edu = $('#education_selected').val();
        if (selected_edu != '') {
            var educations_filter = JSON.parse(selected_edu);
        }

        var jobtypes_filter = [];
        var selected_types = $('#jobtype_selected').val();
        if (selected_types != '') {
            var jobtypes_filter = JSON.parse(selected_types);
        }

        //alert(localStorage.getItem('av_industry_selected'));

        console.log('428 ***** ' + selected_ind);

        if (setlcstorage == "1") {
            var industriesLabel_filter = '';
            var industries_val2 = '';
            var selected_indLabel = $('#industryLabel_selected').val();
            console.log('434 ***** ' + selected_indLabel);
            if (selected_indLabel != '') {
                industriesLabel_filter = JSON.parse(selected_indLabel);
            }

            var selected_val1 = $('#industry_selected').val();
            console.log('420 ***** ' + selected_indLabel);
            if (selected_val1 != '') {
                industries_val2 = JSON.parse(selected_val1);
            }

            var educationLabel_filter = '';
            var education_val2 = '';
            var selected_eduLabel = $('#educationLabel_selected').val();
            if (selected_eduLabel != '') {
                educationLabel_filter = JSON.parse(selected_eduLabel);
            }

            var selected_eduval1 = $('#education_selected').val();
            if (selected_eduval1 != '') {
                education_val2 = JSON.parse(selected_eduval1);
            }

            var locationLabel_filter = '';
            var location_val2 = '';
            var selected_locLabel = $('#locationLabel_selected').val();
            if (selected_locLabel != '') {
                locationLabel_filter = JSON.parse(selected_locLabel);
            }

            var selected_locval1 = $('#location_selected').val();
            if (selected_locval1 != '') {
                location_val2 = JSON.parse(selected_locval1);
            }

            var jobtypeLabel_filter = '';
            var jobtype_val2 = '';
            var selected_jobtypeLabel = $('#jobtypeLabel_selected').val();
            if (selected_jobtypeLabel != '') {
                jobtypeLabel_filter = JSON.parse(selected_jobtypeLabel);
            }

            var selected_jobtypeval1 = $('#jobtype_selected').val();
            if (selected_jobtypeval1 != '') {
                jobtype_val2 = JSON.parse(selected_jobtypeval1);
            }

            // localStorage.setItem('av_industry_selected', industries_val2);
            // localStorage.setItem('av_industry_selected_label', industriesLabel_filter);
            // localStorage.setItem('av_education_selected', education_val2);
            // localStorage.setItem('av_education_selected_label', educationLabel_filter);
            // localStorage.setItem('av_location_selected', location_val2);
            // localStorage.setItem('av_location_selected_label', locationLabel_filter);
            // localStorage.setItem('av_jobtype_selected', jobtype_val2);
            // localStorage.setItem('av_jobtype_selected_label', jobtypeLabel_filter);
            // localStorage.setItem('av_search_text', searchkey);

            // sessionStorage.setItem('av_industry_selected', industries_val2);
            // sessionStorage.setItem('av_industry_selected_label', industriesLabel_filter);
            // sessionStorage.setItem('av_education_selected', education_val2);
            // sessionStorage.setItem('av_education_selected_label', educationLabel_filter);
            // sessionStorage.setItem('av_location_selected', location_val2);
            // sessionStorage.setItem('av_location_selected_label', locationLabel_filter);
            // sessionStorage.setItem('av_jobtype_selected', jobtype_val2);
            // sessionStorage.setItem('av_jobtype_selected_label', jobtypeLabel_filter);
            // sessionStorage.setItem('av_search_text', searchkey);

/*
            var industriesLabel_filter_m = '';
            var industries_val2_m = '';
            var selected_indLabel_m = $('#industryLabel_selected_m').val();
            if (selected_indLabel_m != '') {
                industriesLabel_filter_m = JSON.parse(selected_indLabel_m);
            }

            var selected_val1_m = $('#industry_selected_m').val();
            if (selected_val1_m != '') {
                industries_val2_m = JSON.parse(selected_val1_m);
            }

            var educationLabel_filter_m = '';
            var education_val2_m = '';
            var selected_eduLabel_m = $('#educationLabel_selected_m').val();
            if (selected_eduLabel_m != '') {
                educationLabel_filter_m = JSON.parse(selected_eduLabel_m);
            }

            var selected_eduval1_m = $('#education_selected_m').val();
            if (selected_eduval1_m != '') {
                education_val2_m = JSON.parse(selected_eduval1_m);
            }

            var locationLabel_filter_m = '';
            var location_val2_m = '';
            var selected_locLabel_m = $('#locationLabel_selected_m').val();
            if (selected_locLabel_m != '') {
                locationLabel_filter_m = JSON.parse(selected_locLabel_m);
            }

            var selected_locval1_m = $('#location_selected_m').val();
            if (selected_locval1_m != '') {
                location_val2_m = JSON.parse(selected_locval1_m);
            }

            var jobtypeLabel_filter_m = '';
            var jobtype_val2_m = '';
            var selected_jobtypeLabel_m = $('#jobtypeLabel_selected_m').val();
            if (selected_jobtypeLabel_m != '') {
                jobtypeLabel_filter_m = JSON.parse(selected_jobtypeLabel_m);
            }

            var selected_jobtypeval1_m = $('#jobtype_selected_m').val();
            if (selected_jobtypeval1_m != '') {
                jobtype_val2_m = JSON.parse(selected_jobtypeval1_m);
            }

            localStorage.setItem('av_industry_selected_m', industries_val2_m);
            localStorage.setItem('av_industry_selected_label_m', industriesLabel_filter_m);
            localStorage.setItem('av_education_selected_m', education_val2_m);
            localStorage.setItem('av_education_selected_label_m', educationLabel_filter_m);
            localStorage.setItem('av_location_selected_m', location_val2_m);
            localStorage.setItem('av_location_selected_label_m', locationLabel_filter_m);
            localStorage.setItem('av_jobtype_selected_m', jobtype_val2_m);
            localStorage.setItem('av_jobtype_selected_label_m', jobtypeLabel_filter_m);

            localStorage.setItem('av_search_text_m', searchkey_m); */

        }
        else {
            /*localStorage.setItem('av_search_text', '');
            localStorage.setItem('av_location_selected', '');
            localStorage.setItem('av_education_selected', '');
            localStorage.setItem('av_jobtype_selected', '');
            localStorage.setItem('av_industry_selected', ''); */
        }

        var industriesLabel_filter = '';
            var industries_val2 = '';
            var selected_indLabel = $('#industryLabel_selected').val();
            if (selected_indLabel != '') {
                industriesLabel_filter = JSON.parse(selected_indLabel);
            }

            var selected_val1 = $('#industry_selected').val();
            console.log('578 ***** ' + selected_indLabel);
            if (selected_val1 != '') {
                industries_val2 = JSON.parse(selected_val1);
            }

            var educationLabel_filter = '';
            var education_val2 = '';
            var selected_eduLabel = $('#educationLabel_selected').val();
            if (selected_eduLabel != '') {
                educationLabel_filter = JSON.parse(selected_eduLabel);
            }

            var selected_eduval1 = $('#education_selected').val();
            if (selected_eduval1 != '') {
                education_val2 = JSON.parse(selected_eduval1);
            }

            var locationLabel_filter = '';
            var location_val2 = '';
            var selected_locLabel = $('#locationLabel_selected').val();
            if (selected_locLabel != '') {
                locationLabel_filter = JSON.parse(selected_locLabel);
            }

            var selected_locval1 = $('#location_selected').val();
            if (selected_locval1 != '') {
                location_val2 = JSON.parse(selected_locval1);
            }

            var jobtypeLabel_filter = '';
            var jobtype_val2 = '';
            var selected_jobtypeLabel = $('#jobtypeLabel_selected').val();
            if (selected_jobtypeLabel != '') {
                jobtypeLabel_filter = JSON.parse(selected_jobtypeLabel);
            }

            var selected_jobtypeval1 = $('#jobtype_selected').val();
            if (selected_jobtypeval1 != '') {
                jobtype_val2 = JSON.parse(selected_jobtypeval1);
            }

            console.log('pageNumber = '+pageNumber);
            if(pageNumber > 1){
                var jsonData = {
                    "availableJobs": {
                      "industry": industries_val2,
                      "industry_label": industriesLabel_filter,
                      "education": education_val2,
                      "education_label": educationLabel_filter,
                      "location": location_val2,
                      "location_label": locationLabel_filter,
                      "job_type": jobtype_val2,
                      "job_type_label": jobtypeLabel_filter,
                      "search_text": searchkey,
                      "pagination_val": pageNumber,
                      "industry_m": '',
                      "industry_label_m": '',
                      "education_m": '',
                      "education_label_m": '',
                      "location_m": '',
                      "location_label_m": '',
                      "job_type_m": '',
                      "job_type_label_m": '',
                      "search_text_m": "",
                      "pagination_val_m": '',
                    }
                  };
        
                
            } else {
                var jsonData = {
                    "availableJobs": {
                      "industry": industries_val2,
                      "industry_label": industriesLabel_filter,
                      "education": education_val2,
                      "education_label": educationLabel_filter,
                      "location": location_val2,
                      "location_label": locationLabel_filter,
                      "job_type": jobtype_val2,
                      "job_type_label": jobtypeLabel_filter,
                      "search_text": searchkey,
                      "pagination_val": '',
                      "industry_m": '',
                      "industry_label_m": '',
                      "education_m": '',
                      "education_label_m": '',
                      "location_m": '',
                      "location_label_m": '',
                      "job_type_m": '',
                      "job_type_label_m": '',
                      "search_text_m": "",
                      "pagination_val_m": '',
                    }
                  };
            }
        

        var jsondataformatted = JSON.stringify(jsonData);
        console.log('674 *** '+jsondataformatted);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);
        
        console.log('web filter - 675');
        console.log("Current Time:", new Date().toLocaleTimeString());
        const requestObject = {

            "searchTxt": searchkey,
            "jobCategory": industries_filter,
            // "jobCategory": industry_hidden,
            "jobLocation": locations_filter,
            "jobEducation": educations_filter,
            "jobType": jobtypes_filter,
            "pageSize": ITEMS_PER_PAGE,
            "pageNumber": pageNumber,
            "sortBy": "jobId",
            "sortDir": "desc",
            "siteId": siteID

        }
        console.log('***************');
        console.log(requestObject);
        var accessTokenIn = sessionStorage.getItem("accToken");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn
                //'Authorization': accessTokenIn
            },
            body: JSON.stringify(requestObject)
        };
        $(".cards").show();
        
       // console.log("447" + requestOptions);
        Services.getAvailableJobs(requestOptions).then((res) => {
            console.log('web filter - 706');
            console.log("Current Time:", new Date().toLocaleTimeString());
            if (res.content) {
                console.log('web filter - 712');
            console.log("Current Time:", new Date().toLocaleTimeString());
                console.log(res.content.length)
                setAvailableJobsList(res.content);

                window.scrollTo({ top: 150, behavior: 'smooth' });

                // console.log("company jobs list@@@@@@@" + JSON.stringify(res.content));

                setTotalItems(res.totalElements);
                settotalPages(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                setCurrentPage(pageNumber);
            }
            $(".cards").hide();
            var ind_master = sessionStorage.getItem('master_industry');
            if(ind_master == null || ind_master == ''){
                load_serviceindustrydata();
            }
            else {
                load_localindustrydata();
            }
            
            var loc_master = sessionStorage.getItem('master_location');
            console.log('loc_master == '+loc_master);
            if(loc_master == null || loc_master == ''){
                load_servicelocationdata();
            }
            else {
                load_locallocationdata();
            }

            var jobtype_master = sessionStorage.getItem('master_jobtype');
            console.log('jobtype_master == '+jobtype_master);
            if(jobtype_master == null || jobtype_master == ''){
                load_servicejobtypedata();
            }
            else {
                load_localjobtypedata();
            }
            var edu_master = sessionStorage.getItem('master_education');
            console.log('edu_master == '+edu_master);
            if(edu_master == null || edu_master == ''){
                load_serviceeducationdata();
            }
            else {
                load_localeducationdata();
            }
        });

        ////////
    }
    /// mobile filter services////////////////////////
    const ClearAll = () => {
        setIndustryRequestArray([]);
        setIndustryRequestArrayd([]);
        setLocationRequestArray([]);
        setLocationRequestArrayd([]);
        setJobTypeRequestArray([]);
        setJobTypeRequestArrayd([]);
        setEduTypeRequestArray([]);
        setEduTypeRequestArrayd([]);
        setoptionSelected("");
        setoptionSelectedd("");
        setoptionCatSelected("");
        setoptionCatSelectedd("");
        setMulLocationCountyOptionSelected("");
        setMulLocationCountyOptionSelectedd("");
        setoptionEduSelected("");
        setoptionEduSelectedd("");
        education_webvar = [];
        location_webvar = [];
        jobtype_webvar = [];
        industry_webvar = [];
        $('#industry_selected').val("");
        $('#location_selected').val("");
        $('#education_selected').val("");
        $('#jobtype_selected').val("");
        $('#search_text').val("");
        $('#industryLabel_selected').val("");
        $('#educationLabel_selected').val("");
        $('#jobtypeLabel_selected').val("");
        $('#locationLabel_selected').val("");


        $('#industry_selected_m').val("");
        $('#industryLabel_selected_m').val("");
        $('#education_selected_m').val("");
        $('#educationLabel_selected_m').val("");
        $('#jobtype_selected_m').val("");
        $('#jobtypeLabel_selected_m').val("");
        $('#location_selected_m').val("");
        $('#locationLabel_selected_m').val("");
        $('#search_textm').val("");

        searchInit(ITEMS_PER_PAGE, '1', '0');
        mobileFilterCall(ITEMS_PER_PAGE, '1', '0');
        //mobileFilterCall(ITEMS_PER_PAGE,"1");

    }
    const mobileFilterCall = (ITEMS_PER_PAGE, pageNumber, setlcstorage) => {
        // var searchkey = $('#search_textm').val().trim();

        var searchkey = $('#search_text').val().trim();
        var searchkey_m = $('#search_textm').val().trim();
console.log('mobile filter called');

 var industryRequestArray = [];
        var selected_ind = $('#industry_selected_m').val();
        console.log('420 ***** ' + selected_ind);
        if (selected_ind != '') {
            var industryRequestArray = JSON.parse(selected_ind);
        }

        var locationRequestArray = [];
        var selected_loc = $('#location_selected_m').val();
        if (selected_loc != '') {
            var locationRequestArray = JSON.parse(selected_loc);
        }

        var eduRequestArray = [];
        var selected_edu = $('#education_selected_m').val();
        if (selected_edu != '') {
            var eduRequestArray = JSON.parse(selected_edu);
        }

        var jobtypeRequestArray = [];
        var selected_types = $('#jobtype_selected_m').val();
        if (selected_types != '') {
            var jobtypeRequestArray = JSON.parse(selected_types);
        }

       
        //alert(localStorage.getItem('av_industry_selected'));



        if (setlcstorage == "1") {
            var industriesLabel_filter = '';
            var industries_val2 = '';
            var selected_indLabel = $('#industryLabel_selected').val();
            console.log('420 ***** ' + selected_indLabel);
            if (selected_indLabel != '') {
                industriesLabel_filter = JSON.parse(selected_indLabel);
            }

            var selected_val1 = $('#industry_selected').val();
            console.log('420 ***** ' + selected_indLabel);
            if (selected_val1 != '') {
                industries_val2 = JSON.parse(selected_val1);
            }

            var educationLabel_filter = '';
            var education_val2 = '';
            var selected_eduLabel = $('#educationLabel_selected').val();
            if (selected_eduLabel != '') {
                educationLabel_filter = JSON.parse(selected_eduLabel);
            }

            var selected_eduval1 = $('#education_selected').val();
            if (selected_eduval1 != '') {
                education_val2 = JSON.parse(selected_eduval1);
            }

            var locationLabel_filter = '';
            var location_val2 = '';
            var selected_locLabel = $('#locationLabel_selected').val();
            if (selected_locLabel != '') {
                locationLabel_filter = JSON.parse(selected_locLabel);
            }

            var selected_locval1 = $('#location_selected').val();
            if (selected_locval1 != '') {
                location_val2 = JSON.parse(selected_locval1);
            }

            var jobtypeLabel_filter = '';
            var jobtype_val2 = '';
            var selected_jobtypeLabel = $('#jobtypeLabel_selected').val();
            if (selected_jobtypeLabel != '') {
                jobtypeLabel_filter = JSON.parse(selected_jobtypeLabel);
            }

            var selected_jobtypeval1 = $('#jobtype_selected').val();
            if (selected_jobtypeval1 != '') {
                jobtype_val2 = JSON.parse(selected_jobtypeval1);
            }
            /*
            localStorage.setItem('av_industry_selected', industries_val2);
            localStorage.setItem('av_industry_selected_label', industriesLabel_filter);
            localStorage.setItem('av_education_selected', education_val2);
            localStorage.setItem('av_education_selected_label', educationLabel_filter);
            localStorage.setItem('av_location_selected', location_val2);
            localStorage.setItem('av_location_selected_label', locationLabel_filter);
            localStorage.setItem('av_jobtype_selected', jobtype_val2);
            localStorage.setItem('av_jobtype_selected_label', jobtypeLabel_filter);

            localStorage.setItem('av_search_text', searchkey);
            */


            var industriesLabel_filter_m = '';
            var industries_val2_m = '';
            var selected_indLabel_m = $('#industryLabel_selected_m').val();
            if (selected_indLabel_m != '') {
                industriesLabel_filter_m = JSON.parse(selected_indLabel_m);
            }

            var selected_val1_m = $('#industry_selected_m').val();
            if (selected_val1_m != '') {
                industries_val2_m = JSON.parse(selected_val1_m);
            }

            var educationLabel_filter_m = '';
            var education_val2_m = '';
            var selected_eduLabel_m = $('#educationLabel_selected_m').val();
            if (selected_eduLabel_m != '') {
                educationLabel_filter_m = JSON.parse(selected_eduLabel_m);
            }

            var selected_eduval1_m = $('#education_selected_m').val();
            if (selected_eduval1_m != '') {
                education_val2_m = JSON.parse(selected_eduval1_m);
            }

            var locationLabel_filter_m = '';
            var location_val2_m = '';
            var selected_locLabel_m = $('#locationLabel_selected_m').val();
            if (selected_locLabel_m != '') {
                locationLabel_filter_m = JSON.parse(selected_locLabel_m);
            }

            var selected_locval1_m = $('#location_selected_m').val();
            if (selected_locval1_m != '') {
                location_val2_m = JSON.parse(selected_locval1_m);
            }

            var jobtypeLabel_filter_m = '';
            var jobtype_val2_m = '';
            var selected_jobtypeLabel_m = $('#jobtypeLabel_selected_m').val();
            if (selected_jobtypeLabel_m != '') {
                jobtypeLabel_filter_m = JSON.parse(selected_jobtypeLabel_m);
            }

            var selected_jobtypeval1_m = $('#jobtype_selected_m').val();
            if (selected_jobtypeval1_m != '') {
                jobtype_val2_m = JSON.parse(selected_jobtypeval1_m);
            }

            // localStorage.setItem('av_industry_selected_m', industries_val2_m);
            // localStorage.setItem('av_industry_selected_label_m', industriesLabel_filter_m);
            // localStorage.setItem('av_education_selected_m', education_val2_m);
            // localStorage.setItem('av_education_selected_label_m', educationLabel_filter_m);
            // localStorage.setItem('av_location_selected_m', location_val2_m);
            // localStorage.setItem('av_location_selected_label_m', locationLabel_filter_m);
            // localStorage.setItem('av_jobtype_selected_m', jobtype_val2_m);
            // localStorage.setItem('av_jobtype_selected_label_m', jobtypeLabel_filter_m);
            // localStorage.setItem('av_search_text_m', searchkey_m);

            // sessionStorage.setItem('av_industry_selected_m', industries_val2_m);
            // sessionStorage.setItem('av_industry_selected_label_m', industriesLabel_filter_m);
            // sessionStorage.setItem('av_education_selected_m', education_val2_m);
            // sessionStorage.setItem('av_education_selected_label_m', educationLabel_filter_m);
            // sessionStorage.setItem('av_location_selected_m', location_val2_m);
            // sessionStorage.setItem('av_location_selected_label_m', locationLabel_filter_m);
            // sessionStorage.setItem('av_jobtype_selected_m', jobtype_val2_m);
            // sessionStorage.setItem('av_jobtype_selected_label_m', jobtypeLabel_filter_m);
            // sessionStorage.setItem('av_search_text_m', searchkey_m);

        }
        else {
            /*localStorage.setItem('av_search_text', '');
            localStorage.setItem('av_location_selected', '');
            localStorage.setItem('av_education_selected', '');
            localStorage.setItem('av_jobtype_selected', '');
            localStorage.setItem('av_industry_selected', ''); */
        }

            var industriesLabel_filter_m = '';
            var industries_val2_m = '';
            var selected_indLabel_m = $('#industryLabel_selected_m').val();
            if (selected_indLabel_m != '') {
                industriesLabel_filter_m = JSON.parse(selected_indLabel_m);
            }

            var selected_val1_m = $('#industry_selected_m').val();
            if (selected_val1_m != '') {
                industries_val2_m = JSON.parse(selected_val1_m);
            }

            var educationLabel_filter_m = '';
            var education_val2_m = '';
            var selected_eduLabel_m = $('#educationLabel_selected_m').val();
            if (selected_eduLabel_m != '') {
                educationLabel_filter_m = JSON.parse(selected_eduLabel_m);
            }

            var selected_eduval1_m = $('#education_selected_m').val();
            if (selected_eduval1_m != '') {
                education_val2_m = JSON.parse(selected_eduval1_m);
            }

            var locationLabel_filter_m = '';
            var location_val2_m = '';
            var selected_locLabel_m = $('#locationLabel_selected_m').val();
            if (selected_locLabel_m != '') {
                locationLabel_filter_m = JSON.parse(selected_locLabel_m);
            }

            var selected_locval1_m = $('#location_selected_m').val();
            if (selected_locval1_m != '') {
                location_val2_m = JSON.parse(selected_locval1_m);
            }

            var jobtypeLabel_filter_m = '';
            var jobtype_val2_m = '';
            var selected_jobtypeLabel_m = $('#jobtypeLabel_selected_m').val();
            if (selected_jobtypeLabel_m != '') {
                jobtypeLabel_filter_m = JSON.parse(selected_jobtypeLabel_m);
            }

            var selected_jobtypeval1_m = $('#jobtype_selected_m').val();
            if (selected_jobtypeval1_m != '') {
                jobtype_val2_m = JSON.parse(selected_jobtypeval1_m);
            }

            if(pageNumber > 1){
                var jsonData = {
                    "availableJobs": {
                      "industry": '',
                      "industry_label": '',
                      "education": '',
                      "education_label": '',
                      "location": '',
                      "location_label": '',
                      "job_type": '',
                      "job_type_label": '',
                      "search_text": '',
                      "pagination_val": '',
                      "industry_m": selected_val1_m,
                      "industry_label_m": selected_indLabel_m,
                      "education_m": selected_eduval1_m,
                      "education_label_m": selected_eduLabel_m,
                      "location_m": selected_locval1_m,
                      "location_label_m": selected_locLabel_m,
                      "job_type_m": selected_jobtypeval1_m,
                      "job_type_label_m": selected_jobtypeLabel_m,
                      "search_text_m": searchkey_m,
                      "pagination_val_m": pageNumber,
                    }
                  };
            } else {
                var jsonData = {
                    "availableJobs": {
                      "industry": '',
                      "industry_label": '',
                      "education": '',
                      "education_label": '',
                      "location": '',
                      "location_label": '',
                      "job_type": '',
                      "job_type_label": '',
                      "search_text": '',
                      "pagination_val": '',
                      "industry_m": selected_val1_m,
                      "industry_label_m": selected_indLabel_m,
                      "education_m": selected_eduval1_m,
                      "education_label_m": selected_eduLabel_m,
                      "location_m": selected_locval1_m,
                      "location_label_m": selected_locLabel_m,
                      "job_type_m": selected_jobtypeval1_m,
                      "job_type_label_m": selected_jobtypeLabel_m,
                      "search_text_m": searchkey_m,
                      "pagination_val_m": '',
                    }
                  };
            }

        

        var jsondataformatted = JSON.stringify(jsonData);
        console.log('1095 *** '+jsondataformatted);
        sessionStorage.setItem('pagejsonObject', jsondataformatted);

        const requestObject = {
            "searchTxt": searchkey_m,
            "jobCategory": industryRequestArray,
            "jobLocation": locationRequestArray,
            "jobEducation": eduRequestArray,
            "jobType": jobtypeRequestArray,
            "pageSize": ITEMS_PER_PAGE,
            "pageNumber": pageNumber,
            "sortBy": "jobId",
            "sortDir": "desc",
            "siteId": siteID

        }

        var accessTokenIn = sessionStorage.getItem("accToken");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn
                //'Authorization': accessTokenIn
            },
            body: JSON.stringify(requestObject)
        };
        $(".cards").show();
        Services.getAvailableJobs(requestOptions).then((res) => {
            //$("#availableJobsList_mobileResult").show();
            //$(".company-detail-wrap").show();
            if (res.content) {
                setAvailableJobsList_mobile(res.content);
                window.scrollTo({ top: 100, behavior: 'smooth' });

                // console.log("company jobs list@@@@@@@" + JSON.stringify(res.content));

                setTotalItems_m(res.totalElements);
                settotalPages_m(Math.ceil(res.totalElements / ITEMS_PER_PAGE));
                setCurrentPage_m(pageNumber);
                document.getElementById("closeMobilePopup").click();
                // setCurrentPage(pageNumber);
            }


            //    var dVal = eval(res);
            //    console.log(dVal);
            //    console.log(typeof(dVal));
            //   // console.log(dVal.length);
            //    console.log("142-->"+JSON.stringify(dVal));
            //    //console.log(res.data.length);
            //    if(typeof(dVal) == "object"&& dVal.length>0){

            //     console.log(JSON.stringify(res));
            //     setAvailableJobsList(res);

            //    }else{
            //     setAvailableJobsList([]);

            //    }




            //navigate('/thankyou');
        });
        $(".cards").hide();

        var ind_master = sessionStorage.getItem('master_industry');
        if(ind_master == null || ind_master == ''){
            load_serviceindustrydata();
        }
        else {
            load_localindustrydata();
        }
    
        var loc_master = sessionStorage.getItem('master_location');
        if(loc_master == null || loc_master == ''){
            load_servicelocationdata();
        }
        else {
            load_locallocationdata();
        }

        var jobtype_master = sessionStorage.getItem('master_jobtype');
        if(jobtype_master == null || jobtype_master == ''){
            load_servicejobtypedata();
        }
        else {
            load_localjobtypedata();
        }

        var edu_master = sessionStorage.getItem('master_education');
        if(edu_master == null || edu_master == ''){
            load_serviceeducationdata();
        }
        else {
            load_localeducationdata();
        }
    }

    function openModal() {
        //alert(rid);

        setIsOpen(true);
    }
    function afterOpenModal() {

        // references are now sync'd and can be accessed.
        //subtitle.style.color = '#f00';
    }
    function closeModal() {
        setIsOpen(false);
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '100%',
            maxWidth: '600px',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',

        },
    };

    useEffect(() => {
        $(".cards").hide();
        // Getting the keys of the JSON object
        var keys = sessionStorage.getItem('pagejsonObject');
        if(keys == null || keys == undefined){
            navigate('/Home');
        } else {
        var keys_parsed = JSON.parse(keys);
        var pageobject = Object.keys(keys_parsed);
        // Checking if there is at least one key
        if (pageobject.length > 0) {
            // Accessing the first key
            var firstKey = pageobject[0];
            if(firstKey == 'availableJobs'){
                var pageobj = keys_parsed[firstKey];
                session_pageobj = keys_parsed[firstKey];
                var dev_poff = pageobj['pagination_val'];
                var dev_poff_m = pageobj['pagination_val_m'];
                if(dev_poff == ''){
                    if(dev_poff_m == ''){
                        pagenum = '1';
                    }
                    else {
                        pagenum = dev_poff_m; 
                    } 
                }
                else{
                    pagenum = dev_poff; 
                }
            } else {
                pagenum = '1';
                sessionStorage.removeItem('master_industry');
                sessionStorage.removeItem('master_location');
                sessionStorage.removeItem('master_jobtype');
                sessionStorage.removeItem('master_education');
            }
        } else {
            //json empty
            pagenum = '1';
            sessionStorage.removeItem('master_industry');
            sessionStorage.removeItem('master_location');
            sessionStorage.removeItem('master_jobtype');
            sessionStorage.removeItem('master_education');
        }
    }

    console.log('*** 1265 pagenum = '+pagenum);
        if (pagenum == null || pagenum == '') {
            // localStorage.setItem('av_industry_selected', '');
            // localStorage.setItem('av_industry_selected_label', '');
            // localStorage.setItem('av_education_selected', '');
            // localStorage.setItem('av_education_selected_label', '');
            // localStorage.setItem('av_location_selected', '');
            // localStorage.setItem('av_location_selected_label', '');
            // localStorage.setItem('av_jobtype_selected', '');
            // localStorage.setItem('av_jobtype_selected_label', '');
            // localStorage.setItem('av_search_text', '');
            // localStorage.setItem('av_industry_selected_m', '');
            // localStorage.setItem('av_industry_selected_label_m', '');
            // localStorage.setItem('av_education_selected_m', '');
            // localStorage.setItem('av_education_selected_label_m', '');
            // localStorage.setItem('av_location_selected_m', '');
            // localStorage.setItem('av_location_selected_label_m', '');
            // localStorage.setItem('av_jobtype_selected_m', '');
            // localStorage.setItem('av_jobtype_selected_label_m', '');
            // localStorage.setItem('av_search_text_m', '');

            // sessionStorage.setItem('av_industry_selected', '');
            // sessionStorage.setItem('av_industry_selected_label', '');
            // sessionStorage.setItem('av_education_selected', '');
            // sessionStorage.setItem('av_education_selected_label', '');
            // sessionStorage.setItem('av_location_selected', '');
            // sessionStorage.setItem('av_location_selected_label', '');
            // sessionStorage.setItem('av_jobtype_selected', '');
            // sessionStorage.setItem('av_jobtype_selected_label', '');
            // sessionStorage.setItem('av_search_text', '');
            // sessionStorage.setItem('av_industry_selected_m', '');
            // sessionStorage.setItem('av_industry_selected_label_m', '');
            // sessionStorage.setItem('av_education_selected_m', '');
            // sessionStorage.setItem('av_education_selected_label_m', '');
            // sessionStorage.setItem('av_location_selected_m', '');
            // sessionStorage.setItem('av_location_selected_label_m', '');
            // sessionStorage.setItem('av_jobtype_selected_m', '');
            // sessionStorage.setItem('av_jobtype_selected_label_m', '');
            // sessionStorage.setItem('av_search_text_m', '');

            // sessionStorage.setItem('av_page_offset', '');
            // sessionStorage.setItem('av_page_offset_m', '');

            var web_searchtxt = session_pageobj['search_text'];
            var web_education = session_pageobj['education'];
            var web_industry = session_pageobj['industry'];
            var web_jobtype = session_pageobj['jobtype'];
            var web_location = session_pageobj['location'];
            if(web_searchtxt == '' && web_education == '' && web_industry == '' && web_jobtype == '' && web_location == ''){
                var webfilters = 0;
            }

            var mob_searchtxt = session_pageobj['search_text_m'];
            var mob_education = session_pageobj['education_m'];
            var mob_industry = session_pageobj['industry_m'];
            var mob_jobtype = session_pageobj['jobtype_m'];
            var mob_location = session_pageobj['location_m'];
            if(mob_searchtxt == '' && mob_education == '' && mob_industry == '' && mob_jobtype == '' && mob_location == ''){
                var mobfilters = 0;
            }

            if(webfilters == 0 && mobfilters == 0){
            var jsonData = {
                "availableJobs": {
                  "industry": '',
                  "industry_label": '',
                  "education": '',
                  "education_label": '',
                  "location": '',
                  "location_label": '',
                  "job_type": '',
                  "job_type_label": '',
                  "search_text": "",
                  "pagination_val": '',
                  "industry_m": '',
                  "industry_label_m": '',
                  "education_m": '',
                  "education_label_m": '',
                  "location_m": '',
                  "location_label_m": '',
                  "job_type_m": '',
                  "job_type_label_m": '',
                  "search_text_m": "",
                  "pagination_val_m": '',
                }
              };

            var jsondataformatted = JSON.stringify(jsonData);
            sessionStorage.setItem('pagejsonObject', jsondataformatted);
            }
        }

        $('#yourphone2').usPhoneFormat();
        $("#step1").show();
        $("#step2").hide();
        $("#step3").hide();
        $("#step4").hide();
        $("#step5").hide();


        var siteurlval = document.URL;
        var spliturl = siteurlval.split(/[/]/);
        var urllength = spliturl.length;
        setUrl(spliturl[urllength - 1]);

        //   Services.getLocationCounty().then((res) => {
        //     console.log("getLocationCounty data=" + JSON.stringify(res.data));
        //     setLocationCounty(res.data);
        //   });

        // load_serviceindustrydata();
        // load_serviceeducationdata();
        // load_servicejobtypedata();
        // load_servicelocationdata();
         
         //var t_av_search_text = localStorage.getItem('av_search_text');
         var t_av_search_text = session_pageobj['search_text'];
         $("#search_text").val(t_av_search_text);

         //var t_av_search_text_m = localStorage.getItem('av_search_text_m');
         var t_av_search_text_m = session_pageobj['search_text_m'];
         $("#search_textm").val(t_av_search_text_m);

        
load_selectedindustries();
load_selectededucations();
load_selectedlocations();
load_selectedjobtypes();
        
        
        if (pagenum != null &&  pagenum != '') {
            
            // var web_ind = localStorage.getItem('av_industry_selected');
            // var web_edu = localStorage.getItem('av_education_selected');
            // var web_loc = localStorage.getItem('av_location_selected');
            // var web_typ = localStorage.getItem('av_jobtype_selected');
            // var web_src = localStorage.getItem('av_search_text');

            var web_ind = session_pageobj['industry'];
            var web_edu = session_pageobj['education'];
            var web_loc = session_pageobj['location'];
            var web_typ = session_pageobj['job_type'];
            var web_src = session_pageobj['search_text'];
            var pageoffset = session_pageobj['pagination_val'];
            console.log(web_ind+'***'+web_edu+'***'+web_loc+'***'+web_typ+'***'+web_src+'***'+pageoffset);
            if (web_ind != "" || web_edu != '' || web_loc != '' || web_typ != '' || web_src != '' || pageoffset != ''){
                console.log('web');
                setTimeout(() => {
                    searchInit(ITEMS_PER_PAGE, pagenum, '0');
                //mobileFilterCall(ITEMS_PER_PAGE, pagenum, '0');
                }, 500); 
            }
            else {
                
                var mob_ind = session_pageobj['industry_m'];
                var mob_edu = session_pageobj['education_m'];
                var mob_loc = session_pageobj['location_m'];
                var mob_typ = session_pageobj['job_type_m'];
                var mob_src = session_pageobj['search_text_m'];
                var pageoffset_m = session_pageobj['pagination_val_m'];

                if (mob_ind != "" || mob_edu != '' || mob_loc != '' || mob_typ != '' || mob_src != '' || pageoffset_m != ''){
                    setTimeout(() => {
                        console.log('mob');
                        //searchInit(ITEMS_PER_PAGE, pagenum, '0');

                        mobileFilterCall(ITEMS_PER_PAGE, pagenum, '0');
                    }, 500); 
                }
                else {

                    searchInit(ITEMS_PER_PAGE, pagenum, '0');

                    mobileFilterCall(ITEMS_PER_PAGE, pagenum, '0');
                }
            }
        }
        else {
            searchInit(ITEMS_PER_PAGE, '1', '0');
            mobileFilterCall(ITEMS_PER_PAGE, '1', '0');
        }
 
        //localStorage.setItem('av_search_text', searchkey);
        //localStorage.setItem('av_location_selected', searchkey);
        //localStorage.setItem('av_education_selected', educations_filter);
        //localStorage.setItem('av_jobtype_selected', jobtypes_filter);

        try {
            var t_av_search_text = session_pageobj['search_text'];
            $("#search_text").val(t_av_search_text);
            var t_av_location_selected = session_pageobj['location'];
            var t_av_education_selected = session_pageobj['education'];
            var t_jobtype_selected = session_pageobj['job_type'];
            var t_av_industry_selected = session_pageobj['industry'];

            if (t_av_location_selected != '' || t_av_location_selected != null) {
                var t_av_location_selected_ar = t_av_location_selected.split(",");
                var t_av_education_selected_ar = t_av_education_selected.split(",");
                var t_jobtype_selected_ar = t_jobtype_selected.split(",");
                var t_av_industry_selected_ar = t_av_industry_selected.split(",");
            }

            console.log('selected - ' + t_av_industry_selected);
            setoptionEduSelected(t_av_education_selected_ar);
            // if(t_av_location_selected_ar.length>0){
            //     for(var k=0; k<t_av_location_selected_ar.length; k++)
            //     {

            //     }
            // }
            
        } catch (ex) {

        }

    }, []);

    function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

const cardsloop = [1,2,3,4,5];

const load_serviceindustrydata = () => {
    Services.getIndustryData().then((res) => {
        setCategory(res.data.content);
        var serviceresponse = res.data.content;
        var dummArray = [];
        for (var i = 0; i < res.data.content.length; i++) {
            dummArray.push({ "label": res.data.content[i].name, "value": res.data.content[i].catId })
        }
        setMulCategory(dummArray);
        setMulCategory_d(dummArray);
        var storeval = JSON.stringify(serviceresponse);
        sessionStorage.setItem('master_industry', storeval);
    });
    load_selectedindustries();
}

const load_localindustrydata = () => {
    var localindustryval = sessionStorage.getItem('master_industry');
    localindustryval = JSON.parse(localindustryval);
    var dummArray = [];
    for (var i = 0; i < localindustryval.length; i++) {
        dummArray.push({ "label": localindustryval[i].name, "value": localindustryval[i].catId })
    }
    setMulCategory(dummArray);
    setMulCategory_d(dummArray);
    load_selectedindustries();
}

const load_serviceeducationdata = () => {
    Services.getEducation().then((res) => {
        //console.log("getEducation data=" + JSON.stringify(res.data));
        setEducations(res.data.content);
        var serviceresponse = res.data.content;
        var dummArray = [];
        for (var i = 0; i < res.data.content.length; i++) {
            dummArray.push({ "label": res.data.content[i].name, "value": res.data.content[i].educationId })
        }
        setMulEducationTypes(dummArray);
        var storeeduval = JSON.stringify(serviceresponse);
        sessionStorage.setItem('master_education', storeeduval); 
    });
    load_selectededucations();
}

const load_localeducationdata = () => {
    var localeducationval = sessionStorage.getItem('master_education');
    localeducationval = JSON.parse(localeducationval);
    var dummArray = [];
    for (var i = 0; i < localeducationval.length; i++) {
        dummArray.push({ "label": localeducationval[i].name, "value": localeducationval[i].educationId })
    }
    setMulEducationTypes(dummArray);
    load_selectededucations();
}

const load_servicejobtypedata = () => {
    Services.getJobType().then((res) => {
        var jobtype_unsort = res.data.content;
        var serviceresponse = res.data.content;
        var jobtypes_sorted = jobtype_unsort.sort((a, b) => b.jobTypeId - a.jobTypeId);
      array_move(jobtypes_sorted, 0, (jobtypes_sorted.length-1));
      jobtypes_sorted = jobtypes_sorted.filter(function (elem) {
        return elem.name !== 'Continuing Education';
      });
       // setJobTypes(res.data.content);
        setJobTypes(jobtypes_sorted);
        var dummArray = [];
       /* for (var i = 0; i < res.data.content.length; i++) {
            dummArray.push({ "label": res.data.content[i].name, "value": res.data.content[i].jobTypeId })
        } */
        for (var i = 0; i < jobtypes_sorted.length; i++) {
            var jobtypename = jobtypes_sorted[i].name;
           var typename = jobtypename.split("(");
            dummArray.push({ "label": typename[0], "value": jobtypes_sorted[i].jobTypeId })
        } 
        dummArray.reverse();
        setMulJobTypes(dummArray);
        var storetypeval = JSON.stringify(serviceresponse);
        sessionStorage.setItem('master_jobtype', storetypeval);  
    });
    load_selectedjobtypes();
}

const load_localjobtypedata = () => {
    var localjobtypeval = sessionStorage.getItem('master_jobtype');
    localjobtypeval = JSON.parse(localjobtypeval);
    var jobtypes_sorted = localjobtypeval.sort((a, b) => b.jobTypeId - a.jobTypeId);
    array_move(jobtypes_sorted, 0, (jobtypes_sorted.length-1));
    jobtypes_sorted = jobtypes_sorted.filter(function (elem) {
    return elem.name !== 'Continuing Education';
    });
    setJobTypes(jobtypes_sorted);
    console.log(jobtypes_sorted);
    var dummArray = [];
    for (var i = 0; i < jobtypes_sorted.length; i++) {
        var jobtypename = jobtypes_sorted[i].name;
        var typename = [];
        if(jobtypename != undefined || jobtypename != null){
            var typename = jobtypename.split("(");
        }
        dummArray.push({ "label": typename[0], "value": jobtypes_sorted[i].jobTypeId })
    } 
    dummArray.reverse();
    setMulJobTypes(dummArray);
    load_selectedjobtypes();
}

const load_servicelocationdata = () => {
    Services.getLocationCounty().then((res) => {
        setLocationCounty(res.data.content);
        var serviceresponse = res.data.content;
        // alert(res.data.content.length)
        var dummArray = [];
        for (var i = 0; i < res.data.content.length; i++) {
            dummArray.push({ "label": res.data.content[i].name, "value": res.data.content[i].jobLocationCountyId })
        }
        setMulLocationCounty(dummArray);
        var storelocval = JSON.stringify(serviceresponse);
        sessionStorage.setItem('master_location', storelocval);
    });
    load_selectedlocations();
}

const load_locallocationdata = () => {
    var locallocationval = sessionStorage.getItem('master_location');
    locallocationval = JSON.parse(locallocationval);
    var dummArray = [];
    for (var i = 0; i < locallocationval.length; i++) {
        dummArray.push({ "label": locallocationval[i].name, "value": locallocationval[i].jobLocationCountyId })
    }
    setMulLocationCounty(dummArray);
    load_selectedlocations();
}

const load_selectedindustries = () => {
    var loc_indid = session_pageobj['industry'];
        var loc_indlabel = session_pageobj['industry_label'];
        
        if (loc_indid != undefined && loc_indid != null && loc_indid != "") {
            var loc_indids = loc_indid.toString();
            var loc_indid1 = loc_indids.split(',');

            var loc_indlabels = loc_indlabel.toString();
            var loc_indlabel1 = loc_indlabels.split(',');

            var industry_locstore = [];
            var temparray_ind = [];
            var temparray_ind_l = [];
            for (var o = 0; o < loc_indid1.length; o++) {
                var temp_object = {
                    "label": loc_indlabel1[o],
                    "value": loc_indid1[o]
                };

               // console.log(temp_object);

                temparray_ind.push(loc_indid1[o]);
                temparray_ind_l.push(loc_indlabel1[o]);
                industry_locstore.push(temp_object);
                console.log(industry_locstore);
                
            }
            setoptionCatSelectedd(industry_locstore);
                $('#industry_selected').val(JSON.stringify(temparray_ind));
                $('#industryLabel_selected').val(JSON.stringify(temparray_ind_l));
            //searchInit(ITEMS_PER_PAGE, pagenum, '0');
        } 

        // var loc_indid_m = localStorage.getItem('av_industry_selected_m');
        // var loc_indlabel_m = localStorage.getItem('av_industry_selected_label_m');
        var loc_indid_m = session_pageobj['industry_m'];
        var loc_indlabel_m = session_pageobj['industry_label_m'];

        if (loc_indid_m != undefined && loc_indid_m != null && loc_indid_m != "") {
            var loc_indids_m = loc_indid_m.toString();
            var loc_indid1_m = loc_indids_m.split(',');

            var loc_indlabels_m = loc_indlabel_m.toString();
            var loc_indlabel1_m = loc_indlabels_m.split(',');

            var industry_locstore_m = [];
            var temparray_ind_m = [];
            var temparray_indlab_m = [];
            for (var o = 0; o < loc_indid1_m.length; o++) {
                var temp_object_m = {
                    "label": loc_indlabel1_m[o],
                    "value": loc_indid1_m[o]
                };
                temparray_ind_m.push(loc_indid1_m[o]);
                temparray_indlab_m.push(loc_indlabel1_m[o]);
                industry_locstore_m.push(temp_object_m);

                
            }
            setoptionCatSelected(industry_locstore_m);
                $('#industry_selected_m').val(JSON.stringify(temparray_ind_m));
                $('#industryLabel_selected_m').val(JSON.stringify(temparray_indlab_m));
            //mobileFilterCall(ITEMS_PER_PAGE, pagenum, '0');
        }
}
    
const load_selectededucations = () => {
    // var loc_eduid = localStorage.getItem('av_education_selected');
        // var loc_edulabel = localStorage.getItem('av_education_selected_label');
        var loc_eduid = session_pageobj['education'];
        var loc_edulabel = session_pageobj['education_label'];

        if (loc_eduid != undefined && loc_eduid != null && loc_eduid != "") {
            var loc_eduids = loc_eduid.toString();
            var loc_eduid1 = loc_eduids.split(',');

            var loc_edulabels = loc_edulabel.toString();
            var loc_edulabel1 = loc_edulabels.split(',');

            var education_locstore = [];
            var temparray_edu = [];
            var temparray_edulabel = [];
            for (var e = 0; e < loc_eduid1.length; e++) {
                var temp_object_edu = {
                    "label": loc_edulabel1[e],
                    "value": loc_eduid1[e]
                };
                temparray_edu.push(loc_eduid1[e]);
                temparray_edulabel.push(loc_edulabel1[e]);
                education_locstore.push(temp_object_edu);

                
            }
            setoptionEduSelectedd(education_locstore);
                $('#education_selected').val(JSON.stringify(temparray_edu));
                $('#educationLabel_selected').val(JSON.stringify(temparray_edulabel));
            //searchInit(ITEMS_PER_PAGE, pagenum, '0');
        }

        // var loc_eduid_m = localStorage.getItem('av_education_selected_m');
        // var loc_edulabel_m = localStorage.getItem('av_education_selected_label_m');
        var loc_eduid_m = session_pageobj['education_m'];
        var loc_edulabel_m = session_pageobj['education_label_m'];

        if (loc_eduid_m != undefined && loc_eduid_m != null && loc_eduid_m != "") {
            var loc_eduids_m = loc_eduid_m.toString();
            var loc_eduid1_m = loc_eduids_m.split(',');

            var loc_edulabels_m = loc_edulabel_m.toString();
            var loc_edulabel1_m = loc_edulabels_m.split(',');

            var education_locstore_m = [];
            var temparray_edu_m = [];
            var temparray_edulabel_m = [];
            for (var e = 0; e < loc_eduid1_m.length; e++) {
                var temp_object_edu_m = {
                    "label": loc_edulabel1_m[e],
                    "value": loc_eduid1_m[e]
                };
                temparray_edu_m.push(loc_eduid1_m[e]);
                temparray_edulabel_m.push(loc_edulabel1_m[e]);
                education_locstore_m.push(temp_object_edu_m);

            }
            setoptionEduSelecteddm(education_locstore_m);
                $('#education_selected_m').val(JSON.stringify(temparray_edu_m));
                $('#educationLabel_selected_m').val(JSON.stringify(temparray_edulabel_m));
            //mobileFilterCall(ITEMS_PER_PAGE, pagenum, '0');
        }
}
   
const load_selectedlocations = () => {
    // var loc_locid = localStorage.getItem('av_location_selected');
        // var loc_loclabel = localStorage.getItem('av_location_selected_label');
        var loc_locid = session_pageobj['location'];
        var loc_loclabel = session_pageobj['location_label'];

        if (loc_locid != undefined && loc_locid != null && loc_locid != "") {
            var loc_locids = loc_locid.toString();
            var loc_locid1 = loc_locids.split(',');

            var loc_loclabels = loc_loclabel.toString();
            var loc_loclabel1 = loc_loclabels.split(',');

            var location_locstore = [];
            var temparray_loc = [];
            var temparray_loclabel = [];
            for (var l = 0; l < loc_locid1.length; l++) {
                var temp_object_loc = {
                    "label": loc_loclabel1[l],
                    "value": loc_locid1[l]
                };
                temparray_loc.push(loc_locid1[l]);
                temparray_loclabel.push(loc_loclabel1[l]);
                location_locstore.push(temp_object_loc);

                
            }
            setMulLocationCountyOptionSelectedd(location_locstore);
                $('#location_selected').val(JSON.stringify(temparray_loc));
                $('#locationLabel_selected').val(JSON.stringify(temparray_loclabel));
            //searchInit(ITEMS_PER_PAGE, pagenum, '0');
        }

        // var loc_locid_m = localStorage.getItem('av_location_selected_m');
        // var loc_loclabel_m = localStorage.getItem('av_location_selected_label_m');
        var loc_locid_m = session_pageobj['location_m'];
        var loc_loclabel_m = session_pageobj['location_label_m'];

        if (loc_locid_m != undefined && loc_locid_m != null && loc_locid_m != "") {
            var loc_locids_m = loc_locid_m.toString();
            var loc_locid1_m = loc_locids_m.split(',');

            var loc_loclabels_m = loc_loclabel_m.toString();
            var loc_loclabel1_m = loc_loclabels_m.split(',');

            var location_locstore_m = [];
            var temparray_loc_m = [];
            var temparray_loclabel_m = [];
            for (var l = 0; l < loc_locid1_m.length; l++) {
                var temp_object_loc_m = {
                    "label": loc_loclabel1_m[l],
                    "value": loc_locid1_m[l]
                };
                temparray_loc_m.push(loc_locid1_m[l]);
                temparray_loclabel_m.push(loc_loclabel1_m[l]);
                location_locstore_m.push(temp_object_loc_m);

                
            }
            setMulLocationCountyOptionSelected(location_locstore_m);
                $('#location_selected_m').val(JSON.stringify(temparray_loc_m));
                $('#locationLabel_selected_m').val(JSON.stringify(temparray_loclabel_m));
            //mobileFilterCall(ITEMS_PER_PAGE, pagenum, '0');
        }

}

const load_selectedjobtypes = () => {
    // var loc_jobtypeid = localStorage.getItem('av_jobtype_selected');
        // var loc_jobtypelabel = localStorage.getItem('av_jobtype_selected_label');
        var loc_jobtypeid = session_pageobj['job_type'];
        var loc_jobtypelabel = session_pageobj['job_type_label'];

        if (loc_jobtypeid != undefined && loc_jobtypeid != null && loc_jobtypeid != "") {
            var loc_jobtypeids = loc_jobtypeid.toString();
            var loc_jobtypeid1 = loc_jobtypeids.split(',');

            var loc_jobtypelabels = loc_jobtypelabel.toString();
            var loc_jobtypelabel1 = loc_jobtypelabels.split(',');

            var jobtype_locstore = [];
            var temparray_jobtype = [];
            var temparray_jobtypelabel = [];
            for (var j = 0; j < loc_jobtypeid1.length; j++) {
                var temp_object_jobtype = {
                    "label": loc_jobtypelabel1[j],
                    "value": loc_jobtypeid1[j]
                };
                temparray_jobtype.push(loc_jobtypeid1[j]);
                temparray_jobtypelabel.push(loc_jobtypelabel1[j]);
                jobtype_locstore.push(temp_object_jobtype);

                
            }
            setoptionSelectedd(jobtype_locstore);
                $('#jobtype_selected').val(JSON.stringify(temparray_jobtype));
                $('#jobtypeLabel_selected').val(JSON.stringify(temparray_jobtypelabel));
           // searchInit(ITEMS_PER_PAGE, pagenum, '0');
        }


        // var loc_jobtypeid_m = localStorage.getItem('av_jobtype_selected_m');
        // var loc_jobtypelabel_m = localStorage.getItem('av_jobtype_selected_label_m');
        var loc_jobtypeid_m = session_pageobj['job_type_m'];
        var loc_jobtypelabel_m = session_pageobj['job_type_label_m'];

        if (loc_jobtypeid_m != undefined && loc_jobtypeid_m != null && loc_jobtypeid_m != "") {
            var loc_jobtypeids_m = loc_jobtypeid_m.toString();
            var loc_jobtypeid1_m = loc_jobtypeids_m.split(',');

            var loc_jobtypelabels_m = loc_jobtypelabel_m.toString();
            var loc_jobtypelabel1_m = loc_jobtypelabels_m.split(',');

            var jobtype_locstore_m = [];
            var temparray_jobtype_m = [];
            var temparray_jobtypelabel_m = [];
            for (var j = 0; j < loc_jobtypeid1_m.length; j++) {
                var temp_object_jobtype_m = {
                    "label": loc_jobtypelabel1_m[j],
                    "value": loc_jobtypeid1_m[j]
                };
                temparray_jobtype_m.push(loc_jobtypeid1_m[j]);
                temparray_jobtypelabel_m.push(loc_jobtypelabel1_m[j]);
                jobtype_locstore_m.push(temp_object_jobtype_m); 
            }
            setoptionSelected(jobtype_locstore_m);
                $('#jobtype_selected_m').val(JSON.stringify(temparray_jobtype_m));
                $('#jobtypeLabel_selected_m').val(JSON.stringify(temparray_jobtypelabel_m));
            //mobileFilterCall(ITEMS_PER_PAGE, pagenum, '0');
        }

}
return (
        <>
            <main class="empr-directory-main cd-desktop">

                <div class="main-bgpatten"></div>
                <section class="headtitle-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <nav style={{ "--bs-breadcrumb-divider": '>' }} aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item headtitle "><a href="#">Available Jobs</a></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="py-5 cd-desktop">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="white-bg p-5">
                                    <div class="row mb-3">
                                        <div class="col-lg-12 m-auto">
                                            <h1 class="text-center ">

                                                There are   <span class=" red-text">so many opportunities </span>for local careers!
                                            </h1>
                                            <div class="col-12 m-auto">
                                                <hr />
                                            </div>
                                        </div>
                                        <div class="col-lg-12 m-auto text-left">
                                            <p>
                                                To find a great new job with one of the many amazing local employers in your area, simply select your industry, location and experience level below, as well as whether you’d like part-time, full-time, seasonal work or a full time career. Your matches will automatically appear in the box below.
                                            </p>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-4 mb-4">
                                            <div class="card p-3 mb-3 ">
                                                <div class="bg-heading">
                                                    <h3 class="mb-0">Filter Jobs</h3>
                                                </div>
                                                <div class="py-3 text-start">
                                                    <b class="text-uppercase">Keywords</b>
                                                    <form onSubmit={handleSubmit(submitValue)} class=" search-bar my-2 w-100">
                                                        <input class="form-control me-2" type="search" aria-label="Search" id="search_text" name="search_text" placeholder="Search by Company / Job Title / Summary" />
                                                        <button class="border-0 p-0 dir-search" type="submit" onClick={() => { searchInit(ITEMS_PER_PAGE, "1", "1") }}><img src="images/search-icon.JPG" alt="" /></button>
                                                    </form>
                                                </div>


                                                <div class="mb-4">
                                                    <label for="firstName" class="form-label">industry</label>
                                                    {/* <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select industry</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select> */}
                                                    <ReactSelect
                                                        class="form-select"
                                                        options={mulcategories_d}
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{
                                                            Option
                                                        }}
                                                        onChange={handleChangeForCate_d}
                                                        allowSelectAll={false}
                                                        value={optionCatSelected_d}
                                                        defaultValue={['1']}
                                                    />
                                                </div>

                                                <div class="col-12 mb-4">
                                                    <label for="firstName" class="form-label">Location</label>
                                                    {/* <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select industry</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select> */}
                                                    <ReactSelect
                                                        class="form-select"
                                                        options={mullocationCounty}
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{
                                                            Option
                                                        }}
                                                        onChange={handleChangeForLocation_d}
                                                        allowSelectAll={false}
                                                        value={mullocationCountyselected_d}
                                                    />
                                                </div>
                                                <div class="col-12 mb-4">
                                                    <label for="firstName" class="form-label">Job Type</label>
                                                    {/* <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select Job Type</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select> */}
                                                    <ReactSelect
                                                        class="form-select"
                                                        options={muljobTypes}
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{
                                                            Option
                                                        }}
                                                        onChange={handleChange_d}
                                                        allowSelectAll={true}
                                                        value={optionSelected_d}
                                                    />
                                                </div>

                                                <div class="col-12 mb-4">
                                                    <label for="firstName" class="form-label">Education</label>
                                                    <ReactSelect
                                                        class="form-select"
                                                        options={muleducation}
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{
                                                            Option
                                                        }}
                                                        onChange={handleChangeForEdu_d}
                                                        allowSelectAll={true}
                                                        value={optionEduSelected_d}
                                                    />
                                                    {/* <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select Education</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select> */}
                                                </div>

                                                {/*
                                                <div class="d-flex justify-content-center pb-5">
                                                <button type="button" class="btn btn-secondary text-uppercase mx-2"  onClick={()=>{ClearAll()}}>Reset</button>
                                                <button type="button" class="btn btn-primary text-uppercase" onClick={()=>{mobileFilterCall(ITEMS_PER_PAGE,"1")}}>Filter</button>
                                            </div> */}
                                                <input type="hidden" id="industry_selected" />
                                                <input type="hidden" id="industryLabel_selected" />
                                                <input type="hidden" id="education_selected" />
                                                <input type="hidden" id="educationLabel_selected" />
                                                <input type="hidden" id="jobtype_selected" />
                                                <input type="hidden" id="jobtypeLabel_selected" />
                                                <input type="hidden" id="location_selected" />
                                                <input type="hidden" id="locationLabel_selected" />

                                                <div class="d-flex">
                                                    <button type="button" class="btn btn-secondary text-uppercase  mb-2" onClick={() => { ClearAll() }}>Reset</button>
                                                    <button type="button" class="btn btn-primary text-uppercase mx-2 mb-2" onClick={() => { searchInit(ITEMS_PER_PAGE, "1", "1") }}>Filter</button>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="col-lg-8">
                                        {availableJobsList.length > 0 ? 
                                            <div class="border-top border-bottom py-2 d-flex justify-content-between align-items-center mb-4">
                                                <span class="float-end ">
                                                    {totalItems} Careers - Page {currentPage} of {totalpages}
                                                </span>
                                                <nav aria-label="..." >
                                                    <ul class="pagination m-auto d-inline-flex">
                                                        <Pagination
                                                            total={totalItems}
                                                            itemsPerPage={ITEMS_PER_PAGE}
                                                            currentPage={currentPage}
                                                            onPageChange={handlePageChange2} />
                                                    </ul>
                                                </nav>
                                            </div> : ''
                                        }
                                        

                                            {
                                                cardsloop.map((data, inde) => (
                                                <div class="cards py-3 border mb-3">
                                                    <div class="card-ld is-loading">
                                                        <div class="image"></div>
                                                        <div class="content">

                                                            <h2></h2>
                                                            <div class="spanl">
                                                                <span></span> <span></span>
                                                                <span class="lastspan"></span>
                                                            </div>
                                                            <p></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            
                                            {
                                                availableJobsList.map((data, index) => (

                                                    <div class="avl-job p-3 border mb-3">
                                                        <div class="avl-logo">
                                                            {(data.logoUrl == '' || data.logoUrl == null) ?
                                                                <img src="https://snv3-dev-fs.s3.us-east-2.amazonaws.com/HometownMigration/uploads/default-logo.png" alt="" /> :
                                                                <img src={data.logoUrl} alt="" />

                                                            }
                                                        </div>
                                                        <div class="pt-4 position-relative">
                                                            <a href="#" style={{ position: "absolute", top: "15px", right: "0px" }} class="tool" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="This company provides job shadow opportunities">
                                                                {data.jobShadowConcert == 'Y' ? <div style={{ width: "55px" }}> <img src="images/badge.png" alt="" class="w-100" /></div> : ''}
                                                            </a>
                                                            {/* <h4><a href={`/jobsummary?jobId=${data.jobId}&page=`+currentPage} class="company-name">
                                                {`${data.title.substring(0, 40)}`}{data.title.length>40?"...":""}
                                                     </a></h4> */}


                                                            <h4><Link to={`/jobsummary?jobId=${data.jobId}&page=` + currentPage} class="company-name">
                                                                {`${data.title.substring(0, 40)}`}{data.title.length > 40 ? "..." : ""}
                                                            </Link></h4>





                                                            <div class="d-flex justify-content-between align-self-center avl-ft-14">
                                                                <div class="d-flex align-self-center"><span class="text-underline font-light avl-lmt"><a href={`/companyDetails?companyId=${data.companyId}&page=` + currentPage}>{data.companyName ? data.companyName : ""}</a>

                                                                    {/*<div class="js-badge">*/}

                                                                    {/*</div>*/}


                                                                </span><span class="mx-3">|</span> {data.city != '' ? data.city : ''}{data.city != '' && data.state != '' ? ', ' : ''}{data.state != '' ? data.state : ''}</div>
                                                                <div class="w-25"><small><b>{moment(data.createdDate).format("MM/DD/YYYY")}</b></small></div>
                                                            </div>
                                                            <div>

                                                                <p class="mt-3 list-mb" dangerouslySetInnerHTML={{ __html: data.summary.substring(0, 150) + "...  <a style='font-weight:normal;text-decoration:underline;' href='/jobsummary?jobId=" + data.jobId + "&page=" + currentPage + "'>View more</a>" }} />

                                                            </div>
                                                            <hr></hr>
                                                            <div class="d-flex justify-content-between align-items-center py-2">
                                                            
                                                                <div class="perks-avl d-flex align-items-center">
                                                                    {data.perksList.length > 0 ? <span class="mr-2"><small><b>PERKS</b></small></span> : '' }
                                                                    
                                                                    <div class="perks-avl-icon">

                                                                        {
                                                                            data.perksList.map((datap, index) => (
                                                                                <div> <img src={datap.perksImageUrl} alt="" /></div>
                                                                            ))}
                                                                    </div>

                                                                

                                                                </div>
                                                                 
                                                                <div class="d-flex align-items-center">
                                                                    <div>
                                                                        <Link to={`/jobsummary?jobId=${data.jobId}&page=` + currentPage} class="reg-button d-block">View JOB</Link>

                                                                    </div>

                                                                    <div class="btn-group avls mx-3">
                                                                        <button class="thrdicon tool" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-toggle="tooltip" data-placement="top" title="Share a job">
                                                                            &#8942;
                                                                        </button>
                                                                        <ul class="dropdown-menu ">
                                                                            {/* 
                                                            <li className="px-2 py-1"><a id="fbsharebtn" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.React_App_FRONT_URL}jobsummary?jobId=${data.jobId}&amp;src=sdkpreparse`} data-mobile-iframe="true"><img src="images/FACEBOOK 2.png" width={"32px"} alt="facebook"/><span class="px-3">Facebook</span></a></li>
                                                            <li className="px-2 py-1"><a class="popup" target="_blank" href={`https://twitter.com/share?text=${data.title}&url=${process.env.React_App_FRONT_URL}jobsummary?jobId=${data.jobId}`}><img src="images/TWITTER 2.png" width={"32px"} alt="twitter"/><span class="px-3">Twitter</span></a></li>
                                                            <li className="px-2 py-1"><a class="popup" target="_blank" href={`https://www.linkedin.com/shareArticle?mini=true&url=${process.env.React_App_FRONT_URL}/jobsummary?jobId=${data.jobId}&title=${data.title}`}><img src="images/LINKEDIN 2.png" width={"32px"} alt="linkedin"/><span class="px-3">Linkedin</span></a></li> */}


                                                                            <li className="px-2 py-1"><a id="fbsharebtn" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(process.env.React_App_FRONT_URL + "jobsummary?jobId=" + data.jobId + "&title=" + data.title)}&amp;src=sdkpreparse`} data-mobile-iframe="true"><img src="images/FACEBOOK 2.png" width={"32px"} alt="facebook" /><span class="px-3">Facebook</span></a></li>
                                                                            {/* <li className="px-2 py-1"><a class="popup" target="_blank" href={`https://twitter.com/share?text=${data.title}&url=` + encodeURIComponent(process.env.React_App_FRONT_URL + "jobsummary?jobId=" + data.jobId + "&title=" + data.title)}><img src="images/TWITTER 2.png" width={"32px"} alt="twitter" /><span class="px-3">Twitter</span></a></li> */}
                                                                            <li className="px-2 py-1"><a class="popup" target="_blank" href={`https://twitter.com/intent/tweet?text=${data.title}&url=` + encodeURIComponent(process.env.React_App_FRONT_URL + "jobsummary?jobId=" + data.jobId + "&title=" + data.title)}><img src="images/TWITTER 2.png" width={"32px"} alt="twitter" /><span class="px-3">Twitter</span></a></li>
                                                                            <li className="px-2 py-1"><a class="popup" target="_blank" href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(process.env.React_App_FRONT_URL + "jobsummary?jobId=" + data.jobId + "&title=" + data.title)}`}><img src="images/LINKEDIN 2.png" width={"32px"} alt="linkedin" /><span class="px-3">Linkedin</span></a></li>
                                                                            <li className="px-2 py-1"><a target="_blank" href={`mailto:?subject=Job:%20${data.title}&body=${encodeURIComponent(process.env.React_App_FRONT_URL + "jobsummary?jobId=" + data.jobId + "&title=" + data.title)}`}><img src="images/EMAIL 2.png" width={"32px"} alt="mail"/><span class="px-3">Email</span></a></li>

                                                                        </ul>
                                                                    </div>
                                                                </div>




                                                                {/* <div >
  <div class="avls">Share</div>
  <div class="social twitter"><a href="https://twitter.com/sa_sha26" target="_blank"><i class="fa fa-twitter"></i></a></div>
  <div class="social facebook"><i class="fa fa-facebook"></i></div>
  <div class=" social google"><i class="fa fa-google-plus"></i></div>
  <div class="social youtube"><a href="https://www.youtube.com/channel/UCCATAa8MWoBuH-sR_Jlx29A" target="_blank"><i class="fa fa-youtube"></i></a></div>
</div> */}

                                                            </div>
                                                        </div>

                                                    </div>



                                                ))}


                                            {/*
                                    <div class="avl-job p-3 border mb-4">
                                        <div class="avl-logo">
                                            <img src="images/midmark-logo.JPG" alt="" />
                                        </div>
                                        <div class="pt-4">
                                            <h4><a href="#">Summer Production Help</a></h4>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div><span class="text-underline font-light"><a href="#">Celina City Schools</a></span> <span class="px-2">|</span> <span>OH</span></div>
                                                <div><small><b>MAY 28, 2022</b></small></div>
                                            </div>
                                            <div>
                                                <p class="mt-3">
                                                    CELINA TENT, INC is hiring Summer Production Help in our Celina, OH facility during the summer months on our 1st shift. We’re ... View more
                                                </p>
                                            </div>
                                            <hr></hr>
                                            <div class="d-flex justify-content-between align-items-center py-2">
                                                <div class="perks-avl d-flex">
                                                    <span class="mr-2"><small><b>PERKS</b></small></span>
                                                    <div class="d-flex">
                                                        <div> <img src="images/health.png" alt="" /></div>
                                                        <div> <img src="images/lunch-plate.png" alt="" /></div>
                                                        <div> <img src="images/dental.png" alt="" /></div>
                                                        <div> <img src="images/cofee.png" alt="" /></div>
                                                        <div> <img src="images/cofee.png" alt="" /></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <a href="#" class="reg-button">View JOB</a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="avl-job p-3 border mb-4">
                                        <div class="avl-logo">
                                            <img src="images/midmark-logo.JPG" alt="" />
                                        </div>
                                        <div class="pt-4">
                                            <h4><a href="#">Summer Production Help</a></h4>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div><span class="text-underline font-light"><a href="#">Celina City Schools</a></span> <span class="px-2">|</span> <span>OH</span></div>
                                                <div><small><b>MAY 28, 2022</b></small></div>
                                            </div>
                                            <div>
                                                <p class="mt-3">
                                                    CELINA TENT, INC is hiring Summer Production Help in our Celina, OH facility during the summer months on our 1st shift. We’re ... View more
                                                </p>
                                            </div>
                                            <hr></hr>
                                            <div class="d-flex justify-content-between align-items-center py-2">
                                                <div class="perks-avl d-flex">
                                                    <span class="mr-2"><small><b>PERKS</b></small></span>
                                                    <div class="d-flex">
                                                        <div> <img src="images/health.png" alt="" /></div>
                                                        <div> <img src="images/lunch-plate.png" alt="" /></div>
                                                        <div> <img src="images/dental.png" alt="" /></div>
                                                        <div> <img src="images/cofee.png" alt="" /></div>
                                                        <div> <img src="images/cofee.png" alt="" /></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <a href="#" class="reg-button">View JOB</a>
                                                </div>
                                            </div>
                                        </div>

                                    </div> */}


                                            <div class="pagination-wrap text-center mt-5">
                                                <nav aria-label="...">




                                                    <ul class="pagination m-auto d-inline-flex">
                                                        <Pagination
                                                            total={totalItems}
                                                            itemsPerPage={ITEMS_PER_PAGE}
                                                            currentPage={currentPage}
                                                            onPageChange={handlePageChange2} />
                                                    </ul>
                                                </nav>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                


                {/* <!-- info typography --> */}
                {/*
                <section class="company-detail-wrap py-5 ">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="white-bg px-4 pt-4 pb-4">
                                    <div class="row mb-3">
                                        <div class="col-lg-12 m-auto">
                                            <h1 class="text-center ">

                                                There are   <span class=" red-text">so many opportunities </span>for local careers!
                                            </h1>
                                            <div class="col-12 m-auto">
                                                <hr />
                                            </div>
                                        </div>
                                        <div class="col-lg-12 m-auto text-left">
                                            <p>
                                                To find a great new job with one of the many amazing local employers in your area, simply select your industry, location and experience level below, as well as whether you’d like part-time, full-time, seasonal work or a full time career. Your matches will automatically appear in the box below.
                                            </p>
                                        </div>
                                    </div>

                                    {/* <!-- Your Industry -->
                                    <div class="col-lg-12 mb-4">
                                        <div class="d-flex"><label for="firstName" class=" headtitle">Your Industry </label></div>
                                        <div class="my-2">
                                            <hr />
                                        </div>
                                        <div>
                                            <div class="category-check-itm">
                                                <ul class="category-check">
                                                    {
                                                        categories.map((data, index) => (


                                                            <li><input class="form-check-input searchCls" type="checkbox" name="industry" value={data.catId} id="category" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                        ))}

                                                </ul>
                                            </div>


                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="d-flex"><label for="firstName" class=" headtitle">Your Location </label></div>
                                            <div class="my-2">
                                                <hr />
                                            </div>
                                            <div class="category-check-itm">
                                                <ul>
                                                    {
                                                        locationCounty.map((data, index) => (


                                                            <li><input class="form-check-input searchCls" type="checkbox" name="location" value={data.jobLocationCountyId} id="category" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                        ))}
                                                    {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Accounting </label></li>
                                  <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                  <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                  <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> CNC  </label></li> *
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="d-flex"><label for="firstName" class=" headtitle">Job Type </label></div>
                                            <div class="my-2">
                                                <hr />
                                            </div>
                                            <div class="category-check-itm">
                                                <ul>
                                                    {
                                                        jobTypes.map((data, index) => (


                                                            <li><input class="form-check-input searchCls" type="checkbox" name="jobtype" value={data.jobTypeId} id="category" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                        ))}
                                                    {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Accounting </label></li>
                                  <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                  <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                  <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> CNC  </label></li> *
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="d-flex"><label for="firstName" class=" headtitle">Education </label></div>
                                            <div class="my-2">
                                                <hr />
                                            </div>
                                            <div class="category-check-itm">
                                                <ul>
                                                    {
                                                        educations.map((data, index) => (


                                                            <li><input class="form-check-input searchCls" type="checkbox" name="education" value={data.educationId} id="category" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                        ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>





                                </div>



                            </div>

                        </div>
                    </div>
                </section> */}

                {/* <!-- Top companies hiring in your area --> */}
                {/* <section class="pb-5" >
                    <div class="container">
                        <div >
                            <div class="col-12">
                                <div class="white-bg px-4 pt-4 pb-4">


                                    {
                                        availableJobsList.map((data, index) => (


                                            <div class="oppertunity-card2">
                                                <div class="logo-org justify-content-start">
                                                {data.logoUrl != '' ?
                                                        <img src={data.logoUrl} alt="" /> :
                                                        <img src="../images/no-logo.jpg" alt="" />
                                                     }
                                                </div>
                                                <div class="right-info">
                                                    <div class="d-flex justify-content-between flex-lg-row flex-column">
                                                        <h4>
                                                            <a href={`/jobsummary?jobId=${data.jobId}`} class="company-name">{data.title ? data.title : ""} </a>
                                                        </h4>
                                                        <div class="date">
                                                            <b class="d-flex text-uppercase">
                                                               * <span>August 19</span>,
                                      <span class="ms-1"> 2021</span> *
                                                                <span>{moment(data.createdDate).format("MMMM D, YYYY")}</span>
                                                            </b>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex mb-3">
                                                        <span><a href={`/companyDetails?companyId=${data.companyId}`} class="company-name">{data.companyName ? data.companyName : ""} </a></span>
                                                        <span class="mx-3">|</span>
                                                        <span> {data.city != '' ? data.city : '' }
                                                        {data.city != '' && data.state != '' ? ', ' : '' }
                                                        {data.state != '' ? data.state : ''}</span>
                                                    </div>

                                                    <p dangerouslySetInnerHTML={{ __html: data.summary.substring(0, 150)+"...  <a style='font-weight:normal;text-decoration:underline;' href='/jobsummary?jobId="+data.jobId+"'>View more</a>" }} />
                                                    * <p class="mt-2">
                                                        {`${data.summary.substring(0, 150)}...`}
                                                        </p> *

                                                </div>
                                                <div class="job-seeker">
                                                    <b class="text-uppercase mb-2">Perks</b>
                                                    <div class="job-seek-icon mb-3">

                                                    {
                                                    data.perksList.map((datap, index) => (
                                                        <div> <img src={datap.perksImageUrl} alt="" /></div>
                                                    ))}
                                                        * <div> <img src="images/health.png" alt="" /></div>
                                                        <div> <img src="images/lunch-plate.png" alt="" /></div>
                                                        <div> <img src="images/dental.png" alt="" /></div>
                                                        <div> <img src="images/cofee.png" alt="" /></div>
                                                        <div> <img src="images/cofee.png" alt="" /></div>
                                                        <div><a href="#" class="js-more" data-bs-toggle="modal" data-bs-target="#exampleModal">more &#62;</a></div> *

                                                    </div>
                                                    <div class="text-center">
                                                        <a href={`/jobsummary?jobId=${data.jobId}`} class="reg-button">View JOB</a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}


* <div class="pagination-wrap text-center mt-3">
                                    <nav aria-label="...">
                                        <ul class="pagination m-auto d-inline-flex">
                                            <li class="page-item disabled">
                                                <span class="page-link"><img src="images/arrow-left.JPG" alt=""/></span>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                                            <li class="page-item active" aria-current="page">
                                                <span class="page-link">2</span>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                            <li class="page-item"><a class="page-link" href="#">...</a></li>
                                            <li class="page-item">
                                                <a class="page-link" href="#"><img src="/images/arrow-right.JPG" alt=""/></a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>    *


<div class="paginationContent">
                                       <Pagination
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange2}/>

                                       </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section> */}

            </main>

            <main class="empr-directory-main cd-mobile ofx-h">
                <section class="headtitle-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item headtitle "><a href="#">Available Jobs</a></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- info typography --> */}
                <section class="company-detail-wrap">
                    <div>
                        <div class="row">
                            <div class="col-12 ">
                                <div class="white-bg px-2 pt-4 pb-4">
                                    <div class="row mb-3">
                                        <div class="col-lg-12 m-auto">
                                            <h1 class="text-center ">
                                                There are <span class=" red-text">so many opportunities </span>for local careers!
                                            </h1>
                                        </div>
                                        {/* <div class="col-lg-12 m-auto text-center">
                                            <p>
                                                To find a great new job with one of the many amazing local employers in your area, simply select your industry, location and experience level below, as well as whether you’d like part-time, full-time, seasonal work or a full time career.
                                                <br /><br /> Your matches will automatically appear in the <b>“top job opportunities”</b> box below.
                                            </p>
                                        </div> */}
                                    </div>
                                    <div class="row">

                                        <div class="col-12">
                                            <div class="white-bg px-4">
                                                <a href="#" class="reg_button btn btn-primary text-uppercase  d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                    {/* <span><img src="../images/filter-com.svg" width="42" alt="" /></span> */}
                                                    <h3 class="mb-0 mx-2" onClick={filterFuncInit} id="filterInit">Filter Preferences</h3>
                                                </a>
                                            </div>
                                            <div id="availableJobsList_mobileResult" class="white-bg px-2 pt-4 pb-4">
                                            {
                                                cardsloop.map((data, inde) => (
                                                <div class="cards py-3 border mb-3">
                                                    <div class="card-ld is-loading">
                                                        <div class="image"></div>
                                                        <div class="content">

                                                            <h2></h2>
                                                            <div class="spanl">
                                                                <span></span> <span></span>
                                                                <span class="lastspan"></span>
                                                            </div>
                                                            <p></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                                {
                                                    availableJobsList_mobile.map((data, index) => (


                                                        <div class="oppertunity-card2 position-relative">
                                                            <a href="#" style={{ position: "absolute", top: "15px", right: "0px" }} class="tool" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="This company provides job shadow opportunities">
                                                                {data.jobShadowConcert == 'Y' ? <div style={{ width: "55px" }}> <img src="images/badge.png" alt="" class="w-100" /></div> : ''}
                                                            </a>

                                                            <div class="logo-org justify-content-start">
                                                                <img src={(data.logoUrl == "" || data.logoUrl == null) ? "images/no-logo-sn.JPG" : data.logoUrl} alt="" />
                                                            </div>
                                                            <div class="right-info">
                                                                <div class="d-flex justify-content-between flex-lg-row flex-column">


                                                                    <h4><a href={`/jobsummary?jobId=${data.jobId}&page=` + currentPage} class="company-name">
                                                                        {/* {data.title?data.title.substring(0, 50):""}... */}
                                                                        {`${data.title.substring(0, 40)}`}{data.title.length > 40 ? "..." : ""}
                                                                    </a></h4>

                                                                    {/* <h4>

                                                                <a href={`/jobsummary?jobId=${data.jobId}&page=`+currentPage} class="company-name">{data.title ? data.title : ""} </a>
                                                            </h4> */}
                                                                    <div class="date">
                                                                        <b class="d-flex text-uppercase">
                                                                            {/* <span>August 19</span>,
                                        <span class="ms-1"> 2021</span>  */}
                                                                            <span>{moment(data.createdDate).format("MM/DD/YYYY")}</span>
                                                                        </b>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex mb-3">
                                                                    <span><a href={`/companyDetails?companyId=${data.companyId}&page=` + currentPage}>{data.companyName ? data.companyName : ""} </a></span>
                                                                    <span class="mx-3">|</span>
                                                                    <span>  {data.city + ', '} {data.state}</span>
                                                                </div>
                                                                {/* <p class="mt-2">{`${data.summary.substring(0, 150)}...`}</p> */}
                                                                <p dangerouslySetInnerHTML={{ __html: data.summary.substring(0, 150) + "...  <a style='font-weight:normal;text-decoration:underline;' href='/jobsummary?jobId=" + data.jobId + "&page=" + currentPage + "'>View more</a>" }} />

                                                            </div>

                                                            <div class="job-seeker">
                                                                {/* <b class="text-uppercase mb-2">Perks</b>
                                                        <div class="job-seek-icon mb-3">

                                                        <div class="d-flex align-items-center"> <img src="images/health.png" alt="" /></div>
                                                        <div class="d-flex align-items-center"> <img src="images/lunch-plate.png" alt="" /></div>
                                                        <div class="d-flex align-items-center"> <img src="images/dental.png" alt="" /></div>
                                                        <div class="d-flex align-items-center"> <img src="images/cofee.png" alt="" /></div>
                                                        <div class="d-flex align-items-center"> <img src="images/cofee.png" alt="" /></div>
                                                            <div><a href="#" class="js-more" data-bs-toggle="modal" data-bs-target="#exampleModal">more &#62;</a></div>

                                                        </div> */}
                                                        <div class="perks-avl align-items-center">
                                                                    {data.perksList.length > 0 ? <span class="mr-2"><small><b>PERKS</b></small></span> : '' }
                                                                    
                                                                    <div class="perks-avl-icon">

                                                                        {
                                                                            data.perksList.map((datap, index) => (
                                                                                <div> <img src={datap.perksImageUrl} alt="" /></div>
                                                                            ))}
                                                                    </div>

                                                                

                                                                </div>
                                                                <div class="text-center">
                                                                    <Link to={`/jobsummary?jobId=${data.jobId}&page=` + currentPage} class="reg-button">View JOB</Link>
                                                                </div>

                                                                <div class="btn-group avls mx-3">
                                                                    <button class="thrdicon tool" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-toggle="tooltip" data-placement="top" title="Tooltip on top">
                                                                        &#8942;
                                                                    </button>

                                                                    <ul class="dropdown-menu ">
                                                                        {/* <li className="px-2 py-1"><a id="fbsharebtn" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.React_App_FRONT_URL}jobsummary?jobId=${data.jobId}&page=${currentPage}&amp;src=sdkpreparse`} data-mobile-iframe="true"><img src="images/FACEBOOK 2.png" width={"32px"} alt="facebook"/><span class="px-3">Facebook</span></a></li>
                                                            <li className="px-2 py-1"><a class="popup" target="_blank" href={`https://twitter.com/share?text=${data.title}&url=${process.env.React_App_FRONT_URL}jobsummary?jobId=${data.jobId}`}><img src="images/TWITTER 2.png" width={"32px"} alt="twitter"/><span class="px-3">Twitter</span></a></li>
                                                            <li className="px-2 py-1"><a class="popup" target="_blank" href={`https://www.linkedin.com/shareArticle?mini=true&url=${process.env.React_App_FRONT_URL}/jobsummary?jobId=${data.jobId}&title=${data.title}`}><img src="images/LINKEDIN 2.png" width={"32px"} alt="linkedin"/><span class="px-3">Linkedin</span></a></li> */}



                                                                        <li className="px-2 py-1"><a id="fbsharebtn" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(process.env.React_App_FRONT_URL + "jobsummary?jobId=" + data.jobId + "&title=" + data.title)}&amp;src=sdkpreparse`} data-mobile-iframe="true"><img src="images/FACEBOOK 2.png" width={"32px"} alt="facebook" /><span class="px-3">Facebook</span></a></li>
                                                                        {/* <li className="px-2 py-1"><a class="popup" target="_blank" href={`https://twitter.com/share?text=${data.title}&url=` + encodeURIComponent(process.env.React_App_FRONT_URL + "jobsummary?jobId=" + data.jobId + "&title=" + data.title)}><img src="images/TWITTER 2.png" width={"32px"} alt="twitter" /><span class="px-3">Twitter</span></a></li> */}
                                                                        <li className="px-2 py-1"><a class="popup" target="_blank" href={`https://twitter.com/intent/tweet?text=${data.title}&url=` + encodeURIComponent(process.env.React_App_FRONT_URL + "jobsummary?jobId=" + data.jobId + "&title=" + data.title)}><img src="images/TWITTER 2.png" width={"32px"} alt="twitter" /><span class="px-3">Twitter</span></a></li>
                                                                        <li className="px-2 py-1"><a class="popup" target="_blank" href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(process.env.React_App_FRONT_URL + "jobsummary?jobId=" + data.jobId + "&title=" + data.title)}`}><img src="images/LINKEDIN 2.png" width={"32px"} alt="linkedin" /><span class="px-3">Linkedin</span></a></li>
                                                                        <li className="px-2 py-1"><a target="_blank" href={`mailto:?subject=Check%20out%20this%20article:%20${data.title}&body=${encodeURIComponent(process.env.React_App_FRONT_URL + "jobsummary?jobId=" + data.jobId + "&title=" + data.title)}`}><img src="images/EMAIL 2.png" width={"32px"} alt="email"/><span class="px-3">Email</span></a></li>

                                                                    </ul>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    ))}


                                                <div class="paginationContent">
                                                    <Pagination
                                                        total={totalItems_m}
                                                        itemsPerPage={ITEMS_PER_PAGE}
                                                        currentPage={currentPage_m}
                                                        onPageChange={handlePageChange2_m} />

                                                </div>


                                            </div>

                                        </div>




                                    </div>
                                    {/* Category List */}
                                    {/* <div class="row" id="step1">
                                        <div class="text-center">
                                            <h1 class="mb-4">
                                                <span class=" red-text pb-2 border-bottom ">Step 1:</span>
                                            </h1>
                                            <h2 class="headtitle mt-3">Choose Your Industry</h2>
                                        </div>
                                        <div class="col-12 mt-3 p-3">
                                            <div class="mobile-form-avl p-3">
                                                <div class="category-check-itm">
                                                    <ul>
                                                        {
                                                            categories.map((data, index) => (


                                                                <li><input class="form-check-input searchCls" type="checkbox" name="industry_m" value={data.catId} id="industry" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                            ))}

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4 mb-4">
                                            <a  class="reg-button" onClick={() => submitStep1()}>Next &#62;&#62;</a>
                                        </div>
                                    </div> */}

                                    {/* Location List */}
                                    {/* <div class="row" id="step2">
                                        <div class="text-center">
                                            <h1 class="mb-4">
                                                <span class=" red-text pb-2 border-bottom ">Step 2:</span>
                                            </h1>
                                            <h2 class="headtitle mt-3">Choose Your Location</h2>
                                        </div>
                                        <div class="col-12 mt-3 p-3">
                                            <div class="mobile-form-avl p-3">
                                                <div class="category-check-itm">
                                                    <ul>
                                                        {
                                                            locationCounty.map((data, index) => (


                                                                <li><input class="form-check-input searchCls" type="checkbox" name="location_m" value={data.jobLocationCountyId} id="location" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                            ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4 mb-4">
                                            <a  class="reg-button" onClick={() => submitStep2()}>Next &#62;&#62;</a>
                                        </div>
                                    </div> */}

                                    {/* Job types List */}
                                    {/* <div class="row" id="step3">
                                        <div class="text-center">
                                            <h1 class="mb-4">
                                                <span class=" red-text pb-2 border-bottom ">Step 3:</span>
                                            </h1>
                                            <h2 class="headtitle mt-3">Choose Your Job Types</h2>
                                        </div>
                                        <div class="col-12 mt-3 p-3">
                                            <div class="mobile-form-avl p-3">
                                                <div class="category-check-itm">
                                                    <ul>
                                                        {
                                                            jobTypes.map((data, index) => (


                                                                <li><input class="form-check-input searchCls" type="checkbox" name="jobtype_m" value={data.catId} id="jobtype" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                            ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4 mb-4">
                                            <a  class="reg-button" onClick={() => submitStep3()}>Next &#62;&#62;</a>
                                        </div>
                                    </div> */}

                                    {/* Education List */}
                                    {/* <div class="row" id="step4">
                                        <div class="text-center">
                                            <h1 class="mb-4">
                                                <span class=" red-text pb-2 border-bottom ">Step 4:</span>
                                            </h1>
                                            <h2 class="headtitle mt-3">Choose Your Education</h2>
                                        </div>
                                        <div class="col-12 mt-3 p-3">
                                            <div class="mobile-form-avl p-3">
                                                <div class="category-check-itm">
                                                    <ul>
                                                        {
                                                            educations.map((data, index) => (


                                                                <li><input class="form-check-input searchCls" type="checkbox" name="education_m" value={data.educationId} id="education" />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                                            ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4 mb-4">
                                            <a  class="reg-button" onClick={() => submitStep4()}>Next &#62;&#62;</a>
                                        </div>
                                    </div> */}




                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="pb-5" id="step5">
                    <div class="container">
                        <div >
                            <div class="col-12">
                                <div class="white-bg px-4 pt-4 pb-4">


                                    {
                                        availableJobsList_m.map((data, index) => (


                                            <div class="oppertunity-card2">
                                                <div class="logo-org justify-content-start">
                                                    <img src={(data.logoUrl == '' || data.logoUrl == null) ? "images/no-logo-sn.JPG" : data.logoUrl} alt="" />
                                                </div>
                                                <div class="right-info">
                                                    <div class="d-flex justify-content-between flex-lg-row flex-column">
                                                        <h4>
                                                            {/* {data.title?data.title:""} */}
                                                            <a href={`/jobsummary?jobId=${data.jobId}&page=` + currentPage} class="company-name">{data.title ? data.title : ""} </a>
                                                        </h4>
                                                        <div class="date">
                                                            <b class="d-flex text-uppercase">
                                                                {/* <span>August 19</span>,
                                      <span class="ms-1"> 2021</span>  */}
                                                                <span>{moment(data.createdDate).format("MM/DD/YYYY")}</span>
                                                            </b>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex mb-3">
                                                        <span><a href={`/companyDetails?companyId=${data.companyId}&page=` + currentPage}>{data.companyName ? data.companyName : ""} </a></span>
                                                        <span class="mx-3">|</span>
                                                        <span>  {data.city + ', '} {data.state}</span>
                                                    </div>
                                                    {/* <p class="mt-2">{`${data.summary.substring(0, 150)}...`}</p> */}
                                                    <p dangerouslySetInnerHTML={{ __html: data.summary.substring(0, 150) }} />

                                                </div>
                                                <div class="job-seeker">
                                                    {/* <b class="text-uppercase mb-2">Perks</b>
                                                    <div class="job-seek-icon mb-3">

                                                     <div class="d-flex align-items-center"> <img src="images/health.png" alt="" /></div>
                                                       <div class="d-flex align-items-center"> <img src="images/lunch-plate.png" alt="" /></div>
                                                       <div class="d-flex align-items-center"> <img src="images/dental.png" alt="" /></div>
                                                       <div class="d-flex align-items-center"> <img src="images/cofee.png" alt="" /></div>
                                                       <div class="d-flex align-items-center"> <img src="images/cofee.png" alt="" /></div>
                                                        <div><a href="#" class="js-more" data-bs-toggle="modal" data-bs-target="#exampleModal">more &#62;</a></div>

                                                    </div> */}
                                                    <div class="text-center">
                                                        <Link to={`/jobsummary?jobId=${data.jobId}&page=` + currentPage} class="reg-button">View JOB</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}


                                    <div class="paginationContent">
                                        <Pagination
                                            total={totalItems_m}
                                            itemsPerPage={ITEMS_PER_PAGE}
                                            currentPage={currentPage_m}
                                            onPageChange={handlePageChange2_m} />

                                    </div>


                                </div>



                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">JOB PERKS</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body py-5">
                            <div class="job-seek-modal">
                                <div> <img src="images/health.png" alt="" /></div>
                                <div> <img src="images/lunch-plate.png" alt="" /></div>
                                <div> <img src="images/dental.png" alt="" /></div>
                                <div> <img src="images/cofee.png" alt="" /></div>
                                <div> <img src="images/sick.png" alt="" /></div>
                                <div> <img src="images/health.png" alt="" /></div>
                                <div> <img src="images/lunch-plate.png" alt="" /></div>
                                <div> <img src="images/dental.png" alt="" /></div>

                            </div>
                        </div>

                    </div>
                </div>
            </div> */}




            <div class="filter-modal modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header justify-content-start">
                            <button type="button" class="btn-close" style={{ "position": "absolute", "top": "10px", "right": "10px" }} data-bs-dismiss="modal" id="closeMobilePopup" aria-label="Close"></button>

                        </div>
                        <div class="modal-body">

                            {/* <div class="col-12 mb-4">
                                                    <label for="firstName" class="form-label">Location</label>
                                                    <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select Location</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select>
                                                </div> */}

                            <div class="col-12 mb-4">
                                <label for="firstName" class="form-label">Keywords</label>
                                <form onSubmit={handleSubmit(submitValue_m)} class=" search-bar my-2 w-100">
                                    <input class="form-control me-2" type="search" aria-label="Search" id="search_textm" name="search_textm" placeholder="Search by Company / Job Title / Summary" />
                                    <button class="border-0 p-0 dir-search" type="submit" onClick={() => { mobileFilterCall(ITEMS_PER_PAGE, "1","1") }}><img src="images/search-icon.JPG" alt="" /></button>
                                </form>

                            </div>
                            <div class="col-12 mb-4">
                                <label for="firstName" class="form-label">industry</label>
                                {/* <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select industry</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select> */}
                                <ReactSelect
                                    class="form-select"
                                    options={mulcategories}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                        Option
                                    }}
                                    onChange={handleChangeForCate}
                                    allowSelectAll={false}
                                    value={optionCatSelected}
                                />
                            </div>

                            <div class="col-12 mb-4">
                                <label for="firstName" class="form-label">Location</label>
                                {/* <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select industry</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select> */}
                                <ReactSelect
                                    class="form-select"
                                    options={mullocationCounty}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                        Option
                                    }}
                                    onChange={handleChangeForLocation}
                                    allowSelectAll={false}
                                    value={mullocationCountyselected}
                                />
                            </div>
                            <div class="col-12 mb-4">
                                <label for="firstName" class="form-label">Job Type</label>

                                <ReactSelect
                                    class="form-select"
                                    options={muljobTypes}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                        Option
                                    }}
                                    onChange={handleChange}
                                    allowSelectAll={true}
                                    value={optionSelected}
                                />
                            </div>


                            <div class="col-12 mb-4">
                                <label for="firstName" class="form-label">Education</label>

                                <ReactSelect
                                    class="form-select"
                                    options={muleducation}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                        Option
                                    }}
                                    onChange={handleChangeForEdu_d_m}
                                    allowSelectAll={true}
                                    value={optionEduSelected_d_m}
                                />
                            </div>



                            <div class="col-12 mb-4 d-none">
                                <label for="firstName" class="form-label">Education</label>
                                <ReactSelect
                                    class="form-select"
                                    options={muleducation}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                        Option
                                    }}
                                    onChange={handleChangeForEdu}
                                    allowSelectAll={true}
                                    value={optionEduSelected}
                                />
                                {/* <select class="form-select" aria-label="Default select example">
                                                 <option selected>Select Education</option>
                                                 <option value="1">Ohio</option>
                                                 <option value="2">California</option>
                                                 <option value="3">Masyville</option>
                                              </select> */}
                            </div>


                        </div>

                        <input type="hidden" id="industry_selected_m" />
                        <input type="hidden" id="industryLabel_selected_m" />
                        <input type="hidden" id="education_selected_m" />
                        <input type="hidden" id="educationLabel_selected_m" />
                        <input type="hidden" id="jobtype_selected_m" />
                        <input type="hidden" id="jobtypeLabel_selected_m" />
                        <input type="hidden" id="location_selected_m" />
                        <input type="hidden" id="locationLabel_selected_m" />

                        <div class="d-flex justify-content-center pb-5">
                            <button type="button" class="btn btn-secondary text-uppercase mx-2" onClick={() => { ClearAll() }}>Reset</button>
                            <button type="button" class="btn btn-primary text-uppercase" onClick={() => { mobileFilterCall(ITEMS_PER_PAGE, "1", '1') }}>Filter</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
};

AvailableJobs.propTypes = {};

AvailableJobs.defaultProps = {};

export default AvailableJobs;
